import styled from 'styled-components';

import { Icon as IconBase, Props } from './Icon';

const CloseIcon = ({ id = '', ...props }: Props) => (
  <Icon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 20 20">
      <defs />
      <defs>
        <path id={`path-close-${id}`} d="M20 2l-2-2-8 8-8-8-2 2 8 8-8 8 2 2 8-8 8 8 2-2-8-8z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id={`mask-close-${id}`} fill="#fff">
          <use xlinkHref={`#path-close-${id}`} />
        </mask>
        <use fill="#000" fillOpacity=".6" fillRule="nonzero" xlinkHref={`#path-close-${id}`} />
        <g id={id} mask={`url(#mask-close-${id})`} fill="#000">
          <path d="M0 0h20v20H0z" />
        </g>
      </g>
    </svg>
  </Icon>
);

const Icon = styled(IconBase)`
  opacity: 0.6;
  &:hover {
    opacity: 0.8;
  }
  svg {
    backface-visibility: hidden;
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;
  }
`;

export default CloseIcon;
