import { getCookies, deleteCookie, setCookie } from 'utils';
import {
  STORAGE_ADMIN_ID_ALIAS,
  STORAGE_ADMIN_TOKEN_ALIAS,
  STORAGE_TOKEN_ALIAS,
  STORAGE_USER_ID_ALIAS,
} from './constants';
import { getUserFromTokenPayload } from './utils';

export const setToken = (
  token: string,
  { tokenAlias = STORAGE_TOKEN_ALIAS, userIdAlias = STORAGE_USER_ID_ALIAS } = {},
) => {
  const user = getUserFromTokenPayload(token);
  const { exp, id } = user;
  const expirationDate = new Date(exp * 1000).toUTCString();

  setCookie(tokenAlias, token, expirationDate);
  setCookie(userIdAlias, id, expirationDate);
};

export const setAdminToken = (token: string) => {
  setToken(token, { tokenAlias: STORAGE_ADMIN_TOKEN_ALIAS, userIdAlias: STORAGE_ADMIN_ID_ALIAS });
};

export const getToken = () => {
  const { token } = getCookies(document.cookie);

  return token;
};

export const getAdminToken = () => {
  const { adminToken } = getCookies(document.cookie);

  return adminToken;
};

export const getUserId = () => {
  const { userId } = getCookies(document.cookie);

  return userId;
};

export const getAdminId = () => {
  const { adminId } = getCookies(document.cookie);

  return adminId;
};

export const removeToken = () => {
  deleteCookie(STORAGE_TOKEN_ALIAS);
};

export const removeAdminToken = () => {
  deleteCookie(STORAGE_ADMIN_ID_ALIAS);
  deleteCookie(STORAGE_ADMIN_TOKEN_ALIAS);
};

export const removeUserId = () => {
  deleteCookie(STORAGE_USER_ID_ALIAS);
};

export const removeUserFromLocal = () => {
  removeToken();
  removeAdminToken();
  removeUserId();
};
