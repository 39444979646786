import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { ContentState, Editor } from 'react-draft-wysiwyg';
import styled from 'styled-components';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FormattedMessage } from 'react-intl';
import { I18nEnum } from 'types';

const toolbar = {
  options: ['inline', 'link', 'list', 'history'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold', 'italic', 'underline', 'strikethrough'],
    bold: {
      icon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath d='M17 36q-1.25 0-2.125-.875T14 33V11q0-1.25.875-2.125T17 8h8.4q3.5 0 5.825 2.1t2.325 5.3q0 2-.975 3.525T29.7 21.35v.3q2.35.75 3.575 2.45 1.225 1.7 1.225 4.1 0 3.5-2.425 5.65Q29.65 36 25.75 36Zm1.3-16.15h6.8q1.9 0 3.1-1.125 1.2-1.125 1.2-2.925 0-1.8-1.2-2.95-1.2-1.15-3.1-1.15h-6.8Zm0 12.35h7.2q2.2 0 3.45-1.175T30.2 27.8q0-2-1.25-3.175-1.25-1.175-3.45-1.175h-7.2Z'/%3E%3C/svg%3E",
      className: undefined,
    },
    italic: {
      icon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath d='M12.25 39.1q-.75 0-1.25-.5t-.5-1.25q0-.75.5-1.25t1.25-.5h5.2l9.35-23.2h-5.85q-.75 0-1.25-.525t-.5-1.225q0-.75.5-1.25t1.25-.5h14.8q.75 0 1.25.5t.5 1.25q0 .75-.5 1.25t-1.25.5h-5.2L21.2 35.6h5.85q.75 0 1.25.525t.5 1.225q0 .75-.5 1.25t-1.25.5Z'/%3E%3C/svg%3E",
      className: undefined,
    },
    underline: {
      icon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath d='M11.5 42q-.65 0-1.075-.425Q10 41.15 10 40.5q0-.65.425-1.075Q10.85 39 11.5 39h25q.65 0 1.075.425Q38 39.85 38 40.5q0 .65-.425 1.075Q37.15 42 36.5 42ZM24 34q-5.05 0-8.525-3.45Q12 27.1 12 22.1V8q0-.85.575-1.425Q13.15 6 14 6q.85 0 1.425.575Q16 7.15 16 8v14.2q0 3.3 2.3 5.55T24 30q3.4 0 5.7-2.25Q32 25.5 32 22.2V8q0-.85.575-1.425Q33.15 6 34 6q.85 0 1.425.575Q36 7.15 36 8v14.1q0 5-3.475 8.45Q29.05 34 24 34Z'/%3E%3C/svg%3E",
      className: undefined,
    },
    strikethrough: {
      icon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath d='M25.2 40q-3.4 0-6.275-1.575Q16.05 36.85 14.35 34.1q-.4-.7-.125-1.45t1.075-1.1q.6-.25 1.275-.025.675.225 1.125.875 1.2 1.75 3.2 2.775t4.3 1.025q2.6 0 4.15-1.35 1.55-1.35 1.55-3.65 0-1.15-.475-2.425T29.1 26.5h4.2q.7 1.15 1.05 2.3.35 1.15.35 2.4 0 3.9-2.65 6.35Q29.4 40 25.2 40ZM5.5 23.5q-.65 0-1.075-.425Q4 22.65 4 22q0-.65.425-1.075Q4.85 20.5 5.5 20.5h37q.65 0 1.075.425Q44 21.35 44 22q0 .65-.425 1.075-.425.425-1.075.425Zm9.25-6q-.1-.4-.15-.8-.05-.4-.05-.8 0-3.65 2.55-5.925T23.7 7.7q2.75 0 4.975 1.075Q30.9 9.85 32.3 11.75q.5.65.225 1.45-.275.8-1.125 1.2-.55.25-1.225.05t-1.225-.8q-.9-1-2.25-1.575t-3-.575q-2.45 0-3.95 1.2t-1.5 3.3q0 .4.05.75t.15.75Z'/%3E%3C/svg%3E",
      className: undefined,
    },
  },
  link: {
    inDropdown: true,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: '_blank',
    options: ['link', 'unlink'],
    link: {
      icon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath d='M14 34q-4.25 0-7.125-2.875T4 24q0-4.25 2.875-7.125T14 14h7q.65 0 1.075.425.425.425.425 1.075 0 .65-.425 1.075Q21.65 17 21 17h-7q-3 0-5 2t-2 5q0 3 2 5t5 2h7q.65 0 1.075.425.425.425.425 1.075 0 .65-.425 1.075Q21.65 34 21 34Zm3.7-8.5q-.65 0-1.075-.425Q16.2 24.65 16.2 24q0-.65.425-1.075.425-.425 1.075-.425h12.5q.65 0 1.075.425.425.425.425 1.075 0 .65-.425 1.075-.425.425-1.075.425ZM44 24h-3q0-3-2-5t-5-2h-7q-.65 0-1.075-.425-.425-.425-.425-1.075 0-.65.425-1.075Q26.35 14 27 14h7q4.25 0 7.125 2.875T44 24Zm-7.55 16q-.65 0-1.075-.425-.425-.425-.425-1.075V34h-4.5q-.65 0-1.075-.425-.425-.425-.425-1.075 0-.65.425-1.075Q29.8 31 30.45 31h4.5v-4.5q0-.65.425-1.075Q35.8 25 36.45 25q.65 0 1.075.425.425.425.425 1.075V31h4.5q.65 0 1.075.425.425.425.425 1.075 0 .65-.425 1.075Q43.1 34 42.45 34h-4.5v4.5q0 .65-.425 1.075Q37.1 40 36.45 40Z'/%3E%3C/svg%3E",
      className: undefined,
    },
    unlink: {
      icon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath d='m29.55 25.5-2.85-2.85h2.85q.6 0 1.05.4.45.4.45 1t-.45 1.025q-.45.425-1.05.425Zm7.95 7.95L35.05 31q2.6-.5 4.275-2.4Q41 26.7 41 24.15q0-3-2-5t-5-2h-6.25q-.65 0-1.075-.425-.425-.425-.425-1.075 0-.65.425-1.075.425-.425 1.075-.425H34q4.25 0 7.125 2.875T44 24.15q0 3.25-1.875 5.7t-4.625 3.6Zm2.15 10.65L4.2 8.7q-.45-.45-.45-1.075T4.2 6.55q.45-.45 1.075-.45t1.075.45L41.8 42q.45.45.45 1.05 0 .6-.45 1.05-.45.45-1.075.45t-1.075-.45ZM21 34h-7q-4.25 0-7.125-2.875T4 24q0-3.7 2.225-6.4t5.675-3.4l2.8 2.8H14q-3 0-5 2t-2 5q0 3 2 5t5 2h7q.65 0 1.075.425.425.425.425 1.075 0 .65-.425 1.075Q21.65 34 21 34Zm2.2-8.5h-4.75q-.65 0-1.075-.425-.425-.425-.425-1.075 0-.65.425-1.075.425-.425 1.075-.425h1.75Z'/%3E%3C/svg%3E",
      className: undefined,
    },
  },
  list: {
    inDropdown: true,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered', 'indent', 'outdent'],
  },
  history: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['undo', 'redo'],
    undo: {
      icon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath d='M15.5 38q-.65 0-1.075-.425Q14 37.15 14 36.5q0-.65.425-1.075Q14.85 35 15.5 35h12.95q3.5 0 6.025-2.325Q37 30.35 37 26.9t-2.525-5.775Q31.95 18.8 28.45 18.8H13.7l4.65 4.65q.45.45.45 1.05 0 .6-.45 1.05-.45.45-1.05.45-.6 0-1.05-.45l-7.2-7.2q-.25-.25-.35-.5-.1-.25-.1-.55 0-.3.1-.55.1-.25.35-.5l7.2-7.2q.45-.45 1.05-.45.6 0 1.05.45.45.45.45 1.05 0 .6-.45 1.05L13.7 15.8h14.7q4.75 0 8.175 3.2Q40 22.2 40 26.9t-3.425 7.9Q33.15 38 28.4 38Z'/%3E%3C/svg%3E",
      className: undefined,
    },
    redo: {
      icon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='48'%3E%3Cpath d='M19.6 38q-4.75 0-8.175-3.2Q8 31.6 8 26.9t3.425-7.9q3.425-3.2 8.175-3.2h14.7l-4.65-4.65q-.45-.45-.45-1.05 0-.6.45-1.05.45-.45 1.05-.45.6 0 1.05.45l7.2 7.2q.25.25.35.5.1.25.1.55 0 .3-.1.55-.1.25-.35.5l-7.2 7.2q-.45.45-1.05.45-.6 0-1.05-.45-.45-.45-.45-1.05 0-.6.45-1.05l4.65-4.65H19.55q-3.5 0-6.025 2.325Q11 23.45 11 26.9t2.525 5.775Q16.05 35 19.55 35H32.5q.65 0 1.075.425Q34 35.85 34 36.5q0 .65-.425 1.075Q33.15 38 32.5 38Z'/%3E%3C/svg%3E",
      className: undefined,
    },
  },
};

const optionsToHTML = {
  entityStyleFn: entity => {
    const entityType = entity.get('type').toLowerCase();
    if (entityType === 'link') {
      const data = entity.getData();
      return {
        element: 'a',
        attributes: {
          href: data.url,
          target: '_blank',
        },
        style: {
          // Put styles here...
        },
      };
    }
  },
};

export type onChangeTextEditor = {
  editorState: EditorState;
  contentState: ContentState;
  html: string;
  plainText: string;
};

export const useForceHTML = () => {
  const [forceHTML, setForceHTML] = useState<undefined | string>(undefined);

  const cleanForceHTML = useCallback(() => setForceHTML(undefined), []);

  return { forceHTML, setForceHTML, cleanForceHTML };
};

export interface TextEditorProps {
  html?: string;
  forceHTML?: string;
  placeholder?: string;
  maxLength?: number;
  onChange?: (data: onChangeTextEditor) => void;
  cleanForceHTML?: () => void;
  counter?: boolean;
  hasError?: boolean;
}

const TextEditor: React.FC<TextEditorProps> = ({
  html,
  onChange: _onChange,
  forceHTML,
  placeholder,
  maxLength,
  cleanForceHTML,
  counter,
  hasError,
}) => {
  const initialContentState = useMemo(() => stateFromHTML(html || ''), [html]);
  const wrapperId = useRef(Math.round(Math.random() * 10000000));

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(initialContentState),
  );
  const htmlFromState = useMemo(
    () => stateToHTML(editorState.getCurrentContent(), optionsToHTML),
    [editorState],
  );

  useEffect(() => {
    const editorWrapperEl = document.querySelector(
      `#rdw-wrapper-${wrapperId.current}`,
    ) as HTMLDivElement;

    if (!editorWrapperEl || !maxLength) return;

    const onKeyDown = (e: KeyboardEvent) => {
      if (editorState.getCurrentContent().getPlainText().length >= maxLength) {
        e.preventDefault();
      }
    };

    editorWrapperEl.addEventListener('keydown', onKeyDown);

    return () => editorWrapperEl.removeEventListener('keydown', onKeyDown);
  }, [maxLength, editorState]);

  useEffect(() => {
    if (typeof forceHTML === 'string' && htmlFromState !== forceHTML) {
      const _contentState = stateFromHTML(forceHTML || '');
      setEditorState(EditorState.createWithContent(_contentState));
      cleanForceHTML && cleanForceHTML();
    }
  }, [forceHTML, htmlFromState, cleanForceHTML]);

  const onEditorStateChange = useCallback(
    __editorState => {
      let _editorState = __editorState;

      const _contentState = _editorState.getCurrentContent();
      const _html = stateToHTML(_contentState, optionsToHTML);
      const plainText = _contentState.getPlainText();
      const isChanged = !!_editorState.getUndoStack().size;

      if (!html && plainText) {
        _editorState = EditorState.moveFocusToEnd(_editorState);
      }

      setEditorState(_editorState);
      _onChange &&
        isChanged &&
        _onChange({
          editorState: _editorState,
          contentState: _contentState,
          html: !plainText ? '' : _html,
          plainText: plainText,
        });
    },
    [_onChange, html],
  );

  const countSymbolsLeft = useMemo(() => {
    if (maxLength) {
      const plainText = editorState.getCurrentContent().getPlainText();
      return maxLength - (plainText || '').toString().length;
    }
  }, [editorState, maxLength]);

  return (
    <Root hasError={hasError}>
      <Editor
        wrapperId={wrapperId.current}
        editorState={editorState}
        wrapperClassName="text-editor"
        toolbarClassName="text-editor__toolbar"
        onEditorStateChange={onEditorStateChange}
        toolbar={toolbar}
        placeholder={placeholder}
        handlePastedText={(text, _html, editorState) => {
          if (
            maxLength &&
            editorState.getCurrentContent().getPlainText().length + text.length > maxLength
          ) {
            return true;
          }
          return false;
        }}
      />
      {maxLength && counter && (
        <Tooltip>
          <FormattedMessage id={I18nEnum.SymbolsLeft} values={{ count: countSymbolsLeft }} />
        </Tooltip>
      )}
    </Root>
  );
};

const Root = styled.div<{ hasError?: boolean }>`
  width: 100%;
  position: relative;

  a {
    cursor: pointer;
  }

  .public-DraftEditorPlaceholder-inner {
    /* font-size: 14px;
    line-height: 20px;
    font-weight: 400; */
  }

  &:focus-within {
    .text-editor {
      border-color: ${props =>
        props.hasError ? props.theme.colors.error : props.theme.colors.orange};

      &__toolbar {
        border-color: ${props => props.theme.colors.orange};
      }
    }
  }

  .text-editor {
    padding: 15px;
    border: 1px solid ${props => props.theme.colors.grey};
    border-radius: 6px;
    font-size: 16px;
    min-height: 200px;

    &__toolbar {
      border: 0;
      padding: 0 0 13px;
      margin: 0;
      border-radius: 0;
      border-bottom: 1px solid ${props => props.theme.colors.grey};
      background-color: transparent;
    }
  }

  .rdw {
    &-editor-main {
      overflow: unset;
      word-break: break-word;
    }

    &-list-dropdown {
      z-index: 10;
    }

    &-option {
      &-active {
        background-color: ${props => props.theme.colors.lightGrey};
      }

      &-wrapper {
        width: 30px;
        height: 30px;

        img {
          height: 17px;
        }
      }
    }

    &-option-wrapper,
    &-dropdown-wrapper {
      border: 0;
      box-shadow: 0px 2px 2px 0px ${props => props.theme.colors.lightGrey};
      transition: box-shadow 0.1s linear;
      cursor: pointer;
      z-index: auto;

      &:hover {
        box-shadow: 0px 2px 2px 1px ${props => props.theme.colors.lightGrey};
      }

      img {
        height: 17px;
      }
    }

    &-colorpicker-modal {
      padding: 10px;

      &-options {
        overflow: auto;
      }

      &-style-label-active {
        border-color: ${props => props.theme.colors.orange};
      }
    }

    &-dropdown {
      &-optionwrapper {
        padding: 0;
        overflow: auto;
      }
      &-carettoopen,
      &--carettoclose {
        border-top-color: ${props => props.theme.colors.grey};
      }
    }

    &-link-modal {
      height: auto;
      box-shadow: 2px 2px 5px ${props => props.theme.colors.lightGrey};
      right: -30px;
      left: unset;

      &-input {
        height: 40px;
      }

      &-target-option {
        display: none;
      }

      &-btn {
        border-radius: 6px;
        height: 40px;
        width: 90px;
        margin: 0 5px;
        box-shadow: unset;
        transition: all 0.2s linear;

        &:hover {
          box-shadow: unset;
        }
      }

      &-btn:first-child {
        background-color: ${props => props.theme.colors.orange};
        color: ${props => props.theme.colors.white};
        border-color: ${props => props.theme.colors.orange};

        &:hover {
          background-color: ${props => props.theme.colors.darkOrange};
        }
      }

      &-btn:last-child {
        background-color: ${props => props.theme.colors.white};
        color: ${props => props.theme.colors.blue};
        border-color: ${props => props.theme.colors.lightBlue};

        &:hover {
          border-color: ${props => props.theme.colors.blue};
        }
      }
    }
  }
`;

const Tooltip = styled.span`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.24px;
  color: ${props => props.theme.colors.grey};
  margin-left: 18px;
  position: absolute;
  bottom: 4px;
`;

export default TextEditor;
