import { ActionType } from 'types';
import * as constants from './constants';

const quickQuoteConfigReducer = (state = constants.DEFAULT_QQ_CONFIG, action: ActionType) => {
  switch (action.type) {
    case constants.UPDATE:
      return { ...state, ...action.config };
    case constants.RESET:
      return { ...action.defaultState };
    default:
      return state;
  }
};

export default quickQuoteConfigReducer;
