import { I18nEnum } from 'types';
import { ProductWarrantyCoverageType } from './types';

export type ProductWarrantyTypeConfig = {
  title: I18nEnum;
  description: I18nEnum;
  image?: string;
};

type ProductWarrantyConfigMap = {
  [key in Exclude<ProductWarrantyCoverageType, 'NoWarranty'>]: ProductWarrantyTypeConfig;
};

export type ProductWarrantyCategoriesValue = {
  [key in Exclude<ProductWarrantyCoverageType, 'NoWarranty'>]: {
    id: I18nEnum;
    args?: Record<string, string | number>;
    prompt?: I18nEnum;
  };
};

export type ProductWarrantyCategories = {
  [key in
    | I18nEnum.ProductsDefectsCoverage
    | I18nEnum.WorkmanshipCoverage
    | I18nEnum.KeyRequirements]: {
    label: I18nEnum;
    description?: I18nEnum;
    prompt?: I18nEnum;
    values: ProductWarrantyCategoriesValue;
  }[];
};

export type ProductWarrantyPrompts = {
  category: I18nEnum;
  label: I18nEnum;
  types?: ProductWarrantyCoverageType[];
}[];

export const PRODUCT_WARRANTY_CONFIG_MAP: ProductWarrantyConfigMap = {
  [ProductWarrantyCoverageType.StandardProductLimitedWarranty]: {
    title: I18nEnum.StandardProductLimitedWarranty,
    description: I18nEnum.StandardProductLimitedWarrantyDescription,
  },
  [ProductWarrantyCoverageType.SystemProtectionLimitedWarranty]: {
    title: I18nEnum.SystemProtectionLimitedWarranty,
    image: '/images/product/oc-system-protection-warranty-logo.svg',
    description: I18nEnum.SystemProtectionLimitedWarrantyDescription,
  },
  [ProductWarrantyCoverageType.PreferredProtectionLimitedWarranty]: {
    title: I18nEnum.PreferredProtectionLimitedWarranty,
    image: '/images/product/oc-preferred-warranty-logo.svg',
    description: I18nEnum.PreferredProtectionLimitedWarrantyDescription,
  },
  [ProductWarrantyCoverageType.PlatinumProtectionLimitedWarranty]: {
    title: I18nEnum.PlatinumProtectionLimitedWarranty,
    image: '/images/product/oc-platinum-warranty-logo.svg',
    description: I18nEnum.PlatinumProtectionLimitedWarrantyDescription,
  },
};

export const PRODUCT_WARRANTY_CATEGORIES: ProductWarrantyCategories = {
  [I18nEnum.ProductsDefectsCoverage]: [
    {
      label: I18nEnum.OverallWarrantyLength,
      description: I18nEnum.OverallWarrantyLengthDescription,
      values: {
        [ProductWarrantyCoverageType.StandardProductLimitedWarranty]: { id: I18nEnum.Lifetime },
        [ProductWarrantyCoverageType.SystemProtectionLimitedWarranty]: { id: I18nEnum.Lifetime },
        [ProductWarrantyCoverageType.PreferredProtectionLimitedWarranty]: { id: I18nEnum.Lifetime },
        [ProductWarrantyCoverageType.PlatinumProtectionLimitedWarranty]: { id: I18nEnum.Lifetime },
      },
    },
    {
      label: I18nEnum.ProductsIncluded,
      values: {
        [ProductWarrantyCoverageType.StandardProductLimitedWarranty]: {
          id: I18nEnum.ShinglesAndAllOwensCorningComponents,
        },
        [ProductWarrantyCoverageType.SystemProtectionLimitedWarranty]: {
          id: I18nEnum.ShinglesAndAllOwensCorningComponents,
        },
        [ProductWarrantyCoverageType.PreferredProtectionLimitedWarranty]: {
          id: I18nEnum.ShinglesAndAllOwensCorningComponents,
        },
        [ProductWarrantyCoverageType.PlatinumProtectionLimitedWarranty]: {
          id: I18nEnum.ShinglesAndAllOwensCorningComponents,
        },
      },
    },
    {
      label: I18nEnum.TRUPROtectionPeriod,
      description: I18nEnum.TRUPROtectionPeriodDescription,
      values: {
        [ProductWarrantyCoverageType.StandardProductLimitedWarranty]: {
          id: I18nEnum.FirstNYears,
          args: { amount: 10 },
          prompt: I18nEnum.ExcludeTearOffAndDisposalCostsIfRequired,
        },
        [ProductWarrantyCoverageType.SystemProtectionLimitedWarranty]: {
          id: I18nEnum.FirstNYears,
          args: { amount: 50 },
          prompt: I18nEnum.IncludeTearOffAndDisposalCostsIfRequired,
        },
        [ProductWarrantyCoverageType.PreferredProtectionLimitedWarranty]: {
          id: I18nEnum.FirstNYears,
          args: { amount: 50 },
          prompt: I18nEnum.IncludeTearOffAndDisposalCostsIfRequired,
        },
        [ProductWarrantyCoverageType.PlatinumProtectionLimitedWarranty]: {
          id: I18nEnum.FirstNYears,
          args: { amount: 50 },
          prompt: I18nEnum.IncludeTearOffAndDisposalCostsIfRequired,
        },
      },
    },
    {
      label: I18nEnum.MaterialsOnlyCoverage,
      description: I18nEnum.MaterialsOnlyCoverageDescription,
      values: {
        [ProductWarrantyCoverageType.StandardProductLimitedWarranty]: {
          id: I18nEnum.AfterNYears,
          args: { amount: 10 },
        },
        [ProductWarrantyCoverageType.SystemProtectionLimitedWarranty]: {
          id: I18nEnum.AfterNYears,
          args: { amount: 50 },
        },
        [ProductWarrantyCoverageType.PreferredProtectionLimitedWarranty]: {
          id: I18nEnum.AfterNYears,
          args: { amount: 50 },
        },
        [ProductWarrantyCoverageType.PlatinumProtectionLimitedWarranty]: {
          id: I18nEnum.AfterNYears,
          args: { amount: 50 },
        },
      },
    },
    {
      label: I18nEnum.BlowOffCoverage,
      description: I18nEnum.BlowOffCoverageDescription,
      prompt: I18nEnum.AfterThermalSealings,
      values: {
        [ProductWarrantyCoverageType.StandardProductLimitedWarranty]: {
          id: I18nEnum.FirstNYears,
          args: { amount: 15 },
        },
        [ProductWarrantyCoverageType.SystemProtectionLimitedWarranty]: {
          id: I18nEnum.FirstNYears,
          args: { amount: 15 },
        },
        [ProductWarrantyCoverageType.PreferredProtectionLimitedWarranty]: {
          id: I18nEnum.FirstNYears,
          args: { amount: 15 },
        },
        [ProductWarrantyCoverageType.PlatinumProtectionLimitedWarranty]: {
          id: I18nEnum.FirstNYears,
          args: { amount: 15 },
        },
      },
    },
    {
      label: I18nEnum.StreakGuardAlgaeResistance,
      description: I18nEnum.StreakGuardAlgaeResistanceDescription,
      prompt: I18nEnum.TwentyFiveYearAlgaeResistanceCoverageRequires,
      values: {
        [ProductWarrantyCoverageType.StandardProductLimitedWarranty]: {
          id: I18nEnum.FirstNYears,
          args: { amount: 25 },
        },
        [ProductWarrantyCoverageType.SystemProtectionLimitedWarranty]: {
          id: I18nEnum.FirstNYears,
          args: { amount: 25 },
        },
        [ProductWarrantyCoverageType.PreferredProtectionLimitedWarranty]: {
          id: I18nEnum.FirstNYears,
          args: { amount: 25 },
        },
        [ProductWarrantyCoverageType.PlatinumProtectionLimitedWarranty]: {
          id: I18nEnum.FirstNYears,
          args: { amount: 25 },
        },
      },
    },
  ],
  [I18nEnum.WorkmanshipCoverage]: [
    {
      label: I18nEnum.CoveragePeriod,
      description: I18nEnum.CoveragePeriodDescription,
      values: {
        [ProductWarrantyCoverageType.StandardProductLimitedWarranty]: { id: I18nEnum.None },
        [ProductWarrantyCoverageType.SystemProtectionLimitedWarranty]: { id: I18nEnum.None },
        [ProductWarrantyCoverageType.PreferredProtectionLimitedWarranty]: {
          id: I18nEnum.FirstNYears,
          args: { amount: 10 },
        },
        [ProductWarrantyCoverageType.PlatinumProtectionLimitedWarranty]: {
          id: I18nEnum.LifetimeWithCoverageReducedAfter25Years,
        },
      },
    },
  ],
  [I18nEnum.KeyRequirements]: [
    {
      label: I18nEnum.ComponentProducts,
      description: I18nEnum.ComponentProductsDescription,
      values: {
        [ProductWarrantyCoverageType.StandardProductLimitedWarranty]: {
          id: I18nEnum.MinimumOf3OwensCorningComponentsRequiredForTheAdditionalCoveragePeriod,
        },
        [ProductWarrantyCoverageType.SystemProtectionLimitedWarranty]: {
          id: I18nEnum.MinOf3OwensCorningComponentsRequiredHipAndRidgeAndOthers,
        },
        [ProductWarrantyCoverageType.PreferredProtectionLimitedWarranty]: {
          id: I18nEnum.MinOf4OwensCorningComponentsRequiredHipRidgeUnderlaymentAndOthers,
        },
        [ProductWarrantyCoverageType.PlatinumProtectionLimitedWarranty]: {
          id: I18nEnum.MinOf4OwensCorningComponentsRequiredHipRidgeUnderlaymentAndOthers,
        },
      },
    },
    {
      label: I18nEnum.ContractorCertificationRequired,
      description: I18nEnum.ContractorCertificationRequiredDescription,
      values: {
        [ProductWarrantyCoverageType.StandardProductLimitedWarranty]: {
          id: I18nEnum.None,
        },
        [ProductWarrantyCoverageType.SystemProtectionLimitedWarranty]: {
          id: I18nEnum.MustBeInstalledByAnOwensCorningRoofingPlatinumOrPreferredContractor,
        },
        [ProductWarrantyCoverageType.PreferredProtectionLimitedWarranty]: {
          id: I18nEnum.MustBeInstalledByAnOwensCorningRoofingPlatinumOrPreferredContractor,
        },
        [ProductWarrantyCoverageType.PlatinumProtectionLimitedWarranty]: {
          id: I18nEnum.MustBeInstalledByAnOwensCorningRoofingPlatinumOrPreferredContractor,
        },
      },
    },
  ],
};

export const PRODUCT_WARRANTY_PROMPTS: ProductWarrantyPrompts = [
  {
    category: I18nEnum.TRUPROtectionPeriod,
    types: [ProductWarrantyCoverageType.StandardProductLimitedWarranty],
    label: I18nEnum.ExcludeTearOffAndDisposalCostsIfRequired,
  },
  {
    category: I18nEnum.TRUPROtectionPeriod,
    types: [
      ProductWarrantyCoverageType.SystemProtectionLimitedWarranty,
      ProductWarrantyCoverageType.PreferredProtectionLimitedWarranty,
      ProductWarrantyCoverageType.PlatinumProtectionLimitedWarranty,
    ],
    label: I18nEnum.IncludeTearOffAndDisposalCostsIfRequired,
  },
  {
    category: I18nEnum.BlowOffCoverage,
    label: I18nEnum.AfterThermalSealings,
  },
  {
    category: I18nEnum.StreakGuardAlgaeResistance,
    label: I18nEnum.TwentyFiveYearAlgaeResistanceCoverageRequires,
  },
];
