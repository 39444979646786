import { PriceConfig } from './priceConfig';

import { PriceInfo } from 'modules/financing/types';
import { PriceRangeSettings } from 'modules/global/types';
import { PriceRangeObj } from '../types';
import { getPriceRange } from './utils';

export class PriceRange {
  constructor(priceConfig: PriceConfig) {
    this.priceConfig = priceConfig;
  }

  private readonly priceConfig: PriceConfig;

  get shouldAddPriceRange(): boolean {
    const { companyQuoteSettings, product, productPriceSettings } = this.priceConfig;

    const priceRangeEnabledInPriceSettings = productPriceSettings
      ? !!productPriceSettings.priceRangeEnabled
      : true;

    if (!product || !companyQuoteSettings?.priceRangeEnabled || !priceRangeEnabledInPriceSettings) {
      return false;
    }

    if (product.customProduct && product.fixedPrice) {
      return !product.useInstantQuoteSettings && product.priceRangeEnabled;
    }

    return product.useInstantQuoteSettings || product.priceRangeEnabled;
  }

  getPriceRangeSettings(): PriceRangeSettings | null {
    const { companyQuoteSettings, product } = this.priceConfig;

    if (!companyQuoteSettings || !product) {
      return null;
    }

    if (product.useInstantQuoteSettings) {
      return {
        value: companyQuoteSettings.priceRangeValue,
        type: companyQuoteSettings.priceRangeType,
      };
    }

    return product.priceRangeValue === null || product.priceRangeType === null
      ? null
      : {
          value: product.priceRangeValue,
          type: product.priceRangeType,
        };
  }

  getPriceRange(priceInfo: PriceInfo): PriceRangeObj {
    const priceRangeSettings = this.getPriceRangeSettings();

    return getPriceRange({
      priceRangeSettings,
      priceInfo,
      showPriceRange: this.shouldAddPriceRange,
    });
  }
}
