import { useCallback, useState, ReactElement } from 'react';
import styled from 'styled-components';
import { colors } from 'global-constants';

interface Props {
  [key: string]: any;
  children(
    onFocus: (event: React.FocusEvent<HTMLInputElement>) => void,
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
  ): ReactElement;
}

const Label: React.FC<Props> = ({ children, onFocus, onBlur, ...props }) => {
  const [focused, setFocus] = useState(false);

  const _onFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFocus(true);
      onFocus && onFocus(event);
    },
    [onFocus],
  );

  const _onBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFocus(false);
      onBlur && onBlur(event);
    },
    [onBlur],
  );

  return (
    <Root className={props.className}>
      <Placeholder
        className="input-label"
        focused={focused}
        value={props.value}
        hasError={props.hasError}>
        {props.placeholder}
      </Placeholder>
      {children(_onFocus, _onBlur)}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
`;

const Placeholder = styled.span<{
  focused: boolean;
  value?: string;
  hasError?: boolean;
}>`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  color: ${props => (props.focused ? colors.orange : '#666666')};
  background-color: ${colors.white};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  margin-left: 13px;
  z-index: 10;
  padding: 0 5px;
  transition: all 0.1s;
  opacity: 1;

  ${props => props.hasError && `color: ${props.theme.colors.error};`}

  ${props =>
    !props.focused &&
    (!props.value || !props.value.toString().length) &&
    `
    opacity: 0;
    top: 25%;
    font-size: 15px;
`}
`;

export default Label;
