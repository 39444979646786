import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { ChromePicker as ChromePickerBase, ColorChangeHandler, ColorResult } from 'react-color';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

import { I18nEnum } from 'types';
import { RGBAToHex, hexToRGBA, hexPattern } from 'utils';
import { useOnClickOutside } from 'modules/hooks/useOnClickOutside';

interface ColorPickerProps {
  field?: string;
  value?: string;
  label: I18nEnum;
  onlyColor?: boolean;
  className?: string;
  onChange?: (rgbaHex: string, rgbHex: string, field: string) => void;
}

const ColorPicker = ({
  field,
  value = '',
  label,
  onChange = () => {},
  onlyColor,
  className,
}: ColorPickerProps) => {
  const [pickerVisible, setPickerVisibility] = useState(false);
  const [initialColor] = useState(value);
  const [color, setColor] = useState<Pick<ColorResult, 'hex' | 'rgb'>>({
    hex: value,
    rgb: hexToRGBA(value),
  });

  useEffect(() => {
    setColor({
      hex: value,
      rgb: hexToRGBA(value),
    });
  }, [value]);

  const smallHex = useMemo(() => {
    const hexColor = color.hex.replace('#', '').slice(0, 6);

    return hexColor;
  }, [color]);

  const handleChangeColor = useCallback<ColorChangeHandler>(_color => {
    const { r, g, b, a } = _color.rgb;
    const hex = RGBAToHex(r, g, b, a);
    _color.hex = hex;

    setColor(_color);
  }, []);

  const handleChangeComplete = useCallback<ColorChangeHandler>(
    debounce(_color => {
      const rgbHex = _color.hex.slice(0, 7);
      const hex = onlyColor ? _color.hex.slice(0, 7) : _color.hex;

      onChange(hex, rgbHex, field || '');
    }, 1000),
    [onChange, field],
  );

  const togglePicker = useCallback(() => {
    setPickerVisibility(!pickerVisible);
  }, [pickerVisible]);

  const opacity = useMemo(
    () => ((color.rgb.a === undefined ? 1 : color.rgb.a) * 100).toFixed(),
    [color],
  );

  const onInputChangeColor = useCallback(
    event => {
      const value = event.target.value.slice(0, 6);

      if (!/(^[A-Fa-f\d]*$)/gm.test(value)) {
        return;
      }

      const result = hexPattern.exec(color.hex) || [];
      const colorOpacity = result[4] ? result[4] : '';

      const hex = `#${value}${colorOpacity}`;
      const rgb = hexToRGBA(hex);
      const _color = { ...color, hex, rgb } as ColorResult;

      setColor(_color);
      handleChangeComplete(_color, event);
    },
    [color, handleChangeComplete],
  );

  const onBlurColor = useCallback(
    event => {
      const hexOpacity = Math.round(Math.min(Math.max(Number(opacity) / 100 || 1, 0), 1) * 255);
      const _opacity = hexOpacity.toString(16).toUpperCase();

      const value = `#${event.target.value}${_opacity}`;

      const result = hexPattern.exec(value) || [];

      if (!result.length) {
        onInputChangeColor({ target: { value: initialColor.replace('#', '') } });
      }
    },
    [onInputChangeColor, initialColor, opacity],
  );

  const onInputChangeOpacity = useCallback(
    event => {
      const value = event.target.value.slice(0, 3);

      if (isNaN(value) || Number(value) > 100) {
        return;
      }

      color.rgb.a = value / 100;
      const { r, g, b, a } = color.rgb;

      const hex = RGBAToHex(r, g, b, a);
      const _color = { ...color, hex, rgb: color.rgb } as ColorResult;

      setColor(_color);
      handleChangeComplete(_color, event);
    },
    [color, handleChangeComplete],
  );

  const ref = useOnClickOutside(togglePicker);

  return (
    <Root className={className}>
      <Label>
        <FormattedMessage id={label} />
      </Label>
      <ColorWrapper>
        <Color onClick={togglePicker} color={color.hex} />
        <Hex max={6} onChange={onInputChangeColor} onBlur={onBlurColor} value={smallHex} />
        {!onlyColor && (
          <>
            <Divider />
            <Opacity onChange={onInputChangeOpacity} value={opacity} />%
          </>
        )}
      </ColorWrapper>
      {pickerVisible && (
        <div ref={ref}>
          <ChromePicker
            className="fade-in-roofle-200ms"
            onChangeComplete={handleChangeComplete}
            onChange={handleChangeColor}
            color={color.rgb}
            disableAlpha={onlyColor}
          />
        </div>
      )}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
`;

export const Label = styled.span`
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
`;

export const ColorWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 8px 8px 8px 10px;
  border: 1px solid ${props => props.theme.colors.grey};
  border-radius: 6px;
  transition: border-color 0.2s linear;

  &:focus-within {
    border: 1px solid ${props => props.theme.colors.orange};
  }
`;

const Color = styled.div<{ color: string }>`
  width: 24px;
  height: 24px;
  background-color: ${props => props.color};
  margin-right: 10px;
  box-shadow: 0 0 0 1px ${props => props.theme.colors.lightGrey};
  cursor: pointer;
`;

const ChromePicker = styled(ChromePickerBase)`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
`;

const ColorPickerInput = styled.input`
  border: 0;
`;

const Hex = styled(ColorPickerInput)`
  width: 70px;
  text-transform: uppercase;
`;

const Opacity = styled(ColorPickerInput)`
  width: 45px;
  padding-left: 10px;
`;

export const Divider = styled.div`
  margin-left: 10px;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 40px;
    width: 1px;
    top: -20px;
    background-color: ${props => props.theme.colors.grey};
  }
`;

export default memo(ColorPicker);
