import { Dispatch, SetStateAction } from 'react';

import { SET_USER, SET_USER_ERROR, CLEAR_USER } from './constants';
import { ThunkResult, Routes, I18nEnum } from 'types';
import { actions as messageActions, MessageTypes } from 'modules/message';
import { services } from 'modules/auth';
import { actions as spinnerActions } from 'modules/spinner';
import { removeUserFromLocal } from './storage';
import { SignInLocalUserData } from './types';
import { getUserFromTokenPayload } from './utils';
// import { selectors, actions as emailActions, constants as emailConstants } from 'modules/email';
import { actions as modalActions, ModalTypes } from 'modules/modal';
// import { getCookies } from 'utils';

export const _setUser = (user: any) => ({
  type: SET_USER,
  user,
});

export const _setUserError = (error: any) => ({
  type: SET_USER_ERROR,
  error,
});

export const _clearUser = () => ({
  type: CLEAR_USER,
});

export const setUser =
  (user: any): ThunkResult<void> =>
  dispatch => {
    dispatch(_setUser(user));
  };

export const clearUser = (): ThunkResult<void> => dispatch => {
  removeUserFromLocal();
  dispatch(_clearUser());
};

export const forgotPassword =
  (email: string): ThunkResult<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(spinnerActions.show());

      await services.forgotPassword(email);
      dispatch(_setUser({ email }));
    } catch (error) {
      if (error.code === 404) {
        dispatch(_setUser({ error }));
      } else {
        dispatch(
          modalActions.openModal(ModalTypes.error, {
            action: forgotPassword,
            args: [email],
            error: error.message,
            requestBody: email,
          }),
        );
      }
    } finally {
      dispatch(spinnerActions.hide());
    }
  };

export const resendConfirmation =
  (
    pathname: string,
    userIdOrEmail: string | number,
    byEmail?: boolean,
  ): ThunkResult<Promise<void>> =>
  async dispatch => {
    const isReset =
      pathname !== Routes.confirmEmail && pathname !== Routes.signup && pathname !== Routes.signin;
    try {
      dispatch(spinnerActions.show());
      if (!isReset) {
        byEmail
          ? await services.resendConfirmationEmail(userIdOrEmail as string)
          : await services.resendConfirmationEmailById(userIdOrEmail);
      } else {
        byEmail
          ? await services.resendResetPassword(userIdOrEmail as string)
          : await services.resendResetPasswordById(userIdOrEmail);
      }

      dispatch(spinnerActions.hide());
      dispatch(
        messageActions.openMessage(
          MessageTypes.success,
          isReset ? I18nEnum.YourPasswordResetLinkHasBeen : I18nEnum.YourConfirmationEmailHasBeen,
        ),
      );
    } catch (err) {
      dispatch(spinnerActions.hide());
      dispatch(
        messageActions.openMessage(
          MessageTypes.error,
          isReset
            ? I18nEnum.UnfortunatelyThePasswordResetLink
            : I18nEnum.UnfortunatelyTheConfirmationEmail,
        ),
      );
    }
  };

export const resetPassword =
  (
    password: string,
    userId: string | number,
    resetPasswordCode: string,
  ): ThunkResult<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(spinnerActions.show());

      await services.resetPassword({
        password,
        userId,
        resetPasswordCode,
      });

      dispatch(_setUser({ success: true }));
    } catch (error) {
      if (error.code === 400) {
        dispatch(_setUser({ error }));
      } else {
        dispatch(
          modalActions.openModal(ModalTypes.error, {
            action: resetPassword,
            args: [password, userId, resetPasswordCode],
            error: error.message,
            requestBody: {
              password,
              userId,
              resetPasswordCode,
            },
          }),
        );
      }
    } finally {
      dispatch(spinnerActions.hide());
    }
  };

// export const signUpLocal =
//   (
//     userBody: SignUpLocalUserData,
//     setIsSendConfirmation?: Dispatch<SetStateAction<boolean>>,
//   ): ThunkResult<Promise<void>> =>
//   async (dispatch, getState) => {
//     try {
//       const { affiliateId, affiliateLink } = getAffiliateInfoFromCookie();
//       dispatch(spinnerActions.show());

//       await services.signUpLocal({ ...userBody, affiliateId, affiliateLink });
//       setIsSendConfirmation && setIsSendConfirmation(true);
//       dispatch(_setUser({ email: userBody.email }));

//       const prevEmailDetails = selectors.selectEmailDetails(getState());
//       const details = {
//         ...prevEmailDetails,
//         firstName: userBody.firstName,
//         lastName: userBody.lastName,
//         phone: userBody.phone,
//         email: userBody.email,
//       };

//       dispatch(emailActions.setFields(details));
//       localStorage.setItem(emailConstants.STORAGE_EMAIL_PATH, JSON.stringify(details));

//       dispatch(spinnerActions.hide());
//     } catch (error) {
//       console.log(error);
//       dispatch(spinnerActions.hide());
//       if (error.code === 403) {
//         dispatch(_setUser({ error }));
//       } else {
//         dispatch(
//           modalActions.openModal(ModalTypes.error, {
//             action: signUpLocal,
//             args: [userBody, setIsSendConfirmation],
//             error: error.message,
//           }),
//         );
//       }
//       await Promise.reject(error);
//     }
//   };

export const signInLocal =
  (
    userBody: SignInLocalUserData,
    setIsSendConfirmation?: Dispatch<SetStateAction<boolean>>,
  ): ThunkResult<Promise<any>> =>
  async dispatch => {
    try {
      dispatch(spinnerActions.show());

      const payload = await services.signInLocal(userBody);
      const user = getUserFromTokenPayload(payload.token);

      await dispatch(setUser(user));
      dispatch(spinnerActions.hide());
      return {
        user,
        payload: payload.data,
      };
    } catch (error) {
      console.log(error);

      if (error.code === 400 || error.code === 417) {
        dispatch(_setUserError(error));
      } else if (error.code === 403) {
        setIsSendConfirmation && setIsSendConfirmation(true);
        dispatch(_setUser({ email: userBody.email, error }));
      } else {
        dispatch(
          modalActions.openModal(ModalTypes.error, {
            action: signInLocal,
            args: [userBody, setIsSendConfirmation],
            error: error.message,
            requestBody: { ...userBody, password: undefined },
          }),
        );
      }
      dispatch(spinnerActions.hide());
      await Promise.reject(error);
    }
  };

// export const signInSocial =
//   (userBody: SignInSocialsUserData): ThunkResult<Promise<void>> =>
//   async dispatch => {
//     try {
//       const { affiliateId, affiliateLink } = getAffiliateInfoFromCookie();
//       dispatch(spinnerActions.show());

//       const payload = await services.signInSocial({ ...userBody, affiliateId, affiliateLink });
//       const user = getUserFromTokenPayload(payload.token);

//       dispatch(setUser(user));
//       dispatch(spinnerActions.hide());
//       return user;
//     } catch (error) {
//       console.log(error);
//       dispatch(
//         modalActions.openModal(ModalTypes.error, {
//           action: signInSocial,
//           args: [userBody],
//           error: error.message,
//         }),
//       );
//       dispatch(spinnerActions.hide());
//       await Promise.reject(error);
//     }
//   };

export const updateToken =
  (userId: string | number): ThunkResult<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(spinnerActions.show());

      const payload = await services.updateToken(userId);
      const user = getUserFromTokenPayload(payload.token);

      dispatch(setUser(user));
      dispatch(spinnerActions.hide());
      dispatch(modalActions.closeModal());
    } catch (err) {
      console.log(err);
      dispatch(spinnerActions.hide());
    }
  };

// export const recoverAccount =
//   (userBody: SignInLocalUserData): ThunkResult<Promise<void>> =>
//   async dispatch => {
//     try {
//       dispatch(spinnerActions.show());

//       const { token } = await services.recoverAccountApi(userBody);
//       const user = getUserFromTokenPayload(token);

//       dispatch(setUser(user));
//       dispatch(spinnerActions.hide());
//     } catch (error) {
//       console.log(error);

//       if (error.code === 400) {
//         dispatch(_setUser({ error }));
//       } else {
//         dispatch(
//           modalActions.openModal(ModalTypes.error, {
//             action: recoverAccount,
//             args: [userBody],
//             error: error.message,
//           }),
//         );
//       }
//       dispatch(spinnerActions.hide());
//     }
//   };

export const verifyApprovalCode =
  (userId: number, approvalCode: string): ThunkResult<Promise<void>> =>
  async dispatch => {
    try {
      dispatch(spinnerActions.show());

      await services.verifyApprovalCodeAPI(userId, approvalCode);
    } catch (error) {
      throw error;
    } finally {
      dispatch(spinnerActions.hide());
    }
  };
