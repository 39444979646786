export const googleMapId = 'googleMap';

export const SET_GOOGLE_MAPS_IS_READY = 'google/SET_GOOGLE_MAPS_IS_READY';
export const SET_GOOGLE_MAPS_IS_EDITING = 'google/SET_GOOGLE_MAPS_IS_EDITING';
export const SET_GOOGLE_MAPS_HELP_TEXT = 'google/SET_GOOGLE_MAPS_HELP_TEXT';
export const SET_GOOGLE_MAPS_IS_ADDING_POLYGONS = 'google/SET_GOOGLE_MAPS_IS_ADDING_POLYGONS';
export const SET_GOOGLE_MAPS_IS_DELETING_POLYGONS = 'google/SET_GOOGLE_MAPS_IS_DELETING_POLYGONS';
export const SET_GOOGLE_MAPS_IS_POLYGON_SELECTED = 'google/SET_GOOGLE_MAPS_IS_POLYGON_SELECTED';
export const SET_GOOGLE_MAPS_NO_SELECTION_HELP_TEXT =
  'google/SET_GOOGLE_MAPS_NO_SELECTION_HELP_TEXT';
export const SET_GOOGLE_MAPS_VERTEX_HELP_TEXT = 'google/SET_GOOGLE_MAPS_VERTEX_HELP_TEXT';
export const SET_GOOGLE_MAPS_POLYGON_HELP_TEXT = 'google/SET_GOOGLE_MAPS_POLYGON_HELP_TEXT';
export const SET_GOOGLE_MAPS_DRAW_POLYGON_CLICK_COUNT =
  'google/SET_GOOGLE_MAPS_DRAW_POLYGON_CLICK_COUNT';
export const CLEAR_GOOGLE_MAPS = 'google/CLEAR';
