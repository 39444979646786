import { ComponentType, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { I18nEnum } from 'types';
import IconRestorerBase from 'components/Icons/IconRestorer';
import Tooltip, { TooltipContainer, ITooltipProps } from './Tooltip';

const TooltipWithInfoIcon = ({
  tooltip,
  icon,
  className,
  values,
  onClick,
  trigger = 'hover',
  placement = 'top',
  _ContainerComponent = TooltipContainer,
  floatingPortalRoot,
}: {
  tooltip: string | JSX.Element;
  icon?: JSX.Element;
  className?: string;
  values?: any;
  onClick?: (e: any) => void;
  _ContainerComponent?: ComponentType;
  floatingPortalRoot?: HTMLElement | null;
} & Pick<ITooltipProps, 'placement' | 'trigger'>) => {
  const isLocaleString = useMemo(
    () => typeof tooltip === 'string' && Object.keys(I18nEnum).includes(tooltip),
    [tooltip],
  );
  const _onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onClick && onClick(e);
    },
    [onClick],
  );

  return (
    <Root className={`info-root ${className}`} onClick={_onClick}>
      <Tooltip
        floatingPortalRoot={floatingPortalRoot}
        isShouldShowOnMobile={true}
        tooltip={
          isLocaleString ? (
            <FormattedMessage
              id={tooltip as string}
              values={{ hiddenLink: (chunks: string) => chunks, ...values }}
            />
          ) : (
            tooltip
          )
        }
        trigger={trigger}
        placement={placement}
        ContainerComponent={_ContainerComponent || TooltipContainer}>
        <IconRestorer className="info-icon">
          {icon ? icon : <img src="/images/svg/info-icon.svg" loading="lazy" />}
        </IconRestorer>
      </Tooltip>
    </Root>
  );
};

const Root = styled.span`
  display: inline-flex;
  position: relative;
  cursor: pointer;
  vertical-align: middle;

  &:hover {
    .info-tooltip {
      display: block;
    }
    img {
      opacity: 0.8;
    }
  }
  .info-icon {
    height: 18px;
    width: 18px;
    img {
      opacity: 0.6;
    }
  }
`;

const IconRestorer = styled(IconRestorerBase)`
  img {
    opacity: 0.6;
  }
`;

export default TooltipWithInfoIcon;
