import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { BaseSelectRef } from 'rc-select';
import isEqual from 'lodash/isEqual';
import Children from 'react-children-utilities';

import { I18nEnum } from 'types';
import GhostButton from 'components/Button/GhostButton';
import { NodeDetailsMap, OptionType, SelectProps } from './types';
import { deselectNode, selectNode } from './tree-data.helper';
import uniq from 'lodash/uniq';

type UseDropdownRendererProps = Pick<SelectProps, 'mode' | 'onChange' | 'value'> & {
  internalMultipleSelectValue: SelectProps['value'];
  setOpened: (opened: boolean) => void;
};

export const useDropdownRenderer = ({
  mode,
  onChange,
  value,
  internalMultipleSelectValue,
  setOpened,
}: UseDropdownRendererProps) => {
  return useCallback(
    (menu: React.ReactElement) => {
      if (mode !== 'multiple') {
        return menu;
      }

      return (
        <>
          {menu}
          <UseItemsButtonContainer>
            <UseItemsButton
              title={
                <>
                  <ShareImg src="/images/dashboard/share-icon.svg" width="20px" />
                  <FormattedMessage id={I18nEnum.Apply} />
                </>
              }
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();

                if (onChange && !isEqual(value, internalMultipleSelectValue)) {
                  onChange(internalMultipleSelectValue);
                }

                setOpened(false);
              }}
            />
          </UseItemsButtonContainer>
        </>
      );
    },
    [mode, onChange, value, internalMultipleSelectValue, setOpened],
  );
};

const UseItemsButton = styled(GhostButton)`
  width: 100%;
`;

const ShareImg = styled.img`
  filter: ${props => props.theme.colorFilters.blue};
`;

const UseItemsButtonContainer = styled.div`
  padding: 0 16px 6px;
  width: 100%;
`;

export const useFilterOption = experimentalSearch => {
  return useCallback(
    (inputValue: string, option: any) => {
      if (typeof option.label === 'string') {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
      }

      if (!experimentalSearch) {
        // kindly see getLabel function, it can return FormattedMessage
        return false;
      }

      const text = Children.onlyText(option.label);
      return !!text && text.toLowerCase().includes(inputValue.toLowerCase());
    },
    [experimentalSearch],
  );
};

type UseOnDropdownVisibleChange = Pick<
  SelectProps,
  'onDropdownVisibleChange' | 'onFocus' | 'open'
> & {
  selectRef: React.RefObject<BaseSelectRef>;
};
export const useDropdownVisibility = ({
  onDropdownVisibleChange,
  onFocus,
  selectRef,
  open,
}: UseOnDropdownVisibleChange) => {
  const [opened, setOpened] = useState(false);
  const _onDropdownVisibleChange = useCallback(
    (isOpened: boolean) => {
      setOpened(isOpened);

      onDropdownVisibleChange && onDropdownVisibleChange(isOpened);
      if (!(selectRef && selectRef.current)) {
        return;
      }

      if (!isOpened) {
        selectRef.current.blur();
      } else {
        onFocus && onFocus({} as any);
      }
    },
    [setOpened, onDropdownVisibleChange, selectRef, onFocus],
  );

  useEffect(() => {
    if (open !== undefined) {
      _onDropdownVisibleChange(open);
    }
  }, [open, _onDropdownVisibleChange]);

  return { _onDropdownVisibleChange, opened, setOpened };
};

type UseMultipleTreeSelectInternalValueProps = Pick<SelectProps, 'value' | 'mode'> & {
  opened: boolean;
  nodeDetailsMap: NodeDetailsMap<SelectProps['value']>;
};
export const useMultipleTreeSelectInternalValue = ({
  value,
  mode,
  opened,
  nodeDetailsMap,
}: UseMultipleTreeSelectInternalValueProps) => {
  const [internalMultipleSelectValue, setInternalMultipleSelectValue] = useState(value);

  useEffect(() => {
    if (mode === 'multiple') {
      setInternalMultipleSelectValue(currInternalMultipleSelectValue => {
        return !isEqual(value, currInternalMultipleSelectValue)
          ? value
          : currInternalMultipleSelectValue;
      });
    }
    //   Don't remove opened. It triggers a values sync when a user leaves dropdown without clicking the Apply
  }, [mode, value, opened]);

  const onSelect = useCallback(
    _value => {
      if (mode !== 'multiple') {
        return;
      }

      const newValues = selectNode(nodeDetailsMap, _value, internalMultipleSelectValue || []);
      setInternalMultipleSelectValue(newValues);
    },
    [mode, nodeDetailsMap, internalMultipleSelectValue],
  );
  const onDeselect = useCallback(
    _value => {
      if (mode !== 'multiple') {
        return;
      }

      const newValues = deselectNode(nodeDetailsMap, _value, internalMultipleSelectValue || []);
      setInternalMultipleSelectValue(newValues);
    },
    [mode, nodeDetailsMap, internalMultipleSelectValue],
  );

  return {
    internalMultipleSelectValue,
    onSelect,
    onDeselect,
  };
};

type UseMultipleSelectInternalValueProps = Pick<SelectProps, 'value' | 'mode'> & {
  opened: boolean;
  options: OptionType[];
};
export const useMultipleSelectInternalValue = ({
  value,
  mode,
  opened,
  options,
}: UseMultipleSelectInternalValueProps) => {
  const [internalMultipleSelectValue, setInternalMultipleSelectValue] = useState(value);

  useEffect(() => {
    if (mode === 'multiple') {
      setInternalMultipleSelectValue(currInternalMultipleSelectValue => {
        return !isEqual(value, currInternalMultipleSelectValue)
          ? value
          : currInternalMultipleSelectValue;
      });
    }
    //   Don't remove opened. It triggers a values sync when a user leaves dropdown without clicking the Apply
  }, [mode, value, opened]);

  const onSelect = useCallback(
    _value => {
      if (mode !== 'multiple') {
        return;
      }

      const optionsWithoutDisabled = options.filter(item => !item.disabled);
      let newValues;
      if (_value === 'all') {
        newValues = optionsWithoutDisabled.map(item => item.value);
      } else {
        newValues = internalMultipleSelectValue
          ? [...internalMultipleSelectValue, _value]
          : [_value];
        if (newValues.length === optionsWithoutDisabled.length - 1) {
          newValues.push('all');
        }
      }

      setInternalMultipleSelectValue(newValues);
    },
    [mode, setInternalMultipleSelectValue, options, internalMultipleSelectValue],
  );
  const onDeselect = useCallback(
    _value => {
      if (mode !== 'multiple') {
        return;
      }

      let newValues = [];

      if (_value !== 'all') {
        newValues = internalMultipleSelectValue.filter(item => item !== _value && item !== 'all');
      }

      setInternalMultipleSelectValue(newValues);

      return newValues;
    },
    [mode, setInternalMultipleSelectValue, internalMultipleSelectValue],
  );

  return {
    internalMultipleSelectValue,
    onSelect,
    onDeselect,
  };
};

interface UseExpandedTreeOptionsProps {
  options: OptionType[];
  nodeDetailsMap: NodeDetailsMap<SelectProps['value']>;
  treeDefaultExpandAll: boolean;
}
export const useExpandedTreeOptions = ({
  options,
  nodeDetailsMap,
  treeDefaultExpandAll,
}: UseExpandedTreeOptionsProps) => {
  const [expandedOptionsValues, setExpandedOptionsValues] = useState<
    Record<string | number, boolean>
  >({});
  const expandedOptions = useMemo(() => {
    const hidden = Object.keys(expandedOptionsValues).reduce((acc: (string | number)[], key) => {
      if (!expandedOptionsValues[key] && !nodeDetailsMap[key].leaf) {
        acc.push(...nodeDetailsMap[key].childrenListId);
      }
      return acc;
    }, []);
    const uniqueHidden = uniq(hidden);
    return uniqueHidden.length
      ? options.filter(option => !uniqueHidden.includes(option.value))
      : options;
  }, [expandedOptionsValues, options, nodeDetailsMap]);

  useEffect(() => {
    const newExpandedOptionsValues = options.reduce((acc, option) => {
      if (!nodeDetailsMap[option.value].leaf) {
        acc[option.value] = treeDefaultExpandAll;
      }

      return acc;
    }, {});
    setExpandedOptionsValues(newExpandedOptionsValues);
  }, [treeDefaultExpandAll]);

  const handleToggleExpand = useCallback(_value => {
    setExpandedOptionsValues(currExpanded => ({
      ...currExpanded,
      [_value]: !currExpanded[_value],
    }));
  }, []);

  return { expandedOptions, expandedOptionsValues, handleToggleExpand };
};
