import { State } from 'types';

export const selectSearch = (state: State) => state.search;

export const selectSearchResults = (state: State) => selectSearch(state).results;
export const selectAddress = (state: State) => selectSearch(state).address;
export const selectAddressFeature = (state: State) => selectSearch(state).addressFeature;

export const selectGeoDataRegister = (state: State) => selectSearch(state).geoDataRegister;
export const selectGoogleAutocompleteToken = (state: State) =>
  selectSearch(state).googleAutocompleteToken;

export const selectErrors = (state: State) => ({
  hasError: selectSearch(state).hasError,
  errorMessage: selectSearch(state).errorMessage,
});
