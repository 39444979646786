import { Company } from 'modules/company/types';
import { BrandingConfig, WidgetSettings } from 'modules/widget/types';
import { transformToFrontendSettings } from 'modules/widget/utils/transformSettings';
import { getSubscriptionConfig } from 'modules/subscription/utils';
import { SubscriptionConfig } from 'modules/subscription/types';

const API_URL = process.env.REACT_APP_API_URL;

export const getWidgetConfig = async (
  _wildcard: string,
): Promise<{
  brandingConfig: BrandingConfig;
  company: Company;
  subscriptionConfig: SubscriptionConfig;
}> => {
  const configResponse = await fetch(`${API_URL}widget/${_wildcard}/settings`);

  const { data: _config }: { data: WidgetSettings & { company: Company } } =
    await configResponse.json();

  if (!(_config && Object.values(_config).length !== 0)) {
    if (configResponse.statusText === 'Not Found') {
      throw new Error(`RoofQuote PRO™ widget not found. Check the widget ID.`);
    }

    if (configResponse.statusText === 'Forbidden') {
      throw new Error(`RoofQuote PRO™ widget is not allowed. Check the allowed domains.`);
    }

    throw new Error(configResponse.statusText);
  }

  const config = getSubscriptionConfig(_config.company.subscriptions);
  const brandingConfig = transformToFrontendSettings(
    config.defaultBranding,
    _config,
    config.subscriptionType,
  );
  return { brandingConfig, company: _config.company, subscriptionConfig: config };
};
