import { widgetId } from '../constants';
import { sleep } from './utils';
import { toggleRoofQuotePro } from '../QuickQuoteSlideOut';
import {
  BrandingConfig,
  CalloutImg,
  CalloutSideBar,
  HorisontalAligment,
  ToolCalloutClosingStrategyLiteral,
} from '../types';

const APP_URL = process.env.REACT_APP_APP_URL;
const CALLOUT_ID = 'quick-quote-callout';
const CALLOUT_TEXT_BLOCK_ID = 'quick-quote-callout-text-block';
const CALLOUT_IMAGE_ID = 'quick-quote-callout-image';
const CALLOUT_CLOSE_ICON_ID = 'quick-quote-callout-close-icon';
const calloutVisibleSessionName = `${widgetId}-callout-visible`;

const toggleCalloutSection = (
  element: HTMLElement,
  {
    open,
    closeClass,
    openClass,
  }: {
    open: boolean;
    closeClass: string;
    openClass: string;
  },
) => {
  if (open) {
    element.classList.remove(closeClass);
    element.classList.add(openClass);
  } else {
    element.classList.replace(openClass, closeClass);
  }
};

const isCalloutVisible = (): boolean => {
  try {
    // Safari when cookies are disabled confiders this operation as insecure
    const calloutVisible = sessionStorage.getItem(calloutVisibleSessionName);
    return !calloutVisible || !!JSON.parse(calloutVisible);
  } catch (e) {
    return true;
  }
};

const removeCloseCalloutIcon = (): void => {
  const closeIcons = document.getElementById(CALLOUT_CLOSE_ICON_ID);

  if (closeIcons) {
    closeIcons.remove();
  }
};

export const toggleCallout = ({
  open,
  closingStrategy,
}: {
  open: boolean;
  closingStrategy: ToolCalloutClosingStrategyLiteral;
}): void => {
  const calloutVisible = isCalloutVisible();

  if (!calloutVisible) {
    return;
  }

  const callout = document.getElementById(CALLOUT_ID);
  const calloutText = document.getElementById(CALLOUT_TEXT_BLOCK_ID);

  if (!callout) {
    return;
  }

  if (closingStrategy === 'text-only' && calloutText) {
    toggleCalloutSection(calloutText, {
      open,
      openClass: 'quick-quote-callout-text-block_open',
      closeClass: 'quick-quote-callout-text-block_close',
    });

    if (!open) {
      removeCloseCalloutIcon();
    }

    return;
  }

  if (closingStrategy === 'full') {
    toggleCalloutSection(callout, {
      open,
      openClass: 'quick-quote-callout_open',
      closeClass: 'quick-quote-callout_close',
    });
    return;
  }
};

export const setCalloutVisibilityExpirationDate = () => {
  try {
    // Safari when cookies are disabled confiders this operation as insecure
    sessionStorage.setItem(calloutVisibleSessionName, 'false');
  } catch {}
};

const handleCloseCallout =
  (closingStrategy: ToolCalloutClosingStrategyLiteral) => (event: MouseEvent) => {
    event.stopPropagation();
    toggleCallout({ open: false, closingStrategy });
    setCalloutVisibilityExpirationDate();
  };

const handleOpenWidget =
  (closingStrategy: ToolCalloutClosingStrategyLiteral) => (event: MouseEvent) => {
    handleCloseCallout(closingStrategy)(event);
    toggleRoofQuotePro();
  };

const createCloseButton = (
  closingStrategy: ToolCalloutClosingStrategyLiteral,
  horizontalPosition: HorisontalAligment,
): HTMLButtonElement => {
  const closeCalloutIcon = document.createElement('img');
  closeCalloutIcon.src = `${APP_URL}images/svg/close-icon.svg`;
  closeCalloutIcon.classList.add('quick-quote-close-icon');

  const closeCalloutButton = document.createElement('button');
  closeCalloutButton.id = CALLOUT_CLOSE_ICON_ID;
  closeCalloutButton.classList.add('quick-quote-close');

  if (closingStrategy === 'text-only') {
    closeCalloutButton.classList.add('quick-quote-callout-close-partially');
  }
  closeCalloutButton.style.top = '10px';

  if (horizontalPosition === 'right') {
    closeCalloutButton.style.left = '10px';
  } else {
    closeCalloutButton.style.right = '10px';
  }

  closeCalloutButton.appendChild(closeCalloutIcon);
  closeCalloutButton.onclick = handleCloseCallout(closingStrategy);

  return closeCalloutButton;
};

const createSupportingImage = (imageSettings: CalloutImg): HTMLImageElement => {
  const img = document.createElement('img');
  img.id = CALLOUT_IMAGE_ID;
  img.src = `${APP_URL}${imageSettings.name}`;
  img.style.height = `${imageSettings.height}px`;
  img.style.minHeight = `${imageSettings.height}px`;
  img.style.maxHeight = `${imageSettings.height}px`;
  img.style.width = `${imageSettings.width}px`;
  img.style.minWidth = `${imageSettings.width}px`;
  img.style.maxWidth = `${imageSettings.width}px`;
  img.style.margin = imageSettings.margin;
  img.style.alignSelf = 'center';
  img.classList.add('quick-quote-callout-image');
  return img;
};

const createSideBar = (settings: CalloutSideBar): HTMLImageElement => {
  const img = document.createElement('img');
  img.src = `${APP_URL}${settings.imageName}`;
  img.style.width = `${settings.imageWidth}px`;
  img.style.minWidth = `${settings.imageWidth}px`;
  img.style.maxWidth = `${settings.imageWidth}px`;
  img.style.height = `${settings.imageHeight}px`;
  img.style.minHeight = `${settings.imageHeight}px`;
  img.style.maxHeight = `${settings.imageHeight}px`;
  img.style.pointerEvents = 'all';

  if (settings.class) {
    img.classList.add(settings.class);
  }

  return img;
};

const createTextBlock = (closingStrategy: ToolCalloutClosingStrategyLiteral): HTMLDivElement => {
  const calloutTextBlock = document.createElement('div');
  const calloutTitle = document.createElement('div');
  const calloutText = document.createElement('div');
  calloutTextBlock.id = CALLOUT_TEXT_BLOCK_ID;
  calloutTextBlock.classList.add('quick-quote-callout-text-block');

  if (closingStrategy === 'text-only') {
    calloutTextBlock.classList.add('closable');
  }

  calloutTitle.classList.add('quick-quote-callout-title');
  calloutText.classList.add('quick-quote-callout-text');
  calloutTextBlock.appendChild(calloutTitle);
  calloutTextBlock.appendChild(calloutText);
  return calloutTextBlock;
};

export const setupCallout = async (
  parent: HTMLDivElement,
  calloutSettings: BrandingConfig['callout'],
  horizontalPosition: HorisontalAligment,
) => {
  if (!isCalloutVisible() && calloutSettings.closingStrategy === 'full') {
    return;
  }

  await sleep(1000);

  const calloutContainer = document.createElement('div');
  calloutContainer.classList.add(
    'quick-quote-callout',
    `quick-quote-callout-${horizontalPosition}`,
    ...calloutSettings.customCalloutClasses,
  );
  calloutContainer.id = CALLOUT_ID;
  calloutContainer.onclick = handleOpenWidget(calloutSettings.closingStrategy);
  calloutContainer.appendChild(createTextBlock(calloutSettings.closingStrategy));

  if (isCalloutVisible()) {
    calloutContainer.appendChild(
      createCloseButton(calloutSettings.closingStrategy, horizontalPosition),
    );
  }

  if (calloutSettings.img) {
    calloutContainer.appendChild(createSupportingImage(calloutSettings.img));
  }

  if (calloutSettings.sideBar) {
    const sideBar = createSideBar(calloutSettings.sideBar);
    sideBar.onclick = handleOpenWidget(calloutSettings.closingStrategy);
    calloutContainer.appendChild(sideBar);
  }

  parent.appendChild(calloutContainer);

  toggleCallout({ open: true, closingStrategy: calloutSettings.closingStrategy });
};
