/**
 * While importing new files in script and files script dependent on,
 * please be sure that you don't import extra dependencies to script build
 * https://github.com/Roofing-Made-Simple/roofle-contractor-web/pull/2069
 */
import WidgetPostMessages from './utils/widgetPostMessages';
import { setupQueryParamsBase } from './utils/setupQueryParamsBase';
import { initEnv } from './utils/initEnv';
import { getAddressFields, getPercent, getScrollLength, createSpinner } from './utils/utils';
import { setupRoofQuoteButton } from './widget/button';
import { setCalloutVisibilityExpirationDate, setupCallout, toggleCallout } from './widget/callout';
import { qqCalloutTitleFontWeight, setupStyles } from './widget/styles';
import { getWidgetConfig } from './widget/getWidgetConfig';
import { widgetId } from 'modules/widget/constants/toolScript';
import { BrandingConfig, ToolCalloutParentLiteral } from 'modules/widget/types/toolScript';
import { FontSettings } from 'modules/fonts/types';
import { renderElementAfterFontsLoaded, setupRQPFonts } from 'modules/fonts/utils';

export const quickQuoteButtonWrapperId = 'quick-quote-button-wrapper';

let isOpened = false;
let shouldOpen = false;
let wildcard = '';
let isPreview = false;
let isAutoTests = false;
let config: BrandingConfig;
let userInfo: Record<string, string>;
let windowWidth: number;
let oldWrapperTransition: string;
let firstTachShift = 0;

declare global {
  interface Window {
    RoofQuotePro: {
      open?: () => void;
      close?: () => void;
      toggle?: () => void;
    };
  }
}

const setupEvents = (
  root: HTMLElement,
  quickQuoteWrapper: HTMLDivElement,
  mask: HTMLDivElement,
) => {
  const removeMaskVisibility = () => {
    if (root.classList.contains('quick-quote-closed')) {
      mask.style.display = 'none';
    }
  };
  const addMaskVisibility = () => {
    if (root.classList.contains('quick-quote-opened')) {
      mask.style.display = 'block';
    }
  };

  quickQuoteWrapper.addEventListener('transitionend', removeMaskVisibility);
  quickQuoteWrapper.addEventListener('transitionstart', addMaskVisibility);
};

const setupSliderFonts = (element: HTMLDivElement, branding: BrandingConfig): void => {
  const quickQuoteButtonFont: FontSettings = {
    fontName: branding.roofQuoteProButton.font,
    fontWeight: branding.roofQuoteProButton.fontWeight,
  };
  const calloutTitleFont: FontSettings = {
    fontName: branding.callout.titleFont,
    fontWeight: qqCalloutTitleFontWeight,
  };
  const calloutDescriptionFont: FontSettings = {
    fontName: branding.callout.descriptionTextFont,
  };
  const fontSettings = [quickQuoteButtonFont, calloutTitleFont, calloutDescriptionFont];

  element.style.display = 'none';
  const fonts = setupRQPFonts(fontSettings);
  renderElementAfterFontsLoaded(element, fonts);
};

const getSliderTemplate = (
  root: HTMLElement,
  body: HTMLElement | DocumentFragment,
  params: { showSpinner: boolean },
) => {
  root.classList.add('quick-quote-root');
  root.id = widgetId;

  const { roofQuoteProButton, callout } = config;

  const fragment = document.createDocumentFragment();
  const mask = document.createElement('div');
  const quickQuoteWrapper = document.createElement('div');
  const quickQuoteButtonWrapper = document.createElement('div');
  const quickQuoteButton = document.createElement('div');
  const closeWidgetIcon = document.createElement('img');
  const closeWidgetButton = document.createElement('button');
  const dragHandle = document.createElement('div');
  const dragHandleInner = document.createElement('div');
  const calloutParentMap: Record<ToolCalloutParentLiteral, HTMLDivElement> = {
    wrapper: quickQuoteButtonWrapper,
    button: quickQuoteButton,
  };
  const calloutParent = calloutParentMap[callout.parent] || quickQuoteButtonWrapper;

  if (params.showSpinner) {
    const spinnerContainer = createSpinner();
    quickQuoteWrapper.appendChild(spinnerContainer);
  }

  mask.classList.add('quick-quote-mask');
  quickQuoteWrapper.classList.add('quick-quote-wrapper');
  quickQuoteWrapper.id = 'quick-quote-wrapper';

  quickQuoteButtonWrapper.id = quickQuoteButtonWrapperId;
  quickQuoteButtonWrapper.classList.add('quick-quote-button-wrapper');

  setupSliderFonts(quickQuoteButtonWrapper, config);

  quickQuoteButton.classList.add('quick-quote-button', ...roofQuoteProButton.customButtonClasses);
  quickQuoteButton.id = 'quick-quote-button';
  closeWidgetIcon.src = `${window.__RUNTIME_CONFIG__.REACT_APP_APP_URL}images/svg/close-icon.svg`;
  closeWidgetIcon.classList.add('quick-quote-close-icon');
  closeWidgetButton.classList.add('quick-quote-close');
  closeWidgetButton.setAttribute(
    'style',
    'background-color: transparent; padding: 0; border: none; outline: none;',
  );
  dragHandle.classList.add('quick-quote-drag-handle');
  dragHandleInner.classList.add('quick-quote-drag-handle-inner');

  quickQuoteButton.onclick = toggleRoofQuotePro;
  mask.onclick = toggleRoofQuotePro;
  closeWidgetButton.onclick = closeRoofQuotePro;
  dragHandle.ontouchstart = (e: TouchEvent) => dragHandleTouchStart(e, quickQuoteWrapper);
  dragHandle.ontouchmove = (e: TouchEvent) => dragHandleTouchMove(e, quickQuoteWrapper);
  dragHandle.ontouchend = (e: TouchEvent) => dragHandleTouchEnd(e, quickQuoteWrapper);

  setupEvents(root, quickQuoteWrapper, mask);
  setupCallout(calloutParent, callout, roofQuoteProButton.horisontalPosition);

  closeWidgetButton.appendChild(closeWidgetIcon);
  quickQuoteWrapper.appendChild(closeWidgetButton);

  quickQuoteWrapper.appendChild(body);
  quickQuoteButtonWrapper.appendChild(quickQuoteButton);
  setupRoofQuoteButton(
    quickQuoteButton,
    config.roofQuoteProButton.text,
    config.roofQuoteProButton.textClass,
  );

  dragHandle.appendChild(dragHandleInner);
  quickQuoteWrapper.appendChild(quickQuoteButtonWrapper);
  quickQuoteWrapper.appendChild(dragHandle);
  fragment.appendChild(mask);
  fragment.appendChild(quickQuoteWrapper);

  root.replaceChildren(fragment);

  return { root, fragment, mask, quickQuoteButton, quickQuoteWrapper };
};

const dragHandleTouchStart = (e: TouchEvent, quickQuoteWrapper: HTMLDivElement) => {
  e.preventDefault();
  windowWidth = document.documentElement.clientWidth;
  oldWrapperTransition = getComputedStyle(quickQuoteWrapper).getPropertyValue('transition');
  quickQuoteWrapper.style.transition = 'transform 0s linear';
  firstTachShift = e.touches[0].pageX;
};

const dragHandleTouchMove = (e: TouchEvent, quickQuoteWrapper: HTMLDivElement) => {
  e.preventDefault();
  const direction = config.roofQuoteProButton.horisontalPosition;
  const scrollPageX = e.touches[0].pageX;

  const sliderDirectionCoefficient = direction === 'right' ? 1 : -1;
  const scrollLength = getScrollLength({ direction, scrollPageX, firstTachShift });
  const percent = getPercent(scrollLength, windowWidth);
  const translateX = (percent < 0 ? 0 : percent > 100 ? 100 : percent) * sliderDirectionCoefficient;

  quickQuoteWrapper.style.transform = `translateX(${translateX}%)`;
};

const dragHandleTouchEnd = (e: TouchEvent, quickQuoteWrapper: HTMLDivElement) => {
  e.preventDefault();
  const direction = config.roofQuoteProButton.horisontalPosition;
  const scrollPageX = e.changedTouches[0].pageX;

  quickQuoteWrapper.style.transition = oldWrapperTransition;
  const scrollLength = getScrollLength({ direction, scrollPageX, firstTachShift });
  const isShouldClose = scrollLength / windowWidth >= 0.5;
  firstTachShift = 0;

  isShouldClose ? closeRoofQuotePro() : openRoofQuotePro();
};

const setupQuickQuoteDOM = (root: HTMLElement) => {
  const iframe = document.createElement('iframe');

  iframe.classList.add('quick-quote-frame');
  iframe.id = 'quick-quote-frame';

  getSliderTemplate(root, iframe, { showSpinner: true });

  document.body.appendChild(root);

  if (shouldOpen) {
    toggleRoofQuotePro();
  }

  if (window) {
    window.RoofQuotePro.open = openRoofQuotePro;
    window.RoofQuotePro.close = closeRoofQuotePro;
    window.RoofQuotePro.toggle = toggleRoofQuotePro;
  }
};

const setupQueryParams = () => {
  const { urlObj, wildcardId } = setupQueryParamsBase(widgetId);

  const _isOpened = urlObj.searchParams.has('open');
  const _isPreview = urlObj.searchParams.has('isPreview');
  const _isAutoTests = urlObj.searchParams.has('auto');

  wildcard = wildcardId;
  shouldOpen = _isOpened;
  isPreview = _isPreview;
  isAutoTests = _isAutoTests;

  if (getAddressFields(document.location.href)) {
    shouldOpen = true;
  }

  return { wildcard, isOpened };
};

const init = async () => {
  if (window) {
    window.RoofQuotePro = {};
  }
  await initEnv(widgetId);

  const widgetParent = document.createElement('div');

  let root = document.getElementById(widgetId);

  if (!root) {
    const widgetRoot = document.createElement('div');

    widgetParent.id = widgetId;
    root = widgetRoot;
  }

  root.id = widgetId;

  const { wildcard: _wildcard } = setupQueryParams();
  const { brandingConfig, company } = await getWidgetConfig(_wildcard);
  config = brandingConfig;
  userInfo = { companyName: company.name };
  setupStyles(brandingConfig);

  setupQuickQuoteDOM(root);
};

function closeRoofQuotePro() {
  const root = document.getElementById(widgetId);
  const quickQuoteWrapper = document.getElementById('quick-quote-wrapper');
  const body = document.body;

  if (!(root && quickQuoteWrapper)) {
    return null;
  }

  const direction = config.roofQuoteProButton.horisontalPosition === 'left' ? `-100%` : `100%`;

  quickQuoteWrapper.style.transform = `translateX(${direction})`;
  root.classList.remove('quick-quote-opened');
  root.classList.add('quick-quote-closed');
  body.style.overflow = '';
}

function openRoofQuotePro() {
  const root = document.getElementById(widgetId);
  const quickQuoteWrapper = document.getElementById('quick-quote-wrapper');
  const iframe = document.getElementById('quick-quote-frame') as HTMLIFrameElement;
  const body = document.body;

  if (!(root && quickQuoteWrapper)) {
    return null;
  }

  quickQuoteWrapper.style.transform = `translateX(0%)`;
  root.classList.remove('quick-quote-closed');
  root.classList.add('quick-quote-opened');
  body.style.overflow = 'hidden';
  const widgetPostMessages = new WidgetPostMessages({
    isPreview,
    userInfo,
  });
  widgetPostMessages.observeLocationChange();

  const widgetURLObj = new URL(
    `${window.__RUNTIME_CONFIG__.REACT_APP_APP_URL}roof-quote-pro/${wildcard}`,
  );
  isAutoTests && widgetURLObj.searchParams.set('auto', 'true');

  if (iframe) {
    if (!iframe.src) {
      iframe.setAttribute('src', widgetURLObj.href);
    }
  }

  toggleCallout({ open: false, closingStrategy: config.callout.closingStrategy });
  setCalloutVisibilityExpirationDate();
}

function toggleRoofQuotePro() {
  if (isOpened) {
    closeRoofQuotePro();
  } else {
    openRoofQuotePro();
  }

  isOpened = !isOpened;
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  init();
} else {
  document.addEventListener('DOMContentLoaded', init);
}

export { toggleRoofQuotePro };
