import { I18nEnum } from 'types';
import { Subscription } from 'modules/subscription/types';
import {
  DEFAULT_SUBSCRIPTION_CONFIG,
  SUBSCRIPTION_CONFIG_MAP,
} from 'modules/subscription/constants';

export const getSubscriptionRequestTranslationId = (subscription: Subscription): string => {
  if (subscription.cancelationRequested) {
    return I18nEnum.Pause;
  }
  if (subscription.activationRequested) {
    return I18nEnum.Activation;
  }
  return '';
};

export const getSubscriptionConfig = (subscriptions: Subscription[]) => {
  const subscriptionType = subscriptions?.[0]?.type;
  const subscriptionCountry = subscriptions?.[0].country;
  return (
    SUBSCRIPTION_CONFIG_MAP[`${subscriptionType}_${subscriptionCountry}`] ||
    DEFAULT_SUBSCRIPTION_CONFIG
  );
};
