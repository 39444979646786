import { I18nEnum } from 'types';
import { ProfileSettingsConfig } from './types';

const name = 'company/';

export const SET_COMPANY = `${name}SET_COMPANY`;
export const SET_COMPANY_USERS = `${name}SET_COMPANY_USERS`;
export const SET_COMPANY_USERS_QUERY = `${name}SET_COMPANY_USERS_QUERY`;
export const SET_COMPANY_QUOTE_SETTINGS = `${name}SET_COMPANY_QUOTE_SETTINGS`;
export const CLEAR_COMPANY_QUOTE_SETTINGS = `${name}CLEAR_COMPANY_QUOTE_SETTINGS`;
export const CLEAR_COMPANY = `${name}CLEAR_COMPANY`;

export const COMPANY_KEY = `${name}COMPANY`;
export const COMPANY_SMTP_SERVER = `${name}COMPANY_SMTP_SERVER`;
export const USER_OPTIONS_KEY = `${name}USER_OPTIONS_KEY`;

export const DEFAULT_PROFILE_SETTINGS: ProfileSettingsConfig = {
  productionRepUserAccessInfo: I18nEnum.ProductionRepsAccess,
  salesRepUserAccessInfo: I18nEnum.SalesRepUsersAccess,
};

export const PROFILE_SETTINGS_WITHOUT_CLP: ProfileSettingsConfig = {
  productionRepUserAccessInfo: I18nEnum.ProductionRepsAccessExcludingClp,
  salesRepUserAccessInfo: I18nEnum.SalesRepUsersHaveAccessExcludingClp,
};
