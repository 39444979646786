import { intl } from 'intl';
import { FormInstance } from 'antd';

import { I18nEnum, PartialBy, ThunkResult } from 'types';
import { constants, services } from '.';
import {
  BrandingConfig,
  Products,
  ThankYouMediaTypeEnum,
  WidgetSettings,
  WidgetSettingsState,
} from './types';
import { selectors as userSelectors } from 'modules/auth';
import { selectWidgetSettings } from './selectors';
import { transformToBackendSettings, transformToFrontendSettings } from './utils/transformSettings';
import { actions as modalActions, ModalTypes } from 'modules/modal';
import { actions as spinnerActions } from 'modules/spinner';
import { actions as financingActions } from 'modules/financing';
import { getUserMarkets, _setSelectedMarket } from 'modules/markets/actions';
import { SupportRequestedType } from 'modules/email/types';
import { getCompany, setCompany } from 'modules/company/actions';
import * as quickQuoteSelectors from 'modules/quickQuote/selectors';
import * as marketSelectors from 'modules/markets/selectors';
import * as subscriptionSelectors from 'modules/subscription/selectors';
import { getCompanyLabels } from 'modules/product/actions';

export const setWidgetSettings = (settings: Partial<BrandingConfig>) => ({
  type: constants.SET_WIDGET_SETTINGS,
  settings,
});

export const setAllWidgetSettings = (settings: WidgetSettingsState) => ({
  type: constants.SET_ALL_WIDGET_SETTINGS,
  settings,
});

const resetWidgetSettings = (defaultBranding: BrandingConfig) => ({
  type: constants.RESET_WIDGET_SETTINGS,
  defaultBranding,
});

export const setWidgetProducts = (products: Products) => ({
  type: constants.SET_WIDGET_PRODUCTS,
  products,
});

export const setWildcard = (wildcard: string) => ({
  type: constants.SET_WIDGET_WILDCARD,
  wildcard,
});

export const setIframePreview = (iframePreview: boolean) => ({
  type: constants.SET_WIDGET_IFRAME_PREVIEW,
  iframePreview,
});

export const setExternalUrl = (externalUrl: string) => ({
  type: constants.SET_WIDGET_EXTERNAL_URL,
  externalUrl,
});

export const setUserInfo = (userInfo: Record<string, string | number>) => ({
  type: constants.SET_WIDGET_USER_INFO,
  userInfo,
});

export const setMapSettingsPlaceholderMedia = (placeholderMedia: string) => ({
  type: constants.SET_MAP_PLACEHOLDER_MEDIA,
  placeholderMedia,
});

export const clearMapSettingsMedia = () => ({
  type: constants.CLEAR_MAP_SETTINGS_MEDIA,
});

export const clearBranding = (defaultBranding: BrandingConfig) => ({
  type: constants.CLEAR_BRANDING,
  defaultBranding,
});

export const getUserWidgetSettings =
  (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    const user = userSelectors.selectUser(getState());

    const { data: branding }: { data: WidgetSettings } = await services.getWidgetSettingsAPI(
      user.id,
    );

    //it is important get subscription config after await request
    //otherwise we get default subscription config in most of the cases because we get this info before setSubscriptionConfig action
    //or use getSubscriptionConfig function
    const { defaultBranding, subscriptionType } = subscriptionSelectors.selectSubscriptionConfig(
      getState(),
    );

    const transformedBranding = transformToFrontendSettings(
      defaultBranding,
      branding || {},
      subscriptionType,
    );

    dispatch(setCompany(branding.company));
    dispatch(
      setAllWidgetSettings({
        branding: transformedBranding,
        rawBranding: branding,
        loaded: true,
        companyId: branding.company.id,
        wildcard: branding.wildcard,
        domains: branding.domains,
      }),
    );
  };

export const getWidgetBranding =
  (wildcard?: string): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    dispatch(spinnerActions.show());

    if (!wildcard) {
      dispatch(spinnerActions.hide());
      throw new Error('Company id is not provided!');
    }

    const { data: branding }: { data: WidgetSettings } = await services.getPublicWidgetSettingsAPI(
      wildcard,
    );

    //it is important get subscription config after await request
    //otherwise we get default subscription config in most of the cases because we get this info before setSubscriptionConfig action
    //or use getSubscriptionConfig function
    const { defaultBranding, subscriptionType } = subscriptionSelectors.selectSubscriptionConfig(
      getState(),
    );

    const transformedBranding = transformToFrontendSettings(
      defaultBranding,
      branding,
      subscriptionType,
    );

    dispatch(setCompany(branding.company));

    dispatch(
      setAllWidgetSettings({
        branding: transformedBranding,
        loaded: true,
        companyId: branding.company.id,
        wildcard: branding.wildcard,
        domains: branding.domains,
      }),
    );
    dispatch(spinnerActions.hide());
  };

export const updateWidgetSettings =
  (widgetSettings: Partial<WidgetSettings> = {}): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      const user = userSelectors.selectUser(getState());

      if (!!Object.values(widgetSettings).length) {
        dispatch(spinnerActions.show());

        await services.updateWidgetSettingsAPI(user.id, widgetSettings);

        dispatch(getUserWidgetSettings());
        dispatch(
          modalActions.openModal(ModalTypes.widgetBrandingSaved, {
            subtitle: I18nEnum.YourChangesHaveBeenSavedSuccessfully,
            description: intl.formatMessage({
              id: I18nEnum.AllYourSettingsConfigurationHaveBeenAppliedToTheWidget,
            }),
          }),
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        modalActions.openModal(ModalTypes.error, {
          action: updateWidgetSettings,
          args: [{ widgetSettings }],
          error: error.message,
          requestBody: widgetSettings,
          type: SupportRequestedType.updateWidgetSettings,
          title: I18nEnum.UnfortunatelyYourChangesHaveNotBeen,
        }),
      );
    }
    dispatch(spinnerActions.hide());
  };

export const updateBrandingConfig =
  (brandingConfig: BrandingConfig): ThunkResult<void> =>
  (dispatch, getState) => {
    const subscriptionConfig = subscriptionSelectors.selectSubscriptionConfig(getState());
    const widgetSettings = transformToBackendSettings(
      brandingConfig,
      subscriptionConfig.widgetSettings,
    );
    dispatch(updateWidgetSettings(widgetSettings));
  };

export const resetSettingsToDefault =
  (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    const subscriptionConfig = subscriptionSelectors.selectSubscriptionConfig(getState());
    dispatch(resetWidgetSettings(subscriptionConfig.defaultBranding));
    dispatch(modalActions.closeModal());
  };

export const clearSettingsToDefault =
  (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    const subscriptionConfig = subscriptionSelectors.selectSubscriptionConfig(getState());
    dispatch(clearBranding(subscriptionConfig.defaultBranding));
  };

export const getWidgetProducts =
  (marketSlug: string): ThunkResult<Promise<Products>> =>
  async (dispatch, getState) => {
    dispatch(spinnerActions.show());
    const user = userSelectors.selectUser(getState());

    const { data } = await services.getWidgetProductsAPI(user.id, marketSlug);

    dispatch(setWidgetProducts(data));
    dispatch(spinnerActions.hide());
    return data;
  };

export const getPublicWidgetProducts =
  (wildcard?: string): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    if (!wildcard) {
      throw new Error('Company id is not provided!');
    }

    try {
      const state = getState();
      const quickQuote = quickQuoteSelectors.selectQuickQuote(state);
      const markets = marketSelectors.selectMarkets(state);

      if (!quickQuote.marketSlug) {
        throw new Error(`Market wasn't found!`);
      }

      const { data }: { data: Products } = await services.getPublicWidgetProducts(
        wildcard,
        quickQuote.marketSlug,
      );

      dispatch(setWidgetProducts(data));

      await dispatch(financingActions.getQuickQuoteMerchantOfferCodes());
      await dispatch(financingActions.getQuickQuoteMerchantRateSheets());

      const selectedMarket = markets.find(item => item.market.slug === quickQuote.marketSlug);
      selectedMarket && dispatch(_setSelectedMarket(selectedMarket));
    } catch (error) {}
  };

export const resetToolsSettings = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const user = userSelectors.selectUser(getState());

  try {
    dispatch(spinnerActions.show());
    const { data }: { data: WidgetSettings } = await services.resetToolsSettingsAPI(user.id);

    dispatch(modalActions.closeModal());
    dispatch(setWildcard(data.wildcard));
  } catch (error) {
    // TODO: add error modal if needed
  }
  dispatch(spinnerActions.hide());
};

export const uploadThankYouScreenMedia =
  (
    data: FormData,
    fileType: ThankYouMediaTypeEnum,
    {
      onUploadProgress,
      onComplete,
    }: { onUploadProgress: (progressEvent: ProgressEvent) => void; onComplete?: () => void },
  ): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const user = userSelectors.selectUser(getState());
    const { thankYou } = selectWidgetSettings(getState());

    const { data: status } = await services.uploadThankYouScreenMediaAPI(
      user.id,
      data,
      fileType,
      onUploadProgress,
    );

    onComplete && onComplete();

    dispatch(
      setWidgetSettings({
        thankYou: { ...thankYou, thankYouMediaProcessingStatus: status },
      }),
    );
  };

export const getThankYouMedia =
  (form: FormInstance<BrandingConfig>): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const user = userSelectors.selectUser(getState());
    const thankYou = form.getFieldValue(['thankYou']);

    try {
      const {
        data,
      }: {
        data: PartialBy<
          WidgetSettings,
          'thankYouMedia' | 'thankYouMediaType' | 'thankYouMediaProcessingStatus'
        >;
      } = await services.getThankYouMediaAPI(user.id);

      form.setFieldsValue({ thankYou: { ...thankYou, ...data } });
      dispatch(setWidgetSettings(form.getFieldsValue(true)));
    } catch (error) {}
  };

export const getWidgetSettingsInitialState = (): ThunkResult<Promise<void>> => async dispatch => {
  dispatch(spinnerActions.show());

  await Promise.all([
    dispatch(getCompanyLabels()),
    dispatch(getUserMarkets()),
    dispatch(getCompany()),
  ]);

  dispatch(spinnerActions.hide());
};
