import React, { useCallback, useState, ReactElement, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Affix as AffixBase } from 'antd';
import { FormattedMessage } from 'react-intl';
import isEqual from 'lodash/isEqual';

import ButtonBase from 'components/Button/Button';
import GhostButton from 'components/Button/GhostButton';
import { I18nEnum, PartialBy } from 'types';
import { TabKeys, selectors } from 'modules/widget';
import useDeepCompareEffect from 'modules/hooks/useDeepCompareEffect';
import { _validateDomain } from 'validators';
import { Line, ColoredLine, hasDuplicates, ProductImage } from 'modules/product';
import { _clearSnippetsToInsert } from 'modules/snippet/actions';
import { DeveloperTabKeys } from 'modules/developer/types';
import { MarketsSettingsTabKeys } from 'modules/markets';
import { useTrackButtonClickAction } from 'modules/userAction';

type ProductType = {
  productCode: string;
  warranty: string;
  topFeatures: string;
  productLabelId: number;
  price: number;
  whatsIncluded: string;
  additionalCosts: string;
  order: number;
  coloredLines: ColoredLine[];
  productImages: ProductImage[] | PartialBy<ProductImage, 'id'>[];
  fixedPrice: boolean;
  showTotal: boolean;
  showMonthly: boolean;
};

export type ProductFields = keyof ProductType;

type ProductsType = {
  [key: string]: Partial<ProductType>;
} & { buttonText?: string };

export type ProductDifferenceType = ProductsType;

export type Errors = {
  labelError?: string;
  errorLabels: number[];
  wildcardError?: boolean;
  domainsError?: boolean;
};

export type ToolsDifferenceType = {
  wildcard?: string;
  domains: string[];
};

export type ToolsDifferenceFields = keyof ToolsDifferenceType;

export type ServiceAreaType = {
  includedStates?: string[];
  statesWithinRadius?: string[];
  radius?: number;
  isDisabled?: boolean;
  centerpoint?: [number, number];
};

export type ServiceAreasType = {
  [key: string]: ServiceAreaType;
};

export type NationalServiceAreaDifferenceType = {
  [id: number]: boolean;
};

export type ServiceAreaFields = keyof ServiceAreaType;

const defaultDifference: {
  products: ProductDifferenceType;
  orderedProduct: Line[];
  tools: ToolsDifferenceType;
  serviceArea: ServiceAreasType;
  nationalServiceArea: NationalServiceAreaDifferenceType;
} = {
  products: {},
  tools: { domains: [] },
  serviceArea: {},
  orderedProduct: [],
  nationalServiceArea: {},
};

export type ContextTypeValues = typeof defaultDifference;
export type ContextStateKeys = keyof ContextTypeValues;

export type ContextActionsType = {
  changeProductFields: (
    lineId: number | 'buttonText',
    field: ProductFields | string,
    value: string | number | boolean | ColoredLine[],
  ) => void;
  changeOrderedProducts: (products: Line[]) => void;
  changeProductImages: (lineId: number, productImages: PartialBy<ProductImage, 'id'>[]) => void;
  changeToolsFields: (field: ToolsDifferenceFields, value: string | string[]) => void;
  cleanChanges: () => void;
  changeServiceAreaFields: (
    marketSlug: string,
    field: ServiceAreaFields,
    value?: number | string[] | boolean | [number, number],
  ) => void;
  changeNationalServiceAreaFields: (id: number, value: boolean) => void;
};

type ContextType = ContextTypeValues & ContextActionsType & { errors: Errors };

export const ControlSettingsDifferenceContext = React.createContext<ContextType>({
  ...defaultDifference,
  changeProductFields: () => {},
  changeOrderedProducts: () => {},
  changeProductImages: () => {},
  changeToolsFields: () => {},
  changeServiceAreaFields: () => {},
  changeNationalServiceAreaFields: () => {},
  cleanChanges: () => {},
  errors: {
    errorLabels: [],
  },
});

ControlSettingsDifferenceContext.displayName = 'ControlSettingsDifferenceContext';

interface ControlSettinsProps {
  onSave?: (settingsState: ContextTypeValues) => void;
  onCancelEdit?: () => void;
  onResetSettings?: (callback?: () => void) => void;
  children(isEditMode: boolean): ReactElement;
  control?: TabKeys | DeveloperTabKeys | MarketsSettingsTabKeys;
}

const defaultEditModeTabs = [
  MarketsSettingsTabKeys.ServiceArea,
  MarketsSettingsTabKeys.NationalCoverage,
  DeveloperTabKeys.ToolScripts,
] as (TabKeys | MarketsSettingsTabKeys | DeveloperTabKeys)[];
type DefaultEditModeTabsType = typeof defaultEditModeTabs[number];

const ControlSettings: React.FC<ControlSettinsProps> = ({
  onSave,
  onCancelEdit,
  onResetSettings,
  children,
  control,
}) => {
  const dispatch = useDispatch();
  const trackButtonClickAction = useTrackButtonClickAction();
  const [isEditMode, setIsEditMode] = useState(!!control && defaultEditModeTabs.includes(control));
  const [settingsState, setSettingsState] = useState<
    ContextTypeValues & Partial<ContextActionsType>
  >(defaultDifference);
  const [errors, setErrors] = useState<Errors>({
    errorLabels: [],
  });

  const products = useSelector(selectors.selectWidgetProducts);
  const { wildcard, domains } = useSelector(selectors.selectWidget);

  const cleanChanges = useCallback(() => {
    let state = defaultDifference;

    if (control === TabKeys.ProductCustomizationTab) {
      dispatch(_clearSnippetsToInsert());
      state = { ...defaultDifference, products: {} };
    }

    if (control === DeveloperTabKeys.ToolScripts) {
      state = { ...defaultDifference, tools: { wildcard, domains } };
    }

    if (control === MarketsSettingsTabKeys.ServiceArea) {
      state = { ...defaultDifference, serviceArea: {} };
    }

    if (!defaultEditModeTabs.includes(control as DefaultEditModeTabsType)) {
      setIsEditMode(false);
    }

    setSettingsState(state);
  }, [dispatch, control, wildcard, domains]);

  const handleSave = useCallback(async () => {
    onSave && (await onSave(settingsState));

    cleanChanges();
    trackButtonClickAction(I18nEnum.SaveChanges, control);
  }, [onSave, cleanChanges, settingsState, trackButtonClickAction, control]);

  const handleCancelEdit = useCallback(() => {
    onCancelEdit && onCancelEdit();
    cleanChanges();
  }, [onCancelEdit, cleanChanges]);

  const onResetSettingsCallback = useCallback(() => {
    let state = defaultDifference;

    if (control === TabKeys.ProductCustomizationTab) {
      state = {
        ...defaultDifference,
        products: {} as ProductDifferenceType,
      };
    }

    if (control === DeveloperTabKeys.ToolScripts) {
      state = {
        ...defaultDifference,
        tools: { domains: [] },
      };
    }

    setSettingsState(state);
  }, [control]);

  const handleResetSettings = useCallback(() => {
    onResetSettings && onResetSettings(onResetSettingsCallback);
    trackButtonClickAction(I18nEnum.ResetSettingsToDefault, control);
  }, [onResetSettings, onResetSettingsCallback, trackButtonClickAction, control]);

  const handleEdit = useCallback(() => {
    setIsEditMode(true);
    trackButtonClickAction(I18nEnum.Edit, control);
  }, [trackButtonClickAction, control]);

  const changeProductFields = useCallback(
    (
      lineId: number | 'buttonText',
      field: ProductFields | string,
      value: string | number | boolean | ColoredLine[],
    ) => {
      setSettingsState(state => ({
        ...state,
        products: {
          ...state.products,
          [lineId]:
            lineId !== field
              ? { ...(state.products[lineId as number] || {}), [field]: value }
              : value,
        } as ProductDifferenceType,
      }));
    },
    [],
  );

  const changeOrderedProducts = useCallback((products: Line[]) => {
    setSettingsState(state => ({
      ...state,
      orderedProduct: products,
    }));
  }, []);

  const changeProductImages = useCallback(
    (lineId: number, productImages: PartialBy<ProductImage, 'id'>[]) => {
      setSettingsState(state => ({
        ...state,
        products: {
          ...state.products,
          [lineId]: { ...(state.products[lineId as number] || {}), productImages },
        } as ProductDifferenceType,
      }));
    },
    [],
  );

  const changeToolsFields = useCallback(
    (field: ToolsDifferenceFields, value: string | string[]) => {
      setSettingsState(state => ({
        ...settingsState,
        tools: {
          ...state.tools,
          [field]: value,
        },
      }));
    },
    [settingsState],
  );

  const changeServiceAreaFields = useCallback(
    (
      marketSlug: string,
      field: ServiceAreaFields,
      value?: number | string[] | boolean | [number, number],
    ) => {
      setSettingsState(state => ({
        ...state,
        serviceArea: {
          ...state.serviceArea,
          [marketSlug]: {
            ...(state.serviceArea[marketSlug] || {}),
            [field]: value,
          },
        },
      }));
    },
    [],
  );

  const changeNationalServiceAreaFields = useCallback((stateId: number, status: boolean) => {
    setSettingsState(state => ({
      ...state,
      nationalServiceArea: {
        ...state.nationalServiceArea,
        [stateId]: status,
      },
    }));
  }, []);

  const _hasDuplicates = useMemo(
    () =>
      control === TabKeys.ProductCustomizationTab &&
      !!(products && products.find(item => hasDuplicates(item, products, settingsState.products))),
    [products, settingsState, control],
  );

  const isSaveButtonDisabled = useMemo(() => {
    if (control === TabKeys.ProductCustomizationTab) {
      if (!Object.keys(settingsState.products).length || errors.labelError || _hasDuplicates) {
        return true;
      }
    }

    if (control === DeveloperTabKeys.ToolScripts) {
      if (
        isEqual(settingsState.tools.domains, domains) ||
        errors.wildcardError ||
        errors.domainsError
      ) {
        return true;
      }
    }

    if (control === MarketsSettingsTabKeys.ServiceArea) {
      if (!Object.keys(settingsState.serviceArea).length) {
        return true;
      }
    }

    if (
      control === MarketsSettingsTabKeys.NationalCoverage &&
      !Object.keys(settingsState.nationalServiceArea).length
    ) {
      return true;
    }

    return false;
  }, [control, settingsState, errors, _hasDuplicates, domains]);

  useDeepCompareEffect(() => {
    const { whatsIncluded, additionalCosts, ..._products } = settingsState.products;

    if (!products) {
      return;
    }

    const productLabelsWithModifications = products.map(
      product =>
        (_products[product.id] && _products[product.id].productLabelId !== undefined
          ? _products[product.id].productLabelId
          : product.productLabel?.id) || 0,
    ) as number[];

    const requiredLabels = productLabelsWithModifications.filter(label => label !== 0);

    const labelCounts = requiredLabels.reduce((accum, label) => {
      accum[label] = (accum[label] || 0) + 1;

      return accum;
    }, {});

    const errorLabels = Object.keys(labelCounts)
      .filter(label => labelCounts[label] > 1)
      .map(item => +item);

    setErrors(prevErrors => ({
      ...prevErrors,
      labelError: !errorLabels.length ? undefined : I18nEnum.LabelError,
      errorLabels,
    }));
  }, [settingsState.products, products]);

  useEffect(() => {
    setErrors(errorState => ({
      ...errorState,
      domainsError:
        !settingsState.tools.domains.length ||
        settingsState.tools.domains.some(domain => !_validateDomain(domain)),
    }));
  }, [settingsState.tools]);

  const isEditShow = useMemo(
    () => !defaultEditModeTabs.includes(control as DefaultEditModeTabsType),
    [control],
  );
  const isCancelShow = useMemo(
    () =>
      (control !== MarketsSettingsTabKeys.ServiceArea &&
        control !== MarketsSettingsTabKeys.NationalCoverage) ||
      !!Object.keys(settingsState.serviceArea).length ||
      !!Object.keys(settingsState.nationalServiceArea).length,
    [control, settingsState.serviceArea, settingsState.nationalServiceArea],
  );
  const isResetShow = useMemo(() => control !== DeveloperTabKeys.ToolScripts, [control]);

  return (
    <ControlSettingsDifferenceContext.Provider
      value={{
        ...settingsState,
        changeProductFields,
        changeOrderedProducts,
        changeProductImages,
        changeToolsFields,
        changeServiceAreaFields,
        changeNationalServiceAreaFields,
        cleanChanges,
        errors,
      }}>
      {children(isEditMode)}
      <Affix key={products?.length} offsetBottom={0}>
        <Wrapper hasDuplicates={_hasDuplicates && isEditMode}>
          <ResetSettings
            onClick={handleResetSettings}
            title={I18nEnum.ResetSettingsToDefault}
            hide={!isResetShow}
          />
          <ButtonsWrapper hasDuplicates={_hasDuplicates && isEditMode}>
            {isEditMode ? (
              <>
                {isCancelShow && (
                  <Button onClick={handleCancelEdit} type="default" title={I18nEnum.Cancel} />
                )}

                <Button
                  type="primary"
                  onClick={handleSave}
                  title={I18nEnum.SaveChanges}
                  disabled={isSaveButtonDisabled}
                />
                {_hasDuplicates && (
                  <Error>
                    <FormattedMessage id={I18nEnum.WarninigYouCantSave} />
                  </Error>
                )}
              </>
            ) : (
              <>
                {isEditShow && <Button type="primary" onClick={handleEdit} title={I18nEnum.Edit} />}
              </>
            )}
          </ButtonsWrapper>
        </Wrapper>
      </Affix>
    </ControlSettingsDifferenceContext.Provider>
  );
};

const AffixCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-top: 1px solid ${props => props.theme.colors.lightGrey};
  height: 95px;
  width: 100%;
  box-shadow: 0px 10px 0px 10px ${props => props.theme.colors.white};
  z-index: 101;

  @media ${props => props.theme.mediaQueries.medium} {
    height: auto;
  }
`;

const Affix = styled(AffixBase)`
  .ant-affix,
  & > div {
    ${AffixCSS}
  }
`;

const Wrapper = styled.div<{ hasDuplicates: boolean }>`
  ${AffixCSS}
  ${props => props.hasDuplicates && 'height: 125px;'}
  
  @media ${props => props.theme.mediaQueries.medium} {
    flex-direction: column-reverse;
    justify-content: center;
  }

  @media ${props => props.theme.mediaQueries.small} {
    padding: 16px 0;
  }
`;

const ButtonsWrapper = styled.div<{ hasDuplicates: boolean }>`
  display: flex;
  column-gap: 16px;
  position: relative;
  ${props => props.hasDuplicates && 'padding-bottom: 30px;'}

  @media ${props => props.theme.mediaQueries.medium} {
    width: 100%;
    row-gap: 8px;
    flex-direction: column-reverse;
    margin-top: 8px;
  }

  @media ${props => props.theme.mediaQueries.medium} {
    margin-top: 0;
  }
`;

const Button = styled(ButtonBase)`
  height: 40px;
  font-size: 14px;
  line-height: 17px;

  ${props =>
    props.type !== 'ghost' &&
    `
      width: 150px;
      min-width: 150px;
  `}

  ${props =>
    props.type === 'default' &&
    `
    width: auto;
  `}

  @media ${props => props.theme.mediaQueries.medium} {
    width: 100%;
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    ${props =>
      props.type === 'ghost' &&
      `
      height: 18px;
      margin: 20px 0;
    `}
  }
`;

const ResetSettings = styled(GhostButton)<{ hide?: boolean }>`
  ${props => props.hide && 'visibility: hidden;'}
  @media ${props => props.theme.mediaQueries.medium} {
    margin: 8px 0;
    width: 100%;
    ${props => props.hide && 'height: 0px; margin: 0;'}
  }

  @media ${props => props.theme.mediaQueries.medium} {
    margin-bottom: 0;
  }
`;

const Error = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: ${props => props.theme.colors.error};
  position: absolute;
  bottom: 11px;
  right: 0;
  white-space: nowrap;

  @media ${props => props.theme.mediaQueries.medium} {
    text-align: center;
    bottom: -3px;
    right: 50%;
    transform: translateX(50%);
    width: 231px;
    white-space: initial;
  }
`;

export default ControlSettings;
