import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from 'antd';
import styled, { css } from 'styled-components';

import { I18nEnum } from 'types';
import TableBase from 'components/Table';
import SelectBase from 'components/Inputs/Select';
import TextEditor, { TextEditorProps } from 'components/Inputs/TextEditor';
import { fontSizes, fonts, fontsWeightForRQPButton } from './constants';
import { TextAreaWithCounter } from 'modules/product/components/UpsertProduct/Elements';
import { ColorWrapper } from 'components/ColorPicker/ColorPicker';
import { RadiusContainer } from './CornerRadiusSetting';

interface BrandingSectionProps {
  children: React.ReactNode;
  settingName?: I18nEnum;
  settingDescription?: I18nEnum;
  className?: string;
}

interface FontProps {
  className?: string;
  label: I18nEnum;
  onChange?: (value: string | number) => void;
  value?: string | number;
  options?: { label: string; value: string | number }[];
}

export const BrandingSection: React.FC<BrandingSectionProps> = ({
  settingName,
  settingDescription,
  children,
  className,
}) => {
  return (
    <BrandingSettingsSection>
      {settingName && (
        <SettingName>
          <FormattedMessage id={settingName} />
        </SettingName>
      )}
      {settingDescription && (
        <SettingDescription>
          <FormattedMessage id={settingDescription} />
        </SettingDescription>
      )}
      <SettingsWrapper className={className}>{children}</SettingsWrapper>
    </BrandingSettingsSection>
  );
};

export const FontSelect: React.FC<FontProps> = ({
  className,
  label,
  onChange,
  value,
  options = fonts,
}) => {
  return (
    <div>
      <Label>
        <FormattedMessage id={label} />
      </Label>
      <FontSelector
        className={className}
        options={options}
        onChange={onChange}
        value={value}
        bordered
      />
    </div>
  );
};

export const FontSizeSelect = styled((props: FontProps) => (
  <FontSelect options={fontSizes} {...props} />
))`
  width: 104px;
`;

export const FontWeightSelect = (props: FontProps) => (
  <FontSelect options={fontsWeightForRQPButton} {...props} />
);

export const FormTextEditor = ({
  onChange,
  value,
  ...props
}: {
  value?: string;
  onChange?: (value: string) => boolean;
} & TextEditorProps) => {
  const onChangeHTML = useCallback(
    ({ html, plainText }) => {
      onChange && onChange(plainText ? html : plainText);
    },
    [onChange],
  );

  return <TextEditor html={value} forceHTML={value} onChange={onChangeHTML} {...props} />;
};

export const BrandingSettingsSection = styled.div`
  padding: 8px 16px;
  background-color: ${props => props.theme.colors.white};
  transition: box-shadow 0.2s linear;

  &:focus-within {
    border-radius: 6px;
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.blue};
  }
`;

export const BrandingControls = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 16px;
  overflow-y: visible;
  padding: 0 10px;

  ${BrandingSettingsSection}:last-child,
  ${BrandingSettingsSection}:nth-last-child(1) {
    .chrome-picker {
      top: 0;
      transform: translateY(-100%);
    }
  }
`;

export const BrandingControlsWithDisableOption = styled(BrandingControls)<{
  $brandingFieldsDisabled: boolean;
}>`
  ${props =>
    props.$brandingFieldsDisabled &&
    css`
      ${ColorWrapper},
      ${FontSelector},
      ${RadiusContainer} {
        opacity: 0.5;
        pointer-events: none;
      }
    `}
`;

export const SettingName = styled.div`
  color: ${props => props.theme.colors.black};
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  margin-bottom: 8px;
`;

const SettingDescription = styled(SettingName)`
  font-weight: 400;
`;

export const ToggleSettingName = styled(SettingName)`
  margin: 0;
  @media ${props => props.theme.mediaQueries.large} {
    text-wrap: unset;
  }
`;

const SettingsWrapper = styled.div`
  display: flex;
  gap: 8px 16px;
  flex-wrap: wrap;
`;

export const FontSelector = styled(SelectBase)`
  height: 42px;
  width: 190px;

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    padding: 0 10px;
  }
`;

export const ShortTextArea = styled(TextAreaWithCounter)`
  &,
  & > div {
    height: 57px !important;
  }

  .textarea-root > textarea {
    min-height: 0;
    height: 22px !important;
  }
`;

export const Label = styled.span`
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
`;

export const SettingHint = styled.span`
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.29px;
  font-style: italic;
  color: ${props => props.theme.colors.grey};
`;

export const SettingError = styled.span`
  display: block;
  margin-top: 4px;
  line-height: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${props => props.theme.colors.error};
`;

export const OptionError = styled.div`
  margin-left: 8px;
  color: ${props => props.theme.colors.error};
  font-size: 12px;
  font-weight: 400;
`;

export const Table = styled(TableBase)<{ isMultipleRows: boolean }>`
  .ant-table {
    box-shadow: none;
  }

  table {
    table-layout: fixed !important;
  }

  .ant-table-thead {
    display: none;
  }

  .ant-table-cell.ant-table-row-expand-icon-cell {
    vertical-align: top;
    padding: 18px 2px 16px 0;
  }

  .ant-table-cell.ant-table-cell-fix-left {
    vertical-align: top;
  }

  tr.ant-table-row td.ant-table-cell {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${props => props.theme.colors.blue};
  }
  tr.ant-table-expanded-row td.ant-table-cell {
    padding: 0;
    position: relative;

    &:before {
      content: '';
      background-color: ${props => props.theme.colors.white};
      position: absolute;
      top: -1px;
      width: 100%;
      height: 1px;
      z-index: 2;
    }
  }

  tr.ant-table-expanded-row:hover > td,
  tr.ant-table-expanded-row > td {
    background-color: ${props => props.theme.colors.white};
  }

  .ant-table-tbody > tr > td {
    padding: 16px 0;
  }
  .ant-table-row-expand-icon-cell,
  .ant-table-expand-icon-col {
    width: 26px !important;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: inherit !important;
    ${props => props.isMultipleRows && 'cursor: pointer;'}
  }

  .ant-table-row:nth-child(2n + 0) {
    background-color: ${props => props.theme.colors.white};

    .ant-table-cell {
      background-color: ${props => props.theme.colors.white};
    }
  }

  .ant-table-tbody > tr > td {
    border-color: ${props => props.theme.colors.dividerGrey};
  }
`;

export const AdditionalSettingName = styled.div`
  padding: 0 0 14px;
`;

export const RQPDemoContainerCSS = css`
  //1px to fill all space before scrollbar
  width: calc(((var(--frame-width) + var(--box-shadow-width) * -2) * 2) + 1px);
  transform: scale(0.5) translateX(-50%) translateY(-50%);
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 0;

  &.ant-form-item-has-error {
    width: 100%;
  }

  .ant-form-item-explain {
    margin-top: 4px;
    max-width: 180px;
    line-height: 16px;
  }

  .ant-form-item-control-input-content {
    display: flex;
  }
`;

export const FullWidthFormItem = styled(Form.Item)`
  width: 100%;
  margin-bottom: 0;

  .ant-form-item-explain {
    margin-top: 4px;
  }
`;

export const TogglerWrapper = styled.div`
  display: flex;
  column-gap: 18px;
  align-items: center;
`;
