import { Company } from 'modules/company';
import { PriceRangeObj } from 'modules/global/types';
import { CompanyMarket } from 'modules/markets';
import { I18nEnum } from 'types';

export interface FinancingSetting {
  id?: number;
  months: number;
  percentageRate: number;
  financingType?: FinancingType;
  market?: CompanyMarket;
}

export type FinancingState = {
  basicFinancing: FinancingSetting | null;
  momnt: {
    merchant?: Merchant | null;
    loanApplications: MomntItem<LoanApplication>;
    loanInvitations: MomntItem<LoanInvitation>;
    allTransactions: MomntItem<Transaction>;
    availableTransactions: MomntItem<AvailableTransaction>;
    recentActivity: MomntItem<Transaction>;
    offerCodes: MomntItem<OfferCode>;
    rateSheets: RateSheet[];
    statements: MomntItem<Statement>;
  };
};

export enum TabKeys {
  ContractorLoanPro = 'ContractorLoanPro',
  BasicFinancing = 'BasicFinancing',
}

export enum ApplyLoadTabKeys {
  Market = 'Market',
  Product = 'Product',
}

export enum MomntTabKeys {
  ActivityCenter = 'ActivityCenter',
  AllTransactions = 'AllTransactions',
  RateSheet = 'RateSheet',
  Statements = 'Statements',
  PaymentSettings = 'PaymentSettings',
}

export const MerchantStatuses = {
  intake: 'In Intake',
  intakePartialActive: 'Intake Partial Active',
  intakeDecline: 'Intake Decline',
  verificationHold: 'Verification Hold',
  active: 'Active',
} as const;

export type MerchantStatus = typeof MerchantStatuses[keyof typeof MerchantStatuses];

export const BankAccountStatuses = {
  new: 'new',
  pending: 'pending',
  verified: 'verified',
  verificationFailed: 'verificationFailed',
  errored: 'errored',
};

export type BankAccountStatus =
  | typeof BankAccountStatuses[keyof typeof BankAccountStatuses]
  | false;

export interface CreateMerchantBody {
  firstName: string;
  lastName: string;
  companyEmail: string;
  phone: string;
  companyName: string;
  companyAddress: string;
  companyAddress2?: string;
  companyPhone: string;
  state: string;
  zipCode: string;
  city: string;
}

export interface MomntItem<Type> {
  loaded: boolean;
  data: Type[];
  total: number;
}

export type MomntItemType =
  | LoanApplication
  | LoanInvitation
  | Transaction
  | AvailableTransaction
  | Statement
  | MerchantPaymentRequestDetails;

export enum ConsumerAccountTransactionStatusCode {
  AbleToTransact = 0,
  UnableToTransact = 1,
  PaymentPortalAccountRequired = 2,
}

export interface LoanApplication {
  name: string;
  applicationId: string;
  amount: string;
  productId: string;

  consumerApplicationUuid: string;
  consumerApplicationHumanReadableId: string;
  email: string;
  requestedLoanAmount: string;
  status: LoanApplicationStatus;
  shownOffers: boolean;
  created: string;
  user: {
    firstName: string;
    middleName: string;
    lastName: string;
    phoneNumber: string;
    address: {
      address1: string;
      address2: string;
      city: string;
      state: string;
      zipCode: string;
    };
  };
  consumerAccount: {
    uuid: string;
    productName: string;
    currentBalance: string;
    creditLimit: string;
    availableSpend: string;
    maxAvailableTransaction: string;
    merchantFee: string;
    isValidForTransaction: boolean;
    noTransactionMessage: string;
    transactionStatusCode: ConsumerAccountTransactionStatusCode;
  } | null;
}

export const LoanApplicationStatuses = {
  approved: 'Approved: Accepted',
  approve: 'Approve',
  cancel: 'Cancel',
  declined: 'Declined',
} as const;

export type LoanApplicationStatus =
  typeof LoanApplicationStatuses[keyof typeof LoanApplicationStatuses];

export interface LoanInvitation {
  email: string | null;
  phone: string | null;
  invitedByMerchantUser: string;
  activateDate: string;
  modified: string;
  offerCodeName: string;
  sentTo: string | null;
}

export interface Transaction {
  firstName: string;
  lastName: string;
  description: string;
  amount: string;
  status: string;
  date: string;
  submittedBy: string;
  applicationId: string;
  paymentIdentifier: string;
}

export interface AvailableTransaction {
  name: string;
  amountSpent: string;
  availableSpend: string;
  purchaseWindowEndDate: string;
  applicationId: string;
}

export interface OfferCode {
  id: number;
  name: string;
  isActive: boolean;
  rateSheetMerchantProductPrices: {
    id: number;
    productTermStructure: {
      productName: string;
      shortDescription: string;
      aprRange: string;
      termRange: string;
      customerPreferences: CustomerPreferenceType[];
    };
    merchantFee: string;
    productCategory: ProductCategoryType;
  }[];
}

export interface CreateOfferCodeBody {
  name: string;
  isActive: boolean;
  rateSheetMerchantProductPrices: number[];
  isDeleted?: boolean;
}

export type UpdateOfferCodeBody = Partial<CreateOfferCodeBody>;

export interface InviteConsumerBody {
  email?: string;
  phone?: string;
  offerCode?: string;
  amount?: number;
  // contractor's email info
  shouldSendEmail?: boolean;
  statisticsId?: number;
}

export const MomntCustomerPreference = {
  promotion_motivated: 'promotion_motivated',
  interest_rate_sensitive: 'interest_rate_sensitive',
  payment_sensitive: 'payment_sensitive',
} as const;

export const CustomerPreference = {
  promotionMotivated: MomntCustomerPreference.promotion_motivated,
  interestRateSensitive: MomntCustomerPreference.interest_rate_sensitive,
  paymentSensitive: MomntCustomerPreference.payment_sensitive,
} as const;

export type CustomerPreferenceType = typeof CustomerPreference[keyof typeof CustomerPreference];
export interface Statement {
  id: number;
  createdAt: string;
  startedAt: string;
  finishedAt: string;
  number: string;
  totalCharges: string;
  totalRefunds: string;
  netPurchases: string;
  sponsorFees: string | null;
  totalMerchantReceipt: string;
  merchantFees: string;
  approvedCreditLimit: string;
  applicationsSubmited: number;
  applicationsApproved: number;
}

export interface MerchantInfo {
  name: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface MerchantPaymentRequestDetails {
  transactionDate: string;
  consumerName: string;
  consumerLoanNumber: string;
  consumerLoanProduct: string;
  merchantFeePercentage: string;
  merchantFeeAmount: string;
  sponsorFeeAmount: string | null;
  transactionDescription: string;
  netMerchantReceipt: string;
  transactionAmount: string;
}

export interface StatementDetails {
  merchantInfo: MerchantInfo;
  summary: Partial<Statement>;
  successStatementDetails: MomntItem<MerchantPaymentRequestDetails>;
  failStatementDetails: MomntItem<MerchantPaymentRequestDetails>;
}

export interface RateSheet extends TableRateSheet {
  isActive: boolean;
  isRecommended: boolean;
  topLeftTitle: string;
  topLeftContent: string;
  topCenterTitle: string;
  topCenterContent: string;
  topRightTitle: string;
  topRightContent: string;
  midLeftTitle: string;
  midLeftContent: string;
  midCenterTitle: string;
  midCenterContent: string;
  midRightTitle: string;
  midRightContent: string;
  rateSheetMerchantProductPriceId: number;
  productCategory: ProductCategoryType;
  productType: string;
  longDescription: string;
  paymentFactorLow: number;
  paymentFactorHigh: number;
}

export interface TableRateSheet {
  shortDescription: string;
  productName: string;
  fullTerm: string;
  apr: string;
  merchantFee: string;
  customerPreferences: CustomerPreferenceType[];
}

export interface UpdateMerchantRateSheetBody {
  rateSheets: { id: number; isActive: boolean }[];
}

export interface ConsumerPaymentRequestBody {
  loanApplication: LoanApplication;
  transactionDescription: number;
  amount: number;
}

export interface ConsumerPaymentRefundBody {
  loanApplication: LoanApplication;
  paymentId: string;
  amount: number;
}

export type PriceInfo = {
  priceType: string;
  total: number;
  originalTotal: number;
  monthly?: number;
  pricePerSquare: number;
  payments?: number;
  interest?: number;
  merchantFeeAvg?: number;
  merchantFeeAmount?: number;
  months?: number;
  apr?: number;

  paymentsSelected?: boolean;
  interestSelected?: boolean;
  monthsSelected?: boolean;

  interestTooltipMonths?: string;
  paymentsTooltipMonths?: string;
  paymentsTooltipInterest?: string;
  paymentFactorLow?: number;

  priceRange?: PriceRangeObj;
  additionalCosts?: Record<string, number>;
  totalAdditionalCosts?: number;
  discounts?: Record<string, number>;
  totalDiscount?: number;
};

export const DefaultFinancingTypes = {
  ContractorLoanPro: 'ContractorLoanPro',
  BasicFinancing: 'BasicFinancing',
  GreenSky: 'GreenSky',
  ServiceFinance: 'ServiceFinance',
  Synchrony: 'Synchrony',
  PowerPay: 'PowerPay',
  Financeit: 'Financeit',
} as const;

export type DefaultFinancingLiteralType =
  typeof DefaultFinancingTypes[keyof typeof DefaultFinancingTypes];

export interface FinancingType {
  id?: number;
  logo: string;
  type: string;
  url: string;
  company?: Company;
}

export const ProductCategory = {
  deferredInterest: 'Deferred Interest',
  fixedPayment: 'Fixed Payment',
  fixedRate: 'Fixed Rate',
  zeroInterest: 'Zero Interest',
  sameAsCash: 'Same-As-Cash', // Temporary return supporting Same-As-Cash, because it breaks 0.00% APR block
} as const;

export type ProductCategoryType = typeof ProductCategory[keyof typeof ProductCategory];

export interface VerifyMicroDepositsBody {
  amount1: number;
  amount2: number;
}

export interface Merchant {
  name: string;
  status: MerchantStatus;
  applicationStatus: ApplicationStatus;
  contractorId: string;
  merchantuserSet: MerchantUser[];
  merchantownerSet: {
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    ownerPercentage: string;
  }[];
  merchantAccountExists: boolean;
  bankAccountStatus: BankAccountStatus;
  userHasAccount: boolean;
}

export interface MerchantUser {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  userType: string;
  isActive: boolean;
  userId: null | string;
}

export interface UpsertPaymentDetailsBody {
  holderType: string;
  bankAccountType: string;
  routingNumber: string;
  accountNumber: string;
  nameOnCheck: string;
}

export const ApplicationStatuses = {
  preIntake: 'Pre-Intake',
  intake: 'Intake',
  submitted: 'Submitted',
  canceled: 'Canceled',
  pending: 'Pending',
  pendingDocumentsRequested: 'Pending: Documents Requested',
  pendingDocumentReceived: 'Pending: Document Received',
  declined: 'Declined',
  approved: 'Approved',
  conditionalApproved: 'Conditional Approved',
} as const;

export type ApplicationStatus = typeof ApplicationStatuses[keyof typeof ApplicationStatuses];

export interface RefundableTransaction {
  transactionDescription: string;
  transactionAmount: string;
  created: string;
  submittedBy: string;
  paymentIdentifier: string;
}

export enum CLPFinancingVariants {
  months = 'monthsSelected',
  payments = 'paymentsSelected',
  interest = 'interestSelected',
}

export interface FinancingSettingsConfig {
  applyFinancingToMarketsModal: {
    switchSelectedMarketsToBasicFinancingText: I18nEnum;
  };
}
