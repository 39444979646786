export const fonts = [
  {
    value: "'Rubik', sans-serif",
    label: 'Rubik',
    fontsWeight: [
      { value: 300, label: 'Light' },
      { value: 400, label: 'Medium' },
      { value: 500, label: 'Bold' },
    ],
  },
  {
    value: 'sans-serif',
    label: 'Sans-Serif',
    fontsWeight: [
      { value: 300, label: 'Light' },
      { value: 400, label: 'Medium' },
      { value: 500, label: 'Bold' },
    ],
  },
  {
    value: 'Cinzel Decorative',
    label: 'Cinzel Decorative',
    fontsWeight: [
      { value: 400, label: 'Regular' },
      { value: 700, label: 'Bold' },
      { value: 900, label: 'Black' },
    ],
  },
  {
    value: 'Aktiv Grotesk',
    label: 'Aktiv Grotesk',
    fontsWeight: [
      { value: 100, label: 'Hairline' },
      { value: 200, label: 'Thin' },
      { value: 300, label: 'Light' },
      { value: 400, label: 'Regular' },
      { value: 500, label: 'Medium' },
      { value: 700, label: 'Bold' },
      { value: 800, label: 'Extra Bold' },
      { value: 900, label: 'Black' },
    ],
  },
  {
    value: 'Andale Mono',
    label: 'Andale Mono',
    fontsWeight: [{ value: 400, label: 'Regular' }],
  },
  {
    value: 'Bebas Neue',
    label: 'Bebas Neue',
    fontsWeight: [{ value: 400, label: 'Regular' }],
  },
  {
    value: 'Comic Sans',
    label: 'Comic Sans',
    fontsWeight: [
      { value: 400, label: 'Regular' },
      { value: 700, label: 'Bold' },
    ],
  },
  {
    value: 'Georgia',
    label: 'Georgia',
    fontsWeight: [
      { value: 400, label: 'Regular' },
      { value: 700, label: 'Bold' },
    ],
  },
  {
    value: 'Seriffic',
    label: 'Seriffic',
    fontsWeight: [{ value: 500, label: 'Regular' }],
  },
  {
    value: 'Helvetica',
    label: 'Helvetica',
    fontsWeight: [
      { value: 300, label: 'Light' },
      { value: 400, label: 'Regular' },
      { value: 500, label: 'Bold' },
      { value: 600, label: 'Bold Rounded' },
    ],
  },
  {
    value: 'Impact',
    label: 'Impact',
    fontsWeight: [{ value: 700, label: 'Bold' }],
  },
  {
    value: 'Josefin Sans',
    label: 'Josefin Sans',
    fontsWeight: [
      { value: 100, label: 'Thin' },
      { value: 300, label: 'Light' },
      { value: 400, label: 'Regular' },
      { value: 600, label: 'Semi Bold' },
      { value: 700, label: 'Bold' },
    ],
  },
  {
    value: 'Lato',
    label: 'Lato',
    fontsWeight: [
      { value: 100, label: 'Hairline' },
      { value: 300, label: 'Light' },
      { value: 400, label: 'Regular' },
      { value: 700, label: 'Bold' },
      { value: 900, label: 'Black' },
    ],
  },
  {
    value: 'Lucida Sans',
    label: 'Lucida Sans',
    fontsWeight: [
      { value: 400, label: 'Regular' },
      { value: 600, label: 'Bold' },
    ],
  },
  {
    value: 'Montserrat',
    label: 'Montserrat',
    fontsWeight: [
      { value: 400, label: 'Regular' },
      { value: 700, label: 'Bold' },
    ],
  },
  {
    value: 'Open Sans',
    label: 'Open Sans',
    fontsWeight: [
      { value: 300, label: 'Light' },
      { value: 400, label: 'Regular' },
      { value: 600, label: 'Semi Bold' },
      { value: 700, label: 'Bold' },
      { value: 800, label: 'Extra Bold' },
    ],
  },
  {
    value: 'Oswald',
    label: 'Oswald',
    fontsWeight: [
      { value: 300, label: 'Light' },
      { value: 400, label: 'Regular' },
      { value: 500, label: 'Medium' },
      { value: 700, label: 'Bold' },
    ],
  },
  {
    value: 'Poppins',
    label: 'Poppins',
    fontsWeight: [
      { value: 100, label: 'Thin' },
      { value: 200, label: 'Extra Light' },
      { value: 300, label: 'Light' },
      { value: 400, label: 'Regular' },
      { value: 500, label: 'Medium' },
      { value: 600, label: 'Semi Bold' },
      { value: 700, label: 'Bold' },
      { value: 800, label: 'Extra Bold' },
      { value: 900, label: 'Black' },
    ],
  },
  {
    value: 'Proxima Nova',
    label: 'Proxima Nova',
    fontsWeight: [{ value: 400, label: 'Regular' }],
  },
  {
    value: 'Rajdhani',
    label: 'Rajdhani',
    fontsWeight: [
      { value: 300, label: 'Light' },
      { value: 400, label: 'Regular' },
      { value: 500, label: 'Medium' },
      { value: 600, label: 'Semi Bold' },
      { value: 700, label: 'Bold' },
    ],
  },
  {
    value: 'Raleway',
    label: 'Raleway',
    fontsWeight: [
      { value: 100, label: 'Thin' },
      { value: 200, label: 'Extra Light' },
      { value: 300, label: 'Light' },
      { value: 400, label: 'Regular' },
      { value: 500, label: 'Medium' },
      { value: 600, label: 'Semi Bold' },
      { value: 700, label: 'Bold' },
      { value: 800, label: 'Extra Bold' },
      { value: 900, label: 'Heavy' },
    ],
  },
  {
    value: 'Roboto',
    label: 'Roboto',
    fontsWeight: [
      { value: 100, label: 'Thin' },
      { value: 300, label: 'Light' },
      { value: 400, label: 'Regular' },
      { value: 500, label: 'Medium' },
      { value: 700, label: 'Bold' },
      { value: 900, label: 'Heavy' },
    ],
  },
  {
    value: 'Tahoma',
    label: 'Tahoma',
    fontsWeight: [
      { value: 400, label: 'Regular' },
      { value: 700, label: 'Bold' },
    ],
  },
  {
    value: 'Verdana',
    label: 'Verdana',
    fontsWeight: [{ value: 700, label: 'Bold' }],
  },
];

export const fontsWeightForRQPButton = [
  { value: 300, label: 'Light' },
  { value: 400, label: 'Medium' },
  { value: 500, label: 'Bold' },
];

export const fontSizes = [
  { value: 14, label: '14' },
  { value: 15, label: '15' },
  { value: 16, label: '16' },
  { value: 17, label: '17' },
  { value: 18, label: '18' },
  { value: 19, label: '19' },
  { value: 20, label: '20' },
];
