import { css } from 'styled-components';

export const TooltipCommonStyles = css`
  max-width: 475px;
  padding: 16px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
    0 5px 5px -3px rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.8);
  z-index: 1010;

  a,
  a:hover {
    color: ${props => props.theme.colors.blue};
    text-decoration: underline;
    cursor: pointer;
  }
  a:hover {
    color: ${props => props.theme.colors.darkBlue};
  }
`;

const HoverTooltipStyles = css`
  &::before {
    ${TooltipCommonStyles}
    position: absolute;
    white-space: nowrap;
    text-align: center;
  }
`;

export default HoverTooltipStyles;
