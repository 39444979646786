export interface ModalConfig {
  type: string;
  params: any;
}

export interface ModalState extends ModalConfig {
  opened: boolean;
  stack: ModalConfig[];
}

export interface CloseModalOptions {
  clearStack?: boolean;
}

export enum ModalTypes {
  contact = 'contact',
  addressNotFount = 'addressNotFount',
  deleteStructure = 'deleteStructure',
  expirationModal = 'expirationModal',
  newPassword = 'newPassword',
  error = 'error',
  deleteAccount = 'deleteAccount',
  editProfile = 'editProfile',
  upsertContractor = 'upsertContractor',
  upsertSubscription = 'upsertSubscription',
  deleteContractor = 'deleteContractor',
  pauseSubscription = 'pauseSubscription',
  editCompany = 'editCompany',
  editCompanyEmail = 'editCompanyEmail',
  upsertCompanyUser = 'upsertCompanyUser',
  deleteContact = 'deleteContact',
  resendPassword = 'resendPassword',
  resetWidgetBranding = 'resetWidgetBranding',
  widgetBrandingSaved = 'widgetBrandingSaved',
  resetWidgetProducts = 'resetWidgetProducts',
  cancelSubscription = 'cancelSubscription',
  cancelationReason = 'cancelationReason',
  resetToolsSettings = 'resetToolsSettings',
  selectedProductsSaved = 'selectedProductsSaved',
  warningModal = 'warningModal',
  unsavedChangesWarningModal = 'unsavedChangesWarningModal',
  successModal = 'successModal',
  serviceAreaSaved = 'serviceAreaSaved',
  resetServiceArea = 'resetServiceArea',
  almostDone = 'almostDone',
  potentialClient = 'potentialClient',
  requestProducts = 'requestProducts',
  upsertLabel = 'upsertLabel',
  deleteSnippet = 'deleteSnippet',
  addSnippet = 'addSnippet',
  editSnippet = 'editSnippet',
  snippets = 'snippets',
  webhooksUpdated = 'webhooksUpdated',
  applyFinancingToMarkets = 'applyFinancingToMarkets',
  previewWidget = 'previewWidget',
  thankYou = 'thankYou',
  thankYouContactUs = 'thankYouContactUs',
  applyProductInfoToMarkets = 'applyProductInfoToMarkets',
  addLoanApplication = 'addLoanApplication',
  customerSummaryReport = 'customerSummaryReport',
  applyLoanToMarketsProductsModal = 'applyLoanToMarketsProductsModal',
  rateSheetDetails = 'rateSheetDetails',
  upsertOfferCode = 'upsertOfferCode',
  exportStatementDetails = 'exportStatementDetails',
  invitationSent = 'invitationSent',
  howInstantLoanWorks = 'howInstantLoanWorks',
  customSlope = 'customSlope',
  reassignTo = 'reassignTo',
  sendLoanInvitation = 'sendLoanInvitation',
  upsertSmtpServer = 'upsertSmtpServer',
  smtpServerConnectionWarningModal = 'smtpServerConnectionWarningModal',
  upsertSalesRabbitConnection = 'upsertSalesRabbitConnection',
  upsertJobNimbusConnection = 'upsertJobNimbusConnection',
  upsertLeapConnection = 'upsertLeapConnection',
  createEagleViewConnection = 'createEagleViewConnection',
  disconnectEagleviewConnection = 'disconnectEagleviewConnection',
  editEagleviewConnections = 'editEagleviewConnections',
  /* -------- RepQuotes modals -------- */
  repQuotesEditEmail = 'repQuotesEditEmail',
  repQuotesAlmostDone = 'repQuotesAlmostDone',
  repQuotesInvitationSent = 'repQuotesInvitationSent',
  repQuotesHowInstantLoanWorks = 'repQuoteshHowInstantLoanWorks',
  repQuotesAssignTo = 'repQuotesAssignTo',
  repQuotesEditMarket = 'repQuotesEditMarket',
  editEmailSetting = 'editEmailSetting',
  openExistingLead = 'openExistingLead',
  editAdditionalInformation = 'editAdditionalInformation',
  repQuotesProductDetails = 'repQuotesProductDetails',
  /* ----------------------------------- */
  applyEmailSettingsToMarkets = 'applyEmailSettingsToMarkets',
  productDetails = 'productDetails',
  applyMarketSettings = 'applyMarketSettings',
  orderMeasurements = 'orderMeasurements',
  infoModal = 'infoModal',
  paymentDetails = 'paymentDetails',
  renewOption = 'renewOption',
  createBeaconConnection = 'createBeaconConnection',
  editBeaconConnection = 'editBeaconConnection',
  beaconConnectionSuccess = 'beaconConnectionSuccess',
  associateFormula = 'associateFormula',
  additionalCostsPreview = 'additionalCostsPreview',
  selectMarketForOrder = 'selectMarketForOrder',
  selectBranchForOrder = 'selectBranchForOrder',
  addNewBeaconProduct = 'addNewBeaconProduct',
  widgetProductDetails = 'widgetProductDetails',
  warrantyCoverageModal = 'warrantyCoverageModal',
  upsertFinancingType = 'upsertFinancingType',
}
