import { I18nEnum } from 'types';
import { RoofleStructure, TotalMeasurements, StructureState } from './types';
import { STRUCTURES_LIMIT } from './constants';
import { validateStructureName } from 'utils';

export function getInitialStructureState(structures: RoofleStructure[]) {
  if (!structures.length) {
    return StructureState.NotFound;
  } else if (structures.length > STRUCTURES_LIMIT) {
    return StructureState.TooMany;
  } else {
    return StructureState.Found;
  }
}

export function getEditedStructureState(structures: RoofleStructure[]) {
  return !structures.length ? StructureState.EditedNone : StructureState.EditedSome;
}

export function getTotalMeasurements(structures: RoofleStructure[]): TotalMeasurements {
  const totalSquareFeet = getStructuresTotalSquareFeet(structures);
  const wholeSquareFeet = Math.ceil(totalSquareFeet);
  const totalSquareFeetToLocale = wholeSquareFeet.toLocaleString();
  const totalSquaresCount = Math.ceil(totalSquareFeet / 100);

  return {
    totalSquareFeet,
    totalSquareFeetToLocale,
    totalSquaresCount,
  };
}

export function getStructuresTotalSquareFeet(structures: RoofleStructure[]) {
  const includedStructures = structures.filter(structure => structure.isIncluded);
  const totalSquareFeet = includedStructures
    .map(structure => Math.ceil(structure.measurements.squareFeet))
    .reduce((acc, cur) => acc + cur, 0);

  return totalSquareFeet;
}

// return either `null` or an i18n error key for the structure name
export const structureNameError = (
  structureName: string,
  structures?: RoofleStructure[],
  setStructures?: boolean,
) => {
  const maxMatches = setStructures ? 1 : 0;
  const matches = structures?.reduce((acc, structure) => {
    if (structure.name.toLowerCase() === structureName.toLowerCase()) acc++;
    return acc;
  }, 0);

  if (matches && matches > maxMatches) {
    return I18nEnum.StructureNameMustBeUnique;
  }

  if (validateStructureName(structureName)) {
    return null;
  }

  return I18nEnum.StructureValidation;
};

export const getValidatedStructure = (
  structure: RoofleStructure,
  structures: RoofleStructure[],
  setStructures?: boolean,
) => {
  const nameError = structureNameError(structure.name, structures, setStructures);
  return { ...structure, nameError, isError: !!nameError };
};
