import { AuthActionLabels } from './constants';

export const EventAction = {
  pageView: 'pageView',
  tabView: 'tabView',
  moreActionsItemClick: 'moreActionsItemClick',
  buttonClick: 'buttonClick',
  linkClick: 'linkClick',
  authChange: 'authChange',
  selectAddress: 'selectAddress',
  checkboxClick: 'checkboxClick',
  renameRoof: 'renameRoof',
} as const;

export interface UserAction {
  action: typeof EventAction[keyof typeof EventAction];
  label: string;
  meta?: Record<string, unknown>;
  createdAt?: string;
}

export type AuthActionLabel = typeof AuthActionLabels[keyof typeof AuthActionLabels];

export interface TrackingConfig {
  ignorePageTracking?: boolean;
}
