import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { colors } from 'global-constants';
import { selectors } from 'modules/spinner';

const Spinner = () => {
  const { show, backgroundOpacity = 0.8 } = useSelector(selectors.selectShowSpinner);

  return show ? <SpinnerStateless backgroundOpacity={backgroundOpacity} /> : null;
};

export const SpinnerStateless = ({ backgroundOpacity = 0.8 }) => (
  <Root>
    <BackgroundMask opacity={backgroundOpacity} />
    <Img src={`${process.env.REACT_APP_ASSETS}cms/roofle-logo-progress.gif`} />
  </Root>
);

const Root = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10000;
  top: 0;
  left: 0;
`;

const BackgroundMask = styled.div<{ opacity: number }>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${colors.white};
  ${props => `
    opacity: ${props.opacity};
  `}
`;

const Img = styled.img`
  width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default Spinner;
