import { intl } from 'intl';
import { I18nEnum, ThunkResult } from 'types';
import { getCookies, getSessionId } from 'utils';
import { getUserFromTokenPayload } from 'modules/auth/utils';
import { removeUserFromLocal } from 'modules/auth/storage';
import { SET_USER, CLEAR_USER } from 'modules/auth/constants';
import { CLEAR_CONTRACTORS_DASHBOARD } from 'modules/admin/constants';
import { CLEAR_COMPANY } from 'modules/company/constants';
import { CLEAR_MARKETS } from 'modules/markets/constants';
import {
  getSalesEmailParam,
  getSalesRabbitParams,
} from 'modules/quickQuote/hooks/useQuickQuoteQueryParams';
import { ModalTypes } from 'modules/modal/types';
import { AnalyticsEvent, WidgetAction } from '.';
import { queryClient } from 'modules/reactQuery';
import { abortController } from 'services/api';

export const getUser = (): ThunkResult<Promise<void>> => async dispatch => {
  try {
    const { token } = getCookies(document.cookie);

    if (token) {
      const user = getUserFromTokenPayload(token);

      dispatch({
        type: SET_USER,
        user: user || {},
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const clearUser = (): ThunkResult<void> => dispatch => {
  removeUserFromLocal();
  dispatch({ type: CLEAR_USER });
  dispatch({ type: CLEAR_CONTRACTORS_DASHBOARD });
  dispatch({ type: CLEAR_COMPANY });
  dispatch({ type: CLEAR_MARKETS });
  queryClient.invalidateQueries();
  abortController.abort();
};

export const trackWidgetAnalytics =
  ({ widgetAction: action }: { widgetAction: WidgetAction | null }): ThunkResult<Promise<void>> =>
  async (_, getState) => {
    try {
      const { selectWidget } = await import('modules/widget/selectors');
      const { selectQuickQuote } = await import('modules/quickQuote/selectors');
      const { trackAnalyticsAPI } = await import('modules/company/services');
      const { selectConfig } = await import('modules/quickQuoteConfig/selectors');

      const state = getState();
      const { companyId, demo, iframePreview, externalUrl } = selectWidget(state);
      const { marketSlug } = selectQuickQuote(state);
      const { userId, shouldTrackWidgetAnalytics } = selectConfig(state);

      if (!action || !companyId || demo || iframePreview || !shouldTrackWidgetAnalytics) {
        return;
      }

      const { sessionId, previousSessionId } = await getSessionId();

      switch (action.event) {
        case AnalyticsEvent.qqOpened:
          action.meta = `user agent: ${navigator.userAgent}`;
          break;
        default:
          break;
      }

      const { salesRabbitParam, leadIdParam } = getSalesRabbitParams(externalUrl);
      const email = getSalesEmailParam(externalUrl);

      const meta = {
        ...action,
        sessionId,
        marketSlug,
        externalUrl,
        userId,
        isSalesRabbitUrl: action.isSalesRabbitUrl || !!(salesRabbitParam && leadIdParam),
        forceWebLead: !!email,
      };

      await trackAnalyticsAPI(companyId, meta);
      window.parent.postMessage(meta, '*');

      if (previousSessionId) {
        await trackAnalyticsAPI(companyId, {
          event: AnalyticsEvent.sessionIdChanged,
          sessionId,
          meta: previousSessionId,
        });
      }
    } catch (e) {}
  };

export const openResetModal =
  (
    modalType: ModalTypes,
    secondaryAction: () => ThunkResult<Promise<void>>,
    description = intl.formatMessage({ id: I18nEnum.AllSettingsWillBeResetToTheDefault }),
  ) =>
  async dispatch => {
    const { openModal, closeModal } = await import('modules/modal/actions');

    dispatch(
      openModal(modalType, {
        primaryAction: () => dispatch(closeModal()),
        secondaryAction,
        subtitle: intl.formatMessage({ id: I18nEnum.AreYouSureYouWantToResetSettings }),
        description,
        primaryBtnText: I18nEnum.NoKeepAsIs,
        secondaryBtnText: I18nEnum.YesReset,
      }),
    );
  };
