import { ActionType } from 'types';
import * as constants from './constants';
import { FinancingState } from './types';

const initialState: FinancingState = {
  basicFinancing: null,
  momnt: {
    loanApplications: constants.defaultMomntItem,
    loanInvitations: constants.defaultMomntItem,
    allTransactions: constants.defaultMomntItem,
    availableTransactions: constants.defaultMomntItem,
    recentActivity: constants.defaultMomntItem,
    offerCodes: constants.defaultMomntItem,
    statements: constants.defaultMomntItem,
    rateSheets: [],
  },
};

const financingReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case constants.SET_BASIC_FINANCING:
      return { ...state, basicFinancing: action.basicFinancing };
    case constants.SET_MOMNT_MERCHANT:
      return {
        ...state,
        momnt: {
          ...state.momnt,
          merchant: action.merchant,
        },
      };
    case constants.SET_MOMNT_LOAN_APPLICATIONS:
      return {
        ...state,
        momnt: { ...state.momnt, loanApplications: { ...action.loanApplications } },
      };
    case constants.SET_MOMNT_LOAN_INVITATIONS:
      return {
        ...state,
        momnt: { ...state.momnt, loanInvitations: { ...action.loanInvitations } },
      };
    case constants.SET_MOMNT_ALL_TRANSACTIONS:
      return {
        ...state,
        momnt: { ...state.momnt, allTransactions: { ...action.allTransactions } },
      };
    case constants.SET_MOMNT_AVAILABLE_TRANSACTIONS:
      return {
        ...state,
        momnt: { ...state.momnt, availableTransactions: { ...action.availableTransactions } },
      };
    case constants.SET_MOMNT_RECENT_ACTIVITY:
      return {
        ...state,
        momnt: { ...state.momnt, recentActivity: { ...action.recentActivity } },
      };
    case constants.SET_MOMNT_OFFER_CODES:
      return {
        ...state,
        momnt: { ...state.momnt, offerCodes: { ...action.offerCodes } },
      };
    case constants.SET_MOMNT_STATEMENTS:
      return {
        ...state,
        momnt: { ...state.momnt, statements: { ...action.statements } },
      };
    case constants.SET_MOMNT_RATE_SHEETS:
      return {
        ...state,
        momnt: { ...state.momnt, rateSheets: action.rateSheets },
      };
    default:
      return state;
  }
};

export default financingReducer;
