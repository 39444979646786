import { DefaultTheme } from 'styled-components';

export const colors = {
  beige: '#D6D1CA',
  lightBeige: '#f9f8f7',
  orange: '#EF7E45',
  lightOrange: '#F6BA9D',
  darkOrange: '#EC5D15',
  fuelYellow: '#EEA834',
  white: '#FFFFFF',
  blue: '#244C5A',
  lightBlue: '#738C95',
  darkBlue: '#002E3E',
  grey: '#959595',
  lightGrey: '#E7E5E3',
  mediumGrey: '#D8D8D8',
  darkGrey: '#646464',
  borderGrey: '#E2E2E2',
  dividerGrey: '#b1b0b0',
  error: '#E71616',
  black: '#000000',
  green: '#5FAD56',
  darkCyan: '#285665',

  // Owens Corning
  darkCharcoal: '#333333',
  graniteGray: '#646464',
  deepCerise: '#D62A88',
  vividCerise: '#D40F7D',
  roseGarnet: '#910554',
};

export const colorFilters = {
  blue: 'invert(24%) sepia(19%) saturate(1300%) hue-rotate(150deg) brightness(94%) contrast(88%)',
  grey: 'invert(66%) sepia(0%) saturate(0%) hue-rotate(185deg) brightness(91%) contrast(85%)',
  deepDarkGrey:
    'invert(41%) sepia(0%) saturate(1%) hue-rotate(165deg) brightness(91%) contrast(83%);',
  darkGrey: 'invert(37%) sepia(50%) saturate(6%) hue-rotate(336deg) brightness(97%) contrast(94%);',
  mediumGrey:
    'invert(79%) sepia(0%) saturate(13%) hue-rotate(335deg) brightness(79%) contrast(81%);',
  lightGrey:
    'invert(52%) sepia(67%) saturate(7%) hue-rotate(349deg) brightness(108%) contrast(95%)',
  white: 'invert(77%) sepia(91%) saturate(35%) hue-rotate(197deg) brightness(108%) contrast(113%);',
  orange:
    'invert(77%) sepia(38%) saturate(6930%) hue-rotate(333deg) brightness(102%) contrast(87%);',
  error: 'invert(11%) sepia(95%) saturate(7453%) hue-rotate(2deg) brightness(100%) contrast(83%);',
  black: 'invert(0%) sepia(1%) saturate(10%) hue-rotate(114deg) brightness(99%) contrast(100%)',
  fuelYellow:
    'invert(80%) sepia(76%) saturate(2956%) hue-rotate(334deg) brightness(98%) contrast(90%)',
  green: 'invert(58%) sepia(9%) saturate(2476%) hue-rotate(67deg) brightness(104%) contrast(77%)',
  vividCerise:
    'invert(17%) sepia(64%) saturate(3735%) hue-rotate(310deg) brightness(103%) contrast(108%)',
};

export const sizes = {
  nano: 320, // Apple iPhone 5, SE 2016
  micro: 374, //Apple iPhone X, XS, 11 Pro, 7, 8, 6, 6S, SE2 (2020)
  tiny: 413, // Apple iPhone 11 Pro Max, XS Max, 11, XR, 6+, 6S+, 7+, 8+
  small: 599, // something between tablet & mobile
  medium: 768, // Apple iPad 3, 4, Air, Air2, Pro 9.7 /mini 2, 3, 4/
  mediumXL: 992,
  tablet: 1024,
  mediumXXL: 1200,
  large: 1366,
  largeXL: 1483,
  xl: 1600,
  xxl: 1920,
};

export const mediaQueries = {
  nano: `(max-width: ${sizes.nano}px)`,
  micro: `(max-width: ${sizes.micro}px)`,
  tiny: `(max-width: ${sizes.tiny}px)`,
  small: `(max-width: ${sizes.small}px)`,
  medium: `(max-width: ${sizes.medium}px)`,
  mediumXL: `(max-width: ${sizes.mediumXL}px)`,
  tablet: `(max-width: ${sizes.tablet}px)`,
  mediumXXL: `(max-width: ${sizes.mediumXXL}px)`,
  large: `(max-width: ${sizes.large}px)`,
  largeXL: `(max-width: ${sizes.largeXL}px)`,
  xl: `(max-width: ${sizes.xl}px)`,
  xxl: `(max-width: ${sizes.xxl}px)`,
  retina: `
    only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: ${sizes.large}px),
    only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: ${sizes.large}px),
    only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: ${sizes.large}px),
    only screen and (        min-device-pixel-ratio: 2)      and (min-width: ${sizes.large}px),
    only screen and (                min-resolution: 192dpi) and (min-width: ${sizes.large}px),
    only screen and (                min-resolution: 2dppx)  and (min-width: ${sizes.large}px)
  `,
};

export const orientation = {
  landscape: `(orientation: landscape)`,
  portrait: `(orientation: portrait)`,
};

export const linePrimaryColors = [
  { name: 'gray', hex: '#848484' },
  { name: 'blue', hex: '#507381' },
  { name: 'brown', hex: '#795548' },
  { name: 'green', hex: '#646F48' },
  { name: 'black', hex: '#2A2424' },
  { name: 'red', hex: '#841D1D' },
];

export const KEY_CODES = {
  ENTER: 13,
  ESCAPE: 27,
};

// global-constants are loaded by tool script before __RUNTIME_CONFIG__ initialization
export const SITE_KEY = window.__RUNTIME_CONFIG__?.REACT_APP_RECAPTCHA;
export const ASSETS = window.__RUNTIME_CONFIG__?.REACT_APP_ASSETS;

export const contactPhone = {
  formatted: '(612) 255-8200',
  href: '+1-612-255-8200',
};
export const infoEmail = 'info@roofle.com';
export const roofleGMapHref =
  'https://www.google.com/maps/place/Roofle/@42.8346041,-106.8954536,5z/data=!3m1!4b1!4m5!3m4!1s0x0:0x8e70910912738f31!8m2!3d43.1873239!4d-97.9121164?hl=en';
export const blogUrl = 'https://blog.roofle.com/';
export const winRoofUrl = 'https://win.roofle.com/';
export const tradePartnersUrl = 'https://partners.roofle.com/';
export const meetingUrl = 'https://meet.roofle.com/meetings/roofingmadesimple/talk-to-a-pro';
export const preQualifiedUrl = 'https://app.getpowerpay.com/apply/16340';
export const hubspotMeetingUrl = 'https://meet.roofle.com/meetings/roofingmadesimple/order-review';
export const insuranceContactPhone = '+1-855-770-1444';
export const gafTermsAndConditionsURL =
  'https://www.gaf.com/en-us/document-library/documents/documents/legaldocumentsdocuments/GAF_QuickMeasure_Roof_Report_Terms_and_Conditions.pdf';

export const elementsSizes = {
  header: {
    initial: '64px',
    small: '54px',
  },
  searchHeader: {
    initial: '44px',
    medium: '32px',
  },
  bottomNav: {
    initial: '56px',
  },
};

export const theme: DefaultTheme = {
  colors,
  mediaQueries,
  elementsSizes,
  orientation,
  colorFilters,
};

export const DECKING_SHEETS = 32;

export const getfbShareUrl = (title = '', url: string) =>
  `https://www.facebook.com/sharer/sharer.php?title=${title}&u=${url}`;
export const getPinterestShareUrl = (title = '', url: string, image = '') =>
  `http://pinterest.com/pin/create/link/?url=${url}&description=${title}&media=${image}`;

export const REFERRED_AFFILIATE_ID = 'raid';
export const REFERRED_AFFILIATE_LINK = 'rLink';

export const SSG_REVALIDATE_SUCCESS_TIME = 60 * 30; // 30 min
export const SSG_REVALIDATE_FAIL_TIME = 60 * 5; // 5 min

export const defaultOgImage = `${ASSETS}og-image.png`;

export const SALES_RABBIT_HOME_PAGE = 'https://salesrabbit.com/';
export const JOB_NIMBUS_HOME_PAGE = 'https://jobnimbus.com/';
export const LEAP_HOME_PAGE = 'https://www.jobprogress.com/';
export const ONE_CLICK_CONTRACTOR_HOME_PAGE = 'https://oneclickcontractor.com/';

const paymentCardsSrc = `${ASSETS}cms/payment-cards/`;
export const paymentCardsImages = {
  'American Express': `${paymentCardsSrc}AmericanExpress.svg`,
  'Diners Club': `${paymentCardsSrc}DinersClub.svg`,
  Discover: `${paymentCardsSrc}Discover.svg`,
  JCB: `${paymentCardsSrc}JCB.svg`,
  MasterCard: `${paymentCardsSrc}Mastercard.svg`,
  Visa: `${paymentCardsSrc}Visa.svg`,
};
export const unknownCardImg = `${paymentCardsSrc}Unknown.svg`;

export const termsOfService = 'https://roofquotepro.com/Roofle-Roof-Quote-PRO-Terms.pdf';
export const privacyPolicy = 'https://roofquotepro.com/Roofle-Roof-Quote-PRO-Privacy-Policy.pdf';

export const beaconSignUpLink = 'https://www.becn.com/proplus/sign-up';

export const MIN_SQUARE_FEET = 1;
export const MAX_VALID_VALUE = 10000000;

export const QUICK_QUOTE_WIDGET_TYPES = {
  SlideOut: 'slide-out',
  Embedded: 'embedded',
};
