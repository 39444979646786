import { CompanyNotificationsSettings } from './types';

const name = 'emailsSettings/';

export const COMPANY_EMAIL_SETTINGS_KEY = `${name}COMPANY_EMAIL_SETTINGS`;
export const COMPANY_NOTIFICATIONS_SETTINGS_KEY = `${name}COMPANY_NOTIFICATIONS_SETTINGS`;

export const STORAGE_HIDE_EMAILS_WARNING = `${name}STORAGE_HIDE_EMAILS_WARNING`;

export const NotificationsFormKey = {
  addSquareFootageInInstantQuoteEmail: 'addSquareFootageInInstantQuoteEmail',
  attachPdfToInstantQuoteEmail: 'attachPdfToInstantQuoteEmail',
  sendInstantQuoteEmail: 'sendInstantQuoteEmail',
  sendLoanApplicationCompletedEmail: 'sendLoanApplicationCompletedEmail',
  sendPreQualificationEmail: 'sendPreQualificationEmail',
  sendTransactionApprovedEmail: 'sendTransactionApprovedEmail',
  sendRecommendedProductsEmail: 'sendRecommendedProductsEmail',
} as const;

export const defaultNotificationsSettings: Omit<CompanyNotificationsSettings, 'id'> = {
  addSquareFootageInInstantQuoteEmail: false,
  attachPdfToInstantQuoteEmail: true,
  sendInstantQuoteEmail: true,
  sendLoanApplicationCompletedEmail: false,
  sendPreQualificationEmail: false,
  sendTransactionApprovedEmail: false,
};

export const allMarketsSettingKey = 'allMarkets';
