import api from 'services/api';
import { RoutesTitles } from 'types';
import { TrackingConfig, UserAction } from './types';

export const trackUserActionAPI = (userId: number, userAction: UserAction) =>
  api.put(`user/${userId}/action`, userAction);

export const trackUserAction = (
  userId: number,
  userAction: UserAction,
  config?: TrackingConfig,
) => {
  const page = RoutesTitles[window.location.pathname];
  if (!config?.ignorePageTracking) {
    userAction.meta = { ...(userAction.meta || {}), page };
  }

  return trackUserActionAPI(userId, userAction)
    .then(() => {})
    .catch(e => console.error(e));
};
