import map from 'lodash/map';

import { NodeDetails, NodeDetailsMap, OptionType, TreeData } from './types';
import get from 'lodash/get';

export function treeTraversalInDepth<T>(
  root: TreeData<T>,
  callback: (node: TreeData<T>) => void,
): void {
  let stack: TreeData<T>[] = [root];

  while (stack.length) {
    const handledNode = stack.pop();

    if (handledNode) {
      callback(handledNode);

      if (handledNode?.children) {
        stack = stack.concat([...handledNode?.children].reverse());
      }
    }
  }
}

export function treeTraversalInWidth<T>(
  root: TreeData<T>,
  callback: (node: TreeData<T>, waveIndex: number, parent?: TreeData<T>) => void,
): void {
  let currentWave: TreeData<T>[] = [root];
  let nextWave: TreeData<T>[] = [];
  let waveIndex = 1;
  let parentWave: TreeData<T>[] = [];
  let nodeIndex = 0;

  while (currentWave.length) {
    const handledNode = currentWave[nodeIndex];
    nodeIndex += 1;

    if (handledNode) {
      const parent = parentWave.find(node =>
        node?.children?.find(child => Object.is(handledNode, child)),
      );
      callback(handledNode, waveIndex, parent);

      if (handledNode.children) {
        nextWave.push(...handledNode?.children);
      }

      if (nodeIndex === currentWave.length) {
        parentWave = [...currentWave];
        currentWave = [...nextWave];
        nextWave = [];
        waveIndex += 1;
        nodeIndex = 0;
      }
    }
  }
}

export function findNodeByValue<T extends { value: V }, V>(
  root: TreeData<T>,
  value: V,
): TreeData<T> | null {
  const currentWave: TreeData<T>[] = [root];
  let nextWave: TreeData<T>[] = [];

  while (currentWave.length) {
    const handledNode = currentWave.pop();

    if (handledNode && handledNode.node.value === value) {
      return handledNode;
    }

    if (handledNode?.children) {
      nextWave.push(...handledNode?.children);
    }

    if (!currentWave.length) {
      currentWave.push(...nextWave);
      nextWave = [];
    }
  }

  return null;
}

export function findAllChildren<T>(node: TreeData<T>): TreeData<T>[] {
  const currentWave: TreeData<T>[] = [node];
  let nextWave: TreeData<T>[] = [];
  const children: TreeData<T>[] = [];

  while (currentWave.length) {
    const handledNode = currentWave.pop();

    if (handledNode?.children) {
      nextWave.push(...handledNode.children);
      children.push(...handledNode.children);
    }

    if (!currentWave.length) {
      currentWave.push(...nextWave);
      nextWave = [];
    }
  }

  return children;
}

export function findAllChildrenOfNodeWithValue<T extends { value: V }, V>(
  root: TreeData<T>,
  value: V,
): TreeData<T>[] {
  const node = findNodeByValue(root, value);

  if (!node) {
    return [];
  }

  return findAllChildren(node);
}

export function convertTreeDataToOptions(root: TreeData<OptionType>): OptionType[] {
  const options: OptionType[] = [];

  treeTraversalInDepth(root, option => {
    options.push(option.node);
  });

  return options;
}

export function getNodeDetailsMap(root: TreeData<OptionType>): NodeDetailsMap<string | number> {
  const nodeDetailsMap: NodeDetailsMap<string | number> = {};

  treeTraversalInWidth(root, (option, waveIndex, parent) => {
    const childrenListId = map(findAllChildren(option), 'node.value');
    nodeDetailsMap[option.node.value] = {
      level: waveIndex,
      childrenListId,
      leaf: !childrenListId.length,
      parentId: parent && (get(parent, 'node.value') as string | number),
    };
  });

  return nodeDetailsMap;
}

export function selectNode<T extends string | number>(
  nodeDetailsMap: NodeDetailsMap<T>,
  selectedNodeId: T,
  selectedNodeIds: T[],
): T[] {
  const selectedNodeDetails = nodeDetailsMap[selectedNodeId];

  if (!selectedNodeDetails) {
    return selectedNodeIds;
  }

  const newValues: Set<T> = new Set([
    ...selectedNodeIds,
    selectedNodeId,
    ...(selectedNodeDetails.childrenListId || []),
  ]);
  let handledNodeDetails: NodeDetails<T> | undefined = selectedNodeDetails;

  while (handledNodeDetails?.parentId) {
    const { parentId } = handledNodeDetails;
    const parentNodeData = nodeDetailsMap[parentId];

    if (!parentNodeData) {
      handledNodeDetails = undefined;
    } else {
      const notSelected = !newValues.has(parentId);
      const shouldBeSelected =
        !parentNodeData.leaf && parentNodeData.childrenListId.every(id => newValues.has(id));

      if (notSelected && shouldBeSelected) {
        handledNodeDetails = parentNodeData;
        newValues.add(parentId);
      } else {
        handledNodeDetails = undefined;
      }
    }
  }

  return Array.from(newValues);
}

export function deselectNode<T extends string | number>(
  nodeDetailsMap: NodeDetailsMap<T>,
  deselectedNodeId: T,
  selectedNodeIds: T[],
): T[] {
  const deselectedNodeDetails = nodeDetailsMap[deselectedNodeId];

  if (!deselectedNodeDetails) {
    return selectedNodeIds;
  }

  const { childrenListId } = deselectedNodeDetails;
  const newValues = new Set(
    selectedNodeIds.filter(id => id !== deselectedNodeId && !childrenListId.includes(id)),
  );
  let handledNodeDetails: NodeDetails<T> | undefined = deselectedNodeDetails;

  while (handledNodeDetails?.parentId) {
    const { parentId } = handledNodeDetails;
    const parentNodeData = nodeDetailsMap[parentId];

    if (!parentNodeData) {
      handledNodeDetails = undefined;
    } else {
      const selected = newValues.has(parentId);

      if (selected) {
        handledNodeDetails = parentNodeData;
        newValues.delete(parentId);
      } else {
        handledNodeDetails = undefined;
      }
    }
  }

  return Array.from(newValues);
}

export function getOptionKeyToLabelMap(root: TreeData<OptionType>): Record<string, string> {
  const map: Record<string, string> = {};

  treeTraversalInDepth(root, option => {
    map[option.node.value] = option.node.label;
  });

  return map;
}
