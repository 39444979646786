import { ActionType } from 'types';
import * as constants from './constants';

const initialState = {};

const authReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case constants.SET_USER:
      return { ...action.user };
    case constants.SET_USER_ERROR:
      return { ...state, error: action.error };
    case constants.CLEAR_USER:
      return {};
    default:
      return state;
  }
};

export default authReducer;
