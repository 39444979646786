import { UserState, AllPermissionsType, Roles, User } from 'modules/auth/types';

export const getUserFromTokenPayload = (token: string) => {
  try {
    const tokenPayload = token.split('.')[1];
    const user = JSON.parse(window.atob(tokenPayload));

    return user;
  } catch (error) {
    console.log(error);
  }
};

export const checkPermissions = ({
  user,
  permissionAccess,
  roleAccess,
}: {
  user: UserState;
  permissionAccess?: AllPermissionsType[];
  roleAccess?: Roles[];
}) => {
  if (!(user && user.id)) {
    return { hasCorrespondedPermission: false, hasCorrespondedRole: false };
  }
  const hasCorrespondedPermission = !!(
    permissionAccess &&
    permissionAccess.length &&
    user.role?.permissions.find(permission =>
      permissionAccess.find(item => item === permission.module),
    )
  );
  const hasCorrespondedRole = !!(
    roleAccess &&
    roleAccess.length &&
    roleAccess.find(role => role === user.role?.name)
  );
  return { hasCorrespondedPermission, hasCorrespondedRole };
};

export const isAdmin = (user: UserState) =>
  !!user.role && [Roles.admin, Roles.superAdmin].includes(user.role.name);

export const isSalesOrProductionRep = (user: UserState) =>
  !!user.role && [Roles.salesRep, Roles.productionRep].includes(user.role.name);

export const getUserFullName = (user?: User) => (user ? `${user.firstName} ${user.lastName}` : '');
