import { Typography } from 'antd';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import { I18nEnum } from 'types';
import { colors } from 'global-constants';

interface Props {
  icon?: React.ReactNode;
  title?: string;
  subtitle: string | React.ReactNode;
  button?: JSX.Element;
  className?: string;
}

const NoResults = ({
  icon = <img src="/images/svg/empty-catalog-icon.svg" height="58px" width="58px" />,
  title = I18nEnum.ThereIsNothingHereYet,
  subtitle,
  button,
  className,
}: Props) => {
  return (
    <Root className={className}>
      {icon}
      <Title className="no-results-title">
        {typeof title === 'string' && Object.keys(I18nEnum).includes(title) ? (
          <FormattedMessage id={title} />
        ) : (
          title
        )}
      </Title>
      <Subtitle className="no-results-subtitle">
        {typeof subtitle === 'string' && Object.keys(I18nEnum).includes(subtitle) ? (
          <FormattedMessage id={subtitle} />
        ) : (
          subtitle
        )}
      </Subtitle>
      {button}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
`;

const Title = styled(Typography)`
  color: ${colors.blue};
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  margin: 16px 0 8px;
`;

const Subtitle = styled(Typography)`
  color: ${colors.blue};
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.37px;
  text-align: center;
  margin-bottom: 24px;
`;

export default NoResults;
