// todo: comment these two out and see what happens?
export const SET_MAPBOX_ALL = 'quickQuote/SET_MAPBOX_ALL';
export const CLEAR = 'quickQuote/CLEAR';

export const SET_MAPBOX_IS_EDITING = 'mapbox/SET_MAPBOX_IS_EDITING';
export const SET_MAPBOX_HELP_TEXT = 'mapbox/SET_MAPBOX_HELP_TEXT';
export const SET_MAPBOX_IS_ADDING_POLYGONS = 'mapbox/SET_MAPBOX_IS_ADDING_POLYGONS';
export const SET_MAPBOX_IS_DELETING_POLYGONS = 'mapbox/SET_MAPBOX_IS_DELETING_POLYGONS';
export const SET_MAPBOX_IS_POLYGON_SELECTED = 'mapbox/SET_MAPBOX_IS_POLYGON_SELECTED';
export const SET_MAPBOX_NO_SELECTION_HELP_TEXT = 'mapbox/SET_MAPBOX_NO_SELECTION_HELP_TEXT';
export const SET_MAPBOX_VERTEX_HELP_TEXT = 'mapbox/SET_MAPBOX_VERTEX_HELP_TEXT';
export const SET_MAPBOX_POLYGON_HELP_TEXT = 'mapbox/SET_MAPBOX_POLYGON_HELP_TEXT';
export const SET_MAPBOX_DRAW_POLYGON_CLICK_COUNT = 'mapbox/SET_MAPBOX_DRAW_POLYGON_CLICK_COUNT';
export const CLEAR_MAPBOX = 'mapbox/CLEAR';

export const DRAW_MODE_SIMPLE_SELECT = 'simple_select' as string;
export const DRAW_MODE_DIRECT_SELECT = 'direct_select' as string;
export const DRAW_MODE_DRAW_POLYGON = 'draw_polygon' as string;

export const DRAW_SELECTION_NONE = 'none';
export const DRAW_SELECTION_SHAPE = 'shape';
export const DRAW_SELECTION_VERTEX = 'vertex';

export const MAP_CLICK_MAP = 'click_map';

export const DEFAULT_CENTERPOINT: [number, number] = [-98.085937, 40.058492];

export const mapboxMapId = 'mapboxgl';
