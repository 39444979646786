export enum SubscriptionTypeGeneral {
  RoofQuotePro = 'RQP_PRO',
  OwensCorningPro = 'OC_PRO',
}

export enum SubscriptionCountry {
  USA = 'UnitedStates',
  Canada = 'Canada',
}

export const SubscriptionTypes = {
  RoofQuoteProUSA: `${SubscriptionTypeGeneral.RoofQuotePro}_${SubscriptionCountry.USA}`,
  OwensCorningProUSA: `${SubscriptionTypeGeneral.OwensCorningPro}_${SubscriptionCountry.USA}`,
  RoofQuoteProCanada: `${SubscriptionTypeGeneral.RoofQuotePro}_${SubscriptionCountry.Canada}`,
  OwensCorningProCanada: `${SubscriptionTypeGeneral.OwensCorningPro}_${SubscriptionCountry.Canada}`,
} as const;

export type SubscriptionType = typeof SubscriptionTypes[keyof typeof SubscriptionTypes];

export enum SubscriptionPlan {
  MonthlySubscription = 'monthly',
  AnnualRollup = 'annual_rollup',
  AnnualDiscount = 'annual_discount',
}

export enum SubscriptionRequestType {
  Pause = 'pause',
  Activation = 'activate',
}

export interface Subscription {
  id: number;
  endDate: string;
  startDate: string;
  createdAt: string;
  updatedAt: string;
  activationRequested: boolean;
  cancelationRequested: boolean;
  status?: SubscriptionStatusEnum;
  type: SubscriptionTypeGeneral;
  plan: SubscriptionPlan | null;
  country: SubscriptionCountry;
}

export enum SubscriptionStatusEnum {
  active = 'active',
  inactive = 'inactive',
  upcoming = 'upcoming',
  paused = 'paused',
}
