import { I18nEnum, ListQuery, ThunkResult } from 'types';
import {
  services,
  constants,
  Company,
  UpsertCompanyUserBody,
  selectors as companySelectors,
  mutations,
} from '.';
import { selectors as userSelectors, types as userTypes } from 'modules/auth';
import { actions as spinnerActions } from 'modules/spinner';
import { actions as messageActions, MessageTypes } from 'modules/message';
import { ModalTypes, actions as modalActions } from 'modules/modal';
import { UserStatusEnum } from 'modules/auth/types';
import { PublicQuoteSettingsModel } from 'modules/quoteSettings/types';
import { getCompanyPublicQuoteSettingsApi } from 'modules/quoteSettings/services';
import { mapPublicQuoteSettingsFromDto } from 'modules/quoteSettings/serializers';
import { intl } from 'intl';

export const setCompany = (company: Company) => ({
  company,
  type: constants.SET_COMPANY,
});

export const setCompanyUsers = (
  users: userTypes.User[],
  total: number,
  primaryUser?: userTypes.User,
) => ({
  type: constants.SET_COMPANY_USERS,
  users,
  primaryUser,
  total,
});

export const setCompanyUsersQuery = (query: ListQuery) => ({
  type: constants.SET_COMPANY_USERS_QUERY,
  query,
});

export const setCompanyQuoteSettings = (quoteSettings: PublicQuoteSettingsModel) => ({
  type: constants.SET_COMPANY_QUOTE_SETTINGS,
  quoteSettings,
});

export const clearCompanyQuoteSettings = () => ({
  type: constants.CLEAR_COMPANY_QUOTE_SETTINGS,
});

export const getCompany =
  (): ThunkResult<Promise<Company | undefined>> => async (dispatch, getState) => {
    dispatch(spinnerActions.show());
    try {
      const user = userSelectors.selectUser(getState());

      const {
        data: { company, hsCompany },
      } = await services.getCompanyAPI(user.id);

      dispatch(setCompany({ ...company, hsCompany }));
      dispatch(spinnerActions.hide());
      return company;
    } catch (err) {
      dispatch(spinnerActions.hide());
    }
  };

export const getCompanyUsers = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch(spinnerActions.show());
  try {
    const state = getState();
    const user = userSelectors.selectUser(state);
    const query = companySelectors.selectCompanyUsersQuery(state);

    const { data } = await services.getCompanyUsersAPI(user.id, query);

    dispatch(setCompanyUsers(data.users, data.total, data.primaryUser));
    dispatch(spinnerActions.hide());
  } catch (err) {
    dispatch(setCompanyUsers([], 0));
    dispatch(spinnerActions.hide());
  }
};

export const changeUserStatus =
  (id: number, status: UserStatusEnum): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    dispatch(spinnerActions.show());
    try {
      const state = getState();
      const user = userSelectors.selectUser(state);

      await services.changeUserStatusAPI(user.id, id, status);
      await dispatch(getCompanyUsers());
    } catch (e) {}
    dispatch(spinnerActions.hide());
  };

export const upsertCompanyUser =
  ({
    body,
    isPrimaryContactChanged = false,
    shouldUpdateCompany = false,
  }: {
    body: UpsertCompanyUserBody;
    isPrimaryContactChanged?: boolean;
    shouldUpdateCompany?: boolean;
  }): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const message = {
      type: MessageTypes.success,
      text: body.id ? I18nEnum.UserChangesHasBeen : I18nEnum.NewUserHasBeen,
    };

    if (isPrimaryContactChanged) {
      message.text = I18nEnum.ContactHasBeenMadePrimaryContact;
    }

    dispatch(spinnerActions.show());
    try {
      const user = userSelectors.selectUser(getState());

      await services.upsertCompanyUserAPI(user.id, body);

      dispatch(getCompanyUsers());
      if (shouldUpdateCompany) {
        mutations.invalidateCompany();
      }

      dispatch(modalActions.closeModal());
    } catch (err) {
      if (err.code === 400) {
        dispatch(spinnerActions.hide());
        dispatch(
          modalActions.openModal(ModalTypes.warningModal, {
            action: () => dispatch(modalActions.closeModal()),
            subtitle: I18nEnum.UserChangesHasBeen,
            description: `${intl.formatMessage({ id: I18nEnum.UsersPermissionToAccessClp })} ${
              err.message
            }`,
            hideSecondaryBtn: true,
            primaryBtnText: I18nEnum.Ok,
          }),
        );
        dispatch(getCompanyUsers());
        return;
      }

      message.type = MessageTypes.error;
      message.text = body.id
        ? I18nEnum.UnfortunatelyTheUserChanges
        : I18nEnum.UnfortunatelyTheNewUser;

      if (isPrimaryContactChanged) {
        message.text = I18nEnum.UnfortunatelyTheContactHasNotBeenMadePrimary;
      }
    }

    dispatch(spinnerActions.hide());
    dispatch(messageActions.openMessage(message.type, message.text));
  };

export const resendUserPassword =
  (id: number): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const message = {
      type: MessageTypes.success,
      text: I18nEnum.PasswordHasBeenResent,
    };

    dispatch(spinnerActions.show());

    try {
      const user = userSelectors.selectUser(getState());

      await services.resendUserPasswordAPI(user.id, id);
      dispatch(modalActions.closeModal());
    } catch (err) {
      message.type = MessageTypes.error;
      message.text = I18nEnum.UnfortunatelyThePasswordHasNotBeenResent;
    }

    dispatch(spinnerActions.hide());
    dispatch(messageActions.openMessage(message.type, message.text));
  };

export const deleteCompanyUser =
  (id: number): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const message = {
      type: MessageTypes.success,
      text: I18nEnum.UserHasBeenDeletedSuccessfully,
    };

    dispatch(spinnerActions.show());
    try {
      const state = getState();
      const user = userSelectors.selectUser(state);
      const primaryUser = companySelectors.selectPrimaryUser(state);

      await services.deleteCompanyUserAPI(user.id, id);
      dispatch(getCompanyUsers());
      if (id === primaryUser?.id) {
        mutations.invalidateCompany();
      }
      dispatch(modalActions.closeModal());
    } catch (err) {
      message.type = MessageTypes.error;
      message.text = I18nEnum.UnfortunatelyTheUserHasNotBeenDeleted;
    }

    dispatch(spinnerActions.hide());
    dispatch(messageActions.openMessage(message.type, message.text));
  };

export const sendDeletionRequest = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const message = {
    type: MessageTypes.success,
    text: I18nEnum.YourRequestHasBeenSent,
  };
  dispatch(spinnerActions.show());
  try {
    const user = userSelectors.selectUser(getState());

    await services.sendDeletionRequestApi(user.id);

    mutations.invalidateCompany();
    dispatch(modalActions.closeModal());
  } catch (err) {
    message.type = MessageTypes.error;
    message.text = I18nEnum.UnfortunatelyTheRequestHasNot;
  }

  dispatch(spinnerActions.hide());
  dispatch(messageActions.openMessage(message.type, message.text));
};

export const getCompanyPublicQuoteSettings =
  (wildcard?: string): ThunkResult<Promise<void>> =>
  async dispatch => {
    try {
      if (!wildcard) return;

      const { data: quoteSettings } = await getCompanyPublicQuoteSettingsApi(wildcard);
      const companyPublicQuoteSettings = mapPublicQuoteSettingsFromDto(quoteSettings);

      dispatch(setCompanyQuoteSettings(companyPublicQuoteSettings));
    } catch (error) {
      dispatch(clearCompanyQuoteSettings());
    }
  };
