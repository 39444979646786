import { fontsMap } from './constants';
import { FontSettings, S3FontWeightsPathInterface } from './types';

export const setupRQPFonts = (rqpFonts: FontSettings[]): FontFace[] => {
  const addedFontsMap: Record<string, FontFace> = {};

  rqpFonts.forEach(({ fontName, fontWeight = 400 }) => {
    const primaryFontName = fontName.split(',')[0].trim().replace(/'/g, '');
    const fontPath = getClosestFont(fontsMap[primaryFontName], fontWeight);

    const font = new FontFace(
      primaryFontName,
      `url(${window.__RUNTIME_CONFIG__.REACT_APP_ASSETS}fonts/${fontPath})`,
      {
        weight: `${fontWeight}`,
      },
    );

    if (fontPath && !addedFontsMap[fontPath]) {
      document.fonts.add(font);
      addedFontsMap[fontPath] = font;
    }
  });

  return Object.values(addedFontsMap);
};

export const getClosestFont = (
  fontWeights: S3FontWeightsPathInterface = {},
  weight: number,
): string | null => {
  let closestFont: string | null = null;
  let closestWeight = Infinity;

  for (const fontWeight in fontWeights) {
    if (Math.abs(+fontWeight - weight) < Math.abs(closestWeight - weight)) {
      closestFont = fontWeights[fontWeight];
      closestWeight = +fontWeight;
    }
  }

  return closestFont;
};

export const renderElementAfterFontsLoaded = (
  element: HTMLElement,
  fonts: FontFace[],
  display = 'block',
): void => {
  const fontsPromises = fonts.map(font => font.load());
  Promise.all(fontsPromises).finally(() => {
    // if display is an empty string, display property will be reset
    element.style.display = display;
  });
};
