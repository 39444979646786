import { ActionType } from 'types';
import * as constants from './constants';
import { SearchState } from './types';
import { clearAddressStorage } from './storage';
import { DEFAULT_CENTERPOINT } from 'modules/mapbox/constants';

const initialState: SearchState = {
  results: [],
  address: '',
  addressFeature: { center: DEFAULT_CENTERPOINT, address: '' },
  hasError: false,
  errorMessage: null,
  searchStr: null,
  loaded: false,
  geoDataRegister: {},
  googleAutocompleteToken: undefined,
};

const searchReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case constants.SET_SEARCH_RESULTS:
      return {
        ...state,
        results: action.results,
      };
    case constants.SET_ADDRESS:
      return {
        ...state,
        address: action.address,
        loaded: true,
      };
    case constants.SET_ADDRESS_FEATURE:
      return {
        ...state,
        addressFeature: action.addressFeature,
      };
    case constants.SET_SEARCH:
      return {
        ...state,
        ...action.search,
        loaded: true,
      };
    case constants.CLEAR:
      clearAddressStorage();

      return {
        ...initialState,
        addressFeature: {
          ...initialState.addressFeature,
          center: action.center,
        },
        loaded: false,
        googleAutocompleteToken: state.googleAutocompleteToken,
        geoDataRegister: state.geoDataRegister,
      };
    case constants.SET_ERROR: {
      const { errorMessage, hasError } = action;

      return {
        ...state,
        hasError,
        errorMessage,
      };
    }
    case constants.SET_SEARCH_STR: {
      return {
        ...state,
        searchStr: action.searchStr,
      };
    }
    case constants.UPDATE_GEO_DATA_REGISTER: {
      return {
        ...state,
        geoDataRegister: {
          ...state.geoDataRegister,
          ...action.update,
        },
      };
    }
    case constants.SET_GOOGLE_API_SESSION_TOKEN: {
      return {
        ...state,
        googleAutocompleteToken: action.token,
      };
    }
    default:
      return state;
  }
};

export default searchReducer;
