import { renderToString } from 'react-dom/server';

import { SpinnerStateless } from 'modules/spinner/components/Spinner';
import { SPINNER_CONTAINER } from 'modules/widget/constants/toolScript';

export const createSpinner = () => {
  const spinnerHtml = renderToString(<SpinnerStateless />);

  const container = document.createElement('div');
  container.id = SPINNER_CONTAINER;
  container.innerHTML = spinnerHtml;

  return container;
};

export const removeSpinner = () => {
  const spinnerContainer = document.getElementById(SPINNER_CONTAINER);
  if (spinnerContainer) {
    spinnerContainer.remove();
  }
};

export const getAddressFields = (url: string) => {
  const addressFields = ['street1', 'city', 'state', 'postalCode'];
  const _url = new URL(url);
  const params = _url.search
    .replace('?', '')
    .split('&')
    .reduce((acc, param) => {
      const [key, value] = param.split('=');
      return { ...acc, [key]: decodeURIComponent(value) };
    }, {});

  const addressFeature = addressFields.reduce((acc, field) => {
    const queryParameter = params[field];
    if (queryParameter) {
      acc[field] = queryParameter;
    }
    return acc;
  }, {});

  return Object.keys(addressFeature).length === addressFields.length ? addressFeature : null;
};

export const getPercent = (x: number, wrapperWidth: number): number => {
  return (x / wrapperWidth) * 100;
};

export const getScrollLength = ({
  direction,
  scrollPageX,
  firstTachShift,
}: {
  direction: string;
  scrollPageX: number;
  firstTachShift: number;
}) => {
  return direction === 'right' ? scrollPageX - firstTachShift : firstTachShift - scrollPageX;
};

const SCROLLING_ATTRIBUTE = 'scrolling';

export const preventScrollingChanges = (iframe: HTMLIFrameElement) => {
  const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === SCROLLING_ATTRIBUTE &&
        iframe.getAttribute(SCROLLING_ATTRIBUTE)
      ) {
        iframe.removeAttribute(SCROLLING_ATTRIBUTE);
      }
    });
  });

  observer.observe(iframe, { attributes: true });
};
