import styled from 'styled-components';
import CloseIcon from '../../Icons/CloseIcon';

export interface TagProps extends React.HTMLAttributes<HTMLSpanElement> {
  className?: string;
  closable?: boolean;
  color?: 'primary';
  onClose?: (e: React.MouseEvent<HTMLElement>) => void;
  style?: React.CSSProperties;
}

const TagBase = styled.div`
  align-items: center;
  border-radius: 6px;
  border: none;
  box-sizing: content-box;
  display: inline-flex;
  font-size: 14px;
  height: 20px;
  justify-content: space-between;
  line-height: 20px;
  letter-spacing: 0.29px;
  padding: 6px 8px;
  overflow: hidden;

  ${props =>
    props.color === 'primary' &&
    `
      background-color: #daeff6;
      
      & {
        color: ${props.theme.colors.blue};
      }
      
      svg {
        margin-left: 8px;
      }
  `}
`;

const Tag = (props: TagProps) => {
  return (
    <TagBase
      className={props.className}
      style={props.style}
      color={props.color}
      onMouseOver={props.onMouseOver}
      onMouseLeave={props.onMouseLeave}>
      {props.children}
      {props.closable && <CloseIcon size={{ width: 7, height: 7 }} onClick={props.onClose} />}
    </TagBase>
  );
};

export default Tag;
