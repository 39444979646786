import { SearchAddressFeature } from './types';

const name = 'search/';

export const SET_SEARCH_RESULTS = `${name}SET_SEARCH_RESULTS`;
export const SET_ADDRESS = `${name}SET_ADDRESS`;
export const SET_ADDRESS_FEATURE = `${name}SET_ADDRESS_FEATURE`;
export const CLEAR = `${name}CLEAR`;
export const SET_ERROR = `${name}ERROR`;
export const SET_SEARCH_STR = `${name}SET_SEARCH_STR`;
export const SET_SEARCH = `${name}SET_SEARCH`;
export const UPDATE_GEO_DATA_REGISTER = `${name}GEO_DATA_REGISTER`;

export const SET_GOOGLE_API_SESSION_TOKEN = `${name}SET_GOOGLE_API_SESSION_TOKEN`;

const defaultAddress = '400 Marshall Avenue, Saint Paul, Minnesota 55102, USA';
const defaultCenter = [-93.11688, 44.94824];

export const DEFAULT_SEARCH = {
  address: defaultAddress,
  addressFeature: {
    address: defaultAddress,
    center: defaultCenter,
  } as SearchAddressFeature,
  searchStr: defaultAddress,
  results: [
    {
      address: defaultAddress,
      center: defaultCenter,
    } as SearchAddressFeature,
  ],
};
