import api from 'services/api';

const trackError = async (errM: string, err?: string | { message: string }) => {
  try {
    console.error(errM, err);
    await api.post('track-error', {
      errM: `${errM} ${typeof err === 'object' ? err.message : err}`,
    });
  } catch (e) {
    console.error(`Error in tracking error - ${e}`);
  }
};

export default trackError;

export const logMessage = async (errM: string, err?: string | { message: string }) => {
  try {
    console.log(errM, err);
    // todo: would like to add a 'track-message' endpoint for regular logging
    await api.post('track-error', {
      errM: `${errM} ${typeof err === 'object' ? err.message : err}`,
    });
  } catch (e) {
    console.log(`Error in 'track-error' endpoint - ${e}`);
  }
};
