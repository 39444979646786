import { PriceConfig } from './priceConfig';

export class MonthlyPrice {
  constructor(priceConfig: PriceConfig) {
    this.priceConfig = priceConfig;
  }

  private readonly priceConfig: PriceConfig;

  calculateMonthlyPrice(totalPrice): number {
    return +(totalPrice * this.priceConfig.paymentMultiplier).toFixed(0);
  }
}
