import { createIntl, createIntlCache } from 'react-intl';

import locales from 'i18n';

const locale = 'en';
const cache = createIntlCache();

export const intl = createIntl(
  {
    locale,
    messages: locales,
  },
  cache,
);
