import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { I18nEnum } from 'types';
import { ArrowIconImg } from 'components/Icons/ArrowIcon';

const SeeDefaultDesctiption = ({
  expanded,
  description,
  className,
  onClick,
}: {
  expanded: boolean;
  description: string | JSX.Element;
  className?: string;
  onClick: () => void;
}) => {
  return (
    <Root className={className}>
      <SeeDefaultDescriptionToggler onClick={onClick}>
        <FormattedMessage id={I18nEnum.SeeDefaultDescription} />
        <ArrowIcon isActive={expanded} />
      </SeeDefaultDescriptionToggler>
      {expanded && <DefaultDescription>{description}</DefaultDescription>}
    </Root>
  );
};

const Root = styled.div``;

const SeeDefaultDescriptionToggler = styled.div`
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const DefaultDescription = styled.p`
  &,
  & p,
  & li {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.29px;
    color: rgba(0, 0, 0, 0.6);
  }
  margin: 8px 0 0;
`;

const ArrowIcon = styled(ArrowIconImg)`
  margin-left: 13px;
`;

export default SeeDefaultDesctiption;
