import { onEnterFireAction } from 'modules/hooks/keyboardAction';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { I18nEnum } from 'types';

interface CornerRadiusProps {
  onChange?: (radius: number) => void;
  value?: number;
}

const MAXIMUM_RADIUS = 30;

const CornerRadiusSetting: React.FC<CornerRadiusProps> = ({ onChange, value: _value = 0 }) => {
  const [radius, setRadius] = useState(_value);

  useEffect(() => {
    setRadius(_value);
  }, [_value]);

  const handleChangeRadius = useCallback(event => {
    const { value } = event.target;

    if (isNaN(value)) {
      return;
    }

    setRadius(value);
  }, []);

  const handleBlurChanges = useCallback(
    event => {
      const { value } = event.target;

      if (isNaN(value)) {
        return;
      }

      let _radius = Number(Number(value).toFixed(0));

      if (radius === _radius) {
        return;
      }

      if (Number(value) > MAXIMUM_RADIUS) {
        _radius = MAXIMUM_RADIUS;
      }

      if (isNaN(value)) {
        _radius = 0;
      }

      setRadius(_radius);
      onChange && onChange(_radius);
    },
    [onChange, radius],
  );

  return (
    <Root>
      <Label>
        <FormattedMessage id={I18nEnum.CornerRadius} />
      </Label>
      <RadiusContainer>
        <RadiusWrapper>
          <Radius radius={radius} />
        </RadiusWrapper>
        <RadiusInput
          max={3}
          value={radius}
          onKeyDown={onEnterFireAction(handleBlurChanges)}
          onChange={handleChangeRadius}
          onBlur={handleBlurChanges}
        />
      </RadiusContainer>
    </Root>
  );
};

const Root = styled.div``;

export const RadiusContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  height: 42px;
  padding: 0 10px;
  border: 1px solid ${props => props.theme.colors.grey};
  border-radius: 6px;
  transition: border-color 0.2s linear;

  &:focus-within {
    border: 1px solid ${props => props.theme.colors.orange};
  }
`;

const RadiusWrapper = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
`;

const Radius = styled.div<{ radius: number }>`
  position: absolute;
  left: 0;
  right: 0;
  width: 50px;
  height: 50px;
  border-top: 2px solid ${props => props.theme.colors.grey};
  border-left: 2px solid ${props => props.theme.colors.grey};
  border-radius: ${props => props.radius}px;
`;

const RadiusInput = styled.input`
  border: 0;
  width: 35px;
`;

const Label = styled.span`
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
`;

export default CornerRadiusSetting;
