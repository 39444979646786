import { ActionType } from 'types';
import * as constants from './constants';
import { MessageState } from './types';

const initialState: MessageState = {
  opened: false,
  type: '',
  text: '',
};

const messageReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case constants.OPEN:
      return { ...state, type: action.messageType, opened: true, text: action.text };
    case constants.CLOSE:
      return { ...initialState };
    default:
      return state;
  }
};

export default messageReducer;
