import { StructureSlopeEnum } from 'modules/quickQuote';
import { CompanyMarket } from 'modules/markets';
import {
  DifficultAccess,
  NewDecking,
  PricePerUnitOfMeasurement,
  PriceRangeType,
  PriceType,
  Quantity,
  RoofCondition,
  RoofType,
} from 'modules/global/types';

export enum QuoteFeature {
  InstantQuotes = 'instant-quotes',
  AdditionalCosts = 'additional-costs',
}

export enum InstantQuotesFormKey {
  SteepSlopeChargeEnabled = 'steepSlopeChargeEnabled',
  SteepSlopeChargeValue = 'steepSlopeChargeValue',
  SteepSlopeChargePriceType = 'steepSlopeChargeType',
  WasteFactorEnabled = 'wasteFactorEnabled',
  WasteFactorBasedOnFacetCountEnabled = 'wasteFactorEnabledBasedOnFacetCountEnabled',
  WasteFactorValue = 'wasteFactorValue',
  PriceRangeEnabled = 'priceRangeEnabled',
  PriceRangeValue = 'priceRangeValue',
  PriceRangeType = 'priceRangeType',
  DefaultSlope = 'defaultSlope',
  SlopeByMarket = 'slopeByMarket',
  ApplyDefaultSlopeToAllMarkets = 'applyDefaultSlopeToAllMarkets',
  LastSelectedMarket = 'lastSelectedMarket',
  SquareFootageEnabled = 'squareFootageEnabled',
  SuggestedSlopeEnabled = 'suggestedSlopeEnabled',
}

export enum AdditionalCostsFormKey {
  StoryCostsEnabled = 'storyCostsEnabled',
  TwoStoriesCost = 'twoStoriesCost',
  TwoStoriesCostType = 'twoStoriesCostType',
  ManyStoriesCost = 'manyStoriesCost',
  ManyStoriesCostType = 'manyStoriesCostType',
  LayerCostsEnabled = 'layerCostsEnabled',
  TwoLayersCost = 'twoLayersCost',
  TwoLayersCostType = 'twoLayersCostType',
  ManyLayersCost = 'manyLayersCost',
  ManyLayersCostType = 'manyLayersCostType',
  ChimneyCostsEnabled = 'chimneyCostsEnabled',
  ChimneyFlashingCost = 'chimneyFlashingCost',
  TwoChimneysFlashingCost = 'twoChimneysFlashingCost',
  ManyChimneysFlashingCost = 'manyChimneysFlashingCost',
  SkylightCostsEnabled = 'skylightCostsEnabled',
  SkylightFlashingCost = 'skylightFlashingCost',
  TwoSkylightsFlashingCost = 'twoSkylightsFlashingCost',
  ManySkylightsFlashingCost = 'manySkylightsFlashingCost',
  RoofConditionCostsEnabled = 'roofConditionCostsEnabled',
  AlgaeStainsCost = 'algaeStainsCost',
  AlgaeStainsCostType = 'algaeStainsCostType',
  HailDamageCost = 'hailDamageCost',
  HailDamageCostType = 'hailDamageCostType',
  WindDamageCost = 'windDamageCost',
  WindDamageCostType = 'windDamageCostType',
  MissingShinglesCost = 'missingShinglesCost',
  MissingShinglesCostType = 'missingShinglesCostType',
  RoofLeaksCost = 'roofLeaksCost',
  RoofLeaksCostType = 'roofLeaksCostType',
  SaggingCost = 'saggingCost',
  SaggingCostType = 'saggingCostType',
  StructuralDamageCost = 'structuralDamageCost',
  StructuralDamageCostType = 'structuralDamageCostType',
  RoofTypeCostsEnabled = 'roofTypeCostsEnabled',
  AsphaltShingleRoofCost = 'asphaltShingleRoofCost',
  AsphaltShingleRoofCostType = 'asphaltShingleRoofCostType',
  MetalRoofCost = 'metalRoofCost',
  MetalRoofCostType = 'metalRoofCostType',
  SyntheticRoofCost = 'syntheticRoofCost',
  SyntheticRoofCostType = 'syntheticRoofCostType',
  TileRoofCost = 'tileRoofCost',
  TileRoofCostType = 'tileRoofCostType',
  WoodRoofCost = 'woodRoofCost',
  WoodRoofCostType = 'woodRoofCostType',
  NewDeckingCostsEnabled = 'newDeckingCostsEnabled',
  PartialNewDeckingCost = 'partialNewDeckingCost',
  FullDeckingReplacementCost = 'fullDeckingReplacementCost',
  DifficultAccessCostsEnabled = 'difficultAccessCostsEnabled',
  DifficultAccessCost = 'difficultAccessCost',
  DifficultAccessCostType = 'difficultAccessCostType',
  OtherExtraCostsEnabled = 'extraCostsEnabled',
  OtherExtraCosts = 'extraCosts',
}

export enum ExtraCostFormKeys {
  Question = 'question',
  OptionName = 'optionName',
  Answers = 'answers',
  Cost = 'cost',
  UnitOfMeasurement = 'unit',
  CostType = 'costType',
  CustomUnitName = 'customUnitName',
  MultiChoice = 'multiple',
  Id = 'id',
}

export interface ExtraCostAnswer {
  [ExtraCostFormKeys.OptionName]: string;
  [ExtraCostFormKeys.Cost]: string;
  [ExtraCostFormKeys.UnitOfMeasurement]: PricePerUnitOfMeasurement;
  [ExtraCostFormKeys.CustomUnitName]?: string;
  [ExtraCostFormKeys.CostType]?: PriceType;
  [ExtraCostFormKeys.Id]?: number;
}

export interface ExtraCost {
  [ExtraCostFormKeys.Question]: string;
  [ExtraCostFormKeys.MultiChoice]: boolean;
  [ExtraCostFormKeys.Id]?: number;
  [ExtraCostFormKeys.Answers]: ExtraCostAnswer[];
}

export type QuoteSettingsFormValues = StaticQuoteSettingsFormValues & {
  [InstantQuotesFormKey.DefaultSlope]: StructureSlopeEnum;
  [InstantQuotesFormKey.ApplyDefaultSlopeToAllMarkets]: boolean;
  [InstantQuotesFormKey.SlopeByMarket]: Record<string, StructureSlopeEnum>;
  [InstantQuotesFormKey.LastSelectedMarket]: string;
};

export interface CompanyPublicQuoteSettingsFormValues {
  [InstantQuotesFormKey.SteepSlopeChargeEnabled]: boolean;
  [InstantQuotesFormKey.SteepSlopeChargeValue]: string;
  [InstantQuotesFormKey.SteepSlopeChargePriceType]: PriceType;
  [InstantQuotesFormKey.WasteFactorEnabled]: boolean;
  [InstantQuotesFormKey.WasteFactorBasedOnFacetCountEnabled]: boolean;
  [InstantQuotesFormKey.WasteFactorValue]: string;
  [InstantQuotesFormKey.PriceRangeEnabled]: boolean;
  [InstantQuotesFormKey.PriceRangeValue]: number;
  [InstantQuotesFormKey.PriceRangeType]: PriceRangeType;
  [InstantQuotesFormKey.SuggestedSlopeEnabled]: boolean;
  [InstantQuotesFormKey.SquareFootageEnabled]: boolean;
}
export interface StaticQuoteSettingsFormValues extends CompanyPublicQuoteSettingsFormValues {
  [AdditionalCostsFormKey.StoryCostsEnabled]: boolean;
  [AdditionalCostsFormKey.TwoStoriesCost]: string;
  [AdditionalCostsFormKey.TwoStoriesCostType]: PriceType;
  [AdditionalCostsFormKey.ManyStoriesCost]: string;
  [AdditionalCostsFormKey.ManyStoriesCostType]: PriceType;
  [AdditionalCostsFormKey.LayerCostsEnabled]: boolean;
  [AdditionalCostsFormKey.TwoLayersCost]: string;
  [AdditionalCostsFormKey.TwoLayersCostType]: PriceType;
  [AdditionalCostsFormKey.ManyLayersCost]: string;
  [AdditionalCostsFormKey.ManyLayersCostType]: PriceType;
  [AdditionalCostsFormKey.ChimneyCostsEnabled]: boolean;
  [AdditionalCostsFormKey.ChimneyFlashingCost]: string;
  [AdditionalCostsFormKey.TwoChimneysFlashingCost]: string;
  [AdditionalCostsFormKey.ManyChimneysFlashingCost]: string;
  [AdditionalCostsFormKey.SkylightCostsEnabled]: boolean;
  [AdditionalCostsFormKey.SkylightFlashingCost]: string;
  [AdditionalCostsFormKey.TwoSkylightsFlashingCost]: string;
  [AdditionalCostsFormKey.ManySkylightsFlashingCost]: string;
  [AdditionalCostsFormKey.RoofConditionCostsEnabled]: boolean;
  [AdditionalCostsFormKey.AlgaeStainsCost]: string;
  [AdditionalCostsFormKey.AlgaeStainsCostType]: PriceType;
  [AdditionalCostsFormKey.HailDamageCost]: string;
  [AdditionalCostsFormKey.HailDamageCostType]: PriceType;
  [AdditionalCostsFormKey.WindDamageCost]: string;
  [AdditionalCostsFormKey.WindDamageCostType]: PriceType;
  [AdditionalCostsFormKey.MissingShinglesCost]: string;
  [AdditionalCostsFormKey.MissingShinglesCostType]: PriceType;
  [AdditionalCostsFormKey.RoofLeaksCost]: string;
  [AdditionalCostsFormKey.RoofLeaksCostType]: PriceType;
  [AdditionalCostsFormKey.SaggingCost]: string;
  [AdditionalCostsFormKey.SaggingCostType]: PriceType;
  [AdditionalCostsFormKey.StructuralDamageCost]: string;
  [AdditionalCostsFormKey.StructuralDamageCostType]: PriceType;
  [AdditionalCostsFormKey.RoofTypeCostsEnabled]: boolean;
  [AdditionalCostsFormKey.AsphaltShingleRoofCost]: string;
  [AdditionalCostsFormKey.AsphaltShingleRoofCostType]: PriceType;
  [AdditionalCostsFormKey.MetalRoofCost]: string;
  [AdditionalCostsFormKey.MetalRoofCostType]: PriceType;
  [AdditionalCostsFormKey.SyntheticRoofCost]: string;
  [AdditionalCostsFormKey.SyntheticRoofCostType]: PriceType;
  [AdditionalCostsFormKey.TileRoofCost]: string;
  [AdditionalCostsFormKey.TileRoofCostType]: PriceType;
  [AdditionalCostsFormKey.WoodRoofCost]: string;
  [AdditionalCostsFormKey.WoodRoofCostType]: PriceType;
  [AdditionalCostsFormKey.NewDeckingCostsEnabled]: boolean;
  [AdditionalCostsFormKey.PartialNewDeckingCost]: string;
  [AdditionalCostsFormKey.FullDeckingReplacementCost]: string;
  [AdditionalCostsFormKey.DifficultAccessCostsEnabled]: boolean;
  [AdditionalCostsFormKey.DifficultAccessCost]: string;
  [AdditionalCostsFormKey.DifficultAccessCostType]: PriceType;
  [AdditionalCostsFormKey.OtherExtraCosts]?: ExtraCost[];
  [AdditionalCostsFormKey.OtherExtraCostsEnabled]: boolean;
}

export interface PublicQuoteSettingsDto {
  steepSlopeChargeEnabled: boolean;
  steepSlopeChargeValue: number | null;
  steepSlopeChargeType: PriceType | null;
  wasteFactorEnabled: boolean;
  wasteFactorValue: number | null;
  wasteFactorBasedOnFacetCountEnabled: boolean;
  priceRangeEnabled: boolean;
  priceRangeValue: number | null;
  priceRangeType: PriceRangeType | null;
  suggestedSlopeEnabled: boolean;
  squareFootageEnabled: boolean;
}

export interface QuoteSettingsDto extends PublicQuoteSettingsDto {
  id?: number;
  storyCostsEnabled: boolean;
  twoStoriesCost: number | null;
  twoStoriesCostType: PriceType | null;
  manyStoriesCost: number | null;
  manyStoriesCostType: PriceType | null;
  layerCostsEnabled: boolean;
  twoLayersCost: number | null;
  twoLayersCostType: PriceType | null;
  manyLayersCost: number | null;
  manyLayersCostType: PriceType | null;
  chimneyCostsEnabled: boolean;
  chimneyFlashingCost: number | null;
  twoChimneysFlashingCost: number | null;
  manyChimneysFlashingCost: number | null;
  skylightCostsEnabled: boolean;
  skylightFlashingCost: number | null;
  twoSkylightsFlashingCost: number | null;
  manySkylightsFlashingCost: number | null;
  roofConditionCostsEnabled: boolean;
  algaeStainsCost: number | null;
  algaeStainsCostType: PriceType | null;
  hailDamageCost: number | null;
  hailDamageCostType: PriceType | null;
  windDamageCost: number | null;
  windDamageCostType: PriceType | null;
  missingShinglesCost: number | null;
  missingShinglesCostType: PriceType | null;
  roofLeaksCost: number | null;
  roofLeaksCostType: PriceType | null;
  saggingCost: number | null;
  saggingCostType: PriceType | null;
  structuralDamageCost: number | null;
  structuralDamageCostType: PriceType | null;
  roofTypeCostsEnabled: boolean;
  asphaltShingleRoofCost: number | null;
  asphaltShingleRoofCostType: PriceType | null;
  metalRoofCost: number | null;
  metalRoofCostType: PriceType | null;
  syntheticRoofCost: number | null;
  syntheticRoofCostType: PriceType | null;
  tileRoofCost: number | null;
  tileRoofCostType: PriceType | null;
  woodRoofCost: number | null;
  woodRoofCostType: PriceType | null;
  newDeckingCostsEnabled: boolean;
  partialNewDeckingCost: number | null;
  fullDeckingReplacementCost: number | null;
  difficultAccessCostsEnabled: boolean;
  difficultAccessCost: number | null;
  difficultAccessCostType: PriceType | null;
  extraCostsEnabled: boolean;
  extraCosts?: {
    id?: number;
    question: string;
    multiple: boolean;
    order: number;
    answers: {
      id?: number;
      optionName: string;
      cost: number;
      unitOfMeasurement: PricePerUnitOfMeasurement;
      customUnitName: string;
      costType: PriceType | null;
      order: number;
    }[];
  }[];
}

export interface PublicQuoteSettingsModel {
  steepSlopeChargeEnabled: boolean;
  steepSlopeChargeValue: number;
  steepSlopeChargeType: PriceType;
  wasteFactorEnabled: boolean;
  wasteFactorValue: number;
  wasteFactorBasedOnFacetCountEnabled: boolean;
  priceRangeEnabled: boolean;
  priceRangeValue: number;
  priceRangeType: PriceRangeType;
  suggestedSlopeEnabled: boolean;
  squareFootageEnabled: boolean;
}
export interface QuoteSettingsModel extends PublicQuoteSettingsModel {
  id?: number;
  storyCostsEnabled: boolean;
  twoStoriesCost: number;
  twoStoriesCostType: PriceType;
  manyStoriesCost: number;
  manyStoriesCostType: PriceType;
  layerCostsEnabled: boolean;
  twoLayersCost: number;
  twoLayersCostType: PriceType;
  manyLayersCost: number;
  manyLayersCostType: PriceType;
  chimneyCostsEnabled: boolean;
  chimneyFlashingCost: number;
  twoChimneysFlashingCost: number;
  manyChimneysFlashingCost: number;
  skylightCostsEnabled: boolean;
  skylightFlashingCost: number;
  twoSkylightsFlashingCost: number;
  manySkylightsFlashingCost: number;
  roofConditionCostsEnabled: boolean;
  algaeStainsCost: number;
  algaeStainsCostType: PriceType;
  hailDamageCost: number;
  hailDamageCostType: PriceType;
  windDamageCost: number;
  windDamageCostType: PriceType;
  missingShinglesCost: number;
  missingShinglesCostType: PriceType;
  roofLeaksCost: number;
  roofLeaksCostType: PriceType;
  saggingCost: number;
  saggingCostType: PriceType;
  structuralDamageCost: number;
  structuralDamageCostType: PriceType;
  roofTypeCostsEnabled: boolean;
  asphaltShingleRoofCost: number;
  asphaltShingleRoofCostType: PriceType;
  metalRoofCost: number;
  metalRoofCostType: PriceType;
  syntheticRoofCost: number;
  syntheticRoofCostType: PriceType;
  tileRoofCost: number;
  tileRoofCostType: PriceType;
  woodRoofCost: number;
  woodRoofCostType: PriceType;
  newDeckingCostsEnabled: boolean;
  partialNewDeckingCost: number;
  fullDeckingReplacementCost: number;
  difficultAccessCostsEnabled: boolean;
  difficultAccessCost: number;
  difficultAccessCostType: PriceType;
  extraCostsEnabled: boolean;
  extraCosts?: {
    id?: number;
    question: string;
    multiple: boolean;
    order: number;
    answers: {
      id?: number;
      optionName: string;
      cost: number;
      unitOfMeasurement: PricePerUnitOfMeasurement;
      customUnitName: string;
      costType: PriceType | null;
      order: number;
    }[];
  }[];
}

export interface QuoteSettingsFormModel {
  id?: number;
  formValues: StaticQuoteSettingsFormValues;
  additionalCostsEnabled: boolean;
}

export interface MarketQuoteSettingsDto {
  marketSlug: CompanyMarket['slug'];
  marketId: CompanyMarket['id'];
  slope?: StructureSlopeEnum;
}

export interface MarketQuoteSettingsModel {
  marketSlug: CompanyMarket['slug'];
  marketId: CompanyMarket['id'];
  slope: StructureSlopeEnum;
}

export interface GroupValidationResult {
  extraCostsInvalid: boolean;
  instantQoutesFeatureFieldsInvalid: boolean;
  additionalCostsFeatureFieldsInvalid: boolean;
}

export enum AddAdditionalCostsFormKey {
  StoryCost = 'storyCosts',
  LayerCost = 'layerCosts',
  ChimneyFlashingCost = 'chimneyFlashingCost',
  SkylightFlashingCost = 'skylightFlashingCost',
  RoofConditionCosts = 'roofConditionCosts',
  RoofTypeCost = 'roofTypeCost',
  NewDeckingCost = 'newDeckingCost',
  DifficultAccessCost = 'difficultAccessCost',
  OtherExtraCosts = 'otherExtraCosts',
}

export enum AdditionalCostQuantityFormKey {
  NewDeckingQuantity = 'newDeckingQuantity',
  OtherExtraCostsQuantity = 'otherExtraCostsQuantity',
}

export interface AddAdditionalCostsFormValues {
  [AddAdditionalCostsFormKey.StoryCost]: Quantity;
  [AddAdditionalCostsFormKey.LayerCost]: Quantity;
  [AddAdditionalCostsFormKey.ChimneyFlashingCost]: Quantity;
  [AddAdditionalCostsFormKey.SkylightFlashingCost]: Quantity;
  [AddAdditionalCostsFormKey.RoofConditionCosts]: RoofCondition[];
  [AddAdditionalCostsFormKey.RoofTypeCost]: RoofType;
  [AddAdditionalCostsFormKey.NewDeckingCost]: NewDecking;
  [AddAdditionalCostsFormKey.DifficultAccessCost]: DifficultAccess;
  [AddAdditionalCostsFormKey.OtherExtraCosts]?: {
    [key: string]: number | number[];
  };
  [AdditionalCostQuantityFormKey.NewDeckingQuantity]?: string;
  [AdditionalCostQuantityFormKey.OtherExtraCostsQuantity]?: {
    [key: string]: Record<string, string>;
  };
}

interface BasicCost {
  type: PriceType;
  value: number;
}

export interface OtherCost {
  id: string;
  type: string;
  value: number;
  name: string;
}

export interface FormattedQuoteSettings {
  [AddAdditionalCostsFormKey.StoryCost]: Partial<
    Record<AddAdditionalCostsFormValues[AddAdditionalCostsFormKey.StoryCost], BasicCost>
  >;
  [AddAdditionalCostsFormKey.LayerCost]: Partial<
    Record<AddAdditionalCostsFormValues[AddAdditionalCostsFormKey.LayerCost], BasicCost>
  >;
  [AddAdditionalCostsFormKey.ChimneyFlashingCost]: Partial<
    Record<AddAdditionalCostsFormValues[AddAdditionalCostsFormKey.ChimneyFlashingCost], BasicCost>
  >;
  [AddAdditionalCostsFormKey.SkylightFlashingCost]: Partial<
    Record<AddAdditionalCostsFormValues[AddAdditionalCostsFormKey.SkylightFlashingCost], BasicCost>
  >;
  [AddAdditionalCostsFormKey.RoofConditionCosts]: Partial<Record<RoofCondition, BasicCost>>;
  [AddAdditionalCostsFormKey.RoofTypeCost]: Partial<
    Record<AddAdditionalCostsFormValues[AddAdditionalCostsFormKey.RoofTypeCost], BasicCost>
  >;
  [AddAdditionalCostsFormKey.NewDeckingCost]: Partial<
    Record<AddAdditionalCostsFormValues[AddAdditionalCostsFormKey.NewDeckingCost], BasicCost>
  >;
  [AddAdditionalCostsFormKey.DifficultAccessCost]: Partial<
    Record<AddAdditionalCostsFormValues[AddAdditionalCostsFormKey.DifficultAccessCost], BasicCost>
  >;
  [AddAdditionalCostsFormKey.OtherExtraCosts]: Record<string, Record<number, OtherCost>>;
}
