import { constants } from '.';
import { MarketState } from './types';

const defaultMarketState: MarketState = {
  markets: [],
  logisticsMarkets: [],
  marketsList: [],
  states: [],
  userStates: [],
  countryStatesFeatureCollection: null,
};

const MarketReducer = (state = defaultMarketState, action) => {
  switch (action.type) {
    case constants.SET_MARKETS: {
      const markets = action.markets;
      let selectedMarket = state.selectedMarket;

      if (action.replaceSelectedMarket) {
        selectedMarket = !selectedMarket
          ? markets[0]
          : markets.find(_market => _market.market.slug === selectedMarket?.market.slug);
      }

      return {
        ...state,
        markets: action.markets,
        selectedMarket,
      };
    }
    case constants.SET_LOGISTICS_MARKETS: {
      return {
        ...state,
        logisticsMarkets: action.logisticsMarkets,
      };
    }
    case constants.SET_SELECTED_MARKET: {
      return {
        ...state,
        selectedMarket: action.selectedMarket,
      };
    }
    case constants.SET_MARKETS_LIST: {
      return {
        ...state,
        marketsList: action.marketsList,
      };
    }
    case constants.SET_STATES: {
      return {
        ...state,
        states: action.states,
      };
    }
    case constants.SET_USERS_STATES: {
      return {
        ...state,
        userStates: action.usersStates,
      };
    }
    case constants.SET_COUNTRY_STATES_FEATURE_COLLECTION: {
      return {
        ...state,
        countryStatesFeatureCollection: action.countryStatesFeatureCollection,
      };
    }
    case constants.CLEAR_MARKETS: {
      return defaultMarketState;
    }
    default:
      return state;
  }
};

export default MarketReducer;
