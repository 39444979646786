import { GoogleMapsState } from './types';
import * as constants from './constants';
import { ThunkResult } from 'types';
import { googleMapsCreateMap } from './utils';
import { setMapboxState } from 'modules/mapbox/actions';

export const setGoogleMapsIsReady = ({ isReady }: { isReady: GoogleMapsState['isReady'] }) => ({
  isReady,
  type: constants.SET_GOOGLE_MAPS_IS_READY,
});

export const setGoogleMapsIsEditing = ({
  isEditing,
}: {
  isEditing: GoogleMapsState['isEditing'];
}) => ({
  isEditing,
  type: constants.SET_GOOGLE_MAPS_IS_EDITING,
});

export const setGoogleMapsIsAddingPolygons = ({
  isAddingPolygons,
}: {
  isAddingPolygons: GoogleMapsState['isAddingPolygons'];
}) => ({
  isAddingPolygons,
  type: constants.SET_GOOGLE_MAPS_IS_ADDING_POLYGONS,
});

export const setGoogleMapsIsPolygonSelected = ({
  isPolygonSelected,
}: {
  isPolygonSelected: GoogleMapsState['isPolygonSelected'];
}) => ({
  isPolygonSelected,
  type: constants.SET_GOOGLE_MAPS_IS_POLYGON_SELECTED,
});

export const updateGoogleMapsDrawPolygonClickCount = (polygonClickCount?: number) => ({
  polygonClickCount,
  type: constants.SET_GOOGLE_MAPS_DRAW_POLYGON_CLICK_COUNT,
});

export const setGoogleMapsVertexHelpText = () => ({
  type: constants.SET_GOOGLE_MAPS_VERTEX_HELP_TEXT,
});

export const setGoogleMapsPolygonHelpText = () => ({
  type: constants.SET_GOOGLE_MAPS_POLYGON_HELP_TEXT,
});

export const setGoogleMapsNoSelectionHelpText = () => ({
  type: constants.SET_GOOGLE_MAPS_NO_SELECTION_HELP_TEXT,
});

export const initGoogleMap =
  ({ centerpoint }: { centerpoint: [number, number] }): ThunkResult<Promise<void>> =>
  async dispatch => {
    const { initialStructures, mapboxMap } = await googleMapsCreateMap({
      centerpoint,
    });

    dispatch(setGoogleMapsIsReady({ isReady: true }));

    dispatch(
      setMapboxState({
        structures: initialStructures,
        mapState: {
          mapboxMap,
        },
      }),
    );
  };

export const clearGoogleMap = () => ({
  type: constants.CLEAR_GOOGLE_MAPS,
});
