import { State } from 'types';

export const selecProduct = (state: State) => state.product;
export const selectSelectedProducts = (state: State) => selecProduct(state).selectedProducts;
export const selectInitialSelectedProducts = (state: State) =>
  selecProduct(state).initialSelectedProducts;
export const selectEdited = (state: State) => selecProduct(state).edited;
export const selectLabels = (state: State) => selecProduct(state).labels;
export const selectUpsertCardView = (state: State) => selecProduct(state).upsertCardView;
export const selectUpsertedProduct = (state: State) => selecProduct(state).upsertedProduct;
export const selectUpsertedProductInd = (state: State) => selecProduct(state).upsertedProductInd;
export const selectNewFinancingType = (state: State) => selecProduct(state).newFinancingType;

export const selectWidgetProductsSettings = (state: State) => state.widgetProduct;
