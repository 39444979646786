import cloneDeep from 'lodash/cloneDeep';
import store from 'store';

import { ActionType, ThunkResult } from 'types';
import { actions as spinnerActions } from 'modules/spinner';
import {
  constants,
  services,
  selectors,
  RoofsPredictionPayload,
  MLPredictionDataType,
  MapProviderType,
} from '.';
import {
  RoofleStructure,
  ParcelState,
  GeoJsonPolygon,
  StructureSlope,
  QuickQuoteUserInfo,
  StructureMetaDataResponse,
} from './types';
import { StructuresAction, ErrorAction } from './reducer-types';
import { clearQuickQuoteStorage, getStatisticsIdFromStorage } from './storage';
import { decryptQQ } from './_utils';
import trackError from 'services/trackError';
import { selectors as widgetSelectors, services as widgetServices } from 'modules/widget';
import { actions as messageActions, MessageTypes } from 'modules/message';
import { selectors as marketsSelectors, actions as marketsActions } from 'modules/markets';
import { CLPFinancingVariants, PriceInfo, actions as financingActions } from 'modules/financing';
import { actions as modalActions, ModalTypes } from 'modules/modal';
import { Statistics } from 'modules/dashboard/types';
import idb from 'services/idb';
import {
  LEAD_CREATED_MESSAGE,
  STORAGE_ANALYTICS,
  STORAGE_BROADCAST_CHANNEL,
  STORAGE_STATISTICS_ID_ALIAS,
  STORAGE_STATISTICS_ID_EXPIRATION_DATE_ALIAS,
} from 'modules/global/constants';
import {
  getSalesEmailParam,
  getSalesRabbitParams,
} from 'modules/quickQuote/hooks/useQuickQuoteQueryParams';
import { DEFAULT_GEODATA_STATE } from './constants';
import {
  dataUrlToImageFile,
  encryptQuickQuoteAdditionalInformation,
  formatStructuresForML,
  resetMapInstances,
  setDefaultCustomSquareFeetToStructure,
  setDefaultWasteFactorToStructure,
} from 'modules/quickQuote/utils';
import { QuickQuoteProductPriceSettings } from 'modules/repQuotes';
import { selectIsGoogleMapsActive } from 'modules/company/selectors';
import { sendPredictionCompletedPostMessage } from 'modules/hooks/usePostMessage';

export const setCenterpoint = (centerpoint: [number, number]): ActionType => ({
  type: constants.SET_QUICK_QUOTE_CENTERPOINT,
  centerpoint,
});

export const setParcel = ({
  parcel,
  parcelState,
  parcelStructures,
  structureMetaData,
}: {
  parcel: GeoJsonPolygon | null;
  parcelState: ParcelState;
  parcelStructures: GeoJsonPolygon[];
  structureMetaData: StructureMetaDataResponse;
}) => ({
  type: constants.SET_QUICK_QUOTE_PARCEL,
  parcel,
  parcelState,
  parcelStructures,
  structureMetaData,
});

export const setIsLoaded = (isLoaded: boolean) => ({
  type: constants.SET_QUICK_QUOTE_IS_LOADED,
  isLoaded,
});

export const setIsReadyToRender = (isReadyToRender: boolean) => ({
  type: constants.SET_QUICK_QUOTE_IS_READY_TO_RENDER,
  isReadyToRender,
});

export const setInitialStructures =
  (initialStructures: RoofleStructure[]): ThunkResult<void> =>
  dispatch => {
    initialStructures = setDefaultWasteFactorToStructure(initialStructures);

    dispatch({
      type: constants.SET_QUICK_QUOTE_INITIAL_STRUCTURES,
      initialStructures,
    });
  };

export const _setStructures = (structures: RoofleStructure[]): StructuresAction => ({
  type: constants.SET_QUICK_QUOTE_STRUCTURES,
  structures,
});

export const setStructureName = ({
  name,
  structure,
}: {
  name: string;
  structure: RoofleStructure;
}) => ({
  type: constants.SET_QUICK_QUOTE_STRUCTURE_NAME,
  name,
  structure,
});

export const _setStructureSlope = ({
  slope,
  structure,
}: {
  slope: StructureSlope;
  structure: RoofleStructure;
}) => ({
  type: constants.SET_QUICK_QUOTE_STRUCTURE_SLOPE,
  slope,
  structure,
});

const _clearQuickQuote = () => ({ type: constants.CLEAR });

export const setIsRegionalMarketApplied = (isRegionalMarketApplied: boolean) => ({
  type: constants.SET_REGIONAL_APPLIED,
  regionalApplied: isRegionalMarketApplied,
});

export const setQuickQuoteUserInfo = (userInfo: QuickQuoteUserInfo) => ({
  type: constants.SET_QUICK_QUOTE_USER_INFO,
  userInfo,
});

export const _setQuickQuoteMarketSlug = (marketSlug: string) => ({
  type: constants.SET_QUICK_QUOTE_MARKET_SLUG,
  marketSlug,
});

export const _setQuickQuoteCenterpointState = (centerpointState: string) => ({
  type: constants.SET_QUICK_QUOTE_CENTERPOINT_STATE,
  centerpointState,
});

export const setLeadData = (leadData: Statistics) => ({
  type: constants.SET_LEAD_DATA,
  leadData,
});

export const setProductPriceSettings = (priceSettings: QuickQuoteProductPriceSettings | null) => ({
  type: constants.SET_QUICK_QUOTE_PRODUCT_PRICE_SETTINGS,
  priceSettings,
});

export const clearQuickQuote = (): ThunkResult<void> => async dispatch => {
  await resetMapInstances();
  dispatch(_clearQuickQuote());
};

export const fetchLandgridParcel =
  ({
    address,
    centerpoint,
  }: {
    address: string;
    centerpoint: [number, number];
  }): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    let centerpointMarket, centerpointState;

    const { wildcard } = widgetSelectors.selectWidget(getState());

    try {
      dispatch(spinnerActions.show());

      ({
        data: { market: centerpointMarket, state: centerpointState },
      } = await widgetServices.getMarketAndStateForCenterpoint(wildcard as string, centerpoint));

      if (!centerpointMarket) {
        dispatch(
          setParcel({
            ...DEFAULT_GEODATA_STATE,
            parcelState: ParcelState.NotIncluded,
          }),
        );
        dispatch(setCenterpoint(centerpoint));
        dispatch(
          setError({
            errorMessage: '',
            hasError: false,
          }),
        );

        return;
      }

      const [lng, lat] = centerpoint;
      const result = await services.geodataApiV2(lng, lat, address);
      const parcel = await decryptQQ(result.data);

      const parcelState = parcel.results.length
        ? {
            parcel: parcel.results[0],
            parcelState: ParcelState.Found,
            parcelStructures: parcel.buildings || [],
            structureMetaData: parcel.structureMetaData,
          }
        : {
            ...DEFAULT_GEODATA_STATE,
            parcelState: ParcelState.NotFound,
          };

      dispatch(setParcel(parcelState));

      dispatch(
        setError({
          errorMessage: '',
          hasError: false,
        }),
      );
    } catch (error) {
      dispatch(clearQuickQuote());
      clearQuickQuoteStorage();
      dispatch(
        setError({
          errorMessage: 'api failed',
          hasError: true,
        }),
      );
      dispatch(
        setParcel({
          ...DEFAULT_GEODATA_STATE,
          parcelState: ParcelState.Error,
        }),
      );
    } finally {
      if (centerpointMarket) {
        dispatch(_setQuickQuoteMarketSlug(centerpointMarket.slug || ''));
        dispatch(marketsActions._setMarkets([{ market: centerpointMarket }], false));
        dispatch(_setQuickQuoteCenterpointState(centerpointState || ''));
      }

      dispatch(spinnerActions.hide());
      dispatch(setIsLoaded(true));
    }
  };

export const setError = ({
  hasError,
  errorMessage,
}: {
  hasError: boolean;
  errorMessage: string;
}): ErrorAction => ({
  type: constants.SET_ERROR,
  hasError,
  errorMessage,
});

export const setStructures =
  (_structures: RoofleStructure[]): ThunkResult<Promise<void>> =>
  async dispatch => {
    let structures = cloneDeep(_structures);

    structures = setDefaultWasteFactorToStructure(structures);
    structures = setDefaultCustomSquareFeetToStructure(structures);

    dispatch(_setStructures(structures));
  };

export const setStructureSlope =
  ({
    slope,
    structure,
  }: {
    slope: StructureSlope;
    structure: RoofleStructure;
  }): ThunkResult<Promise<void>> =>
  async dispatch => {
    dispatch(_setStructureSlope({ slope, structure }));
    // dispatch(globalActions.clearCheckout());
  };

export const _setUserDetailsProvided = (userDetailsProvided: boolean) => ({
  type: constants.SET_USER_DETAILS_PROVIDED,
  userDetailsProvided,
});

export const setUserDetailsProvided =
  (userDetailsProvided: boolean): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const { setQuickQuoteStorage } = await import('modules/quickQuote/storage');
    const quickQuote = selectors.selectQuickQuote(getState());
    quickQuote.userDetailsProvided = userDetailsProvided;
    dispatch(_setUserDetailsProvided(quickQuote.userDetailsProvided));
    setQuickQuoteStorage(quickQuote);
  };

export const getQuickQuote =
  (demo?: boolean): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      const { getQuickQuoteStorage, setQuickQuoteStorage } = await import(
        'modules/quickQuote/storage'
      );

      if (demo) {
        const selectedMarket = marketsSelectors.selectSelectedMarket(getState());
        await setQuickQuoteStorage({
          ...constants.DEFAULT_QUICK_QUOTE,
          marketSlug: selectedMarket?.market.slug,
        });
        dispatch({
          type: constants.SET_QUICK_QUOTE_ALL,
          quickQuote: { ...constants.DEFAULT_QUICK_QUOTE, marketSlug: selectedMarket?.market.slug },
          isLoading: false,
          isReadyToRender: false,
        });

        return;
      }

      const { selectConfig } = await import('modules/quickQuoteConfig/selectors');

      const quickQuote = await getQuickQuoteStorage();
      const { usePersistentStorages } = selectConfig(getState());

      if (!usePersistentStorages) {
        return;
      }

      if (quickQuote) {
        dispatch({
          type: constants.SET_QUICK_QUOTE_ALL,
          quickQuote,
          isLoaded: true,
          isReadyToRender: false,
        });
      }
    } catch (err) {
      trackError(`Get quick quote from localStorage failed`, err);
    }
  };

export const sendQuickQuoteStatistics =
  (userInfo: QuickQuoteUserInfo, unavailableStates?: string[]): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const { selectConfig } = await import('modules/quickQuoteConfig/selectors');

    const state = getState();
    const { wildcard, iframePreview, externalUrl } = widgetSelectors.selectWidget(state);
    const {
      userId,
      statistics: {
        track: trackStatistics,
        sendStatisticsFailMessage,
        sendStatisticsSuccessMessage,
      },
    } = selectConfig(state);

    if (iframePreview || !trackStatistics) {
      dispatch(modalActions.closeModal());
      dispatch(setQuickQuoteUserInfo(userInfo));
      return;
    }

    const { salesRabbitParam, leadIdParam } = getSalesRabbitParams(externalUrl);
    const urlParamEmail = getSalesEmailParam(externalUrl);

    if (!wildcard) {
      return;
    }

    try {
      const { buildQuickQuoteRequestBody } = await import('modules/quickQuote/utils');

      const properties = await buildQuickQuoteRequestBody({
        _userInfo: userInfo,
        unavailableStates,
        isMakeScreenshot: true,
      });
      const encryptedAdditionalInfo =
        properties.additionalInformation &&
        (await encryptQuickQuoteAdditionalInformation(properties.additionalInformation));
      const { data: statisticsId } = await services.sendQuickQuoteStatisticsAPI(
        wildcard,
        {
          ...properties,
          additionalInformation: encryptedAdditionalInfo,
          userId,
          forceWebLead: !!urlParamEmail,
        },
        {
          salesRabbitLeadId: salesRabbitParam && leadIdParam ? leadIdParam : undefined,
        },
      );
      dispatch(
        messageActions.openMessage(MessageTypes.success, sendStatisticsSuccessMessage as string),
      );
      dispatch(modalActions.closeModal());
      dispatch(setQuickQuoteUserInfo(userInfo));

      const expirationDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toUTCString();
      idb.setItem(STORAGE_ANALYTICS, STORAGE_STATISTICS_ID_ALIAS, statisticsId);
      idb.setItem(STORAGE_ANALYTICS, STORAGE_STATISTICS_ID_EXPIRATION_DATE_ALIAS, expirationDate);

      if (userId) {
        // Send a message that lead was created. Only or RepQuotes
        const broadcastChannel = new BroadcastChannel(STORAGE_BROADCAST_CHANNEL);
        broadcastChannel.postMessage({ message: LEAD_CREATED_MESSAGE, id: statisticsId });
        broadcastChannel.close();
      }
    } catch (error) {
      dispatch(messageActions.openMessage(MessageTypes.error, sendStatisticsFailMessage as string));
    }
  };

export const quickQuoteGetPreApproved =
  ({ line, priceInfo }): ThunkResult<void> =>
  dispatch => {
    dispatch(financingActions.inviteConsumerQuickQuote({ line, priceInfo }));
    dispatch(modalActions.openModal(ModalTypes.invitationSent, { line, priceInfo }));
  };

const setMLPredictionData = (MLPredictionData: MLPredictionDataType, eventId: string) => ({
  type: constants.SET_QUICK_QUOTE_ML_PREDICTION_DATA,
  MLPredictionData,
  eventId,
  loading: false,
});

const fetchMLPrediction = () => ({
  type: constants.SET_QUICK_QUOTE_FETCH_ML_PREDICTION_DATA,
  loading: true,
});

const mlPredictionFailed = (error: unknown) => ({
  type: constants.SET_QUICK_QUOTE_FAILED_ML_PREDICTION_DATA,
  error,
  loading: false,
});

export const getMLPrediction =
  ({ image, bounds, structures }: RoofsPredictionPayload): ThunkResult<void> =>
  async (dispatch, getState) => {
    try {
      sendPredictionCompletedPostMessage(false);
      dispatch(fetchMLPrediction());
      const state = getState();
      const { wildcard } = widgetSelectors.selectWidget(state);
      const isGoogleMapsActive = selectIsGoogleMapsActive(state);

      if (!wildcard) return;

      const imageFile = await dataUrlToImageFile(image);
      const formattedStructures = formatStructuresForML(structures);
      const mapProvider: MapProviderType = isGoogleMapsActive
        ? MapProviderType.GoogleMaps
        : MapProviderType.Mapbox;

      const formData = new FormData();
      formData.append('image', imageFile, 'property.jpeg');
      formData.append('bounds', JSON.stringify(bounds));
      formData.append('structures', JSON.stringify(formattedStructures));
      formData.append('mapProvider', JSON.stringify(mapProvider));

      const { data } = await services.sendDataToML(wildcard, formData);

      sendPredictionCompletedPostMessage(true);
      dispatch(setMLPredictionData(data.structures, data.eventId));
    } catch (error) {
      dispatch(mlPredictionFailed(error));
    }
  };

async function callStatisticsCallback<N>(
  callback: (props: { wildcard: string; statisticsId: number }) => Promise<N | undefined>,
): Promise<N | undefined> {
  const state = store.getState();
  const { selectStateConfig } = await import('modules/quickQuoteConfig/selectors');
  const {
    statistics: { track: trackStatistics },
  } = selectStateConfig(state);
  const { wildcard } = widgetSelectors.selectWidget(state);

  const statisticsId = await getStatisticsIdFromStorage();

  if (statisticsId && trackStatistics && wildcard) {
    return callback({ wildcard, statisticsId });
  }
}

export async function markStatisticsProductAsInterestedInFinancing({
  productId,
  priceInfo,
}: {
  productId: number;
  priceInfo: PriceInfo;
}): Promise<Statistics | undefined> {
  let financingVariant: CLPFinancingVariants;

  priceInfo.paymentsSelected && (financingVariant = CLPFinancingVariants.payments);
  priceInfo.interestSelected && (financingVariant = CLPFinancingVariants.interest);
  priceInfo.monthsSelected && (financingVariant = CLPFinancingVariants.months);

  return callStatisticsCallback(({ wildcard, statisticsId }) =>
    services.markStatisticsProductAsInterestedInFinancingAPI({
      statisticsId,
      wildcard,
      productId,
      financingVariant,
    }),
  );
}

export async function markStatisticsProductsAsSended(
  productIds: number[],
): Promise<Statistics | undefined> {
  return callStatisticsCallback(({ wildcard, statisticsId }) =>
    services.markStatisticsProductsAsSendedAPI({
      statisticsId,
      wildcard,
      productIds,
    }),
  );
}

export async function updateStatisticsInvitationPhones(
  invitationPhone: string,
): Promise<Record<string, never> | undefined> {
  return callStatisticsCallback(({ wildcard, statisticsId }) =>
    services.updateStatisticsInvitationPhonesAPI({
      statisticsId,
      wildcard,
      invitationPhone,
    }),
  );
}

export async function updateStatisticsInvitationEmails(
  invitationEmail: string,
): Promise<Record<string, never> | undefined> {
  return callStatisticsCallback(({ wildcard, statisticsId }) =>
    services.updateStatisticsInvitationEmailsAPI({
      statisticsId,
      wildcard,
      invitationEmail,
    }),
  );
}
