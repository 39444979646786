import isEmpty from 'lodash/isEmpty';

import { PriceConfig } from './priceConfig';

import {
  TOTAL_ADDITIONAL_COST_DISCOUNT_KEY,
  TOTAL_DISCOUNT_KEY,
} from 'modules/quoteSettings/constants';
import { computeAdditionalCostsDiscounts, computeDiscount } from './utils';
import { DiscountModel } from 'modules/repQuotes/types';

export class Discount {
  constructor(priceConfig: PriceConfig) {
    this.priceConfig = priceConfig;
  }

  private readonly priceConfig: PriceConfig;

  private static getDiscountByType(
    discounts: DiscountModel[],
    discountType: string,
  ): DiscountModel | undefined {
    return discounts.find(({ type }) => type === discountType);
  }

  calculateTotalDiscount(total: number): Record<string, number> {
    const { productPriceSettings } = this.priceConfig;

    if (!productPriceSettings?.discount?.discounts?.length || !total) {
      return {};
    }

    const {
      discount: { discounts },
    } = productPriceSettings;
    const totalDiscount = Discount.getDiscountByType(discounts, TOTAL_DISCOUNT_KEY);

    return totalDiscount ? { [TOTAL_DISCOUNT_KEY]: computeDiscount(total, totalDiscount) } : {};
  }

  calculateAdditionalCostDiscounts(
    additionalCosts: Record<string, number>,
    totalAdditionalCost: number,
  ): Record<string, number> {
    const { productPriceSettings, product } = this.priceConfig;

    if (
      !productPriceSettings?.discount?.discounts?.length ||
      !totalAdditionalCost ||
      !additionalCosts ||
      !product?.addAdditionalCosts
    ) {
      return {};
    }

    const {
      discount: { discounts },
    } = productPriceSettings;
    const totalDiscount = Discount.getDiscountByType(discounts, TOTAL_DISCOUNT_KEY);

    if (totalDiscount) {
      return {};
    }

    const totalAdditionalCostDiscount = Discount.getDiscountByType(
      discounts,
      TOTAL_ADDITIONAL_COST_DISCOUNT_KEY,
    );

    if (totalAdditionalCostDiscount) {
      return {
        [TOTAL_ADDITIONAL_COST_DISCOUNT_KEY]: computeDiscount(
          totalAdditionalCost,
          totalAdditionalCostDiscount,
        ),
      };
    }

    return computeAdditionalCostsDiscounts(additionalCosts, discounts);
  }

  calculateTotal(discounts: Record<string, number>): number {
    if (isEmpty(discounts)) {
      return 0;
    }

    const sum = Object.values(discounts).reduce((acc, value) => acc + value, 0);
    return +sum.toFixed(2);
  }
}
