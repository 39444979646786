import { colors } from 'global-constants';
import { intl } from 'intl';
import { I18nEnum } from 'types';
import { DeliveryTime, MeasurementsReportType } from 'modules/dashboard/types';
import {
  BrandingConfig,
  ContactsFormOptionValue,
  MapCenterpointType,
  ThirdPartyLegalTextId,
  ValidatableFieldsToSectionTitleMap,
  WidgetSettings,
  WidgetSettingsConfigState,
  WidgetSettingsState,
} from './types';
import { ModalTypes } from 'modules/modal/types';

const name = 'company/';

export const SET_WIDGET_SETTINGS = `${name}SET_WIDGET_SETTINGS`;
export const SET_ALL_WIDGET_SETTINGS = `${name}SET_ALL_WIDGET_SETTINGS`;
export const RESET_WIDGET_SETTINGS = `${name}RESET_WIDGET_SETTINGS`;

export const SET_WIDGET_PRODUCTS = `${name}SET_WIDGET_PRODUCTS`;
export const SET_WIDGET_WILDCARD = `${name}SET_WIDGET_WILDCARD`;
export const SET_WIDGET_IFRAME_PREVIEW = `${name}SET_WIDGET_IFRAME_PREVIEW`;
export const SET_WIDGET_EXTERNAL_URL = `${name}SET_WIDGET_EXTERNAL_URL`;
export const SET_WIDGET_USER_INFO = `${name}SET_WIDGET_SET_WIDGET_USER_INFO`;
export const SET_MAP_PLACEHOLDER_MEDIA = `${name}SET_MAP_PLACEHOLDER_MEDIA`;
export const CLEAR_MAP_SETTINGS_MEDIA = `${name}CLEAR_MAP_SETTINGS`;
export const CLEAR_BRANDING = `${name}CLEAR_BRANDING`;

export const GET_WIDGET_PRODUCTS_KEY = `${name}GET_WIDGET_PRODUCTS_KEY`;
export const USER_IP_GEOLOCATION_KEY = `${name}USER_IP_GEOLOCATION_KEY`;
export const SPINNER_CONTAINER = 'spinner-container';
export const REMOVE_SPINNER_MESSAGE_TYPE = 'quick-quote-remove-spinner';
export const PREDICTION_COMPLETED_MESSAGE_TYPE = 'prediction-completed';

export const DEFAULT_BRANDING_CONFIG: BrandingConfig = {
  titles: {
    fontColor: colors.blue,
    font: "'Rubik', sans-serif",
    mainTitleText: intl.formatMessage({
      id: I18nEnum.WhatWillMyRoofCost,
    }),
  },
  mainText: {
    fontColor: colors.blue,
    font: "'Rubik', sans-serif",
    exampleText: intl.formatMessage({
      id: I18nEnum.EnterYourStreetAddressToGetAnEstimateInstantly,
    }),
  },
  secondaryText: {
    fontColor: colors.grey,
    font: "'Rubik', sans-serif",
  },
  icons: {
    fillColor: colors.grey,
  },
  roof: {
    fillColor: colors.orange,
    strokeColor: colors.orange,
  },
  tooltipAndMapControls: {
    fillColor: colors.white,
    strokeColor: colors.orange,
    cornerRadius: 6,
    fontColor: colors.orange,
  },
  illustration: {
    activeColor: colors.blue,
    disabledColor: colors.grey,
  },
  background: {
    fillColor: colors.white,
  },
  mainButton: {
    fillColor: colors.orange,
    strokeColor: colors.orange,
    cornerRadius: 6,
    fontColor: colors.white,
    font: "'Rubik', sans-serif",
    fontWeight: 400,
    fontSize: 18,
    seeProductDetailsButtonText: 'See details',
    customFinancingButtonText: 'Apply now',
  },
  secondaryButton: {
    fillColor: colors.white,
    strokeColor: colors.blue,
    fontColor: colors.blue,
    cornerRadius: 6,
    font: "'Rubik', sans-serif",
    fontWeight: 400,
    fontSize: 18,
  },
  checkbox: {
    fillColor: colors.orange,
    strokeColor: colors.orange,
    cornerRadius: 4,
  },
  radioButton: {
    fillColor: colors.orange,
    strokeColor: colors.orange,
  },
  roofQuoteProButton: {
    fillColor: colors.orange,
    strokeColor: colors.orange,
    fontColor: colors.white,
    font: "'Rubik', sans-serif",
    fontWeight: 500,
    fontSize: 22,
    horisontalPosition: 'right',
    text: 'Instant Roof Quote',
    customButtonClasses: [],
  },
  callout: {
    fillColor: colors.white,
    strokeColor: colors.orange,
    titleText: 'Need a roof quote?',
    titleFont: "'Rubik', sans-serif",
    titleFontColor: colors.blue,
    descriptionText: 'Get your price NOW!',
    descriptionTextFont: "'Rubik', sans-serif",
    descriptionTextFontColor: colors.blue,
    showOnTabletsAndDesktops: true,
    showOnPhones: false,
    customCalloutClasses: ['animated', 'with-arrow'],
    img: null,
    sideBar: null,
    closingStrategy: 'full',
    parent: 'wrapper',
    thirdPartyLegalText: {
      enabled: false,
    },
  },
  rqpLeads: {
    legalTextEnabled: true,
    legalText: intl.formatMessage({
      id: I18nEnum.FillInYourInformationToSeeYourPersonalizedInstantQuote,
    }),
    legalTextFontSize: 14,
    legalTextPosition: 'below',
    buttonText: intl.formatMessage({ id: I18nEnum.ShowMyPrice }),
    contactOptIn: ContactsFormOptionValue.Hide,
    contactOptInText: intl.formatMessage({ id: I18nEnum.YesYouMayContactMeAboutMyProject }),
    smsOptIn: ContactsFormOptionValue.Hide,
    smsOptInText: intl.formatMessage({
      id: I18nEnum.YesIWouldLikeToReceiveSmsMessagesAboutMyProject,
    }),
    subTitleText: intl.formatMessage({ id: I18nEnum.FillInYourDetailsToSeeQuoteInstantly }),
    titleText: intl.formatMessage({ id: I18nEnum.AlmostDoneExclamationMark }),
  },
  otherContacts: {
    legalTextEnabled: true,
    legalText: intl.formatMessage({
      id: I18nEnum.FillInYourInformationToSeeYourPersonalizedInstantQuote,
    }),
    legalTextFontSize: 14,
    legalTextPosition: 'below',
    buttonText: intl.formatMessage({ id: I18nEnum.ContactUs }),
    contactOptIn: ContactsFormOptionValue.Hide,
    contactOptInText: intl.formatMessage({ id: I18nEnum.YesYouMayContactMeAboutMyProject }),
    smsOptIn: ContactsFormOptionValue.Hide,
    smsOptInText: intl.formatMessage({
      id: I18nEnum.YesIWouldLikeToReceiveSmsMessagesAboutMyProject,
    }),
    subTitleText: intl.formatMessage({ id: I18nEnum.PleaseFillInYourDetailsAndWeWillProvide }),
    titleText: intl.formatMessage({ id: I18nEnum.NoWorriesLetsTry }),
  },
  thankYou: {
    thankYouTitleText: 'Great choice!',
    thankYouMedia: '',
    thankYouMediaType: '',
    thankYouSubtitle: '',
    thankYouButtonText: intl.formatMessage({
      id: I18nEnum.BackToProducts,
    }),
    thankYouExternalUrl: '',
    thankYouShowOtherProducts: true,
    thankYouMediaProcessingStatus: null,
    emailIconName: 'email-icon.svg',
  },
  measurementsOrder: {
    isMeasurementsOrderEnabled: false,
    mainMeasurementsReportDeliveryType: DeliveryTime.ThreeHourDelivery,
    backupMeasurementsReportDeliveryType: DeliveryTime.ThreeHourDelivery,
    mainMeasurementsReportType: null,
    backupMeasurementsReportType: MeasurementsReportType.NoReport,
  },
  mapSettings: {
    centerpointType: MapCenterpointType.IpGeolocation,
    placeholderMedia: '',
  },
  productDetails: {
    sendProductButtonText: 'Send me details',
    getFinalProposalButtonText: 'Get Final Proposal',
  },
  howInstantLoanWorks: {
    getPreQualifiedIconName: 'get-pre-qualified-icon.svg',
    submitApplicationIconName: 'submit-application-icon.svg',
    getApprovedAndFundedIconName: 'get-approved-and-funded-icon.svg',
    fiveMonthPurchaseIcon: '5-month-purchase-icon.svg',
  },
  howToReplyYourLoan: {
    setupAutoPaymentsIconName: 'setup-auto-payments-icon.svg',
    secureLoginIconName: 'secure-login-icon.svg',
    simpleOneTimePaymentsIconName: 'simple-one-time-payments-icon.svg',
    monthlyStatementsIconName: 'monthly-statements-icon.svg',
    noEarlyPaymentsIconName: 'no-early-payments-icon.svg',
    payOnlineIconName: 'pay-online-icon.svg',
  },
  invitationSent: {
    invitationSentIconName: 'email-icon.svg',
  },
  overrides: {
    useOwensCorningWidgetBranding: false,
    useOwensCorningToolCalloutBranding: false,
  },
  widgetUnavailable: {
    imgName: 'error-illustration.svg',
    imgHeight: 206,
    imgWidth: 282,
  },
  caption: {
    font: "'Rubik', sans-serif",
    fontColor: colors.grey,
  },
  warningModal: {
    imgName: '/images/svg/warning-circle-icon.svg',
  },
};

export const DEFAULT_OC_BRANDING_CONFIG: BrandingConfig = {
  titles: {
    fontColor: colors.black,
    font: 'Roboto',
    mainTitleText: intl.formatMessage({
      id: I18nEnum.WhatWillMyRoofCost,
    }),
  },
  mainText: {
    fontColor: colors.darkCharcoal,
    font: 'Roboto',
    exampleText: intl.formatMessage({
      id: I18nEnum.EnterYourStreetAddressToGetAnEstimateInstantly,
    }),
  },
  secondaryText: {
    fontColor: `${colors.darkCharcoal}b3`, // 70% opacity
    font: 'Roboto',
  },
  icons: {
    fillColor: colors.graniteGray,
  },
  roof: {
    fillColor: colors.vividCerise,
    strokeColor: colors.vividCerise,
  },
  tooltipAndMapControls: {
    fillColor: colors.white,
    strokeColor: colors.vividCerise,
    cornerRadius: 4,
    fontColor: colors.vividCerise,
  },
  illustration: {
    activeColor: colors.roseGarnet,
    disabledColor: colors.grey,
  },
  background: {
    fillColor: colors.white,
  },
  mainButton: {
    fillColor: colors.vividCerise,
    strokeColor: colors.vividCerise,
    cornerRadius: 6,
    fontColor: colors.white,
    font: 'Roboto',
    fontWeight: 400,
    fontSize: 18,
    seeProductDetailsButtonText: 'See details',
    customFinancingButtonText: 'Apply now',
  },
  secondaryButton: {
    fillColor: colors.white,
    strokeColor: colors.darkCharcoal,
    fontColor: colors.black,
    cornerRadius: 6,
    font: 'Roboto',
    fontWeight: 400,
    fontSize: 18,
  },
  checkbox: {
    fillColor: colors.vividCerise,
    strokeColor: colors.vividCerise,
    cornerRadius: 4,
  },
  radioButton: {
    fillColor: colors.vividCerise,
    strokeColor: colors.vividCerise,
  },
  roofQuoteProButton: {
    fillColor: colors.vividCerise,
    strokeColor: colors.orange,
    fontColor: colors.white,
    font: 'Oswald',
    fontWeight: 500,
    fontSize: 22,
    horisontalPosition: 'right',
    text: 'Instant Roof Quote',
    textClass: 'quick-quote-oc-button-text',
    customButtonClasses: ['oc-button'],
  },
  callout: {
    fillColor: colors.white,
    strokeColor: colors.vividCerise,
    titleText: 'Get Your Free Online Quote',
    titleFont: 'Roboto',
    titleFontColor: colors.black,
    descriptionText: 'See costs for top-rated roof options in seconds',
    descriptionTextFont: 'Roboto',
    descriptionTextFontColor: colors.black,
    showOnTabletsAndDesktops: true,
    showOnPhones: false,
    customCalloutClasses: ['oc-callout'],
    img: {
      name: '/images/svg/owens-corning-logo.svg',
      height: 74,
      width: 85,
      margin: '0 -11px 0 0',
    },
    sideBar: {
      imageName: 'images/svg/panther.svg',
      class: 'quick-quote-oc-panther',
      imageWidth: 134,
      imageHeight: 222,
      overlapY: 17,
    },
    closingStrategy: 'text-only',
    parent: 'button',
    thirdPartyLegalText: {
      enabled: true,
      id: ThirdPartyLegalTextId.OC,
    },
  },
  otherContacts: {
    legalTextEnabled: true,
    legalText: intl.formatMessage({
      id: I18nEnum.FillInYourInformationToSeeYourPersonalizedInstantQuote,
    }),
    legalTextFontSize: 14,
    legalTextPosition: 'below',
    buttonText: intl.formatMessage({ id: I18nEnum.ContactUs }),
    contactOptIn: ContactsFormOptionValue.Hide,
    contactOptInText: intl.formatMessage({ id: I18nEnum.YesYouMayContactMeAboutMyProject }),
    smsOptIn: ContactsFormOptionValue.Hide,
    smsOptInText: intl.formatMessage({
      id: I18nEnum.YesIWouldLikeToReceiveSmsMessagesAboutMyProject,
    }),
    subTitleText: intl.formatMessage({ id: I18nEnum.PleaseFillInYourDetailsAndWeWillProvide }),
    titleText: intl.formatMessage({ id: I18nEnum.NoWorriesLetsTry }),
  },
  rqpLeads: {
    legalTextEnabled: true,
    legalText: intl.formatMessage({
      id: I18nEnum.FillInYourInformationToSeeYourPersonalizedInstantQuote,
    }),
    legalTextFontSize: 14,
    legalTextPosition: 'below',
    buttonText: intl.formatMessage({ id: I18nEnum.ShowMyPrice }),
    contactOptIn: ContactsFormOptionValue.Hide,
    contactOptInText: intl.formatMessage({ id: I18nEnum.YesYouMayContactMeAboutMyProject }),
    smsOptIn: ContactsFormOptionValue.Hide,
    smsOptInText: intl.formatMessage({
      id: I18nEnum.YesIWouldLikeToReceiveSmsMessagesAboutMyProject,
    }),
    subTitleText: intl.formatMessage({ id: I18nEnum.FillInYourDetailsToSeeQuoteInstantly }),
    titleText: intl.formatMessage({ id: I18nEnum.AlmostDoneExclamationMark }),
  },
  thankYou: {
    thankYouTitleText: 'Great choice!',
    thankYouMedia: '',
    thankYouMediaType: '',
    thankYouSubtitle: '',
    thankYouButtonText: intl.formatMessage({
      id: I18nEnum.BackToProducts,
    }),
    thankYouExternalUrl: '',
    thankYouShowOtherProducts: true,
    thankYouMediaProcessingStatus: null,
    emailIconName: 'email-icon-grey.svg',
  },
  measurementsOrder: {
    isMeasurementsOrderEnabled: false,
    mainMeasurementsReportDeliveryType: DeliveryTime.ThreeHourDelivery,
    backupMeasurementsReportDeliveryType: DeliveryTime.ThreeHourDelivery,
    mainMeasurementsReportType: null,
    backupMeasurementsReportType: MeasurementsReportType.NoReport,
  },
  mapSettings: {
    centerpointType: MapCenterpointType.IpGeolocation,
    placeholderMedia: '',
  },
  productDetails: {
    sendProductButtonText: 'Send me details',
    getFinalProposalButtonText: 'Get Final Proposal',
  },
  howInstantLoanWorks: {
    getPreQualifiedIconName: 'get-pre-qualified-icon-grey.svg',
    submitApplicationIconName: 'submit-application-icon-grey.svg',
    getApprovedAndFundedIconName: 'get-approved-and-funded-icon-grey.svg',
    fiveMonthPurchaseIcon: '5-month-purchase-icon-grey.svg',
  },
  howToReplyYourLoan: {
    setupAutoPaymentsIconName: 'setup-auto-payments-icon-grey.svg',
    secureLoginIconName: 'secure-login-icon-grey.svg',
    simpleOneTimePaymentsIconName: 'simple-one-time-payments-icon-grey.svg',
    monthlyStatementsIconName: 'monthly-statements-icon-grey.svg',
    noEarlyPaymentsIconName: 'no-early-payments-icon-grey.svg',
    payOnlineIconName: 'pay-online-icon-grey.svg',
  },
  invitationSent: {
    invitationSentIconName: 'email-icon-grey.svg',
  },
  overrides: {
    useOwensCorningWidgetBranding: false,
    useOwensCorningToolCalloutBranding: false,
  },
  widgetUnavailable: {
    imgName: 'error-illustration-grey.svg',
    imgHeight: 251,
    imgWidth: 343,
  },
  caption: {
    font: 'Roboto',
    fontColor: colors.grey,
  },
  warningModal: {
    imgName: '/images/svg/warning-circle-grey-icon.svg',
  },
};

export const DEFAULT_WIDGET_SETTINGS: WidgetSettingsState = {
  loaded: false,
  demo: false,
  branding: DEFAULT_BRANDING_CONFIG,
  products: [],
  domains: [],
};

export const mapSettings = 'mapSettings' as const;
export const widgetBranding = 'widgetBranding' as const;
export const toolCallout = 'toolCallout' as const;
export const automations = 'automations' as const;

export const brandingValidatableFieldsToSectionTitleMap: ValidatableFieldsToSectionTitleMap = {
  'titles.fontColor': I18nEnum.TextIconsIllustrations,
  'mainText.fontColor': I18nEnum.TextIconsIllustrations,
  'secondaryText.fontColor': I18nEnum.TextIconsIllustrations,
  'mainButton.fillColor': I18nEnum.Controls,
  'mainButton.strokeColor': I18nEnum.Controls,
  'mainButton.seeProductDetailsButtonText': I18nEnum.Controls,
  'mainButton.customFinancingButtonText': I18nEnum.Controls,
  'secondaryButton.fillColor': I18nEnum.Controls,
  'secondaryButton.strokeColor': I18nEnum.Controls,
  'background.fillColor': I18nEnum.Background,

  'roofQuoteProButton.text': I18nEnum.SlideoutTab,
  'callout.descriptionText': I18nEnum.Callout,
  'callout.titleText': I18nEnum.Callout,
  'titles.mainTitleText': I18nEnum.TextIconsIllustrations,
  'mainText.exampleText': I18nEnum.TextIconsIllustrations,
  'thankYou.thankYouTitleText': I18nEnum.ThankYouScreen,
  'thankYou.thankYouButtonText': I18nEnum.ThankYouScreen,

  'rqpLeads.contactOptInText': I18nEnum.RQPLeadsForm,
  'rqpLeads.smsOptInText': I18nEnum.RQPLeadsForm,
  'rqpLeads.titleText': I18nEnum.RQPLeadsForm,
  'rqpLeads.subTitleText': I18nEnum.RQPLeadsForm,
  'rqpLeads.buttonText': I18nEnum.RQPLeadsForm,
  'rqpLeads.legalText': I18nEnum.RQPLeadsForm,
  'otherContacts.contactOptInText': I18nEnum.OtherContactsForm,
  'otherContacts.smsOptInText': I18nEnum.OtherContactsForm,
  'otherContacts.titleText': I18nEnum.OtherContactsForm,
  'otherContacts.subTitleText': I18nEnum.OtherContactsForm,
  'otherContacts.buttonText': I18nEnum.OtherContactsForm,
  'otherContacts.legalText': I18nEnum.OtherContactsForm,

  'measurementsOrder.mainMeasurementsReportType': I18nEnum.MeasurementOrderSettings,

  'productDetails.sendProductButtonText': I18nEnum.Control,
  'productDetails.getFinalProposalButtonText': I18nEnum.Control,
} as const;

export const widgetId = 'roof-quote-pro-widget';
export const embeddedWidgetId = 'roof-quote-pro-embedded-widget';

export const GEODATA_SESSION_STORAGE_LABEL = 'geodata';

const OC_NOT_EDITABLE_FIELDS: (keyof WidgetSettings)[] = [
  'backgroundFillColor',
  'checkboxFillColor',
  'checkboxStrokeColor',
  'checkboxCornerRadius',
  'iconsFillColor',
  'illustrationSelectedColor',
  'illustrationUnselectedColor',
  'mainButtonFillColor',
  'mainButtonFontColor',
  'mainButtonCornerRadius',
  'mainButtonStrokeColor',
  'mainButtonFont',
  'mainButtonFontWeight',
  'mainButtonFontSize',
  'seeProductDetailsButtonText',
  'mainTextFontColor',
  'mainTextFont',
  'mainTextExampleText',
  'roofFillColor',
  'roofStrokeColor',
  'secondaryButtonFillColor',
  'secondaryButtonFontColor',
  'secondaryButtonFont',
  'secondaryButtonStrokeColor',
  'secondaryButtonCornerRadius',
  'secondaryButtonFontWeight',
  'secondaryButtonFontSize',
  'secondaryTextFontColor',
  'secondaryTextFont',
  'titlesFontColor',
  'titlesFont',
  'titlesMainTitleText',
  'tooltipAndMapControlsFillColor',
  'tooltipAndMapControlsFontColor',
  'tooltipAndMapControlsStrokeColor',
  'tooltipAndMapControlsCornerRadius',
  'rqpButtonFillColor',
  'rqpButtonFont',
  'rqpButtonFontColor',
  'rqpButtonFontSize',
  'rqpButtonFontWeight',
  'rqpButtonStrokeColor',
  'rqpButtonText',
  'calloutFillColor',
  'calloutStrokeColor',
  'calloutTitleText',
  'calloutTitleFont',
  'calloutTitleFontColor',
  'calloutDescriptionText',
  'calloutDescriptionTextFont',
  'calloutDescriptionTextFontColor',
  'useOwensCorningWidgetBranding',
  'useOwensCorningToolCalloutBranding',
];

export const MOCK_OC_WIDGET_BRANDING_NOT_EDITABLE_FIELDS: (keyof WidgetSettings)[] = [
  'backgroundFillColor',
  'checkboxFillColor',
  'checkboxStrokeColor',
  'checkboxCornerRadius',
  'iconsFillColor',
  'illustrationSelectedColor',
  'illustrationUnselectedColor',
  'mainButtonFillColor',
  'mainButtonFontColor',
  'mainButtonCornerRadius',
  'mainButtonStrokeColor',
  'mainButtonFont',
  'mainButtonFontWeight',
  'mainButtonFontSize',
  'mainTextFontColor',
  'mainTextFont',
  'roofFillColor',
  'roofStrokeColor',
  'secondaryButtonFillColor',
  'secondaryButtonFontColor',
  'secondaryButtonFont',
  'secondaryButtonStrokeColor',
  'secondaryButtonCornerRadius',
  'secondaryButtonFontWeight',
  'secondaryButtonFontSize',
  'secondaryTextFontColor',
  'secondaryTextFont',
  'titlesFontColor',
  'titlesFont',
  'tooltipAndMapControlsFillColor',
  'tooltipAndMapControlsFontColor',
  'tooltipAndMapControlsStrokeColor',
  'tooltipAndMapControlsCornerRadius',
];

export const MOCK_OC_TOOL_CALLOUT_BRANDING_NOT_EDITABLE_FIELDS: (keyof WidgetSettings)[] = [
  'rqpButtonFillColor',
  'rqpButtonFont',
  'rqpButtonFontColor',
  'rqpButtonFontSize',
  'rqpButtonFontWeight',
  'rqpButtonStrokeColor',
  'calloutFillColor',
  'calloutStrokeColor',
  'calloutTitleFont',
  'calloutTitleFontColor',
  'calloutDescriptionTextFont',
  'calloutDescriptionTextFontColor',
];

export const DEFAULT_SETTINGS_CONFIG: WidgetSettingsConfigState = {
  initialDemoViewType: widgetBranding,
  notEditableFields: [],
  useOCBrandingToggleVisible: true,
  showUpgradeSubscriptionTooltip: false,
  mockOCWidgetBrandingNotEditableFields: MOCK_OC_WIDGET_BRANDING_NOT_EDITABLE_FIELDS,
  mockOCToolCalloutBrandingNotEditableFields: MOCK_OC_TOOL_CALLOUT_BRANDING_NOT_EDITABLE_FIELDS,
  demoViewTypeOptionsValue: [
    widgetBranding,
    toolCallout,
    mapSettings,
    ModalTypes.productDetails,
    ModalTypes.thankYou,
    automations,
    ModalTypes.almostDone,
    ModalTypes.potentialClient,
  ],
  roofQuoteProButton: {
    showFullSettings: true,
  },
  callout: {
    showFullSettings: true,
  },
};

export const OC_SETTINGS_CONFIG: WidgetSettingsConfigState = {
  initialDemoViewType: toolCallout,
  notEditableFields: OC_NOT_EDITABLE_FIELDS,
  mockOCWidgetBrandingNotEditableFields: MOCK_OC_WIDGET_BRANDING_NOT_EDITABLE_FIELDS,
  mockOCToolCalloutBrandingNotEditableFields: MOCK_OC_TOOL_CALLOUT_BRANDING_NOT_EDITABLE_FIELDS,
  useOCBrandingToggleVisible: false,
  showUpgradeSubscriptionTooltip: true,
  demoViewTypeOptionsValue: [
    toolCallout,
    mapSettings,
    ModalTypes.productDetails,
    ModalTypes.thankYou,
    automations,
    ModalTypes.almostDone,
    ModalTypes.potentialClient,
  ],
  roofQuoteProButton: {
    showFullSettings: false,
  },
  callout: {
    showFullSettings: false,
  },
};

export const GENERAL_SETTINGS_BLOCKER_KEY = 'generalSettingsBlocker';
