import {
  STORAGE_ADDRESS_FEATURE_PATH,
  STORAGE_ADDRESS_PATH,
  STORAGE_ADDRESS_RESULTS_PATH,
  STORAGE_QUICK_QUOTE,
  STORAGE_QUICK_QUOTE_MAP_ALIAS,
  STORAGE_PAST_QUICK_QUOTE_MAPS_ALIAS,
} from 'modules/quickQuote/constants';
import { decryptQQ } from 'modules/quickQuote/_utils';
import IDB from 'services/idb';

const MAX_PAST_QUICK_QUOTES = 5;

export const clearAddressStorage = async () => {
  const quickQuote = await IDB.getItem(STORAGE_QUICK_QUOTE, STORAGE_QUICK_QUOTE_MAP_ALIAS);
  const address = localStorage.getItem(STORAGE_ADDRESS_PATH);
  const addressFeature = localStorage.getItem(STORAGE_ADDRESS_FEATURE_PATH);

  if (address && quickQuote) {
    const pastQuickQuotes = await IDB.getItem(
      STORAGE_QUICK_QUOTE,
      STORAGE_PAST_QUICK_QUOTE_MAPS_ALIAS,
    );
    const parsedQuickQuote = await decryptQQ(quickQuote);

    const pastQuickQuoteState = {
      address,
      quickQuote,
      addressFeature,
    };

    const isSameAddressExists =
      pastQuickQuotes &&
      pastQuickQuotes.some((qq: { address: string; map: string }) => qq.address === address);

    const isQuickQuoteHasStructures = !!(parsedQuickQuote && parsedQuickQuote.structures.length);

    if (!isSameAddressExists && isQuickQuoteHasStructures) {
      if ((pastQuickQuotes || []).length < MAX_PAST_QUICK_QUOTES) {
        await IDB.setItem(
          STORAGE_QUICK_QUOTE,
          STORAGE_PAST_QUICK_QUOTE_MAPS_ALIAS,
          pastQuickQuotes ? [pastQuickQuoteState, ...pastQuickQuotes] : [pastQuickQuoteState],
        );
      } else {
        pastQuickQuotes[0] = pastQuickQuoteState;
        await IDB.setItem(
          STORAGE_QUICK_QUOTE,
          STORAGE_PAST_QUICK_QUOTE_MAPS_ALIAS,
          pastQuickQuotes,
        );
      }
    }
  }

  localStorage.removeItem(STORAGE_ADDRESS_PATH);
  localStorage.removeItem(STORAGE_ADDRESS_RESULTS_PATH);
  localStorage.removeItem(STORAGE_ADDRESS_FEATURE_PATH);
};
