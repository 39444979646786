import { SubscriptionCountry } from 'modules/subscription/types';
import {
  FinancingSetting,
  CustomerPreference,
  DefaultFinancingTypes,
  FinancingType,
  FinancingSettingsConfig,
} from './types';
import { I18nEnum } from 'types';

const name = 'financing/';

export const SET_BASIC_FINANCING = `${name}SET_BASIC_FINANCING`;
export const SET_MOMNT_MERCHANT = `${name}SET_MOMNT_MERCHANT`;
export const SET_MOMNT_LOAN_APPLICATIONS = `${name}SET_MOMNT_LOAN_APPLICATIONS`;
export const SET_MOMNT_LOAN_INVITATIONS = `${name}SET_MOMNT_LOAN_INVITATIONS`;
export const SET_MOMNT_ALL_TRANSACTIONS = `${name}SET_MOMNT_ALL_TRANSACTIONS`;
export const SET_MOMNT_AVAILABLE_TRANSACTIONS = `${name}SET_MOMNT_AVAILABLE_TRANSACTIONS`;
export const SET_MOMNT_RECENT_ACTIVITY = `${name}SET_MOMNT_RECENT_ACTIVITY`;
export const SET_MOMNT_OFFER_CODES = `${name}SET_MOMNT_OFFER_CODES`;
export const SET_MOMNT_STATEMENTS = `${name}SET_MOMNT_STATEMENTS`;
export const SET_MOMNT_RATE_SHEETS = `${name}SET_MOMNT_RATE_SHEETS`;

export const UNAVAILABLE_STATES_KEY = `${name}UNAVAILABLE_STATES_KEY`;
export const LOAN_APPLICATION_DETAILS_KEY = `${name}LOAN_APPLICATION_DETAILS`;
export const FINANCING_TYPES_KEY = `${name}FINANCING_TYPES_KEY`;

export const defaultFinancingType: FinancingType = {
  type: DefaultFinancingTypes.BasicFinancing,
  logo: '',
  url: '',
};

export const defaultFinancing: FinancingSetting = {
  months: 60,
  percentageRate: 7.9,
  financingType: defaultFinancingType,
};

export const initialPagination = {
  show: 5,
  page: 1,
};

export const initialPaginationForIncreasedTables = {
  show: 10,
  page: 1,
};

export const defaultMomntItem = {
  total: 0,
  loaded: false,
  data: [],
};

export const PreferencesColors = {
  [CustomerPreference.promotionMotivated]: '#83C4CD',
  [CustomerPreference.interestRateSensitive]: '#738C95',
  [CustomerPreference.paymentSensitive]: '#1A3C4D',
};

export const TransactionDescriptions = {
  109: 'Consumer Deposit/Initial Charge',
  111: 'Consumer Project Charge',
  113: 'Consumer Final Charge',
} as const;

export const NoFinancing = -1;
export const BasePackage = 0;

export const FINANCING_BLOCKER_KEY = 'financingBlocker';

export const DEFAULT_FINANCING_TYPES_CONFIG: Record<SubscriptionCountry, string[]> = {
  [SubscriptionCountry.USA]: [
    DefaultFinancingTypes.BasicFinancing,
    DefaultFinancingTypes.ContractorLoanPro,
    DefaultFinancingTypes.GreenSky,
    DefaultFinancingTypes.PowerPay,
    DefaultFinancingTypes.ServiceFinance,
    DefaultFinancingTypes.Synchrony,
  ],
  [SubscriptionCountry.Canada]: [
    DefaultFinancingTypes.BasicFinancing,
    DefaultFinancingTypes.Financeit,
  ],
};

export const DEFAULT_FINANCING_TYPES: FinancingType[] = [
  {
    type: DefaultFinancingTypes.BasicFinancing,
    logo: '',
    url: '',
  },
  {
    type: DefaultFinancingTypes.ContractorLoanPro,
    logo: '/images/financing/CLP-logo.svg',
    url: '',
  },
  {
    type: DefaultFinancingTypes.GreenSky,
    logo: '/images/financing/GreenSky-logo.svg',
    url: '',
  },
  {
    type: DefaultFinancingTypes.PowerPay,
    logo: '/images/financing/PowerPay-logo.svg',
    url: '',
  },
  {
    type: DefaultFinancingTypes.ServiceFinance,
    logo: '/images/financing/Service-Finance-logo.svg',
    url: '',
  },
  {
    type: DefaultFinancingTypes.Synchrony,
    logo: '/images/financing/Synchrony-logo.svg',
    url: '',
  },
  {
    type: DefaultFinancingTypes.Financeit,
    logo: '/images/financing/Financeit-logo.svg',
    url: '',
  },
];

export const notEditableDefaultFinancingTypes: string[] = [
  DefaultFinancingTypes.BasicFinancing,
  DefaultFinancingTypes.ContractorLoanPro,
];

export const DEFAULT_FINANCING_SETTINGS_CONFIG: FinancingSettingsConfig = {
  applyFinancingToMarketsModal: {
    switchSelectedMarketsToBasicFinancingText: I18nEnum.SwitchSelectedMarketsToBasicFinancingUSA,
  },
};

export const CANADA_FINANCING_SETTINGS_CONFIG: FinancingSettingsConfig = {
  applyFinancingToMarketsModal: {
    switchSelectedMarketsToBasicFinancingText: I18nEnum.SwitchSelectedMarketsToBasicFinancingCanada,
  },
};
