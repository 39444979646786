import { I18nEnum } from 'types';
import { StatisticsType } from 'modules/dashboard/types';

export const ActionLabelsMap = {
  [StatisticsType.ContactsReporting]: I18nEnum.RQPLeads,
  [StatisticsType.PotentialContactsReporting]: I18nEnum.OtherContacts,
  [StatisticsType.AddressesOnlyReporting]: I18nEnum.AddressOnly,
};

export const AuthActionLabels = {
  login: 'Login',
  logout: 'Logout',
} as const;

export const ActionLabels = {
  OpenLead: 'OpenLead',
  AddProduct: 'AddProduct',
  CustomizeSelectedProduct: 'CustomizeSelectedProduct',
  DeleteSelectedProduct: 'DeleteSelectedProduct',
  TurnOffOnOfferCode: 'TurnOffOnOfferCode',
  CustomButton: 'CustomButton',
};
