import { I18nEnum } from 'types';
import {
  SubscriptionConfig,
  SubscriptionConfigMap,
  SubscriptionCountry,
  SubscriptionPlan,
  SubscriptionRequestType,
  SubscriptionType,
  SubscriptionTypes,
} from 'modules/subscription/types';
import {
  DEFAULT_BRANDING_CONFIG,
  DEFAULT_OC_BRANDING_CONFIG,
  DEFAULT_SETTINGS_CONFIG,
  OC_SETTINGS_CONFIG,
} from 'modules/widget/constants';
import { DEFAULT_QQ_CONFIG, OC_QUICK_QUOTE_CONFIG } from 'modules/quickQuoteConfig/constants';
import { OC_REP_QUOTE_CONFIG, DEFAULT_REP_QUOTE_CONFIG } from 'modules/repQuotes/quickQuoteConfig';
import {
  DEFAULT_PRODUCT_SETTINGS_CONFIG,
  OC_PRODUCT_SETTINGS_CONFIG,
  RQP_PRODUCT_SETTINGS_CONFIG,
} from 'modules/product/constants';
import {
  DEFAULT_BILLING_SETTINGS_CONFIG,
  CANADA_BILLING_SETTINGS_CONFIG,
} from 'modules/billing/constants';
import {
  DEFAULT_ORDERS_SETTINGS_CONFIG,
  CANADA_ORDERS_SETTINGS_CONFIG,
} from 'modules/orders/constants';
import { ContactsFormOptionValue } from 'modules/widget/types';
import {
  CANADA_FINANCING_SETTINGS_CONFIG,
  DEFAULT_FINANCING_SETTINGS_CONFIG,
} from 'modules/financing/constants';
import { DEFAULT_PROFILE_SETTINGS, PROFILE_SETTINGS_WITHOUT_CLP } from '../company/constants';

const name = 'subscription/';

export const SUBSCRIPTIONS_KEY = `${name}SUBSCRIPTIONS`;

export const SAVE_SUBSCRIPTION_CONFIG = `${name}/save-config`;

export const subscriptionTypeToTranslationMap: Record<SubscriptionType, I18nEnum> = {
  // the order matters
  [SubscriptionTypes.RoofQuoteProUSA]: I18nEnum.RoofQuotePROUSA,
  [SubscriptionTypes.RoofQuoteProCanada]: I18nEnum.RoofQuoteProCanada,
  [SubscriptionTypes.OwensCorningProUSA]: I18nEnum.OCRoofQuotePROUSA,
  [SubscriptionTypes.OwensCorningProCanada]: I18nEnum.OCRoofQuotePROCanada,
};

export const subscriptionPlanToTranslationMap: Record<SubscriptionPlan, I18nEnum> = {
  // the order matters
  [SubscriptionPlan.MonthlySubscription]: I18nEnum.MonthlySubscription,
  [SubscriptionPlan.AnnualRollup]: I18nEnum.AnnualRollup,
  [SubscriptionPlan.AnnualDiscount]: I18nEnum.AnnualDiscount,
};

export const subscriptionRequestToTranslationMap: Record<SubscriptionRequestType, I18nEnum> = {
  // the order matters
  [SubscriptionRequestType.Activation]: I18nEnum.Activation,
  [SubscriptionRequestType.Pause]: I18nEnum.Pause,
};

export const DEFAULT_SUBSCRIPTION_CONFIG: SubscriptionConfig = {
  defaultBranding: { ...DEFAULT_BRANDING_CONFIG },
  defaultQuickQuoteConfigState: { ...DEFAULT_QQ_CONFIG },
  defaultRepQuoteConfigState: { ...DEFAULT_REP_QUOTE_CONFIG },
  widgetSettings: { ...DEFAULT_SETTINGS_CONFIG },
  productSettings: {
    ...DEFAULT_PRODUCT_SETTINGS_CONFIG,
  },
  billingSettings: { ...DEFAULT_BILLING_SETTINGS_CONFIG },
  ordersSettings: { ...DEFAULT_ORDERS_SETTINGS_CONFIG },
  financingSettings: { ...DEFAULT_FINANCING_SETTINGS_CONFIG },
  isDefault: true,
  profileSettings: { ...DEFAULT_PROFILE_SETTINGS },
};

export const SUBSCRIPTION_CONFIG_MAP: SubscriptionConfigMap = {
  [SubscriptionTypes.RoofQuoteProUSA]: {
    defaultBranding: { ...DEFAULT_BRANDING_CONFIG },
    defaultQuickQuoteConfigState: { ...DEFAULT_QQ_CONFIG },
    defaultRepQuoteConfigState: { ...DEFAULT_REP_QUOTE_CONFIG },
    widgetSettings: { ...DEFAULT_SETTINGS_CONFIG },
    productSettings: {
      ...RQP_PRODUCT_SETTINGS_CONFIG,
    },
    billingSettings: { ...DEFAULT_BILLING_SETTINGS_CONFIG },
    ordersSettings: { ...DEFAULT_ORDERS_SETTINGS_CONFIG },
    financingSettings: { ...DEFAULT_FINANCING_SETTINGS_CONFIG },
    isDefault: false,
    subscriptionType: SubscriptionTypes.RoofQuoteProUSA,
    profileSettings: { ...DEFAULT_PROFILE_SETTINGS },
  },
  [SubscriptionTypes.OwensCorningProUSA]: {
    defaultBranding: { ...DEFAULT_OC_BRANDING_CONFIG },
    defaultQuickQuoteConfigState: {
      ...OC_QUICK_QUOTE_CONFIG,
    },
    defaultRepQuoteConfigState: {
      ...OC_REP_QUOTE_CONFIG,
    },
    widgetSettings: { ...OC_SETTINGS_CONFIG },
    productSettings: {
      ...OC_PRODUCT_SETTINGS_CONFIG,
    },
    billingSettings: { ...DEFAULT_BILLING_SETTINGS_CONFIG },
    ordersSettings: { ...DEFAULT_ORDERS_SETTINGS_CONFIG },
    financingSettings: { ...DEFAULT_FINANCING_SETTINGS_CONFIG },
    isDefault: false,
    subscriptionType: SubscriptionTypes.OwensCorningProUSA,
    profileSettings: { ...DEFAULT_PROFILE_SETTINGS },
  },
  [SubscriptionTypes.RoofQuoteProCanada]: {
    defaultBranding: {
      ...DEFAULT_BRANDING_CONFIG,
      rqpLeads: {
        ...DEFAULT_BRANDING_CONFIG.rqpLeads,
        smsOptIn: ContactsFormOptionValue.Optional,
        contactOptIn: ContactsFormOptionValue.Required,
      },
      otherContacts: {
        ...DEFAULT_BRANDING_CONFIG.otherContacts,
        smsOptIn: ContactsFormOptionValue.Optional,
        contactOptIn: ContactsFormOptionValue.Required,
      },
    },
    defaultQuickQuoteConfigState: { ...DEFAULT_QQ_CONFIG },
    defaultRepQuoteConfigState: { ...DEFAULT_REP_QUOTE_CONFIG },
    widgetSettings: { ...DEFAULT_SETTINGS_CONFIG },
    productSettings: {
      ...RQP_PRODUCT_SETTINGS_CONFIG,
      countryAvailableIn: SubscriptionCountry.Canada,
      showCatalogLink: false,
      showFinancingTypeInfoTooltip: false,
    },
    billingSettings: { ...CANADA_BILLING_SETTINGS_CONFIG },
    ordersSettings: { ...CANADA_ORDERS_SETTINGS_CONFIG },
    financingSettings: { ...CANADA_FINANCING_SETTINGS_CONFIG },
    isDefault: false,
    subscriptionType: SubscriptionTypes.RoofQuoteProCanada,
    profileSettings: { ...PROFILE_SETTINGS_WITHOUT_CLP },
  },
  [SubscriptionTypes.OwensCorningProCanada]: {
    defaultBranding: {
      ...DEFAULT_OC_BRANDING_CONFIG,
      rqpLeads: {
        ...DEFAULT_BRANDING_CONFIG.rqpLeads,
        smsOptIn: ContactsFormOptionValue.Optional,
        contactOptIn: ContactsFormOptionValue.Required,
      },
      otherContacts: {
        ...DEFAULT_BRANDING_CONFIG.otherContacts,
        smsOptIn: ContactsFormOptionValue.Optional,
        contactOptIn: ContactsFormOptionValue.Required,
      },
    },
    defaultQuickQuoteConfigState: {
      ...OC_QUICK_QUOTE_CONFIG,
    },
    defaultRepQuoteConfigState: {
      ...OC_REP_QUOTE_CONFIG,
    },
    widgetSettings: { ...OC_SETTINGS_CONFIG },
    productSettings: {
      ...OC_PRODUCT_SETTINGS_CONFIG,
      countryAvailableIn: SubscriptionCountry.Canada,
      showCatalogLink: false,
      showFinancingTypeInfoTooltip: false,
    },
    billingSettings: { ...CANADA_BILLING_SETTINGS_CONFIG },
    ordersSettings: { ...CANADA_ORDERS_SETTINGS_CONFIG },
    financingSettings: { ...CANADA_FINANCING_SETTINGS_CONFIG },
    isDefault: false,
    subscriptionType: SubscriptionTypes.OwensCorningProCanada,
    profileSettings: { ...PROFILE_SETTINGS_WITHOUT_CLP },
  },
};
