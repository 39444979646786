import { useState, useCallback, useMemo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Input as InputBase } from 'antd';
import styled from 'styled-components';
import { TextAreaProps, TextAreaRef } from 'antd/lib/input/TextArea';

import inputStyles from './inputStyles';
import useNavigation from 'modules/hooks/useNavigation';
import { colors } from 'global-constants';
import { I18nEnum } from 'types';

const { TextArea: TextAreaBase } = InputBase;

interface Props extends TextAreaProps {
  counter?: boolean;
  hasError?: boolean;
}

const TextAreaComponent = ({
  onChange,
  maxLength,
  value: _value,
  counter,
  hasError,
  ...props
}: Props) => {
  const [inputType] = useNavigation();
  const [focused, setFocused] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [value, setValue] = useState(_value || props.defaultValue);

  const onFocus = useCallback(() => setFocused(true), []);
  const onBlur = useCallback(() => setFocused(false), []);

  const _onChange = useCallback(
    event => {
      onChange && onChange(event);
      setValue(event.target.value);
    },
    [onChange],
  );

  const measuredRef = useCallback((ref: TextAreaRef) => {
    setDisabled(!!(ref && ref.resizableTextArea && ref.resizableTextArea.textArea.disabled));
  }, []);

  const countSymbolsLeft = useMemo(() => {
    if (maxLength) {
      return maxLength - (value || '').toString().length;
    }

    return 0;
  }, [value, maxLength]);

  useEffect(() => {
    setValue(_value);
  }, [_value]);

  return (
    <Root
      className="textarea-root"
      data-whatinput={inputType}
      focused={focused}
      disabled={disabled}
      hasError={hasError}>
      <TextArea
        {...props}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={_onChange}
        maxLength={maxLength}
        value={value}
        ref={measuredRef}
      />
      {maxLength && counter && (
        <Tooltip>
          <FormattedMessage id={I18nEnum.SymbolsLeft} values={{ count: countSymbolsLeft }} />
        </Tooltip>
      )}
    </Root>
  );
};

const Root = styled.div<{ focused: boolean; disabled: boolean; hasError?: boolean }>`
  height: 143px !important;
  padding: 10px 0px 27px 0px;
  ${inputStyles}

  ${props =>
    props.focused &&
    ` 
      &, &:hover {
        border: 1px solid ${props.theme.colors.orange};
      }
      &[data-whatinput='keyboard'] {
        box-shadow: 0 0 0px 2px ${colors.blue};
        border: 2px solid white;
        box-sizing: border-box;
    }
  `}

  ${props => props.disabled && 'border: 1px solid rgba(0, 0, 0, 0.2);'}

  ${props => props.hasError && `border-color: ${props.theme.colors.error} !important;`}
`;

const TextArea = styled(TextAreaBase)`
  ${inputStyles}
  border: 0;
  height: 104px !important;
  padding: 0px 37px 0px 10px;
  width: 100%;
  resize: none;
  box-shadow: none !important;
  line-height: 19px;

  &:focus,
  &:hover,
  &:disabled,
  &[disabled]:hover {
    border: 0 !important;
    box-shadow: none !important;
  }
`;

const Tooltip = styled.span`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.24px;
  color: ${props => props.theme.colors.grey};
  margin-left: 10px;
`;

export default TextAreaComponent;
