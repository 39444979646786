import { useSelector } from 'react-redux';

import { queryClient, useAppQuery } from 'modules/reactQuery';
import { constants, services, Market } from 'modules/markets';
import { selectors as userSelectors } from 'modules/auth';

export const invalidateMarketsAccounts = () => {
  queryClient.invalidateQueries({ queryKey: [constants.GET_USER_MARKETS_KEY] });
};

export const useGetUserMarkets = (select?: (data: Market[]) => Market[]) => {
  const user = useSelector(userSelectors.selectUser);

  return useAppQuery<Market[]>(
    [constants.GET_USER_MARKETS_KEY],
    () => {
      return services.getUserMarketsAPI(user.id);
    },
    {
      enabled: !!user.id,
      select,
    },
  );
};

export const useGetUserMarketBySlug = (slug?: string) => {
  const select = (markets: Market[]) => markets.filter(item => item.market.slug === slug);
  return useGetUserMarkets(select);
};
