import cloneDeep from 'lodash/cloneDeep';

import { BrandingConfig, WidgetSettings } from 'modules/widget/types/toolScript';
import { DEFAULT_OC_BRANDING_CONFIG } from 'modules/widget/constants/toolScript';
import { MeasurementsReportType } from 'modules/dashboard/types/toolScript';
import { SubscriptionType, SubscriptionTypes } from 'modules/subscription/types/toolScript';

const isValueExists = (value?: number | string | null | boolean): boolean =>
  value !== undefined && value !== null;

const transformToRQPWidgetBrandingSettings = (
  _branding: BrandingConfig,
  branding: WidgetSettings,
): BrandingConfig => {
  _branding.background.fillColor = branding.backgroundFillColor || _branding.background.fillColor;

  _branding.checkbox.fillColor = branding.checkboxFillColor || _branding.checkbox.fillColor;
  _branding.checkbox.strokeColor = branding.checkboxStrokeColor || _branding.checkbox.strokeColor;
  _branding.checkbox.cornerRadius = isValueExists(branding.checkboxCornerRadius)
    ? (branding.checkboxCornerRadius as number)
    : _branding.checkbox.cornerRadius;

  _branding.icons.fillColor = branding.iconsFillColor || _branding.icons.fillColor;

  _branding.illustration.activeColor =
    branding.illustrationSelectedColor || _branding.illustration.activeColor;
  _branding.illustration.disabledColor =
    branding.illustrationUnselectedColor || _branding.illustration.disabledColor;

  _branding.mainButton.fillColor = branding.mainButtonFillColor || _branding.mainButton.fillColor;
  _branding.mainButton.fontColor = branding.mainButtonFontColor || _branding.mainButton.fontColor;
  _branding.mainButton.cornerRadius = isValueExists(branding.mainButtonCornerRadius)
    ? (branding.mainButtonCornerRadius as number)
    : _branding.mainButton.cornerRadius;
  _branding.mainButton.strokeColor =
    branding.mainButtonStrokeColor || _branding.mainButton.strokeColor;
  _branding.mainButton.font = branding.mainButtonFont || _branding.mainButton.font;
  _branding.mainButton.fontWeight =
    branding.mainButtonFontWeight || _branding.mainButton.fontWeight;
  _branding.mainButton.fontSize = branding.mainButtonFontSize || _branding.mainButton.fontSize;
  _branding.mainButton.seeProductDetailsButtonText =
    branding.seeProductDetailsButtonText || _branding.mainButton.seeProductDetailsButtonText;
  _branding.mainButton.customFinancingButtonText =
    branding.customFinancingButtonText || _branding.mainButton.customFinancingButtonText;

  _branding.mainText.fontColor = branding.mainTextFontColor || _branding.mainText.fontColor;
  _branding.mainText.font = branding.mainTextFont || _branding.mainText.font;
  _branding.mainText.exampleText = branding.mainTextExampleText || _branding.mainText.exampleText;

  _branding.roof.fillColor = branding.roofFillColor || _branding.roof.fillColor;
  _branding.roof.strokeColor = branding.roofStrokeColor || _branding.roof.strokeColor;

  _branding.secondaryButton.fillColor =
    branding.secondaryButtonFillColor || _branding.secondaryButton.fillColor;
  _branding.secondaryButton.fontColor =
    branding.secondaryButtonFontColor || _branding.secondaryButton.fontColor;
  _branding.secondaryButton.font = branding.secondaryButtonFont || _branding.secondaryButton.font;
  _branding.secondaryButton.strokeColor =
    branding.secondaryButtonStrokeColor || _branding.secondaryButton.strokeColor;
  _branding.secondaryButton.cornerRadius = isValueExists(branding.secondaryButtonCornerRadius)
    ? (branding.secondaryButtonCornerRadius as number)
    : _branding.secondaryButton.cornerRadius;
  _branding.secondaryButton.fontWeight =
    branding.secondaryButtonFontWeight || _branding.secondaryButton.fontWeight;
  _branding.secondaryButton.fontSize =
    branding.secondaryButtonFontSize || _branding.secondaryButton.fontSize;

  _branding.secondaryText.fontColor =
    branding.secondaryTextFontColor || _branding.secondaryText.fontColor;
  _branding.secondaryText.font = branding.secondaryTextFont || _branding.secondaryText.font;

  _branding.titles.fontColor = branding.titlesFontColor || _branding.titles.fontColor;
  _branding.titles.font = branding.titlesFont || _branding.titles.font;
  _branding.titles.mainTitleText = branding.titlesMainTitleText || _branding.titles.mainTitleText;

  _branding.subtitles.fontColor = branding.subtitlesFontColor || _branding.subtitles.fontColor;
  _branding.subtitles.font = branding.subtitlesFont || _branding.subtitles.font;

  _branding.tooltipAndMapControls.fillColor =
    branding.tooltipAndMapControlsFillColor || _branding.tooltipAndMapControls.fillColor;
  _branding.tooltipAndMapControls.fontColor =
    branding.tooltipAndMapControlsFontColor || _branding.tooltipAndMapControls.fontColor;
  _branding.tooltipAndMapControls.strokeColor =
    branding.tooltipAndMapControlsStrokeColor || _branding.tooltipAndMapControls.strokeColor;
  _branding.tooltipAndMapControls.cornerRadius = isValueExists(
    branding.tooltipAndMapControlsCornerRadius,
  )
    ? (branding.tooltipAndMapControlsCornerRadius as number)
    : _branding.tooltipAndMapControls.cornerRadius;

  return _branding;
};

const transformToRQPToolCalloutBrandingSettings = (
  _branding: BrandingConfig,
  branding: WidgetSettings,
): BrandingConfig => {
  _branding.roofQuoteProButton.fillColor =
    branding.rqpButtonFillColor || _branding.roofQuoteProButton.fillColor;
  _branding.roofQuoteProButton.fontColor =
    branding.rqpButtonFontColor || _branding.roofQuoteProButton.fontColor;
  _branding.roofQuoteProButton.font = branding.rqpButtonFont || _branding.roofQuoteProButton.font;
  _branding.roofQuoteProButton.fontSize =
    branding.rqpButtonFontSize || _branding.roofQuoteProButton.fontSize;
  _branding.roofQuoteProButton.fontWeight =
    branding.rqpButtonFontWeight || _branding.roofQuoteProButton.fontWeight;
  _branding.roofQuoteProButton.strokeColor =
    branding.rqpButtonStrokeColor || _branding.roofQuoteProButton.strokeColor;
  _branding.roofQuoteProButton.horisontalPosition =
    branding.rqpButtonHorisontalPosition || _branding.roofQuoteProButton.horisontalPosition;
  _branding.roofQuoteProButton.text = branding.rqpButtonText || _branding.roofQuoteProButton.text;

  _branding.callout.fillColor = branding.calloutFillColor || _branding.callout.fillColor;
  _branding.callout.strokeColor = branding.calloutStrokeColor || _branding.callout.strokeColor;
  _branding.callout.titleText = branding.calloutTitleText || _branding.callout.titleText;
  _branding.callout.titleFont = branding.calloutTitleFont || _branding.callout.titleFont;
  _branding.callout.titleFontColor =
    branding.calloutTitleFontColor || _branding.callout.titleFontColor;
  _branding.callout.descriptionText =
    branding.calloutDescriptionText || _branding.callout.descriptionText;
  _branding.callout.descriptionTextFont =
    branding.calloutDescriptionTextFont || _branding.callout.descriptionTextFont;
  _branding.callout.descriptionTextFontColor =
    branding.calloutDescriptionTextFontColor || _branding.callout.descriptionTextFontColor;

  _branding.callout.showOnTabletsAndDesktops = isValueExists(
    branding.calloutShowOnTabletsAndDesktops,
  )
    ? branding.calloutShowOnTabletsAndDesktops
    : _branding.callout.showOnTabletsAndDesktops;
  _branding.callout.showOnPhones = isValueExists(branding.calloutShowOnPhones)
    ? branding.calloutShowOnPhones
    : _branding.callout.showOnPhones;

  return _branding;
};

const transformToContactsFormBrandingSettings = (
  _branding: BrandingConfig,
  branding: WidgetSettings,
): BrandingConfig => {
  const transformedBranding: BrandingConfig = cloneDeep(_branding);

  transformedBranding.otherContacts.legalTextEnabled = isValueExists(
    branding.otherContactsLegalTextEnabled,
  )
    ? branding.otherContactsLegalTextEnabled
    : _branding.otherContacts.legalTextEnabled;
  transformedBranding.otherContacts.legalText =
    branding.otherContactsLegalText || _branding.otherContacts.legalText;
  transformedBranding.otherContacts.legalTextFontSize =
    branding.otherContactsLegalTextFontSize || _branding.otherContacts.legalTextFontSize;
  transformedBranding.otherContacts.legalTextPosition =
    branding.otherContactsLegalTextPosition || _branding.otherContacts.legalTextPosition;
  transformedBranding.otherContacts.buttonText =
    branding.otherContactsButtonText || _branding.otherContacts.buttonText;
  transformedBranding.otherContacts.contactOptIn =
    branding.otherContactsContactOptIn || _branding.otherContacts.contactOptIn;
  transformedBranding.otherContacts.contactOptInText =
    branding.otherContactsContactOptInText || _branding.otherContacts.contactOptInText;
  transformedBranding.otherContacts.smsOptIn =
    branding.otherContactsSMSOptIn || _branding.otherContacts.smsOptIn;
  transformedBranding.otherContacts.smsOptInText =
    branding.otherContactsSMSOptInText || _branding.otherContacts.smsOptInText;
  transformedBranding.otherContacts.subTitleText =
    branding.otherContactsSubTitleText || _branding.otherContacts.subTitleText;
  transformedBranding.otherContacts.titleText =
    branding.otherContactsTitleText || _branding.otherContacts.titleText;

  transformedBranding.rqpLeads.legalTextEnabled = isValueExists(branding.rqpLeadsLegalTextEnabled)
    ? branding.rqpLeadsLegalTextEnabled
    : _branding.rqpLeads.legalTextEnabled;
  transformedBranding.rqpLeads.legalText =
    branding.rqpLeadsLegalText || _branding.rqpLeads.legalText;
  transformedBranding.rqpLeads.legalTextFontSize =
    branding.rqpLeadsLegalTextFontSize || _branding.rqpLeads.legalTextFontSize;
  transformedBranding.rqpLeads.legalTextPosition =
    branding.rqpLeadsLegalTextPosition || _branding.rqpLeads.legalTextPosition;
  transformedBranding.rqpLeads.buttonText =
    branding.rqpLeadsButtonText || _branding.rqpLeads.buttonText;
  transformedBranding.rqpLeads.contactOptIn =
    branding.rqpLeadsContactOptIn || _branding.rqpLeads.contactOptIn;
  transformedBranding.rqpLeads.contactOptInText =
    branding.rqpLeadsContactOptInText || _branding.rqpLeads.contactOptInText;
  transformedBranding.rqpLeads.smsOptIn = branding.rqpLeadsSMSOptIn || _branding.rqpLeads.smsOptIn;
  transformedBranding.rqpLeads.smsOptInText =
    branding.rqpLeadsSMSOptInText || _branding.rqpLeads.smsOptInText;
  transformedBranding.rqpLeads.subTitleText =
    branding.rqpLeadsSubTitleText || _branding.rqpLeads.subTitleText;
  transformedBranding.rqpLeads.titleText =
    branding.rqpLeadsTitleText || _branding.rqpLeads.titleText;

  return transformedBranding;
};

const transformToRQPBrandingSettings = (
  brandingConfig: BrandingConfig,
  branding: WidgetSettings,
): BrandingConfig => {
  const _branding: BrandingConfig = {
    ...transformToRQPWidgetBrandingSettings(brandingConfig, branding),
    ...transformToRQPToolCalloutBrandingSettings(brandingConfig, branding),
    ...transformToContactsFormBrandingSettings(brandingConfig, branding),
  };

  _branding.thankYou.thankYouButtonText =
    branding.thankYouButtonText || _branding.thankYou.thankYouButtonText;
  _branding.thankYou.thankYouExternalUrl =
    branding.thankYouExternalUrl || _branding.thankYou.thankYouExternalUrl;
  _branding.thankYou.thankYouMedia = isValueExists(branding.thankYouMedia)
    ? branding.thankYouMedia
    : _branding.thankYou.thankYouMedia;
  _branding.thankYou.thankYouMediaType = isValueExists(branding.thankYouMediaType)
    ? branding.thankYouMediaType
    : _branding.thankYou.thankYouMediaType;
  _branding.thankYou.thankYouShowOtherProducts = isValueExists(branding.thankYouShowOtherProducts)
    ? branding.thankYouShowOtherProducts
    : _branding.thankYou.thankYouShowOtherProducts;
  _branding.thankYou.thankYouSubtitle =
    branding.thankYouSubtitle || _branding.thankYou.thankYouSubtitle;
  _branding.thankYou.thankYouTitleText =
    branding.thankYouTitleText || _branding.thankYou.thankYouTitleText;
  _branding.thankYou.thankYouMediaProcessingStatus =
    branding.thankYouMediaProcessingStatus || _branding.thankYou.thankYouMediaProcessingStatus;

  _branding.measurementsOrder.isMeasurementsOrderEnabled = isValueExists(
    branding.isMeasurementsOrderEnabled,
  )
    ? branding.isMeasurementsOrderEnabled
    : _branding.measurementsOrder.isMeasurementsOrderEnabled;
  _branding.measurementsOrder.mainMeasurementsReportType = isValueExists(
    branding.mainMeasurementsReportType,
  )
    ? branding.mainMeasurementsReportType
    : _branding.measurementsOrder.mainMeasurementsReportType;
  _branding.measurementsOrder.mainMeasurementsReportDeliveryType =
    branding.mainMeasurementsReportDeliveryType
      ? branding.mainMeasurementsReportDeliveryType
      : _branding.measurementsOrder.mainMeasurementsReportDeliveryType;
  _branding.measurementsOrder.backupMeasurementsReportType = isValueExists(
    branding.backupMeasurementsReportType,
  )
    ? branding.backupMeasurementsReportType
    : MeasurementsReportType.NoReport;
  _branding.measurementsOrder.backupMeasurementsReportDeliveryType =
    branding.backupMeasurementsReportDeliveryType
      ? branding.backupMeasurementsReportDeliveryType
      : _branding.measurementsOrder.backupMeasurementsReportDeliveryType;

  _branding.mapSettings.centerpointType = branding.mapCenterpointType;
  _branding.mapSettings.placeholderMedia = branding.mapPlaceholderMedia;

  _branding.productDetails.sendProductButtonText =
    branding.sendProductButtonText || _branding.productDetails.sendProductButtonText;
  _branding.productDetails.getFinalProposalButtonText =
    branding.getFinalProposalButtonText || _branding.productDetails.getFinalProposalButtonText;

  _branding.overrides.useOwensCorningToolCalloutBranding =
    branding.useOwensCorningToolCalloutBranding;
  _branding.overrides.useOwensCorningWidgetBranding = branding.useOwensCorningWidgetBranding;

  return _branding;
};

const transformToOCBrandingSettings = (
  _branding: BrandingConfig,
  branding: WidgetSettings,
): BrandingConfig => {
  const { rqpLeads, otherContacts } = transformToContactsFormBrandingSettings(_branding, branding);

  _branding.otherContacts = otherContacts;
  _branding.rqpLeads = rqpLeads;

  _branding.roofQuoteProButton.horisontalPosition =
    branding.rqpButtonHorisontalPosition || _branding.roofQuoteProButton.horisontalPosition;

  _branding.callout.showOnTabletsAndDesktops = isValueExists(
    branding.calloutShowOnTabletsAndDesktops,
  )
    ? branding.calloutShowOnTabletsAndDesktops
    : _branding.callout.showOnTabletsAndDesktops;
  _branding.callout.showOnPhones = isValueExists(branding.calloutShowOnPhones)
    ? branding.calloutShowOnPhones
    : _branding.callout.showOnPhones;

  _branding.thankYou.thankYouButtonText =
    branding.thankYouButtonText || _branding.thankYou.thankYouButtonText;
  _branding.thankYou.thankYouExternalUrl =
    branding.thankYouExternalUrl || _branding.thankYou.thankYouExternalUrl;
  _branding.thankYou.thankYouMedia = isValueExists(branding.thankYouMedia)
    ? branding.thankYouMedia
    : _branding.thankYou.thankYouMedia;
  _branding.thankYou.thankYouMediaType = isValueExists(branding.thankYouMediaType)
    ? branding.thankYouMediaType
    : _branding.thankYou.thankYouMediaType;
  _branding.thankYou.thankYouShowOtherProducts = isValueExists(branding.thankYouShowOtherProducts)
    ? branding.thankYouShowOtherProducts
    : _branding.thankYou.thankYouShowOtherProducts;
  _branding.thankYou.thankYouSubtitle =
    branding.thankYouSubtitle || _branding.thankYou.thankYouSubtitle;
  _branding.thankYou.thankYouTitleText =
    branding.thankYouTitleText || _branding.thankYou.thankYouTitleText;
  _branding.thankYou.thankYouMediaProcessingStatus =
    branding.thankYouMediaProcessingStatus || _branding.thankYou.thankYouMediaProcessingStatus;

  _branding.measurementsOrder.isMeasurementsOrderEnabled = isValueExists(
    branding.isMeasurementsOrderEnabled,
  )
    ? branding.isMeasurementsOrderEnabled
    : _branding.measurementsOrder.isMeasurementsOrderEnabled;
  _branding.measurementsOrder.mainMeasurementsReportType = isValueExists(
    branding.mainMeasurementsReportType,
  )
    ? branding.mainMeasurementsReportType
    : _branding.measurementsOrder.mainMeasurementsReportType;
  _branding.measurementsOrder.mainMeasurementsReportDeliveryType =
    branding.mainMeasurementsReportDeliveryType
      ? branding.mainMeasurementsReportDeliveryType
      : _branding.measurementsOrder.mainMeasurementsReportDeliveryType;
  _branding.measurementsOrder.backupMeasurementsReportType = isValueExists(
    branding.backupMeasurementsReportType,
  )
    ? branding.backupMeasurementsReportType
    : MeasurementsReportType.NoReport;
  _branding.measurementsOrder.backupMeasurementsReportDeliveryType =
    branding.backupMeasurementsReportDeliveryType
      ? branding.backupMeasurementsReportDeliveryType
      : _branding.measurementsOrder.backupMeasurementsReportDeliveryType;

  _branding.mapSettings.centerpointType = branding.mapCenterpointType;
  _branding.mapSettings.placeholderMedia = branding.mapPlaceholderMedia;

  _branding.productDetails.sendProductButtonText =
    branding.sendProductButtonText || _branding.productDetails.sendProductButtonText;
  _branding.productDetails.getFinalProposalButtonText =
    branding.getFinalProposalButtonText || _branding.productDetails.getFinalProposalButtonText;

  return _branding;
};

const transformToMockOCBrandingSettings = (
  brandingConfig: BrandingConfig,
  branding: WidgetSettings,
): BrandingConfig => {
  let _branding = transformToOCBrandingSettings(brandingConfig, branding);

  _branding.overrides.useOwensCorningToolCalloutBranding =
    branding.useOwensCorningToolCalloutBranding;
  _branding.overrides.useOwensCorningWidgetBranding = branding.useOwensCorningWidgetBranding;

  if (branding.useOwensCorningWidgetBranding) {
    _branding.mainButton.seeProductDetailsButtonText =
      branding.seeProductDetailsButtonText || _branding.mainButton.seeProductDetailsButtonText;
    _branding.mainButton.customFinancingButtonText =
      branding.customFinancingButtonText || _branding.mainButton.customFinancingButtonText;

    _branding.mainText.exampleText = branding.mainTextExampleText || _branding.mainText.exampleText;

    _branding.titles.mainTitleText = branding.titlesMainTitleText || _branding.titles.mainTitleText;
  } else {
    _branding = {
      ..._branding,
      ...transformToRQPWidgetBrandingSettings(brandingConfig, branding),
    };
  }

  if (branding.useOwensCorningToolCalloutBranding) {
    _branding.callout.titleText = branding.calloutTitleText || _branding.callout.titleText;
    _branding.callout.descriptionText =
      branding.calloutDescriptionText || _branding.callout.descriptionText;

    _branding.roofQuoteProButton.text = branding.rqpButtonText || _branding.roofQuoteProButton.text;
  } else {
    _branding = {
      ..._branding,
      ...transformToRQPToolCalloutBrandingSettings(brandingConfig, branding),
    };
  }

  return _branding;
};

const getMockOCBrandingConfig = (
  branding: WidgetSettings,
  brandingConfig: BrandingConfig,
): BrandingConfig => {
  const _branding = cloneDeep(DEFAULT_OC_BRANDING_CONFIG);

  if (!branding.useOwensCorningWidgetBranding) {
    _branding.titles = brandingConfig.titles;
    _branding.subtitles = brandingConfig.subtitles;
    _branding.mainText = brandingConfig.mainText;
    _branding.secondaryText = brandingConfig.secondaryText;
    _branding.icons = brandingConfig.icons;
    _branding.roof = brandingConfig.roof;
    _branding.tooltipAndMapControls = brandingConfig.tooltipAndMapControls;
    _branding.illustration = brandingConfig.illustration;
    _branding.background = brandingConfig.background;
    _branding.mainButton = brandingConfig.mainButton;
    _branding.secondaryButton = brandingConfig.secondaryButton;
    _branding.checkbox = brandingConfig.checkbox;
    _branding.thankYou = brandingConfig.thankYou;
    _branding.productDetails = brandingConfig.productDetails;
    _branding.howInstantLoanWorks = brandingConfig.howInstantLoanWorks;
    _branding.howToReplyYourLoan = brandingConfig.howToReplyYourLoan;
    _branding.invitationSent = brandingConfig.invitationSent;
    _branding.widgetUnavailable = brandingConfig.widgetUnavailable;
    _branding.caption = brandingConfig.caption;
    _branding.warningModal = brandingConfig.warningModal;
    _branding.radioButton = brandingConfig.radioButton;
  }

  if (!branding.useOwensCorningToolCalloutBranding) {
    _branding.callout = brandingConfig.callout;
    _branding.roofQuoteProButton = brandingConfig.roofQuoteProButton;
  }

  return _branding;
};

export const transformToFrontendSettings = (
  brandingConfig: BrandingConfig,
  branding: WidgetSettings,
  subscriptionType?: SubscriptionType,
): BrandingConfig => {
  const _brandingConfig = cloneDeep(brandingConfig);
  const useOwensCorningBranding =
    branding.useOwensCorningToolCalloutBranding || branding.useOwensCorningWidgetBranding;

  const _branding: BrandingConfig = useOwensCorningBranding
    ? getMockOCBrandingConfig(branding, _brandingConfig)
    : _brandingConfig;

  switch (subscriptionType) {
    case SubscriptionTypes.RoofQuoteProUSA:
    case SubscriptionTypes.RoofQuoteProCanada:
      return useOwensCorningBranding
        ? transformToMockOCBrandingSettings(_branding, branding)
        : transformToRQPBrandingSettings(_branding, branding);
    case SubscriptionTypes.OwensCorningProUSA:
    case SubscriptionTypes.OwensCorningProCanada:
      return transformToOCBrandingSettings(_branding, branding);
    default:
      return brandingConfig;
  }
};
