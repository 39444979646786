import { Feature, Polygon, Properties } from '@turf/helpers';
import { default as turfIntersect } from '@turf/intersect';
import { STRUCTURES_LIMIT, screenshotSettings } from 'modules/quickQuote/constants';
import {
  GeoJsonPolygon,
  StructureMetaDataResponse,
  RoofleStructure,
} from 'modules/quickQuote/types';
import { awaitTimeout } from 'utils';
import { getQuickQuoteStorage } from 'modules/quickQuote/storage';
import {
  assignStructureMetaData,
  buildFakeParcelFromExisting,
  findClosestBuildingToFauxParcel,
  getCompressedScreenshot,
  handleMapboxRenderedStructures,
  isStructureWithinParcel,
  toRoofleStructures,
} from 'modules/quickQuote/utils';
import { cloneDeep, kebabCase } from 'lodash';
import { selectQuickQuote } from 'modules/quickQuote/selectors';
import store from 'store';

export async function mapboxCreateMap({ centerpoint }: { centerpoint: [number, number] }) {
  const state = store.getState();
  const { structureMetaData, parcel, structures: persistedStructures } = selectQuickQuote(state);

  const { mapboxQuickQuoteInstance } = await import(
    'modules/mapbox/classes/MapboxQuickQuoteInstance'
  );

  // note the mapbox map can be called with a persisted parcel or null for an empty map
  const mapboxMap = await mapboxQuickQuoteInstance.createOrUpdateMap({ centerpoint, parcel });

  // no parcel exists on page init
  if (!parcel) {
    return {
      initialStructures: [],
      mapboxMap,
    };
  }

  if (process.env.REACT_APP_IS_PARCEL_LAYER_VISIBLE === '1') {
    mapboxQuickQuoteInstance.addParcelToMap(parcel);
  }

  // rep quotes and browser storage can persist structures
  const initialStructures = persistedStructures.length
    ? persistedStructures
    : await getInitialStructures({
        mapboxMap,
        parcel,
        structureMetaData,
      });

  if (!mapboxQuickQuoteInstance.roofleStructures.length) {
    if (initialStructures.length > 0 && initialStructures.length <= STRUCTURES_LIMIT) {
      // render structures if we have them
      mapboxQuickQuoteInstance.roofleStructures = initialStructures;
      mapboxQuickQuoteInstance.renderRoofleStructures();
    } else {
      // otherwise set a zoom for the user to draw structures
      mapboxQuickQuoteInstance.map?.setZoom(19);
    }
  }

  return { initialStructures, mapboxMap };
}

export async function getInitialStructures({
  mapboxMap,
  parcel,
  structureMetaData,
}: {
  mapboxMap: mapboxgl.Map;
  parcel: GeoJsonPolygon | null;
  structureMetaData: StructureMetaDataResponse;
}) {
  if (!parcel) {
    return [];
  }

  // use stored structures if present
  const quickQuoteStorage = await getQuickQuoteStorage();

  if (quickQuoteStorage?.structures && quickQuoteStorage?.structures.length) {
    return quickQuoteStorage.structures;
  }

  // give mapbox extra time to render the structures
  await awaitTimeout(400);

  const mapboxStructures = await extractStructuresFromMapboxGl({ map: mapboxMap, parcel });

  let combinedStructures = getCombinedStructures({
    mapboxStructures,
    structureMetaData,
  })
    // only use structures within the parcel inclusion logic
    .filter(structure => isStructureWithinParcel({ structure: structure, parcel }))
    // add earth define metadata to the matched structures
    .map(structure => assignStructureMetaData({ structure, structureMetaData }));

  // for faked parcels, only use the closest building to the centerpoint
  if (parcel.properties?.isFaux && combinedStructures.length) {
    const closestStructure = findClosestBuildingToFauxParcel({
      structures: combinedStructures,
      parcel,
    });
    combinedStructures = [closestStructure];
  }

  // if we have a real parcel, no structures, and any mapbox structures
  // try fetching a structure inside a small fake parcel
  if (!parcel.properties?.isFaux && !combinedStructures.length && mapboxStructures.length) {
    const fakeParcel = buildFakeParcelFromExisting({ parcel });

    const closestStructure = findClosestBuildingToFauxParcel({
      structures: mapboxStructures,
      parcel: fakeParcel,
    });
    combinedStructures = [closestStructure];
  }

  const initialStructures = toRoofleStructures({
    structures: combinedStructures,
  })
    // sort by roof size, large to small
    .sort((a, b) => b.measurements.squareFeet - a.measurements.squareFeet)
    .map(formatInitialStructure);

  return initialStructures;
}

// routine to pick all structures from two sets
// always prefer mapbox, and then only add fallback structures which do not overlap
// flatten multipolygons to polygons
function getCombinedStructures({
  mapboxStructures,
  structureMetaData,
}: {
  mapboxStructures: GeoJsonPolygon[];
  structureMetaData: StructureMetaDataResponse;
}): Feature<Polygon, Properties>[] {
  const result = cloneDeep(mapboxStructures);

  structureMetaData.forEach(fallbackStructure => {
    const hasIntersect = mapboxStructures.some(mapboxStructure => {
      return turfIntersect(mapboxStructure, fallbackStructure);
    });

    if (!hasIntersect) {
      result.push(fallbackStructure);
    }
  });

  const result2 = result.reduce((accumulator, structure) => {
    if (structure.geometry.type === 'MultiPolygon') {
      const structures = structure.geometry.coordinates.map(
        (coords): Feature<Polygon, Properties> => {
          return {
            ...structure,
            geometry: {
              type: 'Polygon',
              coordinates: coords,
            },
          };
        },
      );

      accumulator.push(...structures);
    } else if (structure.geometry.type === 'Polygon') {
      accumulator.push(structure as Feature<Polygon, Properties>);
    }

    return accumulator;
  }, [] as Feature<Polygon, Properties>[]);

  return result2;
}

// get Mapbox GL buildings within the parcel and return as geojson
async function extractStructuresFromMapboxGl({
  map,
  parcel,
}: {
  map: mapboxgl.Map;
  parcel: GeoJsonPolygon;
}) {
  // give mapbox up to 2 seconds unless it's a fake parcel
  const maxTime = parcel.properties?.isFaux ? 1000 : 2000;
  const interval = 250;
  let timeUsed = 0;

  // use a recursive function to check mapbox structures for up to the max time
  // this resolves mapbox rendering issues on slower connections
  async function awaitStructures(_map: mapboxgl.Map): Promise<mapboxgl.MapboxGeoJSONFeature[]> {
    return new Promise(resolve => {
      function tryStructures() {
        const structures = getMapboxGlStructures(_map);

        if (timeUsed > maxTime) {
          resolve([]);
          return;
        }

        if (structures.length) {
          resolve(structures);
          return;
        }

        setTimeout(() => {
          timeUsed += interval;
          tryStructures();
        }, interval);
      }

      tryStructures();
    });
  }

  const structures = await awaitStructures(map);

  if (!structures.length) {
    return [];
  }

  const result = await handleMapboxRenderedStructures({ structures });

  return result;
}

// get Mapbox rendered building structures from the initial Mapbox GL map rendering
function getMapboxGlStructures(map: mapboxgl.Map): mapboxgl.MapboxGeoJSONFeature[] {
  const features = map.queryRenderedFeatures();
  return features.filter(layer => layer.sourceLayer === 'building');
}

const emptyScreenshot = {
  bounds: null,
  screenshot: null,
};

export async function getMapboxClearScreenshotAndBounds(structures: RoofleStructure[]) {
  const { mapboxQuickQuoteInstance } = await import(
    'modules/mapbox/classes/MapboxQuickQuoteInstance'
  );

  if (!mapboxQuickQuoteInstance.map) {
    return emptyScreenshot;
  }

  const layerIds = structures.map(structure => structure.geoJsonPolygon.id);

  // give the slideout time to animate
  await awaitTimeout(450);

  await hideLayerIds(layerIds);

  // give mapbox time to re-render
  await awaitTimeout(100);

  const { bounds, screenshot } = await getMapboxScreenshot();

  await showLayerIds(layerIds);

  return { bounds, screenshot };
}

export async function getMapboxScreenshotOnlyPolygons() {
  const { mapboxQuickQuoteInstance } = await import(
    'modules/mapbox/classes/MapboxQuickQuoteInstance'
  );

  if (!mapboxQuickQuoteInstance.map) {
    return emptyScreenshot;
  }

  const { bounds, screenshot } = await getMapboxScreenshot();

  return { bounds, screenshot };
}

async function getMapboxScreenshot() {
  const { mapboxQuickQuoteInstance } = await import(
    'modules/mapbox/classes/MapboxQuickQuoteInstance'
  );

  const map = mapboxQuickQuoteInstance.map;
  if (!map) return emptyScreenshot;

  const mapContainer = map.getContainer();
  if (!mapContainer) return emptyScreenshot;

  const mapCanvas = map.getCanvas();

  if (mapContainer.clientWidth === screenshotSettings.width) {
    await fitToStructuresMapbox();
    const bounds = await getMapboxBoundsAsJson();
    const screenshot = getCompressedScreenshot(mapCanvas);
    return { bounds, screenshot };
  }

  const oldStyle = mapContainer.getAttribute('style');
  mapContainer.style.width = `${screenshotSettings.width}px`;
  mapContainer.style.height = `${screenshotSettings.height}px`;

  map.resize();
  await fitToStructuresMapbox();

  const bounds = await getMapboxBoundsAsJson();
  const screenshot = getCompressedScreenshot(mapCanvas);

  mapContainer.setAttribute(
    'style',
    oldStyle || 'height: 70vh; z-index: 0; position: relative; overflow: hidden;',
  );

  map.resize();
  mapboxQuickQuoteInstance.fitBoundsToRoofleStructures();

  return { bounds, screenshot };
}

async function fitToStructuresMapbox() {
  const { mapboxQuickQuoteInstance } = await import(
    'modules/mapbox/classes/MapboxQuickQuoteInstance'
  );
  mapboxQuickQuoteInstance.fitBoundsToRoofleStructures(false);

  // give mapbox time to redraw
  await awaitTimeout(400);
}

async function hideLayerIds(layerIds: (string | number | undefined)[]) {
  const { mapboxQuickQuoteInstance } = await import(
    'modules/mapbox/classes/MapboxQuickQuoteInstance'
  );

  layerIds.forEach(id => {
    if (!mapboxQuickQuoteInstance.map) return;
    mapboxQuickQuoteInstance.map.setLayoutProperty(String(id), 'visibility', 'none');
  });
}

async function showLayerIds(layerIds: (string | number | undefined)[]) {
  const { mapboxQuickQuoteInstance } = await import(
    'modules/mapbox/classes/MapboxQuickQuoteInstance'
  );

  layerIds.forEach(id => {
    if (!mapboxQuickQuoteInstance.map) return;
    mapboxQuickQuoteInstance.map.setLayoutProperty(String(id), 'visibility', 'visible');
  });
}

async function getMapboxBoundsAsJson() {
  const { mapboxQuickQuoteInstance } = await import(
    'modules/mapbox/classes/MapboxQuickQuoteInstance'
  );

  if (!mapboxQuickQuoteInstance.map) {
    return null;
  }

  const mapboxBounds = mapboxQuickQuoteInstance.map.getBounds();

  return {
    north: mapboxBounds.getNorth(),
    east: mapboxBounds.getEast(),
    south: mapboxBounds.getSouth(),
    west: mapboxBounds.getWest(),
  };
}

// give the structures default names
export const structureNamesByIndex = ['Main Roof', 'Second Roof', 'Third Roof'];

function formatInitialStructure(structure: RoofleStructure, index: number): RoofleStructure {
  const name = structureNamesByIndex[index] || `Additional Roof ${index - 2}`;
  const id = `${kebabCase(name)}-${structure.geoJsonPolygon.id}`;

  return {
    ...structure,
    name,
    geoJsonPolygon: {
      ...structure.geoJsonPolygon,
      id,
    },
  };
}
