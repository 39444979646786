import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import Button from 'components/Button/GhostButton';
import { I18nEnum } from 'types';
import { actions as modalActions, ModalTypes } from 'modules/modal';
import { SnippetTypeEnum } from 'modules/snippet/types';
import { ControlSettingsDifferenceContext } from './ControlSettings';
import { actions } from 'modules/snippet';

interface AddSnippetProps {
  lineId: number;
  snippetType: keyof typeof SnippetTypeEnum;
  /* used for save */
  snippetValue?: string | number;
  productName?: string;
  /* ------------  */
  hideSave?: boolean;
  snippetsButton?: JSX.Element;
  className?: string;
}

const AddSnippet: React.FC<AddSnippetProps> = ({
  lineId,
  snippetType,
  snippetValue,
  productName,
  hideSave,
  snippetsButton,
  className,
}) => {
  const dispatch = useDispatch();
  const { changeProductFields } = useContext(ControlSettingsDifferenceContext);

  const handleOpenSnippetsModal = useCallback(() => {
    dispatch(
      modalActions.openModal(ModalTypes.snippets, { lineId, snippetType, changeProductFields }),
    );
  }, [dispatch, changeProductFields, lineId, snippetType]);

  const handleSaveSnippet = useCallback(() => {
    if (snippetValue) {
      dispatch(actions.createSnippet(snippetType, { value: snippetValue, productName }));
    }
  }, [dispatch, snippetType, snippetValue, productName]);

  return (
    <Root className={className}>
      <Button
        onClick={handleOpenSnippetsModal}
        title={
          snippetsButton || (
            <>
              <img src="/images/product/add-icon.svg" />
              <FormattedMessage id={I18nEnum.AddFromSnippet} />
            </>
          )
        }
        htmlType="button"
      />
      {!hideSave && (
        <Button
          onClick={handleSaveSnippet}
          title={
            <>
              <SaveIcon src="/images/product/save-icon.svg" />
              <FormattedMessage id={I18nEnum.SaveAsSnippet} />
            </>
          }
          disabled={!snippetValue}
          htmlType="button"
        />
      )}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px 20px;
  margin: 8px 0;

  @media ${props => props.theme.mediaQueries.small} {
    flex-direction: column;
    align-items: flex-end;
  }
`;

const SaveIcon = styled.img`
  filter: ${props => props.theme.colorFilters.blue};
`;

export default AddSnippet;
