import { constants } from '.';
import { SnippetState } from './types';

const initialState: SnippetState = {
  snippets: [],
  snippetsToInsert: {},
};

const SnippetReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_SNIPPETS: {
      return { ...state, snippets: action.snippets };
    }
    case constants.SET_SNIPPET: {
      return {
        ...state,
        snippetsToInsert: { ...state.snippetsToInsert, [action.snippetType]: action.snippetValue },
      };
    }
    case constants.CLEAR_SNIPPETS_TO_INSERT: {
      return {
        ...state,
        snippetsToInsert: {},
      };
    }
    default:
      return state;
  }
};

export default SnippetReducer;
