import { memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { OptionType } from './types';
import { getLabel } from './utils';

const OptionTextComponent = ({
  option,
  toggleSetShowTooltipForValue,
}: {
  option: OptionType;
  toggleSetShowTooltipForValue: (value: string | number | null) => void;
}) => {
  const onOptionTextMouseOver = useCallback(
    e => {
      if (e.target.scrollWidth > e.target.clientWidth) {
        toggleSetShowTooltipForValue(option.value);
      }
    },
    [toggleSetShowTooltipForValue, option.value],
  );

  const onOptionTextMouseLeave = useCallback(() => {
    toggleSetShowTooltipForValue(null);
  }, [toggleSetShowTooltipForValue]);

  const label = useMemo(() => getLabel(option.label), [option]);

  return (
    <OptionText onMouseOver={onOptionTextMouseOver} onMouseLeave={onOptionTextMouseLeave}>
      {label}
    </OptionText>
  );
};

const OptionText = styled.span`
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100%);
  overflow: hidden;
`;

export default memo(OptionTextComponent);
