import * as constants from './constants';
import { CloseModalOptions } from './types';

export const openModal = <T>(modalType: string, params?: T) => ({
  type: constants.OPEN,
  modalType,
  params,
});

export const closeModal = ({ clearStack }: CloseModalOptions = { clearStack: false }) => ({
  type: constants.CLOSE,
  clearStack,
});

export const setModalParams = <T>(params?: T) => ({
  type: constants.SET_PARAMS,
  params,
});
