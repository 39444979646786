import { transformToFrontendSettings } from 'modules/widget/utils/toolScriptTransformSettings';
import { BrandingConfig, WidgetSettings, Company } from 'modules/widget/types/toolScript';
import { DEFAULT_BRANDING_CONFIG } from 'modules/widget/constants/toolScript';
import { getSubscriptionType } from 'modules/subscription/utils/toolScript';
import { DEFAULT_BRANDING_MAP } from 'modules/subscription/constants/toolScript';

export const getWidgetConfig = async (
  _wildcard: string,
): Promise<{
  brandingConfig: BrandingConfig;
  company: Company;
}> => {
  const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL;
  const configResponse = await fetch(`${API_URL}widget/${_wildcard}/settings`);

  const { data: dbBrandingConfig }: { data: WidgetSettings & { company: Company } } =
    await configResponse.json();

  if (!(dbBrandingConfig && Object.values(dbBrandingConfig).length !== 0)) {
    if (configResponse.statusText === 'Not Found') {
      throw new Error(`RoofQuote PRO™ widget not found. Check the widget ID.`);
    }

    if (configResponse.statusText === 'Forbidden') {
      throw new Error(`RoofQuote PRO™ widget is not allowed. Check the allowed domains.`);
    }

    throw new Error(configResponse.statusText);
  }

  const subscriptionType = getSubscriptionType(dbBrandingConfig.company.subscriptions);

  const brandingConfig = transformToFrontendSettings(
    DEFAULT_BRANDING_MAP[subscriptionType] || { ...DEFAULT_BRANDING_CONFIG },
    dbBrandingConfig,
    subscriptionType,
  );
  return { brandingConfig, company: dbBrandingConfig.company };
};
