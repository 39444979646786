import * as quickQuoteConstants from 'modules/quickQuote/constants';
import * as searchConstants from 'modules/search/constants';
import * as constants from './constants';

import { MapboxState } from './types';
import { I18nEnum } from 'types';
import { getDrawPolygonHelpText } from 'modules/googleMaps/utils';

export const initialMapboxState: MapboxState = {
  mapboxMap: null,
  mapboxIsReady: false,
  isEditing: false,
  isAddingPolygons: false,
  isPolygonSelected: false,
  polygonClickCount: 0,
  helpText: '',
};

export const MapboxReducer = (state = initialMapboxState, action) => {
  switch (action.type) {
    case constants.SET_MAPBOX_ALL: {
      return {
        ...state,
        ...action,
      };
    }

    case constants.SET_MAPBOX_IS_EDITING: {
      const helpText = I18nEnum.MapShapeSelectedHelpText;

      return {
        ...state,
        isEditing: action.isEditing,
        isAddingPolygons: false,
        helpText,
      };
    }
    case constants.SET_MAPBOX_IS_ADDING_POLYGONS: {
      return {
        ...state,
        isAddingPolygons: action.isAddingPolygons,
      };
    }
    case constants.SET_MAPBOX_IS_POLYGON_SELECTED: {
      return {
        ...state,
        isPolygonSelected: action.isPolygonSelected,
      };
    }
    case constants.SET_MAPBOX_VERTEX_HELP_TEXT: {
      const helpText = I18nEnum.MapVertexSelectedHelpText;

      return {
        ...state,
        helpText,
      };
    }
    case constants.SET_MAPBOX_POLYGON_HELP_TEXT: {
      const helpText = I18nEnum.MapShapeSelectedHelpText;

      return {
        ...state,
        helpText,
      };
    }
    case constants.SET_MAPBOX_NO_SELECTION_HELP_TEXT: {
      const helpText = state.isAddingPolygons
        ? getDrawPolygonHelpText(0)
        : I18nEnum.MapNoSelectionHelpText;

      return {
        ...state,
        helpText,
      };
    }
    case constants.SET_MAPBOX_DRAW_POLYGON_CLICK_COUNT: {
      const { polygonClickCount } = action;
      const count =
        polygonClickCount !== undefined ? polygonClickCount : state.polygonClickCount + 1;
      const helpText = state.isAddingPolygons ? getDrawPolygonHelpText(count) : state.helpText;

      return {
        ...state,
        polygonClickCount: count,
        helpText,
      };
    }

    case constants.CLEAR_MAPBOX:
    case quickQuoteConstants.CLEAR:
    case searchConstants.CLEAR: {
      return { ...initialMapboxState };
    }
    default:
      return state;
  }
};
