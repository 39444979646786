const name = 'market/';

export const SET_MARKETS = `${name}SET_MARKETS`;
export const SET_LOGISTICS_MARKETS = `${name}SET_LOGISTICS_MARKETS`;
export const SET_SELECTED_MARKET = `${name}SET_SELECTED_MARKET`;
export const SET_MARKETS_LIST = `${name}SET_MARKETS_LIST`;
export const SET_STATES = `${name}SET_STATES`;
export const SET_USERS_STATES = `${name}SET_USERS_STATES`;
export const SET_COUNTRY_STATES_FEATURE_COLLECTION = `${name}SET_COUNTRY_STATES_FEATURE_COLLECTION`;
export const CLEAR_MARKETS = `${name}CLEAR_MARKETS`;

export const GET_USER_MARKETS_KEY = `${name}GET_USER_MARKETS_KEY`;

export const MIN_RADIUS_IN_MILES = 15;

export const ALL_MARKETS_SLUG = 'all_markets';

export const MARKET_SOURCE_ID = 'market-source';
export const MARKET_FILL_LAYER_ID = 'market-fill-layer-id';
export const MARKET_FILL_LAYER_DISABLED_ID = 'market-fill-layer-disabled-id';
export const MARKET_CENTERPOINT_LAYER_ID = 'market-centerpoint-id';

export const STRIPED_POINT_SOURCE_ID = 'striped-radius-point';
export const ORIGINAL_RADIUS_PATTERN_SOURCE_ID = 'original-radius-pattern';
export const MARKET_INTERSECTION_PATTERN = 'market-intersection-pattern';
export const MARKET_ORIGINAL_RADIUS_PATTERN = 'market-original-radius-pattern';
export const MARKET_ORIGINAL_RADIUS_DISABLED_PATTERN = `${MARKET_ORIGINAL_RADIUS_PATTERN}-disabled`;

export const SERVICE_ZONE_MAP_MIN_ZOOM = 3;
export const SERVICE_ZONE_MAP_MAX_ZOOM = 15;

export const NATIONAL_LICENSE_ID = 'national_license';

export const UPDATE_MARKETS_TABS = {
  markets: 'markets',
  states: 'states',
} as const;
export type UpdateMarketsTabsType = typeof UPDATE_MARKETS_TABS[keyof typeof UPDATE_MARKETS_TABS];

export const MARKET_SETTINGS_BLOCKER_KEY = 'marketSettingsBlocker';
