import { I18nEnum } from 'types';
import { SnippetTypeEnum } from './types';

const name = 'company/';

export const SET_SNIPPETS = `${name}SET_SNIPPETS`;
export const SET_SNIPPET = `${name}SET_SNIPPET`;
export const CLEAR_SNIPPETS_TO_INSERT = `${name}CLEAR_SNIPPETS_TO_INSERT`;

export const snippets = [
  // { label: I18nEnum.Warranties, value: SnippetTypeEnum.warranty },
  // { label: I18nEnum.TopFeatures, value: SnippetTypeEnum.topFeatures },
  { label: I18nEnum.WhatIncluded, value: SnippetTypeEnum.whatsIncluded },
  { label: I18nEnum.AdditionalCosts, value: SnippetTypeEnum.additionalCosts },
  { label: I18nEnum.Prices, value: SnippetTypeEnum.price },
];
