import { State } from 'types';

export const selectCompanyState = (state: State) => state.company;
export const selectCompany = (state: State) => selectCompanyState(state).company;

// TODO: check if company exists in redux on widget and rep quotes
export const selectIsGoogleMapsActive = (state: State) =>
  selectCompanyState(state).company?.isGoogleMapsActive || false;

export const selectPrimaryUser = (state: State) => selectCompanyState(state).primaryUser;
export const selectCompanyUsers = (state: State) => selectCompanyState(state).users;
export const selectCompanyUsersList = (state: State) =>
  selectCompanyUsers(state).filter(user => user.id !== state.user.id);
export const selectCompanyUsersTotal = (state: State) => selectCompanyState(state).usersTotal;
export const selectCompanyUsersQuery = (state: State) => selectCompanyState(state).usersQuery;
export const selectCompanyQuoteSettings = (state: State) =>
  selectCompanyState(state)?.quoteSettings;
