import { css } from 'styled-components';

export default css<{ readonly?: boolean }>`
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  color: ${({ readonly }) => (readonly ? 'rgba(0, 0, 0, 0.6)' : '#191919')};
  font-size: 16px;
  line-height: 19px;
  height: 55px;
  caret-color: ${props => props.theme.colors.orange};

  &::placeholder {
    color: rgba(0, 0, 0, 0.4) !important;
    opacity: 1;
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.6);
    box-shadow: none;
  }
  &:focus,
  &.ant-input-number-focused,
  &:focus-within {
    border: 1px solid ${props => props.theme.colors.orange};
    box-shadow: none;
  }

  &:disabled,
  &[disabled]:hover {
    color: rgba(0, 0, 0, 0.4);
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
  }

  .ant-input {
    line-height: 19px;
  }

  ${({ readonly }) => readonly && 'pointer-events: none;'}
`;
