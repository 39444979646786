import { useSelector } from 'react-redux';

import { selectors as userSelectors } from 'modules/auth';
import { isAdmin } from 'modules/auth/utils';
import { getAdminId } from 'modules/auth/storage';
import { queryClient, useAppQuery } from 'modules/reactQuery';

import { Subscription, SubscriptionConfig } from './types';
import { SUBSCRIPTIONS_KEY } from './constants';
import { getSubscriptionsAPI } from './services';
import { getSubscriptionConfig } from './utils';

export const invalidateSubscription = () => {
  return queryClient.invalidateQueries({ queryKey: [SUBSCRIPTIONS_KEY] });
};

const useGetSubscriptions = <T>(selectFn: (data: Subscription[]) => T) => {
  const user = useSelector(userSelectors.selectUser);
  const ignoreAdmin = isAdmin(user) && !getAdminId();

  return useAppQuery<Subscription[], T>(
    [SUBSCRIPTIONS_KEY, user.id],
    () => {
      return getSubscriptionsAPI(user.id);
    },
    {
      enabled: !!user.id && !ignoreAdmin,
      select: selectFn,
    },
  );
};

export const useGetSubscription = () =>
  useGetSubscriptions<Subscription>(subscriptions => subscriptions[0]);

export const useGetSubscriptionConfig = () =>
  useGetSubscriptions<SubscriptionConfig>(getSubscriptionConfig);
