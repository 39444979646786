import { BrandingConfig, WidgetSettingsConfigState } from 'modules/widget/types';
import { QuickQuoteConfigState } from 'modules/quickQuoteConfig/types';
import { ProductSettingsConfig } from 'modules/product/types';
import { BillingSettingsConfig } from 'modules/billing/types';
import { OrdersSettingsConfig } from 'modules/orders/types';
import { FinancingSettingsConfig } from 'modules/financing/types';
import { ProfileSettingsConfig } from '../company';

export enum SubscriptionTypeGeneral {
  RoofQuotePro = 'RQP_PRO',
  OwensCorningPro = 'OC_PRO',
}

export enum SubscriptionCountry {
  USA = 'UnitedStates',
  Canada = 'Canada',
}

export const SubscriptionTypes = {
  RoofQuoteProUSA: `${SubscriptionTypeGeneral.RoofQuotePro}_${SubscriptionCountry.USA}`,
  OwensCorningProUSA: `${SubscriptionTypeGeneral.OwensCorningPro}_${SubscriptionCountry.USA}`,
  RoofQuoteProCanada: `${SubscriptionTypeGeneral.RoofQuotePro}_${SubscriptionCountry.Canada}`,
  OwensCorningProCanada: `${SubscriptionTypeGeneral.OwensCorningPro}_${SubscriptionCountry.Canada}`,
} as const;

export type SubscriptionType = typeof SubscriptionTypes[keyof typeof SubscriptionTypes];

export enum SubscriptionPlan {
  MonthlySubscription = 'monthly',
  AnnualRollup = 'annual_rollup',
  AnnualDiscount = 'annual_discount',
}

export enum SubscriptionRequestType {
  Pause = 'pause',
  Activation = 'activate',
}

export interface Subscription {
  id: number;
  endDate: string;
  startDate: string;
  createdAt: string;
  updatedAt: string;
  activationRequested: boolean;
  cancelationRequested: boolean;
  status?: SubscriptionStatusEnum;
  type: SubscriptionTypeGeneral;
  plan: SubscriptionPlan | null;
  country: SubscriptionCountry;
}

export type SubscriptionUpdate = Pick<
  Subscription,
  'endDate' | 'startDate' | 'type' | 'plan' | 'country'
>;

export type SubscriptionMarketsUpdate = {
  markets: string[];
  states: string[];
  isNationalLicenseActive: boolean;
};

export interface AllSubscriptionUpdates {
  subscription: SubscriptionUpdate;
  markets: SubscriptionMarketsUpdate;
}

export enum SubscriptionStatusEnum {
  active = 'active',
  inactive = 'inactive',
  upcoming = 'upcoming',
  paused = 'paused',
}

export interface SubscriptionState {
  config: SubscriptionConfig;
}

export interface SubscriptionConfig {
  defaultBranding: BrandingConfig;
  defaultQuickQuoteConfigState: QuickQuoteConfigState;
  defaultRepQuoteConfigState: QuickQuoteConfigState;
  widgetSettings: WidgetSettingsConfigState;
  productSettings: ProductSettingsConfig;
  billingSettings: BillingSettingsConfig;
  ordersSettings: OrdersSettingsConfig;
  financingSettings: FinancingSettingsConfig;
  isDefault: boolean;
  subscriptionType?: SubscriptionType;
  profileSettings: ProfileSettingsConfig;
}

export type SubscriptionConfigMap = Record<SubscriptionType, SubscriptionConfig>;
