import { useCallback, useRef, useState, useMemo } from 'react';
import { Select as SelectBase } from 'antd';
import { FormattedMessage } from 'react-intl';
import { BaseSelectRef } from 'rc-select';

import { I18nEnum } from 'types';
import Label from '../Label';
import { TreeSelectProps } from './types';
import { getLabel } from './utils';
import { convertTreeDataToOptions, getNodeDetailsMap } from './tree-data.helper';
import TreeItemOffset from './TreeItemOffset';
import OptionTextComponent from './OptionTextComponent';
import {
  useDropdownRenderer,
  useDropdownVisibility,
  useExpandedTreeOptions,
  useMultipleTreeSelectInternalValue,
} from './hooks';
import { Checkbox, Tooltip, SelectContainer } from './SelectContainer';

const { Option } = SelectBase;

export const _TreeSelectComponent = ({
  treeData,
  optionContentRenderer,
  dropdownWidth,
  value,
  optionStyle = {},
  bordered,
  showSearch,
  mode,
  error,
  onChange,
  onDropdownVisibleChange,
  onFocus,
  onBlur,
  onSearch,
  allLabel,
  allTag,
  showMultipleSelectTags,
  open,
  treeDefaultExpandAll,
  ...props
}: TreeSelectProps) => {
  const selectRef = useRef<BaseSelectRef>(null);
  const { _onDropdownVisibleChange, opened, setOpened } = useDropdownVisibility({
    onDropdownVisibleChange,
    onFocus,
    open,
    selectRef,
  });
  const [showTooltipForValue, setShowTooltipForValue] = useState<string | number | null>(null);

  const _optionContentRenderer = useMemo(
    () => optionContentRenderer || (optionContent => optionContent),
    [optionContentRenderer],
  );

  const { options, nodeDetailsMap } = useMemo(() => {
    return {
      options: convertTreeDataToOptions(treeData),
      nodeDetailsMap: getNodeDetailsMap(treeData),
    };
  }, [treeData]);
  const { internalMultipleSelectValue, onSelect, onDeselect } = useMultipleTreeSelectInternalValue({
    value,
    mode,
    opened,
    nodeDetailsMap,
  });
  const { expandedOptions, expandedOptionsValues, handleToggleExpand } = useExpandedTreeOptions({
    options,
    nodeDetailsMap,
    treeDefaultExpandAll,
  });

  const tagRender = useCallback(
    ({ value: tagValue }) => {
      if (!value?.length || tagValue !== value[0] || opened) {
        return <></>;
      }
      const leafValues = value.filter(v => nodeDetailsMap[v].leaf);
      return (
        <FormattedMessage
          id={
            value.length !== options.length
              ? I18nEnum.NItemsSelected
              : allTag
              ? allTag
              : I18nEnum.All
          }
          values={{ n: leafValues.length }}
        />
      );
    },
    [value, opened, options.length, allTag, nodeDetailsMap],
  );

  const dropdownRender = useDropdownRenderer({
    mode,
    onChange,
    value,
    internalMultipleSelectValue,
    setOpened,
  });

  return (
    <SelectContainer
      error={error}
      open={opened}
      showMultipleSelectTags={showMultipleSelectTags}
      mode={mode}
      value={value}
      internalMultipleSelectValue={internalMultipleSelectValue}
      options={expandedOptions}
      bordered={bordered}
      onSelect={onSelect}
      onDeselect={onDeselect}
      onChange={onChange}
      onDropdownVisibleChange={_onDropdownVisibleChange}
      onBlur={onBlur}
      onSearch={onSearch}
      dropdownMatchSelectWidth={dropdownWidth}
      innerRef={selectRef}
      tagRender={tagRender}
      dropdownRender={dropdownRender}
      showSearch={showSearch && opened}
      {...props}>
      {expandedOptions.map(option => {
        const label = getLabel(option.label);
        return (
          <Option
            key={option.value}
            value={option.value}
            label={label}
            disabled={option.disabled}
            style={optionStyle}>
            <>
              <TreeItemOffset
                expanded={expandedOptionsValues[option.value]}
                nodeDetails={nodeDetailsMap[option.value]}
                onToggleExpand={handleToggleExpand}
                value={option.value}
              />
              <Checkbox checked className="checked" />
              <Checkbox className="unchecked" />
              {_optionContentRenderer(
                <>
                  {showTooltipForValue === option.value && <Tooltip treeSelect>{label}</Tooltip>}
                  <OptionTextComponent
                    option={option}
                    toggleSetShowTooltipForValue={setShowTooltipForValue}
                  />
                  {option.children}
                </>,
                option,
              )}
            </>
          </Option>
        );
      })}
    </SelectContainer>
  );
};

export const TreeSelectComponent = (props: TreeSelectProps) => {
  return props.placeholder ? (
    <Label {...props}>
      {(_onFocus, _onBlur) => (
        <_TreeSelectComponent {...props} onFocus={_onFocus} onBlur={_onBlur} />
      )}
    </Label>
  ) : (
    <_TreeSelectComponent {...props} />
  );
};
export default TreeSelectComponent;
