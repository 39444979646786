import { useSelector } from 'react-redux';

import { selectors as userSelectors } from 'modules/auth';
import { queryClient, useAppQuery } from 'modules/reactQuery';
import { constants, services, types } from 'modules/quoteSettings';
import {
  applyDefaultsToQuoteSettings,
  mapMarketQuoteSettingsFromDto,
  mapQuoteSettingsFromDto,
} from './serializers';

export const invalidateQuoteSettings = () => {
  return queryClient.invalidateQueries({
    queryKey: [constants.QUOTE_SETTINGS_KEY],
    exact: true,
  });
};

export const invalidateMarketQuoteSettings = () => {
  return queryClient.invalidateQueries({
    queryKey: [constants.MARKET_QUOTE_SETTINGS_KEY],
    exact: true,
  });
};

export const useQuoteSettings = <TData>(select: (data: types.QuoteSettingsModel) => TData) => {
  const user = useSelector(userSelectors.selectUser);
  return useAppQuery<types.QuoteSettingsModel, TData>(
    [constants.QUOTE_SETTINGS_KEY],
    () => {
      return services.getQuoteSettingsApi(user.id).then(dto => ({
        data: applyDefaultsToQuoteSettings(dto.data),
      }));
    },
    {
      enabled: !!user.id,
      // Remember to check all pages using the query when changing staleTime.
      // If data is stale, the lib fetch data on component mount, window focus (disabled right now), etc.
      staleTime: Infinity,
      select,
    },
  );
};

export const useQuoteSettingsRealData = () => {
  return useQuoteSettings(data => data);
};

export const useQuoteSettingsFormValues = () => {
  return useQuoteSettings(data => mapQuoteSettingsFromDto(data));
};

export const useMarketQuoteSettings = () => {
  const user = useSelector(userSelectors.selectUser);
  return useAppQuery<types.MarketQuoteSettingsModel[]>(
    [constants.MARKET_QUOTE_SETTINGS_KEY],
    () => {
      return services.getMarketQuoteSettings(user.id).then(dto => ({
        data: dto.data?.length ? dto.data.map(item => mapMarketQuoteSettingsFromDto(item)) : [],
      }));
    },
    {
      enabled: !!user.id,
      // Remember to check all pages using the query when changing staleTime.
      // If data is stale, the lib fetch data on component mount, window focus (disabled right now), etc.
      staleTime: Infinity,
    },
  );
};
