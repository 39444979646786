import cloneDeep from 'lodash/cloneDeep';

import { PriceConfig } from './priceConfig';

import { StructureSlope, StructureSlopeEnum } from 'modules/quickQuote/types';
import { PriceType, SteepSlopeByStructures } from 'modules/global/types';

export class SteepSlope {
  constructor(priceConfig: PriceConfig) {
    this.priceConfig = priceConfig;
  }

  private readonly priceConfig: PriceConfig;

  private _steepSlopeByStructures?: SteepSlopeByStructures;

  get steepSlopeChargeValue(): number {
    const { product, companyQuoteSettings } = this.priceConfig;

    if (!companyQuoteSettings || !product) {
      return 0;
    }

    return product.useInstantQuoteSettings
      ? +companyQuoteSettings.steepSlopeChargeValue
      : product.steepSlopeChargeValue ?? +companyQuoteSettings.steepSlopeChargeValue;
  }

  get steepSlopeByStructures(): SteepSlopeByStructures | undefined {
    return cloneDeep(this._steepSlopeByStructures);
  }

  computeSteepSlopeByStructures(): void {
    const { includedStructures } = this.priceConfig;

    this._steepSlopeByStructures = includedStructures.reduce((acc, structure) => {
      if (!structure.geoJsonPolygon.id) {
        return acc;
      }

      acc[structure.geoJsonPolygon.id] = {
        steepSlopeSqFt: this.getSteepSlopeSqFtPrice(structure.slope),
        steepSlopeFixed: this.getSteepSlopeFixedPrice(structure.slope),
      };
      return acc;
    }, {});
  }

  shouldAddSteepSlopeCharge({
    priceType,
    slope,
  }: {
    priceType: PriceType;
    slope: StructureSlope;
  }): boolean {
    const { product, companyQuoteSettings } = this.priceConfig;

    if (
      !companyQuoteSettings ||
      !product ||
      slope !== StructureSlopeEnum.steep ||
      !companyQuoteSettings.steepSlopeChargeEnabled
    ) {
      return false;
    }

    if (product.useInstantQuoteSettings) {
      return product.customProduct
        ? false
        : companyQuoteSettings.steepSlopeChargeType === priceType;
    }

    return product.steepSlopeChargeEnabled && product.steepSlopeChargeType === priceType;
  }

  getSteepSlopeSqFtPrice(slope: StructureSlope): number {
    // return additional price for SqFt if user use this setting and slope is steep
    const shouldAddSteepSlopeSqFtCharge = this.shouldAddSteepSlopeCharge({
      priceType: PriceType.PerSquare,
      slope,
    });

    if (shouldAddSteepSlopeSqFtCharge) {
      return this.steepSlopeChargeValue;
    }

    return 0;
  }

  getSteepSlopeFixedPrice(slope: StructureSlope): number {
    // return steep slope value if user use this setting and slope if steep
    const shouldAddSteepSlopeFixedCharge = this.shouldAddSteepSlopeCharge({
      priceType: PriceType.Fixed,
      slope,
    });

    if (shouldAddSteepSlopeFixedCharge) {
      // value stores price per square, we need to multiply to 100 to get fixed price
      return this.steepSlopeChargeValue * 100;
    }

    return 0;
  }
}
