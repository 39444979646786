import { BillingSettingsConfig, PaymentMethodObject } from './types';

const name = 'billing/';

export const MEASUREMENT_CREDITS_KEY = `${name}MEASUREMENT_CREDITS`;
export const ACCOUNT_KEY = `${name}ACCOUNT`;
export const BILLING_INFO_KEY = `${name}BILLING_INFO`;
export const MEASUREMENT_CREDITS_OPTIONS_KEY = `${name}MEASUREMENT_CREDITS_OPTIONS`;
export const COUPON_KEY = `${name}COUPON`;
export const RENEW_OPTION_KEY = `${name}RENEW_OPTION`;
export const PENDING_AMOUNT_KEY = `${name}PENDING_AMOUNT`;
export const IS_LAST_ACCOUNT_TRANSACTION_SUCCESSFUL_KEY = `${name}IS_LAST_ACCOUNT_TRANSACTION_SUCCESSFUL`;

export const countries = [
  { value: 'US', label: 'USA' },
  { value: 'CA', label: 'Canada' },
];

const commonFields = [
  'address.street1',
  'address.city',
  'address.region',
  'address.postal_code',
  'accepted',
];

const creditCardFields = ['number', 'month', 'year', 'cvv'];
const achFields = [
  'name_on_account',
  'routing_number',
  'account_number',
  'verifyAccountNumber',
  'account_type',
];

export const getFieldList = (paymentMethodObject: string) => [
  ...commonFields,
  ...(paymentMethodObject === PaymentMethodObject.creditCard ? creditCardFields : achFields),
];

export const INITIAL_SELECTED_CREDITS_INDEX = 1;

export const DEFAULT_BILLING_SETTINGS_CONFIG: BillingSettingsConfig = {
  showLicense: true,
};

export const CANADA_BILLING_SETTINGS_CONFIG: BillingSettingsConfig = {
  showLicense: false,
};
