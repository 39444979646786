import styled from 'styled-components';

import { Icon as IconBase, Props } from './Icon';

const ArrowDropdownIcon = (props: Props) => (
  <Icon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 10 6">
      <defs />
      <defs>
        <path id="path-arrow-dropdown" d="M0 .5l5 5 5-5z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id={`mask-arrow-dropdown-${props.id}`} fill="#fff">
          <use xlinkHref="#path-arrow-dropdown" />
        </mask>
        <g
          id={`arrow-dropdown-${props.id}`}
          mask={`url(#mask-arrow-dropdown-${props.id})`}
          fill="#959595">
          <path d="M-5-7h20v20H-5z" />
        </g>
      </g>
    </svg>
  </Icon>
);

export const ArrowDropdownIconImg = (props: { className?: string }) => {
  return <Img src="/images/svg/arrow-dropdown-icon.svg" loading="lazy" {...props} />;
};

const Icon = styled(IconBase)`
  svg {
    height: 6px;
    width: 10px;
  }
`;

const Img = styled.img`
  height: 5px;
  width: 10px;
`;

export default ArrowDropdownIcon;
