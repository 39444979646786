import styled from 'styled-components';

import { Icon, Props } from './Icon';
import { colors } from 'global-constants';

export const ArrowIcon = ({ id = '', ...props }: Props) => (
  <Icon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 20 20">
      <defs />
      <defs>
        <path id={`arrow-${id}`} d="M6 .3l-6 6 1.4 1.4L6 3.1l4.6 4.6L12 6.3z" />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(4 6)">
        <mask id={`mask-arrow-${id}`} fill="#fff">
          <use xlinkHref={`#arrow-${id}`} />
        </mask>
        <g id="arrow" mask={`url(#mask-arrow-${id})`}>
          <path d="M0 0h12v8H0z" />
        </g>
      </g>
    </svg>
  </Icon>
);

const ExpandIcon = ({
  isActive,
  fill,
  id,
  ...props
}: {
  isActive?: boolean;
  fill?: string;
  id?: string;
  className?: string;
}) => (
  <ArrowWrapper
    {...props}
    className={`${!isActive ? 'animate' : ''} ${props.className || ''}`}
    fill={fill}>
    <ArrowIcon id={id} />
  </ArrowWrapper>
);

export const ArrowIconImg = ({
  isActive,
  id,
  ...props
}: {
  isActive?: boolean;
  id?: string;
  className?: string;
}) => (
  <ArrowWrapper
    {...props}
    className={`ant-collapse-arrow ${!isActive ? 'animate' : ''} ${props.className || ''}`}>
    <img src="/images/svg/arrow-icon.svg" id={id} />
  </ArrowWrapper>
);

const ArrowWrapper = styled.div<{ fill?: string }>`
  #arrow {
    fill: ${props => (props.fill ? props.fill : colors.black)};
  }
  opacity: 0.6;
  &:hover {
    opacity: 0.8;
  }

  img {
    width: 20px;
    height: 20px;
  }

  /* IE & Safari fix  */
  &.animate {
    svg,
    img {
      @-webkit-keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(180deg);
        }
      }
      @-ms-keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(180deg);
        }
      }
      @keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(180deg);
        }
      }

      animation-name: rotation;
      -webkit-animation-name: rotation;
      -ms-animation-name: rotation;
      animation-fill-mode: forwards;
      -webkit-animation-fill-mode: forwards;
      -ms-animation-name: rotation;
    }
  }
`;

export default ExpandIcon;
