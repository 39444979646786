import { ActionType } from 'types';
import * as constants from './constants';
import { AnalyticsReportsState } from './types';

const initialState: AnalyticsReportsState = {
  filter: {
    markets: [],
    statuses: [],
  },
  loaded: {
    averageQuotes: false,
    topProducts: false,
    pageConversion: false,
  },
};

const analyticsReportsReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case constants.SET_ANALYTICS_REPORTS_FILTER:
      return {
        ...state,
        filter: action.filter,
      };
    case constants.SET_ANALYTICS_LOADED:
      return {
        ...state,
        loaded: { ...state.loaded, ...action.loaded },
      };
    case constants.SET_ANALYTICS_STATISTICS_PART:
      return {
        ...state,
        statisticsPart: action.statisticsPart,
      };
    case constants.SET_ANALYTICS_ANALYTICS_PART:
      return {
        ...state,
        analyticsPart: action.analyticsPart,
      };
    case constants.SET_ANALYTICS_AVERAGE_QUOTES:
      return {
        ...state,
        averageQuotes: action.averageQuotes,
      };
    case constants.SET_ANALYTICS_TOP_PRODUCTS:
      return {
        ...state,
        topProducts: action.topProducts,
      };
    case constants.SET_ANALYTICS_PAGE_CONVERSION:
      return {
        ...state,
        pageConversion: action.pageConversion,
      };
    case constants.SET_ANALYTICS_FINANCE_ANALYTICS:
      return {
        ...state,
        financeAnalytics: action.financeAnalytics,
      };
    default:
      return state;
  }
};

export default analyticsReportsReducer;
