import styled, { css } from 'styled-components';
import { BaseSelectRef } from 'rc-select';
import { FormattedMessage } from 'react-intl';

import { colors, mediaQueries } from 'global-constants';
import useNavigation from 'modules/hooks/useNavigation';
import { ArrowDropdownIconImg } from 'components/Icons/ArrowDropdownIcon';
import CheckboxBase from '../Checkbox';
import { SelectProps } from './types';
import TagsContainer from './TagsContainer';
import { RefObject, useCallback, useRef } from 'react';
import { SelectProps as BaseSelectProps } from 'antd/lib/select';
import { Select as SelectBase } from 'antd';
import { useFilterOption } from './hooks';

type SelectContainerProps = SelectProps & {
  _bordered?: boolean;
  innerRef?: React.Ref<BaseSelectRef> | undefined;
  internalMultipleSelectValue: SelectProps['value'];
};

export const SelectContainer = ({
  experimentalSearch,
  showMultipleSelectTags,
  error,
  mode,
  value,
  internalMultipleSelectValue,
  dropdownClassName,
  options,
  bordered,
  onDeselect,
  onChange,
  containerRef: outerRef,
  className,
  ...props
}: React.PropsWithChildren<SelectContainerProps>) => {
  const [inputType] = useNavigation();
  const ref = useRef<HTMLDivElement>(null);
  const filterOption = useFilterOption(experimentalSearch);
  const onTagClose = useCallback(
    _value => {
      if (onChange && onDeselect) {
        const newValues = onDeselect(_value, {} as any);
        onChange(newValues);
      }
    },
    [onDeselect, onChange],
  );
  const currentSelectedOption = mode === 'multiple' ? internalMultipleSelectValue : value;
  const containerRef = (outerRef as RefObject<HTMLDivElement>) || ref;
  return (
    <>
      <Root
        ref={ref}
        bordered={bordered}
        className={className}
        withPlaceholder={!!props.placeholder}
        disabled={props.disabled}>
        <Select
          data-whatinput={inputType}
          bordered={false}
          error={!!error}
          value={
            currentSelectedOption !== undefined
              ? currentSelectedOption
              : !props.placeholder
              ? options[0]?.value
              : undefined
          }
          dropdownClassName={`${dropdownClassName} ${bordered ? 'select-bordered' : ''}`}
          onChange={mode !== 'multiple' ? onChange : undefined}
          onDeselect={onDeselect}
          showArrow={false}
          optionLabelProp="label"
          _bordered={bordered}
          mode={mode}
          filterOption={filterOption}
          getPopupContainer={containerRef ? () => containerRef.current as HTMLElement : undefined}
          {...props}
        />
        {props.suffixIcon ? (
          props.suffixIcon
        ) : (
          <ArrowDropdownIcon className="select-dropdown-icon" bordered={bordered} />
        )}
      </Root>
      {error && (
        <Error>
          <FormattedMessage id={error} />
        </Error>
      )}
      {mode === 'multiple' && showMultipleSelectTags && !props.disabled && (
        <TagsContainer values={value} options={options} onTagClose={onTagClose} />
      )}
    </>
  );
};

export const Root = styled.div<{
  bordered?: boolean;
  withPlaceholder?: boolean;
  disabled?: boolean;
}>`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  height: 55px;

  ${props =>
    props.bordered &&
    `
      width: 100%;
      .ant-select-dropdown {
        z-index: ${props.withPlaceholder ? 11 : 3};
      }
  `}

  .ant-select-open ~ img {
    transform: rotate(-180deg) translateY(50%);
  }

  .ant-select ~ img,
  .ant-select ~ span {
    opacity: 0.6;
  }

  ${props =>
    !props.bordered &&
    css`
      &:hover .ant-select ~ img,
      &:hover .ant-select ~ span {
        opacity: 0.8;
      }

      .ant-select-open ~ img,
      .ant-select-open ~ span,
      &:hover .ant-select-open ~ img,
      &:hover .ant-select-open ~ span {
        opacity: 0.4;
      }
      &:hover .ant-select {
        color: ${colors.darkBlue};
      }
      .ant-select-open,
      &:hover .ant-select-open {
        color: ${colors.lightBlue};
      }
    `}

  ${props => props.disabled && 'opacity: 0.4; pointer-events: none;'}
`;

export const Select = styled(
  ({
    _bordered,
    error,
    innerRef,
    ...rest
  }: BaseSelectProps & {
    _bordered?: boolean;
    error?: boolean;
    innerRef?: React.Ref<BaseSelectRef> | undefined;
  }) => <SelectBase {...rest} ref={innerRef} />,
)`
  width: 100%;
  height: 100%;

  && .ant-select-selection-item {
    display: flex;
    align-items: center;
    color: inherit;
  }
  .ant-select-selection-search-input {
    caret-color: ${props => props.theme.colors.orange};
  }

  &&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  &&:not(.ant-select-customize-input) .ant-select-selector,
  &&.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    input.ant-select-selection-search-input {
    height: 100%;
  }

  &.ant-select-multiple .ant-select-selection-placeholder {
    left: 17px;
  }
  &.ant-select-multiple .ant-select-selection-search {
    margin-inline-start: 0;
    -webkit-margin-start: 0;
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: inherit;
    cursor: inherit;
  }

  ${props =>
    props._bordered
      ? css`
          font-size: 16px;
          letter-spacing: 0.33px;
          line-height: 19px;
          border: 1px solid ${props.error ? props.theme.colors.error : `rgba(0, 0, 0, 0.4)`};
          border-radius: 6px;
          &:hover {
            border: 1px solid ${props.error ? props.theme.colors.error : `rgba(0, 0, 0, 0.6)`};
          }
          &.ant-select-open {
            border-color: ${props.error ? props.theme.colors.error : props.theme.colors.orange};
          }

          &&:not(.ant-select-customize-input) .ant-select-selector {
            width: 100%;
            padding: 0 25px 0 17px;
            border-radius: 6px;
            align-items: center;

            @media ${mediaQueries.small} {
              padding: 0 25px 0 17px;
            }
          }

          &.ant-select-status-error {
            border: 1px solid ${props => props.theme.colors.error};
          }

          &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
            .ant-select-selector {
            border-color: transparent !important;
          }

          &.ant-select-single .ant-select-selector .ant-select-selection-search {
            top: 50%;
            left: 18px;
            right: 37px;
            height: 22px;
            transform: translateY(-50%);
          }

          &.ant-select-show-search.ant-select-focused .ant-select-selection-item {
            color: rgba(0, 0, 0, 0.4);
          }

          span.ant-select-selection-placeholder {
            color: rgba(0, 0, 0, 0.4);
            font-size: 16px;
            letter-spacing: normal;
            line-height: 27px !important;
          }

          .ant-select-selector .ant-select-selection-item {
            line-height: 27px;
          }

          /*multiple select styles*/
          &.ant-select-multiple .ant-select-selection-item {
            border: 0;
            background: transparent;
          }

          .ant-select-selection-item-remove {
            display: none;
          }
        `
      : css`
          color: ${colors.blue};
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 19px;
          text-align: right;

          &:not(.ant-select-customize-input) .ant-select-selector,
          &:not(.ant-select-customize-input)
            .ant-select-selector
            .ant-select-selection-search-input {
            height: 19px;
          }

          & .ant-select-selector .ant-select-selection-item {
            line-height: 16px;
          }

          @media ${mediaQueries.small} {
            font-size: 14px;
            line-height: 17px;

            &:not(.ant-select-customize-input) .ant-select-selector,
            &:not(.ant-select-customize-input)
              .ant-select-selector
              .ant-select-selection-search-input {
              height: 17px;
            }

            & .ant-select-selector .ant-select-selection-item {
              line-height: 14px;
            }
          }
        `}
`;

export const ArrowDropdownIcon = styled(ArrowDropdownIconImg)<{ bordered?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  right: ${props => (props.bordered ? '13px' : '0')};
`;

export const Error = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${props => props.theme.colors.error};
`;

export const Checkbox = styled(CheckboxBase)`
  &&& {
    margin-right: 12px;
    pointer-events: none;
  }
`;

export const Tooltip = styled.div<{ treeSelect?: boolean }>`
  position: absolute;
  bottom: 0;
  ${props => (props.treeSelect ? 'right: 5px' : 'left: 5px')};
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  background: ${props => props.theme.colors.white};
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
    0 5px 5px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 5px 10px;
  max-width: calc(100% - 10px);
  white-space: pre-wrap;
  word-break: break-word;
  pointer-events: none;
  z-index: 1;
`;
