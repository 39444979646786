import { Company } from 'modules/company';
import { Line } from 'modules/product/types';
import { DeliveryTimeType, MeasurementsReportType } from 'modules/dashboard';
import { QuoteFeature } from 'modules/quoteSettings/types';
import { I18nEnum } from 'types';

export interface WidgetSettingsState {
  loaded: boolean;
  demo?: boolean;
  branding: BrandingConfig;
  rawBranding?: WidgetSettings;
  products?: Line[];
  companyId?: number;
  wildcard?: string;
  domains: string[];
  iframePreview?: boolean;
  externalUrl?: string;
  userInfo?: Record<string, string | number>;
}

export type ToolCalloutClosingStrategyLiteral = 'full' | 'text-only';
export type ToolCalloutParentLiteral = 'wrapper' | 'button';
export enum ThirdPartyLegalTextId {
  OC,
}

export interface BrandingConfig {
  titles: TitlesConfig;
  mainText: MainTextConfig;
  secondaryText: FontConfig;
  icons: FillColor;
  roof: FillAndStroke;
  tooltipAndMapControls: FillAndStroke & CornerRadius & FontColor;
  illustration: {
    activeColor: string;
    disabledColor: string;
  };
  background: BackgroundConfig;
  mainButton: MainButtonConfig;
  secondaryButton: ButtonConfig;
  checkbox: CheckboxConfig;
  radioButton: FillAndStroke;
  roofQuoteProButton: FillAndStroke &
    FontConfig &
    FontSize &
    FontWeight &
    AligmentConfig & {
      text: string;
      textClass?: string;
      customButtonClasses: string[];
    };
  callout: FillAndStroke & {
    titleText: string;
    titleFont: string;
    titleFontColor: string;
    descriptionText: string;
    descriptionTextFont: string;
    descriptionTextFontColor: string;
    showOnTabletsAndDesktops: boolean;
    showOnPhones: boolean;
    customCalloutClasses: string[];
    img: CalloutImg | null;
    sideBar: CalloutSideBar | null;
    closingStrategy: ToolCalloutClosingStrategyLiteral;
    parent: ToolCalloutParentLiteral;
    thirdPartyLegalText: {
      enabled: boolean;
      id?: ThirdPartyLegalTextId;
    };
  };
  otherContacts: ContactsForm;
  rqpLeads: ContactsForm;
  thankYou: ThankYouConfig;
  measurementsOrder: MeasurementsOrderConfig;
  mapSettings: MapSettings;
  productDetails: ProductDetailsConfig;
  widgetUnavailable: WidgetUnavailableConfig;
  howInstantLoanWorks: HowInstantLoanWorksConfig;
  howToReplyYourLoan: HowToReplyYourLoanConfig;
  invitationSent: HowInvitationSentConfig;
  overrides: OverridesBrandingConfig;
  caption: FontConfig;
  warningModal: WarningModalConfig;
}

export interface WarningModalConfig {
  imgName: string;
}

export interface FillColor {
  fillColor: string;
}

interface StrokeColor {
  strokeColor: string;
}

interface FillAndStroke extends FillColor, StrokeColor {}

interface CornerRadius {
  cornerRadius: number;
}

interface FontWeight {
  fontWeight: number;
}

export interface FontSize {
  fontSize: number;
}

export interface ButtonConfig
  extends FillAndStroke,
    FontConfig,
    CornerRadius,
    FontWeight,
    FontSize {}

export interface CheckboxConfig extends FillAndStroke, CornerRadius {}

export type BackgroundConfig = FillColor;

export interface MainButtonConfig extends ButtonConfig {
  seeProductDetailsButtonText: string;
  customFinancingButtonText: string;
}

interface FontColor {
  fontColor: string;
}

export interface FontConfig extends FontColor {
  font: string;
}

export interface TitlesConfig extends FontConfig {
  mainTitleText: string;
}

export interface MainTextConfig extends FontConfig {
  exampleText: string;
}

export interface InputConfig extends FontColor, FontConfig {
  borderColor: string;
  caretColor: string;
}

export type HorisontalAligment = 'left' | 'right';
export type VerticalAlignment = 'above' | 'below';

export interface AligmentConfig {
  horisontalPosition: HorisontalAligment;
}

export enum ThankYouMediaTypeEnum {
  video = 'video',
  image = 'image',
}

export enum ThankYouMediaProcessingStatusEnum {
  pending = 'pending',
  success = 'success',
  failed = 'failed',
}

export interface ContactsForm {
  legalTextEnabled: boolean;
  legalText: string;
  legalTextFontSize: number;
  legalTextPosition: VerticalAlignment;
  buttonText: string;
  contactOptIn: ContactsFormOptionValue;
  contactOptInText: string;
  smsOptIn: ContactsFormOptionValue;
  smsOptInText: string;
  subTitleText: string;
  titleText: string;
}

export interface ThankYouConfig {
  thankYouTitleText: string;
  thankYouMedia: null | string;
  thankYouMediaType: null | ThankYouMediaTypeEnum | string;
  thankYouMediaProcessingStatus: null | ThankYouMediaProcessingStatusEnum;
  thankYouSubtitle: string;
  thankYouButtonText: string;
  thankYouExternalUrl: string;
  thankYouShowOtherProducts: boolean;
  emailIconName: string;
}

export interface MeasurementsOrderConfig {
  isMeasurementsOrderEnabled: boolean;
  mainMeasurementsReportType: MeasurementsReportType | null;
  mainMeasurementsReportDeliveryType?: DeliveryTimeType;
  backupMeasurementsReportType: MeasurementsReportType | null;
  backupMeasurementsReportDeliveryType?: DeliveryTimeType;
}

interface MapSettings {
  centerpointType: MapCenterpointType;
  placeholderMedia: string | null;
}

interface ProductDetailsConfig {
  sendProductButtonText: string;
  getFinalProposalButtonText: string;
}

export interface OverridesBrandingConfig {
  useOwensCorningToolCalloutBranding: boolean;
  useOwensCorningWidgetBranding: boolean;
}

export interface WidgetUnavailableConfig {
  imgName: string;
  imgHeight: number;
  imgWidth: number;
}

export interface HowInstantLoanWorksConfig {
  getPreQualifiedIconName: string;
  submitApplicationIconName: string;
  getApprovedAndFundedIconName: string;
  fiveMonthPurchaseIcon: string;
}

export interface HowToReplyYourLoanConfig {
  setupAutoPaymentsIconName: string;
  secureLoginIconName: string;
  simpleOneTimePaymentsIconName: string;
  monthlyStatementsIconName: string;
  noEarlyPaymentsIconName: string;
  payOnlineIconName: string;
}

export interface HowInvitationSentConfig {
  invitationSentIconName: string;
}

export enum MapCenterpointType {
  IpGeolocation = 'ipGeolocation',
  CustomImage = 'customImage',
}

export interface WidgetSettings {
  otherContactsLegalText: string;
  otherContactsLegalTextEnabled: boolean;
  otherContactsLegalTextFontSize: number;
  otherContactsLegalTextPosition: VerticalAlignment;
  otherContactsButtonText: string;
  otherContactsContactOptIn: ContactsFormOptionValue;
  otherContactsContactOptInText: string;
  otherContactsSMSOptIn: ContactsFormOptionValue;
  otherContactsSMSOptInText: string;
  otherContactsSubTitleText: string;
  otherContactsTitleText: string;
  rqpLeadsLegalText: string;
  rqpLeadsLegalTextEnabled: boolean;
  rqpLeadsLegalTextFontSize: number;
  rqpLeadsLegalTextPosition: VerticalAlignment;
  rqpLeadsButtonText: string;
  rqpLeadsContactOptIn: ContactsFormOptionValue;
  rqpLeadsContactOptInText: string;
  rqpLeadsSMSOptIn: ContactsFormOptionValue;
  rqpLeadsSMSOptInText: string;
  rqpLeadsSubTitleText: string;
  rqpLeadsTitleText: string;
  titlesFontColor: string;
  titlesFont: string;
  titlesMainTitleText: string;
  mainTextFontColor: string;
  mainTextFont: string;
  mainTextExampleText: string;
  secondaryTextFontColor: string;
  secondaryTextFont: string;
  iconsFillColor: string;
  roofFillColor: string;
  roofStrokeColor: string;
  tooltipAndMapControlsFillColor: string;
  tooltipAndMapControlsStrokeColor: string;
  tooltipAndMapControlsCornerRadius: number | null;
  tooltipAndMapControlsFontColor: string;
  illustrationSelectedColor: string;
  illustrationUnselectedColor: string;
  backgroundFillColor: string;
  mainButtonFillColor: string;
  mainButtonStrokeColor: string;
  mainButtonCornerRadius: number | null;
  mainButtonFontColor: string;
  mainButtonFont: string;
  mainButtonFontWeight: number;
  mainButtonFontSize: number | null;
  mapCenterpointType: MapCenterpointType;
  mapPlaceholderMedia: string | null;
  secondaryButtonFillColor: string;
  secondaryButtonStrokeColor: string;
  secondaryButtonCornerRadius: number | null;
  secondaryButtonFontColor: string;
  secondaryButtonFont: string;
  secondaryButtonFontWeight: number;
  secondaryButtonFontSize: number | null;
  sendProductButtonText: string;
  seeProductDetailsButtonText: string;
  getFinalProposalButtonText: string;
  checkboxFillColor: string;
  checkboxStrokeColor: string;
  checkboxCornerRadius: number | null;
  rqpButtonFillColor: string;
  rqpButtonStrokeColor: string;
  rqpButtonFontColor: string;
  rqpButtonFont: string;
  rqpButtonFontWeight: number;
  rqpButtonFontSize: number | null;
  rqpButtonHorisontalPosition: HorisontalAligment | null;
  rqpButtonVerticalPosition: number | null;
  rqpButtonText: string;
  calloutFillColor: string;
  calloutShowOnTabletsAndDesktops: boolean;
  calloutShowOnPhones: boolean;
  calloutStrokeColor: string;
  calloutTitleText: string;
  calloutTitleFont: string;
  calloutTitleFontColor: string;
  calloutDescriptionText: string;
  calloutDescriptionTextFont: string;
  calloutDescriptionTextFontColor: string;
  company: Company;
  wildcard: string;
  domains: string[];
  thankYouTitleText: string;
  thankYouMedia: string | null;
  thankYouMediaType: ThankYouMediaTypeEnum | null | string;
  thankYouMediaProcessingStatus: null | ThankYouMediaProcessingStatusEnum;
  thankYouSubtitle: string;
  thankYouButtonText: string;
  thankYouExternalUrl: string;
  thankYouShowOtherProducts: boolean;
  squareFootageEnabled: boolean;
  isMeasurementsOrderEnabled: boolean;
  mainMeasurementsReportType: MeasurementsReportType | null;
  mainMeasurementsReportDeliveryType: DeliveryTimeType;
  backupMeasurementsReportType: MeasurementsReportType | null;
  backupMeasurementsReportDeliveryType: DeliveryTimeType;
  suggestedSlopeEnabled: boolean;
  useOwensCorningToolCalloutBranding: boolean;
  useOwensCorningWidgetBranding: boolean;
  customFinancingButtonText: string;
}

export interface WidgetSettingsConfigState {
  initialDemoViewType: string;
  notEditableFields: (keyof WidgetSettings)[];
  mockOCWidgetBrandingNotEditableFields: (keyof WidgetSettings)[];
  mockOCToolCalloutBrandingNotEditableFields: (keyof WidgetSettings)[];
  useOCBrandingToggleVisible: boolean;
  showUpgradeSubscriptionTooltip: boolean;
  demoViewTypeOptionsValue: string[];
  roofQuoteProButton: RoofQuoteProButtonSettingsConfig;
  callout: CalloutSettingsConfig;
}

interface RoofQuoteProButtonSettingsConfig {
  showFullSettings: boolean;
}

interface CalloutSettingsConfig {
  showFullSettings: boolean;
}

export type Products = Line[];

export enum TabKeys {
  ProductCustomizationTab = 'ProductCustomization',
  WidgetTab = 'Widget',
  Snippets = 'Snippets',
  Quotes = 'Quotes',
  ClpButton = 'ClpButton',
  Emails = 'Emails',
}
export interface SettingsLocationState {
  settingsToOpen: [TabKeys, QuoteFeature | undefined];
}

export type ValidatableFieldsToSectionTitleMap = Record<string, I18nEnum>;

export interface CalloutImg {
  name: string;
  width: number;
  height: number;
  margin: string;
}

export interface CalloutSideBar {
  imageName: string;
  imageHeight: number;
  imageWidth: number;
  overlapY: number;
  class: string;
}

export enum ContactsFormOptionValue {
  Hide = 'hide',
  Optional = 'optional',
  Required = 'required',
}
