import { ActionType } from 'types';
import { SubscriptionState } from './types';
import { DEFAULT_SUBSCRIPTION_CONFIG, SAVE_SUBSCRIPTION_CONFIG } from './constants';

const initialState: SubscriptionState = { config: DEFAULT_SUBSCRIPTION_CONFIG };

const subscriptionReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case SAVE_SUBSCRIPTION_CONFIG:
      return {
        ...state,
        config: action.config,
      };
    default:
      return state;
  }
};

export default subscriptionReducer;
