import { createContext } from 'react';

import { CatalogListQuery, TabKeys } from 'modules/product/types';
import { initialQuery } from 'modules/product/constants';

interface TabContextData {
  query: CatalogListQuery;
  setQuery: (query: CatalogListQuery) => void;
}

const tabContextInitialData = {
  query: initialQuery,
  setQuery: () => {},
};

interface ProductsContextType {
  tab: TabKeys;
  [TabKeys.roofleCatalog]: TabContextData;
  [TabKeys.customCatalog]: TabContextData;
}

const ProductsContext = createContext<ProductsContextType>({
  tab: TabKeys.roofleCatalog,
  [TabKeys.roofleCatalog]: tabContextInitialData,
  [TabKeys.customCatalog]: tabContextInitialData,
});

export default ProductsContext;
