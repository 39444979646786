import { cloneElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormInstance } from 'antd';
import get from 'lodash/get';

import { colors } from 'global-constants';
import { I18nEnum } from 'types';
import { intl } from 'intl';
import { AccordionRowsType as RowsType } from 'modules/widget/components/accordionTableUtils';
import { BrandingConfig, ButtonConfig, ContactsFormOptionValue } from '../types';
import { SettingError } from '../components/Widget/Elements';
import { isTextInputEmpty } from '../utils';

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const posibleContrastIssueColorsPaths = [
  ['titles', 'fontColor'],
  ['mainText', 'fontColor'],
  ['secondaryText', 'fontColor'],
];

export const toRGBA = (color: string): string => {
  const rgba = color.length < 8 ? `${color}ff` : color;

  return rgba.toLowerCase();
};

export const checkIsButtonHidden = (button: ButtonConfig, backgroundColor: string): boolean =>
  toRGBA(button.fillColor) === toRGBA(backgroundColor) &&
  toRGBA(button.strokeColor) === toRGBA(backgroundColor);

export const setBackgroundContrastErrors = (
  form: FormInstance<BrandingConfig>,
  message: string,
): void => {
  const errorsState: { paths: string[][]; hasError: boolean }[] = [];

  const brandingConfig = form.getFieldsValue(true);
  const { mainButton, secondaryButton, background } = brandingConfig;

  posibleContrastIssueColorsPaths.forEach(path => {
    errorsState.push({
      paths: [path],
      hasError: toRGBA(background.fillColor) === toRGBA(get(brandingConfig, path)),
    });
  });

  [
    {
      paths: [
        ['mainButton', 'fillColor'],
        ['mainButton', 'strokeColor'],
      ],
      condition: checkIsButtonHidden(mainButton, background.fillColor),
    },
    {
      paths: [
        ['secondaryButton', 'fillColor'],
        ['secondaryButton', 'strokeColor'],
      ],
      condition: checkIsButtonHidden(secondaryButton, background.fillColor),
    },
  ].forEach(({ condition, paths }) => {
    errorsState.push({ paths, hasError: condition });
  });

  errorsState.push({
    paths: [['background', 'fillColor']],
    hasError:
      errorsState.some(s => s.hasError) ||
      toRGBA(brandingConfig.background.fillColor) === toRGBA(colors.grey),
  });

  form.setFields(
    errorsState.flatMap(state =>
      state.paths.map(path => ({
        name: path,
        errors: state.hasError ? [message] : undefined,
      })),
    ),
  );
};

export const setInputsErrors = (form: FormInstance<BrandingConfig>, message: string): void => {
  const brandingConfig: BrandingConfig = form.getFieldsValue(true);

  const { measurementsOrder, mainButton, productDetails } = brandingConfig;
  const { seeProductDetailsButtonText, customFinancingButtonText } = mainButton;
  const { descriptionText, titleText, showOnPhones, showOnTabletsAndDesktops } =
    brandingConfig.callout;
  const { text } = brandingConfig.roofQuoteProButton;
  const { mainTitleText } = brandingConfig.titles;
  const { exampleText } = brandingConfig.mainText;
  const contactUsForm = brandingConfig.otherContacts;
  const rqpLeadsForm = brandingConfig.rqpLeads;
  const { thankYouTitleText, thankYouButtonText } = brandingConfig.thankYou;
  const productDetailsMainButtonText = measurementsOrder.isMeasurementsOrderEnabled
    ? productDetails.getFinalProposalButtonText
    : productDetails.sendProductButtonText;
  const productDetailsMainButtonTextId = measurementsOrder.isMeasurementsOrderEnabled
    ? 'getFinalProposalButtonText'
    : 'sendProductButtonText';

  [
    {
      condition: !descriptionText.trim() && (showOnPhones || showOnTabletsAndDesktops),
      paths: [['callout', 'descriptionText']],
    },
    {
      condition: !titleText.trim() && (showOnPhones || showOnTabletsAndDesktops),
      paths: [['callout', 'titleText']],
    },
    {
      condition: !text.trim(),
      paths: [['roofQuoteProButton', 'text']],
    },
    {
      condition: !mainTitleText.trim(),
      paths: [['titles', 'mainTitleText']],
    },
    {
      condition: isTextInputEmpty(exampleText),
      paths: [['mainText', 'exampleText']],
    },
    {
      condition: !seeProductDetailsButtonText.trim(),
      paths: [['mainButton', 'seeProductDetailsButtonText']],
    },
    {
      condition: !customFinancingButtonText.trim(),
      paths: [['mainButton', 'customFinancingButtonText']],
    },
    {
      condition: !productDetailsMainButtonText.trim(),
      paths: [['productDetails', productDetailsMainButtonTextId]],
    },
    {
      condition:
        contactUsForm.contactOptIn !== ContactsFormOptionValue.Hide &&
        isTextInputEmpty(contactUsForm.contactOptInText),
      paths: [['otherContacts', 'contactOptInText']],
    },
    {
      condition:
        contactUsForm.smsOptIn !== ContactsFormOptionValue.Hide &&
        isTextInputEmpty(contactUsForm.smsOptInText),
      paths: [['otherContacts', 'smsOptInText']],
    },
    {
      condition: !contactUsForm.titleText.trim(),
      paths: [['otherContacts', 'titleText']],
    },
    {
      condition: !contactUsForm.subTitleText.trim(),
      paths: [['otherContacts', 'subTitleText']],
    },
    {
      condition: !contactUsForm.buttonText.trim(),
      paths: [['otherContacts', 'buttonText']],
    },
    {
      condition: contactUsForm.legalTextEnabled && isTextInputEmpty(contactUsForm.legalText),
      paths: [['otherContacts', 'legalText']],
    },
    {
      condition:
        rqpLeadsForm.contactOptIn !== ContactsFormOptionValue.Hide &&
        isTextInputEmpty(rqpLeadsForm.contactOptInText),
      paths: [['rqpLeads', 'contactOptInText']],
    },
    {
      condition:
        rqpLeadsForm.smsOptIn !== ContactsFormOptionValue.Hide &&
        isTextInputEmpty(rqpLeadsForm.smsOptInText),
      paths: [['rqpLeads', 'smsOptInText']],
    },
    {
      condition: !rqpLeadsForm.titleText.trim(),
      paths: [['rqpLeads', 'titleText']],
    },
    {
      condition: !rqpLeadsForm.subTitleText.trim(),
      paths: [['rqpLeads', 'subTitleText']],
    },
    {
      condition: !rqpLeadsForm.buttonText.trim(),
      paths: [['rqpLeads', 'buttonText']],
    },
    {
      condition: rqpLeadsForm.legalTextEnabled && isTextInputEmpty(rqpLeadsForm.legalText),
      paths: [['rqpLeads', 'legalText']],
    },
    {
      condition: !thankYouTitleText.trim(),
      paths: [['thankYou', 'thankYouTitleText']],
    },
    {
      condition: !thankYouButtonText.trim(),
      paths: [['thankYou', 'thankYouButtonText']],
    },
  ].forEach(({ condition, paths }) => {
    form.setFields(paths.map(path => ({ name: path, errors: condition ? [message] : undefined })));
  });
};

export const setMeasurementOrderErrors = (
  form: FormInstance<BrandingConfig>,
  message: string,
): void => {
  const brandingConfig = form.getFieldsValue(true);
  const { isMeasurementsOrderEnabled, mainMeasurementsReportType } =
    brandingConfig.measurementsOrder;

  form.setFields([
    {
      name: ['measurementsOrder', 'mainMeasurementsReportType'],
      errors: isMeasurementsOrderEnabled && !mainMeasurementsReportType ? [message] : undefined,
    },
  ]);
};

export const setBrandingErrors = (form: FormInstance<BrandingConfig>): void => {
  setBackgroundContrastErrors(form, intl.formatMessage({ id: I18nEnum.BackgroundContrastError }));
  setInputsErrors(form, intl.formatMessage({ id: I18nEnum.PleaseFillInTheObligatory }));
  setMeasurementOrderErrors(form, intl.formatMessage({ id: I18nEnum.PleaseFillInTheObligatory }));
};

const ErrorComponent: React.FC<{ errorId?: I18nEnum }> = ({ errorId }) => (
  <SettingError>
    <FormattedMessage id={errorId || I18nEnum.YouHaveMissingFields} />
  </SettingError>
);

export const addSettingError = (rows: RowsType[], errorSections: I18nEnum[], errorId?: I18nEnum) =>
  rows.map(row => {
    if (!errorSections.includes(row.key)) {
      return row;
    }

    return {
      ...row,
      name: cloneElement(row.name, {
        children: Array.isArray(row.name.props.children)
          ? row.name.props.children.concat(<ErrorComponent errorId={errorId} />)
          : [row.name.props.children, <ErrorComponent errorId={errorId} />],
      }),
    };
  });
