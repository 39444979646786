import { ActionType } from 'types';
import * as constants from './constants';
import { ProductState, UpsertCardView, WidgetProductState } from './types';

const productInitialState: ProductState = {
  selectedProducts: [],
  initialSelectedProducts: [],
  edited: false,
  labels: [],
  upsertCardView: UpsertCardView.none,
  upsertedProduct: null,
  upsertedProductInd: -1,
};

const productReducer = (state = productInitialState, action: ActionType) => {
  switch (action.type) {
    case constants.SET_SELECTED_PRODUCTS:
      return {
        ...state,
        selectedProducts: action.selectedProducts,
        edited: action.edited !== undefined ? action.edited : state.edited,
      };
    case constants.SET_INITIAL_SELECTED_PRODUCTS:
      return {
        ...state,
        initialSelectedProducts: action.initialSelectedProducts,
      };
    case constants.SET_LABELS:
      return {
        ...state,
        labels: action.labels,
      };
    case constants.SET_UPSERT_CARD_VIEW:
      return {
        ...state,
        upsertCardView: action.upsertCardView,
      };
    case constants.SET_UPSERTED_PRODUCT:
      return {
        ...state,
        upsertedProduct: action.upsertedProduct,
        upsertedProductInd: action.upsertedProductInd,
      };
    case constants.SET_NEW_FINANCING_TYPE:
      return {
        ...state,
        newFinancingType: action.newFinancingType,
      };
    default:
      return state;
  }
};

const widgetProductReducer = (state: WidgetProductState = {}, action: ActionType) => {
  switch (action.type) {
    case constants.SET_SELECTED_COLORED_LINE: {
      const currentSetting = state[action.productId] || {};

      return {
        ...state,
        [action.productId]: {
          ...currentSetting,
          selectedColoredLine: action.coloredLine,
        },
      };
    }
    case constants.SET_IS_DISABLED_SUBMIT_BUTTON: {
      const currentSetting = state[action.productId] || {};

      return {
        ...state,
        [action.productId]: {
          ...currentSetting,
          isDisabledSubmitButton: action.disabled,
        },
      };
    }
    case constants.SET_IS_LOADING_SUBMIT_BUTTON: {
      const currentSetting = state[action.productId] || {};

      return {
        ...state,
        [action.productId]: {
          ...currentSetting,
          isLoadingDetailsButton: action.loading,
        },
      };
    }
    case constants.CLEAR_WIDGET_PRODUCT:
      return {};
    default:
      return state;
  }
};

export { productReducer, widgetProductReducer };
