import { BrandingConfig, ThirdPartyLegalTextId } from '../types';

const createOcLegalText = (companyName: string): HTMLDivElement => {
  const container = document.createElement('div');
  container.innerText = `${companyName} is an independent contractor [supplier] and is not an affiliate of Owens Corning [Roofing and Asphalt,] [Insulating Systems,] LLC or its affiliated companies. THE PINK PANTHER™ & © 1964-${new Date().getFullYear()} Metro-Goldwyn-Mayer Studios Inc. All Rights Reserved`;
  container.classList.add('oc-legal-text', 'quick-quote-subscription_legal-text');
  return container;
};

export const createThirdPartyLegalText = (
  thirdPartyLegalTextConfig: Required<BrandingConfig['callout']>['thirdPartyLegalText'],
  companyName: string,
): HTMLDivElement | null => {
  switch (thirdPartyLegalTextConfig.id) {
    case ThirdPartyLegalTextId.OC:
      return createOcLegalText(companyName);
    default:
      return null;
  }
};
