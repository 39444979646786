import * as constants from './constants';
import { AddressFeature, ThunkResult } from 'types';
import {
  GeoDataRegister,
  GeoDataRegisterItem,
  GoogleSearchResultType,
  SearchAddressFeature,
  SearchResultType,
  SearchState,
  services,
} from '.';
import trackError from 'services/trackError';
import {
  STORAGE_ADDRESS_FEATURE_PATH,
  STORAGE_ADDRESS_PATH,
  STORAGE_ADDRESS_RESULTS_PATH,
} from 'modules/quickQuote/constants';
import { selectors as quickQuoteSelectors } from 'modules/quickQuote';
import { SET_SEARCH } from 'modules/search/constants';
import { DEFAULT_CENTERPOINT } from 'modules/mapbox/constants';
import { AnalyticsEvent } from 'modules/global';
import { getGeodataFromSessionStorage, replaceCountryName } from 'utils';
import { EventAction } from 'modules/userAction';
import { formAddress } from './utils';
import { selectGeoDataRegister, selectGoogleAutocompleteToken } from './selectors';
import { clearQuickQuote } from 'modules/quickQuote/actions';
import { resetMapInstances } from 'modules/quickQuote/utils';
import { clearWidgetProduct } from 'modules/product/actions';

export const setSearchResults = (results: GoogleSearchResultType[]) => ({
  type: constants.SET_SEARCH_RESULTS,
  results,
});

export const setSearchStr = (searchStr: string | null) => ({
  type: constants.SET_SEARCH_STR,
  searchStr,
});

export const search =
  (searchText: string): ThunkResult<Promise<GoogleSearchResultType[] | undefined>> =>
  async dispatch => {
    try {
      let features: GoogleSearchResultType[] = [];
      const googleAutocomplete = await dispatch(getGoogleAutoCompleteToken());

      if (searchText) {
        features = await services.searchGoogleApi(searchText, googleAutocomplete);
        features = features.map(feature => ({
          ...feature,
          description: replaceCountryName(feature.description),
        }));
      }

      dispatch(setSearchResults(features || []));
      dispatch(setError({ errorMessage: null, hasError: false }));
      localStorage.setItem(STORAGE_ADDRESS_RESULTS_PATH, JSON.stringify(features));

      return features;
    } catch (err) {
      dispatch(setSearchResults([]));
      dispatch(setError({ errorMessage: 'Unexpected network error.', hasError: true }));
      localStorage.setItem(STORAGE_ADDRESS_RESULTS_PATH, JSON.stringify([]));
      trackError(`Search -`, err);
    }
  };

export const setAddress = (formattedAddress?: string) => ({
  type: constants.SET_ADDRESS,
  address: formattedAddress,
});

export const updateGeoDataRegister = (update: GeoDataRegister) => ({
  type: constants.UPDATE_GEO_DATA_REGISTER,
  update,
});

export const setGoogleAutocompleteToken = (
  token: Required<SearchState['googleAutocompleteToken']>,
) => ({
  type: constants.SET_GOOGLE_API_SESSION_TOKEN,
  token,
});

export const clearGoogleAutocompleteToken = () => ({
  type: constants.SET_GOOGLE_API_SESSION_TOKEN,
  token: undefined,
});

export const setAddressFeature = (addressFeature: SearchAddressFeature | null) => ({
  type: constants.SET_ADDRESS_FEATURE,
  addressFeature,
});

export const _clear = (center?: [number, number]) => ({
  type: constants.CLEAR,
  center: center || DEFAULT_CENTERPOINT,
});

export const setError = ({
  hasError,
  errorMessage,
}: {
  hasError: boolean;
  errorMessage: string | null;
}) => ({
  type: constants.SET_ERROR,
  hasError,
  errorMessage,
});

export const getCoordinates: () => Promise<{
  longitude: number;
  latitude: number;
} | null> = async () => {
  try {
    const matches = document.cookie.match(
      new RegExp('(?:^|; )' + 'LL'.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
    );

    if (matches) {
      return JSON.parse(decodeURIComponent(matches[1]));
    } else {
      const { data: coordinates } = await services.coordinatesApi();

      if (coordinates) {
        document.cookie = `LL=${encodeURIComponent(JSON.stringify(coordinates))}; max-age=${
          60 * 5
        }`;

        return coordinates;
      }

      return null;
    }
  } catch (err) {
    trackError(`Coordinates error -`, err);

    return null;
  }
};

export const clear = (): ThunkResult<Promise<void>> => async dispatch => {
  await resetMapInstances();

  const center = getGeodataFromSessionStorage();
  dispatch(_clear(center));

  const { setUserDetailsProvided } = await import('modules/quickQuote/actions');
  dispatch(setUserDetailsProvided(false));

  dispatch(clearWidgetProduct());
};

export const getAddress =
  (demo?: boolean, loadAddress?: boolean): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      if (!loadAddress) {
        const state = getState();
        const statisticRecord = quickQuoteSelectors.selectLeadData(state);

        if (statisticRecord) {
          dispatch({
            type: SET_SEARCH,
            search: {
              searchStr: statisticRecord.address,
              address: statisticRecord.address,
              addressFeature: { center: DEFAULT_CENTERPOINT },
            },
          });
          return;
        }

        dispatch({
          type: SET_SEARCH,
          search: {
            searchStr: null,
            address: '',
            addressFeature: { center: DEFAULT_CENTERPOINT },
          },
        });
        return;
      }

      if (demo) {
        dispatch({
          type: SET_SEARCH,
          search: constants.DEFAULT_SEARCH,
        });
        return;
      }

      const address = localStorage.getItem(STORAGE_ADDRESS_PATH);
      const addressResults: SearchResultType[] =
        JSON.parse(localStorage.getItem(STORAGE_ADDRESS_RESULTS_PATH) as string) || [];
      const centerpointByIp = getGeodataFromSessionStorage();
      const localStorageAddressFeature = localStorage.getItem(STORAGE_ADDRESS_FEATURE_PATH);
      const addressFeature: AddressFeature = localStorageAddressFeature
        ? JSON.parse(localStorageAddressFeature)
        : { center: centerpointByIp ? centerpointByIp : DEFAULT_CENTERPOINT };

      dispatch({
        type: SET_SEARCH,
        search: {
          address,
          results: addressResults,
          addressFeature,
          searchStr: address,
        },
      });
    } catch (err) {
      trackError(`Get address from localStorage -`, err);
    }
  };

export const getGeoDataDetails =
  (result: GoogleSearchResultType): ThunkResult<Promise<GeoDataRegisterItem>> =>
  async (dispatch, getState) => {
    const savedData = selectGeoDataRegister(getState());

    if (savedData[result.place_id]) {
      return savedData[result.place_id];
    }

    const geocoder = new google.maps.Geocoder();
    const geocodeResult = await geocoder.geocode({
      placeId: result.place_id,
    });
    let formattedAddress =
      formAddress(geocodeResult.results[0].address_components) || result.description;
    formattedAddress = replaceCountryName(formattedAddress);

    const { lat, lng } = geocodeResult.results[0].geometry.location.toJSON();
    const center: [number, number] = [lng, lat];
    const placeDetails = {
      formattedAddress,
      center,
    };
    dispatch(
      updateGeoDataRegister({
        [result.place_id]: placeDetails,
      }),
    );
    return placeDetails;
  };

export const getGoogleAutoCompleteToken =
  (): ThunkResult<Promise<google.maps.places.AutocompleteSessionToken>> =>
  async (dispatch, getState) => {
    const savedData = selectGoogleAutocompleteToken(getState());

    if (savedData) {
      return savedData;
    }

    const newToken = new google.maps.places.AutocompleteSessionToken();
    dispatch(setGoogleAutocompleteToken(newToken));

    return newToken;
  };

export const selectAddress =
  (result: GoogleSearchResultType): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const { selectConfig } = await import('modules/quickQuoteConfig/selectors');

    try {
      dispatch(clearGoogleAutocompleteToken());

      const details = await dispatch(getGeoDataDetails(result));
      const addressFeature: SearchAddressFeature = {
        center: details.center,
        address: details.formattedAddress,
      };
      localStorage.setItem(STORAGE_ADDRESS_PATH, addressFeature.address);
      localStorage.setItem(STORAGE_ADDRESS_FEATURE_PATH, JSON.stringify(addressFeature));

      dispatch(clearQuickQuote());
      dispatch(setAddress(addressFeature.address));
      dispatch(setAddressFeature(addressFeature));
      dispatch(setSearchStr(addressFeature.address));

      const { trackAnalyticsCallback } = selectConfig(getState());

      dispatch(
        trackAnalyticsCallback({
          widgetAction: {
            event: AnalyticsEvent.addressEntered,
            meta: addressFeature.address,
          },
          userAction: { action: EventAction.selectAddress },
        }),
      );
    } catch (e) {
      trackError(`Set address in LS -`, e);
    }
  };
