import { colors } from 'global-constants';

const svgNS = 'http://www.w3.org/2000/svg';

export const setupRoofQuoteButton = (
  quickQuoteButton: HTMLDivElement,
  text: string,
  buttonTextClass?: string,
) => {
  const buttonTextElement = document.createElement('div');
  buttonTextElement.innerHTML = text || 'Instant Roof Quote';

  if (buttonTextClass) {
    buttonTextElement.className = buttonTextClass;
  }

  const rightSvg = document.createElementNS(svgNS, 'svg');
  rightSvg.classList.add('quick-quote-button_right-img');
  rightSvg.setAttribute('width', '69');
  rightSvg.setAttribute('height', '43');
  rightSvg.setAttribute('fill', 'none');

  const leftSvg = document.createElementNS(svgNS, 'svg');
  leftSvg.classList.add('quick-quote-button_left-img');
  leftSvg.setAttribute('width', '69');
  leftSvg.setAttribute('height', '43');
  leftSvg.setAttribute('fill', 'none');

  const rightPath = document.createElementNS(svgNS, 'path');
  rightPath.setAttribute('d', 'M0 0v43h68.4a38 38 0 0 1-32.71-18.66l-3.36-5.68A38 38 0 0 0 0 0Z');
  rightPath.setAttribute('fill', colors.orange);
  rightPath.setAttribute('fill-rule', 'evenodd');
  rightPath.setAttribute('clip-rule', 'evenodd');
  rightPath.id = 'quick-quote-button_right-img-path';

  const leftPath = document.createElementNS(svgNS, 'path');
  leftPath.setAttribute('d', 'M68.4 0v43H0a38 38 0 0 0 32.7-18.66l3.37-5.68A38 38 0 0 1 68.4 0Z');
  leftPath.setAttribute('fill', colors.orange);
  leftPath.setAttribute('fill-rule', 'evenodd');
  leftPath.setAttribute('clip-rule', 'evenodd');
  leftPath.id = 'quick-quote-button_left-img-path';

  rightSvg.appendChild(rightPath);
  leftSvg.appendChild(leftPath);

  quickQuoteButton.appendChild(rightSvg);
  quickQuoteButton.appendChild(buttonTextElement);
  quickQuoteButton.appendChild(leftSvg);
};
