import { useState, useEffect } from 'react';
import whatInput from 'what-input';

type Strategy = 'intent' | 'input' | undefined;

export default function useNavigation(strategy: Strategy = 'input') {
  const [intentType, setIntentType] = useState<string>(whatInput.ask());

  const trackNavigation = (type: string) => setIntentType(type);

  useEffect(() => {
    whatInput.registerOnChange(trackNavigation, strategy);
    return () => whatInput.unRegisterOnChange(trackNavigation);
  }, []);

  return [intentType, setIntentType];
}
