import { memo, useCallback } from 'react';
import styled from 'styled-components';

import { ArrowIconImg } from 'components/Icons/ArrowIcon';
import { NodeDetails } from './types';

interface TreeItemOffsetProps<T> {
  nodeDetails: NodeDetails<T>;
  expanded: boolean;
  onToggleExpand: (value: T) => void;
  value: T;
}

function TreeItemOffset<T>({
  nodeDetails,
  expanded,
  onToggleExpand,
  value,
}: TreeItemOffsetProps<T>) {
  const { level, leaf } = nodeDetails;
  const width = level * 36;
  const handleClick = useCallback(
    event => {
      event.stopPropagation();
      event.preventDefault();

      if (!leaf) {
        onToggleExpand(value);
      }
    },
    [leaf, onToggleExpand, value],
  );
  return (
    <Offsets style={{ width, minWidth: width, maxWidth: width }} onClick={handleClick}>
      {!leaf && (
        <InnerArrowContainer>
          <ArrowIconImg isActive={expanded} />
        </InnerArrowContainer>
      )}
    </Offsets>
  );
}

const Offsets = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

const InnerArrowContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 24px;
  margin-right: 12px;
  width: 24px;
`;

export default memo(TreeItemOffset);
