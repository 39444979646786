import { Loader } from '@googlemaps/js-api-loader';
import { default as turfCentroid } from '@turf/centroid';
import { default as turfBbox } from '@turf/bbox';
import { GeoJsonPolygon, QuickQuoteMapsCallbacks, RoofleStructure } from 'modules/quickQuote';
import isEqual from 'lodash/isEqual';
import { googleMapId } from '../constants';
import { getPolygonMeasurements, toRoofleStructure } from 'modules/quickQuote/utils';
import { nanoid } from 'nanoid';
import { geojsonToGoogleMapsPaths, googleMapsPolygonToGeoJson } from '../utils';
import {
  GoogleMapsInstanceCreateMap,
  GoogleMapsPolygonWithListener,
  GoogleMapsRoofBranding,
  GoogleMapsTooltipBranding,
} from '../types';
import { DEFAULT_CENTERPOINT } from 'modules/mapbox/constants';
import centroid from '@turf/centroid';
import { cloneDeep } from 'lodash';
import { colors } from 'global-constants';
import { Polygon } from '@turf/helpers';
import { DEFAULT_QUICK_QUOTE } from 'modules/quickQuote/constants';

if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
  throw new Error('Google Maps API Key is required for Quick Quote.');
}

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const parcelStyles = {
  fillColor: '#3388ff',
  fillOpacity: 0.33,
  strokeColor: '#3388ff',
  strokeWeight: 1,
};

const INITIAL_ZOOM = 13;
const MIN_ZOOM_AFTER_ADDRESS_LOADED = 17;

export class GoogleMapsInstance {
  constructor() {
    this.apiKey = apiKey;
    this.map = null;
    this.loader = null;
    this.googleMapsMapsLibrary = null;
    this.googleMapsDrawingLibrary = null;
    this.googleMapsPlaces = null;
    this.googleMapsAutocompleteService = null;
    this.roofleStructures = [];
    this.polygons = [];
    this.googleMapsPlacesService = null;
    this.drawingManager = null;
    this.infoWindows = [];
    this.previousBounds = null;
    this.previousState = [];
    this.callbacks = {
      onVertexSelected: () => {},
      onPolygonSelection: () => {},
      onPolygonComplete: () => {},
      onMapClick: () => {},
    };
    this.selectedVertex = null;
    this.selectedPolygon = null;
    this.zoomWrapper = null;
    this.zoomInButton = null;
    this.zoomOutButton = null;

    this.roofBranding = {
      fillColor: colors.orange,
      fillOpacity: 0.6,
      strokeColor: colors.orange,
      strokeWeight: 2,
    };

    this.tooltipBranding = {
      cornerRadius: 10,
      fillColor: colors.white,
      fontColor: colors.orange,
      strokeColor: colors.orange,
    };

    this.isDemo = false;

    this.initialize();
  }

  protected readonly apiKey: string;

  map: google.maps.Map | null;

  loader: Loader | null;

  googleMapsMapsLibrary: google.maps.MapsLibrary | null;

  googleMapsDrawingLibrary: google.maps.DrawingLibrary | null;

  googleMapsPlaces: google.maps.PlacesLibrary | null;

  googleMapsAutocompleteService: google.maps.places.AutocompleteService | null;

  googleMapsPlacesService: google.maps.places.PlacesService | null;

  roofleStructures: RoofleStructure[];

  polygons: GoogleMapsPolygonWithListener[] = [];

  roofBranding: GoogleMapsRoofBranding;

  drawingManager: google.maps.drawing.DrawingManager | null;

  infoWindows: google.maps.InfoWindow[] = [];

  previousBounds: google.maps.LatLngBoundsLiteral | null;

  previousState: RoofleStructure[];

  callbacks: QuickQuoteMapsCallbacks;

  selectedVertex: number | null;

  selectedPolygon: google.maps.Polygon | null;

  zoomWrapper: HTMLDivElement | null;

  zoomInButton: HTMLButtonElement | null;

  zoomOutButton: HTMLButtonElement | null;

  tooltipBranding: GoogleMapsTooltipBranding;

  isDemo: boolean;

  get minZoom() {
    return this.roofleStructures.length ? MIN_ZOOM_AFTER_ADDRESS_LOADED : INITIAL_ZOOM;
  }

  async initialize(): Promise<void> {
    this.loader = new Loader({
      apiKey: this.apiKey,
      id: 'google-maps',
      libraries: ['drawing', 'places'],
    });

    this.googleMapsMapsLibrary = await this.loader.importLibrary('maps');
    this.googleMapsDrawingLibrary = await this.loader.importLibrary('drawing');
    this.googleMapsPlaces = await this.loader.importLibrary('places');
    this.googleMapsAutocompleteService = new this.googleMapsPlaces.AutocompleteService();
    this.googleMapsPlacesService = new this.googleMapsPlaces.PlacesService(
      // make sure we don't violate Google TOS
      // https://stackoverflow.com/questions/14343965/google-places-library-without-map
      // https://stackoverflow.com/questions/14343965/google-places-library-without-map#comment70441995_14345546
      document.createElement('div'),
    );
  }

  async createOrUpdateMap({
    container = googleMapId,
    centerpoint,
    isDemo = false,
  }: GoogleMapsInstanceCreateMap) {
    this.isDemo = isDemo;
    const isDefaultMap = isEqual(DEFAULT_CENTERPOINT, centerpoint);
    const standardWidgetZoom = isDefaultMap ? 3.7 : INITIAL_ZOOM;
    const initialZoom =
      this.isDemo && isEqual(DEFAULT_QUICK_QUOTE.centerpoint, centerpoint)
        ? 19.6
        : standardWidgetZoom;
    const minZoom =
      this.isDemo && isEqual(DEFAULT_QUICK_QUOTE.centerpoint, centerpoint)
        ? standardWidgetZoom
        : initialZoom;
    const center = { lat: centerpoint[1], lng: centerpoint[0] };

    if (this.map) {
      this.map.setCenter(center);
      this.map.setZoom(initialZoom);
      return;
    }

    if (this.googleMapsMapsLibrary) {
      const mapOptions: google.maps.MapOptions = {
        center,
        mapId: container,
        mapTypeId: 'satellite',
        zoom: initialZoom,
        minZoom,
        maxZoom: 21,
        tilt: 0,
        scrollwheel: false,
        disableDefaultUI: true,
        zoomControl: true,
        isFractionalZoomEnabled: true,
        gestureHandling: 'greedy',
        keyboardShortcuts: false,
      };

      this.map = new this.googleMapsMapsLibrary.Map(
        document.getElementById(container) as HTMLElement,
        mapOptions,
      );

      this.addCustomControls();
      this.setupGlobalEvents();
      this.initDrawingManager();
      this.initMaxZoom();
    }
  }

  addCustomControls() {
    if (!this.map) return;

    if (!this.zoomWrapper) {
      this.zoomInButton = this.createZoomInControl();
      this.zoomOutButton = this.createZoomOutControl();

      this.zoomWrapper = document.createElement('div');
      this.zoomWrapper.appendChild(this.zoomInButton);
      this.zoomWrapper.appendChild(this.zoomOutButton);

      this.zoomWrapper.style.display = 'none';
    }

    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.zoomWrapper);
  }

  createZoomInControl() {
    const button = document.createElement('button');
    button.classList.add(...['google-maps-control', 'google-maps-control-zoom-in']);
    button.addEventListener('click', () => {
      if (!this.map) return;

      const zoom = this.map.getZoom() || 18;
      this.map.setZoom(zoom + 1);
    });

    return button;
  }

  createZoomOutControl() {
    const button = document.createElement('button');
    button.classList.add(...['google-maps-control', 'google-maps-control-zoom-out']);
    button.addEventListener('click', () => {
      if (!this.map) return;

      const zoom = this.map.getZoom() || 20;
      this.map.setZoom(zoom - 1);
    });

    return button;
  }

  toggleZoomVisible(showZoom: boolean) {
    if (this.zoomWrapper) {
      this.zoomWrapper.style.display = showZoom ? 'block' : 'none';
    }
  }

  setupGlobalEvents() {
    if (!this.map) return;

    google.maps.event.addListener(this.map, 'click', () => {
      this.handleClickOutsidePolygons();
    });

    google.maps.event.addListener(this.map, 'zoom_changed', () => {
      if (!this.map || !this.zoomOutButton) return;

      const zoom = Number(this.map.getZoom());
      this.zoomOutButton.disabled = zoom <= this.minZoom ? true : false;
    });
  }

  handleClickOutsidePolygons() {
    this.removePolygonsEditable();
    this.openInfoWindows();
    this.callbacks.onMapClick();
  }

  initDrawingManager() {
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      polygonOptions: {
        editable: true,
        draggable: true,
        clickable: true,
        ...this.roofBranding,
      },
    });

    google.maps.event.addListener(
      this.drawingManager,
      'polygoncomplete',
      (polygon: google.maps.Polygon) => this.onPolygonComplete(polygon),
    );
  }

  async initMaxZoom() {
    const pollForZoomButton = () => {
      if (!this.zoomInButton) return;

      const element = document.querySelector(
        `#${googleMapId} button[title="Zoom in"]`,
      ) as HTMLButtonElement;

      if (!element) {
        setTimeout(pollForZoomButton, 100);
      } else {
        if (element.disabled) {
          this.zoomInButton.disabled = true;
        }

        const observer = new MutationObserver(mutations => {
          mutations.forEach(mutation => {
            if (!this.zoomInButton) return;

            if (mutation.type === 'attributes') {
              const { cursor } = (mutation.target as HTMLElement).style;
              this.zoomInButton.disabled = cursor === 'default';
            }
          });
        });

        observer.observe(element, {
          attributes: true,
        });
      }
    };

    pollForZoomButton();
  }

  setInitialParcelBounds(parcel: GeoJsonPolygon) {
    const bbox = turfBbox({
      type: 'FeatureCollection',
      features: [parcel],
    });

    googleMapsInstance.map?.fitBounds({
      west: bbox[0],
      south: bbox[1],
      east: bbox[2],
      north: bbox[3],
    });
  }

  addRoofleStructureToMap(roofleStructure: RoofleStructure): google.maps.Polygon {
    const { geoJsonPolygon } = roofleStructure;

    const polygon = this.addGeoJsonPolygonToMap({
      geoJsonPolygon,
      options: {
        ...this.roofBranding,
        fillOpacity: roofleStructure.isIncluded ? 0.6 : 0.25,
        strokeOpacity: roofleStructure.isIncluded ? 0.9 : 0.33,
      },
    });

    polygon.set('id', roofleStructure.id);

    return polygon;
  }

  addGeoJsonPolygonToMap({
    geoJsonPolygon,
    options = {},
  }: {
    geoJsonPolygon: GeoJsonPolygon;
    options: google.maps.PolygonOptions;
  }): google.maps.Polygon {
    const paths = geojsonToGoogleMapsPaths(geoJsonPolygon.geometry as Polygon);

    const polygon = new google.maps.Polygon({
      paths,
      map: this.map,
      ...options,
    });

    return polygon;
  }

  getAllPolygonBounds(polygons: google.maps.Polygon[]): google.maps.LatLngBounds {
    const bounds = new google.maps.LatLngBounds(null);

    polygons.forEach(polygon => {
      polygon.getPaths().forEach(points => {
        points.forEach(point => bounds.extend(point));
      });
    });

    return bounds;
  }

  clearPolygons(): void {
    this.polygons.forEach(polygon => polygon.setMap(null));
    this.polygons = [];
  }

  setRoofBranding(branding: Partial<GoogleMapsRoofBranding>): void {
    this.roofBranding = {
      ...this.roofBranding,
      ...branding,
    };
  }

  setTooltipBranding(branding: Partial<GoogleMapsTooltipBranding>) {
    this.tooltipBranding = {
      ...this.tooltipBranding,
      ...branding,
    };
  }

  // immediately move completed polygons to the main editing experience
  onPolygonComplete(polygon: google.maps.Polygon) {
    polygon.setMap(null);

    const newPolygon = new google.maps.Polygon({
      paths: polygon.getPath(),
      map: this.map,
      editable: true,
      draggable: true,
      ...this.roofBranding,
    }) as GoogleMapsPolygonWithListener;

    newPolygon.set('id', nanoid());
    newPolygon.set('isNew', true);

    this.initPolygon(newPolygon);
    this.polygons.push(newPolygon);
    this.selectedPolygon = newPolygon;
    this.callbacks.onPolygonSelection(true);
    this.callbacks.onPolygonComplete();

    if (this.drawingManager) {
      this.drawingManager.setMap(null);
    }
  }

  addCallbacks(callbacks: QuickQuoteMapsCallbacks) {
    this.callbacks = callbacks;
  }

  async renderRoofleStructures() {
    this.clearPolygons();
    this.clearInfoWindows();

    this.roofleStructures.forEach(roofleStructure => {
      this.polygons.push(this.addRoofleStructureToMap(roofleStructure));
    });

    this.fitToStructureBounds();

    this.roofleStructures.forEach(structure => {
      this.renderStructureInfoWindow(structure);
    });
  }

  addParcelToMap(geoJsonPolygon: GeoJsonPolygon) {
    if (this.isDemo) return;

    const polygon = this.addGeoJsonPolygonToMap({
      geoJsonPolygon,
      options: {
        zIndex: -1,
        ...parcelStyles,
      },
    });

    polygon.addListener('click', () => {
      this.handleClickOutsidePolygons();
    });

    return polygon;
  }

  renderStructureInfoWindow(structure: RoofleStructure) {
    if (!this.map) return;

    const hasExistingWindow = this.infoWindows.some(infoWindow => {
      return infoWindow.get('id') === structure.id;
    });

    if (hasExistingWindow) {
      return;
    }

    const _centroid = centroid(structure.geoJsonPolygon);
    const [lng, lat] = _centroid.geometry.coordinates;
    const latLng = new google.maps.LatLng(lat, lng);

    const infoWindow = new google.maps.InfoWindow({
      disableAutoPan: true,
    });

    const structureName = structure.isError ? structure.previousName : structure.name;
    infoWindow.setContent(structureName);
    infoWindow.setPosition(latLng);
    infoWindow.open({
      map: this.map,
      shouldFocus: false,
    });
    infoWindow.set('id', structure.id);
    this.infoWindows.push(infoWindow);
  }

  startEditor() {
    this.previousState = cloneDeep(this.roofleStructures);

    this.selectedPolygon = this.polygons[0];
    this.selectedPolygon?.setEditable(true);
    this.selectedPolygon?.setDraggable(true);
    this.hideInfoWindowForPolygon(this.selectedPolygon);
    this.callbacks.onPolygonSelection(true);

    this.polygons.forEach(polygon => this.initPolygon(polygon));
  }

  initPolygon(polygon: GoogleMapsPolygonWithListener) {
    const clickListener = polygon.addListener('click', event => {
      this.selectedPolygon = polygon;

      if (Number.isInteger(event.vertex)) {
        this.callbacks.onVertexSelected();
        this.selectedVertex = event.vertex;
      } else {
        this.callbacks.onPolygonSelection(true);
        this.selectedVertex = null;
      }

      this.editOneRoof(polygon);
    });

    const dragListener = polygon.addListener('dragend', () => {
      const infoWindow = this.infoWindows.find(_infoWindow => {
        return _infoWindow.get('id') === polygon.get('id');
      });

      if (!infoWindow) {
        return;
      }

      const bounds = this.getPolygonBounds(polygon);
      infoWindow.setPosition(bounds.getCenter());
    });

    polygon.listeners = [clickListener, dragListener];

    const path = polygon.getPath();
    path.addListener('insert_at', (event: any) => {
      this.selectedPolygon = polygon;
      this.selectedVertex = event;
    });

    path.addListener('remove_at', () => {
      this.selectedVertex = null;
    });

    path.addListener('set_at', (event: any) => {
      this.selectedPolygon = polygon;
      this.selectedVertex = event;
    });
  }

  focusStructurePolygon(id: string) {
    if (!this.map) return;

    const polygon = this.polygons.find(_polygon => _polygon.get('id') === id);

    if (!polygon) {
      console.error('!polygon');
      return;
    }

    const bounds = this.getPolygonBounds(polygon);
    this.map.fitBounds(bounds);
  }

  fitToStructureBounds(force?: boolean) {
    if (!this.map) return;

    const bounds = this.getAllPolygonBounds(this.polygons);
    const boundsJson = bounds.toJSON();

    if (force || (this.polygons.length && this.hasNewBounds(boundsJson))) {
      this.map.fitBounds(bounds);
      this.previousBounds = boundsJson;
    }
  }

  getPolygonBounds(polygon: google.maps.Polygon) {
    const bounds = new google.maps.LatLngBounds();

    polygon.getPaths().forEach(points => {
      points.forEach(element => bounds.extend(element));
    });

    return bounds;
  }

  stopEditor() {
    this.handleRemovedStructures();
    this.updateExistingStructures();
    this.handleNewStructures();
    this.removeEditable();
    this.fitToStructureBounds(true);
  }

  handleRemovedStructures() {
    this.roofleStructures
      .filter(structure => {
        return !this.polygons.some(polygon => {
          return polygon.get('id') === structure.id;
        });
      })
      .forEach(structure => {
        this.infoWindows.forEach((infoWindow, index) => {
          if (infoWindow.get('id') === structure.id) {
            this.clearInfoWindow(infoWindow);
            this.infoWindows.splice(index, 1);
          }
        });
      });

    this.roofleStructures = this.roofleStructures.filter(structure => {
      return this.polygons.some(polygon => {
        return polygon.get('id') === structure.id;
      });
    });
  }

  handleNewStructures() {
    this.polygons
      .filter(polygon => {
        return polygon.get('isNew');
      })
      .forEach(polygon => {
        polygon.set('isNew', false);

        const structure = this.createNewRoofleStructure(polygon);
        this.roofleStructures.push(structure);

        this.renderStructureInfoWindow(structure);
      });
  }

  updateExistingStructures() {
    this.polygons.forEach(polygon => {
      const index = this.roofleStructures.findIndex(structure => {
        return polygon.get('id') === structure.id;
      });

      if (index === -1) {
        return;
      }

      const structure = this.roofleStructures[index];

      const geojson = googleMapsPolygonToGeoJson({
        polygon,
        id: structure.geoJsonPolygon.id || structure.id,
        properties: structure.geoJsonPolygon.properties || {},
      });

      structure.geoJsonPolygon = geojson;
      structure.centroid = turfCentroid(structure.geoJsonPolygon);
      structure.measurements = getPolygonMeasurements(structure.geoJsonPolygon, structure.slope);
    });
  }

  createNewRoofleStructure(polygon: google.maps.Polygon) {
    const newRoofCount = this.roofleStructures.filter(structure =>
      structure.name.includes('New Roof'),
    ).length;
    const count = newRoofCount + 1;

    const geojson = googleMapsPolygonToGeoJson({
      polygon,
      id: polygon.get('id'),
      properties: {
        name: `New Roof ${count}`,
        id: polygon.get('id'),
      },
    });

    return toRoofleStructure({ structure: geojson });
  }

  cancelEditor() {
    if (this.drawingManager) {
      this.drawingManager.setDrawingMode(null);
      this.drawingManager.setMap(null);
    }

    this.clearPolygons();
    this.clearInfoWindows();
    this.restorePreviousState();
    this.fitToStructureBounds(true);
  }

  openInfoWindows() {
    this.infoWindows.forEach(infoWindow => {
      infoWindow.open({
        map: this.map,
        shouldFocus: false,
      });
    });
  }

  clearInfoWindows() {
    this.infoWindows.forEach(infoWindow => this.clearInfoWindow(infoWindow));
    this.infoWindows = [];
  }

  clearInfoWindow(infoWindow: google.maps.InfoWindow) {
    google.maps.event.clearInstanceListeners(infoWindow);
    infoWindow.close();
  }

  restorePreviousState() {
    this.roofleStructures = this.previousState;
    this.renderRoofleStructures();
  }

  removePolygonsEditable() {
    this.polygons.forEach(polygon => {
      polygon.setEditable(false);
      polygon.setDraggable(false);
    });

    this.selectedPolygon = null;
    this.selectedVertex = null;
  }

  removePolygonsListeners() {
    this.polygons.forEach(polygon => {
      if (polygon.listeners) {
        polygon.listeners.forEach(listener => {
          listener.remove();
        });

        polygon.listeners = [];
      }
    });
  }

  removeEditable() {
    this.removePolygonsEditable();
    this.removePolygonsListeners();
    this.openInfoWindows();
    this.clearDrawingManager();

    this.previousState = [];
    this.callbacks.onPolygonSelection(false);
  }

  reset() {
    this.clearPolygons();
    this.clearInfoWindows();
    this.clearDrawingManager();

    if (this.map) {
      this.map.setZoom(INITIAL_ZOOM);
    }
    if (this.zoomInButton) {
      this.zoomInButton.disabled = false;
    }

    this.previousState = [];
    this.roofleStructures = [];
    this.selectedVertex = null;
    this.selectedPolygon = null;
    this.previousBounds = null;
  }

  hardReset() {
    this.reset();
    this.map = null;
    this.googleMapsPlacesService = null;
  }

  clearDrawingManager() {
    if (this.drawingManager) {
      this.drawingManager.setMap(null);
    }
  }

  addRoofs() {
    if (!this.drawingManager) return;

    this.removePolygonsEditable();
    this.openInfoWindows();

    this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    this.drawingManager.setMap(this.map);
  }

  deleteRoofOrVertex() {
    if (!this.selectedPolygon) return;

    if (this.selectedVertex && Number.isInteger(this.selectedVertex)) {
      this.selectedPolygon.getPath().removeAt(this.selectedVertex);
      return;
    }

    this.polygons.forEach((polygon, index) => {
      if (polygon === this.selectedPolygon) {
        polygon.setMap(null);
        this.polygons.splice(index, 1);
        this.callbacks.onPolygonSelection(false);
      }
    });

    this.infoWindows.forEach((infoWindow, index) => {
      if (!this.selectedPolygon) return;

      if (infoWindow.get('id') === this.selectedPolygon.get('id')) {
        this.clearInfoWindow(infoWindow);
        this.infoWindows.splice(index, 1);
      }
    });
  }

  editOneRoof(targetPolygon: google.maps.Polygon) {
    this.polygons.forEach(_polygon => {
      _polygon.setEditable(false);
      _polygon.setDraggable(false);
    });

    targetPolygon.setEditable(true);
    targetPolygon.setDraggable(true);
    this.hideInfoWindowForPolygon(targetPolygon);
    this.callbacks.onPolygonSelection(true);
  }

  hideInfoWindowForPolygon(targetPolygon: google.maps.Polygon) {
    this.infoWindows.forEach(infoWindow => {
      infoWindow.open({
        map: this.map,
        shouldFocus: false,
      });
    });

    // hide info window only for roof being edited
    const infoWindow = this.infoWindows.find(_infoWindow => {
      return _infoWindow.get('id') === targetPolygon.get('id');
    });

    infoWindow?.close();
  }

  hasNewBounds(boundsJson: google.maps.LatLngBoundsLiteral) {
    return (
      boundsJson.north !== this.previousBounds?.north ||
      boundsJson.west !== this.previousBounds?.west ||
      boundsJson.south !== this.previousBounds?.south ||
      boundsJson.east !== this.previousBounds?.east
    );
  }
}

export const googleMapsInstance = new GoogleMapsInstance();
