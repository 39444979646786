import { setupQueryParamsBase } from './setupQueryParamsBase';
import { insertAfter } from './insertScript';

export const initEnv = async (widgetId: string): Promise<void> => {
  insertEnvScript(widgetId);
  await new Promise(waitUntilEnvAppears);
};

const insertEnvScript = (widgetId: string): void => {
  const { script, urlObj } = setupQueryParamsBase(widgetId);

  const envScript = document.createElement('script');
  envScript.setAttribute('src', `${urlObj.origin}/runtime-env.js`);

  insertAfter(script, envScript);
};

const waitUntilEnvAppears = (resolve: (value: unknown) => void): void => {
  if (window.__RUNTIME_CONFIG__) {
    resolve(null);
  } else {
    setTimeout(() => waitUntilEnvAppears(resolve), 250);
  }
};
