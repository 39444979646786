import { I18nEnum } from 'types';
import { constants } from '.';
import { GoogleMapsState } from './types';
import { getDrawPolygonHelpText } from './utils';
import * as quickQuoteConstants from 'modules/quickQuote/constants';
import * as searchConstants from 'modules/search/constants';

const initialState: GoogleMapsState = {
  isReady: false,
  isEditing: false,
  isAddingPolygons: false,
  isPolygonSelected: false,
  polygonClickCount: 0,
  helpText: '',
};

const GoogleMapsReducer = (state = initialState, action): GoogleMapsState => {
  switch (action.type) {
    case constants.SET_GOOGLE_MAPS_IS_READY: {
      return {
        ...state,
        isReady: action.isReady,
      };
    }
    case constants.SET_GOOGLE_MAPS_IS_EDITING: {
      const helpText = I18nEnum.MapShapeSelectedHelpText;

      return {
        ...state,
        isEditing: action.isEditing,
        isAddingPolygons: false,
        helpText,
      };
    }
    case constants.SET_GOOGLE_MAPS_IS_ADDING_POLYGONS: {
      return {
        ...state,
        isAddingPolygons: action.isAddingPolygons,
      };
    }
    case constants.SET_GOOGLE_MAPS_IS_POLYGON_SELECTED: {
      return {
        ...state,
        isPolygonSelected: action.isPolygonSelected,
      };
    }
    case constants.SET_GOOGLE_MAPS_VERTEX_HELP_TEXT: {
      const helpText = I18nEnum.MapVertexSelectedHelpText;

      return {
        ...state,
        helpText,
      };
    }
    case constants.SET_GOOGLE_MAPS_POLYGON_HELP_TEXT: {
      const helpText = I18nEnum.MapShapeSelectedHelpText;

      return {
        ...state,
        helpText,
      };
    }
    case constants.SET_GOOGLE_MAPS_NO_SELECTION_HELP_TEXT: {
      const helpText = I18nEnum.MapNoSelectionHelpText;

      return {
        ...state,
        helpText,
      };
    }
    case constants.SET_GOOGLE_MAPS_DRAW_POLYGON_CLICK_COUNT: {
      const { polygonClickCount } = action;
      const count =
        polygonClickCount !== undefined ? polygonClickCount : state.polygonClickCount + 1;
      const helpText = state.isAddingPolygons ? getDrawPolygonHelpText(count) : state.helpText;

      return {
        ...state,
        polygonClickCount: count,
        helpText,
      };
    }
    case constants.CLEAR_GOOGLE_MAPS:
    case quickQuoteConstants.CLEAR: {
      return { ...initialState };
    }
    case searchConstants.CLEAR: {
      return { ...initialState, isReady: true };
    }
    default:
      return state;
  }
};

export default GoogleMapsReducer;
