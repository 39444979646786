export enum MeasurementsReportType {
  EagleViewBidPerfect = 'EagleViewBidPerfect',
  EagleViewPremiumResidential = 'EagleViewPremiumResidential',
  EagleViewPremiumCommercial = 'EagleViewPremiumCommercial',
  GAFMeasurements = 'GAFMeasurements',
  NoReport = 'NoReport',
}

export const DeliveryTime = {
  RegularDelivery: 'RegularDelivery',
  ExpressDelivery: 'ExpressDelivery',
  ThreeHourDelivery: 'ThreeHourDelivery',
} as const;

export type DeliveryTimeType = typeof DeliveryTime[keyof typeof DeliveryTime];
