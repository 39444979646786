import { PriceConfig } from './priceConfig';

import {
  DefaultFinancingTypes,
  PriceInfo,
  RateSheet,
  ProductCategory,
} from 'modules/financing/types';
import * as constants from 'modules/financing/constants';
import { toFixed } from 'utils';
import { MonthlyPrice } from './monthlyPrice';

export class ContractorLoanPro {
  constructor({
    priceConfig,
    monthlyPrice,
  }: {
    priceConfig: PriceConfig;
    monthlyPrice: MonthlyPrice;
  }) {
    this.priceConfig = priceConfig;
    this.monthlyPriceInstance = monthlyPrice;
  }

  private readonly monthlyPriceInstance: MonthlyPrice;

  private readonly priceConfig: PriceConfig;

  get activeRateSheets(): RateSheet[] {
    return this.priceConfig.rateSheets.filter(item => item.isActive);
  }

  get offerCodeRateSheets(): RateSheet[] {
    let offerCodeRateSheets: RateSheet[] = [];

    const { product, offerCodes, rateSheets } = this.priceConfig;

    if (!product) {
      return offerCodeRateSheets;
    }

    if (product.loanProductId > 0 && offerCodes.length) {
      const productOfferCode = offerCodes.find(
        item => item.id === product.loanProductId && item.isActive,
      );

      if (productOfferCode) {
        offerCodeRateSheets = productOfferCode.rateSheetMerchantProductPrices.reduce(
          (acc: RateSheet[], item) => {
            const rateSheet = rateSheets.find(
              rateSheet => rateSheet.rateSheetMerchantProductPriceId === item.id,
            );
            return !rateSheet ? acc : [...acc, rateSheet];
          },
          [],
        );
      }
    }

    return offerCodeRateSheets;
  }

  generateLoanInformation(
    priceInfo: PriceInfo,
    options?: {
      includeTooltips?: boolean;
      includeMerchantFee?: boolean;
      useAdditionalCostsFormula?: boolean;
    },
  ): PriceInfo {
    const result: PriceInfo = {
      ...priceInfo,
    };

    let paymentFactorLow = Number.MAX_SAFE_INTEGER;
    let merchantFeesSum = 0;

    const { product, selectQQFinancing, unavailableStates, centerpointState, financing } =
      this.priceConfig;

    const rateSheets = this.offerCodeRateSheets.length
      ? this.offerCodeRateSheets
      : this.activeRateSheets;

    if (!rateSheets.length || !product) {
      return result;
    }

    if (selectQQFinancing) {
      if (!(unavailableStates && centerpointState)) {
        return result;
      } else if (unavailableStates.includes(centerpointState)) {
        result.priceType = DefaultFinancingTypes.BasicFinancing;
        if (product.loanProductId !== constants.NoFinancing) {
          result.monthly = this.monthlyPriceInstance.calculateMonthlyPrice(result.total);
          result.months = financing.months;
          result.apr = financing.percentageRate;
        }
        return result;
      }
    }

    result.priceType = DefaultFinancingTypes.ContractorLoanPro;

    if (product.loanProductId === constants.NoFinancing) {
      return result;
    }

    rateSheets.forEach(rateSheet => {
      if (
        ([ProductCategory.fixedRate, ProductCategory.fixedPayment] as unknown[]).includes(
          rateSheet.productCategory,
        )
      ) {
        if (rateSheet.paymentFactorLow < paymentFactorLow) {
          paymentFactorLow = rateSheet.paymentFactorLow;
          if (options?.includeTooltips) {
            result.paymentsTooltipMonths = rateSheet.fullTerm;
            result.paymentsTooltipInterest = rateSheet.apr;
          }
        }
        const interest = +rateSheet.apr.slice(0, rateSheet.apr.indexOf('%'));

        if (result.interest === undefined || result.interest > interest) {
          result.interest = interest;
          if (options?.includeTooltips) {
            result.interestTooltipMonths = rateSheet.fullTerm;
          }
        }
      } else {
        // Temporary return supporting Same-As-Cash, because it breaks 0.00% APR block
        const months = [ProductCategory.deferredInterest, ProductCategory.sameAsCash].some(
          category => category === rateSheet.productCategory,
        )
          ? +rateSheet.shortDescription.slice(0, rateSheet.shortDescription.indexOf('m'))
          : +rateSheet.fullTerm;
        result.months = months > (result.months || 0) ? months : result.months;
      }

      merchantFeesSum += +rateSheet.merchantFee.replace('%', '');
    });

    if (result.interest === undefined) {
      result.interest = 0;
    }

    if (product.includeMerchantFee || options?.includeMerchantFee) {
      result.merchantFeeAvg = +toFixed(merchantFeesSum / rateSheets.length / 100, 4);
    }

    if (product.includeMerchantFee) {
      result.merchantFeeAmount = +toFixed(result.total * (result.merchantFeeAvg as number), 2);
      result.total = +toFixed(result.total + result.merchantFeeAmount, 2);
    }
    if (paymentFactorLow !== Number.MAX_SAFE_INTEGER) {
      result.payments = +toFixed(result.total * paymentFactorLow, 0);
      result.paymentFactorLow = paymentFactorLow;
    }

    return result;
  }
}
