import { FormInstance } from 'antd';
import uniqueId from 'lodash/uniqueId';

import {
  AdditionalCostsFormKey,
  ExtraCostFormKeys,
  GroupValidationResult,
  InstantQuotesFormKey,
  MarketQuoteSettingsModel,
  QuoteSettingsFormValues,
  QuoteSettingsModel,
  StaticQuoteSettingsFormValues,
} from 'modules/quoteSettings/types';
import {
  INITIAL_GROUP_VALIDATION_RESULT,
  ADDITIONAL_COSTS_TOGGLES,
  ADDITIONAL_COSTS_TOGGLES_IN_MODEL,
} from 'modules/quoteSettings/constants';
import { FieldData } from 'modules/quoteSettings/components/types';
import { OptionType } from 'components/Inputs/Select/types';
import { StructureSlopeEnum } from 'modules/quickQuote/types';

export const getFieldsDataAfterUpdate = (
  quoteSettings: StaticQuoteSettingsFormValues,
  marketQuoteSettings: MarketQuoteSettingsModel[],
  lastSelectedMarketName: string,
): FieldData[] => {
  const fieldsData = Object.entries(quoteSettings).map(([key, value]) => ({
    name: key,
    value,
  }));
  return [
    ...fieldsData,
    ...getMarketFieldsDataAfterUpdate(marketQuoteSettings, lastSelectedMarketName),
  ];
};

const getInitialMarketQuoteSettingsFieldData = () => [
  {
    name: InstantQuotesFormKey.ApplyDefaultSlopeToAllMarkets,
    value: true,
  },
  {
    name: InstantQuotesFormKey.DefaultSlope,
    value: StructureSlopeEnum.medium,
  },
  {
    name: InstantQuotesFormKey.LastSelectedMarket,
    value: '',
  },
  {
    name: InstantQuotesFormKey.SlopeByMarket,
    value: {},
  },
];

export const getMarketFieldsDataAfterUpdate = (
  marketQuoteSettings: MarketQuoteSettingsModel[],
  lastSelectedMarketName: string,
): FieldData[] => {
  if (!marketQuoteSettings.length) {
    return getInitialMarketQuoteSettingsFieldData();
  }

  const lastSelectedMarket = lastSelectedMarketName
    ? marketQuoteSettings.find(settings => settings.marketSlug === lastSelectedMarketName)
    : null;
  const firstSlope = marketQuoteSettings[0].slope;
  const sameSlope = marketQuoteSettings.every(market => market.slope === firstSlope);
  return [
    {
      name: InstantQuotesFormKey.ApplyDefaultSlopeToAllMarkets,
      value: sameSlope,
    },
    {
      name: InstantQuotesFormKey.DefaultSlope,
      value: sameSlope ? firstSlope : lastSelectedMarket?.slope || marketQuoteSettings[0].slope,
    },
    {
      name: InstantQuotesFormKey.LastSelectedMarket,
      value: lastSelectedMarket ? lastSelectedMarketName : marketQuoteSettings[0].marketSlug,
    },
    {
      name: InstantQuotesFormKey.SlopeByMarket,
      value: {},
    },
  ];
};

export const validateGroups = (
  form: FormInstance<QuoteSettingsFormValues>,
): GroupValidationResult => {
  const errorFields = form.getFieldsError();
  const result: GroupValidationResult = {
    ...INITIAL_GROUP_VALIDATION_RESULT,
  };
  const instantQoutesFormKeys = Object.values(InstantQuotesFormKey);
  const additionalCostsFormKeys = Object.values(AdditionalCostsFormKey);

  errorFields.forEach(data => {
    if (!data.errors.length) {
      return;
    }

    if (data.name.includes(AdditionalCostsFormKey.OtherExtraCosts)) {
      result.extraCostsInvalid = true;
    }

    if (instantQoutesFormKeys.some(key => data.name.some(name => name === key))) {
      result.instantQoutesFeatureFieldsInvalid = true;
    }

    if (additionalCostsFormKeys.some(key => data.name.some(name => name === key))) {
      result.additionalCostsFeatureFieldsInvalid = true;
    }
  });

  return result;
};

export const areAdditionalCostsEnabledInForm = <T extends StaticQuoteSettingsFormValues>(
  formValues?: T,
): boolean => !!formValues && ADDITIONAL_COSTS_TOGGLES.some(toggleName => formValues[toggleName]);

export const areAdditionalCostsEnabledInModel = (data?: QuoteSettingsModel): boolean =>
  !!data && ADDITIONAL_COSTS_TOGGLES_IN_MODEL.some(toggleName => data[toggleName]);

export const getOtherCostsAsFormValues = (
  quoteSettings: StaticQuoteSettingsFormValues,
): {
  key: string | number;
  question: string;
  multiple: boolean;
  checkboxOptions: OptionType[];
  radioGroupOptions: OptionType[];
}[] => {
  const costs = quoteSettings[AdditionalCostsFormKey.OtherExtraCosts];

  if (!costs?.length) {
    return [];
  }

  return costs
    .filter(otherCost => !!otherCost) // undefined is used to add empty answer to a form
    .map(otherCost => {
      const answersExist = !!otherCost[ExtraCostFormKeys.Answers]?.length;
      return {
        key: otherCost[ExtraCostFormKeys.Id] || uniqueId('question-'),
        question: otherCost[ExtraCostFormKeys.Question],
        multiple: otherCost[ExtraCostFormKeys.MultiChoice],
        checkboxOptions:
          otherCost[ExtraCostFormKeys.MultiChoice] && answersExist
            ? otherCost[ExtraCostFormKeys.Answers]
                .filter(answer => !!answer)
                .map((answer, index) => ({
                  label: answer[ExtraCostFormKeys.OptionName],
                  value: answer[ExtraCostFormKeys.Id] || index,
                  type:
                    answer[ExtraCostFormKeys.CostType] ||
                    answer[ExtraCostFormKeys.UnitOfMeasurement],
                }))
            : [],
        radioGroupOptions:
          !otherCost[ExtraCostFormKeys.MultiChoice] && answersExist
            ? otherCost[ExtraCostFormKeys.Answers]
                .filter(answer => !!answer)
                .map((answer, index) => ({
                  label: answer[ExtraCostFormKeys.OptionName],
                  value: answer[ExtraCostFormKeys.Id] || index,
                  type:
                    answer[ExtraCostFormKeys.CostType] ||
                    answer[ExtraCostFormKeys.UnitOfMeasurement],
                }))
            : [],
      };
    });
};
