import { colors, mediaQueries, orientation, colorFilters } from 'global-constants';
import { widgetId } from 'modules/widget/constants/toolScript';
import { BrandingConfig } from 'modules/widget/types/toolScript';
import { setLimitedSize } from 'utils/css.utils';

const widgetStylesId = `${widgetId}-styles`;
export const qqCalloutTitleFontWeight = 600;

const DESKTOP_SCALE_VALUE = 0.8;
const MOBILE_SCALE_VALUE = 0.7;

export const truncateCSS = `
  width: 100%;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
`;

const rqpButtonMobileStyles = `
  #quick-quote-button.quick-quote-button {
    height: 30px;
    font-size: 16px;
    line-height: 19px;
  }

  #quick-quote-button.quick-quote-button .quick-quote-button_right-img {
    transform: translate(85%, 0%) scale(${MOBILE_SCALE_VALUE});
    transform-origin: top;
  }

  #quick-quote-button.quick-quote-button .quick-quote-button_left-img {
    transform: translate(-85%, 0%) scale(${MOBILE_SCALE_VALUE});
    transform-origin: top;
  }
`;

const getOcPantherHorizontalOffset = (scale: number, isSmallScreen: boolean): number => {
  switch (scale) {
    case DESKTOP_SCALE_VALUE:
      return isSmallScreen ? -4 : 0;
    case MOBILE_SCALE_VALUE:
      return -8;
    default:
      return 0;
  }
};

const getOcPantherScaledStyles = (
  brandingConfig: BrandingConfig,
  params: { isSmallScreen: boolean },
  scale = DESKTOP_SCALE_VALUE,
) => `
  .quick-quote-oc-panther {
    cursor: pointer;
    position: absolute;
    transform-origin: bottom;
    z-index: 1;

    ${
      brandingConfig.roofQuoteProButton.horisontalPosition === 'left'
        ? `transform: scaleX(-1) scale(${scale});`
        : `transform: scale(${scale});`
    }
    top: -${
      (brandingConfig.callout.sideBar?.imageHeight || 0) -
      (brandingConfig.callout.sideBar?.overlapY || 0) +
      6 // 6 additional pixels to compensate scale
    }px;

    ${(() => {
      const offset = getOcPantherHorizontalOffset(scale, params.isSmallScreen);
      return brandingConfig.roofQuoteProButton.horisontalPosition === 'left'
        ? `left: ${offset}px;`
        : `right: ${offset}px;`;
    })()};
  }
`;

const getOcCalloutMobileStyles = (
  brandingConfig: BrandingConfig,
  params: { isSmallScreen: boolean },
  scale = DESKTOP_SCALE_VALUE,
) => `
  ${getOcPantherScaledStyles(brandingConfig, params, scale)}
  
  .quick-quote-button.oc-button .quick-quote-callout {
    column-gap: 16px;
    padding: 14px;
  }

  .quick-quote-button.oc-button .quick-quote-callout .quick-quote-callout-title {
    font-size: 16px;
    line-height: 18px;
  }

  .quick-quote-button.oc-button .quick-quote-callout .quick-quote-callout-text {
    font-size: 14px;
    line-height: 14px;
  }

  .quick-quote-button.oc-button .quick-quote-callout .quick-quote-callout-image {
    margin-right: -8px !important;
  }
`;

const keyframes = `
  @keyframes quick-quote-mask-show {
    0% {
      display: none;
      opacity: 0;
    }
    99% {
      opacity: 0.45;
    }
    100% {
      display: block;
      opacity: 0.45;
    }
  }

  @keyframes quick-quote-mask-hide {
    0% {
      opacity: 0.45;
    }
    99% {
      opacity: 0;
    }
    100% {
      display: none;
      opacity: 0;
    }
  }

  @keyframes quick-quote-spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  @keyframes callout-appear {
    0% {
      max-width: 15px;
      opacity: 0.3;
    }
    100% {
      max-width: 400px;
      opacity: 1;
      pointer-events: all;
    }
  }

  @keyframes callout-disappear {
    0% {
      max-width: 400px;
      opacity: 1;
      pointer-events: none;
    }
    100% {
      max-width: 15px;
      opacity: 0;
      pointer-events: none;
    }
  }
`;

export const setupStyles = (brandingConfig: BrandingConfig) => {
  const styles = document.getElementById(widgetStylesId);
  if (styles) {
    styles.remove();
  }

  const style = document.createElement('style');
  style.id = widgetStylesId;

  style.textContent = `
    ${keyframes}

    .quick-quote-root {
      z-index: 2147483647;
      position: relative;
      font-family: Arial, Helvetica, Sans-serif;
    }

    .quick-quote-mask {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${colors.white};
      opacity: 0.45;
      z-index: 10000;
    }

    .quick-quote-closed .quick-quote-mask {
      display: none;
      animation: quick-quote-mask-hide 0.25s linear;
      animation-fill-mode: forwards;
    }

    .quick-quote-opened .quick-quote-mask {
      display: block;
      animation: quick-quote-mask-show 0.25s linear forwards;
    }

    .quick-quote-wrapper {
      position: fixed;
      z-index: 10000;
      top: 0;
      ${brandingConfig.roofQuoteProButton.horisontalPosition === 'left' ? `left: 0;` : `right: 0;`}
      display: flex;
      height: 100%;
      max-width: 1200px;
      width: 80%;
      transform: translateX(
        ${brandingConfig.roofQuoteProButton.horisontalPosition === 'left' ? `-100%` : `100%`}
      );
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 0 3px rgba(0, 0, 0, 0.12),
        0 0 8px rgba(0, 0, 0, 0.2);
      transition: transform 0.3s ease-in-out;
    }

    .quick-quote-wrapper::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      border: 8px solid #f3f3f3;
      border-radius: 50%;
      border-top: 8px solid ${colors.mediumGrey};
      width: 60px;
      height: 60px;
      animation: quick-quote-spin 1s linear infinite;
      z-index: -1;
    }

    .quick-quote-frame {
      background-color: ${brandingConfig.background.fillColor};
      width: 100%;
      height: 100%;
      border: 0;
    }

    .quick-quote-button-wrapper {
      position: absolute;
      width: 0;
      top: 50%;
      ${
        brandingConfig.roofQuoteProButton.horisontalPosition === 'left'
          ? `right: 0; transform: translateX(100%);`
          : `left: 0;`
      }
      -webkit-tap-highlight-color: transparent;
    }

    .animated.quick-quote-callout_open {
      animation: callout-appear 0.4s ease-out;
      animation-fill-mode: forwards;
    }

    .animated.quick-quote-callout_close {
      animation: callout-disappear 0.4s ease-in;
      animation-fill-mode: forwards;
    }

    .quick-quote-callout-text-block.closable.quick-quote-callout-text-block_open {
      display: ${brandingConfig.callout.showOnTabletsAndDesktops ? 'flex' : 'none'};
    }

    .quick-quote-callout-text-block.closable.quick-quote-callout-text-block_close {
      display: none;
    }

    .quick-quote-callout {
      display: ${brandingConfig.callout.showOnTabletsAndDesktops ? 'flex' : 'none'};
      column-gap: 24px;
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;
      ${
        brandingConfig.roofQuoteProButton.horisontalPosition === 'left'
          ? `transform: translate(calc(50% - 70px), -150%);`
          : `transform: translate(calc(-100% - 60px), -150%);`
      }
      border-radius: 6px;
      padding: 20px;
      background-color: ${brandingConfig.callout.fillColor || colors.white};
      border: 1px solid
        ${brandingConfig.callout.strokeColor || brandingConfig.roofQuoteProButton.fillColor};
      filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.14))
        drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.2));
    }

    .quick-quote-callout.oc-callout {
      filter: unset;
    }

    .quick-quote-callout.quick-quote-callout-left {
      flex-direction: row-reverse;
    }

    .quick-quote-callout.animated {
      opacity: 0;
      pointer-events: none;
    }

    .quick-quote-button.oc-button .quick-quote-callout {
      display: flex;
      left: unset;
      top: 100%;

      ${
        brandingConfig.roofQuoteProButton.horisontalPosition === 'left'
          ? `transform-origin: left top; transform: rotate(-90deg); left: 100%;`
          : `transform-origin: right top; transform: rotate(90deg); right: 100%;`
      }
    }

    .quick-quote-callout-text-block {
      align-items: center;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      min-width: 0;
      row-gap: 4px;
      width: 230px;
    }

    .quick-quote-button.oc-button .quick-quote-callout-text-block {
      width: 270px;
    }

    .quick-quote-callout-text-block.closable {
      display: none;
    }

    .quick-quote-callout-title {
      font-weight: ${qqCalloutTitleFontWeight};
      font-size: 22px;
      font-family: ${brandingConfig.callout.titleFont || brandingConfig.titles.font};
      line-height: 26px;
      color: ${brandingConfig.callout.titleFontColor || brandingConfig.titles.fontColor};
      word-break: break-word;
      ${truncateCSS};
      white-space: pre-wrap;
    }

    .quick-quote-button.oc-button .quick-quote-callout-title {
      font-size: 20px;
      line-height: 24px;
    }

    .quick-quote-callout-title:before {
      content: '${
        brandingConfig.callout.titleText.replace(/"/g, '\\"').replace(/\n/g, '') ||
        'Need a roof quote?'
      }';
    }

    .quick-quote-callout-text {
      font-size: 20px;
      font-family: ${brandingConfig.callout.descriptionTextFont || brandingConfig.titles.font};
      font-weight: normal;
      line-height: 22px;
      color: ${
        brandingConfig.callout.descriptionTextFontColor || brandingConfig.mainText.fontColor
      };
      word-break: break-word;
      ${truncateCSS}
    }

    .quick-quote-button.oc-button .quick-quote-callout-text {
      font-size: 16px;
      line-height: 18px;
    }

    .quick-quote-callout-text:before {
      content: '${
        brandingConfig.callout.descriptionText.replace(/"/g, '\\"').replace(/\n/g, '') ||
        'Get your price NOW!'
      }';
    }

    .quick-quote-callout.with-arrow:before {
      content: '';
      position: absolute;
      background-color: inherit;
      width: 17px;
      height: 17px;
      border-top: 1px solid;
      border-right: 1px solid;
      border-color: ${
        brandingConfig.callout.strokeColor || brandingConfig.roofQuoteProButton.fillColor
      };
      box-sizing: content-box;
      bottom: 12px;
      ${
        brandingConfig.roofQuoteProButton.horisontalPosition === 'left'
          ? `left: -10px; transform: rotate(45deg) scale(-1);`
          : `right: -10px; transform: rotate(45deg);`
      }
    }

    .quick-quote-button {
      position: absolute;
      display: flex;
      align-items: center;
      height: 43px;
      ${
        brandingConfig.roofQuoteProButton.horisontalPosition === 'left'
          ? `transform: translate(-50%, -100%) rotate(90deg);`
          : `transform: translate(-50%, -100%) rotate(270deg);`
      }
      transform-origin: bottom;
      background-color: ${brandingConfig.roofQuoteProButton.fillColor || colors.orange};
      color: ${brandingConfig.roofQuoteProButton.fontColor || colors.white};
      font-family: ${brandingConfig.roofQuoteProButton.font || brandingConfig.titles.font};
      font-weight: ${brandingConfig.roofQuoteProButton.fontWeight || 500};
      font-size: ${brandingConfig.roofQuoteProButton.fontSize || 22}px;
      line-height: 26px;
      padding: 0 1px;
      cursor: pointer;
      white-space: nowrap;
    }

    .quick-quote-button.oc-button {
      height: 34px;
      font-size: 16px;
      line-height: 19px;
    }

    .quick-quote-button_right-img {
      position: absolute;
      right: 1px;
      top: 0;
      transform: translateX(100%);
      width: unset;
      height: unset;
    }

    .quick-quote-button.oc-button .quick-quote-button_right-img {
      transform: translate(85%, 0%) scale(0.8);
      transform-origin: top;
    }

    .quick-quote-button.oc-button .quick-quote-button_left-img {
      transform: translate(-85%, 0%) scale(0.8);
      transform-origin: top;
    }

    .quick-quote-button_left-img {
      position: absolute;
      left: 1px;
      top: 0;
      transform: translateX(-100%);
      width: unset;
      height: unset;
    }

    #quick-quote-button_right-img-path,
    #quick-quote-button_left-img-path {
      fill: ${brandingConfig.roofQuoteProButton.fillColor || colors.orange};
    }

    .quick-quote-button::after {
      right: 0;
    }

   .quick-quote-close-icon {
      filter: ${colorFilters.mediumGrey};
    }

    .quick-quote-close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      z-index: 1;
      display: inline-flex;
      width: 14px;
      height: 14px;
    }

    .quick-quote-callout-close-partially {
      display: ${brandingConfig.callout.showOnTabletsAndDesktops ? 'initial' : 'none'};
    }

    .quick-quote-oc-button-text {
      ${
        brandingConfig.roofQuoteProButton.horisontalPosition === 'left'
          ? 'margin: 0 50px 0 20px'
          : 'margin: 0 20px 0 50px'
      }
    }

    ${getOcPantherScaledStyles(brandingConfig, { isSmallScreen: false })}

    .oc-callout .quick-quote-callout-image {
      ${setLimitedSize(
        `${Math.ceil((brandingConfig.callout.img?.width || 0) * DESKTOP_SCALE_VALUE)}px`,
        `${Math.ceil((brandingConfig.callout.img?.height || 0) * DESKTOP_SCALE_VALUE)}px`,
      )}
    }
    
    .oc-legal-text {
      color: ${brandingConfig.caption.fontColor};
      font-family: ${brandingConfig.caption.font};
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-top: 16px;
    }

    @media ${mediaQueries.small} {
      ${getOcCalloutMobileStyles(brandingConfig, { isSmallScreen: true })}

      .quick-quote-callout-image {
        ${setLimitedSize(
          `${Math.ceil((brandingConfig.callout.img?.width || 0) * MOBILE_SCALE_VALUE)}px`,
          `${Math.ceil((brandingConfig.callout.img?.height || 0) * MOBILE_SCALE_VALUE)}px`,
        )}
      }

      .quick-quote-callout-text-block.closable.quick-quote-callout-text-block_open {
        display: ${brandingConfig.callout.showOnPhones ? 'flex' : 'none'};
      }

      .quick-quote-callout-close-partially {
        display: ${brandingConfig.callout.showOnPhones ? 'initial' : 'none'};
      }

      .quick-quote-wrapper {
        width: 100%;
      }

      .quick-quote-close {
        top: 37px;
        right: 13px;
      }

      .quick-quote-callout {
        display: ${brandingConfig.callout.showOnPhones ? 'flex' : 'none'};

        ${
          brandingConfig.roofQuoteProButton.horisontalPosition === 'left'
            ? `transform: translate(calc(0% + 50px), -50%);`
            : `transform: translate(calc(-100% - 50px), -50%);`
        }
      }

      .quick-quote-drag-handle {
        height: 196px;
        width: 36px;
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        ${
          brandingConfig.roofQuoteProButton.horisontalPosition === 'left'
            ? `right: 36px; transform: translate(100%, -50%);`
            : `left: 0px;`
        }
      }

      .quick-quote-drag-handle-inner {
        margin: 0
          ${
            brandingConfig.roofQuoteProButton.horisontalPosition === 'left'
              ? '10px 0 22px'
              : '22px 0 10px'
          };
        height: 196px;
        width: 4px;
        background-color: #d6d1ca;
        border-radius: 11px;
      }

      ${rqpButtonMobileStyles}
    }

    @media ${mediaQueries.tablet} {
      @media ${orientation.landscape} {
        ${rqpButtonMobileStyles}
        ${getOcCalloutMobileStyles(brandingConfig, { isSmallScreen: false }, MOBILE_SCALE_VALUE)}
      }
    }
  `;

  document.head.appendChild(style);
};
