import { format } from 'date-fns';
import { intl } from 'intl';

import * as services from './services';
import * as constants from './constants';
import * as selectors from './selectors';
import * as actions from './actions';
import * as mutations from './mutations';
import {
  DefaultFinancingTypes,
  FinancingSetting,
  FinancingType,
  MomntItem,
  OfferCode,
} from './types';
import { defaultFinancing, NoFinancing, BasePackage, DEFAULT_FINANCING_TYPES } from './constants';
import { I18nEnum } from 'types';
import { SubscriptionCountry } from 'modules/subscription/types';

export { services, constants, selectors, actions, mutations };
export * from './types';
export * from './queries';

export const formatDate = (date: string) => format(new Date(date), 'MM-dd-yyyy | pp');

export const formatApplicationId = (applicationId: string) =>
  applicationId.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

export const getFinancingObject = (financing?: FinancingSetting | null) =>
  !financing
    ? defaultFinancing
    : {
        ...financing,
        months: financing.months || defaultFinancing.months,
        percentageRate:
          !financing.percentageRate && !financing.months
            ? defaultFinancing.percentageRate
            : financing.percentageRate,
      };

export const contactPhone = '1-800-470-3871';

export const getFinancingOption = (loanProductId: number, offerCodes: MomntItem<OfferCode>) => {
  const basePackage = intl.formatMessage({ id: I18nEnum.BasePackage });
  switch (loanProductId) {
    case NoFinancing:
      return intl.formatMessage({ id: I18nEnum.NoFinancing });
    case BasePackage:
      return basePackage;
    default:
      return (
        offerCodes.data.find(offerCode => offerCode.id === loanProductId && offerCode.isActive)
          ?.name || basePackage
      );
  }
};

export const filterCustomFinancingTypes = (financingTypes: FinancingType[]): FinancingType[] =>
  financingTypes?.reduce<FinancingType[]>((acc, financingType) => {
    if (!DefaultFinancingTypes[financingType.type]) {
      acc.push(financingType);
    }
    return acc;
  }, []);

export const filterDefaultFinancingTypeByCountry = (
  financingType: FinancingType[],
  _subscriptionCountry: SubscriptionCountry,
): FinancingType[] =>
  financingType.filter(({ type }) =>
    constants.DEFAULT_FINANCING_TYPES_CONFIG[_subscriptionCountry].includes(type),
  );

export const mergeDefaultFinancingTypes = (
  financingTypes: FinancingType[],
  isMomntConnected: boolean,
): FinancingType[] => {
  const defaultFinancingTypes = DEFAULT_FINANCING_TYPES.reduce<FinancingType[]>(
    (acc, defaultFinancingType) => {
      if (
        defaultFinancingType.type === DefaultFinancingTypes.ContractorLoanPro &&
        !isMomntConnected
      ) {
        return acc;
      }

      const savedFinancingType = financingTypes.find(
        ({ type }) => type === defaultFinancingType.type,
      );

      const financingType = savedFinancingType
        ? { ...savedFinancingType, logo: defaultFinancingType.logo }
        : defaultFinancingType;

      acc.push(financingType);
      return acc;
    },
    [],
  );

  return defaultFinancingTypes;
};

export const selectCompanyFinancingTypes = ({
  financingTypes,
  subscriptionCountry,
  isMomntConnected,
}: {
  financingTypes: FinancingType[];
  subscriptionCountry: SubscriptionCountry;
  isMomntConnected: boolean;
}): FinancingType[] => {
  const customFinancingTypes = filterCustomFinancingTypes(financingTypes);
  const defaultFinancingTypes = mergeDefaultFinancingTypes(financingTypes, isMomntConnected);
  const filteredDefaultFinancingTypes = filterDefaultFinancingTypeByCountry(
    defaultFinancingTypes,
    subscriptionCountry,
  );

  const fullArray = [...filteredDefaultFinancingTypes, ...customFinancingTypes];
  const endIndexToSlice = isMomntConnected ? 2 : 1;
  const alreadySortedFinancingTypes = fullArray.slice(0, endIndexToSlice);
  const financingTypesToSort = fullArray.slice(endIndexToSlice);
  const sortedFinancingTypes = financingTypesToSort.sort((prev, next) => {
    return prev.type.toLowerCase().localeCompare(next.type.toLowerCase());
  });
  return [...alreadySortedFinancingTypes, ...sortedFinancingTypes];
};

export const findFinancingTypesDuplicates = ({
  financingTypes,
  searchField,
  searchValue: _searchValue,
  filterSelectedFinancingType,
  selectedFinancingTypeId,
}: {
  financingTypes: FinancingType[];
  searchField: 'type' | 'url';
  searchValue: string;
  filterSelectedFinancingType: boolean;
  selectedFinancingTypeId?: string | number;
}): FinancingType[] =>
  financingTypes?.filter(_financingType => {
    let financingTypeValue = _financingType[searchField];
    let searchValue = _searchValue;

    if (searchField === 'type' && DefaultFinancingTypes[financingTypeValue]) {
      financingTypeValue = intl.formatMessage({ id: financingTypeValue });
    }

    if (searchField === 'url') {
      financingTypeValue = financingTypeValue.toLowerCase();
      searchValue = _searchValue.toLowerCase();
    }

    return (
      financingTypeValue &&
      financingTypeValue === searchValue.trim() &&
      (filterSelectedFinancingType ? _financingType.id !== selectedFinancingTypeId : true)
    );
  });
