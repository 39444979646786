import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { search, selectAddress, clear } from 'modules/search/actions';
import { GoogleSearchResultType } from 'modules/search';
import { ParcelState, actions } from 'modules/quickQuote';
import { AddressFeature } from 'types';
import { DEFAULT_GEODATA_STATE } from 'modules/quickQuote/constants';

const useFetchAddress = () => {
  const dispatch = useDispatch();

  const fetchAddress = useCallback(
    async ({ number, street1, city, state, postalCode }: AddressFeature) => {
      dispatch(clear());

      const features = (await dispatch(
        search(`${number ? `${number} ` : ''}${street1} ${city} ${state} ${postalCode}`),
      )) as unknown as GoogleSearchResultType[] | undefined;

      if (!(features && features[0])) {
        dispatch(actions.clearQuickQuote());
        dispatch(
          actions.setParcel({
            ...DEFAULT_GEODATA_STATE,
            parcelState: ParcelState.DeepLinkAddressError,
          }),
        );
        return;
      }

      dispatch(selectAddress(features[0]));
    },
    [dispatch],
  );

  return fetchAddress;
};

export default useFetchAddress;
