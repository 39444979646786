import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import { ModalState } from 'modules/modal/types';
import { MessageState } from 'modules/message/types';
import { SpinnerState } from 'modules/spinner/types';
import { UserState } from 'modules/auth/types';
import { AdminState } from 'modules/admin/types';
import { colorFilters, colors, elementsSizes, mediaQueries, orientation } from 'global-constants';
import { QuickQuoteState } from 'modules/quickQuote/types';
import { MapboxState } from 'modules/mapbox/types';
import { SearchState } from 'modules/search/types';
import { CompanyState } from 'modules/company/types';
import { WidgetSettingsState } from 'modules/widget/types';
import { ProductState, WidgetProductState } from 'modules/product/types';
import { MarketState } from 'modules/markets/types';
import { SnippetState } from 'modules/snippet/types';
import { FinancingState } from 'modules/financing/types';
import { AnalyticsReportsState } from 'modules/analytics/types';
import { QuickQuoteConfigState } from 'modules/quickQuoteConfig/types';
import { RepQuotesState } from 'modules/repQuotes/types';
import { GoogleMapsState } from 'modules/googleMaps/types';
import { SubscriptionState } from 'modules/subscription/types';

export enum I18nEnum {
  PleaseEnterAValidName = 'PleaseEnterAValidName',
  NotAValid10Digit = 'NotAValid10Digit',
  PleaseProvideValidEmailAddress = 'PleaseProvideValidEmailAddress',
  PasswordMaxLength = 'PasswordMaxLength',
  PleaseDontBeginOrEnd = 'PleaseDontBeginOrEnd',
  NoValidPassword = 'NoValidPassword',
  PleaseFillInAllRequieredFields = 'PleaseFillInAllRequieredFields',
  PleaseEnterAValidAddress = 'PleaseEnterAValidAddress',
  PleaseEnterAValidZipCode = 'PleaseEnterAValidZipCode',
  Yes = 'Yes',
  Cancel = 'Cancel',
  DontWorryThough = 'DontWorryThough',
  TryAgain = 'TryAgain',
  GetSupport = 'GetSupport',
  OopsShort = 'OopsShort',
  SomethingWentWrong = 'SomethingWentWrong',
  TalkToSales = 'TalkToSales',
  FirstName = 'FirstName',
  LastName = 'LastName',
  EmailReq = 'EmailReq',
  PhoneReq = 'PhoneReq',
  FirstNameNotReq = 'FirstNameNotReq',
  LastNameNotReq = 'LastNameNotReq',
  Email = 'Email',
  PersonalEmail = 'PersonalEmail',
  PleaseEnterYourNewPersonalEmail = 'PleaseEnterYourNewPersonalEmail',
  Phone = 'Phone',
  Notes = 'Notes',
  NotesReq = 'NotesReq',
  EmailOrPasswordIncorrect = 'EmailOrPasswordIncorrect',
  Continue = 'Continue',
  Password = 'Password',
  PasswordReq = 'PasswordReq',
  Oops = 'Oops',
  PersonalInformation = 'PersonalInformation',
  Name = 'Name',
  Security = 'Security',
  LastUpdated = 'LastUpdated',
  Edit = 'Edit',
  DeleteAccount = 'DeleteAccount',
  AreYouSureYouWantDeleteAccount = 'AreYouSureYouWantDeleteAccount',
  IfYouDeleteAccount = 'IfYouDeleteAccount',
  NewPassword = 'NewPassword',
  InvalidCurrentPassword = 'InvalidCurrentPassword',
  ResetYourPassword = 'ResetYourPassword',
  NewPasswordMustBeDifferent = 'NewPasswordMustBeDifferent',
  PasswordsDoNotMatch = 'PasswordsDoNotMatch',
  SuccessfullPasswordReset = 'SuccessfullPasswordReset',
  CurrentPassword = 'CurrentPassword',
  ConfirmNewPassword = 'ConfirmNewPassword',
  UpdatePassword = 'UpdatePassword',
  Show = 'Show',
  Hide = 'Hide',
  Help = 'Help',
  StructureValidation = 'StructureValidation',
  MapNoSelectionHelpText = 'MapNoSelectionHelpText',
  MapShapeSelectedHelpText = 'MapShapeSelectedHelpText',
  MapVertexSelectedHelpText = 'MapVertexSelectedHelpText',
  MapDrawShapeHelpText = 'MapDrawShapeHelpText',
  MapDrawShapeContinueHelpText = 'MapDrawShapeContinueHelpText',
  MapDrawShapeCloseHelpText = 'MapDrawShapeCloseHelpText',
  DeleteSelectedItem = 'DeleteSelectedItem',
  DrawNewRoofShape = 'DrawNewRoofShape',
  HmmWeMissedSomething = 'HmmWeMissedSomething',
  YouCanManuallyDrawYourRoofShapeOrClickGetHelp = 'YouCanManuallyDrawYourRoofShapeOrClickGetHelp',
  ClickAddRoofNowToDraw = 'ClickAddRoofNowToDraw',
  GetHelp = 'GetHelp',
  ClickAddRoofNowToBeginDrawing = 'ClickAddRoofNowToBeginDrawing',
  OneRoofIsRequiredToContinueEstimate = 'OneRoofIsRequiredToContinueEstimate',
  EditRoofs = 'EditRoofs',
  AddRoofNow = 'AddRoofNow',
  SeeMyPrice = 'SeeMyPrice',
  SaveChanges = 'SaveChanges',
  WhatWillMyRoofCost = 'WhatWillMyRoofCost',
  EnterYourAddressToSee = 'EnterYourAddressToSee',
  EnterYourAddress = 'EnterYourAddress',
  CalculatePrice = 'CalculatePrice',
  ICantFindMyAddress = 'ICantFindMyAddress',
  NoAddressFoundTooltip = 'NoAddressFoundTooltip',
  NoAddressFound = 'NoAddressFound',
  ContactUs = 'ContactUs',
  EnterYourStreetAddressToGetAnEstimateInstantly = 'EnterYourStreetAddressToGetAnEstimateInstantly',
  Start = 'Start',
  NoResults = 'NoResults',
  SorryNotingMatch = 'SorryNotingMatch',
  CompanyName = 'CompanyName',
  CompanyNameReq = 'CompanyNameReq',
  Save = 'Save',
  YourSessionWillExpire = 'YourSessionWillExpire',
  YourSessionHasExpired = 'YourSessionHasExpired',
  DueToInactivity = 'DueToInactivity',
  YouWillLoggedOut = 'YouWillLoggedOut',
  DoYouWantToStaySignedIn = 'DoYouWantToStaySignedIn',
  KeepMeSignedIn = 'KeepMeSignedIn',
  PleaseFillInTheObligatory = 'PleaseFillInTheObligatory',
  SignIn = 'SignIn',
  GeneralSettings = 'GeneralSettings',
  Settings = 'Settings',
  ProductCardCustomization = 'ProductCardCustomization',
  Widget = 'Widget',
  ToolScripts = 'ToolScripts',
  TextIconsIllustrations = 'TextIconsIllustrations',
  Controls = 'Controls',
  ControlsHint = 'ControlsHint',
  Titles = 'Titles',
  TitlesSettingDescription = 'TitlesSettingDescription',
  MainText = 'MainText',
  MainTextSettingDescription = 'MainTextSettingDescription',
  SecondaryText = 'SecondaryText',
  SecondaryTextDescription = 'SecondaryTextDescription',
  Icons = 'Icons',
  RoofBordersColor = 'RoofBordersColor',
  TooltipAndMapControls = 'TooltipAndMapControls',
  TooltipAndMapControlsDescription = 'TooltipAndMapControlsDescription',
  IllustrationSlopePreview = 'IllustrationSlopePreview',
  WidgetBackground = 'WidgetBackground',
  MainButton = 'MainButton',
  SecondaryButton = 'SecondaryButton',
  Checkbox = 'Checkbox',
  Color = 'Color',
  FillColor = 'FillColor',
  StrokeColor = 'StrokeColor',
  FontColor = 'FontColor',
  Font = 'Font',
  CornerRadius = 'CornerRadius',
  UnselectedColor = 'UnselectedColor',
  SelectedColor = 'SelectedColor',
  ResetSettingsToDefault = 'ResetSettingsToDefault',
  PreviewWidget = 'PreviewWidget',
  FontWeight = 'FontWeight',
  FontSize = 'FontSize',
  EditYourRoofNameAndSlope = 'EditYourRoofNameAndSlope',
  SignOut = 'SignOut',
  AdminDashboard = 'AdminDashboard',
  CountOfItems = 'CountOfItems',
  ResendPassword = 'ResendPassword',
  AddSubscription = 'AddSubscription',
  PauseSubscription = 'PauseSubscription',
  ProceedSubscription = 'ProceedSubscription',
  DeleteSubscription = 'DeleteSubscription',
  DeleteCompany = 'DeleteCompany',
  PleaseSelectTheDateRange = 'PleaseSelectTheDateRange',
  SubscriptionHasBeenSaved = 'SubscriptionHasBeenSaved',
  UnfortunatelyTheSubscription = 'UnfortunatelyTheSubscription',
  AreYouSureDelete = 'AreYouSureDelete',
  BeAwareTheRecordWillBeDeleted = 'BeAwareTheRecordWillBeDeleted',
  AreYouSurePause = 'AreYouSurePause',
  TheSubscriptionWillBePaused = 'TheSubscriptionWillBePaused',
  AreYouSureProceed = 'AreYouSureProceed',
  TheSubscriptionWillBeProceeded = 'TheSubscriptionWillBeProceeded',
  Proceed = 'Proceed',
  Pause = 'Pause',
  RecordHasBeen = 'RecordHasBeen',
  UnfortunatelyTheRecord = 'UnfortunatelyTheRecord',
  SubscriptionHasBeenPaused = 'SubscriptionHasBeenPaused',
  UnfortunatelySubscriptionHasNotBeenPaused = 'UnfortunatelySubscriptionHasNotBeenPaused',
  SubscriptionHasBeenProceeded = 'SubscriptionHasBeenProceeded',
  UnfortunatelySubscriptionHasNotBeenProceeded = 'UnfortunatelySubscriptionHasNotBeenProceeded',
  Type = 'Type',
  Warranty = 'Warranty',
  TopFeatures = 'TopFeatures',
  PriceForSquare = 'PriceForSquare',
  PriceAverageInfo = 'PriceAverageInfo',
  SeeInstallationPackageDetails = 'SeeInstallationPackageDetails',
  WhatIncluded = 'WhatIncluded',
  AllRooflePricingIncludes = 'AllRooflePricingIncludes',
  RemoveExistingShingles = 'RemoveExistingShingles',
  InstallIceAndWater = 'InstallIceAndWater',
  InstallUnderlayment = 'InstallUnderlayment',
  InstallSelectRoofingProduct = 'InstallSelectRoofingProduct',
  InstallHipRidge = 'InstallHipRidge',
  ReplaceAllExisting = 'ReplaceAllExisting',
  InstallNewPipeAndChimney = 'InstallNewPipeAndChimney',
  CleanUpAllJob = 'CleanUpAllJob',
  CityPermitIsIncluded = 'CityPermitIsIncluded',
  AllRoofleRoofingCrewsAreLicensed = 'AllRoofleRoofingCrewsAreLicensed',
  InAdditionToYourProductWarranties = 'InAdditionToYourProductWarranties',
  AdditionalCosts = 'AdditionalCosts',
  ProductDetailLink = 'ProductDetailLink',
  TheFollowingItemsAreNotIncluded = 'TheFollowingItemsAreNotIncluded',
  RoofIsMoreThan1 = 'RoofIsMoreThan1',
  PleaseSelectSubscriptions = 'PleaseSelectSubscriptions',
  SubscriptionHasBeen = 'SubscriptionHasBeen',
  UnfortunatelySubscriptionHasNotDeleted = 'UnfortunatelySubscriptionHasNotDeleted',
  SubscriptionsHaveBeen = 'SubscriptionsHaveBeen',
  UnfortunatelySubscriptionsHaveNotDeleted = 'UnfortunatelySubscriptionsHaveNotDeleted',
  AddCompany = 'AddCompany',
  Delete = 'Delete',
  SymbolsLeft = 'SymbolsLeft',
  PleaseFillInCompanysDetailsToCreate = 'PleaseFillInCompanysDetailsToCreate',
  PleaseFillInCompanysDetails = 'PleaseFillInCompanysDetails',
  NotObligatory = 'NotObligatory',
  NewRecordHasBeenAdded = 'NewRecordHasBeenAdded',
  UnfortunatelyRecordHasNotBeenCreated = 'UnfortunatelyRecordHasNotBeenCreated',
  Website = 'Website',
  WebsiteReq = 'WebsiteReq',
  PleaseEnterAValidWebsite = 'PleaseEnterAValidWebsite',
  PleaseEnterAValidWebsiteNoLimit = 'PleaseEnterAValidWebsiteNoLimit',
  PleaseUploadContractorsLogo = 'PleaseUploadContractorsLogo',
  OnlyPngJpg = 'OnlyPngJpg',
  DragAndDrop = 'DragAndDrop',
  PleaseEnterValidCompanyName = 'PleaseEnterValidCompanyName',
  CompanyProfile = 'CompanyProfile',
  Company = 'Company',
  CompanyEmail = 'CompanyEmail',
  CompanyPrimaryContact = 'CompanyPrimaryContact',
  YouDontHaveAnyCompanyContacts = 'YouDontHaveAnyCompanyContacts',
  PleaseEnterNewWebsiteAddress = 'PleaseEnterNewWebsiteAddress',
  WebsiteChangesHasBeen = 'WebsiteChangesHasBeen',
  UnfortunatelyTheWebsite = 'UnfortunatelyTheWebsite',
  PleaseEnterNewCompanyEmail = 'PleaseEnterNewCompanyEmail',
  CompanyEmailHasBeen = 'CompanyEmailHasBeen',
  UnfortunatelyTheCompanyEmail = 'UnfortunatelyTheCompanyEmail',
  RequestToDeleteCompanyAccount = 'RequestToDeleteCompanyAccount',
  NewUserHasBeen = 'NewUserHasBeen',
  UnfortunatelyTheNewUser = 'UnfortunatelyTheNewUser',
  UserChangesHasBeen = 'UserChangesHasBeen',
  UnfortunatelyTheUserChanges = 'UnfortunatelyTheUserChanges',
  UsersPermissionToAccessClp = 'UsersPermissionToAccessClp',
  JobTitleReq = 'JobTitleReq',
  PleaseEnterAValidJobTitle = 'PleaseEnterAValidJobTitle',
  PhoneNumber = 'PhoneNumber',
  AreYouSureYouWantDeleteContact = 'AreYouSureYouWantDeleteContact',
  BeAwareTheSelectedContact = 'BeAwareTheSelectedContact',
  DeleteContact = 'DeleteContact',
  ContactHasBeenDeleted = 'ContactHasBeenDeleted',
  UnfortunatelyYheContact = 'UnfortunatelyYheContact',
  AreYouSureYouWantResendPassword = 'AreYouSureYouWantResendPassword',
  ANewPasswordWillBeSentToTheirEmail = 'ANewPasswordWillBeSentToTheirEmail',
  PasswordHasBeenResent = 'PasswordHasBeenResent',
  UnfortunatelyThePasswordHasNotBeenResent = 'UnfortunatelyThePasswordHasNotBeenResent',
  TheContractorWillRecieve = 'TheContractorWillRecieve',
  Resend = 'Resend',
  Products = 'Products',
  Product = 'Product',
  AllProducts = 'AllProducts',
  EditLabel = 'EditLabel',
  Label = 'Label',
  LabelShouldBeUnique = 'LabelShouldBeUnique',
  ForgotPassword = 'ForgotPassword',
  EnterTheEmailAddress = 'EnterTheEmailAddress',
  EmailIsNotFound = 'EmailIsNotFound',
  RequestResetLink = 'RequestResetLink',
  BackToSignIn = 'BackToSignIn',
  WeSentYouPasswordResetLink = 'WeSentYouPasswordResetLink',
  TimeToCheckYourInbox = 'TimeToCheckYourInbox',
  ResendPasswordReset = 'ResendPasswordReset',
  YourPasswordResetLinkHasBeen = 'YourPasswordResetLinkHasBeen',
  UnfortunatelyThePasswordResetLink = 'UnfortunatelyThePasswordResetLink',
  YourConfirmationEmailHasBeen = 'YourConfirmationEmailHasBeen',
  UnfortunatelyTheConfirmationEmail = 'UnfortunatelyTheConfirmationEmail',
  YouCanNowUse = 'YouCanNowUse',
  HeyIts = 'HeyIts',
  WeAreSorryLinkExpired = 'WeAreSorryLinkExpired',
  PleaseClickToResend = 'PleaseClickToResend',
  LabelError = 'LabelError',
  AreYouSureSendADeletion = 'AreYouSureSendADeletion',
  AfterRequestSending = 'AfterRequestSending',
  SendRequest = 'SendRequest',
  YourRequestHasBeenSent = 'YourRequestHasBeenSent',
  UnfortunatelyTheRequestHasNot = 'UnfortunatelyTheRequestHasNot',
  YouDeletionRequest = 'YouDeletionRequest',
  MobileNotSupported = 'MobileNotSupported',
  MobileNotSupportedBrandingDescription = 'MobileNotSupportedBrandingDescription',
  AlmostDone = 'AlmostDone',
  AlmostDoneExclamationMark = 'AlmostDoneExclamationMark',
  Back = 'Back',
  ShowMyPrice = 'ShowMyPrice',
  FillInYourDetailsToSeeQuoteInstantly = 'FillInYourDetailsToSeeQuoteInstantly',
  RecommendedProducts = 'RecommendedProducts',
  Dashboard = 'Dashboard',
  MyProfile = 'MyProfile',
  Billing = 'Billing',
  Reset = 'Reset',
  JobTitle = 'JobTitle',
  YourNameSentSuccessfully = 'YourNameSentSuccessfully',
  UnfortunatelyYourName = 'UnfortunatelyYourName',
  YourPhoneSentSuccessfully = 'YourPhoneSentSuccessfully',
  UnfortunatelyYourPhone = 'UnfortunatelyYourPhone',
  YourJobTitleSentSuccessfully = 'YourJobTitleSentSuccessfully',
  UnfortunatelyYourJobTitle = 'UnfortunatelyYourJobTitle',
  PersonalEmailChangesHaveBeenSavedSuccessfully = 'PersonalEmailChangesHaveBeenSavedSuccessfully',
  UnfortunatelyYourEmail = 'UnfortunatelyYourEmail',
  AreYouSureYouWantToResetSettings = 'AreYouSureYouWantToResetSettings',
  AllSettingsWillBeResetToTheDefault = 'AllSettingsWillBeResetToTheDefault',
  NoKeepAsIs = 'NoKeepAsIs',
  YesReset = 'YesReset',
  Ok = 'Ok',
  YourChangesHaveBeenSavedSuccessfully = 'YourChangesHaveBeenSavedSuccessfully',
  DisableThisMarket = 'DisableThisMarket',
  AllYourSettingsConfigurationHaveBeenAppliedToTheWidget = 'AllYourSettingsConfigurationHaveBeenAppliedToTheWidget',
  AllYourCardsChangesHaveBeenAppliedToTheWidget = 'AllYourCardsChangesHaveBeenAppliedToTheWidget',
  StartDate = 'StartDate',
  EndDate = 'EndDate',
  Status = 'Status',
  Active = 'Active',
  Disabled = 'Disabled',
  Deactivated = 'Deactivated',
  Inactive = 'Inactive',
  Expired = 'Expired',
  Upcoming = 'Upcoming',
  ActivateSubscription = 'ActivateSubscription',
  AreYouSureActivate = 'AreYouSureActivate',
  AreYouSureCancel = 'AreYouSureCancel',
  AfterSendingRequestYourAccountManagerWillContactYou = 'AfterSendingRequestYourAccountManagerWillContactYou',
  NA = 'NA',
  PleaseTellUs = 'PleaseTellUs',
  IDontThink = 'IDontThink',
  ItsTooExpensive = 'ItsTooExpensive',
  ItDidntWorkProperly = 'ItDidntWorkProperly',
  IDontWant = 'IDontWant',
  IOnlyNeeded = 'IOnlyNeeded',
  OtherPleaseSpecify = 'OtherPleaseSpecify',
  Submit = 'Submit',
  ActivationRequested = 'ActivationRequested',
  YourActivation = 'YourActivation',
  PauseRequested = 'PauseRequested',
  YourCancelation = 'YourCancelation',
  ToolID = 'ToolID',
  ToolIDHint = 'ToolIDHint',
  UnfortunatelyYourPasswordNotChanged = 'UnfortunatelyYourPasswordNotChanged',
  YourPasswordHasBeenChanged = 'YourPasswordHasBeenChanged',
  PleaseEnterAValidWidgetId = 'PleaseEnterAValidWidgetId',
  SelectTheProducts = 'SelectTheProducts',
  Manufacturer = 'Manufacturer',
  Grade = 'Grade',
  AsphaltShingle = 'AsphaltShingle',
  Wood = 'Wood',
  Tile = 'Tile',
  Metal = 'Metal',
  Synthetic = 'Synthetic',
  Architectural = 'Architectural',
  Designer = 'Designer',
  Specialty = 'Specialty',
  All = 'All',
  AllWithTotal = 'AllWithTotal',
  ClearAllFilters = 'ClearAllFilters',
  SeeRoofleCatalog = 'SeeRoofleCatalog',
  SendStatisticsSuccessMessage = 'SendStatisticsSuccessMessage',
  SendStatisticsFailMessage = 'SendStatisticsFailMessage',
  AreYouSureYouWantDeleteRoof = 'AreYouSureYouWantDeleteRoof',
  AllSelectedProducts = 'AllSelectedProducts',
  UnfortunatelyYourChangesHaveNotBeen = 'UnfortunatelyYourChangesHaveNotBeen',
  YourChangesHaveNotBeen = 'YourChangesHaveNotBeen',
  AreYouSureLeave = 'AreYouSureLeave',
  NoBackToThe = 'NoBackToThe',
  Filters = 'Filters',
  OurTechnicalSupport = 'OurTechnicalSupport',
  Paused = 'Paused',
  Market = 'Market',
  MarketReq = 'MarketReq',
  MarketIsRequired = 'MarketIsRequired',
  MarketOrStateRequired = 'MarketOrStateRequired',
  NoWorriesLetsTry = 'NoWorriesLetsTry',
  PleaseFillInYourDetailsAndWeWillProvide = 'PleaseFillInYourDetailsAndWeWillProvide',
  PreferredMethodOfContact = 'PreferredMethodOfContact',
  SymbolsLeftToEnter = 'SymbolsLeftToEnter',
  YourMessageHasBeenSent = 'YourMessageHasBeenSent',
  UnfortunatelyTheEmailHasNotBeen = 'UnfortunatelyTheEmailHasNotBeen',
  ServiceArea = 'ServiceArea',
  NationalServiceArea = 'NationalServiceArea',
  NationalCoverage = 'NationalCoverage',
  StateList = 'StateList',
  StateListText = 'StateListText',
  StateName = 'StateName',
  Radius = 'Radius',
  StateWithPluralization = 'StateWithPluralization',
  UncheckAnyStateIncludedInYourServiceArea = 'UncheckAnyStateIncludedInYourServiceArea',
  YourAccountHasANationalLicense = 'YourAccountHasANationalLicense',
  SlideOutScriptDescription = 'SlideOutScriptDescription',
  FullPageFrameDescription = 'FullPageFrameDescription',
  SlideoutRoofQuoteProScript = 'SlideoutRoofQuoteProScript',
  EmbeddedRoofQuoteProScript = 'EmbeddedRoofQuoteProScript',
  UnfortunatelyYourChangesHasNotBeenSaved = 'UnfortunatelyYourChangesHasNotBeenSaved',
  EditCompany = 'EditCompany',
  YourAddressIsNotWhithinOurServiceArea = 'YourAddressIsNotWhithinOurServiceArea',
  SqftOfRoof = 'SqftOfRoof',
  EmbeddedWidget = 'EmbeddedWidget',
  Background = 'Background',
  ExistingCompany = 'ExistingCompany',
  NewCompany = 'NewCompany',
  CompanyNameIsARequired = 'CompanyNameIsARequired',
  IWouldStillLikeAQuote = 'IWouldStillLikeAQuote',
  Leads = 'Leads',
  OtherContacts = 'OtherContacts',
  WidgetIsTemporarilyUnavailable = 'WidgetIsTemporarilyUnavailable',
  PleaseContactUsToReceiveYourRoofEstimate = 'PleaseContactUsToReceiveYourRoofEstimate',
  InstantRoofQuoteButton = 'InstantRoofQuoteButton',
  Left = 'Left',
  Right = 'Right',
  Location = 'Location',
  Domain = 'Domain',
  PleaseProvideAValidDomain = 'PleaseProvideAValidDomain',
  DeleteDomain = 'DeleteDomain',
  AddNewDomain = 'AddNewDomain',
  NItemsSelected = 'NItemsSelected',
  State = 'State',
  ViewMore = 'ViewMore',
  ViewLess = 'ViewLess',
  YourDomainMustBe = 'YourDomainMustBe',
  YourPrimaryContactMustHaveAccount = 'YourPrimaryContactMustHaveAccount',
  headTitle = 'headTitle',
  ToUpdateYourServiceArea = 'ToUpdateYourServiceArea',
  Markets = 'Markets',
  States = 'States',
  AvailableMarkets = 'AvailableMarkets',
  YourMarketSelection = 'YourMarketSelection',
  RemoveDisabledMarketNote = 'RemoveDisabledMarketNote',
  AvailableStates = 'AvailableStates',
  YourStateSelection = 'YourStateSelection',
  AddAllSelected = 'AddAllSelected',
  MarketSelection = 'MarketSelection',
  StateSelection = 'StateSelection',
  SendTheRequest = 'SendTheRequest',
  NationalLicense = 'NationalLicense',
  AllMarkets = 'AllMarkets',
  RemoveNote = 'RemoveNote',
  KeepActive = 'KeepActive',
  ThisNationalLicenseWillBeRemoved = 'ThisNationalLicenseWillBeRemoved',
  ByRemovingYourNationalLicense = 'ByRemovingYourNationalLicense',
  MarketsIncludedInTheStateLicenseWillBeRemoved = 'MarketsIncludedInTheStateLicenseWillBeRemoved',
  ThisStatesWillBeRemovedDuringWorkingHours = 'ThisStatesWillBeRemovedDuringWorkingHours',
  ToRemoveThisMarket = 'ToRemoveThisMarket',
  ThisMarketWillBeRemovedDuringWorkingHours = 'ThisMarketWillBeRemovedDuringWorkingHours',
  ToContinueYourSubscription = 'ToContinueYourSubscription',
  NationalLicenseRemoveNote = 'NationalLicenseRemoveNote',
  NationalLicenseNote = 'NationalLicenseNote',
  AllStatesNationalLicense = 'AllStatesNationalLicense',
  IfYouNoLongerNeedANationalLicense = 'IfYouNoLongerNeedANationalLicense',
  YouShouldAddTheMarketAndOrState = 'YouShouldAddTheMarketAndOrState',
  YouShouldAddTheMarket = 'YouShouldAddTheMarket',
  MarketsAndStatesWillBeReset = 'MarketsAndStatesWillBeReset',
  NoActiveStates = 'NoActiveStates',
  RequestNewProduct = 'RequestNewProduct',
  ProductName = 'ProductName',
  RequestANewProduct = 'RequestANewProduct',
  IfThereIsAProductWeDontCurrentlyHaveInOutCatalog = 'IfThereIsAProductWeDontCurrentlyHaveInOutCatalog',
  RoofleAdmin = 'RoofleAdmin',
  SearchContractor = 'SearchContractor',
  EditTextButton = 'EditTextButton',
  SeeDefaultDescription = 'SeeDefaultDescription',
  AddNewLabel = 'AddNewLabel',
  PleaseFillTheNameOfNewLabel = 'PleaseFillTheNameOfNewLabel',
  LabelNameReq = 'LabelNameReq',
  PleaseEnterAValidLabel = 'PleaseEnterAValidLabel',
  DisplayOrder = 'DisplayOrder',
  Add = 'Add',
  WarningThisProduct = 'WarningThisProduct',
  PleaseChangeTheProductAfter = 'PleaseChangeTheProductAfter',
  PleaseChangeTheProduct = 'PleaseChangeTheProduct',
  AreYouSureDeleteProduct = 'AreYouSureDeleteProduct',
  IfYouDeleteTheProduct = 'IfYouDeleteTheProduct',
  FieldMaxLengthLimitError = 'FieldMaxLengthLimitError',
  Snippet = 'Snippet',
  Price = 'Price',
  ListOf = 'ListOf',
  No = 'No',
  AreYouSureYouWantToDeleteSnippet = 'AreYouSureYouWantToDeleteSnippet',
  ThisActionCannotBeUndone = 'ThisActionCannotBeUndone',
  AddNewSnippet = 'AddNewSnippet',
  AddPriceFromHistory = 'AddPriceFromHistory',
  EditSnippet = 'EditSnippet',
  BackToTheSnippetList = 'BackToTheSnippetList',
  ClickToMakeVisible = 'ClickToMakeVisible',
  ClickToMakeInvisible = 'ClickToMakeInvisible',
  FieldIsNotValid = 'FieldIsNotValid',
  BackgroundContrastError = 'BackgroundContrastError',
  SaveAsSnippet = 'SaveAsSnippet',
  AddFromSnippet = 'AddFromSnippet',
  ExistingSnippet = 'ExistingSnippet',
  ChooseExistingSnippetToProduct = 'ChooseExistingSnippetToProduct',
  WarninigYouCantSave = 'WarninigYouCantSave',
  SnippetHasBeenAddedSuccessfully = 'SnippetHasBeenAddedSuccessfully',
  UnfortunatelySnippetHasNotBeenCreated = 'UnfortunatelySnippetHasNotBeenCreated',
  SnippetName = 'SnippetName',
  ProductImagesUploadHint = 'ProductImagesUploadHint',
  UploadPhoto = 'UploadPhoto',
  MainPhoto = 'MainPhoto',
  RQPLeadsAreAll = 'RQPLeadsAreAll',
  OtherContactsAreAll = 'OtherContactsAreAll',
  CompanyPhoneReq = 'CompanyPhoneReq',
  CompanyPhone = 'CompanyPhone',
  PleaseEnterNewCompanyPhone = 'PleaseEnterNewCompanyPhone',
  ResendApprovalEmail = 'ResendApprovalEmail',
  ApprovalEmailWasResendSuccessfully = 'ApprovalEmailWasResendSuccessfully',
  ToSendDetailsFromRepQuotes = 'ToSendDetailsFromRepQuotes',
  ToSendEmailsFromYourPersonalEmailVerifyEmail = 'ToSendEmailsFromYourPersonalEmailVerifyEmail',
  ToSendEmails = 'ToSendEmails',
  Callout = 'Callout',
  EditCalloutTitle = 'EditCalloutTitle',
  EditCalloutText = 'EditCalloutText',
  EditCalloutTabText = 'EditCalloutTabText',
  CompanyPhoneChanges = 'CompanyPhoneChanges',
  UnfortunatelyTheCompanyPhone = 'UnfortunatelyTheCompanyPhone',
  ThreeTab = 'ThreeTab',
  CompanyNameChanges = 'CompanyNameChanges',
  UnfortunatelyTheCompanyName = 'UnfortunatelyTheCompanyName',
  CompanyWithThisName = 'CompanyWithThisName',
  PleaseEnterYourNewCompany = 'PleaseEnterYourNewCompany',
  RoofleCatalog = 'RoofleCatalog',
  MyCatalog = 'MyCatalog',
  ThereIsNothingHereYet = 'ThereIsNothingHereYet',
  NoDataAvailable = 'NoDataAvailable',
  PressCreateProductCard = 'PressCreateProductCard',
  PressRequestNewProduct = 'PressRequestNewProduct',
  CreateProductCard = 'CreateProductCard',
  EnterProductName = 'EnterProductName',
  EnterType = 'EnterType',
  EnterWarranty = 'EnterWarranty',
  EnterTopFeatures = 'EnterTopFeatures',
  EnterWhatsIncluded = 'EnterWhatsIncluded',
  EnterAdditionalCosts = 'EnterAdditionalCosts',
  FixedPrice = 'FixedPrice',
  CustomProductImagesUploadHint = 'CustomProductImagesUploadHint',
  YouProductHasBeenCreated = 'YouProductHasBeenCreated',
  UnfortunatelyTheProduct = 'UnfortunatelyTheProduct',
  DeveloperSettings = 'DeveloperSettings',
  Developer = 'Developer',
  Webhooks = 'Webhooks',
  Method = 'Method',
  Post = 'Post',
  ItSendsTheDataAfterMainContactFormCompleted = 'ItSendsTheDataAfterMainContactFormCompleted',
  ItSendsTheDataAfterProductRequest = 'ItSendsTheDataAfterProductRequest',
  JSONExample = 'JSONExample',
  OpenSwaggerDocumentation = 'OpenSwaggerDocumentation',
  InvalidWebhookURL = 'InvalidWebhookURL',
  YourWebhookURLHaveBeenSaved = 'YourWebhookURLHaveBeenSaved',
  DataWillNowBeSent = 'DataWillNowBeSent',
  PleaseEnterAValidType = 'PleaseEnterAValidType',
  TheStructureWasSelected = 'TheStructureWasSelected',
  TheStructureWasntSelected = 'TheStructureWasntSelected',
  Warranties = 'Warranties',
  Prices = 'Prices',
  Create = 'Create',
  MainTool = 'MainTool',
  RQPLeadsForm = 'RQPLeadsForm',
  OtherContactsForm = 'OtherContactsForm',
  Feature = 'Feature',
  LegalText = 'LegalText',
  ContactUsLegalText = 'ContactUsLegalText',
  LegalTextHint = 'LegalTextHint',
  LegalTextAreaPlaceholder = 'LegalTextAreaPlaceholder',
  TextArea = 'TextArea',
  Financing = 'Financing',
  FinancingSettings = 'FinancingSettings',
  TheLengthOfFinancing = 'TheLengthOfFinancing',
  AnnualPercentageRate = 'AnnualPercentageRate',
  DescriptionThatCustomers = 'DescriptionThatCustomers',
  ApplyToOtherMarkets = 'ApplyToOtherMarkets',
  ApplyFinancingSettingsToOtherMarkets = 'ApplyFinancingSettingsToOtherMarkets',
  AdditionalProductCardsFunctionality = 'AdditionalProductCardsFunctionality',
  AddressOnly = 'AddressOnly',
  ThereAreAddressesWhich = 'ThereAreAddressesWhich',
  ShowPrices = 'ShowPrices',
  YouShouldSelectAtLeast = 'YouShouldSelectAtLeast',
  Total = 'Total',
  PerMonth = 'PerMonth',
  Apply = 'Apply',
  Switch = 'Switch',
  ForMarkets = 'ForMarkets',
  ChangesHaveBeenApplied = 'ChangesHaveBeenApplied',
  UnfortunatelyTheChanges = 'UnfortunatelyTheChanges',
  TheStructureWasDeleted = 'TheStructureWasDeleted',
  ThisIsUsedToCalculate = 'ThisIsUsedToCalculate',
  ClosePreview = 'ClosePreview',
  ThankYouScreen = 'ThankYouScreen',
  ThankYouScreenHint = 'ThankYouScreenHint',
  EditTitle = 'EditTitle',
  UploadMedia = 'UploadMedia',
  EditSubtitle = 'EditSubtitle',
  EditExampleText = 'EditExampleText',
  EditButtonText = 'EditButtonText',
  URL = 'URL',
  URLReq = 'URLReq',
  GreatChoice = 'GreatChoice',
  WeHaveEmailedYouProductDetails = 'WeHaveEmailedYouProductDetails',
  YourSelectedProductOrService = 'YourSelectedProductOrService',
  ShowOtherProducts = 'ShowOtherProducts',
  HideOtherProducts = 'HideOtherProducts',
  YouCanUploadImage = 'YouCanUploadImage',
  Catalog = 'Catalog',
  SelectedProducts = 'SelectedProducts',
  Customize = 'Customize',
  ThankYouScreenUploadHint = 'ThankYouScreenUploadHint',
  EditCallToAction = 'EditCallToAction',
  BackToCatalog = 'BackToCatalog',
  DeletePhoto = 'DeletePhoto',
  YourChangesWillBeSavedAfter = 'YourChangesWillBeSavedAfter',
  DeleteProduct = 'DeleteProduct',
  EditProduct = 'EditProduct',
  AreYouSureDeleteProductName = 'AreYouSureDeleteProductName',
  ThisProductCard = 'ThisProductCard',
  ProductCardHasBeenDeleted = 'ProductCardHasBeenDeleted',
  UnfortunatelyProductCard = 'UnfortunatelyProductCard',
  EditProductCard = 'EditProductCard',
  MonthlyPriceBasedOn = 'MonthlyPriceBasedOn',
  MonthlyPaymentEstimates = 'MonthlyPaymentEstimates',
  Analytics = 'Analytics',
  TotalWebsiteLeads = 'TotalWebsiteLeads',
  TotalOtherContacts = 'TotalOtherContacts',
  TotalAddressOnlyLeads = 'TotalAddressOnlyLeads',
  AddressEntries = 'AddressEntries',
  UniqueAddressesEntered = 'UniqueAddressesEntered',
  AverageProjectSize = 'AverageProjectSize',
  AverageTotalSqftOfRoofForAllStructures = 'AverageTotalSqftOfRoofForAllStructures',
  AverageTotalSQOfRoofForAllStructures = 'AverageTotalSQOfRoofForAllStructures',
  TimesAlmostDoneForm = 'TimesAlmostDoneForm',
  SentDetails = 'SentDetails',
  DetailsWereSentToUsers = 'DetailsWereSentToUsers',
  OutsideOfServiceArea = 'OutsideOfServiceArea',
  AddressesOutsideOfOurServiceArea = 'AddressesOutsideOfOurServiceArea',
  DataUnavailable = 'DataUnavailable',
  AddressesSearchedWithUnavailableData = 'AddressesSearchedWithUnavailableData',
  TimesGetHelpFormWasShown = 'TimesGetHelpFormWasShown',
  CreateDate = 'CreateDate',
  NotMarketSpecific = 'NotMarketSpecific',
  AverageQuote = 'AverageQuote',
  TheHighestPrice = 'TheHighestPrice',
  TheLowestPrice = 'TheLowestPrice',
  TopProducts = 'TopProducts',
  Ordinal = 'Ordinal',
  ShowMore = 'ShowMore',
  ShowLess = 'ShowLess',
  YouCanNotDeleteThisProduct = 'YouCanNotDeleteThisProduct',
  YouMustHaveAtLeast = 'YouMustHaveAtLeast',
  Integration = 'Integration',
  NoIntegration = 'NoIntegration',
  Integrations = 'Integrations',
  SalesRabbit = 'SalesRabbit',
  SalesRabbitIntegration = 'SalesRabbitIntegration',
  Beacon = 'Beacon',
  APIKey = 'APIKey',
  ProductReq = 'ProductReq',
  PriceCanNotBeAppliedFor = 'PriceCanNotBeAppliedFor',
  TheAddressProvidedCouldNotBeFound = 'TheAddressProvidedCouldNotBeFound',
  ShowInSQ = 'ShowInSQ',
  OneSquare100SquareFeet = 'OneSquare100SquareFeet',
  WeAreUnableToTurnOffAllMarkets = 'WeAreUnableToTurnOffAllMarkets',
  WeAreUnableToTurnOffAllMarketsInState = 'WeAreUnableToTurnOffAllMarketsInState',
  WeAreUnableToTurnOffAllStates = 'WeAreUnableToTurnOffAllStates',
  StatewideLicense = 'StatewideLicense',
  ToolPreview = 'ToolPreview',
  ToolDemo = 'ToolDemo',
  ToolPreviewDescription = 'ToolPreviewDescription',
  SquareFootageSetting = 'SquareFootageSetting',
  ShowSquareFootage = 'ShowSquareFootage',
  SuggestedSlopeSetting = 'SuggestedSlopeSetting',
  ShowSuggestedSlope = 'ShowSuggestedSlope',
  ShowMonthlyPriceOnAllProductCards = 'ShowMonthlyPriceOnAllProductCards',
  ShowTotalPriceOnAllProductCards = 'ShowTotalPriceOnAllProductCards',
  SelectMarketsToApplyBasicFinancing = 'SelectMarketsToApplyBasicFinancing',
  FinancingCaption = 'FinancingCaption',
  SaveChangesBeforeApply = 'SaveChangesBeforeApply',
  SwitchMarketToBasicFinancing = 'SwitchMarketToBasicFinancing',
  SwitchSelectedMarketsToBasicFinancingUSA = 'SwitchSelectedMarketsToBasicFinancingUSA',
  SwitchSelectedMarketsToBasicFinancingCanada = 'SwitchSelectedMarketsToBasicFinancingCanada',
  MarketHasFinancingType = 'MarketHasFinancingType',
  YouCouldntDisableAllMarkets = 'YouCouldntDisableAllMarkets',
  IfYouWantToTurnOffTheState = 'IfYouWantToTurnOffTheState',
  LeadConversions = 'LeadConversions',
  TotalToolInteractions = 'TotalToolInteractions',
  GrandTotalOfLeads = 'GrandTotalOfLeads',
  Including = 'Including',
  PageConversion = 'PageConversion',
  RoofQuoteProViewed = 'RoofQuoteProViewed',
  AddressLoaded = 'AddressLoaded',
  ContactFormDisplayed = 'ContactFormDisplayed',
  ContactFormSubmitted = 'ContactFormSubmitted',
  ProductRequestedCTA = 'ProductRequestedCTA',
  TimesAlmostDoneFormWasShown = 'TimesAlmostDoneFormWasShown',
  ProjectDetails = 'ProjectDetails',
  OtherInfrormation = 'OtherInfrormation',
  MapInteractions = 'MapInteractions',
  EditRoofsClicked = 'EditRoofsClicked',
  NumberOfTimesUsersEditedTheRoofStructures = 'NumberOfTimesUsersEditedTheRoofStructures',
  AddRoofClicked = 'AddRoofClicked',
  NumberOfTimesUsersAddedNewRoofStructure = 'NumberOfTimesUsersAddedNewRoofStructure',
  SlopeChanged = 'SlopeChanged',
  NumberOfTimesUsersChangedTheSlope = 'NumberOfTimesUsersChangedTheSlope',
  RoofRenamed = 'RoofRenamed',
  NumberOfTimesUsersRenamedRoofStructure = 'NumberOfTimesUsersRenamedRoofStructure',
  RoofInclusionToggled = 'RoofInclusionToggled',
  NumberOfTimesUsersIncludedRemovedStructureFromQuote = 'NumberOfTimesUsersIncludedRemovedStructureFromQuote',
  FormsInteractions = 'FormsInteractions',
  HelpFormDisplayed = 'HelpFormDisplayed',
  NumberOfTimesGetHelpFormWasShown = 'NumberOfTimesGetHelpFormWasShown',
  HelpFormSubmitted = 'HelpFormSubmitted',
  NumberOfTimesUsersSubmittedGetHelpForm = 'NumberOfTimesUsersSubmittedGetHelpForm',
  OutsideServiceArea = 'OutsideServiceArea',
  NumberOfAddressesThatWereOutsideOfServiceArea = 'NumberOfAddressesThatWereOutsideOfServiceArea',
  NumberOfAddressesSearchedButDataWasNotAvailable = 'NumberOfAddressesSearchedButDataWasNotAvailable',
  Date = 'Date',
  PluralNumber = 'PluralNumber',
  ContractorLoanPro = 'ContractorLoanPro',
  BasicFinancing = 'BasicFinancing',
  Introducing = 'Introducing',
  GetHomeOwnersALoan = 'GetHomeOwnersALoan',
  EasyToUseAndMobileFriendlyLending = 'EasyToUseAndMobileFriendlyLending',
  ClosedLoopPayments = 'ClosedLoopPayments',
  ZeroStartUpCost = 'ZeroStartUpCost',
  HomeownerCanOnly = 'HomeownerCanOnly',
  LoanAmmountApproved = 'LoanAmmountApproved',
  SignUpNowAndStartOffering = 'SignUpNowAndStartOffering',
  YourMobilePhoneReq = 'YourMobilePhoneReq',
  BusinessNameReq = 'BusinessNameReq',
  BusinessEmailReq = 'BusinessEmailReq',
  BusinessPhoneReq = 'BusinessPhoneReq',
  BusinessAddressReq = 'BusinessAddressReq',
  BusinessAddress2 = 'BusinessAddress2',
  CityReq = 'CityReq',
  StateReq = 'StateReq',
  ZIPCodeReq = 'ZIPCodeReq',
  PleaseProvideAValidBusinessAddress = 'PleaseProvideAValidBusinessAddress',
  PleaseProvideAValidCity = 'PleaseProvideAValidCity',
  PleaseProvideAValidZipCode = 'PleaseProvideAValidZipCode',
  SignUp = 'SignUp',
  ActivityCenter = 'ActivityCenter',
  AllTransactions = 'AllTransactions',
  RateSheet = 'RateSheet',
  Statements = 'Statements',
  NewLoanApplication = 'NewLoanApplication',
  UserAccess = 'UserAccess',
  ClickHereToSeeAListOfUsers = 'ClickHereToSeeAListOfUsers',
  CustomerSummaryReport = 'CustomerSummaryReport',
  RecentLoanApplicationsReport = 'RecentLoanApplicationsReport',
  RecentLoanInvitations = 'RecentLoanInvitations',
  AvailableTransactions = 'AvailableTransactions',
  RecentActivity = 'RecentActivity',
  Send = 'Send',
  OfferCode = 'OfferCode',
  LoanApplicationHasBeenSent = 'LoanApplicationHasBeenSent',
  UnfortunatelyLoanApplication = 'UnfortunatelyLoanApplication',
  ProvidePhoneNumberOrEmail = 'ProvidePhoneNumberOrEmail',
  Or = 'Or',
  Address = 'Address',
  City = 'City',
  Zip = 'Zip',
  MobilePhoneNumber = 'MobilePhoneNumber',
  AvailableSpend = 'AvailableSpend',
  AmountSpent = 'AmountSpent',
  AppID = 'AppID',
  BasePackage = 'BasePackage',
  AllLoanProductOfferings = 'AllLoanProductOfferings',
  OfferCodes = 'OfferCodes',
  UseThisUniqueIdentifier = 'UseThisUniqueIdentifier',
  SimplyStatedTheFull = 'SimplyStatedTheFull',
  AnnualPercentageRateOfInterest = 'AnnualPercentageRateOfInterest',
  ThisIsYourFee = 'ThisIsYourFee',
  OpenProductDetails = 'OpenProductDetails',
  DeleteFromBasePackage = 'DeleteFromBasePackage',
  ApplyToMarketsProducts = 'ApplyToMarketsProducts',
  PleaseSelectMarketsOrProducts = 'PleaseSelectMarketsOrProducts',
  ExportStatement = 'ExportStatement',
  OpenStatement = 'OpenStatement',
  SubmittedOn = 'SubmittedOn',
  ForThePeriod = 'ForThePeriod',
  StatementNumber = 'StatementNumber',
  Momnt = 'Momnt',
  StatementDetails = 'StatementDetails',
  FailedTransactions = 'FailedTransactions',
  FailedTransactionsDescription = 'FailedTransactionsDescription',
  StatementSummary = 'StatementSummary',
  StatementFor = 'StatementFor',
  PayableTo = 'PayableTo',
  TotalCharges = 'TotalCharges',
  Refunds = 'Refunds',
  NetPurchases = 'NetPurchases',
  Fees = 'Fees',
  NetMerchantFees = 'NetMerchantFees',
  NetMerchantReceipt = 'NetMerchantReceipt',
  Export = 'Export',
  ExpandByCategory = 'ExpandByCategory',
  AddToBasePackage = 'AddToBasePackage',
  RateSheetHasBeenAdded = 'RateSheetHasBeenAdded',
  UnfortunatelyTheRateSheetHasNotBeenAdded = 'UnfortunatelyTheRateSheetHasNotBeenAdded',
  RateSheetHasBeenRemoved = 'RateSheetHasBeenRemoved',
  UnfortunatelyTheRateSheetHasNot = 'UnfortunatelyTheRateSheetHasNot',
  ProductDetails = 'ProductDetails',
  ProductID = 'ProductID',
  PromotionMotivatedCustomers = 'PromotionMotivatedCustomers',
  INormallyPayCash = 'INormallyPayCash',
  PromotionMotivatedCustomersINormallyPayCash = 'PromotionMotivatedCustomersINormallyPayCash',
  InterestRateSensitiveCustomers = 'InterestRateSensitiveCustomers',
  IWontPayOver = 'IWontPayOver',
  InterestRateSensitiveCustomersIWontPayOver = 'InterestRateSensitiveCustomersIWontPayOver',
  PaymentSensitiveCustomers = 'PaymentSensitiveCustomers',
  ICanOnlyPay = 'ICanOnlyPay',
  PaymentSensitiveCustomersICanOnlyPay = 'PaymentSensitiveCustomersICanOnlyPay',
  AreYouSureYouWantToRemoveProduct = 'AreYouSureYouWantToRemoveProduct',
  Last5Transactions = 'Last5Transactions',
  EditOfferCode = 'EditOfferCode',
  CreateNewOfferCode = 'CreateNewOfferCode',
  SelectTheLoanProducts = 'SelectTheLoanProducts',
  YouMustSelectAtLeastTwo = 'YouMustSelectAtLeastTwo',
  NameReq = 'NameReq',
  AddToOffer = 'AddToOffer',
  DeleteFromOffer = 'DeleteFromOffer',
  OfferCodeHasBeenCreated = 'OfferCodeHasBeenCreated',
  UnfortunatelyTheOfferCodeHasNotBeenCreated = 'UnfortunatelyTheOfferCodeHasNotBeenCreated',
  OfferCodeHasBeenUpdated = 'OfferCodeHasBeenUpdated',
  UnfortunatelyTheOfferCodeHasNotBeenUpdated = 'UnfortunatelyTheOfferCodeHasNotBeenUpdated',
  YouCannotUseAnExistingName = 'YouCannotUseAnExistingName',
  DeleteOfferCode = 'DeleteOfferCode',
  AreYouSureYouWantToRemoveOfferCode = 'AreYouSureYouWantToRemoveOfferCode',
  OfferCodeHasBeenRemoved = 'OfferCodeHasBeenRemoved',
  UnfortunatelyTheOfferCodeHasNotBeenRemoved = 'UnfortunatelyTheOfferCodeHasNotBeenRemoved',
  YouCanChooseBasePackageOrOfferCode = 'YouCanChooseBasePackageOrOfferCode',
  PayRequestAmount = 'PayRequestAmount',
  TransactionDescription = 'TransactionDescription',
  TransactionCannotExceed = 'TransactionCannotExceed',
  Request = 'Request',
  Refund = 'Refund',
  Amount = 'Amount',
  DateTime = 'DateTime',
  SubmittedBy = 'SubmittedBy',
  RefundtHasBeenSent = 'RefundtHasBeenSent',
  UnfortunatelyRefundHasNot = 'UnfortunatelyRefundHasNot',
  PleaseSelectExportType = 'PleaseSelectExportType',
  HowInstantLoanWorks = 'HowInstantLoanWorks',
  HowToRepayYourLoan = 'HowToRepayYourLoan',
  MoreBuyingPowerLessHassle = 'MoreBuyingPowerLessHassle',
  LearnMore = 'LearnMore',
  GetPreApproved = 'GetPreApproved',
  PromotionalFinancingProvidesOffers = 'PromotionalFinancingProvidesOffers',
  SimpleOnlineApplicationWithInstantDecisioning = 'SimpleOnlineApplicationWithInstantDecisioning',
  ShopOffersWithoutAffectingCreditScore = 'ShopOffersWithoutAffectingCreditScore',
  ReceiveInstantApproval = 'ReceiveInstantApproval',
  MonthPurchaseWindowtoSpendYourLoan = 'MonthPurchaseWindowtoSpendYourLoan',
  NoEarlyPaymentPenaltyOnAnyLoans = 'NoEarlyPaymentPenaltyOnAnyLoans',
  InvitationSent = 'InvitationSent',
  WeSentInvitationToApplyYourMobilePhone = 'WeSentInvitationToApplyYourMobilePhone',
  IfYouWnatToGetMoreInfoAboutLoan = 'IfYouWnatToGetMoreInfoAboutLoan',
  IfYouWouldLikeToUseDifferentPhoneNumber = 'IfYouWouldLikeToUseDifferentPhoneNumber',
  ResendInvitation = 'ResendInvitation',
  Step = 'Step',
  ClickGetPreQualified = 'ClickGetPreQualified',
  SubmitYourApplication = 'SubmitYourApplication',
  GetApprovedAndFunded = 'GetApprovedAndFunded',
  SetupAutomaticPayments = 'SetupAutomaticPayments',
  SecureLoginToProtect = 'SecureLoginToProtect',
  SimpleOneTimeOrAutoPayments = 'SimpleOneTimeOrAutoPayments',
  ClearAndConciseMonthlyStatements = 'ClearAndConciseMonthlyStatements',
  PayOnlineOrByPhone = 'PayOnlineOrByPhone',
  Next = 'Next',
  TotalPrice = 'TotalPrice',
  TheInvitationHasBeenSend = 'TheInvitationHasBeenSend',
  UnfortunatelyTheInvitationHasNotBeenSent = 'UnfortunatelyTheInvitationHasNotBeenSent',
  FinancingIsNotAvailable = 'FinancingIsNotAvailable',
  WeAreSorryToInform = 'WeAreSorryToInform',
  BackToInvitation = 'BackToInvitation',
  FinanceAnalytics = 'FinanceAnalytics',
  StartOfferingRealFinancing = 'StartOfferingRealFinancing',
  TotalFinalizedLoan = 'TotalFinalizedLoan',
  UsersLoanWasSuccessfullyFinalized = 'UsersLoanWasSuccessfullyFinalized',
  TotalCanceledLoan = 'TotalCanceledLoan',
  UsersLoanWasCanceled = 'UsersLoanWasCanceled',
  AverageApprovedLoanAmount = 'AverageApprovedLoanAmount',
  AverageAmountOfApproved = 'AverageAmountOfApproved',
  RepQuotes = 'RepQuotes',
  RepQuotesDescription = 'RepQuotesDescription',
  UnlimitedQuotesAtRecordSpeeds = 'UnlimitedQuotesAtRecordSpeeds',
  NotAvailableInNewRadius = 'NotAvailableInNewRadius',
  AfterSavingThisStateWillBeUnavailable = 'AfterSavingThisStateWillBeUnavailable',
  PleaseCheckYouEmail = 'PleaseCheckYouEmail',
  NameCanHaveOnly = 'NameCanHaveOnly',
  ThisRecordWasCreatedOutside = 'ThisRecordWasCreatedOutside',
  PerSq = 'PerSq',
  Basic = 'Basic',
  Lowest = 'Lowest',
  Highest = 'Highest',
  MomntPaymentsEstimation = 'MomntPaymentsEstimation',
  DetailsWereSent = 'DetailsWereSent',
  Description = 'Description',
  BackToStart = 'BackToStart',
  ChooseFinancingTypeWhichWillApply = 'ChooseFinancingTypeWhichWillApply',
  SwitchingToBasicFinancing = 'SwitchingToBasicFinancing',
  SwitchingToContractorLoanPro = 'SwitchingToContractorLoanPro',
  SwitchingToCLP = 'SwitchingToCLP',
  SwitchingToBasicFinancingTitle = 'SwitchingToBasicFinancingTitle',
  SwitchingToCustomFinancingTitle = 'SwitchingToCustomFinancingTitle',
  SwitchingToCustomFinancing = 'SwitchingToCustomFinancing',
  StateIsntAvailable = 'StateIsntAvailable',
  FinancingType = 'FinancingType',
  PricePerSquare = 'PricePerSquare',
  FinancingOptions = 'FinancingOptions',
  PaymentsAsLowAs = 'PaymentsAsLowAs',
  InterestAsLowAs = 'InterestAsLowAs',
  ZeroAPRUpTo = 'ZeroAPRUpTo',
  FinancingAndProducts = 'FinancingAndProducts',
  EditSettingsForBasicFinancing = 'EditSettingsForBasicFinancing',
  FactorInMerchantFee = 'FactorInMerchantFee',
  ExampleWithFee = 'ExampleWithFee',
  TurningThisOnWill = 'TurningThisOnWill',
  PricePerMonth = 'PricePerMonth',
  IfYouWouldLikeToDisplay = 'IfYouWouldLikeToDisplay',
  InstantLoanOptions = 'InstantLoanOptions',
  ZeroAPRIsOnlyAvailable = 'ZeroAPRIsOnlyAvailable',
  InterestAsLowAsPercent = 'InterestAsLowAsPercent',
  ThePaymentOfAmountPerMonth = 'ThePaymentOfAmountPerMonth',
  AverageMerchantFee = 'AverageMerchantFee',
  MerchantFeeAdded = 'MerchantFeeAdded',
  SaveMarketSettingBefore = 'SaveMarketSettingBefore',
  SwitchingToContractorLoanProWillApply = 'SwitchingToContractorLoanProWillApply',
  VerifyBothMicroDeposits = 'VerifyBothMicroDeposits',
  TwoMicroTransactions = 'TwoMicroTransactions',
  TheAmountsEnteredWere = 'TheAmountsEnteredWere',
  PleaseContactMerchantSupport = 'PleaseContactMerchantSupport',
  VerifyAccount = 'VerifyAccount',
  ResendVerification = 'ResendVerification',
  Amount1 = 'Amount1',
  Amount2 = 'Amount2',
  InCSV = 'InCSV',
  InExcel = 'InExcel',
  ExportToCSV = 'ExportToCSV',
  ExportToExcel = 'ExportToExcel',
  ExportToPDF = 'ExportToPDF',
  AreYouSureWantToExport = 'AreYouSureWantToExport',
  PricingHistory = 'PricingHistory',
  CompanyUsers = 'CompanyUsers',
  AddNewUser = 'AddNewUser',
  PleaseFillInUserDetails = 'PleaseFillInUserDetails',
  RoleReq = 'RoleReq',
  Admin = 'Admin',
  SalesRep = 'SalesRep',
  AdminHasAccessToAllPages = 'AdminHasAccessToAllPages',
  YourPrimaryContactIs = 'YourPrimaryContactIs',
  SalesRepUsersAccess = 'SalesRepUsersAccess',
  SalesRepUsersHaveAccessExcludingClp = 'SalesRepUsersHaveAccessExcludingClp',
  AfterSavingTheContact = 'AfterSavingTheContact',
  MakeCompanyPrimaryContact = 'MakeCompanyPrimaryContact',
  AddAsUser = 'AddAsUser',
  RoofQuotePRO = 'RoofQuotePRO',
  RoofQuotePROUSA = 'RoofQuotePROUSA',
  RoofQuoteProCanada = 'RoofQuoteProCanada',
  EnterYourStreetAddressToStart = 'EnterYourStreetAddressToStart',
  CustomSlope = 'CustomSlope',
  EditCustomSlope = 'EditCustomSlope',
  PleaseChooseCustomSlopeFromTheList = 'PleaseChooseCustomSlopeFromTheList',
  Slope = 'Slope',
  SlopeWithName = 'SlopeWithName',
  StructureWithComplexity = 'StructureWithComplexity',
  CreateQuote = 'CreateQuote',
  OriginalRep = 'OriginalRep',
  Rep = 'Rep',
  Reassigned = 'Reassigned',
  More = 'More',
  NeedToSelectAtLeastOnePosition = 'NeedToSelectAtLeastOnePosition',
  ReassignTo = 'ReassignTo',
  OpenURL = 'OpenURL',
  PleaseChooseUserFromTheList = 'PleaseChooseUserFromTheList',
  User = 'User',
  Reassign = 'Reassign',
  MultipleRep = 'MultipleRep',
  UnfortunatelyTheLeadHaveNotBeenReassigned = 'UnfortunatelyTheLeadHaveNotBeenReassigned',
  LeadsHaveBeenReassignedToSuccessfully = 'LeadsHaveBeenReassignedToSuccessfully',
  SelectAllLeads = 'SelectAllLeads',
  SelectLeadsOnCurrentPage = 'SelectLeadsOnCurrentPage',
  UnselectAllLeads = 'UnselectAllLeads',
  CompleteTheContactFormToSendThisLeads = 'CompleteTheContactFormToSendThisLeads',
  SendLoanInvitation = 'SendLoanInvitation',
  CreateQuoteManually = 'CreateQuoteManually',
  THeAddressIsNotFound = 'THeAddressIsNotFound',
  WeSentAnEmailToVerify = 'WeSentAnEmailToVerify',
  StartOver = 'StartOver',
  UpdateLead = 'UpdateLead',
  CreateNewLead = 'CreateNewLead',
  CreateLead = 'CreateLead',
  AreYouSureYouWantToStartOver = 'AreYouSureYouWantToStartOver',
  AllUnsavedRoofsSettingsWillBeLost = 'AllUnsavedRoofsSettingsWillBeLost',
  AreYouSureYouWantToCreateNewLead = 'AreYouSureYouWantToCreateNewLead',
  TheDataWillBeCreatedForTheNewSeparateLead = 'TheDataWillBeCreatedForTheNewSeparateLead',
  AreYouSureYouWantToModifyLead = 'AreYouSureYouWantToModifyLead',
  ExistingLeadWillBeModified = 'ExistingLeadWillBeModified',
  Modify = 'Modify',
  RepQuotesHasBeenStartedOverSuccessfully = 'RepQuotesHasBeenStartedOverSuccessfully',
  UnfortunatelyTheRepQuoteHasNotBeenStartedOver = 'UnfortunatelyTheRepQuoteHasNotBeenStartedOver',
  LeadHasBeenModifiedSuccessfully = 'LeadHasBeenModifiedSuccessfully',
  UnfortunatelyTheLeadHasNotBeenModified = 'UnfortunatelyTheLeadHasNotBeenModified',
  LeadHasBeenCreatedSuccessfully = 'LeadHasBeenCreatedSuccessfully',
  UnfortunatelyTheLeadHasNotBeenCreated = 'UnfortunatelyTheLeadHasNotBeenCreated',
  YouCanManuallyDrawYourRoofShape = 'YouCanManuallyDrawYourRoofShape',
  ThisAddressIsLocatedOutsideYourServiceArea = 'ThisAddressIsLocatedOutsideYourServiceArea',
  SwitchAllMarketsToContractorLoanPro = 'SwitchAllMarketsToContractorLoanPro',
  SwitchAllMarketsToBasicFinancing = 'SwitchAllMarketsToBasicFinancing',
  BasicFinancingAppliedToAllMarkets = 'BasicFinancingAppliedToAllMarkets',
  New = 'New',
  Open = 'Open',
  Cold = 'Cold',
  Lost = 'Lost',
  Sold = 'Sold',
  Deleted = 'Deleted',
  StatusHasBeenChangedSuccessfully = 'StatusHasBeenChangedSuccessfully',
  UnfortunatelyStatusHasNotBeenChanged = 'UnfortunatelyStatusHasNotBeenChanged',
  InterestedInFinancing = 'InterestedInFinancing',
  EmailDetails = 'EmailDetails',
  TurnThisOffToSkipTheContactForm = 'TurnThisOffToSkipTheContactForm',
  TurnThisOnToCreateANewLead = 'TurnThisOnToCreateANewLead',
  HomeownersContactInformationWillBeUsedForEmailingQuotes = 'HomeownersContactInformationWillBeUsedForEmailingQuotes',
  PrimaryContact = 'PrimaryContact',
  DeleteUser = 'DeleteUser',
  EditUser = 'EditUser',
  AreYouSureYouWantToMakerPrimaryContact = 'AreYouSureYouWantToMakerPrimaryContact',
  TheyWillBeTheMainPointOfContact = 'TheyWillBeTheMainPointOfContact',
  ContactHasBeenMadePrimaryContact = 'ContactHasBeenMadePrimaryContact',
  UnfortunatelyTheContactHasNotBeenMadePrimary = 'UnfortunatelyTheContactHasNotBeenMadePrimary',
  Make = 'Make',
  YouHaveAlreadyHadPrimaryContact = 'YouHaveAlreadyHadPrimaryContact',
  UserHasBeenDeletedSuccessfully = 'UserHasBeenDeletedSuccessfully',
  UnfortunatelyTheUserHasNotBeenDeleted = 'UnfortunatelyTheUserHasNotBeenDeleted',
  AreYouSureYouWantToDeleteUser = 'AreYouSureYouWantToDeleteUser',
  NeedToReassignLeads = 'NeedToReassignLeads',
  EditEmail = 'EditEmail',
  YouCanChangeTheContentOfTheSentEmail = 'YouCanChangeTheContentOfTheSentEmail',
  SendTo = 'SendTo',
  Comment = 'Comment',
  EditCommentTitle = 'EditCommentTitle',
  EditCommentText = 'EditCommentText',
  ProvideCommentWhichYouWantToAddToTheEamil = 'ProvideCommentWhichYouWantToAddToTheEamil',
  EnterTheCommentYouWantToAddToTheLetter = 'EnterTheCommentYouWantToAddToTheLetter',
  EditProductsThatWilBeSentToLeads = 'EditProductsThatWilBeSentToLeads',
  ProductsDescription = 'ProductsDescription',
  EditProductDescriptionThatWilBeSentToLeads = 'EditProductDescriptionThatWilBeSentToLeads',
  PackageDetails = 'PackageDetails',
  AttachPDFOfQuote = 'AttachPDFOfQuote',
  EmailHasBeenSentSuccessfully = 'EmailHasBeenSentSuccessfully',
  CompanyInfo = 'CompanyInfo',
  TotalRepLeads = 'TotalRepLeads',
  BackToProducts = 'BackToProducts',
  AttentionIfYouChangeYourEmailAddress = 'AttentionIfYouChangeYourEmailAddress',
  TypeOfInstance = 'TypeOfInstance',
  FileHasBeenExportedSuccessfully = 'FileHasBeenExportedSuccessfully',
  UnfortunatelyTheFileHasNotBeenExported = 'UnfortunatelyTheFileHasNotBeenExported',
  LeadStatus = 'LeadStatus',
  ToUpdateYourEstimateOrUseADifferentAddress = 'ToUpdateYourEstimateOrUseADifferentAddress',
  GotIt = 'GotIt',
  LeadInformation = 'LeadInformation',
  FillInYourHomeownersInformation = 'FillInYourHomeownersInformation',
  AreYouSureWantLeavepage = 'AreYouSureWantLeavepage',
  AllChangesHaveBeenAppliedToTheDashboard = 'AllChangesHaveBeenAppliedToTheDashboard',
  AllLeadChangesHaveBeenAppliedToTheDashboard = 'AllLeadChangesHaveBeenAppliedToTheDashboard',
  CompanyOwner = 'CompanyOwner',
  CompanySubscription = 'CompanySubscription',
  CompanyEmailReq = 'CompanyEmailReq',
  LoanProduct = 'LoanProduct',
  Copy = 'Copy',
  TheCustomerGotDetailsAboutThisProduct = 'TheCustomerGotDetailsAboutThisProduct',
  HideTotalPriceOnTheProductCard = 'HideTotalPriceOnTheProductCard',
  ShowTotalPriceOnTheProductCard = 'ShowTotalPriceOnTheProductCard',
  HideMonthlyPriceOnTheProductCard = 'HideMonthlyPriceOnTheProductCard',
  ShowMonthlyPriceOnTheProductCard = 'ShowMonthlyPriceOnTheProductCard',
  TurnOn = 'TurnOn',
  TurnOff = 'TurnOff',
  ClickEditToMakeChanges = 'ClickEditToMakeChanges',
  RefreshData = 'RefreshData',
  ConnectYourEmail = 'ConnectYourEmail',
  PleaseCompleteYourEmailDetails = 'PleaseCompleteYourEmailDetails',
  EmailService = 'EmailService',
  OrProvideYourOwn = 'OrProvideYourOwn',
  OrChooseFromTheList = 'OrChooseFromTheList',
  Connect = 'Connect',
  Port = 'Port',
  PleaseEnterTheCorrectPassword = 'PleaseEnterTheCorrectPassword',
  PleaseEnterTheCorrectEmailService = 'PleaseEnterTheCorrectEmailService',
  PleaseCheckPort = 'PleaseCheckPort',
  PasswordWillBeEncrypted = 'PasswordWillBeEncrypted',
  ToSendDetailsFromRepQuotesVerifyIt = 'ToSendDetailsFromRepQuotesVerifyIt',
  ToSendDetailsFromRepQuotesYouMustConnect = 'ToSendDetailsFromRepQuotesYouMustConnect',
  ChangesHaveBeenSavedSuccessfully = 'ChangesHaveBeenSavedSuccessfully',
  ConnectionHaveBeenSavedSuccessfully = 'ConnectionHaveBeenSavedSuccessfully',
  UnfortunatelyTheChangesHaveNotBeenSaved = 'UnfortunatelyTheChangesHaveNotBeenSaved',
  UnfortunatelyTheConnectionHaveNotBeenCreated = 'UnfortunatelyTheConnectionHaveNotBeenCreated',
  ConnectYourEmailToSendQuotes = 'ConnectYourEmailToSendQuotes',
  Unassigned = 'Unassigned',
  ContractorLoanProIsNotYet = 'ContractorLoanProIsNotYet',
  AreYouSureYouWantToSwitchOnCLP = 'AreYouSureYouWantToSwitchOnCLP',
  AllMarketsWillBeSwitchedToCLP = 'AllMarketsWillBeSwitchedToCLP',
  AllMarketsWillBeSwitchedToBF = 'AllMarketsWillBeSwitchedToBF',
  AreYouSureYouWantToSwitchOnBF = 'AreYouSureYouWantToSwitchOnBF',
  AreYouSureYouWantToSwitchMarketToBF = 'AreYouSureYouWantToSwitchMarketToBF',
  NoLeaveTheCurrentFinancing = 'NoLeaveTheCurrentFinancing',
  MarketWillBeSwitchedToBF = 'MarketWillBeSwitchedToBF',
  NoIWantToLeaveAsIs = 'NoIWantToLeaveAsIs',
  SomeStatesAreNotYetAvailable = 'SomeStatesAreNotYetAvailable',
  WebsiteAnalytics = 'WebsiteAnalytics',
  LeadSource = 'LeadSource',
  ExamplePort = 'ExamplePort',
  ForCorrectWork = 'ForCorrectWork',
  GiveAccessToCLP = 'GiveAccessToCLP',
  NoAccount = 'NoAccount',
  YouDontHavePermissions = 'YouDontHavePermissions',
  PleaseContactYourCompanyAdmin = 'PleaseContactYourCompanyAdmin',
  PaymentSettings = 'PaymentSettings',
  BankDetails = 'BankDetails',
  NiceWorkExpectAShortOnboardingCall = 'NiceWorkExpectAShortOnboardingCall',
  YouAreCurrentlySetUpToReceive = 'YouAreCurrentlySetUpToReceive',
  UpdateBankDetails = 'UpdateBankDetails',
  Business = 'Business',
  Personal = 'Personal',
  BankAccount = 'BankAccount',
  AccountType = 'AccountType',
  NameOnAccount = 'NameOnAccount',
  NameOnAccountReq = 'NameOnAccountReq',
  BankAccountNumber = 'BankAccountNumber',
  BankAccountNumberReq = 'BankAccountNumberReq',
  VerifyBankAccountNumber = 'VerifyBankAccountNumber',
  RoutingNumber = 'RoutingNumber',
  Checking = 'Checking',
  Savings = 'Savings',
  SendChanges = 'SendChanges',
  ChangesHaveBeenSentSuccessfully = 'ChangesHaveBeenSentSuccessfully',
  UnfortunatelyTheChangesHaveNotBeenSent = 'UnfortunatelyTheChangesHaveNotBeenSent',
  YouHaveToSetUpABankAccount = 'YouHaveToSetUpABankAccount',
  ItAppearsSomeOfYourData = 'ItAppearsSomeOfYourData',
  PleaseCorrectRoutingNumberFormat = 'PleaseCorrectRoutingNumberFormat',
  TwoMicroTransactionShouldBeSent = 'TwoMicroTransactionShouldBeSent',
  RefreshPage = 'RefreshPage',
  SetDefaultLoanProducts = 'SetDefaultLoanProducts',
  YourApplicationIsBeing = 'YourApplicationIsBeing',
  UnfortunatelyYourUpplication = 'UnfortunatelyYourUpplication',
  EmailConnectionIssue = 'EmailConnectionIssue',
  EditEmailConnection = 'EditEmailConnection',
  PleaseUpdateYourEmailConnection = 'PleaseUpdateYourEmailConnection',
  Update = 'Update',
  OpenQuote = 'OpenQuote',
  Assign = 'Assign',
  PleaseSelectWhatYouWantToUpdate = 'PleaseSelectWhatYouWantToUpdate',
  Constructions = 'Constructions',
  ProductsAndPrice = 'ProductsAndPrice',
  MarketsProductsAndPrice = 'MarketsProductsAndPrice',
  PleaseSelectAtLeastOneValue = 'PleaseSelectAtLeastOneValue',
  ItIsNotAvailableForOldLeads = 'ItIsNotAvailableForOldLeads',
  AssignTo = 'AssignTo',
  LeadHasBeenAssignedToSuccessfully = 'LeadHasBeenAssignedToSuccessfully',
  UnfortunatelyTheLeadHasNotBeenAssignedTo = 'UnfortunatelyTheLeadHasNotBeenAssignedTo',
  OrignialMarketIsNoLongerActive = 'OrignialMarketIsNoLongerActive',
  ProductsAndPricingHaveBeenUpdatedFor = 'ProductsAndPricingHaveBeenUpdatedFor',
  MyPublicLink = 'MyPublicLink',
  CreateAPersonalizedLinkToShare = 'CreateAPersonalizedLinkToShare',
  HowToCreatePublicLink = 'HowToCreatePublicLink',
  CopyAnyUrlOnYourCompanyWebsite = 'CopyAnyUrlOnYourCompanyWebsite',
  AddRepToTheEndOfTheUrl = 'AddRepToTheEndOfTheUrl',
  BeAwareIfYouChangeYourEmailYoullNeedToUpdate = 'BeAwareIfYouChangeYourEmailYoullNeedToUpdate',
  RemoveUrlIsReadyToShare = 'RemoveUrlIsReadyToShare',
  YouCanUseThisLinkInTheFollowingWay = 'YouCanUseThisLinkInTheFollowingWay',
  LinkUsageExampleList = 'LinkUsageExampleList',
  CalloutTextEmptyError = 'CalloutTextEmptyError',
  YouCantChangeStatusBecauseUserHasACLP = 'YouCantChangeStatusBecauseUserHasACLP',
  YouNeedAdminAccessToChangeTheseSettings = 'YouNeedAdminAccessToChangeTheseSettings',
  SelectMarket = 'SelectMarket',
  SeeQuote = 'SeeQuote',
  SlideoutTab = 'SlideoutTab',
  VerticalPosition = 'VerticalPosition',
  ShowOnTabletsAndDesktops = 'ShowOnTabletsAndDesktops',
  ShowOnPhones = 'ShowOnPhones',
  WebhookType = 'WebhookType',
  ContactFormCompleted = 'ContactFormCompleted',
  ProductRequested = 'ProductRequested',
  SendsTheDataAfterAnAddressIsEnteredWithoutCompletingContactForm = 'SendsTheDataAfterAnAddressIsEnteredWithoutCompletingContactForm',
  DeleteWebhook = 'DeleteWebhook',
  AddNewWebhook = 'AddNewWebhook',
  UpdateMarkets = 'UpdateMarkets',
  ApplyMarket = 'ApplyMarket',
  WeAreUnableToProvideAnInstantQuote = 'WeAreUnableToProvideAnInstantQuote',
  UseTheSameEmailsForAllMarkets = 'UseTheSameEmailsForAllMarkets',
  ApplyEmailSettingsToOtherMarkets = 'ApplyEmailSettingsToOtherMarkets',
  InboundEmailForReceiving = 'InboundEmailForReceiving',
  OutboundEmailForSending = 'OutboundEmailForSending',
  WeAreUpgradingOurEmailSystem = 'WeAreUpgradingOurEmailSystem',
  ToUseYourCompanyEmail = 'ToUseYourCompanyEmail',
  ConnectionFailedPleaseReconnect = 'ConnectionFailedPleaseReconnect',
  ConnectionFailedForTheMarkets = 'ConnectionFailedForTheMarkets',
  ToApplyNeedsToTurnOff = 'ToApplyNeedsToTurnOff',
  Emails = 'Emails',
  AreYouSureYouWantToUseTheSame = 'AreYouSureYouWantToUseTheSame',
  ByDefaultEmailsFromMarket = 'ByDefaultEmailsFromMarket',
  ConnectEmail = 'ConnectEmail',
  ProvideEmailAuthenticationDetails = 'ProvideEmailAuthenticationDetails',
  ConnectYourCompanyEmail = 'ConnectYourCompanyEmail',
  ProvideFollowingDetailsForCompanyEmail = 'ProvideFollowingDetailsForCompanyEmail',
  UseDefaultCompanyEmail = 'UseDefaultCompanyEmail',
  AreYouSureWantToResetEmails = 'AreYouSureWantToResetEmails',
  AllMarketsWillUseCompanyEmail = 'AllMarketsWillUseCompanyEmail',
  EmailChangesHaveBeenSaved = 'EmailChangesHaveBeenSaved',
  UnfortunatelyEmailChanges = 'UnfortunatelyEmailChanges',
  ApplyEmailSettingsToOther = 'ApplyEmailSettingsToOther',
  PleaseSelectTheMarketsToApplyEmailSettings = 'PleaseSelectTheMarketsToApplyEmailSettings',
  EmailForLeadNotifications = 'EmailForLeadNotifications',
  EmailForSendingProduct = 'EmailForSendingProduct',
  YouHaveInTheSystemTheLead = 'YouHaveInTheSystemTheLead',
  ThisLeadAlreadyExists = 'ThisLeadAlreadyExists',
  OpenExistingLead = 'OpenExistingLead',
  ChooseOneOfTheExistingLead = 'ChooseOneOfTheExistingLead',
  Lead = 'Lead',
  StructuresAndSqFt = 'StructuresAndSqFt',
  MoreActions = 'MoreActions',
  SaveAsDefault = 'SaveAsDefault',
  MyMarkets = 'MyMarkets',
  SeeProductDetails = 'SeeProductDetails',
  ApplySettingsToOtherMarkets = 'ApplySettingsToOtherMarkets',
  NoFinancing = 'NoFinancing',
  ContractorLoanPROBasePackage = 'ContractorLoanPROBasePackage',
  ContractorLoanPROOfferCodes = 'ContractorLoanPROOfferCodes',
  BackToMarketList = 'BackToMarketList',
  ApplyMarketSettingsToOtherMarkets = 'ApplyMarketSettingsToOtherMarkets',
  PleaseSelectTheMarketsToApplyFinancingAndProducts = 'PleaseSelectTheMarketsToApplyFinancingAndProducts',
  ActiveRep = 'ActiveRep',
  RQPLeads = 'RQPLeads',
  ItemsRange = 'ItemsRange',
  OrderMeasurements = 'OrderMeasurements',
  LeadDetails = 'LeadDetails',
  Measurements = 'Measurements',
  FinalTotalSqFt = 'FinalTotalSqFt',
  OriginalSqFt = 'OriginalSqFt',
  ThisIncludesStructureEditsAndIsUsedToCalculatePricing = 'ThisIncludesStructureEditsAndIsUsedToCalculatePricing',
  ThisIsTheSqftDetectedWithoutStructureEdits = 'ThisIsTheSqftDetectedWithoutStructureEdits',
  SqFtOfMainRoof = 'SqFtOfMainRoof',
  RoofDetails = 'RoofDetails',
  OpenInFullScreen = 'OpenInFullScreen',
  CloseFullScreen = 'CloseFullScreen',
  Search = 'Search',
  NOfM = 'NOfM',
  SqftInformation = 'SqftInformation',
  ToGetDataClickOrderMeasurements = 'ToGetDataClickOrderMeasurements',
  Order = 'Order',
  PleaseSelectTheMeasurementReport = 'PleaseSelectTheMeasurementReport',
  PleaseProvideInstructions = 'PleaseProvideInstructions',
  Report = 'Report',
  EagleView = 'EagleView',
  EagleViewBidPerfect = 'EagleViewBidPerfect',
  EagleViewPremiumCommercialReport = 'EagleViewPremiumCommercialReport',
  EagleViewPremiumResidentialReport = 'EagleViewPremiumResidentialReport',
  EagleViewBidPerfectReport = 'EagleViewBidPerfectReport',
  GAFQuickMeasure = 'GAFQuickMeasure',
  GAF = 'GAF',
  NotAvailableReport = 'NotAvailableReport',
  TrialMeasurementCredit = 'TrialMeasurementCredit',
  YouHaveNCreditsLeft = 'YouHaveNCreditsLeft',
  Structures = 'Structures',
  AllStructures = 'AllStructures',
  MainRoofOnly = 'MainRoofOnly',
  MainRoofPlus = 'MainRoofPlus',
  OtherStructures = 'OtherStructures',
  GetMoreCredits = 'GetMoreCredits',
  Instructions = 'Instructions',
  MeasurementReportsAreNotAvailableForThisRroject = 'MeasurementReportsAreNotAvailableForThisRroject',
  UnfortunatelyThisAddressIsNotAvailableForMeasurementProviders = 'UnfortunatelyThisAddressIsNotAvailableForMeasurementProviders',
  YourGafMeasurementOrderHasBeenPlaced = 'YourGafMeasurementOrderHasBeenPlaced',
  YourEVMeasurementOrderHasBeenPlaced = 'YourEVMeasurementOrderHasBeenPlaced',
  UnfortunatelyTheOrderHasNotBeenSent = 'UnfortunatelyTheOrderHasNotBeenSent',
  SingleFamily = 'SingleFamily',
  MultiFamily = 'MultiFamily',
  Commercial = 'Commercial',
  PleaseProvideProductCode = 'PleaseProvideProductCode',
  ProductCode = 'ProductCode',
  PleaseSelectAnotherReportType = 'PleaseSelectAnotherReportType',
  CurrentPlan = 'CurrentPlan',
  GeneralSubscription = 'GeneralSubscription',
  NextPayment = 'NextPayment',
  NMarkets = 'NMarkets',
  NStateLicenses = 'NStateLicenses',
  License = 'License',
  ConnectWithAdmin = 'ConnectWithAdmin',
  MeasurementCredits = 'MeasurementCredits',
  Available = 'Available',
  RenewOption = 'RenewOption',
  RenewDate = 'RenewDate',
  AddRenewOption = 'AddRenewOption',
  LastPaymentWasCanceled = 'LastPaymentWasCanceled',
  PaymentDetails = 'PaymentDetails',
  ProvideCreditCardOrBankAccount = 'ProvideCreditCardOrBankAccount',
  PleaseContactTheSupportTeam = 'PleaseContactTheSupportTeam',
  AddPaymentDetailsFirst = 'AddPaymentDetailsFirst',
  AccountIsntConnected = 'AccountIsntConnected',
  AccountIsConnected = 'AccountIsConnected',
  Disconnect = 'Disconnect',
  ConnectAccount = 'ConnectAccount',
  EditConnection = 'EditConnection',
  ConnectYourSalesRabbitAccount = 'ConnectYourSalesRabbitAccount',
  LearnMoreAboutSalesRabbit = 'LearnMoreAboutSalesRabbit',
  SalesRabbitWasConnectedSuccessfully = 'SalesRabbitWasConnectedSuccessfully',
  UnfortunatelyTheSalesRabbitWasNotConnected = 'UnfortunatelyTheSalesRabbitWasNotConnected',
  SalesRabbitWasEditedSuccessfully = 'SalesRabbitWasEditedSuccessfully',
  UnfortunatelyTheSalesRabbitWasNotEdited = 'UnfortunatelyTheSalesRabbitWasNotEdited',
  SalesRabbitWasDisconnectedSuccessfully = 'SalesRabbitWasDisconnectedSuccessfully',
  UnfortunatelyTheSalesRabbitWasNotDisconnected = 'UnfortunatelyTheSalesRabbitWasNotDisconnected',
  ConnectYourEagleviewAccount = 'ConnectYourEagleviewAccount',
  LearnMoreAboutEagleview = 'LearnMoreAboutEagleview',
  EagleviewWasConnectedSuccessfully = 'EagleviewWasConnectedSuccessfully',
  UnfortunatelyTheEagleviewWasNotConnected = 'UnfortunatelyTheEagleviewWasNotConnected',
  AddAccount = 'AddAccount',
  ActiveAccountsAmount = 'ActiveAccountsAmount',
  SomeAccountsActive = 'SomeAccountsActive',
  EagleViewWasDisconnectedSuccessfully = 'EagleViewWasDisconnectedSuccessfully',
  UnfortunatelyTheEagleViewWasNotDisconnected = 'UnfortunatelyTheEagleViewWasNotDisconnected',
  DisconnectAccount = 'DisconnectAccount',
  SelectAccountWhichWouldBeDisconnected = 'SelectAccountWhichWouldBeDisconnected',
  ManageAllYourConnectedAccount = 'ManageAllYourConnectedAccount',
  ChooseAssociatedMarketsFromTheList = 'ChooseAssociatedMarketsFromTheList',
  SomeMarketsAreNotAssociatedWithAnAccount = 'SomeMarketsAreNotAssociatedWithAnAccount',
  MarketsAreNotAssociatedWithAnAccount = 'MarketsAreNotAssociatedWithAnAccount',
  AccountName = 'AccountName',
  ReconnectAccount = 'ReconnectAccount',
  EagleviewWasEditedSuccessfully = 'EagleviewWasEditedSuccessfully',
  UnfortunatelyTheEagleviewWasNotEdited = 'UnfortunatelyTheEagleviewWasNotEdited',
  Okay = 'Okay',
  SelectedMarketsWillBeExcluded = 'SelectedMarketsWillBeExcluded',
  AccountOrdinal = 'AccountOrdinal',
  AssociatedWithAccount = 'AssociatedWithAccount',
  ThisAccountAlreadyExists = 'ThisAccountAlreadyExists',
  ToConnectANewAccount = 'ToConnectANewAccount',
  ThisAccountAlreadyConnected = 'ThisAccountAlreadyConnected',
  PleaseTryAnotherEmail = 'PleaseTryAnotherEmail',
  Existing = 'Existing',
  RecurlyAccount = 'RecurlyAccount',
  CompanyOwnersIfoWillBeUsed = 'CompanyOwnersIfoWillBeUsed',
  EditSubscription = 'EditSubscription',
  SubscriptionHasBeenUpdated = 'SubscriptionHasBeenUpdated',
  UnfortunatelyTheSubscriptionHasNotBeenUpdated = 'UnfortunatelyTheSubscriptionHasNotBeenUpdated',
  GAFQuickMeasureFullReport = 'GAFQuickMeasureFullReport',
  InProgress = 'InProgress',
  Declined = 'Declined',
  Completed = 'Completed',
  Failed = 'Failed',
  YourMeasurementIsOrdered = 'YourMeasurementIsOrdered',
  ReportDate = 'ReportDate',
  PleaseContactUsIfAnyQuestions = 'PleaseContactUsIfAnyQuestions',
  SeeFullReport = 'SeeFullReport',
  SeePropertyOwnerReport = 'SeePropertyOwnerReport',
  See3DReport = 'See3DReport',
  RoofArea = 'RoofArea',
  RoofFacets = 'RoofFacets',
  Pitch = 'Pitch',
  Eaves = 'Eaves',
  Hips = 'Hips',
  Rakes = 'Rakes',
  Ridges = 'Ridges',
  Valleys = 'Valleys',
  Flash = 'Flash',
  RoofleStep = 'RoofleStep',
  DripEdge = 'DripEdge',
  LeakBarrier = 'LeakBarrier',
  RidgeCap = 'RidgeCap',
  IceAndWaterEaves = 'IceAndWaterEaves',
  IceAndWaterValleys = 'IceAndWaterValleys',
  IceAndWaterRakes = 'IceAndWaterRakes',
  IceAndWaterStepFlashings = 'IceAndWaterStepFlashings',
  IceAndWaterHeadwallFlashings = 'IceAndWaterHeadwallFlashings',
  IceAndWaterPenetrations = 'IceAndWaterPenetrations',
  Starter = 'Starter',
  DownloadXML = 'DownloadXML',
  DownloadPDF = 'DownloadPDF',
  FullReport = 'FullReport',
  PropertyOwnerReport = 'PropertyOwnerReport',
  PrimaryPayment = 'PrimaryPayment',
  Expire = 'Expire',
  PaymentMethod = 'PaymentMethod',
  CreditCard = 'CreditCard',
  ACH = 'ACH',
  CardNumberReq = 'CardNumberReq',
  ExpirationDateReq = 'ExpirationDateReq',
  MM = 'MM',
  YYYY = 'YYYY',
  CVVReq = 'CVVReq',
  AddressData = 'AddressData',
  CurrentCardNumber = 'CurrentCardNumber',
  EditPaymentDetails = 'EditPaymentDetails',
  AddDetails = 'AddDetails',
  AccountNumber = 'AccountNumber',
  ConfirmAccountNumber = 'ConfirmAccountNumber',
  CountryReq = 'CountryReq',
  Address1Req = 'Address1Req',
  Address2 = 'Address2',
  ZipPostalReq = 'ZipPostalReq',
  IAcceptThePrivacyPolicy = 'IAcceptThePrivacyPolicy',
  NewPaymentHasBeen = 'NewPaymentHasBeen',
  UnfortunatelyTheNewPayment = 'UnfortunatelyTheNewPayment',
  CurrentBankAccountNumber = 'CurrentBankAccountNumber',
  Automations = 'Automations',
  MeasurementOrderSettings = 'MeasurementOrderSettings',
  AutomateMeasurementOrdersHint = 'AutomateMeasurementOrdersHint',
  TurnOnMeasurementOrdering = 'TurnOnMeasurementOrdering',
  PleaseSelectTheMeasurementReportYouWantToOrderAutomatically = 'PleaseSelectTheMeasurementReportYouWantToOrderAutomatically',
  PleaseSelectBackupMeasurementReport = 'PleaseSelectBackupMeasurementReport',
  YouHaveNoMeasurementCompanyConnected = 'YouHaveNoMeasurementCompanyConnected',
  EagleviewAccountsIsNotCurrentlyConnected = 'EagleviewAccountsIsNotCurrentlyConnected',
  NotAvailable = 'NotAvailable',
  MeasurementsReport = 'MeasurementsReport',
  ReportHasBeenOrdered = 'ReportHasBeenOrdered',
  ReportHasNotBeenOrdered = 'ReportHasNotBeenOrdered',
  BackToBilling = 'BackToBilling',
  SelectACreditsPackage = 'SelectACreditsPackage',
  AmountCredits = 'AmountCredits',
  SaveAmount = 'SaveAmount',
  OrderSummary = 'OrderSummary',
  CouponCode = 'CouponCode',
  Discount = 'Discount',
  OrderTotal = 'OrderTotal',
  CouponIsNotValid = 'CouponIsNotValid',
  Checkout = 'Checkout',
  YourRequestToBuyCredits = 'YourRequestToBuyCredits',
  UnfortunatelyTheRequestToBuyCredits = 'UnfortunatelyTheRequestToBuyCredits',
  Trial = 'Trial',
  AmountPending = 'AmountPending',
  TrialStatus = 'TrialStatus',
  Swap = 'Swap',
  Collapse = 'Collapse',
  Expand = 'Expand',
  PleaseSaveYourChangesFirst = 'PleaseSaveYourChangesFirst',
  TheCustomerChoseThisOption = 'TheCustomerChoseThisOption',
  IncludedByDefaultCanNotBeExcluded = 'IncludedByDefaultCanNotBeExcluded',
  Include = 'Include',
  Exclude = 'Exclude',
  OpenMeasurement = 'OpenMeasurement',
  OneAvailableTrialCredit = 'OneAvailableTrialCredit',
  GetMoreCreditsToContinueWithGAF = 'GetMoreCreditsToContinueWithGAF',
  AvailableCredits = 'AvailableCredits',
  NoAvailableCredits = 'NoAvailableCredits',
  None = 'None',
  Cancelled = 'Cancelled',
  VerificationHold = 'VerificationHold',
  InIntake = 'InIntake',
  IntakePartialActive = 'IntakePartialActive',
  IntakeDecline = 'IntakeDecline',
  ReorderMesurements = 'ReorderMesurements',
  AccountExist = 'AccountExist',
  CommercialComplex = 'CommercialComplex',
  PleaseChooseDeliveryTime = 'PleaseChooseDeliveryTime',
  DeliveryTime = 'DeliveryTime',
  RegularDelivery = 'RegularDelivery',
  ExpressDelivery = 'ExpressDelivery',
  ThreeHourDelivery = 'ThreeHourDelivery',
  QuickDelivery = 'QuickDelivery',
  DefaultTurnaroundTimeOf3BusinessDays = 'DefaultTurnaroundTimeOf3BusinessDays',
  DefaultTurnaroundTimeOf1BusinessDay = 'DefaultTurnaroundTimeOf1BusinessDay',
  DefaultTurnaroundTimeOf3BusinessHours = 'DefaultTurnaroundTimeOf3BusinessHours',
  DefaultTurnaroundTimeOf2BusinessHours = 'DefaultTurnaroundTimeOf2BusinessHours',
  EagleviewBidPerfectMeasurements = 'EagleviewBidPerfectMeasurements',
  EagleviewPremiumMeasurements = 'EagleviewPremiumMeasurements',
  SelectTheAmountOfCredits = 'SelectTheAmountOfCredits',
  AmountOfCredits = 'AmountOfCredits',
  AutoRenewalOption = 'AutoRenewalOption',
  AddMoreCreditsAtThreeOrLess = 'AddMoreCreditsAtThreeOrLess',
  CustomRenewDate = 'CustomRenewDate',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  DayOfTheWeek = 'DayOfTheWeek',
  WeeklyAutoRenewal = 'WeeklyAutoRenewal',
  MonthlyAutoRenewal = 'MonthlyAutoRenewal',
  AutoRenewsAt3Credits = 'AutoRenewsAt3Credits',
  AreYouSureYouWantToCancel = 'AreYouSureYouWantToCancel',
  AfterCancellingYourAccountWillNoLonger = 'AfterCancellingYourAccountWillNoLonger',
  YesCancel = 'YesCancel',
  RenewOptionHasBeenCanceled = 'RenewOptionHasBeenCanceled',
  UnfortunatelyYourRenewOption = 'UnfortunatelyYourRenewOption',
  GetFinalProposal = 'GetFinalProposal',
  WeAreWorkingOnYourFinalProposal = 'WeAreWorkingOnYourFinalProposal',
  AccountAssociatedWithYourMarket = 'AccountAssociatedWithYourMarket',
  Pending = 'Pending',
  NumberOfStories = 'NumberOfStories',
  PredominantPitch = 'PredominantPitch',
  StructureComplexity = 'StructureComplexity',
  RoofPitches = 'RoofPitches',
  AreaSqFt = 'AreaSqFt',
  PercentOfRoof = 'PercentOfRoof',
  AreasPerPitch = 'AreasPerPitch',
  Structure = 'Structure',
  Complexity = 'Complexity',
  PleaseReorderTheReportOrContactOurSupport = 'PleaseReorderTheReportOrContactOurSupport',
  PleaseReorderTheReportOrContactEagleviewSupport = 'PleaseReorderTheReportOrContactEagleviewSupport',
  EagleViewAccountIsNotConnected = 'EagleViewAccountIsNotConnected',
  Branding = 'Branding',
  BeAwareTurningThisOnWillReplaceYourProductCallToAction = 'BeAwareTurningThisOnWillReplaceYourProductCallToAction',
  YouHaveZeroMeasurementCreditsLeft = 'YouHaveZeroMeasurementCreditsLeft',
  ThisReportRequiresThreeCredits = 'ThisReportRequiresThreeCredits',
  SuggestedSlopeDescription = 'SuggestedSlopeDescription',
  SuggestedAngle = 'SuggestedAngle',
  IAgreeToGAFQuickMeasureReportTerms = 'IAgreeToGAFQuickMeasureReportTerms',
  TermsAndConditions = 'TermsAndConditions',
  CopyScript = 'CopyScript',
  ProposalPending = 'ProposalPending',
  ClosePage = 'ClosePage',
  PleaseProvideValidAccountName = 'PleaseProvideValidAccountName',
  PleaseContactYourCompanyAdminToGetMoreCredits = 'PleaseContactYourCompanyAdminToGetMoreCredits',
  On = 'On',
  Off = 'Off',
  PleaseUpdateYourBillingInformation = 'PleaseUpdateYourBillingInformation',
  CurrentTurnaroundTime = 'CurrentTurnaroundTime',
  sqft = 'sqft',
  AccountNameMustBeUniqueInTheCompany = 'AccountNameMustBeUniqueInTheCompany',
  ToHaveAccessToTheBeaconPro = 'ToHaveAccessToTheBeaconPro',
  Credentials = 'Credentials',
  ConnectYourBeaconAccount = 'ConnectYourBeaconAccount',
  LearnMoreAboutBeacon = 'LearnMoreAboutBeacon',
  LoginReq = 'LoginReq',
  SaveAndContinue = 'SaveAndContinue',
  LoginAndOrPasswordIsIncorrect = 'LoginAndOrPasswordIsIncorrect',
  Orders = 'Orders',
  MarketList = 'MarketList',
  SelectAMarketForAssociation = 'SelectAMarketForAssociation',
  PleaseSelectABranchForMarket = 'PleaseSelectABranchForMarket',
  Branch = 'Branch',
  BranchReq = 'BranchReq',
  TemplateName = 'TemplateName',
  LastModified = 'LastModified',
  PleaseSelectABranch = 'PleaseSelectABranch',
  ToAddTemplates = 'ToAddTemplates',
  AreYouSureYouWantToDisconnect = 'AreYouSureYouWantToDisconnect',
  AllDraftOrdersWillBeDeleted = 'AllDraftOrdersWillBeDeleted',
  YesDisconnect = 'YesDisconnect',
  BeaconProPlusHasBeenDisconnected = 'BeaconProPlusHasBeenDisconnected',
  UnfortunatelyBeaconProPlusHasNotBeenDisconnected = 'UnfortunatelyBeaconProPlusHasNotBeenDisconnected',
  Branches = 'Branches',
  ToUnlockOrderingPleaseAssociateMarkets = 'ToUnlockOrderingPleaseAssociateMarkets',
  RemindYouThatYouHaveUnassociated = 'RemindYouThatYouHaveUnassociated',
  AccountIsDisconnectedCheckYourLoginAndPassword = 'AccountIsDisconnectedCheckYourLoginAndPassword',
  ProvideYourNewLoginAndPassword = 'ProvideYourNewLoginAndPassword',
  YouHaveNotAssociatedAllMarkets = 'YouHaveNotAssociatedAllMarkets',
  CongratsYouHaveSuccessfully = 'CongratsYouHaveSuccessfully',
  ToAutoPopulateOrderItemQuantities = 'ToAutoPopulateOrderItemQuantities',
  SomeMarketsStillNeedToBeAssociated = 'SomeMarketsStillNeedToBeAssociated',
  AssociateMarkets = 'AssociateMarkets',
  SetupFormulas = 'SetupFormulas',
  Later = 'Later',
  MyOrders = 'MyOrders',
  BeaconProPlusIsDisconnected = 'BeaconProPlusIsDisconnected',
  ReconnectYourAccountIntegration = 'ReconnectYourAccountIntegration',
  ToReconnectYourAccount = 'ToReconnectYourAccount',
  OrderDate = 'OrderDate',
  Draft = 'Draft',
  Processing = 'Processing',
  Invoiced = 'Invoiced',
  DeliveryReady = 'DeliveryReady',
  PickUpReady = 'PickUpReady',
  Delivered = 'Delivered',
  OrderNumber = 'OrderNumber',
  PO = 'PO',
  TheTotalPriceIsApproximate = 'TheTotalPriceIsApproximate',
  TemplateDetails = 'TemplateDetails',
  ProductList = 'ProductList',
  ItemNumber = 'ItemNumber',
  Nickname = 'Nickname',
  Size = 'Size',
  Packaging = 'Packaging',
  Width = 'Width',
  Thickness = 'Thickness',
  Length = 'Length',
  MFG = 'MFG',
  CurrentlyOutOfStock = 'CurrentlyOutOfStock',
  AvailableToPickUpOrDelivery = 'AvailableToPickUpOrDelivery',
  OrderMaterials = 'OrderMaterials',
  EditMaterialOrder = 'EditMaterialOrder',
  CustomerInfo = 'CustomerInfo',
  MaterialTemplate = 'MaterialTemplate',
  DeliveryInfo = 'DeliveryInfo',
  ReviewOrder = 'ReviewOrder',
  MeasurementInputs = 'MeasurementInputs',
  SaveAsDraft = 'SaveAsDraft',
  SeeMaterialReport = 'SeeMaterialReport',
  CreateOrder = 'CreateOrder',
  CustomerName = 'CustomerName',
  ProjectAddress = 'ProjectAddress',
  MeasurementReport = 'MeasurementReport',
  EnterThePurchasedOrder = 'EnterThePurchasedOrder',
  InstantQuote = 'InstantQuote',
  AreYouSureYouWantToBackWithout = 'AreYouSureYouWantToBackWithout',
  SaveAsDraftAndClose = 'SaveAsDraftAndClose',
  CloseWithoutSaving = 'CloseWithoutSaving',
  PleaseSelectMeasurementReport = 'PleaseSelectMeasurementReport',
  YouHaveMissingFields = 'YouHaveMissingFields',
  ProductionRep = 'ProductionRep',
  ProductionRepsAccess = 'ProductionRepsAccess',
  ProductionRepsAccessExcludingClp = 'ProductionRepsAccessExcludingClp',
  TotalRoofArea = 'TotalRoofArea',
  TotalRoofAreaIsTheEntireRoofSquareFeet = 'TotalRoofAreaIsTheEntireRoofSquareFeet',
  SqFt = 'SqFt',
  LF = 'LF',
  QTY = 'QTY',
  Underlayment = 'Underlayment',
  StepFlashing = 'StepFlashing',
  Flashing = 'Flashing',
  Facets = 'Facets',
  Parapets = 'Parapets',
  Penetrations = 'Penetrations',
  KitchenVent = 'KitchenVent',
  BathVent = 'BathVent',
  PlumbingStack = 'PlumbingStack',
  StaticVent = 'StaticVent',
  RidgeVent = 'RidgeVent',
  IceWater = 'IceWater',
  RoofWallIntersection = 'RoofWallIntersection',
  DeckingSheathing = 'DeckingSheathing',
  OSBDecking = 'OSBDecking',
  CDXPlywood = 'CDXPlywood',
  Formulas = 'Formulas',
  AddFormula = 'AddFormula',
  FormulaDetails = 'FormulaDetails',
  GeneralInfo = 'GeneralInfo',
  NameYourFormula = 'NameYourFormula',
  FormulaGuide = 'FormulaGuide',
  FormulaNameReq = 'FormulaNameReq',
  AssociatedProducts = 'AssociatedProducts',
  AssociateBeaconProductsToThisFormula = 'AssociateBeaconProductsToThisFormula',
  FormulaReq = 'FormulaReq',
  BuildYourFormula = 'BuildYourFormula',
  FormulaPreview = 'FormulaPreview',
  ProductQuantity = 'ProductQuantity',
  ClickHereToStartBuildingFormula = 'ClickHereToStartBuildingFormula',
  AvailableInputs = 'AvailableInputs',
  DataInputsList = 'DataInputsList',
  TestFormula = 'TestFormula',
  WeProvideExample = 'WeProvideExample',
  StartCreatingYourFormula = 'StartCreatingYourFormula',
  Formula = 'Formula',
  FormulaColon = 'FormulaColon',
  Result = 'Result',
  InvalidOperator = 'InvalidOperator',
  FormulaCannotContainTwo = 'FormulaCannotContainTwo',
  YouCannotEnterMoreThanOne = 'YouCannotEnterMoreThanOne',
  FormulaMustBegin = 'FormulaMustBegin',
  SpacesIsNotAllowed = 'SpacesIsNotAllowed',
  AClosingParenthesisCannot = 'AClosingParenthesisCannot',
  InputsCannotContainNumbers = 'InputsCannotContainNumbers',
  InputsCannotContainOperators = 'InputsCannotContainOperators',
  AnOpeningParenthesis = 'AnOpeningParenthesis',
  NoCharactersOtherThanDigits = 'NoCharactersOtherThanDigits',
  PleaseCompleteOrCorrectYourFormula = 'PleaseCompleteOrCorrectYourFormula',
  NoReport = 'NoReport',
  Item = 'Item',
  Template = 'Template',
  SelectedMeasurementUnit = 'SelectedMeasurementUnit',
  ItemsAssociatedToThis = 'ItemsAssociatedToThis',
  EditFormula = 'EditFormula',
  DuplicateFormula = 'DuplicateFormula',
  DeleteFormula = 'DeleteFormula',
  AssociateFormula = 'AssociateFormula',
  FormulaHasBeenDeleted = 'FormulaHasBeenDeleted',
  UnfortunatelyFormulaHasNotBeenDeleted = 'UnfortunatelyFormulaHasNotBeenDeleted',
  EstimateWithSum = 'EstimateWithSum',
  HideExcludedProducts = 'HideExcludedProducts',
  ShowExcludedProducts = 'ShowExcludedProducts',
  ItemIsCurrentlyOOSButCanStillBeIncludedInYourOrder = 'ItemIsCurrentlyOOSButCanStillBeIncludedInYourOrder',
  JobAccount = 'JobAccount',
  SelectJobAccount = 'SelectJobAccount',
  SelectTemplate = 'SelectTemplate',
  PleaseEnterQuantityOrExcludeThisItemFromTheList = 'PleaseEnterQuantityOrExcludeThisItemFromTheList',
  OrderDraftHasBeenSavedSuccessfully = 'OrderDraftHasBeenSavedSuccessfully',
  UnfortunatelyTheOrderDraftHasNotBeenSaved = 'UnfortunatelyTheOrderDraftHasNotBeenSaved',
  HomeownerDetails = 'HomeownerDetails',
  TotalSqFt = 'TotalSqFt',
  AdditionalCost = 'AdditionalCost',
  AdditionalCostAndOrDiscount = 'AdditionalCostAndOrDiscount',
  YourRoofDetails = 'YourRoofDetails',
  ExpectedPriceRangeForThisProduct = 'ExpectedPriceRangeForThisProduct',
  Close = 'Close',
  SaveAndClose = 'SaveAndClose',
  SelectTheMarketFromTheList = 'SelectTheMarketFromTheList',
  AreYouSureYouWantToCloseWithoutSavingChanges = 'AreYouSureYouWantToCloseWithoutSavingChanges',
  Quotes = 'Quotes',
  InstantQuotes = 'InstantQuotes',
  DefaultSlopeSelection = 'DefaultSlopeSelection',
  DefaultSlopeCaption = 'DefaultSlopeCaption',
  UseDefaultSlopeToAllMarkets = 'UseDefaultSlopeToAllMarkets',
  SteepSlopeCharge = 'SteepSlopeCharge',
  SteepSlopeChargeCaption = 'SteepSlopeChargeCaption',
  SteepSlopeChargeProductCaption = 'SteepSlopeChargeProductCaption',
  Fixed = 'Fixed',
  NotAppliedToCustomProducts = 'NotAppliedToCustomProducts',
  NotAppliedToCustomProductsWithFixedPrice = 'NotAppliedToCustomProductsWithFixedPrice',
  NotAvailableToCustomProductsWithFixedPrice = 'NotAvailableToCustomProductsWithFixedPrice',
  AddWasteFactor = 'AddWasteFactor',
  AddWasteFactorCaption = 'AddWasteFactorCaption',
  DefaultWasteFactorCaption = 'DefaultWasteFactorCaption',
  WasteFactorProductCaption = 'WasteFactorProductCaption',
  DefaultWasteFactor = 'DefaultWasteFactor',
  UseAiToDetermineWaste = 'UseAiToDetermineWaste',
  UseAiToDetermineWasteCaption = 'UseAiToDetermineWasteCaption',
  PriceRange = 'PriceRange',
  PriceRangeCaption = 'PriceRangeCaption',
  SetRangePercentage = 'SetRangePercentage',
  ShowPriceRange = 'ShowPriceRange',
  SetRangeType = 'SetRangeType',
  TotalPricePlusRange = 'TotalPricePlusRange',
  TotalPricePlusRangeCaption = 'TotalPricePlusRangeCaption',
  TotalPricePlusMinusRange = 'TotalPricePlusMinusRange',
  TotalPricePlusMinusRangeCaption = 'TotalPricePlusMinusRangeCaption',
  TotalPriceMinusRange = 'TotalPriceMinusRange',
  TotalPriceMinusRangeCaption = 'TotalPriceMinusRangeCaption',
  AllSettingAppliedToRepQuote = 'AllSettingAppliedToRepQuote',
  AdditionalCostsSettings = 'AdditionalCostsSettings',
  AdditionalCostsSettingsCaption = 'AdditionalCostsSettingsCaption',
  MultiStoryCharge = 'MultiStoryCharge',
  MultiStoryChargeCaption = 'MultiStoryChargeCaption',
  Question = 'Question',
  AnswerOptions = 'AnswerOptions',
  MultiStoryCostsQuestion = 'MultiStoryCostsQuestion',
  NStories = 'NStories',
  Layers = 'Layers',
  LayersCaption = 'LayersCaption',
  LayersCostsQuestion = 'LayersCostsQuestion',
  NLayers = 'NLayers',
  Chimney = 'Chimney',
  ChimneyCaption = 'ChimneyCaption',
  ChimneyFlashing = 'ChimneyFlashing',
  ChimneyFlashingQuestion = 'ChimneyFlashingQuestion',
  NChimneys = 'NChimneys',
  SkylightFlashing = 'SkylightFlashing',
  SkylightFlashingCaption = 'SkylightFlashingCaption',
  SkylightFlashingQuestion = 'SkylightFlashingQuestion',
  NSkylights = 'NSkylights',
  RoofCondition = 'RoofCondition',
  RoofConditionCaption = 'RoofConditionCaption',
  RoofConditionQuestion = 'RoofConditionQuestion',
  AlgaeStains = 'AlgaeStains',
  HailDamage = 'HailDamage',
  WindDamage = 'WindDamage',
  MissingShingles = 'MissingShingles',
  RoofLeaks = 'RoofLeaks',
  Sagging = 'Sagging',
  StructuralDamage = 'StructuralDamage',
  NewDecking = 'NewDecking',
  NewDeckingCaption = 'NewDeckingCaption',
  NewDeckingQuestion = 'NewDeckingQuestion',
  FullDeckingReplacement = 'FullDeckingReplacement',
  Partial = 'Partial',
  EnterTheFixedPricePerSheet = 'EnterTheFixedPricePerSheet',
  UsersWillBeAbleToSpecifyTheQuantity = 'UsersWillBeAbleToSpecifyTheQuantity',
  EnterPricePerSquare = 'EnterPricePerSquare',
  DifficultAccess = 'DifficultAccess',
  DifficultAccessCaption = 'DifficultAccessCaption',
  DifficultAccessQuestion = 'DifficultAccessQuestion',
  OtherExtraCosts = 'OtherExtraCosts',
  OtherExtraCostsCaption = 'OtherExtraCostsCaption',
  OtherExtraCostsWarning = 'OtherExtraCostsWarning',
  ChangeSelectionTypeWarning = 'ChangeSelectionTypeWarning',
  AddOtherExtraCosts = 'AddOtherExtraCosts',
  PerLinealFeet = 'PerLinealFeet',
  PerEach = 'PerEach',
  PerBundle = 'PerBundle',
  PerPiece = 'PerPiece',
  PerRoll = 'PerRoll',
  PerCarton = 'PerCarton',
  PerTube = 'PerTube',
  PerCan = 'PerCan',
  PerCase = 'PerCase',
  OptionName = 'OptionName',
  Custom = 'Custom',
  CustomUnitName = 'CustomUnitName',
  ToTurnOffFillInRequiredFields = 'ToTurnOffFillInRequiredFields',
  PleaseAnswerTheQuestionsAboutRoof = 'PleaseAnswerTheQuestionsAboutRoof',
  PleaseAnswerTheQuestionsAboutRoofPreview = 'PleaseAnswerTheQuestionsAboutRoofPreview',
  FormPreview = 'FormPreview',
  AllowMultipleOptions = 'AllowMultipleOptions',
  SelectTheMeasurementOption = 'SelectTheMeasurementOption',
  TheWasteFactorMayBeAdjustedAutomatically = 'TheWasteFactorMayBeAdjustedAutomatically',
  TheQuoteIsPoweredBy = 'TheQuoteIsPoweredBy',
  TheMapAndStructuresAreNotAvailable = 'TheMapAndStructuresAreNotAvailable',
  ThisImageIsForIllustrationOnly = 'ThisImageIsForIllustrationOnly',
  Original = 'Original',
  ExtraLayerRemoval = 'ExtraLayerRemoval',
  HighRoof = 'HighRoof',
  TheAdditionalCostIsAlreadyIncluded = 'TheAdditionalCostIsAlreadyIncluded',
  OfInstantPrice = 'OfInstantPrice',
  OfTotalCost = 'OfTotalCost',
  Quote = 'Quote',
  DefaultWaste = 'DefaultWaste',
  ToUseThisSettingTurnOnInQuoteSettings = 'ToUseThisSettingTurnOnInQuoteSettings',
  ToUseThisSettingTurnOnInAdditionalCost = 'ToUseThisSettingTurnOnInAdditionalCost',
  WasteFactorIsNotAvailableForProductsWithAFixedPrice = 'WasteFactorIsNotAvailableForProductsWithAFixedPrice',
  UseInstantQuotesSettings = 'UseInstantQuotesSettings',
  UseInstantQuotesSettingsCaption = 'UseInstantQuotesSettingsCaption',
  IncludeAdditionalCost = 'IncludeAdditionalCost',
  IncludeAdditionalCostCaption = 'IncludeAdditionalCostCaption',
  RoofType = 'RoofType',
  RoofTypeCaption = 'RoofTypeCaption',
  RoofTypeQuestion = 'RoofTypeQuestion',
  AsphaltShingleRemoval = 'AsphaltShingleRemoval',
  MetalRoofRemoval = 'MetalRoofRemoval',
  SyntheticRoofRemoval = 'SyntheticRoofRemoval',
  TileRoofRemoval = 'TileRoofRemoval',
  WoodShakeRemoval = 'WoodShakeRemoval',
  PleaseEnterValidQuantity = 'PleaseEnterValidQuantity',
  TheAdditionalCostIsAlreadyIncludedInTheRecommendedProducts = 'TheAdditionalCostIsAlreadyIncludedInTheRecommendedProducts',
  HideDetails = 'HideDetails',
  ShowDetails = 'ShowDetails',
  EnterNewWasteFactor = 'EnterNewWasteFactor',
  Suggested = 'Suggested',
  Default = 'Default',
  OriginalDefault = 'OriginalDefault',
  CurrentDefault = 'CurrentDefault',
  ThisQuestionHasBeenRemoved = 'ThisQuestionHasBeenRemoved',
  ThisProductsPricingDoesNotIncludeAdditionalCosts = 'ThisProductsPricingDoesNotIncludeAdditionalCosts',
  NoAdditionalCost = 'NoAdditionalCost',
  EditAdditionalCost = 'EditAdditionalCost',
  PleaseSelectTheDiscountTypeAndItsAmount = 'PleaseSelectTheDiscountTypeAndItsAmount',
  Percentage = 'Percentage',
  TotalAdditionalCost = 'TotalAdditionalCost',
  OffWhat = 'OffWhat',
  PleaseEnterANumberWithNoMoreThan2DecimalPlaces = 'PleaseEnterANumberWithNoMoreThan2DecimalPlaces',
  TheDiscountCanNotBeDuplicated = 'TheDiscountCanNotBeDuplicated',
  NoDiscount = 'NoDiscount',
  ChangeWFToTheCustomNewDefaultAndSuggested = 'ChangeWFToTheCustomNewDefaultAndSuggested',
  UsersCanChangeWFOnlyToTheCustomWF = 'UsersCanChangeWFOnlyToTheCustomWF',
  UsersCanEnterCustomWFOrDefaultWF = 'UsersCanEnterCustomWFOrDefaultWF',
  NotSet = 'NotSet',
  TheTotalPriceIncludesAllCharges = 'TheTotalPriceIncludesAllCharges',
  ThePriceRangeIncludesAllCharges = 'ThePriceRangeIncludesAllCharges',
  TheInstantQuoteIncludessWasteAndSteepSlope = 'TheInstantQuoteIncludessWasteAndSteepSlope',
  TotalAdditionalCostDiscountValidation = 'TotalAdditionalCostDiscountValidation',
  TotalDiscountValidation = 'TotalDiscountValidation',
  TotalDiscountAndTotalAdditionalCostDiscountValidation = 'TotalDiscountAndTotalAdditionalCostDiscountValidation',
  TheAdditionalCostDiscountIsExcluded = 'TheAdditionalCostDiscountIsExcluded',
  StructureNameMustBeUnique = 'StructureNameMustBeUnique',
  NewPriceIs = 'NewPriceIs',
  HasBeenRemoved = 'HasBeenRemoved',
  UpdateAll = 'UpdateAll',
  PricingHasBeenChanged = 'PricingHasBeenChanged',
  ML = 'ML',
  BeaconWasConnectedSuccessfully = 'BeaconWasConnectedSuccessfully',
  ShippingDetails = 'ShippingDetails',
  ShippingMethod = 'ShippingMethod',
  ShipToAddress = 'ShipToAddress',
  PickUpInStore = 'PickUpInStore',
  ShippingTime = 'ShippingTime',
  Anytime = 'Anytime',
  Afternoon = 'Afternoon',
  Morning = 'Morning',
  SpecialRequest = 'SpecialRequest',
  ShippingAddress = 'ShippingAddress',
  OrderNotes = 'OrderNotes',
  ProvideAdditionalInstructions = 'ProvideAdditionalInstructions',
  OrderNDetails = 'OrderNDetails',
  OrderN = 'OrderN',
  NeedToMakeChangesToYourOrder = 'NeedToMakeChangesToYourOrder',
  ProcessedBy = 'ProcessedBy',
  DeliveryTracking = 'DeliveryTracking',
  ShippingDate = 'ShippingDate',
  DeliveryStatus = 'DeliveryStatus',
  OrderList = 'OrderList',
  BelowAreDetails = 'BelowAreDetails',
  Quantity = 'Quantity',
  SubTotal = 'SubTotal',
  OtherCharges = 'OtherCharges',
  Tax = 'Tax',
  WhatIsOtherCharges = 'WhatIsOtherCharges',
  MaterialOrdersAreNotAvailableForThisProject = 'MaterialOrdersAreNotAvailableForThisProject',
  ToAccessTheBeaconProIntegration = 'ToAccessTheBeaconProIntegration',
  ForAccessToMaterialOrdersReconnect = 'ForAccessToMaterialOrdersReconnect',
  ForAccessToMaterialOrdersAssociate = 'ForAccessToMaterialOrdersAssociate',
  ReconnectYourAccountIntegrationForAccessToMaterialOrders = 'ReconnectYourAccountIntegrationForAccessToMaterialOrders',
  ToGetDataPleaseClickTheOrderMaterials = 'ToGetDataPleaseClickTheOrderMaterials',
  OrderCouldNotBeDisplayed = 'OrderCouldNotBeDisplayed',
  OrderWasPlacedForThisLead = 'OrderWasPlacedForThisLead',
  AssociateYourMarketWithABeacon = 'AssociateYourMarketWithABeacon',
  ThisProjectIsOldPleaseSelectAMarket = 'ThisProjectIsOldPleaseSelectAMarket',
  MarketIsNoLongerAvailableOrHasNoAssociation = 'MarketIsNoLongerAvailableOrHasNoAssociation',
  IfYouWantToUpdateTheCurrentMarket = 'IfYouWantToUpdateTheCurrentMarket',
  SelectBranch = 'SelectBranch',
  Associate = 'Associate',
  WasteFactor = 'WasteFactor',
  WasteFactorPercent = 'WasteFactorPercent',
  ProductIsAlreadyAssociated = 'ProductIsAlreadyAssociated',
  ProductCanNotBeAssigned = 'ProductCanNotBeAssigned',
  NoBranch = 'NoBranch',
  PriceWillBeCalculatedAtInvoicing = 'PriceWillBeCalculatedAtInvoicing',
  DeliveryDetails = 'DeliveryDetails',
  Unit = 'Unit',
  ItemTotal = 'ItemTotal',
  POcantBeLonger = 'POcantBeLonger',
  PleaseFillInAllRequired = 'PleaseFillInAllRequired',
  YourPOIsAnyNameOrNumber = 'YourPOIsAnyNameOrNumber',
  YourOrderHasBeenPlaced = 'YourOrderHasBeenPlaced',
  EditOrder = 'EditOrder',
  DeleteDraft = 'DeleteDraft',
  OpenOrderDetails = 'OpenOrderDetails',
  OpenLeadDetails = 'OpenLeadDetails',
  AreYouSureDeleteOrderDraft = 'AreYouSureDeleteOrderDraft',
  BeAwareTheOrderWillBeDeleted = 'BeAwareTheOrderWillBeDeleted',
  ThereAreNoProductsYet = 'ThereAreNoProductsYet',
  YourOrderHasNotBeenPlaced = 'YourOrderHasNotBeenPlaced',
  CostForecastDoesntIncludeItems = 'CostForecastDoesntIncludeItems',
  PleaseSpecifyInInstructions = 'PleaseSpecifyInInstructions',
  PleaseEnterANumberWithNoMore = 'PleaseEnterANumberWithNoMore',
  PleaseEnterAWholeNumber = 'PleaseEnterAWholeNumber',
  PleaseEnterAWholeNumberFromTo = 'PleaseEnterAWholeNumberFromTo',
  TaxAndShippingAreEstimated = 'TaxAndShippingAreEstimated',
  SelectTime = 'SelectTime',
  SomeWebPlatformsDoNotSupportRepLinks = 'SomeWebPlatformsDoNotSupportRepLinks',
  SomeWebPlatformsDoNotSupportSalesRabbit = 'SomeWebPlatformsDoNotSupportSalesRabbit',
  Chimneys = 'Chimneys',
  Skylights = 'Skylights',
  HeadwallFlashings = 'HeadwallFlashings',
  StepFlashings = 'StepFlashings',
  UpdateTo = 'UpdateTo',
  Current = 'Current',
  PricingHasChangedToAmmendAdditionaCost = 'PricingHasChangedToAmmendAdditionaCost',
  EditingDiscountIsNotAvailable = 'EditingDiscountIsNotAvailable',
  SqFtOf = 'SqFtOf',
  AdditionalCostIsUnavailable = 'AdditionalCostIsUnavailable',
  SetUpAdditionalCostQuestions = 'SetUpAdditionalCostQuestions',
  SetUp = 'SetUp',
  ThisFeatureHasNotBeenSetUpByYourCompanyAdmin = 'ThisFeatureHasNotBeenSetUpByYourCompanyAdmin',
  AdditionalCostHasBeenTurnedOffForTheAccount = 'AdditionalCostHasBeenTurnedOffForTheAccount',
  AdditionalCostHasBeenTurnedOffForTheAccountUpdateAll = 'AdditionalCostHasBeenTurnedOffForTheAccountUpdateAll',
  EnterCustomButtonUrl = 'EnterCustomButtonUrl',
  EnterCustomButtonText = 'EnterCustomButtonText',
  PleaseEnterAValidUrl = 'PleaseEnterAValidUrl',
  PleaseEnterAValidCustomButtonText = 'PleaseEnterAValidCustomButtonText',
  ItIsNotAvailableForCanceledSubscription = 'ItIsNotAvailableForCanceledSubscription',
  Beta = 'Beta',
  ThisFeatureIsCurrentlyInBeta = 'ThisFeatureIsCurrentlyInBeta',
  WasteFactorIsTurnedOffInCompanySettings = 'WasteFactorIsTurnedOffInCompanySettings',
  ToChangeWasteFactorItMustBeTurnedOnFromCompanySettings = 'ToChangeWasteFactorItMustBeTurnedOnFromCompanySettings',
  ProductsThatPreviouslyHadCustomSettingsWillBeRestored = 'ProductsThatPreviouslyHadCustomSettingsWillBeRestored',
  ToSeeTheShippingAddressContact = 'ToSeeTheShippingAddressContact',
  ReconnectYourAccountIntegrationToSeeShippingAddress = 'ReconnectYourAccountIntegrationToSeeShippingAddress',
  PleaseIncludeThisItemToTheList = 'PleaseIncludeThisItemToTheList',
  ReportIsNotAvailableForThisProject = 'ReportIsNotAvailableForThisProject',
  AddNewProducts = 'AddNewProducts',
  SelectProductsYouWantToAdd = 'SelectProductsYouWantToAdd',
  SeeDetails = 'SeeDetails',
  SKU = 'SKU',
  Select = 'Select',
  ThisProductAlredyExists = 'ThisProductAlredyExists',
  RemoveFromOrder = 'RemoveFromOrder',
  SignInWith = 'SignInWith',
  ThisTemplateHasNoProductsInIt = 'ThisTemplateHasNoProductsInIt',
  TemplateIsEmpty = 'TemplateIsEmpty',
  ClpButton = 'ClpButton',
  AddThisButtonToYourWebsite = 'AddThisButtonToYourWebsite',
  ButtonBranding = 'ButtonBranding',
  PopUpDesign = 'PopUpDesign',
  LoanProductsSelection = 'LoanProductsSelection',
  YouCanChooseWhichLoan = 'YouCanChooseWhichLoan',
  SelectDefaultLoanPackage = 'SelectDefaultLoanPackage',
  ButtonType = 'ButtonType',
  BlockButton = 'BlockButton',
  BlockElements = 'BlockElements',
  InlineButton = 'InlineButton',
  InlineElements = 'InlineElements',
  ButtonDisplay = 'ButtonDisplay',
  ButtonText = 'ButtonText',
  SeePreQualifiedLoanOptions = 'SeePreQualifiedLoanOptions',
  Text = 'Text',
  BackgroundUnderPopUp = 'BackgroundUnderPopUp',
  Title = 'Title',
  EditText = 'EditText',
  EditTitleText = 'EditTitleText',
  EditDescriptionText = 'EditDescriptionText',
  EditErrorText = 'EditErrorText',
  EditMainButtonText = 'EditMainButtonText',
  AllChangesHaveBeenAppliedToClpButton = 'AllChangesHaveBeenAppliedToClpButton',
  RadioButton = 'RadioButton',
  ContractorLoanProButtonScript = 'ContractorLoanProButtonScript',
  ThisButtonWillAllowHomeowners = 'ThisButtonWillAllowHomeowners',
  EmbedThisScriptToDisplayClpButton = 'EmbedThisScriptToDisplayClpButton',
  YouHaveSentLoanApplication = 'YouHaveSentLoanApplication',
  UnfortunatelyTheLoanOption = 'UnfortunatelyTheLoanOption',
  LeaveWithoutSaving = 'LeaveWithoutSaving',
  YouAreAboutToLeaveThisPageWithoutSaving = 'YouAreAboutToLeaveThisPageWithoutSaving',
  YouAreAboutToLeaveThisPageWithoutSavingDoYouWantToSave = 'YouAreAboutToLeaveThisPageWithoutSavingDoYouWantToSave',
  ManageYourPersonalProfile = 'ManageYourPersonalProfile',
  ManageYourCompanyAccount = 'ManageYourCompanyAccount',
  ManageSubscriptionPaymentInfo = 'ManageSubscriptionPaymentInfo',
  General = 'General',
  OptimizeAllOfYourWidget = 'OptimizeAllOfYourWidget',
  ControlYourMarketServiceArea = 'ControlYourMarketServiceArea',
  SelectAndConfigureYourProduct = 'SelectAndConfigureYourProduct',
  InstallYourInstantQuoteTool = 'InstallYourInstantQuoteTool',
  SettingsAreOnlyAvailable = 'SettingsAreOnlyAvailable',
  Account = 'Account',
  CompanyHasOnlyOneMarket = 'CompanyHasOnlyOneMarket',
  GroupURLs = 'GroupURLs',
  CombineMetricsForIdenticalURLs = 'CombineMetricsForIdenticalURLs',
  MapPlatform = 'MapPlatform',
  Mapbox = 'Mapbox',
  GoogleMaps = 'GoogleMaps',
  GoogleMapsCustomersRequireAdditionalRecurly = 'GoogleMapsCustomersRequireAdditionalRecurly',
  PreviewThisCustomButton = 'PreviewThisCustomButton',
  PleaseEnterAValueFromToWithNoMoreThanTwoDecimalPlaces = 'PleaseEnterAValueFromToWithNoMoreThanTwoDecimalPlaces',
  AddNItems = 'AddNItems',
  ThisProductCannotBeAdded = 'ThisProductCannotBeAdded',
  QuoteInfo = 'QuoteInfo',
  UnfortunatelyChangesHaveNotBeenSavedPleaseTryAgain = 'UnfortunatelyChangesHaveNotBeenSavedPleaseTryAgain',
  Phones = 'Phones',
  EmailOrEmails = 'EmailOrEmails',
  ContactName = 'ContactName',
  MapType = 'MapType',
  JobNimbus = 'JobNimbus',
  ConnectYourJobNimbusAccount = 'ConnectYourJobNimbusAccount',
  APIKeyReq = 'APIKeyReq',
  APIKeyIsIncorrect = 'APIKeyIsIncorrect',
  ContactDetails = 'ContactDetails',
  JobNimbusWasConnectedSuccessfully = 'JobNimbusWasConnectedSuccessfully',
  UnfortunatelyJobNimbusWasNotConnected = 'UnfortunatelyJobNimbusWasNotConnected',
  AreYouSureDisconnectJobNimbus = 'AreYouSureDisconnectJobNimbus',
  JobNimbusWasDisconnectedSuccessfully = 'JobNimbusWasDisconnectedSuccessfully',
  UnfortunatelyJobNimbusWasNotDisconnected = 'UnfortunatelyJobNimbusWasNotDisconnected',
  LearnMoreAboutJobNimbus = 'LearnMoreAboutJobNimbus',
  TheDefaultContactWorkflow = 'TheDefaultContactWorkflow',
  JobNimbusContactWorkflowName = 'JobNimbusContactWorkflowName',
  JobNimbusContactWorkflowStatusName = 'JobNimbusContactWorkflowStatusName',
  JobNimbusLeadSourceName = 'JobNimbusLeadSourceName',
  JobNimbusJobWorkflowName = 'JobNimbusJobWorkflowName',
  JobNimbusJobWorkflowStatusName = 'JobNimbusJobWorkflowStatusName',
  ConnectedEmails = 'ConnectedEmails',
  Notifications = 'Notifications',
  LeadEmails = 'LeadEmails',
  FinanceEmails = 'FinanceEmails',
  NewInstantQuoteLead = 'NewInstantQuoteLead',
  GetNotifiedEveryTimeUserHasCompleted = 'GetNotifiedEveryTimeUserHasCompleted',
  AttachQuotePdf = 'AttachQuotePdf',
  IncludeAnAttachedPdf = 'IncludeAnAttachedPdf',
  ShowQuoteSqFt = 'ShowQuoteSqFt',
  DisplayTheTotalSqFt = 'DisplayTheTotalSqFt',
  PreQualificationRequests = 'PreQualificationRequests',
  GetNotifiedWhenHomeownerClicks = 'GetNotifiedWhenHomeownerClicks',
  ApplicationCompleted = 'ApplicationCompleted',
  GetNotifiedWhenHomeownerCompletes = 'GetNotifiedWhenHomeownerCompletes',
  Transactions = 'Transactions',
  GetNotifiedWhenHomeownerResponds = 'GetNotifiedWhenHomeownerResponds',
  SendLinkToCreateAccount = 'SendLinkToCreateAccount',
  // OC translations (start)
  Plan = 'Plan',
  TypeReq = 'TypeReq',
  OCRoofQuotePRO = 'OCRoofQuotePRO',
  OCRoofQuotePROUSA = 'OCRoofQuotePROUSA',
  OCRoofQuotePROCanada = 'OCRoofQuotePROCanada',
  MonthlySubscription = 'MonthlySubscription',
  AnnualRollup = 'AnnualRollup',
  AnnualDiscount = 'AnnualDiscount',
  SubscriptionType = 'SubscriptionType',
  SubscriptionPlan = 'SubscriptionPlan',
  SubscriptionRequest = 'SubscriptionRequest',
  ResetPauseRequest = 'ResetPauseRequest',
  ResetActivationRequest = 'ResetActivationRequest',
  AreYouSureYouWantToResetRequest = 'AreYouSureYouWantToResetRequest',
  RequestHasBeenResetedSuccessfully = 'RequestHasBeenResetedSuccessfully',
  UnfortunatelyTheRequestHasNotBeenReseted = 'UnfortunatelyTheRequestHasNotBeenReseted',
  RequestWithType = 'RequestWithType',
  Activation = 'Activation',
  InstallationPackage = 'InstallationPackage',
  PoweredBy = 'PoweredBy',
  Specifications = 'Specifications',
  TechnicalCharacteristics = 'TechnicalCharacteristics',
  TechnicalDocuments = 'TechnicalDocuments',
  WindResistance = 'WindResistance',
  AlgaeResistance = 'AlgaeResistance',
  NominalSize = 'NominalSize',
  Exposure = 'Exposure',
  ShinglesPerSquare = 'ShinglesPerSquare',
  BundlesPerSq = 'BundlesPerSq',
  CoveragePerSq = 'CoveragePerSq',
  ApplicableStandards = 'ApplicableStandards',
  TotalProtection = 'TotalProtection',
  TotalProtectionRoofingSystem = 'TotalProtectionRoofingSystem',
  DesignInspiration = 'DesignInspiration',
  MapSettings = 'MapSettings',
  Centerpoint = 'Centerpoint',
  CustomImage = 'CustomImage',
  UseTheLatLong = 'UseTheLatLong',
  MapPlaceholderMediaUploadHint = 'MapPlaceholderMediaUploadHint',
  MapPlaceholderMediaUploadError = 'MapPlaceholderMediaUploadError',
  UploadImage = 'UploadImage',
  Control = 'Control',
  ShowSquareFootageInfoTooltip = 'ShowSquareFootageInfoTooltip',
  UseOwensCorningBranding = 'UseOwensCorningBranding',
  UseOwensCorningBrandingHint = 'UseOwensCorningBrandingHint',
  YourFirstProductShouldBeOwensCorning = 'YourFirstProductShouldBeOwensCorning',
  WarrantyDescription = 'WarrantyDescription',
  WarrantyCoverageType = 'WarrantyCoverageType',
  SelectWarrantyCoverageTypeForTheProduct = 'SelectWarrantyCoverageTypeForTheProduct',
  NoWarranty = 'NoWarranty',
  PlatinumProtectionLimitedWarranty = 'PlatinumProtectionLimitedWarranty',
  PreferredProtectionLimitedWarranty = 'PreferredProtectionLimitedWarranty',
  SystemProtectionLimitedWarranty = 'SystemProtectionLimitedWarranty',
  StandardProductLimitedWarranty = 'StandardProductLimitedWarranty',
  StandardProductLimitedWarrantyDescription = 'StandardProductLimitedWarrantyDescription',
  PlatinumProtectionLimitedWarrantyDescription = 'PlatinumProtectionLimitedWarrantyDescription',
  PreferredProtectionLimitedWarrantyDescription = 'PreferredProtectionLimitedWarrantyDescription',
  SystemProtectionLimitedWarrantyDescription = 'SystemProtectionLimitedWarrantyDescription',
  ProductsDefectsCoverage = 'ProductsDefectsCoverage',
  WorkmanshipCoverage = 'WorkmanshipCoverage',
  KeyRequirements = 'KeyRequirements',
  Lifetime = 'Lifetime',
  OverallWarrantyLength = 'OverallWarrantyLength',
  OverallWarrantyLengthDescription = 'OverallWarrantyLengthDescription',
  ProductsIncluded = 'ProductsIncluded',
  ShinglesAndAllOwensCorningComponents = 'ShinglesAndAllOwensCorningComponents',
  TRUPROtectionPeriod = 'TRUPROtectionPeriod',
  TRUPROtectionPeriodDescription = 'TRUPROtectionPeriodDescription',
  FirstNYears = 'FirstNYears',
  AfterNYears = 'AfterNYears',
  MaterialsOnlyCoverage = 'MaterialsOnlyCoverage',
  MaterialsOnlyCoverageDescription = 'MaterialsOnlyCoverageDescription',
  BlowOffCoverage = 'BlowOffCoverage',
  BlowOffCoverageDescription = 'BlowOffCoverageDescription',
  StreakGuardAlgaeResistance = 'StreakGuardAlgaeResistance',
  StreakGuardAlgaeResistanceDescription = 'StreakGuardAlgaeResistanceDescription',
  CoveragePeriod = 'CoveragePeriod',
  CoveragePeriodDescription = 'CoveragePeriodDescription',
  LifetimeWithCoverageReducedAfter25Years = 'LifetimeWithCoverageReducedAfter25Years',
  ComponentProducts = 'ComponentProducts',
  ComponentProductsDescription = 'ComponentProductsDescription',
  MinimumOf3OwensCorningComponentsRequiredForTheAdditionalCoveragePeriod = 'MinimumOf3OwensCorningComponentsRequiredForTheAdditionalCoveragePeriod',
  MinOf3OwensCorningComponentsRequiredHipAndRidgeAndOthers = 'MinOf3OwensCorningComponentsRequiredHipAndRidgeAndOthers',
  MinOf4OwensCorningComponentsRequiredHipRidgeUnderlaymentAndOthers = 'MinOf4OwensCorningComponentsRequiredHipRidgeUnderlaymentAndOthers',
  ContractorCertificationRequired = 'ContractorCertificationRequired',
  ContractorCertificationRequiredDescription = 'ContractorCertificationRequiredDescription',
  MustBeInstalledByAnOwensCorningRoofingPlatinumOrPreferredContractor = 'MustBeInstalledByAnOwensCorningRoofingPlatinumOrPreferredContractor',
  SeeActualWarrantyForCompleteDetailsLimitationsAndRequirements = 'SeeActualWarrantyForCompleteDetailsLimitationsAndRequirements',
  ExcludeTearOffAndDisposalCostsIfRequired = 'ExcludeTearOffAndDisposalCostsIfRequired',
  IncludeTearOffAndDisposalCostsIfRequired = 'IncludeTearOffAndDisposalCostsIfRequired',
  AfterThermalSealings = 'AfterThermalSealings',
  TwentyFiveYearAlgaeResistanceCoverageRequires = 'TwentyFiveYearAlgaeResistanceCoverageRequires',
  ShingleCoverageAppliesWithoutOwensCorningComponents = 'ShingleCoverageAppliesWithoutOwensCorningComponents',
  StandartCoverage = 'StandartCoverage',
  ExtendedCoverage = 'ExtendedCoverage',
  UpgradeYourSubscriptionToUseCustomBranding = 'UpgradeYourSubscriptionToUseCustomBranding',
  TitleColor = 'TitleColor',
  TitleFont = 'TitleFont',
  TextColor = 'TextColor',
  TextFont = 'TextFont',
  Main = 'Main',
  OwensCorningLegalText = 'OwensCorningLegalText',
  // OC translations (end)
  MobileHorizontalRotationNotSupported = 'MobileHorizontalRotationNotSupported',
  PleaseRotateYouDevice = 'PleaseRotateYouDevice',
  MarketSettings = 'MarketSettings',
  ProductSettings = 'ProductSettings',
  PleaseCheckTheEmail = 'PleaseCheckTheEmail',
  YouShouldSelectTheSendAnEmail = 'YouShouldSelectTheSendAnEmail',
  ToSaveChangesPleaseFillInMissingFields = 'ToSaveChangesPleaseFillInMissingFields',
  Leap = 'Leap',
  ConnectYourLeapAccount = 'ConnectYourLeapAccount',
  LeapWasConnectedSuccessfully = 'LeapWasConnectedSuccessfully',
  UnfortunatelyLeapWasNotConnected = 'UnfortunatelyLeapWasNotConnected',
  AreYouSureDisconnectLeap = 'AreYouSureDisconnectLeap',
  LeapWasDisconnectedSuccessfully = 'LeapWasDisconnectedSuccessfully',
  UnfortunatelyLeapWasNotDisconnected = 'UnfortunatelyLeapWasNotDisconnected',
  LearnMoreAboutLeap = 'LearnMoreAboutLeap',
  LeapWorkflowStage = 'LeapWorkflowStage',
  TheDefaultWorkflowStage = 'TheDefaultWorkflowStage',
  ActivateSubscriptionToGetMoreInfo = 'ActivateSubscriptionToGetMoreInfo',
  USA = 'USA',
  Canada = 'Canada',
  YouHaveMissingFieldsOrErrors = 'YouHaveMissingFieldsOrErrors',
  CustomizeOtherContactsForm = 'CustomizeOtherContactsForm',
  CustomizeRQPLeadsForm = 'CustomizeRQPLeadsForm',
  UserConsent = 'UserConsent',
  ContactOptIn = 'ContactOptIn',
  SMSOptIn = 'SMSOptIn',
  DisplayTypes = 'DisplayTypes',
  UsersCantProceedUntilOptInIsChecked = 'UsersCantProceedUntilOptInIsChecked',
  AboveButton = 'AboveButton',
  BelowButton = 'BelowButton',
  PleaseEnterAValueFromTo = 'PleaseEnterAValueFromTo',
  OptInToBeContacted = 'OptInToBeContacted',
  OptInToSMSMessages = 'OptInToSMSMessages',
  ServiceFinance = 'ServiceFinance',
  GreenSky = 'GreenSky',
  Synchrony = 'Synchrony',
  PowerPay = 'PowerPay',
  Financeit = 'Financeit',
  AddNewFinancingType = 'AddNewFinancingType',
  NewFinancingType = 'NewFinancingType',
  EditFinancingType = 'EditFinancingType',
  PleaseFillTheNameOfNewFinancingTypeAndAddURL = 'PleaseFillTheNameOfNewFinancingTypeAndAddURL',
  PleaseProvideTheURL = 'PleaseProvideTheURL',
  PleaseEditTheInformationBelow = 'PleaseEditTheInformationBelow',
  FinancingTypeName = 'FinancingTypeName',
  PleaseEnterAValidFinancingTypeName = 'PleaseEnterAValidFinancingTypeName',
  UploadLogo = 'UploadLogo',
  UploadFinancingTypeLogoHint = 'UploadFinancingTypeLogoHint',
  NewFinancingTypeHasBeenAddedSuccessfully = 'NewFinancingTypeHasBeenAddedSuccessfully',
  UnfortunatelyTheNewFinancingTypeHasNotBeenCreated = 'UnfortunatelyTheNewFinancingTypeHasNotBeenCreated',
  TheFinancingTypeHasBeenChangedSuccessfully = 'TheFinancingTypeHasBeenChangedSuccessfully',
  UnfortunatelyTheFinancingTypeHasNotBeenChanged = 'UnfortunatelyTheFinancingTypeHasNotBeenChanged',
  Logo = 'Logo',
  URLMustBeAdded = 'URLMustBeAdded',
  FinancingTypeCanNotBeRemoved = 'FinancingTypeCanNotBeRemoved',
  AreYouSureYouWantToDeleteFinancingType = 'AreYouSureYouWantToDeleteFinancingType',
  ThisFinancingTypeHasBeenDeletedSuccessfully = 'ThisFinancingTypeHasBeenDeletedSuccessfully',
  ThisFinancingTypeHasNotBeenDeleted = 'ThisFinancingTypeHasNotBeenDeleted',
  ThisFinancingTypeNameAlreadyExists = 'ThisFinancingTypeNameAlreadyExists',
  ThisURLAlreadyExists = 'ThisURLAlreadyExists',
  NoLabel = 'NoLabel',
  LabelCantBeRemoved = 'LabelCantBeRemoved',
  AreYouSureYouWantToDeleteThisLabel = 'AreYouSureYouWantToDeleteThisLabel',
  ApplyNow = 'ApplyNow',
  FinancingThrough = 'FinancingThrough',
  EditCustomFinancingButton = 'EditCustomFinancingButton',
  ThisLabelHasBeenDeletedSuccessfully = 'ThisLabelHasBeenDeletedSuccessfully',
  UnfortunatelyThisLabelHasNotBeenDeleted = 'UnfortunatelyThisLabelHasNotBeenDeleted',
  EmailRecommendedProducts = 'EmailRecommendedProducts',
  EmailRecommendedProductsHint = 'EmailRecommendedProductsHint',
  FillInYourInformationToSeeYourPersonalizedInstantQuote = 'FillInYourInformationToSeeYourPersonalizedInstantQuote',
  YesYouMayContactMeAboutMyProject = 'YesYouMayContactMeAboutMyProject',
  YesIWouldLikeToReceiveSmsMessagesAboutMyProject = 'YesIWouldLikeToReceiveSmsMessagesAboutMyProject',
  SendMeDetails = 'SendMeDetails',
  FinancingOption = 'FinancingOption',
}

export type I18nType = { [K in I18nEnum]: string };

export enum Routes {
  home = '/',
  signin = '/signin',
  signup = '/signup',
  quickQuote = '/roof-quote-pro/:id',
  forgotPassword = '/forgot-password',
  resetPassword = '/reset-password',
  confirmEmail = '/confirm-email',
  approveEmail = '/approve-email',
  googleOauthCallback = '/oauth/google/callback',
  outlookOauthCallback = '/oauth/outlook/callback',
  eagleviewOauthCallback = '/oauth/eagleview/callback',
  preview = '/preview/:src',
  loanInvitation = '/loan-invitation',
  loanInvitationModal = '/loan-invitation-modal/:wildcard',
}

export enum UserRoutes {
  dashboard = '/personal/dashboard',
  analyticsReports = '/personal/analytics-reports',
  toolSettings = '/personal/tool-settings',
  marketsSettings = '/personal/markets-settings',
  developerInfo = '/personal/developer',
  profile = '/personal/profile',
  billing = '/personal/billing',
  companyProfile = '/personal/company-profile',
  products = '/personal/products',
  financingSettings = '/personal/financing-settings',
  repQuotes = '/personal/rep-quotes',
  statementDetails = '/personal/financing-settings/statement-details/:id',
  orders = '/personal/orders',
}

export enum AdminRoutes {
  adminDashboard = '/admin/dashboard',
}

export const RoutesTitles: Record<string, string> = {
  [UserRoutes.dashboard]: I18nEnum.Dashboard,
  [UserRoutes.analyticsReports]: I18nEnum.Analytics,
  [UserRoutes.toolSettings]: I18nEnum.Settings,
  [UserRoutes.marketsSettings]: I18nEnum.Markets,
  [UserRoutes.developerInfo]: I18nEnum.Developer,
  [UserRoutes.profile]: I18nEnum.MyProfile,
  [UserRoutes.billing]: I18nEnum.Billing,
  [UserRoutes.companyProfile]: I18nEnum.Company,
  [UserRoutes.products]: I18nEnum.Products,
  [UserRoutes.financingSettings]: I18nEnum.Financing,
  [UserRoutes.repQuotes]: I18nEnum.RepQuotes,
  [UserRoutes.orders]: I18nEnum.Orders,
  [AdminRoutes.adminDashboard]: I18nEnum.AdminDashboard,
} as const;

export const PrivateRoutes = {
  ...UserRoutes,
  ...AdminRoutes,
};

export interface ActionType {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export type ThunkResult<R> = ThunkAction<R, State, undefined, Action<string>>;

export interface State {
  modal: ModalState;
  message: MessageState;
  search: SearchState;
  spinner: SpinnerState;
  user: UserState;
  quickQuote: QuickQuoteState;
  mapbox: MapboxState;
  widgetSettings: WidgetSettingsState;
  admin: AdminState;
  company: CompanyState;
  product: ProductState;
  widgetProduct: WidgetProductState;
  market: MarketState;
  snippets: SnippetState;
  financing: FinancingState;
  analyticsReports: AnalyticsReportsState;
  quickQuoteConfig: QuickQuoteConfigState;
  repQuotes: RepQuotesState;
  googleMaps: GoogleMapsState;
  subscription: SubscriptionState;
}

declare module 'axios' {
  export interface AxiosInstance {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request<T = any>(config: AxiosRequestConfig): Promise<T>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  }
}

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      [k in keyof typeof colors]: string;
    };
    mediaQueries: {
      [k in keyof typeof mediaQueries]: string;
    };
    elementsSizes: {
      [k in keyof typeof elementsSizes]: {
        initial: string;
        medium?: string;
        small?: string;
      };
    };
    orientation: {
      [k in keyof typeof orientation]: string;
    };
    colorFilters: {
      [k in keyof typeof colorFilters]: string;
    };
  }
}

export enum SortingType {
  asc = 'asc',
  desc = 'desc',
}

export type ListQuery = {
  pagination: { show: number; page: number };
  sorting: {
    [key: string]: SortingType;
  };
  search: string;
};

export enum Slopes {
  flat = 'flat',
  shallow = 'shallow',
  medium = 'medium',
  steep = 'steep',
  custom = 'custom',
}

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export enum ExportType {
  excel = 'xlsx',
  csv = 'csv',
  pdf = 'pdf',
}

export interface ParsedAddress {
  number: string;
  prefix: string;
  street: string;
  type: string;
  city: string;
  state: string;
  zip: string;
}

export const street = 'street1' as const;
export const city = 'city' as const;
export const state = 'state' as const;
export const postalCode = 'postalCode' as const;

export type AddressFeature = {
  number?: string;
  [street]: string;
  [city]: string;
  [state]: string;
  [postalCode]: string;
};

export type QueryError = {
  message: string;
  code: number;
};

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};
