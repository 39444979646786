const name = 'auth/';

export const SET_USER = `${name}SET_USER`;
export const SET_USER_ERROR = `${name}SET_USER_ERROR`;
export const CLEAR_USER = `${name}CLEAR_USER`;

export const STORAGE_TOKEN_ALIAS = 'token';
export const STORAGE_USER_ID_ALIAS = 'userId';
export const STORAGE_RETURN_URL = 'returnUrl';

export const STORAGE_ADMIN_TOKEN_ALIAS = 'adminToken';
export const STORAGE_ADMIN_ID_ALIAS = 'adminId';

export const OAUTH_STATE_BROADCAST_CHANNEL = 'oauth-state';
