export const calculateRegionalProductPricePerSquareFoot = ({
  productSqftPrice,
  regionalPricePerSquare,
}: {
  productSqftPrice: number;
  regionalPricePerSquare: number;
}) => {
  // Our per-square pricing is relative to the baseline price, which GAF Timberline is 95% of sales
  const baselineProductPriceGAF = 3.97; // pull the `3.97` value directly from the database: gaf/timberline-hdz/weatheredwood

  // Create a multiplier by comparing the regional price per square to the baseline price
  const regionalPricePerSqft = regionalPricePerSquare / 100;
  const regionalBasePriceMultiplier = regionalPricePerSqft / baselineProductPriceGAF;
  // Create the regional product price using the regional multiplier
  const productPricePerSquare = productSqftPrice * 100;
  const regionalProductPricePerSquare = productPricePerSquare * regionalBasePriceMultiplier;
  const pricePerSquareFeet = regionalProductPricePerSquare / 100;
  const formattedPricePerSquareFeet = Number(pricePerSquareFeet.toFixed(4));

  return formattedPricePerSquareFeet;
};
