import { I18nType } from 'types';

const en: I18nType = {
  PleaseEnterAValidName: 'Please enter a valid name',
  NotAValid10Digit: 'Not a valid 10-digit phone number',
  PleaseProvideValidEmailAddress: 'Please provide valid email address',
  PasswordMaxLength: 'Please enter a password shorter than 30 characters',
  PleaseDontBeginOrEnd: "Please don't begin or end your password with blank space.",
  PleaseFillInAllRequieredFields: 'Please fill in all required fields',
  NoValidPassword:
    'Password must contain at least 8 characters, 1 special character, 1 uppercase and lowercase letter, and 1 number',
  PleaseEnterAValidAddress: 'Please enter a valid address',
  PleaseEnterAValidZipCode: 'Please enter a valid zip code',
  Yes: 'Yes',
  Cancel: 'Cancel',
  DontWorryThough:
    "Don't worry though, we'll try to fix it.\nIn the meantime, you may try again{withGetSupport, select, true { or get support} other {}}.",
  TryAgain: 'Try Again',
  GetSupport: 'Get support',
  OopsShort: 'Oops!',
  SomethingWentWrong: 'Something went wrong!',
  TalkToSales: 'Talk to Sales',
  FirstName: 'First Name *',
  LastName: 'Last Name *',
  FirstNameNotReq: 'First Name',
  LastNameNotReq: 'Last Name',
  EmailReq: 'Email *',
  PhoneReq: 'Phone',
  Email: 'Email',
  PersonalEmail: 'Personal Email',
  PleaseEnterYourNewPersonalEmail: 'Please enter your new personal email',
  Phone: 'Phone',
  Notes: 'Notes',
  NotesReq: 'Notes *',
  EmailOrPasswordIncorrect: 'Email or password is incorrect. Please try again',
  Continue: 'Continue',
  Password: 'Password',
  PasswordReq: 'Password *',
  Oops: 'Oops! Something went wrong!',
  PersonalInformation: 'Personal Information',
  Name: 'Name',
  Security: 'Security',
  LastUpdated: 'Last updated',
  Edit: 'Edit',
  DeleteAccount: 'Delete account',
  AreYouSureYouWantDeleteAccount: 'Are you sure you want to delete your account?',
  IfYouDeleteAccount:
    'If you delete your account, you’ll be able to restore it within 14 days. After that time, your account will be permanently deleted.',
  NewPassword: 'New Password',
  InvalidCurrentPassword: 'Invalid current password',
  ResetYourPassword: 'Reset Your Password',
  NewPasswordMustBeDifferent: 'New password must be different from your previous password',
  PasswordsDoNotMatch: 'Passwords do not match',
  SuccessfullPasswordReset: 'Successful Password Reset!',
  CurrentPassword: 'Current Password',
  ConfirmNewPassword: 'Confirm New Password',
  UpdatePassword: 'Update password',
  Show: 'Show',
  Hide: 'Hide',
  Help: 'Help',
  StructureValidation:
    'Only alphanumeric symbols, commas, apostrophes, hyphens and dots are allowed. 1-30 characters allowed',
  MapNoSelectionHelpText: 'Select a roof to edit',
  MapShapeSelectedHelpText: 'Select a corner to move or delete it',
  MapVertexSelectedHelpText: 'Drag the corner to shape your roof',
  MapDrawShapeHelpText: 'Click to start drawing a roof',
  MapDrawShapeContinueHelpText: 'Click to continue drawing the roof',
  MapDrawShapeCloseHelpText: 'Click the first point to close this shape',
  DeleteSelectedItem: 'Delete selected item',
  DrawNewRoofShape: 'Draw new roof shape',
  HmmWeMissedSomething: 'Your roof was not detected',
  YouCanManuallyDrawYourRoofShapeOrClickGetHelp:
    'You can manually draw your roof shape or click get help to request your estimate.',
  ClickAddRoofNowToDraw: `That's OK, click "Add Roof Now" to draw your roof outline.`,
  GetHelp: 'Get Help',
  ClickAddRoofNowToBeginDrawing: 'Click "Add Roof Now" to begin drawing your roof.',
  OneRoofIsRequiredToContinueEstimate: 'One roof is required to continue your estimate.',
  EditRoofs: 'Edit Roofs',
  AddRoofNow: 'Add Roof Now',
  SeeMyPrice: 'See My Price',
  SaveChanges: 'Save changes',
  WhatWillMyRoofCost: 'What Will My Roof Cost?',
  EnterYourAddressToSee: 'Enter your street address to see your price',
  EnterYourAddress: 'Enter your street address',
  CalculatePrice: 'Calculate price',
  ICantFindMyAddress: 'I can’t find my address',
  NoAddressFoundTooltip: "Unfortunately, the property details couldn't be found automatically.",
  NoAddressFound:
    'The good news is that we can still provide you with an estimate in a very timely manner. Please fill your details in "Contact us" form.',
  ContactUs: 'Contact Us',
  EnterYourStreetAddressToGetAnEstimateInstantly:
    'Enter your street address to get an accurate estimate instantly',
  Start: 'Start',
  NoResults: 'No Results',
  SorryNotingMatch: 'Sorry, nothing matches your search, please try another request.',
  CompanyName: 'Company Name',
  CompanyNameReq: 'Company Name *',
  Save: 'Save',
  YourSessionWillExpire: 'Your session will expire!',
  YourSessionHasExpired: 'Your session has expired',
  DueToInactivity: 'due to inactivity.',
  YouWillLoggedOut: 'You will logged out in {seconds} seconds.',
  DoYouWantToStaySignedIn: 'Do you want to stay signed in?',
  KeepMeSignedIn: 'Yes, keep me signed in',
  PleaseFillInTheObligatory: 'Please fill in the required field',
  SignIn: 'Sign In',
  GeneralSettings: 'General Settings',
  Settings: 'Settings',
  ProductCardCustomization: 'Product Card Customization',
  Widget: 'Widget',
  ToolScripts: 'Tool Scripts',
  TextIconsIllustrations: 'Text, Icons, Illustrations',
  Controls: 'Controls',
  ControlsHint:
    'For color selections, enter the Hex color code or select it manually by clicking on the color square.',
  Titles: 'Titles',
  TitlesSettingDescription: 'Page titles, product names, and structure names',
  MainText: 'Main Text',
  MainTextSettingDescription:
    'Supporting text, product descriptions, selected slope type, and prices',
  SecondaryText: 'Secondary Text',
  SecondaryTextDescription: 'Product description labels and structure slope',
  Icons: 'Icons',
  RoofBordersColor: 'Roof Borders Color',
  TooltipAndMapControls: 'Tooltip and Map Controls',
  TooltipAndMapControlsDescription: 'Map buttons and tooltips showing roof names',
  IllustrationSlopePreview: 'Illustration (Slope Preview)',
  WidgetBackground: 'Widget Background',
  MainButton: 'Main Button',
  SecondaryButton: 'Secondary Button',
  Checkbox: 'Checkbox',
  Color: 'Color',
  FillColor: 'Fill Color',
  StrokeColor: 'Outline Color',
  FontColor: 'Font Color',
  Font: 'Font',
  CornerRadius: 'Corner Rounding',
  UnselectedColor: 'Unselected Color',
  SelectedColor: 'Selected Color',
  ResetSettingsToDefault: 'Reset Settings to Default',
  PreviewWidget: 'Preview Widget',
  FontWeight: 'Font Weight',
  FontSize: 'Font Size',
  EditYourRoofNameAndSlope:
    'Review your {itemCount, plural, one {roof} other {roofs}} and confirm its {itemCount, plural, one {slope} other {slopes}}',
  SignOut: 'Sign-out',
  AdminDashboard: 'Admin Dashboard',
  CountOfItems: '{count} of {total} items',
  ResendPassword: 'Resend Password',
  AddSubscription: 'Add Subscription',
  PauseSubscription: 'Pause Subscription',
  ProceedSubscription: 'Proceed Subscription',
  DeleteSubscription: 'Delete Subscription',
  DeleteCompany: 'Delete Company',
  PleaseSelectTheDateRange: 'Please select the date range of subscription *',
  SubscriptionHasBeenSaved: 'Subscription has been added successfully!',
  UnfortunatelyTheSubscription:
    'Unfortunately, the subscription has not been added. Please, try again.',
  AreYouSureDelete: 'Are you sure you want to delete {name} record?',
  BeAwareTheRecordWillBeDeleted:
    'Be aware the record will be deleted only in the admin. You will still have {name} information in Hubspot.',
  AreYouSurePause: 'Are you sure you want to pause subscription to {name}?',
  TheSubscriptionWillBePaused:
    'The subscription will be paused immediately and the widget will not work until subscription is proceeded.',
  AreYouSureProceed: 'Are you sure you want to proceed subscription to {name}?',
  TheSubscriptionWillBeProceeded:
    "The subscription will be proceeded immediately and the widget will work if it is still installed on the client's website.",
  Proceed: 'Proceed',
  Pause: 'Pause',
  RecordHasBeen: 'Record has been deleted successfully!',
  UnfortunatelyTheRecord: 'Unfortunately, the record has not been deleted. Please, try again.',
  SubscriptionHasBeenPaused: 'Subscription has been paused successfully!',
  UnfortunatelySubscriptionHasNotBeenPaused:
    'Unfortunately, the subscription has not been paused. Please, try again.',
  SubscriptionHasBeenProceeded: 'Subscription has been proceeded successfully!',
  UnfortunatelySubscriptionHasNotBeenProceeded:
    'Unfortunately, the subscription has not been proceeded. Please, try again.',
  Type: 'Type',
  Warranty: 'Warranty',
  TopFeatures: 'Top Features',
  PriceForSquare: 'Price per Square',
  PriceAverageInfo: 'e.g. 2000.15 sqft roof equals {price}',
  SeeInstallationPackageDetails: 'See Package Details',
  WhatIncluded: "What's Included",
  AllRooflePricingIncludes: 'All pricing includes the following line items',
  RemoveExistingShingles: 'Remove existing shingles',
  InstallIceAndWater: 'Install ice and water shield (colder climates only)',
  InstallUnderlayment: 'Install underlayment',
  InstallSelectRoofingProduct: 'Install selected roofing products per specifications',
  InstallHipRidge: 'Install hip & ridge',
  ReplaceAllExisting: 'Replace existing attic vents with same but new',
  InstallNewPipeAndChimney: 'Install new pipe jacks and chimney flashings',
  CleanUpAllJob: 'Clean up all job related debris',
  CityPermitIsIncluded: 'City Permit is included in the price',
  AllRoofleRoofingCrewsAreLicensed:
    'All roofing crews are licensed, insured and safety on the job site is our top priority.',
  InAdditionToYourProductWarranties:
    'In addition to your product warranties, your new roof will include a workmanship warranty.',
  AdditionalCosts: 'Additional Costs',
  ProductDetailLink: 'Custom Button',
  TheFollowingItemsAreNotIncluded:
    'The following items are not included in this price but will be added to your final proposal or during online checkout:',
  RoofIsMoreThan1:
    '<li>Roof is more than 1 story high</li><li>More than 1 layer of existing roof layer tear off</li><li>New decking required</li><li>Roof is extra complex</li><li>Replacing skylights</li><li>Chimney flashing</li>',
  PleaseSelectSubscriptions: 'Please select one or more subscriptions you want to delete',
  SubscriptionHasBeen: 'Subscription has been deleted successfully!',
  UnfortunatelySubscriptionHasNotDeleted:
    'Unfortunately, the subscription has not been deleted. Please, try again.',
  SubscriptionsHaveBeen: 'Subscriptions have been deleted successfully!',
  UnfortunatelySubscriptionsHaveNotDeleted:
    'Unfortunately, subscriptions have not been deleted. Please, try again.',
  AddCompany: 'Add Company',
  Delete: 'Delete',
  SymbolsLeft: '{count} {count, plural, one {symbol} other {symbols}} left to enter',
  PleaseFillInCompanysDetailsToCreate:
    "Please fill in company's details to create a record. If you don't find necessary company in the list - create new one.",
  PleaseFillInCompanysDetails:
    "Please fill in company's details.\nBe aware this changes will be applied immediately.",
  NotObligatory: 'Not obligatory',
  NewRecordHasBeenAdded: 'New record has been added successfully!',
  UnfortunatelyRecordHasNotBeenCreated:
    'Unfortunately, the new record has not been created. Please, try again.',
  Website: 'Website',
  WebsiteReq: 'Website *',
  PleaseEnterAValidWebsite:
    "Please enter a valid URL. It should start with 'http://' or 'https://' and have no more than 100 symbols",
  PleaseEnterAValidWebsiteNoLimit:
    "Please enter a valid URL. It should start with 'http://' or 'https://'",
  PleaseUploadContractorsLogo: "Please upload company's logo.",
  OnlyPngJpg: 'Only PNG, JPG or JPEG files with max size of {size} can be uploaded',
  DragAndDrop: 'Drag and drop or <a>browse</a> your file',
  PleaseEnterValidCompanyName:
    "Please enter a valid company name. It shouldn't start and end with a space and have no more than 160 symbols",
  CompanyProfile: 'Company Profile',
  Company: 'Company',
  CompanyEmail: 'Company Email',
  CompanyPrimaryContact: 'Company Primary Contact',
  YouDontHaveAnyCompanyContacts:
    "You don't have company primary contact yet. Please select user or create new user.",
  PleaseEnterNewWebsiteAddress: 'Please enter your new website address',
  WebsiteChangesHasBeen: 'Website changes have been saved successfully!',
  UnfortunatelyTheWebsite:
    'Unfortunately, the website changes have not been saved. Please, try again.',
  PleaseEnterNewCompanyEmail: 'Please enter your new company email',
  CompanyEmailHasBeen: 'Company email changes have been saved successfully!',
  UnfortunatelyTheCompanyEmail:
    'Unfortunately, the company email changes have not been saved. Please, try again.',
  RequestToDeleteCompanyAccount: 'Request to Delete Company Account',
  NewUserHasBeen: 'New user has been created successfully!',
  UnfortunatelyTheNewUser: 'Unfortunately, the new user has not been created. Please, try again.',
  UserChangesHasBeen: 'User changes have been saved successfully!',
  UnfortunatelyTheUserChanges:
    'Unfortunately, the user changes have not been saved. Please, try again.',
  UsersPermissionToAccessClp:
    "User's permission to access Contractor Loan Pro Financing was not changed:",
  JobTitleReq: 'Job Title *',
  PleaseEnterAValidJobTitle: 'Please enter a valid job title',
  PhoneNumber: 'Phone Number',
  AreYouSureYouWantDeleteContact: 'Are you sure you want to delete this contact?',
  BeAwareTheSelectedContact:
    'Be aware the selected contact will be deleted immediately and won’t be used as your primary contact for communication anymore.',
  DeleteContact: 'Delete Contact',
  ContactHasBeenDeleted: 'Contact has been deleted.',
  UnfortunatelyYheContact: 'Unfortunately, the contact has not been deleted. Please, try again.',
  AreYouSureYouWantResendPassword: 'Are you sure you want to resend  password to {name}?',
  ANewPasswordWillBeSentToTheirEmail: 'A new password will be sent to their email.',
  PasswordHasBeenResent: 'Password has been resent successfully!',
  UnfortunatelyThePasswordHasNotBeenResent:
    'Unfortunately, the password has not been resent. Please, try again.',
  TheContractorWillRecieve: 'The contractor will receive an email with new password.',
  Resend: 'Resend',
  Products: 'Products',
  Product: 'Product',
  AllProducts: 'All Products',
  EditLabel: 'Edit Label',
  Label: 'Label',
  LabelShouldBeUnique: 'Your label should be unique',
  ForgotPassword: 'Forgot Password?',
  EnterTheEmailAddress:
    "Enter the email address you used when you joined and we'll send you a password reset link.",
  EmailIsNotFound: 'Email is not found. Please try a different email address',
  RequestResetLink: 'Request Reset Link',
  BackToSignIn: 'Back to sign in',
  WeSentYouPasswordResetLink: "We've Sent You a Password Reset Link",
  TimeToCheckYourInbox: 'Time to check your inbox',
  ResendPasswordReset: 'Resend Password Reset Link',
  YourPasswordResetLinkHasBeen: 'Your password reset link has been sent successfully!',
  UnfortunatelyThePasswordResetLink:
    'Unfortunately, the password reset link has not been sent. Please, try again.',
  YourConfirmationEmailHasBeen: 'Your confirmation email has been sent successfully!',
  UnfortunatelyTheConfirmationEmail:
    'Unfortunately, the confirmation email has not been sent. Please, try again.',
  YouCanNowUse: 'You can now use your password to sign into your account!',
  HeyIts: "Hey, it's",
  WeAreSorryLinkExpired: ' We are sorry, but your link has expired',
  PleaseClickToResend: 'Please, click the button to resend it.',
  LabelError: 'This label already exists on other card',
  AreYouSureSendADeletion: 'Are you sure you want to send a deletion request?',
  AfterRequestSending:
    'After deletion is requested, your account manager will contact you in a timely manner.',
  SendRequest: 'Send Request',
  YourRequestHasBeenSent: 'Your request has been sent successfully!',
  UnfortunatelyTheRequestHasNot: 'Unfortunately, the request has not been sent. Please, try again.',
  YouDeletionRequest:
    'Your deletion request is in process, your subscription will continue to work in the meantime. Please contact <a>help@roofle.com</a> if you have any questions.',
  MobileNotSupported: 'Mobile not Supported',
  MobileNotSupportedBrandingDescription:
    "Unfortunately, we don't support customization for mobile. Please use a desktop or tablet device to make these changes.",
  AlmostDone: 'Almost Done',
  AlmostDoneExclamationMark: 'Almost Done!',
  Back: 'Back',
  ShowMyPrice: 'Show My Price',
  FillInYourDetailsToSeeQuoteInstantly: 'Fill in your details to see your quote instantly.',
  RecommendedProducts: 'Recommended Products',
  Dashboard: 'Dashboard',
  MyProfile: 'My Profile',
  Billing: 'Billing',
  Reset: 'Reset',
  JobTitle: 'Job Title',
  YourNameSentSuccessfully: 'Your name has been sent successfully!',
  UnfortunatelyYourName: 'Unfortunately, your name has not been changed. Please, try again.',
  YourPhoneSentSuccessfully: 'Your phone number has been changed successfully!',
  UnfortunatelyYourPhone:
    'Unfortunately, your phone number has not been changed. Please, try again.',
  YourJobTitleSentSuccessfully: 'Your job title has been changed successfully!',
  UnfortunatelyYourJobTitle:
    'Unfortunately, your job title has not been changed. Please, try again.',
  PersonalEmailChangesHaveBeenSavedSuccessfully:
    'Personal email changes have been saved successfully!',
  UnfortunatelyYourEmail:
    'Unfortunately, the personal email changes have not been saved. Please, try again.',
  AreYouSureYouWantToResetSettings: 'Are you sure you want to reset settings?',
  AllSettingsWillBeResetToTheDefault: 'All settings will be restored to default.',
  NoKeepAsIs: 'No, keep as is',
  YesReset: 'Yes, Reset',
  Ok: 'Ok',
  YourChangesHaveBeenSavedSuccessfully: 'Your changes have been saved successfully!',
  DisableThisMarket: 'Disable This Market',
  AllYourSettingsConfigurationHaveBeenAppliedToTheWidget:
    'All settings have been applied to your website tool.',
  AllYourCardsChangesHaveBeenAppliedToTheWidget:
    'All your cards changes have been applied to the widget.',
  StartDate: 'Start Date',
  EndDate: 'End Date',
  Status: 'Status',
  Active: 'Active',
  Disabled: 'Disabled',
  Deactivated: 'Deactivated',
  Inactive: 'Inactive',
  Expired: 'Expired',
  Upcoming: 'Upcoming',
  ActivateSubscription: 'Activate Subscription',
  AreYouSureActivate: 'Are you sure you want to activate your subscription?',
  AreYouSureCancel: 'Are you sure you want to pause your subscription?',
  AfterSendingRequestYourAccountManagerWillContactYou:
    'After sending your request, your account manager will contact you in a timely manner.',
  NA: 'N/A',
  PleaseTellUs: 'Please tell us why you are considering pausing',
  IDontThink: "I don't think the product is useful",
  ItsTooExpensive: "It's too expensive",
  ItDidntWorkProperly: "It didn't work properly",
  IDontWant: "I don't want it to auto-renew",
  IOnlyNeeded: 'I only needed it for a certain period',
  OtherPleaseSpecify: 'Other (please specify)',
  Submit: 'Submit',
  ActivationRequested: 'Activation Requested',
  YourActivation:
    'Your activation request is in process. Please contact <a>help@roofle.com</a> if you have any questions.',
  PauseRequested: 'Pause Requested',
  YourCancelation:
    'Your request to pause is in process, your subscription will continue to work in the meantime. Please contact <a>help@roofle.com</a> if you have any questions.',
  ToolID: 'Tool ID',
  ToolIDHint:
    'This is your unique ID for your tool and account. If you wish to change this, please contact support.',
  UnfortunatelyYourPasswordNotChanged:
    'Unfortunately, your password has not been changed. Please, try again.',
  YourPasswordHasBeenChanged: 'Your password has been changed successfully!',
  PleaseEnterAValidWidgetId: 'Please enter a valid Widget ID',
  SelectTheProducts:
    "Select the products you'd like to display on your tool.  Go to the Tool Settings tab to customize your products pricing, display order, labels, images, and descriptions.",
  Manufacturer: 'Manufacturer',
  Grade: 'Grade',
  AsphaltShingle: 'Asphalt Shingle',
  Wood: 'Wood',
  Tile: 'Tile',
  Metal: 'Metal',
  Synthetic: 'Synthetic',
  Architectural: 'Architectural',
  Designer: 'Designer',
  Specialty: 'Specialty',
  All: 'All',
  AllWithTotal: 'All ({total})',
  ClearAllFilters: 'Clear all filters',
  SeeRoofleCatalog: 'See Roofle Catalog',
  SendStatisticsSuccessMessage: 'Great! Here is your instant quote.',
  SendStatisticsFailMessage: 'Unfortunately something went wrong. Please try again.',
  AreYouSureYouWantDeleteRoof: 'Are you sure you want to delete the borders of this roof?',
  AllSelectedProducts: 'All selected products have been applied to the widget.',
  UnfortunatelyYourChangesHaveNotBeen: 'Unfortunately, your changes have not been saved.',
  YourChangesHaveNotBeen: 'Your changes have not been saved.',
  AreYouSureLeave:
    'Are you sure you want to leave this page without saving changes? Be aware all your previously settings will be applied.',
  NoBackToThe: 'No, back to the page',
  Filters: 'Filters',
  OurTechnicalSupport:
    'Our technical support team has been notified and will assist you shortly, in the meantime, please feel free to try again.',
  Paused: 'Paused',
  Market: 'Market',
  MarketReq: 'Market *',
  MarketIsRequired: 'Market is required.',
  MarketOrStateRequired: 'Either Market or State is required',
  NoWorriesLetsTry: "No worries. Let's try it this way",
  PleaseFillInYourDetailsAndWeWillProvide:
    'Please fill your details, and we will provide you with an estimate in a very timely manner.',
  PreferredMethodOfContact: 'Preferred method of contact',
  SymbolsLeftToEnter: '{count} {count, plural, one {symbol} other {symbols}} left to enter',
  YourMessageHasBeenSent: 'Your message has been sent successfully.',
  UnfortunatelyTheEmailHasNotBeen: 'Unfortunately, the email has not been sent. Please, try again.',
  ServiceArea: 'Service Area',
  NationalServiceArea: 'National Service Area',
  NationalCoverage: 'National Coverage',
  StateList: 'State List',
  StateListText:
    'Uncheck any state included in your service area to turn off instant quotes for any addresses within that state.',
  StateName: 'State Name',
  Radius: 'Radius',
  StateWithPluralization: '{count, plural, one {State} other {States}}',
  UncheckAnyStateIncludedInYourServiceArea:
    'Uncheck any state included in your service area to turn off instant quotes for any addresses within that state.',
  YourAccountHasANationalLicense:
    'Your account has a national license. To manage states (turn them on/off), please go to the National Service Area tab.',
  SlideoutRoofQuoteProScript: 'Slideout RoofQuote PRO™ Script',
  EmbeddedRoofQuoteProScript: 'Embedded RoofQuote PRO™ Script',
  SlideOutScriptDescription:
    'Embed this script on your website inside the <head> element on each page you want to see the tool. It will appear as a button sticking to the side of the screen.',
  FullPageFrameDescription:
    'Embed this script on your website inside the <body> element on each page you want to see the tool.',
  UnfortunatelyYourChangesHasNotBeenSaved:
    'Unfortunately, your changes have not been saved. Please, try again',
  EditCompany: 'Edit Company',
  YourAddressIsNotWhithinOurServiceArea:
    'We are unable to provide an instant quote for this address.',
  SqftOfRoof: '{sqft} sqft of roof',
  EmbeddedWidget: 'Embedded Widget',
  Background: 'Background',
  ExistingCompany: 'Existing Company',
  NewCompany: 'New Company',
  CompanyNameIsARequired: 'Company Name is a required field',
  IWouldStillLikeAQuote: "I'd still like a quote",
  Leads: 'RQP Leads',
  OtherContacts: 'Other Contacts',
  WidgetIsTemporarilyUnavailable: 'Widget Is Temporarily Unavailable',
  PleaseContactUsToReceiveYourRoofEstimate:
    'Please contact us to receive your roof estimate or try again later.',
  InstantRoofQuoteButton: '“Instant Roof Quote” Button',
  Left: 'Left',
  Right: 'Right',
  Location: 'Location',
  Domain: 'Domain',
  PleaseProvideAValidDomain: 'Please provide a valid domain',
  DeleteDomain: 'Delete Domain',
  AddNewDomain: 'Add New Domain',
  NItemsSelected: '{n} {n, plural, one {item is} other {items are}} selected',
  State: 'State',
  ViewMore: 'View more',
  ViewLess: 'View less',
  YourDomainMustBe:
    'Your Domain(s) must be added for the widget to work correctly. Please, follow this <a>link</a>',
  YourPrimaryContactMustHaveAccount:
    'Your Company Primary Contact must have account to work correctly. Please, follow this <a>link</a>',
  headTitle: '{pageTitle} | RoofQuote PRO™',
  ToUpdateYourServiceArea:
    'To update your service area, please select markets {isUSSubscription, select, true {and (or) states } other {}}you would like to add or remove. Your request will be processed during working hours.',
  Markets: 'Markets',
  States: 'States {withNationalLicense, select, true {(National License)} other {}}',
  AvailableMarkets: 'Available Markets',
  YourMarketSelection: 'Your Market Selection',
  RemoveDisabledMarketNote:
    '* If you would like to remove this market (-s), please switch on “States” tab and remove the state',
  AvailableStates: 'Available States',
  YourStateSelection: 'Your State Selection',
  AddAllSelected: 'Add All Selected',
  MarketSelection: 'Market Selection',
  StateSelection: 'State Selection',
  SendTheRequest: 'Send the request',
  NationalLicense: 'National License',
  AllMarkets: 'All Markets',
  RemoveNote: 'This {isMarket, select, true {market} other {state}} will be removed',
  KeepActive: 'Keep Active',
  ThisNationalLicenseWillBeRemoved: 'This National License will be removed.',
  ByRemovingYourNationalLicense:
    'By removing your National License, all markets will also be removed.',
  MarketsIncludedInTheStateLicenseWillBeRemoved:
    'Markets included in the State License you are removing will also be removed.',
  ThisStatesWillBeRemovedDuringWorkingHours:
    'This State (-s) will be removed during working hours by admin.',
  ToRemoveThisMarket:
    'To remove this market(s), please switch to the "States" tab and remove the state license.',
  ThisMarketWillBeRemovedDuringWorkingHours:
    'This Market (-s) will be removed during working hours by admin.',
  ToContinueYourSubscription:
    'To continue your subscription, please select at least one {isUSSubscription, select, true {state or } other {}}individual market. Otherwise, your subscription will be cancelled at the end of the current billing cycle. It will remain active in the meantime.',
  NationalLicenseRemoveNote: 'This National License will be removed',
  NationalLicenseNote:
    'To request national coverage, check "States" then click "Add All Selected". To remove national coverage, remove National License before reselecting states you wish to keep.',
  AllStatesNationalLicense: 'All States (National License)',
  IfYouNoLongerNeedANationalLicense:
    'If you no longer need a National License - uncheck checkbox near the National License first and add necessary states.',
  YouShouldAddTheMarketAndOrState:
    "You should add the market and (or) the state depending on the contractor's request (at least one)*.",
  YouShouldAddTheMarket:
    "You should add the market depending on the contractor's request (at least one)*.",
  MarketsAndStatesWillBeReset:
    'All settings will be restored to default (all markets and states will be activated).',
  NoActiveStates: 'No active states',
  RequestNewProduct: 'Request New Product',
  ProductName: 'Product Name',
  RequestANewProduct: 'Request a New Product',
  IfThereIsAProductWeDontCurrentlyHaveInOutCatalog:
    "If there is a product we don't currently have in our catalog that you'd like to use, please request it to be added.",
  RoofleAdmin: 'Roofle Admin',
  SearchContractor: 'Search Contractor',
  EditTextButton: 'Edit Text Button',
  SeeDefaultDescription: 'See default description',
  AddNewLabel: 'Add New Label',
  PleaseFillTheNameOfNewLabel: 'Please fill the name of new label.',
  LabelNameReq: 'Label Name *',
  PleaseEnterAValidLabel: 'Please enter a valid label',
  DisplayOrder: 'Display Order:',
  Add: 'Add',
  WarningThisProduct: 'Warning! This product is duplicated. ',
  PleaseChangeTheProductAfter: 'Please change the product description after saving',
  PleaseChangeTheProduct: 'Please change the product description.',
  AreYouSureDeleteProduct: 'Are you sure you want to delete product?',
  IfYouDeleteTheProduct:
    'Deleting product will reset its price and descriptions to default. Do you want to delete it?',
  No: 'No',
  FieldMaxLengthLimitError:
    'Please enter a {fieldName} shorter than {characterCount} {characterCount, plural, one {characters} other {characters}}',
  Snippet: 'Snippets',
  Price: 'Price',
  ListOf: 'List of {category}',
  AreYouSureYouWantToDeleteSnippet: 'Are you sure you want to delete snippet?',
  ThisActionCannotBeUndone: 'This action cannot be undone.',
  AddNewSnippet: 'Add new Snippet',
  EditSnippet: 'Edit Snippet',
  BackToTheSnippetList: 'Back to the snippet list',
  ClickToMakeVisible: 'Click to make it visible',
  ClickToMakeInvisible: 'Click to make it invisible ',
  FieldIsNotValid: 'Field is not valid',
  BackgroundContrastError:
    'For visibility, your background color should not be the same as other elements. Please choose a different color.',
  SaveAsSnippet: 'Save Text As Snippet',
  AddFromSnippet: 'Add Text From Snippet',
  AddPriceFromHistory: 'Add Price From History',
  ExistingSnippet: 'Existing Snippet',
  ChooseExistingSnippetToProduct: 'Choose existing snippet to product.',
  WarninigYouCantSave: "Warning! You can't save because you have repetitive product.",
  SnippetHasBeenAddedSuccessfully: 'New snippet has been added successfully!',
  UnfortunatelySnippetHasNotBeenCreated:
    'Unfortunately, the new snippet has not been created. Please, try again.',
  SnippetName: 'Snippet name',
  ProductImagesUploadHint:
    'You can upload a maximum of two photos for selected color. Default photo will be replaced with your photo automatically.',
  UploadPhoto: 'Upload Photo',
  MainPhoto: 'Main Photo',
  RQPLeadsAreAll:
    'RQP Leads are all users who submitted their info and received a quote through the RoofQuote PRO™ tool on your website.',
  OtherContactsAreAll:
    'Other Contacts are all users who were unable to get a quote directly from RoofQuote PRO™, but still requested to be contacted for a quote.',
  CompanyPhoneReq: 'Company Phone *',
  CompanyPhone: 'Company Phone',
  PleaseEnterNewCompanyPhone: 'Please enter your new company phone',
  ResendApprovalEmail: 'Resend Verification Email',
  ApprovalEmailWasResendSuccessfully: 'Verification email was resent successfully',
  ToSendDetailsFromRepQuotes:
    'To send details from Rep Quotes using {email}, please verify it first. Once verified, you’ll need to connect your new email after signing in.',
  ToSendEmails: 'To send emails from your company email, please verify it.',
  Callout: 'Callout',
  EditCalloutTitle: 'Edit Callout Title',
  EditCalloutText: 'Edit Callout Text',
  EditCalloutTabText: 'Edit Tab Text',
  CompanyPhoneChanges: 'Company phone changes have been saved successfully!',
  UnfortunatelyTheCompanyPhone:
    'Unfortunately, the company phone changes have not been saved. Please, try again.',
  ThreeTab: '3-Tab',
  CompanyNameChanges: 'Company name changes have been saved successfully!',
  UnfortunatelyTheCompanyName:
    'Unfortunately, the company name changes have not been saved. Please, try again.',
  CompanyWithThisName:
    'Сompany with this name already exists.\nPlease enter another company name or contact Admin.',
  PleaseEnterYourNewCompany: 'Please enter your new company name',
  RoofleCatalog: 'Roofle Catalog',
  MyCatalog: 'My Catalog',
  ThereIsNothingHereYet: 'There Is Nothing Here Yet',
  NoDataAvailable: 'No data available.',
  PressCreateProductCard: 'Press "Create Product Card" button and create first product.',
  PressRequestNewProduct: 'Press "Request New Product" button to get a product.',
  CreateProductCard: 'Create Product Card',
  EnterProductName: 'Enter Product name here',
  EnterType: 'Enter Type here',
  EnterWarranty: 'Enter Warranty here',
  EnterTopFeatures: 'Enter Top Features here',
  EnterWhatsIncluded: "Enter What's Included here",
  EnterAdditionalCosts: 'Enter Additional Costs here',
  FixedPrice: 'Fixed Price',
  CustomProductImagesUploadHint: 'You can upload a maximum of two photos.',
  YouProductHasBeenCreated: 'Your product has been created successfully!',
  UnfortunatelyTheProduct:
    'Unfortunately, the new product has not been created. Please, try again.',
  DeveloperSettings: 'Developer Settings',
  Developer: 'Developer',
  Webhooks: 'Webhooks',
  Method: 'Method: {method}',
  Post: 'POST',
  ItSendsTheDataAfterMainContactFormCompleted:
    'It sends the data after a main contact form completed.',
  ItSendsTheDataAfterProductRequest: 'It sends the data after a product request.',
  JSONExample: 'JSON Example',
  OpenSwaggerDocumentation: 'Open Swagger Documentation',
  InvalidWebhookURL: 'Invalid webhook URL',
  YourWebhookURLHaveBeenSaved: 'Your webhook URLs have been saved successfully!',
  DataWillNowBeSent: 'Data will now be sent.',
  PleaseEnterAValidType: 'Please enter a valid type.',
  TheStructureWasSelected: 'The structure was selected by customer',
  TheStructureWasntSelected: 'The structure wasn’t selected by customer',
  Warranties: 'Warranties',
  Prices: 'Prices',
  Create: 'Create',
  MainTool: 'Main Tool',
  RQPLeadsForm: 'RQP Leads Form',
  OtherContactsForm: 'Other Contacts Form',
  Feature: 'Feature',
  LegalText: 'Legal Text',
  ContactUsLegalText: 'Legal Text',
  LegalTextHint: 'Show or hide a custom legal disclosure text under the form',
  LegalTextAreaPlaceholder:
    'Fill in your information to see your personalized instant quote and be contacted by us about your project.',
  TextArea: 'Text Area',
  Financing: 'Financing',
  FinancingSettings: 'Financing Settings',
  TheLengthOfFinancing: 'The Length of Financing (Months):',
  AnnualPercentageRate: 'Annual Percentage Rate:',
  DescriptionThatCustomers: 'Description that customers will see under Price per Month:',
  ApplyToOtherMarkets: 'Apply to Other Markets',
  ApplyFinancingSettingsToOtherMarkets: 'Apply Financing Settings\nto Other Markets',
  AdditionalProductCardsFunctionality: 'Additional Product Cards Functionality',
  AddressOnly: 'Address Only',
  ThereAreAddressesWhich:
    'These are addresses which were entered, but the user did not submit the rest of their information.',
  ShowPrices: 'Show Prices',
  YouShouldSelectAtLeast: 'You should select at least one option below',
  Total: 'Total',
  PerMonth: 'Per Month',
  Apply: 'Apply',
  Switch: 'Switch',
  ForMarkets: 'For Markets',
  ChangesHaveBeenApplied: 'Changes have been applied successfully!',
  UnfortunatelyTheChanges: 'Unfortunately, the changes have not been applied. Please, try again.',
  TheStructureWasDeleted: 'The structure was deleted by customer',
  ThisIsUsedToCalculate:
    'This is used to calculate the price. It is the total square footage of roof for all structures included in quote, accounting for any modifications to map structure data (adding, removing, or editing structures).',
  ClosePreview: 'Close Preview',
  ThankYouScreen: 'Thank You Screen',
  ThankYouScreenHint:
    'Build a custom Thank You screen that displays after the homeowner clicks a specific product details button.',
  EditTitle: 'Edit Title',
  UploadMedia: 'Upload Media',
  EditSubtitle: 'Edit Subtitle',
  EditExampleText: 'Edit Example Text',
  EditButtonText: 'Edit Button Text',
  URL: 'URL',
  URLReq: 'URL *',
  GreatChoice: 'Great choice!',
  WeHaveEmailedYouProductDetails:
    "We've emailed you product details and will be in touch shortly to discuss your project. {contactUs} for further assistance.",
  YourSelectedProductOrService: 'Your selected product or service',
  ShowOtherProducts: 'Show Other Products',
  HideOtherProducts: 'Hide Other Products',
  YouCanUploadImage: 'You can upload image ....',
  Catalog: 'Catalog',
  SelectedProducts: 'Selected Products',
  Customize: 'Customize',
  ThankYouScreenUploadHint:
    "It's possible to upload the video with the max size of 200MB or image of PNG, JPG or JPEG format with the max size of 15MB",
  EditCallToAction: 'Edit {buttonType} "Call to Action" Button',
  BackToCatalog: 'Back to Catalog',
  DeletePhoto: 'Delete Photo',
  YourChangesWillBeSavedAfter:
    'Your changes will be saved after saving changes on the Products page.',
  DeleteProduct: 'Delete Product',
  EditProduct: 'Edit Product',
  AreYouSureDeleteProductName: 'Are you sure you want to delete {productName}?',
  ThisProductCard: 'This Product Card will also be removed from markets.',
  ProductCardHasBeenDeleted: 'Product Card has been deleted successfully!',
  UnfortunatelyProductCard:
    'Unfortunately, the Product Card has not been deleted. Please, try again.',
  EditProductCard: 'Edit Product Card',
  MonthlyPriceBasedOn: 'Monthly price based on {percentageRate}% APR for {months} months',
  MonthlyPaymentEstimates:
    '* - Monthly payment estimates are not guaranteed until you have completed the pre-approval process and received final approval for financing. The final financing pricing options are subject to credit approval and may differ from the estimates provided. Accessible credit report and acceptance of final terms are required to apply for any credit or payment plan.',
  Analytics: 'Analytics',
  TotalWebsiteLeads: 'Total Web Leads',
  TotalOtherContacts: 'Total Other Contacts',
  TotalAddressOnlyLeads: 'Total Address Only Leads',
  AddressEntries: 'Address Entries',
  UniqueAddressesEntered: 'Unique addresses entered.',
  AverageProjectSize: 'Average Project Size',
  AverageTotalSqftOfRoofForAllStructures: 'Average total sqft of roof for all structures.',
  AverageTotalSQOfRoofForAllStructures: 'Average total SQ of roof for all structures.',
  TimesAlmostDoneForm: 'times "Almost Done" form was shown.',
  SentDetails: 'Sent Details',
  DetailsWereSentToUsers: 'Details were sent to users.',
  OutsideOfServiceArea: 'Outside of Service Area',
  AddressesOutsideOfOurServiceArea: 'addresses outside of our service area',
  DataUnavailable: 'Data Unavailable',
  AddressesSearchedWithUnavailableData: 'addresses searched with unavailable data',
  TimesGetHelpFormWasShown: 'times "Get Help" form was shown',
  CreateDate: 'Create Date',
  NotMarketSpecific: 'Not market specific.',
  AverageQuote: 'Average Quote',
  TheHighestPrice: 'The Highest Price:',
  TheLowestPrice: 'The Lowest Price:',
  TopProducts: 'Top Products',
  Ordinal:
    '{number, selectordinal, one {#<span>st</span>} two {#<span>nd</span>} few {#<span>rd</span>} other {#<span>th</span>}}',
  ShowMore: 'Show More',
  ShowLess: 'Show Less',
  YouCanNotDeleteThisProduct: "You can't delete this product",
  YouMustHaveAtLeast:
    'You must have at least one product shown in {markets}. Please add at least one other product to your selections before you can remove {productName}.',
  Integration: 'Integration',
  NoIntegration: 'No Integration',
  Integrations: 'Integrations',
  SalesRabbit: 'SalesRabbit',
  SalesRabbitIntegration: 'SalesRabbit Integration',
  Beacon: 'Beacon',
  APIKey: 'API Key',
  ProductReq: 'Product *',
  PriceCanNotBeAppliedFor: "Price can't be applied for {productNames}",
  TheAddressProvidedCouldNotBeFound:
    'The address provided could not be found. Use the address search box to find your address.',
  ShowInSQ: 'Show in SQ',
  OneSquare100SquareFeet: '1 Square = 100 square feet',
  WeAreUnableToTurnOffAllMarkets:
    'We are unable to turn off all markets - you must have at least one active market.',
  WeAreUnableToTurnOffAllMarketsInState:
    'We are unable to turn off all markets in {stateName} - you must have at least one active market while that state is turned on.',
  IfYouWantToTurnOffTheState:
    'You have disabled all markets in {stateName}. To disable this state, go to the National Service Area tab and uncheck the state, otherwise users will see quotes based on the nearest active market from their address.',
  WeAreUnableToTurnOffAllStates:
    'We are unable to turn off all states - you must have at least one active state.',
  ToolPreview: 'Tool Preview',
  ToolDemo: 'Tool Demo',
  ToolPreviewDescription:
    'This allows you to use your tool and generate quotes without entering any contact information.',
  SquareFootageSetting: 'Square Footage Settings',
  ShowSquareFootage: 'Show Square Footage',
  SuggestedSlopeSetting: 'Suggested Slope Setting',
  ShowSuggestedSlope: 'Show Suggested Slope',
  ShowMonthlyPriceOnAllProductCards: 'Show monthly price on all product cards for this market',
  ShowTotalPriceOnAllProductCards: 'Show total price on all product cards for this market',
  SelectMarketsToApplyBasicFinancing:
    'Please select the market(s) you want to apply Basic Financing with the monthly price based on {percentageRate} % APR for {months} months.',
  FinancingCaption: '{financingType}: {months} mo; {percentageRate}%',
  SaveChangesBeforeApply: 'To apply needs to save financing changes first.',
  SwitchMarketToBasicFinancing: 'Switch {marketName} to Basic Financing',
  SwitchSelectedMarketsToBasicFinancingUSA:
    'Switch selected markets to Basic Financing. Otherwise, those settings will be applied to the Custom and CLP financing types as a backup',
  SwitchSelectedMarketsToBasicFinancingCanada:
    'Switch selected markets to Basic Financing. Otherwise, those settings will be applied to the Custom financing type as a backup',
  MarketHasFinancingType: '<b>{marketName}</b> has <b>{financingType}</b> financing type.',
  YouCouldntDisableAllMarkets: 'You couldn’t disable all markets.',
  LeadConversions: 'Lead Conversions',
  TotalToolInteractions: 'Total Tool Interactions',
  GrandTotalOfLeads: 'Grand Total of Leads',
  Including: 'Including:',
  PageConversion: 'Page Conversion',
  RoofQuoteProViewed: 'Total RoofQuote PRO™ Views',
  AddressLoaded: 'Address Loaded',
  ContactFormSubmitted: 'Contact Form Submitted',
  ContactFormDisplayed: 'Contact Form Displayed',
  ProductRequestedCTA: 'Product Requested CTA',
  TimesAlmostDoneFormWasShown: 'Times "Almost Done" form was shown.',
  ProjectDetails: 'Project Details',
  OtherInfrormation: 'Other Information',
  MapInteractions: 'Map Interactions',
  EditRoofsClicked: 'Edit Roofs Clicked',
  NumberOfTimesUsersEditedTheRoofStructures: 'Number of times users edited the roof structures.',
  AddRoofClicked: 'Add Roof Clicked',
  NumberOfTimesUsersAddedNewRoofStructure: 'Number of times users added new roof structure(s).',
  SlopeChanged: 'Slope Changed',
  NumberOfTimesUsersChangedTheSlope: 'Number of times users changed the slope.',
  RoofRenamed: 'Roof Renamed',
  NumberOfTimesUsersRenamedRoofStructure: 'Number of times users renamed roof structure(s).',
  RoofInclusionToggled: 'Roof Inclusion Toggled',
  NumberOfTimesUsersIncludedRemovedStructureFromQuote:
    'Number of times users included/removed structure from quote.',
  FormsInteractions: 'Forms Interactions',
  HelpFormDisplayed: 'Help Form Displayed',
  NumberOfTimesGetHelpFormWasShown: "Number of times 'Get help' form was shown.",
  HelpFormSubmitted: 'Help Form Submitted',
  NumberOfTimesUsersSubmittedGetHelpForm: 'Number of times users submitted ‘Get Help’ form.',
  OutsideServiceArea: 'Outside Service Area',
  NumberOfAddressesThatWereOutsideOfServiceArea:
    'Number of addresses that were outside of service area.',
  NumberOfAddressesSearchedButDataWasNotAvailable:
    'Number of addresses searched but data wasn’t available.',
  Date: 'Date',
  PluralNumber: '{value, selectordinal, one {#st} two {#nd} few {#rd} other {#th}}',
  ContractorLoanPro: 'ContractorLoanPRO',
  BasicFinancing: 'Basic Financing',
  Introducing: 'Introducing',
  GetHomeOwnersALoan:
    'Get homeowners a loan within minutes right from their instant quote, or send them an invitation to apply for a loan.',
  EasyToUseAndMobileFriendlyLending: 'Easy to use and mobile-friendly lending',
  ClosedLoopPayments: 'Closed-loop payments ensure you get paid',
  ZeroStartUpCost: 'Zero start-up cost and low risk',
  HomeownerCanOnly: 'Homeowner can only use their loan with your company',
  LoanAmmountApproved: 'Loan amount approved is 15% over their instant quote price',
  SignUpNowAndStartOffering:
    'Sign Up Now And Start Offering Real Financing With Instant Decisioning',
  YourMobilePhoneReq: 'Your Mobile Phone *',
  BusinessNameReq: 'Business Name *',
  BusinessEmailReq: 'Business Email *',
  BusinessPhoneReq: 'Business Phone *',
  BusinessAddressReq: 'Business Address *',
  BusinessAddress2: 'Business Address 2',
  CityReq: 'City *',
  StateReq: 'State *',
  ZIPCodeReq: 'ZIP Code *',
  PleaseProvideAValidBusinessAddress: 'Please provide a valid business address',
  PleaseProvideAValidCity: 'Please provide a valid city',
  PleaseProvideAValidZipCode: 'Please provide a valid ZIP Code',
  SignUp: 'Sign Up',
  ActivityCenter: 'Activity Center',
  AllTransactions: 'All Transactions',
  RateSheet: 'Rate Sheet',
  Statements: 'Statements',
  NewLoanApplication: 'New Loan Application',
  UserAccess: 'User Access',
  ClickHereToSeeAListOfUsers: 'Click to see a list of users and manage Contractor Loan PRO access',
  CustomerSummaryReport: 'Customer Summary Report',
  RecentLoanApplicationsReport: 'Recent Loan Applications Report',
  RecentLoanInvitations: 'Recent Loan Invitations Report',
  AvailableTransactions: 'Available Transactions',
  RecentActivity: 'Recent Activity',
  Send: 'Send',
  OfferCode: 'Offer Code',
  LoanApplicationHasBeenSent: 'Loan application has been sent successfully!',
  UnfortunatelyLoanApplication:
    'Unfortunately, the loan application has not been sent. Please, try again.',
  ProvidePhoneNumberOrEmail: 'Provide phone number or email:',
  Or: 'or',
  Address: 'Address',
  City: 'City',
  Zip: 'Zip',
  MobilePhoneNumber: 'Mobile Phone Number',
  AvailableSpend: 'Available Spend',
  AmountSpent: 'Amount Spent',
  AppID: 'App ID',
  BasePackage: 'Base Package',
  AllLoanProductOfferings: 'All Loan Product Offerings',
  OfferCodes: 'Offer Codes',
  UseThisUniqueIdentifier:
    'Use this unique identifier when discussing product options with merchant support or team members at your company.',
  SimplyStatedTheFull:
    'Simply stated, the full term is the purchase window period plus payback period of the loan product represented in a total term length.',
  AnnualPercentageRateOfInterest:
    'Annual percentage rate of interest applied to a loan product. APRs may vary depending on consumer credit score.',
  ThisIsYourFee:
    'This is your fee for offering the selected products below. Each product may carry a different fee.',
  OpenProductDetails: 'Open Product Details',
  DeleteFromBasePackage: 'Delete from Base Package',
  ApplyToMarketsProducts: 'Apply to Market(s)/Product(s)',
  PleaseSelectMarketsOrProducts:
    'Please select the market(s) or product(s) for which you want to apply:',
  ExportStatement: 'Export Statement',
  OpenStatement: 'Open Statement',
  SubmittedOn: 'Submitted on {date}',
  ForThePeriod: 'For the Period of {start} to {finish}',
  StatementNumber: 'Statement #{number}',
  Momnt: 'Momnt',
  StatementDetails: 'Statement Details',
  FailedTransactions: 'Failed Transactions',
  FailedTransactionsDescription:
    'Transactions displayed here have been reversed due to an ACH return. An email was sent to notify all account admins for any failed transaction(s). ',
  StatementSummary: 'Statement Summary',
  StatementFor: 'Statement for',
  PayableTo: 'Payable to',
  TotalCharges: 'Total Charges',
  Refunds: 'Refunds',
  NetPurchases: 'Net Purchases',
  Fees: 'Fees',
  NetMerchantFees: 'Net Merchant Fees',
  NetMerchantReceipt: 'Net Merchant Receipt',
  Export: 'Export',
  ExpandByCategory: 'Expand by category below.',
  AddToBasePackage: 'Add to Base Package',
  RateSheetHasBeenAdded: 'Loan product has been added successfully!',
  UnfortunatelyTheRateSheetHasNotBeenAdded:
    'Unfortunately, the loan product has not been added. Please, try again.',
  RateSheetHasBeenRemoved: 'Loan product has been deleted successfully!',
  UnfortunatelyTheRateSheetHasNot:
    'Unfortunately, the loan product has not been deleted. Please, try again.',
  ProductDetails: 'Product Details',
  ProductID: 'Product ID',
  PromotionMotivatedCustomers: 'Promotion Motivated Customers',
  INormallyPayCash: '"I normally pay cash."',
  PromotionMotivatedCustomersINormallyPayCash:
    'Promotion Motivated Customers - "I normally pay cash."',
  InterestRateSensitiveCustomers: 'Interest Rate Sensitive Customers',
  IWontPayOver: '"I won\'t pay over X% interest rate."',
  InterestRateSensitiveCustomersIWontPayOver:
    'Interest Rate Sensitive Customers - "I won\'t pay over X% interest rate."',
  PaymentSensitiveCustomers: 'Payment Sensitive Customers',
  ICanOnlyPay: '"I can only pay $ X per month."',
  PaymentSensitiveCustomersICanOnlyPay:
    'Payment Sensitive Customers - "I can only pay $ X per month."',
  AreYouSureYouWantToRemoveProduct: 'Are you sure you want to delete product from Base Package?',
  Last5Transactions: 'Last 5 Transactions',
  EditOfferCode: 'Edit Offer Code',
  CreateNewOfferCode: 'Create New Offer Code',
  SelectTheLoanProducts: 'Select the loan products you would like to add to offer code',
  YouMustSelectAtLeastTwo:
    'You must select at least two loan products, and the maximum number is seven.',
  NameReq: 'Name *',
  AddToOffer: 'Add to Offer',
  DeleteFromOffer: 'Delete from Offer',
  OfferCodeHasBeenCreated: 'Offer code has been created successfully!',
  UnfortunatelyTheOfferCodeHasNotBeenCreated:
    'Unfortunately, the offer code has not been created. Please, try again.',
  OfferCodeHasBeenUpdated: 'Offer code has been updated successfully!',
  UnfortunatelyTheOfferCodeHasNotBeenUpdated:
    'Unfortunately, the offer code has not been updated. Please, try again.',
  YouCannotUseAnExistingName: 'You cannot use an existing name',
  DeleteOfferCode: 'Delete Offer Code',
  AreYouSureYouWantToRemoveOfferCode: 'Are you sure you want to delete offer code?',
  OfferCodeHasBeenRemoved: 'Offer code has been deleted successfully!',
  UnfortunatelyTheOfferCodeHasNotBeenRemoved:
    'Unfortunately, the offer code has not been deleted. Please, try again.',
  YouCanChooseBasePackageOrOfferCode: 'You can choose the Base Package or an Offer Code',
  PayRequestAmount: 'Pay Request Amount',
  TransactionDescription: 'Transaction Description',
  TransactionCannotExceed: 'Transaction cannot exceed {amount}',
  Request: 'Request',
  Refund: 'Refund',
  Amount: 'Amount',
  DateTime: 'Data/Time',
  SubmittedBy: 'Submitted by',
  RefundtHasBeenSent: 'Refund has been sent successfully!',
  UnfortunatelyRefundHasNot: 'Unfortunately, the refund has not been sent. Please, try again.',
  PleaseSelectExportType: 'Please select export type.',
  HowInstantLoanWorks: 'How Instant Loan Works',
  HowToRepayYourLoan: 'How to Repay Your Loan',
  MoreBuyingPowerLessHassle: 'More Buying Power. Less Hassle',
  LearnMore: 'Learn More',
  GetPreApproved: 'Get Pre-Qualified',
  PromotionalFinancingProvidesOffers:
    'Promotional financing provides offers that cater to your needs and keeps more money in your pocket today.',
  SimpleOnlineApplicationWithInstantDecisioning:
    'Simple Online Application with Instant Decisioning',
  ShopOffersWithoutAffectingCreditScore: 'Shop Offers Without Affecting Credit Score',
  ReceiveInstantApproval: 'Receive Instant Approval',
  MonthPurchaseWindowtoSpendYourLoan: '5-Month Purchase Window to Spend Your Loan',
  NoEarlyPaymentPenaltyOnAnyLoans: 'No Early Payment Penalty On Any Loans',
  InvitationSent: 'Invitation Sent!',
  WeSentInvitationToApplyYourMobilePhone:
    "We've sent an invitation to apply to your mobile phone. It only takes a few minutes to get pre-qualified, and this will not affect your credit score. Once pre-qualified, you can choose a loan that fits your needs. Apply for the loan and get qualified within minutes!",
  IfYouWnatToGetMoreInfoAboutLoan: 'If you want to get more info about loan click {link}.',
  IfYouWouldLikeToUseDifferentPhoneNumber:
    "If you'd like to use a different phone number or email, please enter it here:",
  ResendInvitation: 'Resend Invitation',
  Step: 'Step {index}',
  ClickGetPreQualified: 'Click "Get Pre-Qualified" to receive your mobile application',
  SubmitYourApplication: 'Submit your application and view pre-qualified offers',
  GetApprovedAndFunded: 'Get approved and funded',
  SetupAutomaticPayments: 'Setup automatic payments in minutes',
  SecureLoginToProtect: 'Secure Login to Protect Your Data',
  SimpleOneTimeOrAutoPayments: 'Simple One-time or Auto Payments',
  ClearAndConciseMonthlyStatements: 'Clear and Concise Monthly Statements',
  PayOnlineOrByPhone: 'Pay Online or by Phone',
  Next: 'Next',
  TotalPrice: 'Total Price',
  TheInvitationHasBeenSend: 'The invitation has been sent successfully!',
  UnfortunatelyTheInvitationHasNotBeenSent:
    'Unfortunately, the invitation has not been sent successfully. Please, try again.',
  FinancingIsNotAvailable: 'Financing Is Not Available',
  WeAreSorryToInform:
    "We're sorry to inform you that this financing option is no longer available.",
  BackToInvitation: 'Back to Invitation',
  FinanceAnalytics: 'Finance Analytics',
  StartOfferingRealFinancing: 'Start Offering Real Financing',
  TotalFinalizedLoan: 'Total Finalized Loan',
  UsersLoanWasSuccessfullyFinalized: 'Users loan was successfully finalized.',
  TotalCanceledLoan: 'Total Canceled Loan',
  UsersLoanWasCanceled: 'Users loan was canceled.',
  AverageApprovedLoanAmount: 'Average Approved Loan Amount',
  AverageAmountOfApproved: 'Average amount of approved and accepted loans.',
  RepQuotes: 'Rep Quotes',
  RepQuotesDescription: 'Unlimited instant quotes to supercharge your sales.',
  UnlimitedQuotesAtRecordSpeeds: 'Unlimited quotes at record speeds. Add lead to your dashboard',
  TurnThisOffToSkipTheContactForm: 'Turn this off to skip the contact form and not create a lead',
  TurnThisOnToCreateANewLead: 'Turn this on to create a new lead',
  StatewideLicense:
    'Statewide License{isOutOfRadius, select, true {: not available in new radius} other {}}',
  NotAvailableInNewRadius: 'Not available in new radius',
  AfterSavingThisStateWillBeUnavailable: '* After saving this state will be unavailable',
  PleaseCheckYouEmail:
    'Please check your email {email} for a link to your merchant application, which takes about 5 minutes to complete.\n<b>Notice</b>: Your merchant application will expire in 7 calendar days',
  NameCanHaveOnly:
    'Name can have only alphanumeric characters and space with length from 2 to 80 symbols',
  ThisRecordWasCreatedOutside:
    'This record was created outside of our app, so you cannot send a payment request or refund through our app.',
  PerSq: 'Per Sq',
  Basic: 'basic',
  Lowest: 'lowest',
  Highest: 'highest',
  MomntPaymentsEstimation:
    '* - These estimates illustrate monthly loan payments for the most creditworthy consumers. Financing requires a credit application and is subject to credit approval. The lender will use your credit report and credit score to set the terms of the credit, including credit limit and the Annual Percentage Rate (APR). Your monthly payment may be higher than these estimates depending upon the amount you borrow, your APR, and your credit history.',
  DetailsWereSent: 'Details were sent',
  Description: 'Description',
  BackToStart: 'Back to Start',
  ChooseFinancingTypeWhichWillApply:
    'Choose which financing type to apply to all products in selected market',
  SwitchingToBasicFinancing:
    'Switching to Basic Financing will disable Contractor Loan Pro for all products. Factoring in Merchant Fee will no longer be available.',
  SwitchingToContractorLoanPro:
    'Switching to Contractor Loan Pro will automatically apply your Base Package to all products currently showing a monthly price.',
  SwitchingToCLP: 'Switching to CLP',
  SwitchingToBasicFinancingTitle: 'Switching to Basic Financing',
  SwitchingToCustomFinancingTitle: 'Switching to Custom Financing',
  SwitchingToCustomFinancing:
    'Switching to Custom Financing will disable Contractor Loan Pro for all products. Factoring in Merchant Fee will no longer be available.',
  StateIsntAvailable: 'State isn’t Available',
  FinancingType: 'Financing Type',
  PricePerSquare: 'Price Per Square',
  FinancingOptions: 'Financing Options',
  PaymentsAsLowAs: 'Payments as\nLow as',
  InterestAsLowAs: 'Interest as\nLow as',
  ZeroAPRUpTo: '0.00% APR\nup to',
  FinancingAndProducts: 'Financing & Products',
  EditSettingsForBasicFinancing: 'Edit settings for <a>Basic Financing</a>',
  FactorInMerchantFee: 'Factor in Merchant Fee',
  ExampleWithFee: 'Example With Fee',
  TurningThisOnWill:
    'Turning this on will automatically adjust the Total Price to include the average merchant fee from {loanProduct}',
  PricePerMonth: 'Price Per Month',
  IfYouWouldLikeToDisplay:
    'If you would like to display price per month you need to select Financing Options.',
  InstantLoanOptions: 'Instant Loan Options',
  ZeroAPRIsOnlyAvailable:
    '0.00% APR is only available if the loan balance is repaid in full by {months} months of loan origination. Get Pre-Qualified to see all qualifying loan options.',
  InterestAsLowAsPercent:
    'Interest as low as {interest}% is based upon a loan amount of {total} that is paid over {months} number of months. Get Pre-Qualified to see all qualifying loan options.',
  ThePaymentOfAmountPerMonth:
    'The payment of {payments} per month is based upon a loan amount of {total} that is paid over {months} number of months at an APR of {interest}. Get Pre-Qualified to see all qualifying loan options.',
  AverageMerchantFee: 'Average Merchant Fee',
  MerchantFeeAdded: 'Merchant Fee Added',
  SaveMarketSettingBefore: 'Save market setting before customizing products details',
  SwitchingToContractorLoanProWillApply:
    'Switching to Contractor Loan Pro will apply your {loanProduct} to the selected products in the selected markets{isPartially, select, true { and all other basic financing monthly prices will be automatically switched to your Base Package for the market(s). Products with no monthly price will show No Financing unless you apply the Base Package or an Offer Code} other {}}.',
  VerifyBothMicroDeposits: 'Verify Both Micro-Deposits to Activate the Account',
  TwoMicroTransactions:
    'Two micro-transactions have been sent to your payment account with amounts between $0.01 and $0.99. Please enter these amounts below in any order. Please ensure that the amounts are correct in order to successfully verify your account and start receiving payments.',
  TheAmountsEnteredWere:
    'The amounts entered were incorrect, or the micro-deposits expired after 14 days. You can resend verification.',
  PleaseContactMerchantSupport:
    'Unfortunately, something went wrong. Please contact Merchant support <a>{phone}</a>.',
  VerifyAccount: 'Verify Account',
  ResendVerification: 'Resend Verification',
  Amount1: 'Amount 1',
  Amount2: 'Amount 2',
  InCSV: 'in CSV',
  InExcel: 'in Excel',
  ExportToCSV: 'Export to CSV',
  ExportToExcel: 'Export to Excel',
  ExportToPDF: 'Export to PDF',
  AreYouSureWantToExport: 'Are you sure want to export {value}?',
  PricingHistory: 'Pricing History',
  CompanyUsers: 'Company Users',
  AddNewUser: 'Add New User',
  PleaseFillInUserDetails: 'Please fill in user details.',
  RoleReq: 'Role *',
  Admin: 'Admin',
  SalesRep: 'Sales Rep',
  AdminHasAccessToAllPages: 'Admins have access to all pages and settings.',
  YourPrimaryContactIs:
    'You have already had primary contact {firstName} {lastName}. After saving primary contact will be changed.',
  SalesRepUsersAccess:
    "Sales Reps have access only to their Leads Dashboard, Rep Quotes, Orders and Personal Profile. If given access to CLP Financing, they'll be able to request payments and see transactions.",
  SalesRepUsersHaveAccessExcludingClp:
    'Sales Reps have access only to their Leads Dashboard, Rep Quotes, Orders, and My Profile.',
  AfterSavingTheContact: 'After saving, the contact will no longer be Company Primary Contact.',
  MakeCompanyPrimaryContact: 'Make Company Primary Contact',
  AddAsUser: 'Add as User',
  RoofQuotePRO: 'RoofQuote PRO™',
  RoofQuotePROUSA: 'RoofQuote PRO™ [USA]',
  RoofQuoteProCanada: 'RoofQuote PRO™ [Canada]',
  EnterYourStreetAddressToStart: 'Enter a street address to start',
  CustomSlope: 'Custom Slope',
  EditCustomSlope: 'Edit Custom Slope',
  PleaseChooseCustomSlopeFromTheList: 'Please choose custom slope from the list.',
  Slope: 'Slope',
  SlopeWithName: '{name} Slope',
  StructureWithComplexity: '{name} Structure',
  CreateQuote: 'Create Quote',
  OriginalRep: 'Original Rep',
  Rep: 'Rep',
  Reassigned: 'Reassigned',
  CompleteTheContactFormToSendThisLeads:
    'Complete the contact form to send this lead’s info to your dashboard and be able to email them quotes for specific products. If you’re using Contractor Loan Pro you’ll be able to send them an invitation to get pre-qualified.',
  More: 'More',
  NeedToSelectAtLeastOnePosition: 'Need to select at least one position',
  ReassignTo: 'Reassign to',
  OpenURL: 'Open URL',
  PleaseChooseUserFromTheList:
    'Please choose user from the list who will be responsible for this lead.',
  User: 'User',
  Reassign: 'Reassign',
  MultipleRep: 'multiple rep',
  UnfortunatelyTheLeadHaveNotBeenReassigned:
    'Unfortunately, the lead has not been reassigned to. Please, try again.',
  LeadsHaveBeenReassignedToSuccessfully: 'Lead has been reassigned to successfully!',
  SelectAllLeads: 'Select All Leads',
  SelectLeadsOnCurrentPage: 'Select Leads on Current Page',
  UnselectAllLeads: 'Unselect All Leads',
  SendLoanInvitation: 'Send Loan Invitation',
  EmailDetails: 'Email Details',
  CreateQuoteManually: 'Create quote manually',
  THeAddressIsNotFound: 'If not in the list, the address was not found.',
  ToSendEmailsFromYourPersonalEmailVerifyEmail:
    'To send emails from your personal email, please verify the email.',
  WeSentAnEmailToVerify:
    'We sent a verification email to {email}. Once it’s verified, you’ll need to sign in using your new email address.',
  StartOver: 'Start Over',
  UpdateLead: 'Update Lead',
  CreateNewLead: 'Create new Lead',
  CreateLead: 'Create Lead',
  AreYouSureYouWantToStartOver: 'Are you sure you want to start over?',
  AllUnsavedRoofsSettingsWillBeLost: 'All unsaved roofs settings will be lost.',
  AreYouSureYouWantToCreateNewLead: 'Are you sure you want to create new lead?',
  TheDataWillBeCreatedForTheNewSeparateLead: 'The data will be created for the new separate lead.',
  AreYouSureYouWantToModifyLead: 'Are you sure you want to modify {name}?',
  ExistingLeadWillBeModified:
    'Existing lead will be modified. Previous lead data can not be restored.',
  Modify: 'Modify',
  RepQuotesHasBeenStartedOverSuccessfully: 'Rep Quotes has been started over successfully!',
  UnfortunatelyTheRepQuoteHasNotBeenStartedOver:
    'Unfortunately, the Rep Quotes has not been started over. Please, try again.',
  LeadHasBeenCreatedSuccessfully: 'Lead has been created successfully!',
  UnfortunatelyTheLeadHasNotBeenCreated:
    'Unfortunately, the lead has not been created. Please, try again.',
  LeadHasBeenModifiedSuccessfully: 'Lead has been modified successfully!',
  UnfortunatelyTheLeadHasNotBeenModified:
    'Unfortunately, the lead has not been modified. Please, try again.',
  YouCanManuallyDrawYourRoofShape: 'You can manually draw your roof shape.',
  ThisAddressIsLocatedOutsideYourServiceArea: 'This address is located outside your service area.',
  SwitchAllMarketsToContractorLoanPro: 'Switch All Markets to Contractor Loan PRO',
  SwitchAllMarketsToBasicFinancing: 'Switch All Markets to Basic Financing',
  BasicFinancingAppliedToAllMarkets: 'Basic financing applied to all markets',
  New: 'New',
  Open: 'Open',
  Cold: 'Cold',
  Lost: 'Lost',
  Sold: 'Sold',
  Deleted: 'Deleted',
  StatusHasBeenChangedSuccessfully: 'The status has been changed successfully!',
  UnfortunatelyStatusHasNotBeenChanged:
    'Unfortunately, the status has not been changed. Please try again.',
  InterestedInFinancing: 'Interested in Financing',
  HomeownersContactInformationWillBeUsedForEmailingQuotes:
    "Homeowner's contact information will be used for emailing quotes and texting invites to loan pre-qualifications.",
  PrimaryContact: 'Primary contact',
  DeleteUser: 'Delete User',
  EditUser: 'Edit User',
  AreYouSureYouWantToMakerPrimaryContact:
    'Are you sure you want to make {name} as Primary Contact?',
  TheyWillBeTheMainPointOfContact: 'They will be the main point of contact for your company.',
  ContactHasBeenMadePrimaryContact: 'Contact has been made as primary contact successfully!',
  UnfortunatelyTheContactHasNotBeenMadePrimary:
    'Unfortunately, the contact has not been made as primary contact. Please, try again.',
  Make: 'Make',
  YouHaveAlreadyHadPrimaryContact:
    'You have already had primary contact {name}. After saving primary contact will be changed.',
  UserHasBeenDeletedSuccessfully: 'User has been deleted successfully!',
  UnfortunatelyTheUserHasNotBeenDeleted:
    'Unfortunately, the user has not been deleted. Please, try again.',
  AreYouSureYouWantToDeleteUser: 'Are you sure you want to delete {name}?',
  NeedToReassignLeads: 'Need to reassign leads',
  EditEmail: 'Edit Email',
  YouCanChangeTheContentOfTheSentEmail: 'You can change the content of the sent email',
  SendTo: 'Send to:',
  Comment: 'Comment',
  EditCommentTitle: 'Edit Comment Title',
  EditCommentText: 'Edit Comment Text',
  ProvideCommentWhichYouWantToAddToTheEamil: 'Provide comment which you want to add to the email.',
  EnterTheCommentYouWantToAddToTheLetter: 'Enter the comment you want to add to the letter',
  EditProductsThatWilBeSentToLeads: 'Edit products that will be sent to leads.',
  ProductsDescription: 'Products Description',
  EditProductDescriptionThatWilBeSentToLeads:
    'Edit products description that will be sent to leads.',
  PackageDetails: 'Package Details',
  AttachPDFOfQuote: 'Attach PDF of Quote',
  EmailHasBeenSentSuccessfully: 'Email has been sent successfully!',
  CompanyInfo: 'Company Info',
  TotalRepLeads: 'Total Rep Leads',
  BackToProducts: 'Back to Products',
  AttentionIfYouChangeYourEmailAddress:
    'Attention! If you change your email address, your login will be changed after verifying your new email.',
  TypeOfInstance: 'Type of Instance',
  FileHasBeenExportedSuccessfully: 'File has been exported successfully!',
  UnfortunatelyTheFileHasNotBeenExported:
    'Unfortunately, the file has not been exported. Please, try again.',
  LeadStatus: 'Lead status',
  ToUpdateYourEstimateOrUseADifferentAddress:
    'To update your estimate or use a different address -',
  GotIt: 'Got it',
  LeadInformation: 'Lead Information',
  FillInYourHomeownersInformation: 'Fill in your homeowner’s information.',
  AreYouSureWantLeavepage: 'Are you sure want to leave this page without saving changes?',
  AllChangesHaveBeenAppliedToTheDashboard: 'All changes have been applied to the dashboard.',
  AllLeadChangesHaveBeenAppliedToTheDashboard:
    'All lead changes have been applied to the dashboard.',
  CompanyOwner: 'Company Owner',
  CompanySubscription: 'Company Subscription',
  CompanyEmailReq: 'Company Email *',
  LoanProduct: 'Loan Product',
  ConnectYourEmail: 'Connect Your Email',
  PleaseCompleteYourEmailDetails: 'Please complete your email details:',
  Copy: 'Copy',
  TheCustomerGotDetailsAboutThisProduct: 'The customer got details about this product',
  HideTotalPriceOnTheProductCard: 'Hide total price on the product card',
  ShowTotalPriceOnTheProductCard: 'Show total price on the product card',
  HideMonthlyPriceOnTheProductCard: 'Hide monthly price on the product card',
  ShowMonthlyPriceOnTheProductCard: 'Show monthly price on the product card',
  TurnOn: 'Turn On',
  TurnOff: 'Turn Off',
  ClickEditToMakeChanges: 'Disabled. Click “Edit“ to make changes',
  RefreshData: 'Refresh Data',
  EmailService: 'Email Service',
  OrProvideYourOwn: 'or provide <a>your own</a>. Example: Outlook.com',
  OrChooseFromTheList: 'or <a>choose from the list</a>. Example: smtp-mail.outlook.com',
  PasswordWillBeEncrypted: 'Password will be encrypted',
  Connect: 'Connect',
  Port: 'Port',
  PleaseEnterTheCorrectPassword: 'Please enter the correct password',
  PleaseEnterTheCorrectEmailService: 'Please enter the correct email service',
  PleaseCheckPort: 'Please check a port',
  ToSendDetailsFromRepQuotesVerifyIt:
    'To send details from Rep Quotes using {email}, please verify it first. Once verified, you’ll need to connect your new email after signing in.',
  ToSendDetailsFromRepQuotesYouMustConnect:
    'To send details from Rep Quotes using your personal email address, you must <a>connect it</a>.',
  ChangesHaveBeenSavedSuccessfully: 'Changes have been saved successfully!',
  ConnectionHaveBeenSavedSuccessfully: 'Connection has been saved successfully!',
  UnfortunatelyTheChangesHaveNotBeenSaved:
    'Unfortunately, the changes have not been saved. Please, try again.',
  UnfortunatelyTheConnectionHaveNotBeenCreated:
    'Unfortunately, the connection has not been created. Please, try again.',
  ConnectYourEmailToSendQuotes: '<a>Connect your email</a> to send quotes to your customers.',
  Unassigned: 'Unassigned',
  ContractorLoanProIsNotYet:
    'Contractor Loan Pro is not yet available in the following states: {unavailableStates}. If CLP is applied, any addresses in these restricted states will show Basic Financing.',
  AreYouSureYouWantToSwitchOnCLP:
    'Are you sure you want to switch on ContractorLoanPRO for all markets as default?',
  AllMarketsWillBeSwitchedToCLP:
    'All markets with alternative financing will be switched to ContractorLoanPRO.',
  AreYouSureYouWantToSwitchOnBF:
    'Are you sure you want to switch on Basic Financing for all markets as default?',
  AllMarketsWillBeSwitchedToBF:
    'All markets with alternative financing will be switched to Basic Financing.',
  AreYouSureYouWantToSwitchMarketToBF:
    'Are you sure you want to switch this market to Basic Financing?',
  NoLeaveTheCurrentFinancing: 'No, leave the current financing',
  MarketWillBeSwitchedToBF: '{marketName} will be switched to Basic Financing.',
  NoIWantToLeaveAsIs: 'No, I want to leave as is',
  SomeStatesAreNotYetAvailable: 'Some states are not yet available',
  WebsiteAnalytics: 'Website Analytics',
  LeadSource: 'Lead Source',
  ExamplePort: 'Example: 587',
  ForCorrectWork:
    'To configure your connection, please create a new password according to the <a>Guide</a> and enter it below:',
  GiveAccessToCLP: 'Give Access to Contractor Loan Pro Financing',
  NoAccount: 'No Account',
  YouDontHavePermissions: "You don't have permissions to view this page.",
  PleaseContactYourCompanyAdmin: 'Please contact your company admins',
  PaymentSettings: 'Payment Settings',
  BankDetails: 'Bank Details',
  NiceWorkExpectAShortOnboardingCall:
    'Nice work! Expect a short onboarding call from Momnt in the next 1-2 business days before Contractor Loan Pro is officially activated. For support, call <a>1-800-470-3871</a>.',
  YouAreCurrentlySetUpToReceive: 'You are currently set up to receive payments by ACH.',
  UpdateBankDetails: 'Update bank details',
  Business: 'Business',
  Personal: 'Personal',
  BankAccount: 'Bank Account *',
  AccountType: 'Account Type *',
  NameOnAccount: 'Name on Account',
  NameOnAccountReq: 'Name on Account *',
  BankAccountNumber: 'Bank Account Number',
  BankAccountNumberReq: 'Bank Account Number *',
  VerifyBankAccountNumber: 'Verify Bank Account Number *',
  RoutingNumber: 'Routing Number *',
  Checking: 'Checking',
  Savings: 'Savings',
  SendChanges: 'Send changes',
  ChangesHaveBeenSentSuccessfully: 'Changes have been sent successfully!',
  UnfortunatelyTheChangesHaveNotBeenSent:
    'Unfortunately, the changes have not been sent. Please, try again.',
  YouHaveToSetUpABankAccount: 'You have to set up a bank account to continue creating CLP account.',
  ItAppearsSomeOfYourData:
    'It appears some of your data is incorrect. Please correct the input in order to proceed.',
  PleaseCorrectRoutingNumberFormat: 'Please correct routing number format.',
  TwoMicroTransactionShouldBeSent:
    'Two micro-transaction should be sent to your payment account with amounts between $0.01 and $0.99. It could take some time, please check back soon.',
  RefreshPage: 'Refresh page',
  SetDefaultLoanProducts: 'Set Default Loan Products',
  YourApplicationIsBeing:
    'Your application is being reviewed. Please contact Merchant support <a>{phone}</a>.',
  UnfortunatelyYourUpplication:
    'Unfortunately, your application wasn’t approved. Please contact Merchant support <a>{phone}</a>.',
  EmailConnectionIssue: 'Email connection issue',
  EditEmailConnection: 'Edit email connection',
  PleaseUpdateYourEmailConnection:
    'Please update your email connection on the My Profile page. Be aware by clicking Update you will be directed to My Profile and will lose unsaved data.<br/><br/>Or you can proceed with default email.',
  Update: 'Update',
  OpenQuote: 'Open Quote',
  Assign: 'Assign',
  PleaseSelectWhatYouWantToUpdate: 'Please select what you want to update:',
  Constructions: 'Constructions',
  ProductsAndPrice: 'Products and Price',
  MarketsProductsAndPrice: 'Markets, Products and Price',
  PleaseSelectAtLeastOneValue: 'Please select at least one value',
  ItIsNotAvailableForOldLeads: 'It’s not available for old leads',
  AssignTo: 'Assign To',
  LeadHasBeenAssignedToSuccessfully: 'Lead has been assigned to successfully!',
  UnfortunatelyTheLeadHasNotBeenAssignedTo:
    'Unfortunately, the lead has not been assigned to. Please, try again.',
  OrignialMarketIsNoLongerActive:
    '{market} is no longer active. Please select another market for this quote. Products and pricing will be used from the market selected.',
  ProductsAndPricingHaveBeenUpdatedFor: 'Products and/or pricing have been updated for {market}',
  SlideoutTab: 'Slideout Tab',
  VerticalPosition: 'Vertical Position',
  ShowOnTabletsAndDesktops: 'Show on tablets and desktops',
  ShowOnPhones: 'Show on phones',
  MyPublicLink: 'My Public Link',
  CreateAPersonalizedLinkToShare:
    'Create a personalized link to share and generate leads that will be assigned to you.',
  HowToCreatePublicLink: 'How to create Public Link',
  CopyAnyUrlOnYourCompanyWebsite:
    'Copy any URL on your company website where the instant quote tool is installed',
  AddRepToTheEndOfTheUrl: 'Add string to the end of the URL',
  BeAwareIfYouChangeYourEmailYoullNeedToUpdate:
    'Be aware if you change your email you’ll need to update your public link.',
  RemoveUrlIsReadyToShare: 'URL is ready to share',
  YouCanUseThisLinkInTheFollowingWay: 'You can use this link in the following way',
  LinkUsageExampleList:
    '<li>Add it to your email signature</li><li>Create a QR code and add it to your business card or other print materials</li><li>Share it on social media</li><li>Share it with real estate agents, insurance agents, or other partners</li>',
  CalloutTextEmptyError:
    'For the callout to be displayed, callout text or title should be filled. Please enter callout text or title or turn off the toggle.',
  YouCantChangeStatusBecauseUserHasACLP:
    "You can't change status because that user has a CLP account. Please contact your company’s account owner.",
  YouNeedAdminAccessToChangeTheseSettings:
    'You need admin access in order to change these settings. Please contact your company account owner.',
  SelectMarket: 'Select Market',
  SeeQuote: 'See Quote',
  WebhookType: 'Webhook Type',
  ContactFormCompleted: 'Contact Form Completed',
  ProductRequested: 'Product Requested',
  SendsTheDataAfterAnAddressIsEnteredWithoutCompletingContactForm:
    'It sends the data after an address is entered without completing Contact form.',
  DeleteWebhook: 'Delete Webhook',
  AddNewWebhook: 'Add New Webhook',
  UpdateMarkets: 'Update Markets',
  ApplyMarket: 'Apply Market',
  WeAreUnableToProvideAnInstantQuote:
    'We are unable to provide an instant quote because your browser is blocking cookies. Our widget needs cookies to work properly. To fix the issue, please adjust your browser settings to allow cookies for our site or use a different browser. Or fill out the form below to request your quote.',
  UseTheSameEmailsForAllMarkets: 'Use the same emails for all markets',
  ApplyEmailSettingsToOtherMarkets: 'Apply Email Settings to Other Markets',
  InboundEmailForReceiving: 'Inbound Email - for receiving lead notifications:',
  OutboundEmailForSending: 'Outbound Email - for sending customer quotes:',
  WeAreUpgradingOurEmailSystem:
    "We've upgraded our email system! Please <a>connect your outbound email</a> for better deliverability.",
  ToUseYourCompanyEmail:
    "To use your Company's email for sending customer emails, you must <a>connect it</a>.",
  ConnectionFailedPleaseReconnect: 'Connection failed. Please <a>reconnect it</a>.',
  ConnectionFailedForTheMarkets: 'Connection failed for the {markets}. Please reconnect it.',
  ToApplyNeedsToTurnOff: 'To apply needs to turn off using the same emails for all markets',
  Emails: 'Emails',
  AreYouSureYouWantToUseTheSame: 'Are you sure you want to use the same emails for all markets?',
  ByDefaultEmailsFromMarket:
    'By default, emails from {market} will be used. All other emails will be lost.',
  ConnectEmail: 'Connect Email',
  ProvideEmailAuthenticationDetails: 'Provide your email authentication details to connect it:',
  ConnectYourCompanyEmail: 'Connect your company email to send customer quotes.',
  ProvideFollowingDetailsForCompanyEmail:
    'Provide following details if you use company email for sending product quotes to customers.',
  UseDefaultCompanyEmail: 'Use Default Company Email',
  AreYouSureWantToResetEmails: 'Are you sure you want to reset emails to default?',
  AllMarketsWillUseCompanyEmail:
    'All markets will use company email by default. Email notifications will be turned on by default.',
  EmailChangesHaveBeenSaved: 'Email changes have been saved successfully!',
  UnfortunatelyEmailChanges:
    'Unfortunately, the email changes have not been saved. Please, try again.',
  ApplyEmailSettingsToOther: 'Apply Email Settings\nto Other Markets',
  PleaseSelectTheMarketsToApplyEmailSettings:
    'Please select the market(s) for which you want to apply Email Settings.',
  EmailForLeadNotifications: 'Email for lead notifications',
  EmailForSendingProduct: 'Email for sending product quotes to customers',
  ThisLeadAlreadyExists: 'This lead already exists.',
  YouHaveInTheSystemTheLead:
    'You have in the system the lead with the same address. Open it for getting more information.',
  OpenExistingLead: 'Open Existing Lead',
  ChooseOneOfTheExistingLead: 'Choose one of the existing lead with the same address.',
  Lead: 'Lead',
  StructuresAndSqFt: 'Structures and SqFt:',
  MoreActions: 'More Actions',
  SaveAsDefault: 'Save as Default',
  MyMarkets: 'My Markets',
  SeeProductDetails: 'See Product Details',
  ApplySettingsToOtherMarkets: 'Apply settings to other markets',
  NoFinancing: 'No Financing',
  ContractorLoanPROBasePackage: 'ContractorLoanPRO - Base Package',
  ContractorLoanPROOfferCodes: 'ContractorLoanPRO - Offer Codes',
  BackToMarketList: 'Back to market list',
  ApplyMarketSettingsToOtherMarkets: 'Apply Market Settings\nto Other Markets',
  PleaseSelectTheMarketsToApplyFinancingAndProducts:
    'Please select the market(s) you want to apply the following financing and product card settings:',
  SuggestedSlopeDescription: 'Slope suggestion is based on building data.',
  ActiveRep: 'Active Rep',
  RQPLeads: 'RQP Leads',
  ItemsRange: '{from} - {to} of {total} items',
  OrderMeasurements: 'Order Measurements',
  LeadDetails: 'Lead Details',
  Measurements: 'Measurements',
  FinalTotalSqFt: 'Final Total SqFt',
  OriginalSqFt: 'Original SqFt',
  ThisIncludesStructureEditsAndIsUsedToCalculatePricing:
    'This includes structure edits and is used to calculate pricing.',
  ThisIsTheSqftDetectedWithoutStructureEdits: 'This is the sqft detected without structure edits.',
  SqFtOfMainRoof: 'SqFt of Main Roof',
  RoofDetails: 'Roof Details',
  OpenInFullScreen: 'Open in Fullscreen',
  CloseFullScreen: 'Close Fullscreen',
  Search: 'Search',
  NOfM: '{n} of {m}',
  SqftInformation: '{initial} Original SqFt / {edited} Edited SqFt',
  ToGetDataClickOrderMeasurements: 'To get data please click the Order Measurements button above',
  Order: 'Order',
  PleaseSelectTheMeasurementReport: 'Please select the measurement report you want to order:',
  PleaseProvideInstructions: 'Please provide instructions:',
  Report: 'Report',
  EagleView: 'EagleView',
  EagleViewBidPerfect: 'EagleView Bid Perfect',
  EagleViewPremiumCommercialReport: 'EagleView Premium Commercial Report',
  EagleViewPremiumResidentialReport: 'EagleView Premium Residential Report',
  EagleViewBidPerfectReport: 'EagleView Bid Perfect Report',
  GAFQuickMeasure: 'GAF QuickMeasure',
  GAF: 'GAF',
  NotAvailableReport: '{report} - Not Available',
  TrialMeasurementCredit: 'You have 1 free trial measurement credit.',
  YouHaveNCreditsLeft: 'You have {amount} measurement credits left. ',
  Structures: 'Structures',
  AllStructures: 'All Structures',
  MainRoofOnly: 'Main Roof Structure Only (Largest)',
  MainRoofPlus: 'Main Roof Structure + Detached Garage',
  CommercialComplex: 'Commercial Complex',
  OtherStructures: 'Other (Please Provide Instructions)',
  GetMoreCredits: 'Get more credits',
  Instructions: 'Instructions',
  MeasurementReportsAreNotAvailableForThisRroject:
    'Measurement reports are not available for this project.',
  UnfortunatelyThisAddressIsNotAvailableForMeasurementProviders:
    'Unfortunately, this address is not available for measurement {isPlural, select, true {providers} other {provider}}.',
  YourGafMeasurementOrderHasBeenPlaced:
    'Your measurement order has been placed! All details will appear here when it’s complete. Turnaround time is typically {type, select, SF {1 hour} MF {1 business day} CM {1 business day} other {1 day}} but may vary.',
  YourEVMeasurementOrderHasBeenPlaced:
    'Your measurement order has been placed! All details will appear here when it’s complete. {type, select, RegularDelivery {Turnaround time is typically 3 business days but may vary.} ExpressDelivery {Turnaround time is typically 1 business day but may vary.} ThreeHourDelivery {Turnaround time is typically 3 business hours but may vary.} other {}} ',
  UnfortunatelyTheOrderHasNotBeenSent:
    'Unfortunately, the order has not been sent. Please, try again.',
  SingleFamily: 'Single-Family',
  MultiFamily: 'Multi-Family',
  Commercial: 'Commercial',
  PleaseProvideProductCode: 'Please provide product code:',
  ProductCode: 'Product Code',
  PleaseSelectAnotherReportType:
    'Please select another report type or get more credits to proceed.',
  CurrentPlan: 'Current Plan',
  GeneralSubscription: 'General Subscription',
  NextPayment: 'Next Payment',
  NMarkets: '{n} {n, plural, one {market} other {markets}}',
  NStateLicenses: '{n} state {n, plural, one {license} other {licenses}}',
  License: 'License',
  ConnectWithAdmin: 'Connect with admin',
  MeasurementCredits: 'Measurement Credits',
  Available: 'Available:',
  RenewOption: 'Renew Option',
  RenewDate: 'Renew Date:',
  AddRenewOption: 'Add Renew Option',
  LastPaymentWasCanceled:
    'Last payment was canceled. Please check with your bank for further information.',
  PaymentDetails: 'Payment Details',
  ProvideCreditCardOrBankAccount: 'Provide credit card or bank account',
  PleaseContactTheSupportTeam: 'Please contact the support team',
  AddPaymentDetailsFirst: 'Add Payment Details first',
  AccountIsntConnected: "Account isn't connected",
  AccountIsConnected: 'Account is connected',
  Disconnect: 'Disconnect',
  ConnectAccount: 'Connect Account',
  EditConnection: 'Edit Connection',
  ConnectYourSalesRabbitAccount:
    'Connect your SalesRabbit account to easily generate quotes within the SalesRabbit app.',
  LearnMoreAboutSalesRabbit: 'Learn More About SalesRabbit',
  SalesRabbitWasConnectedSuccessfully: 'SalesRabbit was connected successfully!',
  UnfortunatelyTheSalesRabbitWasNotConnected:
    'Unfortunately, the SalesRabbit was not connected. Please, try again.',
  SalesRabbitWasEditedSuccessfully: 'SalesRabbit was edited successfully!',
  UnfortunatelyTheSalesRabbitWasNotEdited:
    'Unfortunately, the SalesRabbit was not edited. Please, try again.',
  SalesRabbitWasDisconnectedSuccessfully: 'SalesRabbit was disconnected successfully!',
  UnfortunatelyTheSalesRabbitWasNotDisconnected:
    'Unfortunately, SalesRabbit was not disconnected. Please, try again.',
  ConnectYourEagleviewAccount:
    'Connect your EagleView account to get more information about leads roofs.',
  LearnMoreAboutEagleview: 'Learn More About EagleView',
  EagleviewWasConnectedSuccessfully: 'EagleView was connected successfully!',
  UnfortunatelyTheEagleviewWasNotConnected:
    'Unfortunately, the EagleView was not connected. Please, try again.',
  AddAccount: 'Add Account',
  ActiveAccountsAmount: '{amount} active {amount, plural, one {account} other {accounts}}',
  SomeAccountsActive:
    '{activeAccounts} of {accountsTotal} active {accountsTotal, plural, one {account} other {accounts}}',
  EagleViewWasDisconnectedSuccessfully: 'EagleView was disconnected successfully!',
  UnfortunatelyTheEagleViewWasNotDisconnected:
    'Unfortunately, the EagleView was not disconnected. Please, try again.',
  DisconnectAccount: 'Disconnect Account',
  SelectAccountWhichWouldBeDisconnected:
    'Select account which you would be disconnected after saving.',
  ManageAllYourConnectedAccount: 'Manage all your connected accounts.',
  ChooseAssociatedMarketsFromTheList: 'Choose associated markets from the list:',
  SomeMarketsAreNotAssociatedWithAnAccount: 'Some markets are not associated with an account.',
  MarketsAreNotAssociatedWithAnAccount:
    '{markets} {n, plural, one {is} other {are}} not associated with an account.',
  AccountName: 'Account Name',
  ReconnectAccount: 'Reconnect Account',
  EagleviewWasEditedSuccessfully: 'EagleView was edited successfully!',
  UnfortunatelyTheEagleviewWasNotEdited:
    'Unfortunately, the EagleView was not edited. Please, try again.',
  Okay: 'Okay',
  SelectedMarketsWillBeExcluded: 'Selected markets will be excluded from {accounts} automatically.',
  AccountOrdinal:
    '{number, selectordinal, one {#<sup>st</sup>} two {#<sup>nd</sup>} few {#<sup>rd</sup>} other {#<sup>th</sup>}} Account:',
  AssociatedWithAccount: 'Associated with {account}',
  ThisAccountAlreadyExists: 'This account already exists.',
  ToConnectANewAccount:
    'To connect a new account, try using a new browser or log out of EagleView before connecting.',
  ThisAccountAlreadyConnected: 'This account is already connected.',
  PleaseTryAnotherEmail: 'Please try another email to reconnect your expired account.',
  Existing: 'Existing',
  RecurlyAccount: 'Recurly Account',
  CompanyOwnersIfoWillBeUsed: "Company Owner's info will be used for creating new Recurly account.",
  EditSubscription: 'Edit Subscription',
  SubscriptionHasBeenUpdated: 'Subscription has been updated successfully!',
  UnfortunatelyTheSubscriptionHasNotBeenUpdated:
    'Unfortunately, the subscription has not been updated. Please, try again.',
  GAFQuickMeasureFullReport: 'GAF QuickMeasure Full Report',
  InProgress: 'In Progress',
  Declined: 'Declined',
  Completed: 'Completed',
  Failed: 'Failed',
  YourMeasurementIsOrdered: 'Your measurement is ordered and will appear here once complete.',
  ReportDate: 'Report Date',
  PleaseContactUsIfAnyQuestions: 'Please contact us if any questions.',
  SeeFullReport: 'See Full Report',
  SeePropertyOwnerReport: 'See Property Owner Report',
  See3DReport: 'See 3D Report',
  RoofArea: 'Roof Area',
  RoofFacets: 'Roof Facets',
  Pitch: 'Pitch',
  Eaves: 'Eaves',
  Hips: 'Hips',
  Rakes: 'Rakes',
  Ridges: 'Ridges',
  Valleys: 'Valleys',
  Flash: 'Flash',
  RoofleStep: 'Step',
  DripEdge: 'Drip Edge',
  LeakBarrier: 'Leak Barrier',
  RidgeCap: 'Ridge Cap',
  IceAndWaterEaves: 'Ice and Water Eaves',
  IceAndWaterValleys: 'Ice and Water Valleys',
  IceAndWaterRakes: 'Ice and Water Rakes',
  IceAndWaterStepFlashings: 'Ice and Water Step Flashings',
  IceAndWaterHeadwallFlashings: 'Ice and Water Headwall Flashings',
  IceAndWaterPenetrations: 'Ice and Water Penetrations',
  Starter: 'Starter',
  DownloadXML: 'Download XML',
  DownloadPDF: 'Download PDF',
  FullReport: 'Full Report',
  PropertyOwnerReport: 'Property Owner Report',
  AddDetails: 'Add Details',
  PrimaryPayment: 'Primary Payment',
  Expire: 'Expire',
  PaymentMethod: 'Payment method:',
  CreditCard: 'Credit Card',
  ACH: 'ACH',
  CardNumberReq: 'Card Number *',
  ExpirationDateReq: 'Expiration Date *',
  MM: 'MM',
  YYYY: 'YYYY',
  CVVReq: 'CVV *',
  AddressData: 'Address Data:',
  CurrentCardNumber: 'Current card number:',
  EditPaymentDetails: 'Edit Payment Details',
  AccountNumber: 'Account Number *',
  ConfirmAccountNumber: 'Confirm Account Number *',
  CountryReq: 'Country *',
  Address1Req: 'Address 1 *',
  Address2: 'Address 2',
  ZipPostalReq: 'Zip/Postal *',
  IAcceptThePrivacyPolicy:
    'I accept the <policy>Privacy Policy</policy> and <terms>Terms of Service</terms>.',
  NewPaymentHasBeen: 'New payment details has been added successfully!',
  UnfortunatelyTheNewPayment:
    'Unfortunately, the new payment details has not been created. Please, try again.',
  CurrentBankAccountNumber: 'Current bank account number:',
  Automations: 'Automations',
  MeasurementOrderSettings: 'Measurement Order Settings',
  AutomateMeasurementOrdersHint:
    'Automate Measurement Orders When Homeowner Clicks Get Final Proposal Button',
  TurnOnMeasurementOrdering: 'Turn on Measurement Ordering with Get Final Proposal button',
  PleaseSelectTheMeasurementReportYouWantToOrderAutomatically:
    'Please select the measurement report you want to order automatically:',
  PleaseSelectBackupMeasurementReport:
    'Please select a backup measurement report to order when your preferred report is not available. This is optional:',
  YouHaveNoMeasurementCompanyConnected:
    'You have no measurement company connected.\nPlease connect one or get credits to proceed.',
  EagleviewAccountsIsNotCurrentlyConnected:
    '{isAllAccountsDisconnected, select, true {Your account is not currently connected.\nPlease reconnect it or choose another report to proceed} other {Some accounts need to be reconnected}}. <span>Reconnect Now</span>',
  NotAvailable: 'Not Available',
  MeasurementsReport: 'Measurements Report',
  ReportHasBeenOrdered: 'Report has been ordered',
  ReportHasNotBeenOrdered: 'Report hasn’t been ordered',
  BackToBilling: 'Back to Billing',
  SelectACreditsPackage: 'Select a credits package:',
  AmountCredits: '{amount} Credits',
  SaveAmount: 'Save ${amount}',
  OrderSummary: 'Order Summary',
  CouponCode: 'Coupon Code',
  Discount: 'Discount',
  OrderTotal: 'Order Total',
  CouponIsNotValid: 'Coupon is not valid. Try another one.',
  Checkout: 'Checkout',
  YourRequestToBuyCredits: 'Your request to buy credits has been sent successfully!',
  UnfortunatelyTheRequestToBuyCredits:
    'Unfortunately, the request to buy credits has not been sent. Please, try again.',
  Trial: '(trial)',
  AmountPending: '(+{amount} Pending)',
  TrialStatus: 'Trial',
  Swap: 'Swap',
  Collapse: 'Collapse',
  Expand: 'Expand',
  PleaseSaveYourChangesFirst:
    'Please save your changes first before you can customize product card.',
  TheCustomerChoseThisOption: 'The customer chose this option',
  IncludedByDefaultCanNotBeExcluded: `Included by default. Can't be excluded.`,
  Include: 'Include',
  Exclude: 'Exclude',
  OpenMeasurement: 'Open Measurement',
  OneAvailableTrialCredit: '1 available trial credit',
  GetMoreCreditsToContinueWithGAF: 'Get more credits to continue having connection with GAF',
  AvailableCredits: '{number} available {number, plural, one {credit} other {credits}}',
  NoAvailableCredits: 'No available credits',
  None: 'None',
  Cancelled: 'Cancelled',
  VerificationHold: 'Verification Hold',
  InIntake: 'In Intake',
  IntakePartialActive: 'Intake Partial Active',
  IntakeDecline: 'Intake Decline',
  ReorderMesurements: 'Reorder Measurements',
  AccountExist: 'Account exist',
  PleaseChooseDeliveryTime: 'Please choose delivery time:',
  DeliveryTime: 'Delivery Time',
  RegularDelivery: 'Regular Delivery',
  ExpressDelivery: 'Express Delivery',
  ThreeHourDelivery: 'Three Hour Delivery',
  QuickDelivery: 'Quick Delivery',
  DefaultTurnaroundTimeOf3BusinessDays:
    'Default turnaround time of 3 business days. No additional fees.',
  DefaultTurnaroundTimeOf1BusinessDay:
    'Default turnaround time of 1 business day till closing. Additional fees apply.',
  DefaultTurnaroundTimeOf3BusinessHours:
    'Default turnaround time of 3 business hours. Additional fees apply.',
  DefaultTurnaroundTimeOf2BusinessHours:
    'Default turnaround time of 2 business hours. No additional fees. Only for use with the QuickSquares product.',
  EagleviewBidPerfectMeasurements: 'EagleView Bid Perfect Measurements',
  EagleviewPremiumMeasurements: 'EagleView Premium Measurements',
  SelectTheAmountOfCredits: 'Select the amount of credits and renewal option.',
  AmountOfCredits: 'Amount of Credits',
  AutoRenewalOption: 'Auto-renewal Option',
  AddMoreCreditsAtThreeOrLess: 'Add more credits at 3 or less',
  CustomRenewDate: 'Custom renew date',
  Weekly: 'Weekly',
  Monthly: 'Monthly',
  DayOfTheWeek: 'Day of the week',
  WeeklyAutoRenewal: 'Weekly auto-renewal',
  MonthlyAutoRenewal: 'Monthly auto-renewal',
  AutoRenewsAt3Credits: 'Auto-renews at 3 credits',
  AreYouSureYouWantToCancel: 'Are you sure you want to cancel credit renew option?',
  AfterCancellingYourAccountWillNoLonger:
    'After cancelling, your account will no longer automatically purchase credits.',
  YesCancel: 'Yes, Cancel',
  RenewOptionHasBeenCanceled: 'Renew option has been canceled successfully!',
  UnfortunatelyYourRenewOption: 'Unfortunately, your renew option has not been canceled.',
  GetFinalProposal: 'Get Final Proposal',
  WeAreWorkingOnYourFinalProposal:
    "We're working on your final proposal and will get in touch soon. {contactUs} for further assistance.",
  AccountAssociatedWithYourMarket:
    'Account associated with your market is currently unavailable. Please choose another account or contact your admin to reconnect your account.',
  Pending: 'Pending',
  NumberOfStories: 'Number of Stories',
  PredominantPitch: 'Predominant Pitch',
  StructureComplexity: 'Structure Complexity',
  RoofPitches: 'Roof Pitches',
  AreaSqFt: 'Area (sqft)',
  PercentOfRoof: '% of Roof',
  AreasPerPitch: 'Areas per Pitch',
  Structure: 'Structure',
  Complexity: 'Complexity',
  PleaseReorderTheReportOrContactOurSupport:
    'Please reorder the report or contact our support at <a>help@roofle.com</a> with questions.',
  PleaseReorderTheReportOrContactEagleviewSupport:
    'Please reorder the report or contact EagleView support at <a>(866) 659-8439</a>.',
  EagleViewAccountIsNotConnected:
    'EagleView account is not connected. {isAdmin, select, true {Please <a>reconnect</a> your account} other {Please contact your Administrator}}.',
  Branding: 'Branding',
  BeAwareTurningThisOnWillReplaceYourProductCallToAction:
    'Be aware turning this on will replace your product call-to-action button to “Get Final Proposal”. Clicking it will trigger placing a measurement order for that project automatically. Only one measurement will be ordered per project address.',
  YouHaveZeroMeasurementCreditsLeft:
    'You have 0 measurement credits left.\nPlease select another report type or get more credits to proceed.',
  ThisReportRequiresThreeCredits: 'This report requires 3 measurement credits',
  SuggestedAngle: '{key} (angle {angle}°) {isSuggested, select, true {- Suggested} other {}}',
  IAgreeToGAFQuickMeasureReportTerms: 'I agree to GAF QuickMeasure Roof Report {link}.',
  TermsAndConditions: 'Terms and Conditions',
  CopyScript: 'Copy Script',
  ProposalPending: 'Proposal pending',
  ClosePage: 'Close Page',
  PleaseProvideValidAccountName: 'Please provide a valid account name',
  PleaseContactYourCompanyAdminToGetMoreCredits:
    'Please contact your company admin to get more credits',
  On: 'On',
  Off: 'Off',
  PleaseUpdateYourBillingInformation:
    'Please update your billing information and we’ll try the payment again',
  CurrentTurnaroundTime: 'Current turnaround time is {time}.',
  sqft: 'sqft',
  AccountNameMustBeUniqueInTheCompany:
    'Account name must be unique in the company. Please change it',
  ToHaveAccessToTheBeaconPro:
    'To have access to the <a>Beacon Pro+</a> integration, you must have an active Pro+ account. Including:<ul><li>Having at least one existing Branch</li><li>Creating templates for each Branch</li></ul>Then Connect Account by signing in using your Master account credentials.',
  Credentials: 'Credentials',
  ConnectYourBeaconAccount:
    'Connect your Beacon Pro+ account to easily place material orders directly in Roof Quote PRO™.',
  LearnMoreAboutBeacon: 'Learn More About Beacon Pro+',
  LoginReq: 'Login *',
  SaveAndContinue: 'Save & continue',
  LoginAndOrPasswordIsIncorrect: 'Login and/or Password is incorrect. Please try again.',
  Orders: 'Orders',
  MarketList: 'Market List',
  SelectAMarketForAssociation: 'Select a market for association:',
  PleaseSelectABranchForMarket: 'Please select a branch for this market:',
  Branch: 'Branch',
  BranchReq: 'Branch *',
  TemplateName: 'Template Name',
  LastModified: 'Last Modified',
  PleaseSelectABranch: 'Please select a branch.',
  ToAddTemplates: 'To add templates, please create them in your <a>Beacon Pro+ account</a>',
  AreYouSureYouWantToDisconnect:
    'Are you sure you want to disconnect your Beacon Pro+ Account and lose access to your orders?',
  AllDraftOrdersWillBeDeleted:
    'All draft orders, Branch association with Markets and Formula association with Items will be deleted.',
  YesDisconnect: 'Yes, Disconnect',
  BeaconProPlusHasBeenDisconnected: 'Beacon Pro+ has been disconnected successfully!',
  UnfortunatelyBeaconProPlusHasNotBeenDisconnected:
    'Unfortunately, Beacon Pro+ has not been disconnected. Please, try again.',
  Branches: 'Branches',
  ToUnlockOrderingPleaseAssociateMarkets:
    'To unlock ordering for all markets, associate each market to a Beacon branch.',
  RemindYouThatYouHaveUnassociated: 'Heads up! Some branches are not associated with a market.',
  AccountIsDisconnectedCheckYourLoginAndPassword:
    'Account is disconnected. Check your login and password.',
  ProvideYourNewLoginAndPassword: 'Provide your new login and password.',
  YouHaveNotAssociatedAllMarkets: 'You have not associated all your markets to branches.',
  CongratsYouHaveSuccessfully: "Congrats! You've successfully connected your Beacon Pro+ account",
  ToAutoPopulateOrderItemQuantities:
    '{withConnecting, select, true {And to} other {To}} auto-populate order item quantities using measurements, please set up your Formulas.',
  SomeMarketsStillNeedToBeAssociated:
    'Some markets still need to be associated with a Beacon Pro+ branch to unlock ordering for those markets.',
  AssociateMarkets: 'Associate Markets',
  SetupFormulas: 'Setup Formulas',
  Later: 'Later',
  MyOrders: 'My Orders',
  BeaconProPlusIsDisconnected:
    'Beacon PRO+ is {isDisconnected, select, true {Disconnected} other {Not Connected}}',
  ReconnectYourAccountIntegration:
    '{isDisconnected, select, true {Reconnect} other {Connect}} your account integration for access to material orders.',
  ToReconnectYourAccount:
    'For access to material orders, contact your company admin to {isDisconnected, select, true {reconnect} other {connect}} your account integration.',
  OrderDate: 'Order Date',
  Draft: 'Draft',
  Processing: 'Processing',
  Invoiced: 'Invoiced',
  DeliveryReady: 'Delivery Ready',
  PickUpReady: 'Pick Up Ready',
  Delivered: 'Delivered',
  OrderNumber: 'Order Number',
  PO: 'PO',
  TheTotalPriceIsApproximate:
    'The total price is approximate and may change after completing the order.',
  TemplateDetails: 'Template Details',
  ProductList: 'Product List',
  ItemNumber: 'Item Number',
  Nickname: 'Nickname',
  Size: 'Size',
  Packaging: 'Packaging',
  Width: 'Width',
  Thickness: 'Thickness',
  Length: 'Length',
  MFG: 'MFG',
  CurrentlyOutOfStock: 'Currently out of stock.',
  AvailableToPickUpOrDelivery: 'Available to pick up or delivery.',
  OrderMaterials: 'Order Materials',
  EditMaterialOrder: 'Edit Material Order',
  CustomerInfo: 'Customer Info',
  MaterialTemplate: 'Material Template',
  DeliveryInfo: 'Delivery Info',
  ReviewOrder: 'Review Order',
  MeasurementInputs: 'Measurement Inputs',
  SaveAsDraft: 'Save as Draft',
  SeeMaterialReport: 'See Material Report',
  CreateOrder: 'Create Order',
  CustomerName: 'Customer Name',
  ProjectAddress: 'Project Address',
  MeasurementReport: 'Measurement Report',
  EnterThePurchasedOrder: 'Enter a PO to reference your order',
  InstantQuote: 'Instant Quote',
  AreYouSureYouWantToBackWithout: 'Are you sure you want to go back without saving changes?',
  SaveAsDraftAndClose: 'Save as draft and close',
  CloseWithoutSaving: 'Close without saving',
  PleaseSelectMeasurementReport: 'Please select measurement report',
  YouHaveMissingFields: 'You have missing fields',
  ProductionRep: 'Production Rep',
  ProductionRepsAccess:
    "Production Reps have access only to the Dashboard, Rep Quotes, Products, Orders and Personal Profile. If given access to CLP Financing, they'll be able to request payments and see transactions.",
  ProductionRepsAccessExcludingClp:
    'Production Reps have access only to the Dashboard, Rep Quotes, Products, Orders, and My Profile.',
  TotalRoofArea: 'Total Roof Area',
  TotalRoofAreaIsTheEntireRoofSquareFeet:
    'Total roof area is the entire roof square feet without a waste.',
  SqFt: 'SqFt',
  LF: 'LF',
  QTY: 'QTY',
  Underlayment: 'Underlayment',
  StepFlashing: 'Step Flashing',
  Flashing: 'Flashing',
  Facets: 'Facets',
  Parapets: 'Parapets',
  Penetrations: 'Penetrations',
  KitchenVent: 'Kitchen Vents',
  BathVent: 'Bath Vents',
  PlumbingStack: 'Plumbing Stacks',
  StaticVent: 'Static Vents',
  RidgeVent: 'Ridge Vent',
  IceWater: 'Ice/Water',
  RoofWallIntersection: 'Roof & Wall Intersection',
  DeckingSheathing: 'Decking/Sheathing',
  OSBDecking: 'OSB Decking',
  CDXPlywood: 'CDX Plywood',
  Formulas: 'Formulas',
  AddFormula: 'Add Formula',
  FormulaDetails: 'Formula Details',
  GeneralInfo: 'General Info',
  NameYourFormula: 'Name your formula and associate template items.',
  FormulaGuide: 'Formula Guide',
  FormulaNameReq: 'Formula Name *',
  AssociatedProducts: 'Associated Products',
  AssociateBeaconProductsToThisFormula:
    'Associate Beacon products from your template(s) to this formula.',
  FormulaReq: 'Formula *',
  BuildYourFormula:
    'Build your formula in the space below. To insert data inputs, select from the list or type a letter to see suggested inputs.',
  FormulaPreview: 'Formula Preview:',
  ProductQuantity: 'Product Quantity',
  ClickHereToStartBuildingFormula: 'Click here to start building your formula',
  AvailableInputs: 'Available Inputs',
  DataInputsList: 'Data Inputs List',
  TestFormula: 'Test Formula',
  WeProvideExample:
    'We provide example values to test your formula. Change these inputs to check if your formula works as intended.',
  StartCreatingYourFormula: 'Start creating your formula and inputs here will appear.',
  Formula: 'Formula',
  FormulaColon: 'Formula:',
  Result: 'Result:',
  InvalidOperator: 'Invalid operator. Available operators are +, -, *, /, ( , ).',
  FormulaCannotContainTwo: 'Formula cannot contain two Data Inputs or numbers in a row.',
  YouCannotEnterMoreThanOne:
    'You cannot enter more than one math operator in a row (e.g. plus, minus, divided, or multiplied by).',
  FormulaMustBegin: 'Formula must begin or end with a number, Data Input, or parentheses.',
  SpacesIsNotAllowed: 'Spaces are not allowed. Enter either an operator or Data Input.',
  AClosingParenthesisCannot: 'A closing parenthesis cannot be entered first.',
  InputsCannotContainNumbers: 'Data Inputs cannot contain numbers.',
  InputsCannotContainOperators: 'Data Inputs cannot contain operators.',
  AnOpeningParenthesis: 'An opening parenthesis is used improperly after Data Input or number.',
  NoCharactersOtherThanDigits: 'No characters other than digits can be entered after the dot.',
  PleaseCompleteOrCorrectYourFormula: 'Please complete or correct your formula.',
  NoReport: 'No Report',
  Item: 'Item',
  Template: 'Template',
  SelectedMeasurementUnit: 'Selected Measurement Unit',
  ItemsAssociatedToThis: 'Items associated to this formula have different measurement units.',
  EditFormula: 'Edit Formula',
  DuplicateFormula: 'Duplicate Formula',
  DeleteFormula: 'Delete Formula',
  AssociateFormula: 'Associate Formula',
  FormulaHasBeenDeleted: 'Formula has been deleted successfully!',
  UnfortunatelyFormulaHasNotBeenDeleted:
    'Unfortunately, the formula has not been deleted. Please, try again.',
  EstimateWithSum: 'Estimate: {sum}',
  HideExcludedProducts: 'Hide excluded products',
  ShowExcludedProducts: 'Show excluded products',
  ItemIsCurrentlyOOSButCanStillBeIncludedInYourOrder:
    'Item is currently out-of-stock but can still be included in your order.',
  JobAccount: 'Job Account',
  SelectJobAccount: 'Select Job Account',
  SelectTemplate: 'Select Template',
  PleaseEnterQuantityOrExcludeThisItemFromTheList:
    'Please enter a quantity or exclude this item from the list',
  OrderDraftHasBeenSavedSuccessfully: 'Order draft has been saved successfully!',
  UnfortunatelyTheOrderDraftHasNotBeenSaved:
    'Unfortunately, the order draft has not been saved. Please, try again.',
  HomeownerDetails: 'Homeowner Details',
  TotalSqFt: 'Total SqFt',
  WasteFactor: 'Waste Factor',
  AdditionalCost: 'Additional Cost',
  AdditionalCostAndOrDiscount: 'Additional Cost and/or Discount',
  Close: 'Close',
  SaveAndClose: 'Save and Close',
  SelectTheMarketFromTheList: 'Select the market from the list',
  AreYouSureYouWantToCloseWithoutSavingChanges:
    'Are you sure you want to close without saving changes?',
  Quotes: 'Quotes',
  InstantQuotes: 'Instant Quotes',
  DefaultSlopeSelection: 'Default Slope Selection',
  DefaultSlopeCaption:
    'Choose which slope will be selected by default. Users will still be able to change selection.',
  UseDefaultSlopeToAllMarkets: 'Use the same default slope for all markets',
  SteepSlopeCharge: 'Steep Slope Charge',
  SteepSlopeChargeCaption: 'Enter your pricing for steep roofs.',
  SteepSlopeChargeProductCaption: 'Include extra cost for steep roofs.',
  Fixed: 'Fixed',
  NotAppliedToCustomProducts:
    'By default, this will not be applied to custom products from My Catalog.',
  NotAppliedToCustomProductsWithFixedPrice:
    'By default, this will not be applied to custom products with fixed price from My Catalog.',
  NotAvailableToCustomProductsWithFixedPrice:
    'By default, this will not be applied to custom products from My Catalog and will not be available for custom products with fixed price.',
  AddWasteFactor: 'Add Waste Factor',
  AddWasteFactorCaption:
    'Turn this on to add waste to your instant quotes. Waste Factor will be included in price calculation<br></br>' +
    'e.g. 2000 sqft roof, medium slope, $600 per sq, WF = 10%<br></br>' +
    'Total Price = (2000/100)*1.1*600=$13,200',
  DefaultWasteFactorCaption:
    'Set your default waste percentage. This can be manually overwritten in Rep Quotes.',
  WasteFactorProductCaption: 'Include waste in instant quote calculation.',
  DefaultWasteFactor: 'Default waste factor',
  UseAiToDetermineWaste: 'Use AI To Determine Waste',
  UseAiToDetermineWasteCaption:
    'AI is used to estimate the facet count and the waste is determined based on facet count, roof size, and roof type. Your default waste will be applied when data is not available.',
  PriceRange: 'Price Range',
  PriceRangeCaption:
    'Show a price range instead of an exact price for instant quotes on your website.',
  SetRangePercentage: 'Set Range %',
  ShowPriceRange: 'Show Price Range',
  SetRangeType: 'Set range type:',
  TotalPricePlusRange: 'Total Price + Range %',
  TotalPricePlusRangeCaption:
    'The total price will be set as the minimum amount and the max will be your range % above total<br></br>' +
    'e.g. range % = 10%, total price is $10,000. Price Range - from $10,000 to $11,000',
  TotalPricePlusMinusRange: 'Total Price +/- Range %',
  TotalPriceMinusRangeCaption:
    'The total price will be set as the max amount and the minimum will be your range % below total<br></br>' +
    'e.g. range % = 10%, total price is $10,000. Price Range - from $9,000 to $10,000',
  TotalPriceMinusRange: 'Total Price - Range %',
  TotalPricePlusMinusRangeCaption:
    'Your range % will be set below total price for minimum and above total for max<br></br>' +
    'e.g. range % = 10%, total price is $10,000. Price Range - from $9,000 to $11,000',
  AllSettingAppliedToRepQuote: 'All settings have been applied to Rep Quote tool.',
  YourRoofDetails: 'Your Roof Details',
  ExpectedPriceRangeForThisProduct: 'Expected price range for this product',
  AdditionalCostsSettings: 'Additional Cost Settings',
  AdditionalCostsSettingsCaption:
    'Turn on items you want to include in Rep Quotes additional cost calculation.',
  MultiStoryCharge: 'Multi-Story Charge',
  MultiStoryChargeCaption: 'Enter your pricing for roofs that are multiple stories.',
  Question: 'Question',
  AnswerOptions: 'Answer Options',
  MultiStoryCostsQuestion: 'How many stories above ground is the roof?',
  NStories:
    '{itemCount, plural, =1 {1} =2 {2} other {3+}} {itemCount, plural, one {Story} other {Stories}}',
  Layers: 'Layers',
  LayersCaption: 'Enter your pricing for removal of multiple layers.',
  LayersCostsQuestion: 'How many layers will need to be removed?',
  NLayers:
    '{itemCount, plural, =1 {1} =2 {2} other {3+}} {itemCount, plural, one {Layer} other {Layers}}',
  Chimney: 'Chimney',
  ChimneyCaption: 'Enter your pricing for replacing chimney flashing.',
  ChimneyFlashing: 'Chimney Flashing',
  ChimneyFlashingQuestion: 'How many chimneys need new flashing?',
  NChimneys:
    '{itemCount, plural, =1 {1} =2 {2} other {3+}} {itemCount, plural, one {Chimney} other {Chimneys}}',
  SkylightFlashing: 'Skylight Flashing',
  SkylightFlashingCaption: 'Enter your pricing for replacing skylight flashing.',
  SkylightFlashingQuestion: 'How many skylights need new flashing?',
  NSkylights:
    '{itemCount, plural, =1 {1} =2 {2} other {3+}} {itemCount, plural, one {Skylight} other {Skylights}}',
  RoofCondition: 'Roof Condition',
  RoofConditionCaption: 'Enter your pricing if you want to charge extra for conditional items.',
  RoofConditionQuestion: 'Current roof condition. Please check all that apply.',
  AlgaeStains: 'Algae Stains',
  HailDamage: 'Hail Damage',
  WindDamage: 'Wind Damage',
  MissingShingles: 'Missing Shingles',
  RoofLeaks: 'Roof Leaks',
  Sagging: 'Sagging',
  StructuralDamage: 'Structural Damage',
  NewDecking: 'New Decking',
  NewDeckingCaption: 'Enter your pricing for decking replacement options.',
  NewDeckingQuestion: 'Does roof need new decking?',
  FullDeckingReplacement: 'Full Decking Replacement',
  Partial: 'Partial',
  EnterTheFixedPricePerSheet: 'Enter the fixed price per sheet.',
  UsersWillBeAbleToSpecifyTheQuantity: 'Users will be able to specify the quantity.',
  EnterPricePerSquare: 'Enter price per square.',
  DifficultAccess: 'Difficult Access',
  DifficultAccessCaption: 'Enter your pricing for projects that are difficult to access.',
  DifficultAccessQuestion: 'Is the project easily accessible?',
  OtherExtraCosts: 'Other Extra Costs',
  OtherExtraCostsCaption: 'Create custom additional cost options.',
  OtherExtraCostsWarning:
    'Some question details cannot be edited once saved. Try creating a new question and deleting old one.',
  ChangeSelectionTypeWarning:
    'To make those questions with a {toSingle, select, true {single} other {multiple}} selection without breaking the calculation logic please create a new one.',
  AddOtherExtraCosts: 'Add Other Extra Costs',
  PerLinealFeet: 'Per Lineal Feet',
  PerEach: 'Per Each',
  PerBundle: 'Per Bundle',
  PerPiece: 'Per Piece',
  PerRoll: 'Per Roll',
  PerCarton: 'Per Carton',
  PerTube: 'Per Tube',
  PerCan: 'Per Can',
  PerCase: 'Per Case',
  OptionName: 'Option Name',
  Custom: 'Custom',
  CustomUnitName: 'Custom Unit Name',
  ToTurnOffFillInRequiredFields:
    'To turn off please fill in all required fields or delete the question with its options',
  PleaseAnswerTheQuestionsAboutRoof: 'Please answer the questions about the {name}',
  PleaseAnswerTheQuestionsAboutRoofPreview:
    'Please answer the questions about the {Main Roof Name}',
  FormPreview: 'Form Preview',
  AllowMultipleOptions: 'Allow multiple options',
  SelectTheMeasurementOption:
    'Select the measurement option you want to use and change it if necessary/possible.',
  TheWasteFactorMayBeAdjustedAutomatically:
    'The waste factor may be adjusted automatically based on the selected measurement.',
  TheQuoteIsPoweredBy: 'This quote is powered by {type}',
  TheMapAndStructuresAreNotAvailable:
    'The map and structures are not available when using measurement data.',
  ThisImageIsForIllustrationOnly: 'This image is for illustration only.',
  Original: 'Original',
  ExtraLayerRemoval: 'Extra Layer Removal',
  HighRoof: 'High Roof',
  TheAdditionalCostIsAlreadyIncluded:
    'The Additional Cost is already included in the Product Details prices calculation.',
  OfInstantPrice: '{value}% of Instant Price',
  OfTotalCost: '$ {value} of Total Cost',
  Quote: 'Quote',
  DefaultWaste: 'Default Waste 10%',
  ToUseThisSettingTurnOnInQuoteSettings:
    'To use this setting, it must be turned on in Quote Settings.',
  ToUseThisSettingTurnOnInAdditionalCost:
    'To use this setting, it must be turned on in Additional Cost Settings.',
  WasteFactorIsNotAvailableForProductsWithAFixedPrice:
    'Waste Factor is not available for products with a fixed price.',
  UseInstantQuotesSettings: 'Use Instant Quotes Settings',
  UseInstantQuotesSettingsCaption:
    'Turn this on to use your company’s account settings for steep slope charge, waste factor, and price range.',
  IncludeAdditionalCost: 'Include Additional Cost',
  IncludeAdditionalCostCaption:
    "Include Additional Cost computed in Rep Quotes to the product's price.",
  RoofType: 'Roof Type',
  RoofTypeCaption: 'Enter your pricing if you want to charge extra for the roof type.',
  RoofTypeQuestion: 'Current roof type.',
  AsphaltShingleRemoval: 'Asphalt Shingle Removal',
  MetalRoofRemoval: 'Metal Roof Removal',
  SyntheticRoofRemoval: 'Synthetic Roof Removal',
  TileRoofRemoval: 'Tile Roof Removal',
  WoodShakeRemoval: 'Wood Shake Removal',
  PleaseEnterValidQuantity: 'Please enter a valid quantity',
  TheAdditionalCostIsAlreadyIncludedInTheRecommendedProducts:
    'The Additional Cost is already included in the Recommended Products prices calculation.',
  HideDetails: 'Hide Details',
  ShowDetails: 'Show Details',
  EnterNewWasteFactor: 'Enter a new waste factor',
  Suggested: 'Suggested',
  Default: 'Default',
  OriginalDefault: 'Original Default',
  CurrentDefault: 'Current Default',
  ThisQuestionHasBeenRemoved: 'This question has been removed.',
  ThisProductsPricingDoesNotIncludeAdditionalCosts:
    'This product’s pricing does not include additional costs',
  NoAdditionalCost: 'No Additional Cost',
  EditAdditionalCost: 'Edit Additional Cost',
  PleaseSelectTheDiscountTypeAndItsAmount: 'Please select the discount type and its amount',
  Percentage: 'Percentage',
  TotalAdditionalCost: 'Additional Cost Total',
  OffWhat: 'Off What?',
  PleaseEnterANumberWithNoMoreThan2DecimalPlaces:
    'Please enter max 7 numbers with no more than 2 decimal places',
  TheDiscountCanNotBeDuplicated:
    'The discount can’t be duplicated. Only the last discount listed will be applied.',
  NoDiscount: 'No Discount',
  ChangeWFToTheCustomNewDefaultAndSuggested:
    'Users can change WF to the Custom WF, New Default WF and Suggested WF (if the toggle is turn on).',
  UsersCanChangeWFOnlyToTheCustomWF: 'Users can change WF only to the Custom WF.',
  UsersCanEnterCustomWFOrDefaultWF: 'But Users can enter Custom WF or Default WF.',
  NotSet: 'Not Set',
  TheTotalPriceIncludesAllCharges:
    'The Total Price includes all charges, additional costs, discounts, and any adjustments to quote',
  ThePriceRangeIncludesAllCharges:
    'The Price Range includes all charges, additional costs, discounts, and any adjustments to quote',
  TheInstantQuoteIncludessWasteAndSteepSlope:
    'The Instant Quote price includes waste and steep slope charge (if applicable) but does not include additional costs, discounts, or adjustments to quote',
  TotalAdditionalCostDiscountValidation:
    'Discounts can’t be applied to individual items and Additional Cost Total at the same time. Only the Additional Cost Total discount will be applied.',
  TotalDiscountValidation:
    'Discounts can’t be applied to individual items and Total at the same time. Only the Total discount will be applied.',
  TotalDiscountAndTotalAdditionalCostDiscountValidation:
    'Discounts can’t be applied to Total and Additional Cost Total at the same time. Only the Total discount will be applied.',
  TheAdditionalCostDiscountIsExcluded:
    'The additional cost discount is excluded from the Recommended Products prices calculation.',
  StructureNameMustBeUnique: 'Structure Name must be unique. Please change it.',
  NewPriceIs: 'New price is ${price}',
  HasBeenRemoved: 'has been removed',
  UpdateAll: 'Update All',
  PricingHasBeenChanged: 'Pricing has been changed.',
  ML: 'ML',
  BeaconWasConnectedSuccessfully: 'Beacon Pro + was connected successfully!',
  ShippingDetails: 'Shipping Details',
  ShippingMethod: 'Shipping Method',
  ShipToAddress: 'Ship to Address',
  PickUpInStore: 'Pick Up in Store',
  ShippingTime: 'Shipping Time',
  Anytime: 'Anytime',
  Afternoon: 'Afternoon',
  Morning: 'Morning',
  SpecialRequest: 'Special Request',
  ShippingAddress: 'Shipping Address',
  OrderNotes: 'Order Notes',
  ProvideAdditionalInstructions:
    'Provide additional instructions or notes, e.g. ground or roof drop, specify a certain job account, etc.',
  OrderNDetails: 'Order #{number} Details',
  OrderN: 'Order #{number}',
  NeedToMakeChangesToYourOrder:
    'Need to make changes to your order? Contact your branch representative.\nBranch name: {branchName}\nPhone number: <a>{phoneNumber}</a>',
  ProcessedBy: 'Processed By',
  DeliveryTracking: 'Delivery Tracking',
  ShippingDate: 'Shipping Date',
  DeliveryStatus: 'Delivery Status',
  OrderList: 'Order List',
  BelowAreDetails: 'Below are details about the item(s)',
  Quantity: 'Quantity',
  SubTotal: 'Sub Total',
  OtherCharges: 'Other Charges',
  Tax: 'Tax',
  WhatIsOtherCharges:
    'What are Other Charges? Other charges are typically delivery fees, but may include handling and fuel fees.',
  MaterialOrdersAreNotAvailableForThisProject:
    ' Material Orders are not available for this project',
  ToAccessTheBeaconProIntegration:
    'To access the Beacon Pro+ integration, your company must have an active Pro+ account with at least one account branch and template(s). Then connect your active Pro+ account using your Pro+ account credentials.',
  ForAccessToMaterialOrdersReconnect:
    'For access to material orders, contact your company admin to reconnect your account integration.',
  ForAccessToMaterialOrdersAssociate:
    'For access to material orders, contact your company admin to associate market(s) with a Beacon Pro+ account branch.',
  ReconnectYourAccountIntegrationForAccessToMaterialOrders:
    'Reconnect your account integration for access to material orders.',
  ToGetDataPleaseClickTheOrderMaterials:
    'To get data please click the Order Materials button above',
  OrderCouldNotBeDisplayed: "Order Couldn't Be Displayed",
  OrderWasPlacedForThisLead: "Order was placed for this lead, but we can't display it.",
  AssociateYourMarketWithABeacon:
    'Associate your market(s) with a Beacon Pro+ branch for access to material orders.',
  Associate: 'Associate',
  ThisProjectIsOldPleaseSelectAMarket:
    'This project is old. Please select a market to proceed with ordering materials from Beacon Pro +.',
  MarketIsNoLongerAvailableOrHasNoAssociation:
    '{marketName} or branch is no longer available or market has no association. Please select another branch for this lead.',
  IfYouWantToUpdateTheCurrentMarket:
    'If you want to update the current market, please select from the list below:',
  SelectBranch: 'Select Branch',
  WasteFactorPercent: 'Waste Factor, %',
  ProductIsAlreadyAssociated:
    '{items} {length, select, 1 {is} other {are}} already associated with formula {formulaName}.',
  ProductCanNotBeAssigned: "{items} can't be associated multiple times.",
  NoBranch: 'No Branch',
  PriceWillBeCalculatedAtInvoicing: 'Price will be calculated at invoicing',
  DeliveryDetails: 'Delivery Details',
  Unit: 'Unit',
  ItemTotal: 'Item Total',
  POcantBeLonger: "PO can't be longer than 22 characters",
  PleaseFillInAllRequired: 'Please fill in all required fields',
  YourPOIsAnyNameOrNumber:
    'Your PO (purchase order) is any name and/or number you’ll use to reference your order (max 22 characters)',
  YourOrderHasBeenPlaced: 'Your order has been placed successfully!',
  EditOrder: 'Edit Order',
  DeleteDraft: 'Delete Draft',
  OpenOrderDetails: 'Open Order Details',
  OpenLeadDetails: 'Open Lead Details',
  AreYouSureDeleteOrderDraft: 'Are you sure you want to delete this material order draft?',
  BeAwareTheOrderWillBeDeleted: 'Be aware the order will be deleted permanently.',
  ThereAreNoProductsYet:
    'There are no products yet. Please go to the Edit Order step and finish ordering the materials.',
  YourOrderHasNotBeenPlaced:
    'Your order has not been placed. For now we saved it as a draft. Please, reconnect your account integration and try again.',
  CostForecastDoesntIncludeItems: "Cost forecast doesn't include items priced at invoicing.",
  PleaseSpecifyInInstructions: 'Please specify in instructions',
  PleaseEnterANumberWithNoMore: 'Please enter max 7 numbers with no more than 3 decimal places',
  PleaseEnterAWholeNumber: 'Please enter a whole number, 7 digits max',
  PleaseEnterAWholeNumberFromTo: 'Please enter a whole number from {from, number} to {to, number}',
  TaxAndShippingAreEstimated:
    'Tax and shipping are estimated. Final prices are calculated during order processing at the branch.',
  SelectTime: 'Select Time',
  SomeWebPlatformsDoNotSupportRepLinks:
    'Some web platforms do not support the Rep Public Link feature, including: GoDaddy Website Builder, Google Sites, and the Wix full page embedded widget.',
  SomeWebPlatformsDoNotSupportSalesRabbit:
    'Some web platforms do not support the SalesRabbit integration, including: GoDaddy Website Builder, Google Sites, and the Wix full page embedded widget.',
  Chimneys: 'Chimneys',
  Skylights: 'Skylights',
  HeadwallFlashings: 'Headwall Flashings',
  StepFlashings: 'Step Flashings',
  UpdateTo: 'Update to',
  Current: 'Current',
  PricingHasChangedToAmmendAdditionaCost:
    'Pricing has changed. To amend additional costs please update the prices. ',
  EditingDiscountIsNotAvailable:
    'Editing discount is not available until additional cost is updated.',
  SqFtOf: 'SqFt of {name}',
  AdditionalCostIsUnavailable: 'Additional Cost is Unavailable',
  SetUpAdditionalCostQuestions: 'Set up additional cost questions and pricing to use this feature.',
  SetUp: 'Set Up',
  ThisFeatureHasNotBeenSetUpByYourCompanyAdmin:
    'This feature hasn’t been set up by your company admin.',
  AdditionalCostHasBeenTurnedOffForTheAccount:
    'Additional cost has been turned off for the account.',
  AdditionalCostHasBeenTurnedOffForTheAccountUpdateAll:
    'Additional cost has been turned off for the account. Click Update All to set additional cost to zero.',
  EnterCustomButtonUrl: 'Enter Custom Button URL Here',
  EnterCustomButtonText: 'Enter Custom Button Text Here',
  PleaseEnterAValidUrl: 'Please enter a valid URL',
  PleaseEnterAValidCustomButtonText: 'Please enter a valid custom button text',
  ItIsNotAvailableForCanceledSubscription: `It's not available for canceled subscription.`,
  Beta: 'Beta',
  ThisFeatureIsCurrentlyInBeta:
    'This feature is currently in Beta - accuracy of suggested waste may vary.',
  WasteFactorIsTurnedOffInCompanySettings: 'Waste factor is turned off in the company settings.',
  ToChangeWasteFactorItMustBeTurnedOnFromCompanySettings: `To change waste factor, it must be turned on from your company’s settings.`,
  ProductsThatPreviouslyHadCustomSettingsWillBeRestored:
    'Products that previously had custom settings for {section} will be automatically restored. Review your selected products’ customizations to verify or edit these settings.',
  ToSeeTheShippingAddressContact:
    'To see the shipping address, contact your company admin to reconnect your account integration.',
  ReconnectYourAccountIntegrationToSeeShippingAddress:
    'Reconnect your account integration to see the shipping address.',
  PleaseIncludeThisItemToTheList:
    'Please include this item to the list and then change the {fieldName}',
  ReportIsNotAvailableForThisProject: '{reportName} is not available for this project.',
  AddNewProducts: 'Add New Products',
  SelectProductsYouWantToAdd: 'Select product(-s) you want to add',
  SeeDetails: 'See Details',
  SKU: 'SKU',
  Select: 'Select',
  ThisProductAlredyExists: 'This product already exists. Please remove it or change variation.',
  RemoveFromOrder: 'Remove from order',
  SignInWith: 'Sign in with {provider}',
  ThisTemplateHasNoProductsInIt:
    'This template has no products in it.\nPlease select another one to create an order.',
  TemplateIsEmpty: 'Template Is Empty',
  ClpButton: 'CLP Button',
  AddThisButtonToYourWebsite:
    'Add this button to your website to offer financing through Contractor Loan Pro.',
  ButtonBranding: 'Button Branding',
  PopUpDesign: 'Pop-up Design',
  LoanProductsSelection: 'Loan Products Selection',
  YouCanChooseWhichLoan:
    'You can choose which package of loan products will be offered to customers.',
  SelectDefaultLoanPackage: 'Select default loan package:',
  ButtonType: 'Button Type',
  BlockButton: 'Block Button',
  BlockElements: 'Block elements always start from a new line.',
  InlineButton: 'Inline Button',
  InlineElements: 'Inline elements never start from a new line.',
  ButtonDisplay: 'Button Display',
  ButtonText: 'Button Text',
  Text: 'Text',
  SeePreQualifiedLoanOptions:
    'See pre-qualified loan options in seconds with no impact to your credit.',
  BackgroundUnderPopUp: 'Background Under Pop-up',
  Title: 'Title',
  EditText: 'Edit Text',
  EditTitleText: 'Edit Title Text',
  EditDescriptionText: 'Edit Description Text',
  EditErrorText: 'Edit Error Text',
  EditMainButtonText: 'Edit Main Button Text',
  RadioButton: 'Radio Button',
  AllChangesHaveBeenAppliedToClpButton: 'All settings have been applied to CLP Button.',
  ContractorLoanProButtonScript: 'ContractorLoan PRO Button Script',
  ThisButtonWillAllowHomeowners:
    'This button will allow homeowners to get pre-qualified without requiring them to get an instant quote. Configure the branding and display settings from the Settings page -> CLP Button tab.',
  EmbedThisScriptToDisplayClpButton:
    'Embed this script on your website inside the <body> element on any page to display a button powered by ContractorLoanPro.',
  YouHaveSentLoanApplication:
    'You have sent a loan application to the {isPhoneInvitation, select, true {phone number} other {email address}} {destination}.',
  UnfortunatelyTheLoanOption:
    'Unfortunately, the loan option has not been sent. Please, try again.',
  LeaveWithoutSaving: 'Leave without saving',
  YouAreAboutToLeaveThisPageWithoutSaving: 'You are about to leave this page without saving.',
  YouAreAboutToLeaveThisPageWithoutSavingDoYouWantToSave:
    'You are about to leave this page without saving. Do you want to save changes before leaving?',
  ManageYourPersonalProfile: 'Manage your personal profile and account information',
  ManageYourCompanyAccount: "Manage your company's account details and users",
  ManageSubscriptionPaymentInfo: 'Manage subscription, payment info, and measurement credits',
  General: 'General',
  OptimizeAllOfYourWidget: 'Optimize all of your widget and quote settings',
  ControlYourMarketServiceArea: 'Control your market(s) service area and add markets',
  SelectAndConfigureYourProduct: 'Select and configure your product/service offerings',
  InstallYourInstantQuoteTool: 'Install your instant quote tool and connect integrations',
  SettingsAreOnlyAvailable: 'Settings are only available when you sign in as a contractor.',
  Account: 'Account',
  CompanyHasOnlyOneMarket: 'Company has only one market: {marketName}.',
  GroupURLs: 'Group URLs',
  CombineMetricsForIdenticalURLs: 'Combine metrics for identical URLs with different parameters.',
  MapPlatform: 'Map Platform',
  Mapbox: 'Mapbox',
  GoogleMaps: 'Google Maps',
  GoogleMapsCustomersRequireAdditionalRecurly:
    'Google Maps customers require additional billing in Recurly.',
  PreviewThisCustomButton: 'Preview this custom button from Branding to make sure the text fits.',
  PleaseEnterAValueFromToWithNoMoreThanTwoDecimalPlaces:
    'Please enter a value from {from, number} to {to, number} with no more than 2 decimal places.',
  AddNItems: 'Add {amount} item(-s)',
  ThisProductCannotBeAdded: 'This product cannot be added. It already exists',
  QuoteInfo: 'Quote Info',
  UnfortunatelyChangesHaveNotBeenSavedPleaseTryAgain:
    'Unfortunately, changes have not been saved. Please try again.',
  Phones: 'Phone(s)',
  EmailOrEmails: 'Email(s)',
  ContactName: 'Contact Name',
  MapType: 'Map Type',
  JobNimbus: 'JobNimbus',
  ConnectYourJobNimbusAccount:
    'Connect your JobNimbus account to easily create contacts directly in JobNimbus.',
  APIKeyReq: 'API Key *',
  APIKeyIsIncorrect: 'API Key is incorrect. Please try again.',
  ContactDetails: 'Contact Details',
  JobNimbusWasConnectedSuccessfully: 'JobNimbus has been connected successfully!',
  UnfortunatelyJobNimbusWasNotConnected:
    'Unfortunately, JobNimbus has not been connected. Please, try again.',
  AreYouSureDisconnectJobNimbus: 'Are you sure you want to disconnect your JobNimbus Account?',
  JobNimbusWasDisconnectedSuccessfully: 'JobNimbus has been disconnected successfully!',
  UnfortunatelyJobNimbusWasNotDisconnected:
    'Unfortunately, JobNimbus has not been disconnected. Please, try again.',
  LearnMoreAboutJobNimbus: 'Learn More About JobNimbus',
  TheDefaultContactWorkflow:
    'The default contact workflow and status, job workflow and status are currently selected. You can customize these settings below.',
  JobNimbusContactWorkflowName: 'Contact Workflow Name',
  JobNimbusContactWorkflowStatusName: 'Contact Workflow Status Name',
  JobNimbusLeadSourceName: 'Lead Source Name',
  JobNimbusJobWorkflowName: 'Job Workflow Name',
  JobNimbusJobWorkflowStatusName: 'Job Workflow Status Name',
  ConnectedEmails: 'Connected Emails',
  Notifications: 'Notifications',
  LeadEmails: 'Lead Emails',
  FinanceEmails: 'Finance Emails',
  NewInstantQuoteLead: 'New Instant Quote Lead',
  GetNotifiedEveryTimeUserHasCompleted:
    'Get notified every time a user has completed an Instant Quote from your company website.',
  AttachQuotePdf: 'Attach Quote PDF',
  IncludeAnAttachedPdf: 'Include an attached PDF of your quote(s) in emails sent to homeowners.',
  ShowQuoteSqFt: 'Show Quote SqFt',
  DisplayTheTotalSqFt: 'Display the Total SqFt used for pricing in emails sent to homeowners.',
  PreQualificationRequests: 'Pre-Qualification Requests',
  GetNotifiedWhenHomeownerClicks:
    "Get notified when the homeowner clicks on 'Get Pre-Qualified' and the invitation is sent successfully.",
  ApplicationCompleted: 'Application Completed',
  GetNotifiedWhenHomeownerCompletes:
    'Get notified when the homeowner completes a loan application with the outcome of their application.',
  Transactions: 'Transactions',
  GetNotifiedWhenHomeownerResponds:
    'Get notified when the homeowner responds to the SMS transaction request to approve the charge and initiate money movement.',
  SendLinkToCreateAccount: 'Send Link To Create Account',
  // OC translations (start)
  Plan: 'Plan',
  TypeReq: 'Type *',
  OCRoofQuotePRO: 'OC RoofQuote PRO™',
  OCRoofQuotePROUSA: 'OC RoofQuote PRO™ [USA]',
  OCRoofQuotePROCanada: 'OC RoofQuote PRO™ [Canada]',
  MonthlySubscription: 'Monthly Subscription',
  AnnualRollup: 'Annual Rollup',
  AnnualDiscount: 'Annual Discount',
  SubscriptionType: 'Subscription Type',
  SubscriptionPlan: 'Subscription Plan',
  SubscriptionRequest: 'Subscription Request',
  ResetPauseRequest: 'Reset Pause Request',
  ResetActivationRequest: 'Reset Activation Request',
  AreYouSureYouWantToResetRequest: 'Are you sure you want to reset {type} request?',
  RequestHasBeenResetedSuccessfully: 'Request has been reseted successfully!',
  UnfortunatelyTheRequestHasNotBeenReseted:
    'Unfortunately, the request has not been reseted. Please, try again.',
  RequestWithType: '{type} request',
  Activation: 'Activation',
  InstallationPackage: 'Installation Package',
  PoweredBy: 'Powered by',
  Specifications: 'Specifications',
  TechnicalCharacteristics: 'Technical Characteristics',
  TechnicalDocuments: 'Technical Documents',
  WindResistance: 'Wind Resistance',
  AlgaeResistance: 'Algae Resistance<sup>\u00A7</sup>',
  NominalSize: 'Nominal Size',
  Exposure: 'Exposure',
  ShinglesPerSquare: 'Shingles Per Square',
  BundlesPerSq: 'Bundles Per Square',
  CoveragePerSq: 'Coverage Per Square',
  ApplicableStandards: 'Applicable Standards',
  TotalProtection: 'Total Protection',
  TotalProtectionRoofingSystem: 'Total Protection Roofing System',
  DesignInspiration: 'Design Inspiration',
  MapSettings: 'Map Settings',
  Centerpoint: 'Centerpoint',
  CustomImage: 'Custom Image',
  UseTheLatLong: `Use the user's Lat/Long`,
  MapPlaceholderMediaUploadHint: `Upload an image in PNG, JPG or JPEG format, recommended size less than 500KB with dimensions 1280x720 px (16:9). It’s important to preview your image before saving.`,
  MapPlaceholderMediaUploadError:
    'Only PNG, JPG or JPEG files with a max size of 500 KB can be uploaded. The size of the image should be 1280x720 px (16:9).',
  UploadImage: 'Upload Image',
  Control: 'Control',
  ShowSquareFootageInfoTooltip:
    'By default, square footage will be displayed in emails to homeowners.',
  UseOwensCorningBranding: 'Use Owens Corning Branding',
  UseOwensCorningBrandingHint: 'Design your widget with Owens Corning colors and The Pink Panther™',
  YourFirstProductShouldBeOwensCorning: 'Your first product should be Owens Corning.',
  WarrantyDescription: 'Warranty Description',
  WarrantyCoverageType: 'Warranty Coverage Type',
  SelectWarrantyCoverageTypeForTheProduct:
    'Select a warranty coverage type for the product. <a>See Warranties Details</a>',
  NoWarranty: 'No Warranty',
  PlatinumProtectionLimitedWarranty: 'Platinum Protection Limited Warranty',
  PreferredProtectionLimitedWarranty: 'Preferred Protection Limited Warranty',
  SystemProtectionLimitedWarranty: 'System Protection Limited Warranty',
  StandardProductLimitedWarranty: 'Standard Product Limited Warranty',
  StandardProductLimitedWarrantyDescription:
    'All Owens Corning<sup>®</sup> Shingle products come with a standard product limited warranty providing coverage against manufacturing defects. Each warranty starts with a TRU PROtection<sup>®</sup> Period during the initial years where coverage includes labor cost to repair/replace defective materials. After the TRU PROtection<sup>®</sup> Period is over, the coverage is for materials only and adjusted based on the age of your roof.',
  SystemProtectionLimitedWarrantyDescription:
    'Provides full replacement coverage for manufacturing defects on your Owens Corning<sup>®</sup> shingles and all Owens Corning<sup>®</sup> roofing components for 50 years.',
  PreferredProtectionLimitedWarrantyDescription:
    'Provides full replacement coverage for manufacturing defects on your Owens Corning<sup>®</sup> shingles and all Owens Corning<sup>®</sup> roofing components for 50 years. This warranty includes a 10-year limited warranty on contractor workmanship.',
  PlatinumProtectionLimitedWarrantyDescription:
    'Provides full replacement coverage for manufacturing defects on your Owens Corning<sup>®</sup> shingles and all Owens Corning<sup>®</sup> roofing components for 50 years. This warranty includes a limited lifetime warranty on contractor workmanship.',
  ProductsDefectsCoverage: 'Products Defects Coverage',
  WorkmanshipCoverage: 'Workmanship Coverage',
  KeyRequirements: 'Key Requirements',
  Lifetime: 'Lifetime',
  OverallWarrantyLength: 'Overall Warranty Length',
  OverallWarrantyLengthDescription:
    'As a single family homeowner, lifetime coverage means that your warranty is in effect as long as you own your home.',
  ProductsIncluded: 'Products Included',
  ShinglesAndAllOwensCorningComponents: 'Shingles & all Owens Corning<sup>®</sup> components',
  TRUPROtectionPeriod: 'TRU PROtection<sup>®</sup> Period',
  TRUPROtectionPeriodDescription:
    'Each warranty starts with a TRU PROtection<sup>®</sup> Period during the initial years where coverage includes labor cost to repair/replace defective materials.',
  FirstNYears: 'First {amount} Years',
  AfterNYears: 'After {amount} Years',
  MaterialsOnlyCoverage: 'Materials Only Coverage',
  MaterialsOnlyCoverageDescription:
    'After the TRU PROtection<sup>®</sup> Period is over, warranty compensation is for materials only. The compensation is reduced based on the number of years you’ve enjoyed your roof from installation date through the date of your claim.',
  BlowOffCoverage: 'Blow Off Coverage',
  BlowOffCoverageDescription:
    'After your shingles have achieved thermal sealing they will be covered if they experience blow-offs or wind damage in winds (including gusts) for the wind speed listed for the period from the original installation date.',
  StreakGuardAlgaeResistance: 'StreakGuard<sup>®</sup> Algae Resistance',
  StreakGuardAlgaeResistanceDescription:
    'Warranty length following the date of installation against brown-black staining caused by growth of cyanobacteria Gloeocapsa magma algae.',
  CoveragePeriod: 'Coverage Period',
  CoveragePeriodDescription:
    'Contractor workmanship coverage includes any defective installation of the Owens Corning<sup>®</sup> Roofing System. Contractor workmanship is available only through certified Owens Corning Roofing Platinum or Preferred Contractors.',
  LifetimeWithCoverageReducedAfter25Years:
    'Lifetime with coverage reduced after 25 years based on the age of the roof.',
  ComponentProducts: 'Component Products',
  ComponentProductsDescription:
    'The minimum number of Owens Corning<sup>®</sup> component product categories that must be installed to qualify for warranty coverage. The categories of component products include underlayments, intake and exhaust ventilation, hip & ridge shingles, ice & water barriers, and starter shingles.',
  MinimumOf3OwensCorningComponentsRequiredForTheAdditionalCoveragePeriod:
    'Minimum of 3 Owens Corning<sup>®</sup> components required, for the additional coverage period to apply.',
  MinOf3OwensCorningComponentsRequiredHipAndRidgeAndOthers:
    'Min of 3 Owens Corning<sup>®</sup> components required - 1 Hip and Ridge and 2 of any others',
  MinOf4OwensCorningComponentsRequiredHipRidgeUnderlaymentAndOthers:
    'Min of 4 Owens Corning<sup>®</sup> components required - 1 Hip and Ridge, 1 Underlayment and 2 of any others',
  ContractorCertificationRequired: 'Contractor Certification Required',
  ContractorCertificationRequiredDescription:
    'Your Owens Corning<sup>®</sup> Roofing System must be installed by a certified Owens Corning Roofing Platinum or Preferred Contractor and your contractor must register the warranty within 60 days of the installation.',
  MustBeInstalledByAnOwensCorningRoofingPlatinumOrPreferredContractor:
    'Must be installed by an Owens Corning Roofing Platinum or Preferred Contractor',
  SeeActualWarrantyForCompleteDetailsLimitationsAndRequirements:
    'See actual warranty for complete details, limitations and requirements.',
  ExcludeTearOffAndDisposalCostsIfRequired: 'Excludes tear-off and disposal costs if required.',
  IncludeTearOffAndDisposalCostsIfRequired: 'Includes tear-off and disposal costs if required.',
  AfterThermalSealings: 'After thermal sealings.',
  TwentyFiveYearAlgaeResistanceCoverageRequires:
    '25-year Algae Resistance coverage requires the use of Owens Corning<sup>®</sup> Berkshire<sup>®</sup>, DecoRidge<sup>®</sup>, DuraRidge<sup>®</sup>, ImpactRidge<sup>®</sup>, ProEdge<sup>®</sup>, or RIZERidge<sup>®</sup>, Hip & Ridge Shingles. If a qualifying Owens Corning<sup>®</sup> manufactured ridge product is not used, the Algae Resistance warranty period is reduced from 25 to 10 years, and the non-prorated period will be 1 year. Supreme<sup>®</sup> shingles feature a 10-year Algae Resistance Limited Warranty. Algae Resistance is available on a regional basis.',
  ShingleCoverageAppliesWithoutOwensCorningComponents:
    'Shingle coverage applies without Owens Corning<sup>®</sup> components. Minimum 3 required for components to take on coverage period of the shingle, otherwise standard individual component warranty terms apply.',
  StandartCoverage: 'Standart Coverage',
  ExtendedCoverage: 'Extended Coverage',
  UpgradeYourSubscriptionToUseCustomBranding:
    'Upgrade your subscription to use Custom Branding for your widget.',
  TitleColor: 'Title Color',
  TitleFont: 'Title Font',
  TextColor: 'Text Color',
  TextFont: 'Text Font',
  Main: 'Main',
  OwensCorningLegalText:
    '{companyName} is an independent contractor [supplier] and is not an affiliate of Owens Corning [Roofing and Asphalt,] [Insulating Systems,] LLC or its affiliated companies. THE PINK PANTHER™ & © 1964-{year} Metro-Goldwyn-Mayer Studios Inc. All Rights Reserved.',
  // OC translations (end)
  MobileHorizontalRotationNotSupported: 'Mobile Horizontal Rotation not Supported',
  PleaseRotateYouDevice: 'Please rotate your device to vertical position.',
  MarketSettings: 'Market Settings',
  ProductSettings: 'Product Settings',
  PleaseCheckTheEmail: 'Please check the email on the Service screen',
  YouShouldSelectTheSendAnEmail:
    "You should select the 'Send email on your behalf' checkbox to connect email.",
  ToSaveChangesPleaseFillInMissingFields: 'To save changes please fill in missing fields.',
  Leap: 'Leap',
  ConnectYourLeapAccount: 'Connect your Leap account to easily create contacts directly in Leap.',
  LeapWasConnectedSuccessfully: 'Leap has been connected successfully!',
  UnfortunatelyLeapWasNotConnected:
    'Unfortunately, Leap has not been connected. Please, try again.',
  AreYouSureDisconnectLeap: 'Are you sure you want to disconnect your Leap Account?',
  LeapWasDisconnectedSuccessfully: 'Leap has been disconnected successfully!',
  UnfortunatelyLeapWasNotDisconnected:
    'Unfortunately, Leap has not been disconnected. Please, try again.',
  LearnMoreAboutLeap: 'Learn More About Leap',
  LeapWorkflowStage: 'Initial Workflow Stage',
  TheDefaultWorkflowStage:
    'The default workflow stage is selected. You can customize these settings below.',
  ActivateSubscriptionToGetMoreInfo: 'Activate the subscription to get more info',
  USA: 'USA',
  Canada: 'Canada',
  YouHaveMissingFieldsOrErrors: 'You have a missing field(s) or error(s)',
  CustomizeOtherContactsForm:
    'Customize Other Contacts Form and the information that lead will provide.',
  CustomizeRQPLeadsForm: 'Customize RQP Leads Form and the information that lead will provide.',
  UserConsent: 'User Consent:',
  ContactOptIn: 'Contact Opt-In',
  SMSOptIn: 'SMS Opt-In',
  DisplayTypes: 'Display, {type, select, required {required} other {optional}}',
  UsersCantProceedUntilOptInIsChecked:
    'Users can’t proceed until opt-in is checked. This may impact conversion rates.',
  AboveButton: 'Above button',
  BelowButton: 'Below button',
  PleaseEnterAValueFromTo: 'Please enter a value from {from, number} to {to, number}.',
  OptInToBeContacted: 'Opt-in to be contacted',
  OptInToSMSMessages: 'Opt-in to SMS messages',
  ServiceFinance: 'Service Finance',
  GreenSky: 'GreenSky',
  Synchrony: 'Synchrony',
  PowerPay: 'PowerPay',
  Financeit: 'Financeit',
  AddNewFinancingType: 'Add New Financing Type',
  NewFinancingType: 'New Financing Type',
  EditFinancingType: 'Edit Financing Type',
  PleaseFillTheNameOfNewFinancingTypeAndAddURL:
    'Please fill the name of new Financing Type and add URL to it:',
  PleaseProvideTheURL: 'Please provide the URL:',
  PleaseEditTheInformationBelow: 'Please edit the information below:',
  FinancingTypeName: 'Financing Type Name *',
  PleaseEnterAValidFinancingTypeName: 'Please enter a valid financing type name',
  UploadLogo: 'Upload Logo',
  UploadFinancingTypeLogoHint: `It's possible to upload the image of PNG, JPG or JPEG format with the max size of 15MB`,
  NewFinancingTypeHasBeenAddedSuccessfully: 'New financing type has been added successfully!',
  UnfortunatelyTheNewFinancingTypeHasNotBeenCreated:
    'Unfortunately, the new financing type has not been created. Please, try again.',
  TheFinancingTypeHasBeenChangedSuccessfully: 'The financing type has been changed successfully!',
  UnfortunatelyTheFinancingTypeHasNotBeenChanged:
    'Unfortunately, the financing type has not been changed. Please, try again.',
  Logo: 'Logo',
  URLMustBeAdded: 'URL must be added',
  FinancingTypeCanNotBeRemoved: `Financing type can't be removed because it's applied to another market.`,
  AreYouSureYouWantToDeleteFinancingType: 'Are you sure you want to delete financing type?',
  ThisFinancingTypeHasBeenDeletedSuccessfully: 'This financing type has been deleted successfully!',
  ThisFinancingTypeHasNotBeenDeleted:
    'Unfortunately, this financing type has not been deleted. Please, try again.',
  ThisFinancingTypeNameAlreadyExists:
    'This financing type name already exists. Please enter a new name.',
  ThisURLAlreadyExists: 'This URL already exists. Please enter a new one.',
  NoLabel: 'No Label',
  LabelCantBeRemoved: "Label can't be removed because it's used in another product.",
  AreYouSureYouWantToDeleteThisLabel: 'Are you sure you want to delete this label?',
  ApplyNow: 'Apply Now',
  FinancingThrough: 'Financing through {name}',
  EditCustomFinancingButton: 'Edit Custom Financing Button',
  ThisLabelHasBeenDeletedSuccessfully: 'This label has been deleted successfully!',
  UnfortunatelyThisLabelHasNotBeenDeleted:
    'Unfortunately, this label has not been deleted. Please, try again.',
  EmailRecommendedProducts: 'Email Recommended Products',
  EmailRecommendedProductsHint:
    'Send homeowners an email with Recommended Products after they click the Show My Price button.',
  FillInYourInformationToSeeYourPersonalizedInstantQuote:
    'Fill in your information to see your personalized instant quote. Instant quote pricing is subject to measurement and/or site verification.',
  YesYouMayContactMeAboutMyProject: 'Yes, you may contact me about my project.',
  YesIWouldLikeToReceiveSmsMessagesAboutMyProject:
    'Yes, I would like to receive sms messages about my project. Opt-out anytime.',
  SendMeDetails: 'Send me details',
  FinancingOption: 'Financing Option',
};

export default en;
