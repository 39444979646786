import { forwardRef } from 'react';
import CheckboxBase, { CheckboxProps } from 'antd/lib/checkbox';
import styled from 'styled-components';

interface Props extends Omit<CheckboxProps, 'onClick'> {
  onClick?: (checked: boolean, event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

// TODO: use onChange instead of onClick
const CheckboxComponent = forwardRef<HTMLInputElement, Props>(({ onClick, ...props }, ref) => {
  const _onClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, checked: boolean): void => {
    onClick && onClick(checked, event);
  };

  return (
    <Checkbox
      ref={ref}
      {...props}
      onClick={event => _onClick(event, !props.checked)}
      className={`${props.className || ''} roofle-checkbox`}
    />
  );
});

const Checkbox = styled(CheckboxBase)`
  .ant-checkbox {
    z-index: 0;
    &:after {
      border-radius: 4px;
    }
  }
  .ant-checkbox-input {
    z-index: 2;
  }
  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
    z-index: 1;
    border-radius: 4px;
    &:after {
      height: 12px;
      width: 7px;
    }
  }
  .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-disabled .ant-checkbox-inner {
    border: 2px solid rgba(0, 0, 0, 0.4) !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => props.theme.colors.orange};
    border-color: ${props => props.theme.colors.orange} !important;
  }
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: rgba(0, 0, 0, 0.6) !important;
  }
  .ant-checkbox-checked:hover .ant-checkbox-inner,
  .ant-checkbox-checked:hover .ant-checkbox-input:focus + .ant-checkbox-inner {
    background-color: ${props => props.theme.colors.darkOrange};
    border-color: ${props => props.theme.colors.darkOrange} !important;
  }
  .ant-checkbox:hover::after {
    z-index: 0;
  }
  .ant-checkbox-disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: white;
  }
  &.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin: 0;
  }
`;

export default CheckboxComponent;
