import cloneDeep from 'lodash/cloneDeep';
import * as constants from './constants';
import { WidgetSettingsState } from './types';
import { ActionType } from 'types';

const defaultWidgetSettings: WidgetSettingsState = cloneDeep(constants.DEFAULT_WIDGET_SETTINGS);

const WidgetReducer = (state = defaultWidgetSettings, action: ActionType): WidgetSettingsState => {
  switch (action.type) {
    case constants.SET_ALL_WIDGET_SETTINGS: {
      return {
        ...state,
        ...action.settings,
      };
    }
    case constants.SET_WIDGET_SETTINGS: {
      const nextBrandingState = { ...state.branding, ...action.settings };

      return {
        ...state,
        branding: nextBrandingState,
      };
    }
    case constants.RESET_WIDGET_SETTINGS: {
      return {
        ...state,
        branding: { ...action.defaultBranding },
        loaded: true,
      };
    }
    case constants.SET_WIDGET_PRODUCTS: {
      return {
        ...state,
        products: action.products,
      };
    }
    case constants.SET_WIDGET_WILDCARD: {
      return {
        ...state,
        wildcard: action.wildcard,
      };
    }
    case constants.SET_WIDGET_IFRAME_PREVIEW: {
      return {
        ...state,
        iframePreview: action.iframePreview,
      };
    }
    case constants.SET_WIDGET_EXTERNAL_URL: {
      return {
        ...state,
        externalUrl: action.externalUrl,
      };
    }
    case constants.SET_WIDGET_USER_INFO: {
      return {
        ...state,
        userInfo: action.userInfo,
      };
    }
    case constants.SET_MAP_PLACEHOLDER_MEDIA: {
      const { placeholderMedia } = action;
      return {
        ...state,
        branding: {
          ...state.branding,
          mapSettings: {
            ...state.branding.mapSettings,
            placeholderMedia,
          },
        },
      };
    }
    case constants.CLEAR_MAP_SETTINGS_MEDIA: {
      return {
        ...state,
        branding: {
          ...state.branding,
          mapSettings: {
            ...state.branding.mapSettings,
            placeholderMedia: '',
          },
        },
      };
    }
    case constants.CLEAR_BRANDING: {
      return {
        ...state,
        branding: { ...action.defaultBranding },
        loaded: false,
      };
    }
    default:
      return state;
  }
};

export default WidgetReducer;
