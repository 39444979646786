import { ActionType } from 'types';
import * as constants from './constants';

const initialState = { show: false, count: 0 };

const spinnerReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case constants.SHOW:
      const options = action.options || {};
      return { show: true, ...options, count: state.count + 1 };
    case constants.HIDE:
      const newCount = state.count - 1;
      return { show: newCount > 0, count: newCount < 0 ? 0 : newCount };
    default:
      return state;
  }
};

export default spinnerReducer;
