import { State } from 'types';
import { getFinancingObject } from '.';

export const selecFinancing = (state: State) => state.financing;
export const selectBasicFinancing = (state: State) => selecFinancing(state).basicFinancing;
export const selectMomnt = (state: State) => selecFinancing(state).momnt;
export const selectLoanApplications = (state: State) => selectMomnt(state).loanApplications;
export const selectLoanInvitations = (state: State) => selectMomnt(state).loanInvitations;
export const selectAllTransactions = (state: State) => selectMomnt(state).allTransactions;
export const selectAvailableTransactions = (state: State) =>
  selectMomnt(state).availableTransactions;
export const selectRecentActivity = (state: State) => selectMomnt(state).recentActivity;
export const selectOfferCodes = (state: State) => selectMomnt(state).offerCodes;
export const selectStatements = (state: State) => selectMomnt(state).statements;
export const selectRateSheets = (state: State) => selectMomnt(state).rateSheets;
export const selectActiveRateSheets = (state: State) =>
  selectMomnt(state).rateSheets.filter(rateSheet => rateSheet.isActive);

export const selectDefinedBasicFinancing = (state: State) =>
  getFinancingObject(selectBasicFinancing(state));
