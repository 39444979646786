import { AdditionalCost } from './additionalCost';
import { ContractorLoanPro } from './contractorLoanPro';
import { FixedPrice } from './fixedPrice';
import { MonthlyPrice } from './monthlyPrice';
import { PriceConfig, OriginalPriceConfig, InstantQuotePriceConfig } from './priceConfig';
import { PriceRange } from './priceRange';
import { SteepSlope } from './steepSlope';
import { TotalPrice } from './totalPrice';
import { WasteFactor } from './wasteFactor';
import { Discount } from './discount';

import { PriceInfoType, PriceRangeObj, PricesParams } from 'modules/global/types';
import { DefaultFinancingTypes, PriceInfo } from 'modules/financing/types';

export interface PriceInfoOptions {
  includeTooltips?: boolean;
  includeMerchantFee?: boolean;
  useAdditionalCostsFormula?: boolean;
  // TODO: Artem + BA review. This one doesn't work as expected
  roundTotalPrice?: boolean;
}

type Instances = {
  priceConfig: PriceConfig;
  additionalCost: AdditionalCost;
  discount: Discount;
  contractorLoanPro: ContractorLoanPro;
  fixedPrice: FixedPrice;
  monthlyPrice: MonthlyPrice;
  priceRange: PriceRange;
  steepSlope: SteepSlope;
  totalPrice: TotalPrice;
  originalTotalPrice: TotalPrice;
  wasteFactor: WasteFactor;
};

export class PricesBuilder {
  constructor(parameters: PricesParams) {
    this.parameters = parameters;
  }

  private configs?: Instances;

  static DEFAULT_PRICE_INFO = {
    priceType: DefaultFinancingTypes.BasicFinancing,
    total: 0,
    originalTotal: 0,
    pricePerSquare: 0,
  };

  private readonly parameters: PricesParams;

  private priceInfoType: PriceInfoType = PriceInfoType.Default;

  private isPriceInformationHasBeenGenerated = false;

  private result: PriceInfo = PricesBuilder.DEFAULT_PRICE_INFO;

  get originalPriceConfig(): PriceConfig {
    return new OriginalPriceConfig(this.parameters);
  }

  get defaultPriceConfig(): PriceConfig {
    return new PriceConfig(this.parameters);
  }

  get instantQuotePriceConfig(): PriceConfig {
    return new InstantQuotePriceConfig(this.parameters);
  }

  private createPriceConfig(priceInfoType: PriceInfoType): PriceConfig {
    switch (priceInfoType) {
      case PriceInfoType.InstantQuote:
        return this.instantQuotePriceConfig;
      case PriceInfoType.Original:
        return this.originalPriceConfig;
      default:
        return this.defaultPriceConfig;
    }
  }

  private createConfigsInstances(priceInfoType: PriceInfoType = PriceInfoType.Default): Instances {
    const priceConfig = this.createPriceConfig(priceInfoType);
    const additionalCost = new AdditionalCost(priceConfig);
    const discount = new Discount(priceConfig);
    const steepSlope = new SteepSlope(priceConfig);
    const wasteFactor = new WasteFactor(priceConfig);
    const priceRange = new PriceRange(priceConfig);

    const fixedPrice = new FixedPrice({ priceConfig, steepSlope });
    const totalPrice = new TotalPrice({
      priceConfig,
      steepSlope,
      fixedPrice,
      wasteFactor,
    });
    const originalTotalPrice = new TotalPrice({
      priceConfig: this.originalPriceConfig,
      steepSlope,
      fixedPrice,
      wasteFactor,
    });
    const monthlyPrice = new MonthlyPrice(priceConfig);
    const contractorLoanPro = new ContractorLoanPro({ priceConfig, monthlyPrice });

    return {
      priceConfig,
      additionalCost,
      discount,
      steepSlope,
      wasteFactor,
      priceRange,
      fixedPrice,
      totalPrice,
      originalTotalPrice,
      monthlyPrice,
      contractorLoanPro,
    };
  }

  // Initial and required step
  createInitialPriceInfo(type: PriceInfoType = PriceInfoType.Default): PricesBuilder {
    this.priceInfoType = type;
    this.configs = this.createConfigsInstances(type);

    this.result = {
      ...this.result,
      priceType:
        this.configs.priceConfig.financing.financingType?.type ||
        DefaultFinancingTypes.BasicFinancing,
      pricePerSquare: Number((this.parameters.squareFeetPrice * 100).toFixed(2)),
    };

    return this;
  }

  computeWasteFactor(): PricesBuilder {
    if (!this.configs) {
      return this;
    }

    this.configs.wasteFactor.computeWasteFactorByStructures();

    return this;
  }

  computeSteepSlope(): PricesBuilder {
    if (!this.configs) {
      return this;
    }

    this.configs.steepSlope.computeSteepSlopeByStructures();

    return this;
  }

  // Adding total and original total prices
  addTotalPrices(options?: PriceInfoOptions): PricesBuilder {
    if (!this.configs) {
      return this;
    }

    const useAdditionalCostsFormula =
      options && 'useAdditionalCostsFormula' in options ? options.useAdditionalCostsFormula : true;

    this.result = {
      ...this.result,
      originalTotal: this.configs.originalTotalPrice.calculateTotalPrice(useAdditionalCostsFormula),
      total: this.configs.totalPrice.calculateTotalPrice(useAdditionalCostsFormula),
    };

    return this;
  }

  addAdditionalCosts(options?: PriceInfoOptions): PricesBuilder {
    const useAdditionalCostsFormula =
      options && 'useAdditionalCostsFormula' in options ? options.useAdditionalCostsFormula : true;

    if (!this.configs || !useAdditionalCostsFormula || !this.result.total) {
      return this;
    }

    const additionalCosts = this.configs.additionalCost.getCombinedByStructureAdditionalCosts();
    const totalAdditionalCosts = this.configs.additionalCost.calculateTotal(additionalCosts);
    const needToAddAdditionalCost = this.configs.priceConfig.product?.addAdditionalCosts;

    let updatedTotal = this.result.total;

    if (needToAddAdditionalCost) {
      updatedTotal += totalAdditionalCosts;
    }

    this.result = {
      ...this.result,
      additionalCosts,
      totalAdditionalCosts,
      total: +updatedTotal.toFixed(2),
    };

    return this;
  }

  computeAdditionalCostDiscounts(): PricesBuilder {
    if (!this.configs || !this.result.additionalCosts || !this.result.totalAdditionalCosts) {
      return this;
    }

    const additionalCostDiscounts = this.configs.discount.calculateAdditionalCostDiscounts(
      this.result.additionalCosts,
      this.result.totalAdditionalCosts,
    );
    const discountsCopy = !this.result.discounts ? {} : { ...this.result.discounts };
    const newDiscounts = { ...discountsCopy, ...additionalCostDiscounts };
    this.result = {
      ...this.result,
      discounts: newDiscounts,
      totalDiscount: this.configs.discount.calculateTotal(newDiscounts),
    };

    return this;
  }

  computeTotalDiscount(): PricesBuilder {
    if (!this.configs || !this.result.total) {
      return this;
    }

    const totalDiscounts = this.configs.discount.calculateTotalDiscount(this.result.total);
    const discountsCopy = !this.result.discounts ? {} : { ...this.result.discounts };
    const newDiscounts = { ...discountsCopy, ...totalDiscounts };
    this.result = {
      ...this.result,
      discounts: newDiscounts,
      totalDiscount: this.configs.discount.calculateTotal(newDiscounts),
    };

    return this;
  }

  applyDiscounts(): PricesBuilder {
    if (!this.configs || !this.result.totalDiscount || !this.result.total) {
      return this;
    }

    const updatedTotal = this.result.total - this.result.totalDiscount;

    this.result = {
      ...this.result,
      total: +updatedTotal.toFixed(2),
    };

    return this;
  }

  addFinancingInfo(): PricesBuilder {
    if (!this.configs) {
      return this;
    }

    //Currently every financing type (except CLP) has Basic Financing logic
    if (
      this.result.priceType !== DefaultFinancingTypes.ContractorLoanPro &&
      !this.parameters.line?.showMonthly
    ) {
      this.isPriceInformationHasBeenGenerated = true;
      return this;
    }

    //Currently every financing type (except CLP) has Basic Financing logic
    if (this.result.priceType !== DefaultFinancingTypes.ContractorLoanPro) {
      this.result.monthly = this.configs.monthlyPrice.calculateMonthlyPrice(this.result.total);
      this.result.months = this.configs.priceConfig.financing.months;
      this.result.apr = this.configs.priceConfig.financing.percentageRate;
      this.isPriceInformationHasBeenGenerated = true;
    }

    return this;
  }

  addLoanInformation(options?: PriceInfoOptions): PricesBuilder {
    if (!this.configs || this.isPriceInformationHasBeenGenerated) {
      return this;
    }

    this.result = {
      ...this.result,
      ...this.configs.contractorLoanPro.generateLoanInformation(this.result as PriceInfo, options),
    };

    return this;
  }

  addPriceRange(): PricesBuilder {
    if (!this.configs) {
      return this;
    }

    const obj: Record<string, PriceRangeObj | number | undefined> = {};

    if (this.configs.priceRange.shouldAddPriceRange) {
      obj.priceRange = this.configs.priceRange.getPriceRange({
        ...this.result,
        monthly: this.result.payments || this.result.monthly,
      });

      this.result.payments && (obj.payments = obj.priceRange.monthlyMin);
    }

    this.result = { ...this.result, ...obj };

    return this;
  }

  getPriceInfo(): PriceInfo {
    const result = { ...this.result };
    this.result = PricesBuilder.DEFAULT_PRICE_INFO;
    this.isPriceInformationHasBeenGenerated = false;

    return result;
  }
}

export class Prices {
  constructor(parameters: PricesParams) {
    this.builder = new PricesBuilder(parameters);
  }

  builder: PricesBuilder;

  get includedStructuresTotalSquareFeet(): number {
    return this.builder.defaultPriceConfig.includedStructuresTotalSquareFeet;
  }

  generatePriceInfo(options?: PriceInfoOptions): PriceInfo {
    return this.builder
      .createInitialPriceInfo()
      .computeWasteFactor()
      .computeSteepSlope()
      .addTotalPrices(options)
      .addAdditionalCosts(options)
      .computeAdditionalCostDiscounts()
      .computeTotalDiscount()
      .applyDiscounts()
      .addFinancingInfo()
      .addLoanInformation(options)
      .addPriceRange()
      .getPriceInfo();
  }

  generateInstantQuotePriceInfo(options?: PriceInfoOptions): PriceInfo {
    return this.builder
      .createInitialPriceInfo(PriceInfoType.InstantQuote)
      .computeWasteFactor()
      .computeSteepSlope()
      .addTotalPrices(options)
      .addAdditionalCosts(options)
      .computeAdditionalCostDiscounts()
      .computeTotalDiscount()
      .applyDiscounts()
      .addFinancingInfo()
      .addLoanInformation(options)
      .addPriceRange()
      .getPriceInfo();
  }

  generateOriginalPriceInfo(): PriceInfo {
    return this.builder
      .createInitialPriceInfo(PriceInfoType.Original)
      .computeWasteFactor()
      .computeSteepSlope()
      .addTotalPrices()
      .addFinancingInfo()
      .addLoanInformation()
      .getPriceInfo();
  }

  generateCustomPriceInfo(): PriceInfo {
    return this.builder
      .createInitialPriceInfo()
      .computeWasteFactor()
      .computeSteepSlope()
      .addTotalPrices()
      .addAdditionalCosts()
      .computeAdditionalCostDiscounts()
      .computeTotalDiscount()
      .applyDiscounts()
      .addFinancingInfo()
      .addPriceRange()
      .getPriceInfo();
  }
}

export const getPrices = (parameters: PricesParams) => new Prices(parameters);
