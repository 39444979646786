import { useCallback, useRef, useState, useMemo, forwardRef } from 'react';
import { Select as SelectBase } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { BaseSelectRef } from 'rc-select';

import { I18nEnum } from 'types';
import Label from '../Label';
import { SelectProps } from './types';
import { getLabel } from './utils';
import OptionTextComponent from './OptionTextComponent';
import {
  useDropdownRenderer,
  useDropdownVisibility,
  useMultipleSelectInternalValue,
} from './hooks';
import { Checkbox, Tooltip, SelectContainer } from './SelectContainer';

const { Option } = SelectBase;

export const _SelectComponent = forwardRef<HTMLElement, SelectProps>(
  (
    {
      options: _options,
      optionContentRenderer,
      dropdownWidth,
      value,
      optionStyle = {},
      bordered,
      showSearch,
      mode,
      error,
      onChange,
      onDropdownVisibleChange,
      onFocus,
      onBlur,
      onSearch,
      allLabel,
      allTag,
      showMultipleSelectTags,
      open,
      ...props
    },
    ref,
  ) => {
    const intl = useIntl();
    const selectRef = useRef<BaseSelectRef>(null);
    const { _onDropdownVisibleChange, opened, setOpened } = useDropdownVisibility({
      onDropdownVisibleChange,
      onFocus,
      open,
      selectRef,
    });
    const [showTooltipForValue, setShowTooltipForValue] = useState<string | number | null>(null);
    const _optionContentRenderer = useMemo(
      () => optionContentRenderer || (optionContent => optionContent),
      [optionContentRenderer],
    );

    const options = useMemo(
      () =>
        mode === 'multiple'
          ? [
              {
                label: allLabel
                  ? allLabel
                  : intl.formatMessage({
                      id: I18nEnum.All,
                    }),
                value: 'all',
              },
              ..._options,
            ]
          : _options,
      [_options, mode, allLabel, intl],
    );

    const { internalMultipleSelectValue, onSelect, onDeselect } = useMultipleSelectInternalValue({
      value,
      mode,
      opened,
      options,
    });

    const tagRender = useCallback(
      ({ value: tagValue }) => {
        const shouldShowSelectedAmount =
          options.length === 1 ? options[0].value !== 'all' : value?.length !== options.length;

        return value && value.length && tagValue === value[0] && !opened ? (
          <FormattedMessage
            id={shouldShowSelectedAmount ? I18nEnum.NItemsSelected : allTag ? allTag : I18nEnum.All}
            values={{ n: value.includes('all') ? value.length - 1 : value.length }}
          />
        ) : (
          <></>
        );
      },
      [allTag, opened, options, value],
    );

    const dropdownRender = useDropdownRenderer({
      mode,
      onChange,
      value,
      internalMultipleSelectValue,
      setOpened,
    });

    return (
      <SelectContainer
        error={error}
        open={opened}
        showMultipleSelectTags={showMultipleSelectTags}
        mode={mode}
        value={value}
        internalMultipleSelectValue={internalMultipleSelectValue}
        options={options}
        bordered={bordered}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onChange={onChange}
        onDropdownVisibleChange={_onDropdownVisibleChange}
        onBlur={onBlur}
        onSearch={onSearch}
        dropdownMatchSelectWidth={dropdownWidth}
        innerRef={selectRef}
        containerRef={ref}
        tagRender={tagRender}
        dropdownRender={dropdownRender}
        showSearch={showSearch && opened}
        {...props}>
        {options.map(option => (
          <Option
            key={option.value}
            value={option.value}
            label={
              Object.keys(I18nEnum).includes(option.label)
                ? intl.formatMessage({ id: option.label })
                : option.label
            }
            disabled={option.disabled}
            style={optionStyle}>
            {mode === 'multiple' && (
              <>
                <Checkbox checked className="checked" />
                <Checkbox className="unchecked" />
              </>
            )}
            {_optionContentRenderer(
              <>
                {option.value === showTooltipForValue && (
                  <Tooltip>{getLabel(option.label)}</Tooltip>
                )}
                <OptionTextComponent
                  option={option}
                  toggleSetShowTooltipForValue={setShowTooltipForValue}
                />
                {option.children}
              </>,
              option,
            )}
          </Option>
        ))}
      </SelectContainer>
    );
  },
);

const SelectComponent = ({
  hideDropdownLabel,
  ...props
}: SelectProps & { hideDropdownLabel?: boolean }) => {
  return props.placeholder && !hideDropdownLabel ? (
    <Label {...props}>
      {(_onFocus, _onBlur) => <_SelectComponent {...props} onFocus={_onFocus} onBlur={_onBlur} />}
    </Label>
  ) : (
    <_SelectComponent {...props} />
  );
};
export default SelectComponent;
