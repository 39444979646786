import cloneDeep from 'lodash/cloneDeep';
import { WasteFactorByStructures } from '../types';
import { PriceConfig } from './priceConfig';

import { MLWasteFactor, RoofleStructure } from 'modules/quickQuote/types';

export class WasteFactor {
  constructor(priceConfig: PriceConfig) {
    this.priceConfig = priceConfig;
  }

  private readonly priceConfig: PriceConfig;

  private _wasteFactorByStructures?: WasteFactorByStructures;

  get wasteFactorByStructures(): WasteFactorByStructures | undefined {
    return cloneDeep(this._wasteFactorByStructures);
  }

  get shouldAddWasteFactor(): boolean {
    const { companyQuoteSettings, product } = this.priceConfig;
    if (!product) {
      return false;
    }

    if (product.customProduct) {
      const wasteFactorApplicable = !product.useInstantQuoteSettings && !product.fixedPrice;
      return (
        wasteFactorApplicable &&
        product.wasteFactorEnabled &&
        !!companyQuoteSettings?.wasteFactorEnabled
      );
    }

    return (
      product.useInstantQuoteSettings ||
      (product.wasteFactorEnabled && !!companyQuoteSettings?.wasteFactorEnabled)
    );
  }

  get productCustomWasteFactorEnabled(): boolean {
    const { product } = this.priceConfig;
    return (
      this.shouldAddWasteFactor &&
      !product?.useInstantQuoteSettings &&
      !!product?.wasteFactorEnabled
    );
  }

  static wasteFactorIsSuggested(
    wasteFactor: number | undefined | null | Partial<MLWasteFactor>,
  ): wasteFactor is Partial<MLWasteFactor> {
    return (
      typeof wasteFactor !== 'number' && typeof wasteFactor !== 'undefined' && wasteFactor !== null
    );
  }

  getCorrectWasteFactorFromUnion(wasteFactor?: number | Partial<MLWasteFactor>): number {
    const { product } = this.priceConfig;

    if (!wasteFactor || !product) return 1;

    if (WasteFactor.wasteFactorIsSuggested(wasteFactor)) {
      const productType = /tile/i.test(product.type) ? 'Tile' : product.type;
      return wasteFactor[productType] || 1;
    }

    return wasteFactor;
  }

  computeWasteFactorByStructures(): void {
    const { includedStructures } = this.priceConfig;

    this._wasteFactorByStructures = includedStructures.reduce((acc, structure) => {
      if (!structure.geoJsonPolygon.id) return acc;
      const wasteFactorValue = this.getWasteFactorValue(structure);
      acc[structure.geoJsonPolygon.id] = wasteFactorValue;
      return acc;
    }, {});
  }

  getWasteFactorValue(structure?: RoofleStructure): number {
    const { product, productPriceSettings } = this.priceConfig;

    if (!this.shouldAddWasteFactor) {
      return 1;
    }

    if (this.productCustomWasteFactorEnabled) {
      return product?.wasteFactorValue || 1;
    }

    //case when user changes smth on rep quote page
    if (structure?.geoJsonPolygon.id) {
      const updatedWFsByStructureName = productPriceSettings?.wasteFactor;
      const updatedStructureWF = updatedWFsByStructureName?.[structure.geoJsonPolygon.id];
      const usedWF = updatedStructureWF?.usedWFType;
      const wasteFactorUpdated = usedWF && updatedStructureWF?.[usedWF];

      if (wasteFactorUpdated !== undefined) {
        return this.getCorrectWasteFactorFromUnion(wasteFactorUpdated);
      }
    }

    //case when lead created with wf
    if (!!structure?.wasteFactor) {
      const savedWasteFactor = structure?.wasteFactor?.[structure.wasteFactor.usedWFType];
      return this.getCorrectWasteFactorFromUnion(savedWasteFactor);
    }

    return 1;
  }
}
