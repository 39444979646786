import { initialQuery as _initialQuery } from 'components/Table';

const name = 'analyticsReports/';

export const SET_ANALYTICS_REPORTS_FILTER = `${name}SET_ANALYTICS_REPORTS_FILTER`;
export const SET_ANALYTICS_LOADED = `${name}SET_ANALYTICS_LOADED`;
export const SET_ANALYTICS_STATISTICS_PART = `${name}SET_ANALYTICS_STATISTICS_PART`;
export const SET_ANALYTICS_ANALYTICS_PART = `${name}SET_ANALYTICS_ANALYTICS_PART`;
export const SET_ANALYTICS_AVERAGE_QUOTES = `${name}SET_ANALYTICS_AVERAGE_QUOTES`;
export const SET_ANALYTICS_TOP_PRODUCTS = `${name}SET_ANALYTICS_TOP_PRODUCTS`;
export const SET_ANALYTICS_PAGE_CONVERSION = `${name}SET_ANALYTICS_PAGE_CONVERSION`;
export const SET_ANALYTICS_FINANCE_ANALYTICS = `${name}SET_ANALYTICS_FINANCE_ANALYTICS`;

export const initialQuery = { ..._initialQuery, filter: {} };

export const defaultNotSelectedStatisticsStatuses = ['Deleted'];
