import { ButtonType, ButtonHTMLType } from 'antd/lib/button/button';
import { FormattedMessage } from 'react-intl';

import { I18nEnum } from 'types';
import { Button, Wrapper, PrefixWrapper } from './styled';
import useNavigation from 'modules/hooks/useNavigation';

interface Props {
  title: string | JSX.Element;
  type: ButtonType | 'light-outlined';
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  disabled?: boolean;
  prefix?: React.ReactNode;
  htmlType?: ButtonHTMLType;
  loading?: boolean;
  wrapperClassName?: string;
  id?: string;
}

const ButtonComponent = ({
  id,
  title,
  type,
  onClick,
  className,
  disabled,
  prefix,
  htmlType = 'submit',
  loading,
  wrapperClassName,
}: Props) => {
  const [inputType] = useNavigation();

  return (
    <Button
      id={id}
      type="primary"
      onClick={onClick}
      customType={type}
      className={className}
      tabIndex={0}
      data-whatinput={inputType}
      disabled={!!disabled}
      loading={loading}
      htmlType={htmlType}>
      <Wrapper className={wrapperClassName || ''}>
        {prefix && <PrefixWrapper className="prefix">{prefix}</PrefixWrapper>}
        {typeof title === 'string' ? (
          <span>
            {Object.keys(I18nEnum).includes(title) ? <FormattedMessage id={title} /> : title}
          </span>
        ) : (
          title
        )}
      </Wrapper>
    </Button>
  );
};

export default ButtonComponent;
