export interface MessageState {
  opened: boolean;
  type: string;
  text: string | string[];
}

export enum MessageTypes {
  success = 'success',
  error = 'error',
}
