import { FC } from 'react';
import { renderToString } from 'react-dom/server';
import styled, { DefaultTheme, css } from 'styled-components';

import { SpinnerStateless } from 'modules/spinner/components/Spinner';
import { ButtonConfig, CheckboxConfig, FontConfig, InputConfig } from './types';
import { SPINNER_CONTAINER } from './constants';

export const getButtonBrandingConfigStyles = (config: ButtonConfig, isUsePseudo = true) => `
  border: 1px solid ${config.strokeColor};
  background-color: ${config.fillColor};
  color: ${config.fontColor};
  border-radius: ${config.cornerRadius}px;
  font-weight: ${config.fontWeight};
  font-size: ${config.fontSize}px;
  font-family: ${config.font};

  ${
    isUsePseudo
      ? `
    &:hover,
    &:active,
    &:focus,
    &:disabled,
    &:disabled:hover {
      color: ${config.fontColor};
      background-color: ${config.fillColor};
      border-color: ${config.strokeColor};
    }

    &:disabled, &:disabled:hover  {
      pointer-events: none;
      opacity: 0.5;
    }
  `
      : ''
  }
`;

export const getCheckboxBrandingConfigStyles = (config: CheckboxConfig) => `
    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner,
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-checked:hover .ant-checkbox-inner,
    .ant-checkbox-checked:hover .ant-checkbox-input:focus + .ant-checkbox-inner {
      background-color: ${config.fillColor};
      border-color: ${config.strokeColor} !important;
    }
    .ant-checkbox-inner {
      border-radius: ${config.cornerRadius}px;
    }
    .ant-checkbox-checked:after {
      content: unset;
    }
`;

export const getTextConfig = (config: FontConfig) => `
  color: ${config.fontColor};
  font-family: ${config.font};
`;

export const addBrandingForInputs = (inputComponents: FC<any>[]) =>
  inputComponents.map(
    component =>
      styled(component)<{ $inputConfig: InputConfig; theme: DefaultTheme }>`
        ${({ $inputConfig, theme }) => css`
          caret-color: ${$inputConfig.caretColor};
          background-color: transparent;
          color: ${$inputConfig.fontColor};
          font-family: ${$inputConfig.font};

          .ant-select-selector {
            background-color: rgba(255, 255, 255, 0.85) !important;
          }

          &:focus,
          &:focus-within {
            border-color: ${$inputConfig.borderColor};
          }

          ${!$inputConfig.showLabel &&
          `
          .input-label {
            display: none;
          }
      `}

          &.ant-input, input {
            background-color: rgba(255, 255, 255, 0.85);

            &::placeholder {
              color: ${theme.colors.grey} !important;
            }
          }

          .ant-select-selection-placeholder {
            color: ${theme.colors.grey} !important;
          }

          &.ant-input.ant-input-status-error {
            background-color: rgba(255, 255, 255, 0.85) !important;
          }
        `}
      `,
  );

export const addBrandingForMomntInvitationModal = <T,>(component: React.FC<T>) => {
  return styled(component)<{ radioButtonStylesConfig: { fillColor: string; strokeColor: string } }>`
    .ant-radio-inner {
      background-color: rgba(255, 255, 255, 0.85);
    }

    .ant-radio-checked .ant-radio-inner,
    .ant-radio-checked:hover .ant-radio-inner {
      border-color: ${props => props.radioButtonStylesConfig.strokeColor};

      &::after {
        background-color: ${props => props.radioButtonStylesConfig.fillColor};
      }
    }

    .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: none;
    }
  `;
};

export const getAddressFields = (url: string) => {
  const addressFields = ['street1', 'city', 'state', 'postalCode'];
  const _url = new URL(url);
  const params = _url.search
    .replace('?', '')
    .split('&')
    .reduce((acc, param) => {
      const [key, value] = param.split('=');
      return { ...acc, [key]: decodeURIComponent(value) };
    }, {});

  const addressFeature = addressFields.reduce((acc, field) => {
    const queryParameter = params[field];
    if (queryParameter) {
      acc[field] = queryParameter;
    }
    return acc;
  }, {});

  return Object.keys(addressFeature).length === addressFields.length ? addressFeature : null;
};

export const getPercent = (x: number, wrapperWidth: number): number => {
  return (x / wrapperWidth) * 100;
};

export const getScrollLength = ({
  direction,
  scrollPageX,
  firstTachShift,
}: {
  direction: string;
  scrollPageX: number;
  firstTachShift: number;
}) => {
  return direction === 'right' ? scrollPageX - firstTachShift : firstTachShift - scrollPageX;
};

export const minutesConvert = (min: number): string => {
  const hours = min / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return rhours ? `${rhours} h ${rminutes} min` : `${rminutes} min`;
};

export const createSpinner = () => {
  const spinnerHtml = renderToString(<SpinnerStateless />);

  const container = document.createElement('div');
  container.id = SPINNER_CONTAINER;
  container.innerHTML = spinnerHtml;

  return container;
};

export const removeSpinner = () => {
  const spinnerContainer = document.getElementById(SPINNER_CONTAINER);
  if (spinnerContainer) {
    spinnerContainer.remove();
  }
};

export const isTextInputEmpty = (input: string) => {
  if (!input || !input.trim()) {
    return true;
  }

  const container = document.createElement('div');
  container.innerHTML = input;

  return !container.textContent?.trim().replace(/(\r\n|\n|\r)/gm, '').length;
};

export const isBase64Image = (str: string): boolean => {
  return /^data:image\/[a-z]+;base64,[a-zA-Z0-9+/]+={0,2}$/.test(str);
};
