import { Typography } from 'antd';
import { FormattedMessage, RawIntlProvider } from 'react-intl';
import { intl } from 'intl';
import styled, { css } from 'styled-components';

import { I18nEnum } from 'types';
import IconRestorer from 'components/Icons/IconRestorer';
import ButtonBase from 'components/Button/Button';
import { getButtonBrandingConfigStyles } from 'modules/widget/utils';
import { ButtonConfig } from 'modules/widget';

export const ProductLabel = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 6px 0 0;
  background-color: ${props => props.theme.colors.beige};
  min-width: 84px;
  height: 26px;
  position: absolute;
  right: 0;
  top: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.29px;
  text-transform: uppercase;
  padding: 0 2px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 13px solid transparent;
    top: -1px;
    left: -14px;
    transform: rotate(45deg);

    border-bottom: 11px solid ${props => props.theme.colors.beige};
  }
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 11px solid transparent;
    bottom: -2px;
    left: -13px;
    transform: rotate(-225deg);

    border-bottom: 11px solid ${props => props.theme.colors.beige};
  }
`;

export const FiltersTitle = () => {
  return (
    <>
      <FilterIconWrapper size={{ width: 15, height: 10 }}>
        <img src="/images/svg/filter-icon.svg" loading="lazy" />
      </FilterIconWrapper>
      <FiltersText>
        <FormattedMessage id={I18nEnum.Filters} />
      </FiltersText>
    </>
  );
};

const FilterIconWrapper = styled(IconRestorer)`
  display: inline-block;
  margin-right: 4px;
  vertical-align: middle;

  img {
    filter: ${props => props.theme.colorFilters.blue};
  }
`;

const FiltersText = styled(Typography)`
  color: ${props => props.theme.colors.blue};
  font-size: 14px;
  letter-spacing: 0.29px;
  line-height: 17px;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: middle;
`;

export const Title = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: ${props => props.theme.colors.blue};
  margin-bottom: 8px;
  position: relative;
`;

export const WhatsIncluded = () => (
  <>
    <p>
      <FormattedMessage id={I18nEnum.AllRooflePricingIncludes} />:
    </p>
    <ul>
      <li>
        <FormattedMessage id={I18nEnum.RemoveExistingShingles} />
      </li>
      <li>
        <FormattedMessage id={I18nEnum.InstallIceAndWater} />
      </li>
      <li>
        <FormattedMessage id={I18nEnum.InstallUnderlayment} />
      </li>
      <li>
        <FormattedMessage id={I18nEnum.InstallSelectRoofingProduct} />
      </li>
      <li>
        <FormattedMessage id={I18nEnum.InstallHipRidge} />
      </li>
      <li>
        <FormattedMessage id={I18nEnum.ReplaceAllExisting} />
      </li>
      <li>
        <FormattedMessage id={I18nEnum.InstallNewPipeAndChimney} />
      </li>
      <li>
        <FormattedMessage id={I18nEnum.CleanUpAllJob} />
      </li>
      <li>
        <FormattedMessage id={I18nEnum.CityPermitIsIncluded} />
      </li>
    </ul>
    <p>
      <FormattedMessage id={I18nEnum.AllRoofleRoofingCrewsAreLicensed} />
    </p>
    <p>
      <FormattedMessage id={I18nEnum.InAdditionToYourProductWarranties} />
    </p>
  </>
);

export const WhatsIncludedIntl = () => (
  <RawIntlProvider value={intl}>
    <WhatsIncluded />
  </RawIntlProvider>
);

export const AdditionalCosts = () => (
  <>
    <p>
      <FormattedMessage id={I18nEnum.TheFollowingItemsAreNotIncluded} />
    </p>
    <ul>
      <FormattedMessage
        id={I18nEnum.RoofIsMoreThan1}
        values={{
          li: chunks => <li>{chunks}</li>,
        }}
      />
    </ul>
  </>
);

export const AdditionalCostsIntl = () => (
  <RawIntlProvider value={intl}>
    <AdditionalCosts />
  </RawIntlProvider>
);

export const Button = styled(ButtonBase)<{
  mainButtonConfig?: ButtonConfig;
  secondaryButtonConfig?: ButtonConfig;
}>`
  height: 40px;

  ${props =>
    props.type === 'primary' &&
    props.mainButtonConfig &&
    getButtonBrandingConfigStyles(props.mainButtonConfig)}

  ${props =>
    props.type === 'default' &&
    props.secondaryButtonConfig &&
    getButtonBrandingConfigStyles(props.secondaryButtonConfig)}

  font-size: 14px;

  @media ${props => props.theme.mediaQueries.medium} {
    width: 100%;
    height: 44px;
  }
`;

export const SubmitButton = styled(Button)`
  width: 170px;

  @media ${props => props.theme.mediaQueries.medium} {
    width: 100%;
  }
`;

export const InstallationPackageSectionCSS = css`
  ul,
  ol {
    margin: 0 0 8px;
    padding-inline-start: 20px;
    font-size: 16px;
    line-height: 24px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }
`;

export const BaseTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

export const ReferenceTooltipText = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.8);
`;
