import { FontsMapInterface } from './types';

export const fontsMap: FontsMapInterface = {
  'Cinzel Decorative': {
    400: './cinzel-decorative/CinzelDecorative-Regular.ttf',
    700: './cinzel-decorative/CinzelDecorative-Bold.ttf',
    900: './cinzel-decorative/CinzelDecorative-Black.ttf',
  },
  'Aktiv Grotesk': {
    100: './aktiv-grotesk/AktivGrotesk-Hairline.ttf',
    200: './aktiv-grotesk/AktivGrotesk-Thin.ttf',
    300: './aktiv-grotesk/AktivGrotesk-Light.ttf',
    400: './aktiv-grotesk/AktivGrotesk-Regular.ttf',
    500: './aktiv-grotesk/AktivGrotesk-Medium.ttf',
    700: './aktiv-grotesk/AktivGrotesk-Bold.ttf',
    800: './aktiv-grotesk/AktivGrotesk-XBold.ttf',
    900: './aktiv-grotesk/AktivGrotesk-Black.ttf',
  },
  'Andale Mono': {
    400: './andale-mo/ANDALEMO.TTF',
  },
  'Bebas Neue': {
    400: './bebas-neue/BebasNeue-Regular.ttf',
  },
  'Comic Sans': {
    400: './comic-sans-ms/design.graffiti.comicsansms.ttf',
    700: './comic-sans-ms/design.graffiti.comicsansmsgras.ttf',
  },
  Georgia: {
    400: './georgia/Georgia.Regular.font.ttf',
    700: './georgia/georgia.bold.ttf',
  },
  Seriffic: {
    500: './seriffic/seriffic.ttf',
  },
  Helvetica: {
    300: './helvetica/helvetica-light-587ebe5a59211.ttf',
    400: './helvetica/Helvetica.ttf',
    500: './helvetica/Helvetica-Bold.ttf',
    600: './helvetica/helvetica-rounded-bold-5871d05ead8de.otf',
  },
  Impact: {
    700: './impact/impact.ttf',
  },
  'Josefin Sans': {
    100: './josefin-sans/JosefinSans-Thin.ttf',
    300: './josefin-sans/JosefinSans-Light.ttf',
    400: './josefin-sans/JosefinSans-Regular.ttf',
    600: './josefin-sans/JosefinSans-SemiBold.ttf',
    700: './josefin-sans/JosefinSans-Bold.ttf',
  },
  Lato: {
    100: './lato/Lato-Hairline.ttf',
    300: './lato/Lato-Light.ttf',
    400: './lato/Lato-Regular.ttf',
    700: './lato/Lato-Bold.ttf',
    900: './lato/Lato-Black.ttf',
  },
  'Lucida Sans': {
    400: './lucida-sans/LTYPE.TTF',
    600: './lucida-sans/LTYPEB.TTF',
  },
  Montserrat: {
    400: './montserrat/Montserrat-Regular.ttf',
    700: './montserrat/Montserrat-Bold.ttf',
  },
  'Open Sans': {
    300: './open-sans/OpenSans-Light.ttf',
    400: './open-sans/OpenSans-Regular.ttf',
    600: './open-sans/OpenSans-Semibold.ttf',
    700: './open-sans/OpenSans-Bold.ttf',
    800: './open-sans/OpenSans-ExtraBold.ttf',
  },
  Oswald: {
    300: './oswald/Oswald-Light.ttf',
    400: './oswald/Oswald-Regular.ttf',
    500: './oswald/Oswald-Medium.ttf',
    700: './oswald/Oswald-Bold.ttf',
  },
  Poppins: {
    100: './poppins/Poppins-Thin.ttf',
    200: './poppins/Poppins-ExtraLight.ttf',
    300: './poppins/Poppins-Light.ttf',
    400: './poppins/Poppins-Regular.ttf',
    500: './poppins/Poppins-Medium.ttf',
    600: './poppins/Poppins-SemiBold.ttf',
    700: './poppins/Poppins-Bold.ttf',
    800: './poppins/Poppins-ExtraBold.ttf',
    900: './poppins/Poppins-Black.ttf',
  },
  'Proxima Nova': {
    400: './proxima-nova/Proxima.Nova.Font.otf',
  },
  Rajdhani: {
    300: './rajdhani/Rajdhani-Light.ttf',
    400: './rajdhani/Rajdhani-Regular.ttf',
    500: './rajdhani/Rajdhani-Medium.ttf',
    600: './rajdhani/Rajdhani-SemiBold.ttf',
    700: './rajdhani/Rajdhani-Bold.ttf',
  },
  Raleway: {
    100: './raleway/Raleway-Thin.ttf',
    200: './raleway/Raleway-ExtraLight.ttf',
    300: './raleway/Raleway-Light.ttf',
    400: './raleway/Raleway-Regular.ttf',
    500: './raleway/Raleway-Medium.ttf',
    600: './raleway/Raleway-SemiBold.ttf',
    700: './raleway/Raleway-Bold.ttf',
    800: './raleway/Raleway-ExtraBold.ttf',
    900: './raleway/Raleway-Heavy.ttf',
  },
  Roboto: {
    100: './roboto/Roboto-Thin.ttf',
    300: './roboto/Roboto-Light.ttf',
    400: './roboto/Roboto-Regular.ttf',
    500: './roboto/Roboto-Medium.ttf',
    700: './roboto/Roboto-Bold.ttf',
    900: './roboto/Roboto-Black.ttf',
  },
  Tahoma: {
    400: './tahoma/TAHOMA_0.TTF',
    700: './tahoma/TAHOMABD.TTF',
  },
  Verdana: {
    700: './verdana/Verdana.ttf',
  },
  'Segoe UI': {
    400: './segoe-ui/Segoe UI.woff',
  },
  Rubik: {
    300: './rubik/Rubik-Light.ttf',
    400: './rubik/Rubik-Regular.ttf',
    500: './rubik/Rubik-Medium.ttf',
  },
};
