import axios from 'axios';
import { QuickQuoteEncryptedRequestBody, Statistics } from 'modules/dashboard/types';

import api from 'services/api';

const geodataApiUrlBase = process.env.REACT_APP_GEODATA_API_URL;

// the API URL accepts: `/v2/:centerLng/:centerLat/:address`
export const geodataApiV2 = (lng: number, lat: number, address: string) => {
  if (!lng || !lat) {
    throw new Error(`geodataApi requires lat & lng`);
  }

  const url = `${geodataApiUrlBase}/v2/${lng}/${lat}/${encodeURIComponent(address)}`;

  return axios.get(url);
};

export const sendQuickQuoteStatisticsAPI = (
  wildcard: string,
  data: QuickQuoteEncryptedRequestBody & { userId?: number; forceWebLead: boolean },
  queryParams?: Partial<{
    [key: string]: string | boolean;
  }>,
) =>
  api.post(`/widget/${wildcard}/statistics`, data, {
    params: queryParams,
  });

export const getStatisticsForAddressAPI = (userId: number, address: string) =>
  api.get(`/user/${userId}/statistics/address/${address}`);

export const sendDataToML = async (wildcard: string, formData: FormData) =>
  api.post(`/widget/${wildcard}/ml/predict`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const markStatisticsProductsAsSendedAPI = ({
  statisticsId,
  wildcard,
  productIds,
}: {
  wildcard: string;
  statisticsId: number;
  productIds: number[];
}): Promise<Statistics> =>
  api.patch(`/widget/${wildcard}/statistics-mark-sended/${statisticsId}`, { productIds });

export const markStatisticsProductAsInterestedInFinancingAPI = ({
  statisticsId,
  wildcard,
  ...body
}: {
  wildcard: string;
  statisticsId: number;
  productId: number;
  financingVariant: string;
}): Promise<Statistics> =>
  api.patch(`/widget/${wildcard}/statistics-interested-financing/${statisticsId}`, body);

export const updateStatisticsInvitationPhonesAPI = ({
  statisticsId,
  wildcard,
  invitationPhone,
}: {
  wildcard: string;
  statisticsId: number;
  invitationPhone: string;
}): Promise<Record<string, never>> =>
  api.patch(`/widget/${wildcard}/statistics-invitation-phone/${statisticsId}`, { invitationPhone });

export const updateStatisticsInvitationEmailsAPI = ({
  statisticsId,
  wildcard,
  invitationEmail,
}: {
  wildcard: string;
  statisticsId: number;
  invitationEmail: string;
}): Promise<Record<string, never>> =>
  api.patch(`/widget/${wildcard}/statistics-invitation-email/${statisticsId}`, { invitationEmail });
