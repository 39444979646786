import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

import * as searchConstants from 'modules/search/constants';
import * as constants from './constants';
import { SupportedActions } from './reducer-types';
import { ParcelState, QuickQuoteState, StructureState, WasteFactorTypeEnum } from './types';
import { clearQuickQuoteStorage } from './storage';

import {
  getTotalMeasurements,
  getValidatedStructure,
  getInitialStructureState,
  getEditedStructureState,
} from './reducer-utils';

export const initialQuickQuoteState: QuickQuoteState = {
  ...constants.DEFAULT_GEODATA_STATE,
  parcelState: ParcelState.Initial,
  centerpoint: null,
  initialStructures: [],
  structures: [],
  totalMeasurements: null,
  isLoaded: false,
  isReadyToRender: false,
  structureState: StructureState.Initial,
  hasError: false,
  errorMessage: null,
  regionalApplied: false,
  userDetailsProvided: false,
  userInfo: null,
  leadData: null,
  mlPrediction: {
    error: null,
    loading: false,
  },
  productPriceSettings: null,
};

export const QuickQuoteReducer = (
  state = initialQuickQuoteState,
  action: SupportedActions,
): QuickQuoteState => {
  switch (action.type) {
    case constants.SET_QUICK_QUOTE_ALL: {
      const { quickQuote } = action;

      return {
        ...state,
        ...quickQuote,
        structures: quickQuote.structures.map((structure, _, structures) =>
          getValidatedStructure(structure, structures, true),
        ),
      };
    }
    case constants.SET_QUICK_QUOTE_IS_LOADED: {
      const { isLoaded } = action;

      return {
        ...state,
        isLoaded,
      };
    }
    case constants.SET_QUICK_QUOTE_IS_READY_TO_RENDER: {
      const { isReadyToRender } = action;

      return {
        ...state,
        isReadyToRender,
      };
    }
    case constants.SET_QUICK_QUOTE_CENTERPOINT: {
      const { centerpoint } = action;

      return {
        ...state,
        centerpoint,
      };
    }
    case constants.SET_QUICK_QUOTE_PARCEL: {
      const { parcel, parcelState, parcelStructures, structureMetaData } = action;

      return {
        ...state,
        parcel,
        parcelState,
        parcelStructures,
        structureMetaData,
      };
    }
    case constants.SET_QUICK_QUOTE_INITIAL_STRUCTURES: {
      let { initialStructures } = action;
      const totalMeasurements = getTotalMeasurements(initialStructures);
      const structureState = getInitialStructureState(initialStructures);

      if (structureState === StructureState.TooMany) {
        initialStructures = [];
      }

      const _initialStructures = state.initialStructures.length
        ? state.initialStructures
        : initialStructures;

      return {
        ...state,
        initialStructures: _initialStructures,
        structures: cloneDeep(initialStructures),
        structureState,
        totalMeasurements,
      };
    }
    case constants.SET_QUICK_QUOTE_STRUCTURES: {
      const { structures } = action;

      if (structures.length && structures.every(structure => !structure.isIncluded)) {
        structures[0].isIncluded = true;
      }

      const totalMeasurements = getTotalMeasurements(structures);
      const structureState = getEditedStructureState(structures);

      return {
        ...state,
        structures: structures.map(structure => getValidatedStructure(structure, structures, true)),
        structureState,
        totalMeasurements,
      };
    }
    case constants.SET_QUICK_QUOTE_STRUCTURE_NAME: {
      const { structure, name } = action;

      const newStructures = [...state.structures];
      const index = newStructures.findIndex(_structure => _structure.id === structure.id);
      newStructures[index] = { ...structure, name };

      return {
        ...state,
        structures: newStructures.map(_structure =>
          getValidatedStructure(_structure, newStructures, true),
        ),
      };
    }
    case constants.SET_QUICK_QUOTE_STRUCTURE_SLOPE: {
      const { slope, structure } = action;

      const newStructures = [...state.structures];
      const index = newStructures.findIndex(_structure => _structure.id === structure.id);
      newStructures[index] = merge(
        { ...structure, slope },
        { geoJsonPolygon: { properties: { slope } } },
      );

      const totalMeasurements = getTotalMeasurements(newStructures);

      return {
        ...state,
        totalMeasurements,
        structures: newStructures,
      };
    }
    case constants.SET_REGIONAL_APPLIED: {
      const { regionalApplied } = action;
      return {
        ...state,
        regionalApplied,
      };
    }
    case constants.SET_ERROR: {
      const { errorMessage, hasError } = action;

      return {
        ...state,
        errorMessage,
        hasError,
      };
    }
    case constants.SET_USER_DETAILS_PROVIDED: {
      return {
        ...state,
        userDetailsProvided: action.userDetailsProvided,
      };
    }
    case constants.SET_QUICK_QUOTE_USER_INFO: {
      const { userInfo } = action;

      return {
        ...state,
        userInfo,
      };
    }
    case constants.SET_QUICK_QUOTE_MARKET_SLUG: {
      const { marketSlug } = action;

      return { ...state, marketSlug };
    }
    case constants.SET_QUICK_QUOTE_CENTERPOINT_STATE: {
      const { centerpointState } = action;

      return { ...state, centerpointState };
    }
    case constants.CLEAR:
    case searchConstants.CLEAR: {
      clearQuickQuoteStorage();

      return { ...initialQuickQuoteState };
    }
    case constants.SET_LEAD_DATA: {
      return { ...state, leadData: action.leadData };
    }
    case constants.SET_QUICK_QUOTE_FETCH_ML_PREDICTION_DATA: {
      return {
        ...state,
        mlPrediction: {
          error: null,
          loading: true,
        },
      };
    }
    case constants.SET_QUICK_QUOTE_ML_PREDICTION_DATA: {
      const { MLPredictionData, eventId } = action;
      return {
        ...state,
        structures: state.structures.map(structure => ({
          ...structure,
          roofComplexity: MLPredictionData[structure.id]?.roofComplexity,
          wasteFactor: {
            ...structure.wasteFactor,
            suggested: MLPredictionData[structure.id]?.wasteFactor,
            usedWFType: MLPredictionData[structure.id]?.wasteFactor
              ? WasteFactorTypeEnum.Suggested
              : WasteFactorTypeEnum.Default,
          },
        })),
        mlPrediction: {
          ...state.mlPrediction,
          eventId,
        },
      };
    }
    case constants.SET_QUICK_QUOTE_FAILED_ML_PREDICTION_DATA: {
      const { error } = action;
      return {
        ...state,
        mlPrediction: {
          error,
          loading: true,
        },
      };
    }
    case constants.SET_QUICK_QUOTE_PRODUCT_PRICE_SETTINGS: {
      return { ...state, productPriceSettings: action.priceSettings };
    }
    default: {
      return state;
    }
  }
};
