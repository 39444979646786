import { ActionType } from 'types';
import * as constants from './constants';
import { AdminState } from './types';

const initialState: AdminState = {
  contractorsDashboard: {
    contractors: [],
    total: 0,
    filter: {
      markets: [],
      states: [],
      recurly: [],
      mapPlatforms: [],
      subscriptionTypes: [],
      subscriptionPlans: [],
      subscriptionRequests: [],
    },
    scores: {
      integrations: {},
      recurly: {},
      markets: {},
      states: {},
      mapPlatforms: {},
      subscriptionTypes: {},
      subscriptionPlans: {},
      subscriptionRequests: {},
    },
  },
};

const adminReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case constants.SET_CONTRACTORS_DASHBOARD:
      return {
        ...state,
        contractorsDashboard: {
          ...state.contractorsDashboard,
          contractors: action.contractors,
          total: action.total,
        },
      };
    case constants.SET_CONTRACTORS_FILTER:
      return {
        ...state,
        contractorsDashboard: {
          ...state.contractorsDashboard,
          filter: action.filter,
        },
      };
    case constants.SET_CONTRACTORS_SCORES:
      return {
        ...state,
        contractorsDashboard: {
          ...state.contractorsDashboard,
          scores: action.scores,
        },
      };
    case constants.CLEAR_CONTRACTORS_DASHBOARD:
      return initialState;
    default:
      return state;
  }
};

export default adminReducer;
