import { SubscriptionTypes, SubscriptionType } from 'modules/subscription/types/toolScript';
import {
  DEFAULT_BRANDING_CONFIG,
  DEFAULT_OC_BRANDING_CONFIG,
} from 'modules/widget/constants/toolScript';
import { ContactsFormOptionValue, BrandingConfig } from 'modules/widget/types/toolScript';

export const DEFAULT_BRANDING_MAP: Record<SubscriptionType, BrandingConfig> = {
  [SubscriptionTypes.RoofQuoteProUSA]: { ...DEFAULT_BRANDING_CONFIG },
  [SubscriptionTypes.OwensCorningProUSA]: { ...DEFAULT_OC_BRANDING_CONFIG },
  [SubscriptionTypes.RoofQuoteProCanada]: {
    ...DEFAULT_BRANDING_CONFIG,
    rqpLeads: {
      ...DEFAULT_BRANDING_CONFIG.rqpLeads,
      smsOptIn: ContactsFormOptionValue.Optional,
      contactOptIn: ContactsFormOptionValue.Required,
    },
    otherContacts: {
      ...DEFAULT_BRANDING_CONFIG.otherContacts,
      smsOptIn: ContactsFormOptionValue.Optional,
      contactOptIn: ContactsFormOptionValue.Required,
    },
  },
  [SubscriptionTypes.OwensCorningProCanada]: {
    ...DEFAULT_OC_BRANDING_CONFIG,
    rqpLeads: {
      ...DEFAULT_BRANDING_CONFIG.rqpLeads,
      smsOptIn: ContactsFormOptionValue.Optional,
      contactOptIn: ContactsFormOptionValue.Required,
    },
    otherContacts: {
      ...DEFAULT_BRANDING_CONFIG.otherContacts,
      smsOptIn: ContactsFormOptionValue.Optional,
      contactOptIn: ContactsFormOptionValue.Required,
    },
  },
};
