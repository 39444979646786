import { ASSETS } from 'global-constants';
import { fontsMap } from './constants';
import { FontSettings, S3FontWeightsPathInterface } from './types';

export const setupRQPFonts = (rqpFonts: FontSettings[]): FontFace[] => {
  const addedFonts: FontFace[] = [];

  rqpFonts.forEach(({ fontName, fontWeight = 400 }) => {
    const primaryFontName = fontName.split(',')[0].trim().replace(/'/g, '');
    const fontPath = getClosestFont(fontsMap[primaryFontName], fontWeight);

    const font = new FontFace(primaryFontName, `url(${ASSETS}fonts/${fontPath})`, {
      weight: `${fontWeight}`,
    });

    document.fonts.add(font);
    addedFonts.push(font);
  });

  return addedFonts;
};

export const getClosestFont = (
  fontWeights: S3FontWeightsPathInterface = {},
  weight: number,
): string | null => {
  let closestFont: string | null = null;
  let closestWeight = Infinity;

  for (const fontWeight in fontWeights) {
    if (Math.abs(+fontWeight - weight) < Math.abs(closestWeight - weight)) {
      closestFont = fontWeights[fontWeight];
      closestWeight = +fontWeight;
    }
  }

  return closestFont;
};

export const renderElementAfterFontsLoaded = (
  element: HTMLElement,
  fonts: FontFace[],
  display = 'block',
): void => {
  const fontsPromises = fonts.map(font => font.load());
  Promise.all(fontsPromises).finally(() => (element.style.display = display));
};
