import api from 'services/api';

import { Subscription, SubscriptionRequestType } from './types';

export const getSubscriptionsAPI = (userId: number): Promise<{ data: Subscription[] }> =>
  api.get(`user/${userId}/subscriptions`);

export const requestChangingSubscriptionStatusAPI = (
  userId: number,
  subscriptionId: number,
  body: { requestType: SubscriptionRequestType; reasons?: string[] },
) => api.post(`user/${userId}/request-changing-subscription-status/${subscriptionId}`, body);
