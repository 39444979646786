import { FormattedMessage } from 'react-intl';
import { I18nEnum } from 'types';

export const getLabel = (label: string | JSX.Element) => {
  if (typeof label !== 'string') {
    return label;
  }

  return Object.keys(I18nEnum).includes(label) ? <FormattedMessage id={label} /> : label;
};
