import { constants as widgetConstants, WidgetSettingsState } from 'modules/widget';
import { actions as quickQuoteConfigActions } from 'modules/quickQuoteConfig';
import { State } from 'types';
import { setMeasurementsOrderValuesInQQConfig } from 'modules/quickQuoteConfig/actions';

export const configureQuickQuoteConfig =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const state: State = getState();
    const currentConfig = state.quickQuoteConfig;
    const subscriptionConfig = state.subscription.config.defaultQuickQuoteConfigState;

    if (
      ![widgetConstants.SET_ALL_WIDGET_SETTINGS, widgetConstants.RESET_WIDGET_SETTINGS].includes(
        action.type,
      ) ||
      currentConfig.userId
    ) {
      return next(action);
    }

    if (action.type === widgetConstants.SET_ALL_WIDGET_SETTINGS) {
      const { branding } = action.settings as WidgetSettingsState;
      const isMeasurementsOrderEnabled = branding.measurementsOrder.isMeasurementsOrderEnabled;

      if (isMeasurementsOrderEnabled) {
        dispatch(setMeasurementsOrderValuesInQQConfig(currentConfig));
        return next(action);
      }
    }

    dispatch(
      quickQuoteConfigActions.update({
        productCard: subscriptionConfig.productCard,
        modals: subscriptionConfig.modals,
      }),
    );

    return next(action);
  };
