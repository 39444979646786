import { useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Form, FormInstance } from 'antd';
import cloneDeep from 'lodash/cloneDeep';

import { I18nEnum } from 'types';
import TextAreaBase from 'components/Inputs/TextArea';
import TextEditor from 'components/Inputs/TextEditor';
import TooltipWithInfoIcon from 'components/Tooltips/TooltipWithInfoIcon';
import InfoIcon from 'components/Icons/InfoIcon';
import SeeDefaultDesctiptionBase from './SeeDefaultDesctiption';
import AddSnippet from 'modules/widget/components/AddSnippet';
import { SnippetTypeEnum } from 'modules/snippet/types';
import { AdditionalCosts, WhatsIncluded } from 'modules/product/components/Elements';
import { Line } from 'modules/product/types';
import { DEFAULT_SEARCH } from 'modules/search/constants';
import { DEFAULT_QUICK_QUOTE } from 'modules/quickQuote/constants';
import { PastAddressesState, QuickQuoteState } from 'modules/quickQuote/types';
import { usePrices } from 'modules/hooks/usePrices';
import { FinancingSetting } from 'modules/financing';
import { FillColor } from 'modules/widget';
import { checkIsOwensCorningProduct } from '../WidgetProductCard/utils';

const EXAMPLE_SQUARE_FEET = 2000.15;

const quickQuote: QuickQuoteState = cloneDeep(DEFAULT_QUICK_QUOTE);
quickQuote.structures = [quickQuote.structures[0]];
quickQuote.structures[0].measurements.squareFeet = EXAMPLE_SQUARE_FEET;

export const EXAMPLE_QUICK_QUOTE_STATE: PastAddressesState = {
  address: DEFAULT_SEARCH.address,
  search: {
    addressFeature: DEFAULT_SEARCH.addressFeature,
  },
  quickQuote: quickQuote,
};

export const MonthlyPriceTooltip = ({
  className,
  financing,
  iconsConfig,
}: {
  className?: string;
  financing: FinancingSetting;
  iconsConfig?: FillColor;
}) => {
  const { percentageRate, months } = financing;
  return (
    <Tooltip
      tooltip={I18nEnum.MonthlyPriceBasedOn}
      values={{ percentageRate, months }}
      className={className}
      iconsConfig={iconsConfig}
      icon={<InfoIcon size={{ width: 16, height: 16 }} />}
    />
  );
};

const Tooltip = styled(TooltipWithInfoIcon)<{ iconsConfig?: FillColor }>`
  margin-left: 4px;

  .info-tooltip {
    width: auto;
    right: 0;
    left: auto;
    bottom: auto;
    top: 19px;
    transform: translateX(0);
    padding: 16px;
    white-space: nowrap;
    z-index: 10;
  }

  .info-icon {
    svg path {
      fill: ${props =>
        props.iconsConfig ? props.iconsConfig.fillColor : props.theme.colors.darkGrey};
    }
  }

  @media ${props => props.theme.mediaQueries.medium} {
    position: static;
    .info-tooltip {
      right: auto;
      left: 0;
    }
  }
`;

export interface CustomizationProps {
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  productName?: string;
  line?: Line | null;
  hideSaveSnippet?: boolean;
  placeholder?: string;
  className?: string;
  id?: string;
  maxLength?: number;
  minLength?: number;
  hasError?: boolean;
}

export const TextAreaWithCounter = ({
  value,
  onChange,
  placeholder,
  className,
  id,
  maxLength,
  minLength,
  hasError,
}: CustomizationProps) => {
  return (
    <EditableRowPart className={className}>
      <TextArea
        id={id}
        onChange={onChange}
        maxLength={maxLength}
        minLength={minLength}
        value={value}
        counter
        placeholder={placeholder}
        hasError={hasError}
      />
    </EditableRowPart>
  );
};

export const TopFeaturesCustomization = ({
  value,
  onChange,
  line,
  placeholder,
  className,
}: CustomizationProps) => {
  const [openedDefaultDescriptions, setOpenedDefaultDescriptions] = useState(false);
  const toggleOpenedDefaultDescriptions = useCallback(
    () => setOpenedDefaultDescriptions(!openedDefaultDescriptions),
    [openedDefaultDescriptions],
  );

  return (
    <EditableRowPart className={className}>
      {line && (
        <SeeDefaultDesctiption
          expanded={openedDefaultDescriptions}
          description={line.defaultTopFeatures}
          onClick={toggleOpenedDefaultDescriptions}
        />
      )}
      <TextArea
        id="topFeatures"
        onChange={onChange}
        maxLength={100}
        value={value}
        counter
        placeholder={placeholder}
      />
    </EditableRowPart>
  );
};

const EditableRowPart = styled.div`
  width: 100%;
`;

const TextArea = styled(TextAreaBase)`
  background-color: transparent;
`;

interface EditorCustomizationProps {
  value?: string;
  forceValue?: string;
  onChange?: (v: string) => void;
  productName?: string;
  line: Line | null;
  hideSaveSnippet?: boolean;
  placeholder?: string;
  cleanForceValue?: () => void;
}

export const WhatsIncludedCustomization = ({
  value,
  forceValue,
  onChange: _onChange,
  productName,
  line,
  hideSaveSnippet,
  placeholder,
  cleanForceValue,
}: EditorCustomizationProps) => {
  const [openedDefaultDescriptions, setOpenedDefaultDescriptions] = useState(false);

  const defaultDescription = useMemo(
    () =>
      line && checkIsOwensCorningProduct(line) && !!line.defaultWhatsIncluded ? (
        <div dangerouslySetInnerHTML={{ __html: line.defaultWhatsIncluded }} />
      ) : (
        <WhatsIncluded />
      ),
    [line],
  );

  const toggleOpenedDefaultDescriptions = useCallback(
    () => setOpenedDefaultDescriptions(!openedDefaultDescriptions),
    [openedDefaultDescriptions],
  );

  const onChange = useCallback(
    ({ html }) => {
      _onChange && _onChange(html);
    },
    [_onChange],
  );

  return (
    <>
      {!!line && (
        <SeeDefaultDesctiption
          expanded={openedDefaultDescriptions}
          description={defaultDescription}
          onClick={toggleOpenedDefaultDescriptions}
        />
      )}
      <TextEditor
        html={value}
        forceHTML={forceValue}
        onChange={onChange}
        placeholder={placeholder}
        cleanForceHTML={cleanForceValue}
      />
      <AddSnippet
        snippetValue={value}
        productName={productName}
        lineId={line?.id || 0}
        snippetType={SnippetTypeEnum.whatsIncluded}
        hideSave={hideSaveSnippet}
      />
    </>
  );
};

export const AdditionalCostsCustomization = ({
  value,
  forceValue,
  onChange: _onChange,
  productName,
  line,
  hideSaveSnippet,
  placeholder,
  cleanForceValue,
}: EditorCustomizationProps) => {
  const [openedDefaultDescriptions, setOpenedDefaultDescriptions] = useState(false);

  const defaultDescription = useMemo(
    () =>
      line && checkIsOwensCorningProduct(line) && line.defaultAdditionalCosts ? (
        <div dangerouslySetInnerHTML={{ __html: line.defaultAdditionalCosts }} />
      ) : (
        <AdditionalCosts />
      ),
    [line],
  );

  const toggleOpenedDefaultDescriptions = useCallback(
    () => setOpenedDefaultDescriptions(!openedDefaultDescriptions),
    [openedDefaultDescriptions],
  );

  const onChange = useCallback(
    ({ html }) => {
      _onChange && _onChange(html);
    },
    [_onChange],
  );

  return (
    <>
      {!!line && (
        <SeeDefaultDesctiption
          expanded={openedDefaultDescriptions}
          description={defaultDescription}
          onClick={toggleOpenedDefaultDescriptions}
        />
      )}
      <TextEditor
        html={value}
        forceHTML={forceValue}
        onChange={onChange}
        placeholder={placeholder}
        cleanForceHTML={cleanForceValue}
      />
      <AddSnippet
        snippetValue={value}
        productName={productName}
        lineId={line?.id || 0}
        snippetType={SnippetTypeEnum.additionalCosts}
        hideSave={hideSaveSnippet}
      />
    </>
  );
};

const SeeDefaultDesctiption = styled(SeeDefaultDesctiptionBase)`
  margin-bottom: 8px;
`;

export const InfoTip = styled.span<{ isEditMode?: boolean }>`
  display: block;
  font-size: 14px;
  line-height: 17px;
  color: ${props => props.theme.colors.grey};
  ${props => !props.isEditMode && 'text-align: right;'}
  margin-top: 8px;

  @media ${props => props.theme.mediaQueries.medium} {
    text-align: left;
  }
`;

export const InfoLabel = styled.p<{ isEditMode?: boolean }>`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.37px;
  margin-bottom: 8px;
`;

export interface PriceProps {
  price: {
    validValue: number;
    value: number | string;
  };
  fixedPrice: boolean;
  form: FormInstance<any>;
}

export const usePriceInfo = ({ price, fixedPrice, form }: PriceProps) => {
  const loanProductId = Form.useWatch('loanProductId', form);
  const includeMerchantFee = Form.useWatch('includeMerchantFee', form);

  const line = useMemo(
    () =>
      ({
        showTotal: false,
        showMonthly: true,
        loanProductId,
        includeMerchantFee,
      } as Line),
    [loanProductId, includeMerchantFee],
  );

  const prices = usePrices({
    sfPrice: Number(price.validValue),
    fixedPrice,
    state: EXAMPLE_QUICK_QUOTE_STATE,
    selectQQFinancing: false,
    line,
  });

  const priceInfo = useMemo(
    () => prices.generatePriceInfo({ includeMerchantFee: true, useAdditionalCostsFormula: false }),
    [prices],
  );

  return priceInfo;
};

export const FormItem = styled(Form.Item)`
  flex-direction: column;

  && .ant-form-item-label {
    padding-bottom: 0;

    & > label {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.32px;
      float: left;
      color: rgba(0, 0, 0, 0.8);

      &:after {
        content: none;
      }

      @media ${props => props.theme.mediaQueries.mediumXL} {
        margin-top: 24px;
      }
    }
  }

  .ant-form-item-control-input-content:fist-child {
    margin-right: 0;
  }

  .ant-form-item-control {
    margin-top: 8px;
  }
`;
