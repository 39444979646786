import { removeSpinner } from './utils';
import { QUICK_QUOTE_WIDGET_TYPES, sizes } from 'global-constants';
import {
  REMOVE_SPINNER_MESSAGE_TYPE,
  PREDICTION_COMPLETED_MESSAGE_TYPE,
} from 'modules/widget/constants/toolScript';
import { getCorrectDocumentRootElement } from 'utils/toolScript';

const slideOutIframeId = 'quick-quote-frame';
const embeddedIframeId = 'roof-quote-pro-embedded-frame';

export type LocationObserverType = {
  iframeId?: string;
  isPreview: boolean;
  userInfo?: Record<string, string>;
  settings?: Record<string, unknown>;
};

class LocationObserver {
  private href = document.location.href;

  private iframeId = slideOutIframeId;

  private isPreview = false;

  private userInfo: Record<string, string> | null = null;

  private settings: Record<string, unknown> = {};

  constructor({
    iframeId = undefined,
    isPreview,
    userInfo = undefined,
    settings,
  }: LocationObserverType) {
    this.isPreview = isPreview;

    if (iframeId) {
      this.iframeId = iframeId;
    }

    if (userInfo) {
      this.userInfo = userInfo;
    }

    if (settings) {
      this.settings = settings;
    }

    this.subscribeOnLoad();
  }

  sendOnLoadMessage() {
    const iframe = document.getElementById(this.iframeId) as HTMLIFrameElement;

    if (!iframe) {
      return;
    }

    setTimeout(() => {
      iframe.contentWindow?.postMessage(
        {
          type: 'onload',
          isPreview: this.isPreview,
          userInfo: this.userInfo,
          settings: this.settings,
          href: this.href,
          widgetType:
            this.iframeId === slideOutIframeId
              ? QUICK_QUOTE_WIDGET_TYPES.SlideOut
              : QUICK_QUOTE_WIDGET_TYPES.Embedded,
        },
        '*',
      );
    }, 0);
  }

  sendExternalUrl() {
    this.href = document.location.href;
    const iframe = document.getElementById(this.iframeId) as HTMLIFrameElement;

    if (!iframe) {
      return;
    }

    console.log(`send external url in post message: ${this.href}`);

    setTimeout(() => {
      iframe.contentWindow?.postMessage(
        {
          type: 'external-url',
          href: this.href,
        },
        '*',
      );
    }, 1500);
  }

  observeLocationChange(callback = () => {}) {
    const bodyList = document.querySelector('body');

    const observer = new MutationObserver(mutations => {
      mutations.forEach(() => {
        if (this.href != document.location.href) {
          this.sendExternalUrl();
        }
        callback();
      });
    });

    const config = {
      childList: true,
      subtree: true,
    };

    observer.observe(bodyList as Node, config);
  }

  setIframeHeight(height: number) {
    if (this.iframeId === slideOutIframeId) {
      return;
    }
    const iframe = document.getElementById(this.iframeId) as HTMLIFrameElement;

    const element = getCorrectDocumentRootElement();
    const correctHeight = element.clientWidth <= sizes.small ? element.clientHeight : height;

    iframe.style.height = `${correctHeight}px`;
  }

  scrollToTop() {
    const iframe = document.getElementById(embeddedIframeId);
    if (!iframe) {
      return;
    }

    const top = iframe.getBoundingClientRect().top + scrollY;
    window.scrollTo({
      top,
      behavior: 'smooth',
    });
  }

  sendParentSize() {
    const iframe = document.getElementById(this.iframeId) as HTMLIFrameElement | null;
    if (!iframe) return;

    const element = getCorrectDocumentRootElement();
    iframe.contentWindow?.postMessage(
      {
        type: 'send-parent-size',
        width: element.clientWidth,
        height: element.clientHeight,
      },
      '*',
    );
  }

  setPredictionCompleted(isPredictionCompleted: boolean) {
    document.body.dataset.isPredictionCompleted = isPredictionCompleted.toString();
  }

  subscribeOnLoad() {
    window.addEventListener('message', event => {
      switch (event.data.type) {
        case REMOVE_SPINNER_MESSAGE_TYPE:
          removeSpinner();
          break;
        case 'quick-quote-loaded':
          this.sendOnLoadMessage();
          break;
        case 'quick-quote-height':
          this.setIframeHeight(event.data.height);
          break;
        case 'quick-quote-scroll-to-top':
          this.scrollToTop();
          break;
        case 'quick-quote-get-parent-size':
          this.sendParentSize();
          break;
        case PREDICTION_COMPLETED_MESSAGE_TYPE:
          this.setPredictionCompleted(event.data.isPredictionCompleted);
          break;
        default:
          break;
      }
    });
  }
}

export default LocationObserver;
