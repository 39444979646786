import { initialQuery } from 'components/Table';
import { ActionType } from 'types';
import * as constants from './constants';
import { CompanyState } from './types';

const initialState: CompanyState = {
  company: null,
  users: [],
  usersLoaded: false,
  usersTotal: 0,
  usersQuery: initialQuery,
  quoteSettings: null,
};

const companyReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case constants.SET_COMPANY:
      return {
        ...state,
        company: action.company,
      };
    case constants.SET_COMPANY_USERS:
      return {
        ...state,
        users: action.users,
        primaryUser: action.primaryUser,
        usersTotal: action.total,
        usersLoaded: true,
      };
    case constants.SET_COMPANY_USERS_QUERY:
      return {
        ...state,
        usersQuery: action.query,
      };
    case constants.SET_COMPANY_QUOTE_SETTINGS:
      return {
        ...state,
        quoteSettings: action.quoteSettings,
      };
    case constants.CLEAR_COMPANY_QUOTE_SETTINGS: {
      return {
        ...state,
        quoteSettings: null,
      };
    }
    case constants.CLEAR_COMPANY:
      return initialState;
    default:
      return state;
  }
};

export default companyReducer;
