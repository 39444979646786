import api from 'services/api';
import { WidgetSettings } from './types';

export const getWidgetSettingsAPI = (userId: number) => api.get(`user/${userId}/widget/settings`);
export const updateWidgetSettingsAPI = (userId: number, settings: Partial<WidgetSettings>) =>
  api.patch(`user/${userId}/widget/settings`, settings);

export const getPublicWidgetSettingsAPI = (wildcard: string) =>
  api.get(`widget/${wildcard}/settings`);

export const getWidgetProductsAPI = (userId: number, marketSlug: string) =>
  api.get(`user/${userId}/widget/products/${marketSlug}`);

export const getPublicWidgetProducts = (wildcard: string, marketSlug: string) =>
  api.get(`widget/${wildcard}/products/${marketSlug}`);

export const getWidgetUserIpGeolocation = (wildcard: string) =>
  api.get(`widget/${wildcard}/ip-geolocation`);

export const resetToolsSettingsAPI = (userId: number) =>
  api.post(`user/${userId}/widget/reset-tools`);

export const uploadThankYouScreenMediaAPI = (
  userId: number,
  data: FormData,
  fileType: string,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  api.request({
    method: 'POST',
    url: `user/${userId}/widget/thank-you-media/${fileType}/upload`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 60000 * 10,
    onUploadProgress,
  });

export const getThankYouMediaAPI = (userId: number) =>
  api.get(`user/${userId}/widget/thank-you-media`);

export const getMarketAndStateForCenterpoint = (wildcard: string, centerpoint: [number, number]) =>
  api.get(`widget/${wildcard}/market`, { params: { centerpoint } });

export const getUserIdByEmail = (wildcard: string, email: string) =>
  api.get(`/widget/${wildcard}/user/email/${email}`);
