import { PriceConfig } from './priceConfig';

import {
  calculateStructuresAddedValues,
  combineAdditionalCostsByStructure,
  formatQuoteSettings,
} from './utils';
import { areAdditionalCostsEnabledInModel } from 'modules/quoteSettings/components/utils';

export class AdditionalCost {
  constructor(priceConfig: PriceConfig) {
    this.priceConfig = priceConfig;
  }

  private readonly priceConfig: PriceConfig;

  getCombinedByStructureAdditionalCosts(): Record<string, number> {
    const { includedStructures, productPriceSettings } = this.priceConfig;
    if (!productPriceSettings) {
      return {};
    }
    const { additionalCost, additionalCostSettings } = productPriceSettings;

    if (
      !additionalCost ||
      !additionalCostSettings ||
      !areAdditionalCostsEnabledInModel(additionalCostSettings)
    ) {
      return {};
    }

    const formattedAdditionalCostSettings = formatQuoteSettings(additionalCostSettings);
    const additionalCostsByStructure = calculateStructuresAddedValues({
      structures: includedStructures,
      additionalCost,
      additionalCostSettings: formattedAdditionalCostSettings,
    });

    return combineAdditionalCostsByStructure(additionalCostsByStructure);
  }

  calculateTotal(addedValues: Record<string, number>): number {
    const sum = Object.values(addedValues).reduce((acc, value) => acc + value, 0);
    return +sum.toFixed(2);
  }
}
