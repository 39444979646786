import axios from 'axios';

import { _setUser } from 'modules/auth/actions';
import { getAdminId, getAdminToken, getToken, setToken } from 'modules/auth/storage';
import { getUserFromTokenPayload } from 'modules/auth/utils';
import { GEODATA_SESSION_STORAGE_LABEL } from 'modules/widget/constants';
import store from 'store';
import { setCenterpointInQQStore } from 'utils';

const API_URL = process.env.REACT_APP_API_URL;

export let abortController = new AbortController();

const api = axios.create({
  baseURL: API_URL,
  timeout: 60000,
  withCredentials: true,
});

export const apiRoutes = axios.create({
  baseURL: '/api',
  timeout: 60000,
});

api.interceptors.response.use(
  response => {
    if (response.data.token && typeof window !== 'undefined') {
      const user = getUserFromTokenPayload(response.data.token);
      setToken(response.data.token);
      store.dispatch(_setUser(user));
    }

    if (response.headers.geodata) {
      try {
        sessionStorage.setItem(GEODATA_SESSION_STORAGE_LABEL, response.headers.geodata);
      } catch (error) {
        console.error(error);
      }
      setCenterpointInQQStore(response.headers.geodata);
    }

    return response.data;
  },
  error => {
    if (axios.isCancel(error)) {
      abortController = new AbortController();
      return Promise.reject('Request was canceled');
    }

    return Promise.reject(error.response && error.response.data ? error.response.data : error);
  },
);

api.interceptors.request.use(config => {
  const isCookieAvailable = typeof window !== 'undefined';

  const token = isCookieAvailable ? getToken() : '';
  const adminToken = isCookieAvailable ? getAdminToken() : '';
  const adminId = isCookieAvailable ? getAdminId() : '';

  if (!config.headers) {
    return config;
  }

  config.headers.Accept = '*/*';
  config.signal = abortController.signal;

  if (token) {
    config.headers['contractor-auth'] = token;
  }

  if (adminId && adminToken) {
    config.headers['admin-auth'] = adminToken;
    config.headers['admin-id'] = adminId;
  }

  return config;
});

export default api;
