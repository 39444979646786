import { Button as ButtonBase } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import styled, { css } from 'styled-components';

import { colors } from 'global-constants';

export const Button = styled(({ customType, ...rest }: ButtonProps & { customType: string }) => (
  <ButtonBase {...rest} />
))`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 6px;
  height: 56px;
  width: 255px;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.37px;
  font-weight: 400;
  text-transform: uppercase;
  text-shadow: unset;

  @media ${props => props.theme.mediaQueries.small} {
    height: 40px;
    font-size: 14px;
    letter-spacing: 0.29px;
    line-height: 17px;
  }

  ${props =>
    props.customType === 'default' &&
    `
    border: 1px solid rgba(36,75,90,0.58);
    
    & {
      background-color: ${colors.white};
      color: ${colors.blue};
    }

    &:hover,
    &:active,
    &:focus {
      background-color: ${colors.white};
      border: 1px solid rgba(36,75,90,0.9);
      color: #163C4A;
    }

    &:disabled, &:disabled:hover  {
      pointer-events: none;
      opacity: 0.5;
      background-color: ${colors.white};
      color: ${colors.blue};
    }
      
  `}

  ${props =>
    props.customType === 'primary' &&
    css`
      background-color: ${colors.orange};

      &:hover,
      &:active,
      &:focus {
        background-color: ${colors.darkOrange};
        color: ${colors.white};
      }

      &:disabled,
      &:disabled:hover {
        pointer-events: none;
        background-color: #f4ba9d;
        color: ${colors.white};
      }
    `}

  ${props =>
    props.customType === 'ghost' &&
    `
      &,
      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
        border: none;
        color: ${colors.blue};
        text-decoration: underline;
        
        width: auto;
        height: auto;
        text-transform: none;
        box-shadow: none;

        span {
          line-height: 22px;
        }
      }

      &.ant-btn:not([disabled]):hover {
        text-decoration: underline;
      }

      & > div {
        justify-content: flex-start;
      }
      &:hover {
        color: ${colors.darkBlue};
      }
  `}

  ${props =>
    props.customType === 'light-outlined' &&
    `
      background-color: transparent;
      border: 1px solid ${props.theme.colors.white}cc;
      color: ${props.theme.colors.white}cc;

      &:hover,
      &:active,
      &:focus {
        background-color: ${props.theme.colors.white}1a;
        border: 1px solid ${props.theme.colors.white};
        color: ${props.theme.colors.white};
      }
  `}

  &[data-whatinput='keyboard']:focus {
    box-shadow: 0 0px 0px 2px ${colors.darkCyan};
    box-sizing: border-box;
  }
`;

export const Wrapper = styled.div<{ withIcon?: boolean }>`
  display: flex;
  align-items: center;
`;

export const PrefixWrapper = styled.div`
  margin: 0 15px 0 0;
`;

export const PostfixWrapper = styled.div`
  margin: 0 0 0 15px;
`;
