import styled, { css } from 'styled-components';

export interface Props {
  children: React.ReactNode;
  size?: { height?: number; width?: number };
  tabIndex?: number;
  className?: string;
  id?: string;
  onClick?: (e: React.MouseEvent<any>) => void;
  onKeyUp?: (e: React.KeyboardEvent<any>) => void;
  // [k: string]: any;
}

const IconRestorer = ({ isLink, children, ...props }: Props & { isLink?: boolean }) =>
  isLink ? (
    <A {...props}>
      <RelativeContainer>{children}</RelativeContainer>
    </A>
  ) : (
    <Span {...props}>
      <RelativeContainer>{children}</RelativeContainer>
    </Span>
  );

const styles = css<Props>`
  display: block;
  width: ${props => props.size?.width || 20}px;
  height: ${props => props.size?.height || 20}px;
  --icon-coefficient: 4;

  img {
    height: calc(100% * var(--icon-coefficient));
    width: calc(100% * var(--icon-coefficient));
    vertical-align: middle;
    transform-origin: 0 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% / var(--icon-coefficient)), calc(-50% / var(--icon-coefficient)))
      scale(calc(1 / var(--icon-coefficient)));
  }
`;

const RelativeContainer = styled.span`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
`;

const Span = styled.span<Props>`
  ${styles}
`;

const A = styled.a<Props>`
  ${styles}
`;

export default IconRestorer;
