import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useDeepCompareEffect from './useDeepCompareEffect';
import {
  actions as widgetActions,
  selectors as widgetSelectors,
  BrandingConfig,
} from 'modules/widget';
import { selectors as companySelectors } from 'modules/company';
import { actions as globalActions, AnalyticsEvent } from 'modules/global';
import { selectors as modalSelectors } from 'modules/modal';
import useQuickQuoteQueryParams from 'modules/quickQuote/hooks/useQuickQuoteQueryParams';
import {
  REMOVE_SPINNER_MESSAGE_TYPE,
  PREDICTION_COMPLETED_MESSAGE_TYPE,
} from 'modules/widget/constants';
import { actions as subscriptionActions, SubscriptionConfig } from 'modules/subscription';
import { actions as quickQuoteConfigActions } from 'modules/quickQuoteConfig';
import { awaitTimeout, isSafari } from 'utils';

const externalUrl = 'external-url' as const;
const onLoad = 'onload' as const;
const settingsChanged = 'settings-changed' as const;
const sendParentSize = 'send-parent-size' as const;
const sendParentGeolocation = 'send-parent-geolocation' as const;
let onLoadSent = false;
let isRemoveSpinnerSent = false;

export type ExternalUrlType = {
  type: typeof externalUrl;
  href: string;
};

export type OnLoadType = Pick<SettingsChangedType, 'settings'> & {
  type: typeof onLoad;
  isPreview: boolean;
  userInfo: Record<string, string>;
  settings: Partial<BrandingConfig>;
  subscriptionConfig: SubscriptionConfig;
  href: string;
  widgetType: string;
};

export type SettingsChangedType = {
  type: typeof settingsChanged;
  settings: Partial<BrandingConfig> & Record<string, any>;
};

type GetParentWithType = {
  type: typeof sendParentSize;
  width: number;
  height: number;
};

type GetParentGeolocation = {
  type: typeof sendParentGeolocation;
  longitude: number;
  latitude: number;
};

export const messageTypes = [
  externalUrl,
  onLoad,
  settingsChanged,
  sendParentSize,
  sendParentGeolocation,
] as const;
export type Type = typeof messageTypes[number];

export const useReceiveExternalLinkMessage = () => {
  const data = usePostMessage<ExternalUrlType>(['external-url']);
  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    if (!data) {
      return;
    }

    console.log(`receive external url from post message: ${data.href}`);
    dispatch(widgetActions.setExternalUrl(data.href));
  }, [data]);
};

export const useReceiveOnLoadMessage = (isSalesIdLoaded: boolean, isDemo?: boolean) => {
  const data = usePostMessage<OnLoadType>([onLoad]);
  const dispatch = useDispatch();

  const company = useSelector(companySelectors.selectCompany);
  const { loaded } = useSelector(widgetSelectors.selectWidget);
  const [iframeData, setIframeData] = useState<
    undefined | { isPreview: boolean; externalUrl: string }
  >();

  const isSalesRabbitUrl = useQuickQuoteQueryParams(iframeData?.externalUrl);

  useDeepCompareEffect(() => {
    if (!data) {
      return;
    }

    console.log(
      `receive external url from post message: ${data.href}, isPreview: ${
        data.isPreview
      }, settings: ${JSON.stringify(data.settings)}`,
    );

    dispatch(widgetActions.setIframePreview(data.isPreview));
    dispatch(widgetActions.setExternalUrl(data.href));

    if (data.subscriptionConfig) {
      dispatch(subscriptionActions.setSubscriptionConfig(data.subscriptionConfig));
      dispatch(quickQuoteConfigActions.reset());
    }

    const { userInfo, ...brandingConfig } = data.settings;

    data.userInfo && dispatch(widgetActions.setUserInfo(data.userInfo));
    brandingConfig && dispatch(widgetActions.setWidgetSettings(brandingConfig));

    document.body.dataset.widgetType = data.widgetType;

    setIframeData({
      isPreview: data.isPreview,
      externalUrl: data.href,
    });
  }, [data]);

  useEffect(() => {
    if (!iframeData || iframeData.isPreview || isSalesRabbitUrl === undefined) {
      return;
    }
    if (
      !isDemo &&
      company &&
      !company.subscriptionPaused &&
      company.subscriptionActive &&
      loaded &&
      isSalesIdLoaded
    ) {
      dispatch(
        globalActions.trackWidgetAnalytics({ widgetAction: { event: AnalyticsEvent.qqOpened } }),
      );
    }
  }, [dispatch, isDemo, company, loaded, iframeData, isSalesRabbitUrl, isSalesIdLoaded]);
};

export const useReceiveSettingsChangedMessage = () => {
  const data = usePostMessage<SettingsChangedType>([settingsChanged]);
  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    if (!data) {
      return;
    }

    console.log(`receive settings changed message: ${JSON.stringify(data.settings)}`);
    if (data.settings) {
      const { ...brandingConfig } = data.settings;

      dispatch(widgetActions.setWidgetSettings(brandingConfig));
    }
  }, [data]);
};

export const sendPostMessage = (message: {
  type: string;
  [k: string]: string | number | boolean | undefined;
}) => {
  window.parent.postMessage(message, '*');
};

export const useRemoveSpinnerMessage = () => {
  if (!isRemoveSpinnerSent) {
    sendPostMessage({ type: REMOVE_SPINNER_MESSAGE_TYPE });
    isRemoveSpinnerSent = true;
  }
};

export const useSendOnloadMessage = () => {
  if (!onLoadSent) {
    setTimeout(() => sendPostMessage({ type: 'quick-quote-loaded' }), 1000);
    onLoadSent = true;
  }
};

export const useSendHeightMessage = (_height: number, isQQ = false) => {
  const opened = useSelector(modalSelectors.selectOpened);
  const [height, setHeight] = useState(0);
  const [lastQQHeight, setLastQQHeight] = useState(0);

  useEffect(() => {
    if (height < 500) {
      return;
    }

    sendPostMessage({
      type: 'quick-quote-height',
      height,
    });
  }, [height, opened]);

  useEffect(() => {
    if (isQQ) {
      setLastQQHeight(_height);
    }
  }, [_height, isQQ]);

  useEffect(() => {
    setHeight(!opened ? lastQQHeight : _height);
  }, [_height, lastQQHeight, opened]);
};

export const useScrollToTop = () => async () => {
  //need to await for Safari to avoid incorrect widget position - HWCR-3603, HWCR-3792
  if (isSafari()) {
    await awaitTimeout(300);
  }

  sendPostMessage({
    type: 'quick-quote-scroll-to-top',
  });
};

export const useQuickQuotePostMessages = ({
  isDemo,
  height,
  isSalesIdLoaded,
}: {
  isDemo?: boolean;
  height: number;
  isSalesIdLoaded: boolean;
}) => {
  useRemoveSpinnerMessage();
  useSendOnloadMessage();
  useReceiveOnLoadMessage(isSalesIdLoaded, isDemo);
  useReceiveExternalLinkMessage();
  useReceiveSettingsChangedMessage();
  useSendHeightMessage(height, true);
};

export const useGetIframeParentSize = () => () => {
  sendPostMessage({
    type: 'quick-quote-get-parent-size',
  });
};

export const useReceiveParentSize = () => {
  const data = usePostMessage<GetParentWithType>([sendParentSize]);
  if (!data) return;

  return { width: data.width, height: data.height };
};

export const useReceiveParentGeolocation = () => {
  const data = usePostMessage<GetParentGeolocation>([sendParentGeolocation]);
  if (!data || !data.latitude || !data.longitude) return;

  return [+data.longitude, +data.latitude] as [number, number];
};

const usePostMessage = <R,>(types: Type[]) => {
  const [data, setData] = useState<R>();

  const listenPostMessages = useCallback(() => {
    window.addEventListener(
      'message',
      event => {
        if (types.includes(event.data.type)) {
          setData(event.data);
        }
      },
      {},
    );
  }, [types]);

  useEffect(() => {
    listenPostMessages();

    return () => {
      window.removeEventListener('message', listenPostMessages);
    };
  }, [listenPostMessages]);

  return data;
};

export const sendPredictionCompletedPostMessage = (isPredictionCompleted: boolean) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  if (urlParams.get('auto') === 'true') {
    sendPostMessage({
      type: PREDICTION_COMPLETED_MESSAGE_TYPE,
      isPredictionCompleted,
    });
  }
};

export default usePostMessage;
