export const street_number_type = 'street_number' as const;
export const route_type = 'route' as const;
export const locality_type = 'locality' as const;
export const state_type = 'administrative_area_level_1' as const;
export const postal_code_type = 'postal_code' as const;
export const country_type = 'country' as const;

const ADDRESS_FIELDS = [
  street_number_type,
  route_type,
  locality_type,
  state_type,
  postal_code_type,
  country_type,
];

const REQUIRED_ADDRESS_FIELDS = [postal_code_type];

export const validateAddressComponents = (
  details: google.maps.GeocoderAddressComponent[],
): boolean => {
  return REQUIRED_ADDRESS_FIELDS.every(field => details.some(({ types }) => types.includes(field)));
};

export const formAddress = (details: google.maps.GeocoderAddressComponent[]) => {
  const fieldsMap = details.reduce(
    (map: Record<string, google.maps.GeocoderAddressComponent>, item) => {
      const matchedField = ADDRESS_FIELDS.find(field => item.types.includes(field));
      if (!matchedField) {
        return map;
      }

      map[matchedField] = item;
      return map;
    },
    {},
  );

  const streetNumber = fieldsMap[street_number_type]?.long_name || '';
  const route = fieldsMap[route_type]?.long_name || '';
  const locality = fieldsMap[locality_type]?.long_name || '';
  const state = fieldsMap[state_type]?.long_name || '';
  const postal_code = fieldsMap[postal_code_type]?.long_name || '';
  const country = fieldsMap[country_type]?.long_name || '';

  const block1 = [route, locality, state].filter(v => v).join(', ');
  const block2 = [postal_code, country].filter(v => v).join(', ');
  return [streetNumber, block1, block2].filter(v => v).join(' ');
};
