import { useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ButtonHTMLType } from 'antd/lib/button/button';

import { I18nEnum } from 'types';

interface Props {
  title: string | JSX.Element;
  className?: string;
  tabIndex?: number;
  href?: string;
  target?: string;
  htmlType?: ButtonHTMLType;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<any>) => void;
}

const GhostButton = ({ title, href, target, htmlType, ...props }: Props) => {
  const intl = useIntl();
  const _title = useMemo(
    () =>
      typeof title === 'string' && Object.keys(I18nEnum).includes(title)
        ? intl.formatMessage({ id: title })
        : title,
    [intl, title],
  );

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      if (!props.disabled) {
        props.onClick && props.onClick(event);
        return;
      }
      // The styles for a disabled state is overwritten. A user can click the disabled button.
      // It is necessary to display a tooltip for the disabled state.
      event.stopPropagation();
      event.preventDefault();
    },
    [props],
  );

  return href ? (
    <A to={href} target={target} {...props} onClick={handleClick}>
      {_title}
    </A>
  ) : (
    <Button type={htmlType} {...props} onClick={handleClick}>
      {_title}
    </Button>
  );
};

export const GhostButtonStyle = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 40px;
  padding: 0 16px;
  border: none;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2px;
  color: ${props => props.theme.colors.blue};
  text-transform: uppercase;
  text-align: left;
  &:hover {
    background-color: rgba(36, 76, 90, 0.2);
  }
  &:disabled {
    opacity: 0.65;
    cursor: default;
  }
`;

const Button = styled.button`
  ${GhostButtonStyle}
`;

const A = styled(Link)`
  && {
    ${GhostButtonStyle}
  }
`;

export default GhostButton;
