import { StructureWasteFactorPartialSuggested } from 'modules/dashboard/types';
import { ColoredLine } from 'modules/product';
import { MLWasteFactor, QuickQuoteUserInfo, WasteFactorTypeEnum } from 'modules/quickQuote';
import { AddAdditionalCostsFormValues, QuoteSettingsModel } from 'modules/quoteSettings/types';
import { CompanyMarket } from 'modules/markets/types';
import { OptionType } from 'components/Inputs/Select/types';

export interface RepQuotesState {
  userWidgetSettings: UserWidgetSetting;
  selectedColoredLines: { [key: string]: ColoredLine };
  leadSessionId: string;
}

export const CustomSlopes = {
  '0/12': {
    angle: 0,
    multiplier: 1,
  },
  '1/12': {
    angle: 4.8,
    multiplier: 1.003,
  },
  '2/12': {
    angle: 9.5,
    multiplier: 1.014,
  },
  '3/12': {
    angle: 14,
    multiplier: 1.031,
  },
  '4/12': {
    angle: 18.4,
    multiplier: 1.054,
  },
  '5/12': {
    angle: 22.6,
    multiplier: 1.083,
  },
  '6/12': {
    angle: 26.6,
    multiplier: 1.118,
  },
  '7/12': {
    angle: 30.3,
    multiplier: 1.158,
  },
  '8/12': {
    angle: 33.7,
    multiplier: 1.202,
  },
  '9/12': {
    angle: 36.9,
    multiplier: 1.25,
  },
  '10/12': {
    angle: 39.8,
    multiplier: 1.302,
  },
  '11/12': {
    angle: 42.5,
    multiplier: 1.357,
  },
  '12/12': {
    angle: 45,
    multiplier: 1.414,
  },
  '13/12': {
    angle: 47.3,
    multiplier: 1.474,
  },
  '14/12': {
    angle: 49.4,
    multiplier: 1.537,
  },
  '15/12': {
    angle: 51.3,
    multiplier: 1.601,
  },
  '16/12': {
    angle: 53.1,
    multiplier: 1.667,
  },
  '17/12': {
    angle: 54.8,
    multiplier: 1.734,
  },
  '18/12': {
    angle: 56.3,
    multiplier: 1.803,
  },
} as const;

export type CustomSlopeType = keyof typeof CustomSlopes;

export interface UserWidgetSetting {
  commentTitle: string;
  commentText: string;
  saveLeadInfo: boolean;
}

export const AdditionalInformationType = {
  HomeownerDetails: 'HomeownerDetails',
  Market: 'Market',
  TotalSqFt: 'TotalSqFt',
  AdditionalCost: 'AdditionalCost',
  WasteFactor: 'WasteFactor',
  PriceRangeEnable: 'PriceRangeEnabled',
  Discount: 'Discount',
} as const;

export type AdditionalInformationTypes = keyof typeof AdditionalInformationType;

export enum SquareFeetEnum {
  InstantQuote = 'InstantQuote',
  Custom = 'Custom',
  // This types will be added in next release
  // GAFMeasurements = 'GAFMeasurements',
  // EagleViewPremium = 'EagleViewPremium',
  // EagleViewBidPerfect = 'EagleViewBidPerfect',
}

export type SquareFeetType = {
  type: SquareFeetEnum;
  value: Record<string, number>;
};

export type AdditionalInfoWasteFactorType = {
  usedWFType: WasteFactorTypeEnum;
  custom?: number;
  default?: number;
  suggested?: Partial<MLWasteFactor>;
};

export enum DiscountUnitOfMeasurement {
  Percentage = 'percentage',
  FixedPrice = 'fixedPrice',
}

export interface DiscountModel {
  unit: DiscountUnitOfMeasurement;
  type: string;
  value: string;
}

export interface DiscountFormValues {
  useDiscount: boolean;
  discounts: DiscountModel[];
}

export interface DiscountSettings {
  discounts: DiscountModel[];
}

export interface AdditionalCostValues {
  [key: number | string]: AddAdditionalCostsFormValues;
}

export interface AdditionalCostFormValues {
  additionalCost: AdditionalCostValues;
  useAdditionalCost: boolean;
}

export interface StructureWasteFactorData {
  id: string | number;
  structureName: string;
  wasteFactor?: StructureWasteFactorPartialSuggested;
}

export interface QuickQuoteProductPriceSettings {
  squareFeet?: SquareFeetType;
  additionalCost?: {
    [key: number | string]: AddAdditionalCostsFormValues;
  };
  additionalCostSettings?: QuoteSettingsModel;
  wasteFactor?: Record<string, AdditionalInfoWasteFactorType>;
  priceRangeEnabled?: boolean;
  discount?: DiscountSettings;
}

export interface ChangesType {
  userInfo?: QuickQuoteUserInfo;
  market?: CompanyMarket;
  squareFeet?: SquareFeetType;
  additionalCost?: AdditionalCostValues | null;
  additionalCostEnabled?: boolean;
  wasteFactor?: Record<string, AdditionalInfoWasteFactorType>;
  priceRangeEnabled?: boolean;
  additionalCostSettings?: QuoteSettingsModel | null;
  discount?: DiscountSettings;
  discountEnabled?: boolean;
  filteredDiscount?: DiscountSettings | null;
}

export type AdditionalCostData = Pick<ChangesType, 'additionalCost' | 'additionalCostSettings'>;
export type TotalSqFtData = Pick<ChangesType, 'squareFeet'>;

export enum AdditionalCostUpdateType {
  None = 1,
  Removed,
  Added,
  Changed,
}

export interface AdditionalCostUpdate {
  key: string;
  currentValue: number;
  updatedValue: number;
  updateType: AdditionalCostUpdateType;
}

export interface AdditionalCostAddedValuesWithUpdates {
  [key: number]: { option: OptionType; update: AdditionalCostUpdate }[];
}

export enum AdditionalCostVariant {
  Current = 1,
  New,
}

export interface SharedAdditionalInformationFormValues {
  [AdditionalInformationType.AdditionalCost]?: AdditionalCostData | null;
  [AdditionalInformationType.TotalSqFt]?: TotalSqFtData | null;
}

export interface AdditionalInfoWarningDetails {
  [AdditionalInformationType.AdditionalCost]?: {
    updatesAvailable: boolean;
    useAdditionalCosts: boolean;
  };
  [AdditionalInformationType.Discount]?: {
    updatesAvailable: boolean;
    useAdditionalCosts: boolean;
  };
}

export enum FieldAffectingStateOfProducts {
  price = 'price',
  fixedPrice = 'fixedPrice',
  showMonthly = 'showMonthly',
  showTotal = 'showTotal',
  loanProductId = 'loanProductId',
  includeMerchantFee = 'includeMerchantFee',
  useInstantQuoteSettings = 'useInstantQuoteSettings',
  steepSlopeChargeEnabled = 'steepSlopeChargeEnabled',
  steepSlopeChargeType = 'steepSlopeChargeType',
  steepSlopeChargeValue = 'steepSlopeChargeValue',
  wasteFactorEnabled = 'wasteFactorEnabled',
  wasteFactorValue = 'wasteFactorValue',
  priceRangeEnabled = 'priceRangeEnabled',
  priceRangeType = 'priceRangeType',
  priceRangeValue = 'priceRangeValue',
  addAdditionalCosts = 'addAdditionalCosts',
}

export type LeadUnsavedChanges = ChangesType & {
  mapChanged?: boolean;
  structuresChanged?: boolean;
  productsChanged?: boolean;
};
