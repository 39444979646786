import { Feature, MultiPolygon, Polygon } from 'geojson';
import html2canvas from 'html2canvas';

import store from 'store';

import { GeoJsonPolygon } from 'modules/quickQuote';
import { selectWidgetIsDemo } from 'modules/widget/selectors';
import { googleMapsInstance } from 'modules/googleMaps/classes/GoogleMapsInstance';
import { getInitialStructures } from 'modules/mapbox/utils';
import { STRUCTURES_LIMIT, screenshotSettings } from 'modules/quickQuote/constants';

import { awaitTimeout } from 'utils';
import { I18nEnum } from 'types';
import { googleMapId } from './constants';
import { getCompressedScreenshot } from 'modules/quickQuote/utils';
import { selectQuickQuote } from 'modules/quickQuote/selectors';

export async function googleMapsCreateMap({ centerpoint }: { centerpoint: [number, number] }) {
  const state = store.getState();
  const isDemo = selectWidgetIsDemo(state);

  const { structureMetaData, parcel, structures: persistedStructures } = selectQuickQuote(state);

  const { mapboxGoogleMapsInstance } = await import(
    'modules/mapbox/classes/MapboxGoogleMapsInstance'
  );

  // note the mapbox map can be called with a persisted parcel or null for an empty map
  const mapboxMap = await mapboxGoogleMapsInstance.createOrUpdateMap({ centerpoint, parcel });
  googleMapsInstance.createOrUpdateMap({ centerpoint, isDemo });

  if (!parcel) {
    return {
      initialStructures: [],
      mapboxMap,
    };
  }

  if (process.env.REACT_APP_IS_PARCEL_LAYER_VISIBLE === '1') {
    googleMapsInstance.addParcelToMap(parcel);
  }

  // rep quotes and browser storage can persist structures
  const initialStructures = persistedStructures.length
    ? persistedStructures
    : await getInitialStructures({
        mapboxMap,
        parcel,
        structureMetaData,
      });

  if (initialStructures.length > 0 && initialStructures.length <= STRUCTURES_LIMIT) {
    // render structures if we have them
    googleMapsInstance.roofleStructures = initialStructures;
    googleMapsInstance.renderRoofleStructures();
  } else {
    // otherwise set a zoom for the user to draw structures
    googleMapsInstance.setInitialParcelBounds(parcel);
    googleMapsInstance.map?.setZoom(19);
  }

  return { initialStructures, mapboxMap };
}

export async function getGoogleMapsClearScreenshotAndBounds() {
  if (!googleMapsInstance.map) {
    return { bounds: null, screenshot: null };
  }

  // give the slideout time to animate
  await awaitTimeout(450);

  googleMapsHideAllLayers();

  // give google maps time to re-render
  await awaitTimeout(100);

  const { bounds, screenshot } = await getGoogleMapsScreenshot();

  googleMapsShowAllLayers();

  return { bounds, screenshot };
}

export async function getGoogleMapsScreenshotOnlyPolygons() {
  if (!googleMapsInstance.map) {
    return { bounds: null, screenshot: null };
  }

  // give the slideout form time to animate
  await awaitTimeout(450);

  hideGoogleMapsNonPolygons();

  // give google maps time to re-render
  await awaitTimeout(100);

  const { bounds, screenshot } = await getGoogleMapsScreenshot();

  showGoogleMapsNonPolygons();

  return { bounds, screenshot };
}

const emptyScreenshot = {
  bounds: null,
  screenshot: null,
};

async function getGoogleMapsScreenshot() {
  const map = googleMapsInstance.map;
  if (!map) return emptyScreenshot;

  const mapContainer = map.getDiv();
  if (!mapContainer) return emptyScreenshot;

  await applyGoogleMapsLogoSvgHack();

  if (mapContainer.clientWidth === screenshotSettings.width) {
    await fitToStructuresGoogleMap();

    const bounds = map.getBounds()?.toJSON() || null;
    const canvas = await html2canvas(mapContainer, { useCORS: true });
    const screenshot = getCompressedScreenshot(canvas);
    return { bounds, screenshot };
  }

  // resize the responsive map to desktop size
  const oldStyle = mapContainer.getAttribute('style');
  mapContainer.style.width = `${screenshotSettings.width}px`;
  mapContainer.style.height = `${screenshotSettings.height}px`;

  await fitToStructuresGoogleMap();

  const bounds = map.getBounds()?.toJSON() || null;
  const canvas = await html2canvas(mapContainer, { useCORS: true });
  const screenshot = getCompressedScreenshot(canvas);

  // restore the responsive map size
  mapContainer.setAttribute(
    'style',
    oldStyle || 'height: 70vh; z-index: 0; position: relative; overflow: hidden;',
  );
  googleMapsInstance.fitToStructureBounds(true);

  return { bounds, screenshot };
}

async function fitToStructuresGoogleMap() {
  googleMapsInstance.fitToStructureBounds(true);

  // give google maps time to redraw
  await awaitTimeout(400);
}

// fix html2canvas svg image scaling when svg includes `viewBox`
// https://github.com/niklasvh/html2canvas/issues/1803
async function applyGoogleMapsLogoSvgHack() {
  const googleLogo = document.querySelector(
    `#${googleMapId} a[title*="Open this area in Google Maps"] img`,
  ) as HTMLImageElement;

  // one time, add width & height attributes, kill the viewBox attribute
  if (googleLogo && !googleLogo.src.includes('_viewBox')) {
    googleLogo.src = googleLogo.src.replace(
      /viewBox/,
      'width%3D%2266%22%20height%3D%2226%22%20_viewBox',
    );

    // allow new image src to re-render
    await awaitTimeout(10);
  }
}

function googleMapsHideAllLayers() {
  googleMapsInstance.polygons.forEach(polygon => {
    polygon.setMap(null);
  });

  hideGoogleMapsNonPolygons();
}

function googleMapsShowAllLayers() {
  googleMapsInstance.polygons.forEach(polygon => {
    polygon.setMap(googleMapsInstance.map);
  });

  showGoogleMapsNonPolygons();
}

export function hideGoogleMapsNonPolygons() {
  googleMapsInstance.infoWindows.forEach(infoWindow => {
    infoWindow.close();
  });

  if (googleMapsInstance.zoomWrapper) {
    googleMapsInstance.zoomWrapper.style.display = 'none';
  }
}

export function showGoogleMapsNonPolygons() {
  googleMapsInstance.infoWindows.forEach(infoWindow => {
    infoWindow.open(googleMapsInstance.map);
  });

  if (googleMapsInstance.zoomWrapper) {
    googleMapsInstance.zoomWrapper.style.display = 'block';
  }
}

export function googleMapsPolygonToGeoJson({
  polygon,
  id,
  properties = {},
}: {
  polygon: google.maps.Polygon;
  id: string | number;
  properties?: object;
}): GeoJsonPolygon {
  const geometry: Polygon = {
    type: 'Polygon' as const,
    coordinates: [],
  };

  const coords = polygon
    .getPath()
    .getArray()
    .map(el => {
      return [el.lng(), el.lat()];
    });

  geometry.coordinates = [coords];

  const feature: Feature<Polygon | MultiPolygon> = {
    geometry,
    properties,
    id,
    type: 'Feature',
  };

  return feature;
}

export function geojsonToGoogleMapsPaths(geometry: Polygon): google.maps.LatLngLiteral[] {
  const paths = geometry.coordinates[0].map((coord: number[]) => {
    return { lng: coord[0], lat: coord[1] };
  });

  return paths;
}

export function getDrawPolygonHelpText(count: number) {
  if (count === 0) {
    return I18nEnum.MapDrawShapeHelpText;
  } else if (count > 0 && count < 4) {
    return I18nEnum.MapDrawShapeContinueHelpText;
  } else if (count >= 4) {
    return I18nEnum.MapDrawShapeCloseHelpText;
  }

  return '';
}
