import * as constants from './constants';
import { ThunkResult } from 'types';

export const open = (messageType: string, text: string | string[]) => ({
  type: constants.OPEN,
  messageType,
  text,
});

export const openMessage =
  (messageType: string, text: string | string[]): ThunkResult<void> =>
  dispatch => {
    dispatch(open(messageType, text));
    setTimeout(() => {
      dispatch(closeMessage());
    }, 3000);
  };

export const closeMessage = () => ({
  type: constants.CLOSE,
});
