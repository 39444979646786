import api from 'services/api';
import { ListQuery } from 'types';
import { Company, UpsertCompanyUserBody, Analytics } from './types';

export const getCompanyAPI = (userId: number) => api.get(`user/${userId}/company`);

export const updateCompanyApi = (userId: number, company: Company) =>
  api.post(`/user/${userId}/company`, company);

export const getCompanyUsersAPI = (userId: number, params?: ListQuery) =>
  api.get(`user/${userId}/company/users`, { params });

export const changeUserStatusAPI = (userId: number, patchUserId: number, status: string) =>
  api.patch(`user/${userId}/company/change-user-status`, { status, patchUserId });

export const resendUserPasswordAPI = (userId: number, salesId: number) =>
  api.get(`user/${userId}/company/resend-password/${salesId}`);

export const deleteCompanyUserAPI = (userId: number, salesId: number) =>
  api.delete(`user/${userId}/company/user/${salesId}`);

export const upsertCompanyUserAPI = (userId: number, body: UpsertCompanyUserBody) =>
  api.post(`user/${userId}/company/user`, body);

export const sendDeletionRequestApi = (userId: number) =>
  api.post(`/user/${userId}/company/request-deletion`, {});

export const trackAnalyticsAPI = (companyId: number, action: Analytics) =>
  api.post(`/company/${companyId}/analytics`, action);

export const hasPrimaryUserAPI = (userId: number) =>
  api.get(`user/${userId}/company/has-primary-user`);

export const getUserOptions = (userId: number) => api.get(`user/${userId}/company/user-options`);

export const getCompanySmtpServerAPI = (userId: number) =>
  api.get(`user/${userId}/company/smtp-server`);
