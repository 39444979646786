import { I18nEnum } from 'types';

export interface Account {
  id: string;
  object: string;
  state: string;
  hostedLoginToken: string;
  hasLiveSubscription: boolean;
  hasActiveSubscription: boolean;
  hasFutureSubscription: boolean;
  hasCanceledSubscription: boolean;
  hasPausedSubscription: boolean;
  hasPastDueInvoice: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  code: string;
  username: string;
  email: string;
  overrideBusinessEntityId: string;
  preferredLocale: string;
  preferredTimeZone: string;
  ccEmails: string;
  firstName: string;
  lastName: string;
  company: string;
  vatNumber: string;
  taxExempt: boolean;
  exemptionCertificate: string;
  parentAccountId: string;
  billTo: string;
  dunningCampaignId: string;
  invoiceTemplateId: string;
  address: Address;
  billingInfo: BillingInfo | null;
}

export interface BillingInfo {
  id: string;
  object: string;
  accountId: string;
  firstName: string;
  lastName: string;
  company: string;
  address: Address;
  vatNumber: string;
  valid: boolean;
  paymentMethod: PaymentMethod;
  primaryPaymentMethod: boolean;
  backupPaymentMethod: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface Address {
  phone: string;
  street1: string;
  street2: string;
  city: string;
  region: string;
  postalCode: string;
  country: string;
}

export interface PaymentMethod {
  object: string;
  cardType: string;
  firstSix: string;
  lastFour: string;
  lastTwo: string;
  expMonth: number;
  expYear: number;
  gatewayToken: string;
  ccBinCountry: string;
  gatewayCode: string;
  billingAgreementId: string;
  nameOnAccount: string;
  accountType: string;
  routingNumber: string;
  routingNumberBank: string;
  username: string;
}

export const PaymentMethodObject = {
  creditCard: 'credit_card',
  ach: 'bank_account_info',
} as const;

export const paymentMethods = [
  { id: PaymentMethodObject.ach, text: I18nEnum.ACH },
  { id: PaymentMethodObject.creditCard, text: I18nEnum.CreditCard },
];

type UpdateBillingBodyAddress = {
  street1: string;
  street2?: string;
  city: string;
  region: string;
  postal_code: string;
};

export type UpdateBillingBody =
  | {
      number: string;
      month: string;
      year: string;
      cvv: string;
      address: UpdateBillingBodyAddress;
    }
  | {
      name_on_account: string;
      routing_number: string;
      account_number: string;
      account_type: string;
      address: UpdateBillingBodyAddress;
    };

export interface MeasurementCredit {
  code: string;
  creditsAmount: number;
  price: number;
  pricePerCredit: number;
  savedMoney: number;
}

export interface BuyMeasurementCreditsBody {
  account: {
    code: string;
  };
  lineItems: [{ itemCode: string }];
  couponCodes?: string[];
  billing?: UpdateBillingBody;
}

export interface CompanyMeasurementCredits {
  availableCreditsAmount: number;
  hasTrial: boolean;
  pendingCreditsAmount: boolean;
}

export interface LocationState {
  isShouldOpenBuyCreditsView?: boolean;
}

export interface Coupon {
  code: string;
  discount:
    | {
        type: 'fixed';
        currencies: [{ amount: number }];
      }
    | {
        type: 'percent';
        percent: number;
      };
}

export const RenewOptions = {
  lessThan3: 'lessThan3',
  date: 'date',
} as const;

export const Frequency = {
  weekly: 'weekly',
  monthly: 'monthly',
} as const;

export interface RenewOption {
  creditCode: string;
  renewType: string;
  frequency?: string;
  startDate?: string;
  renewDate?: string;
}

export interface BillingSettingsConfig {
  showLicense: boolean;
}
