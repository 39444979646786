import * as constants from './constants';

type SpinnerOptions = {
  backgroundOpacity: number;
};

export const show = (options?: SpinnerOptions) => ({
  type: constants.SHOW,
  options,
});

export const hide = () => ({
  type: constants.HIDE,
});
