import { ListQuery } from 'types';
import { MEASUREMENTS_FORM_FIELDS } from './constants';
import { FormType } from 'modules/orders/components/CreateOrder/types';

export enum OrdersTabKeys {
  MyOrders = 'MyOrders',
  AccountsManagement = 'AccountsManagement',
  Formulas = 'Formulas',
}

export interface Account {
  accountName: string;
  accountLegacyId: string;
  branch: {
    branchName: string;
    branchNumber: string;
    address: Address;
  };
}

export interface Address {
  country: string | null;
  address3: string | null;
  address2: string | null;
  city: string;
  address1: string;
  postalCode: string;
  state: string;
}

export interface Template {
  templateId: string;
  templateName: string;
  lastModifiedDate: string;
  templateItems: TemplateItem[];
}

export const Variations = {
  color: 'color',
  size: 'size',
  thickness: 'thickness',
  packaging: 'packaging',
  width: 'width',
  length: 'length',
  MFG: 'MFG',
};

export interface TemplateItem {
  templateItemId?: string;
  imageUrl: {
    thumbnail: string;
    large: string;
    swatch: string;
  };
  productOnErrorImageUrl: string;
  itemNumber: string;
  nickName: string;
  productOrItemNumber: string;
  itemOrProductDescription: string;
  variations: {
    [k in keyof typeof Variations]?: { [k: string]: string[] };
  };
  skusVariation: {
    [k: number]: {
      [k in keyof typeof Variations]?: string[];
    };
  };
  quantity: number;
  unitPrice: number;
  unitOfMeasure: string;
  available: boolean;
  // custom fields
  includingTemplates: { name: string; id: string }[];
  isCustom?: boolean;
}

export interface TemplateItemDetails {
  product: {
    baseProductName: string;
    internalProductName: string;
    itemNumber: string;
    productImage: string;
    productOnErrorImage: string;
    productId: string;
    productName: string;
    longDesc: string;
  };
  skuList: { itemNumber: string; thumbImage: string }[];
  currentSKU: {
    currentUOM: string;
    unitPrice: number;
    thumbImage: string;
    itemImage: string;
    variations: {
      [k in keyof typeof Variations]?: string[];
    };
  };
  variations: { [k in keyof typeof Variations]?: { [k: string]: string[] } };
}

export interface MarketsAccounts {
  [marketId: number]: string;
}

export interface OrdersFilter {
  dateFrom?: string;
  dateTo?: string;
  status?: string[];
  account?: string[];
}

export interface OrdersQuery extends ListQuery {
  filter?: OrdersFilter;
}

export type FilterOptions = Record<'account' | 'status', Record<string, number>>;

export const OrderStatuses = {
  processing: 'processing',
  invoiced: 'invoiced',
  readyPickUp: 'ready_pick_up',
  readyDelivery: 'ready_delivery',
  pending: 'pending',
  delivered: 'delivered',
  draft: 'draft',
} as const;

export type OrderStatus = typeof OrderStatuses[keyof typeof OrderStatuses];

export interface Order {
  orderId: string;
  purchaseOrderNo: string;
  accountId: string;
  orderPlacedDate: string;
  total: number;
  otherCharges: number;
  tax: number;
  subTotal: number;
  orderStatusCode: keyof typeof OrderStatuses;
  job: Job;
  specialInstruction: string;
  shipping: {
    shippingBranch: string;
    address?: Address;
    shippingMethod: string;
  };
  // custom fields
  status: OrderStatus;
  customerName: string;
  projectAddress: string;
  shippingAddress: string;
  shippingDate?: string;
  branchName: string;
  statisticsId: number;
  draftOrderId?: number;
}

export interface DTInfo {
  status: string;
  type: string;
  destination: Address;
  photos: {
    largeUrl: string;
    thumbnailUrl: string;
    timestamp: string;
  }[];
  projectedArrivalDate: string;
  earliestRequestedDelivery: string;
}

export interface OrderLineItem {
  productImageUrl: string;
  unitPrice: number;
  itemNumber: string;
  quantity: number;
  itemOrProductDescription: string;
  unitOfMeasure: string;
  productOrItemNumber: string;
  productOnErrorImageUrl: string;
  subTotal: number;
}

export interface Formula {
  id?: number;
  name: string;
  assignments: Assignment[];
  createdAt: string;
  updatedAt: string;
  entity: FormulaEntity;
  isDefault: boolean;
}

export interface Assignment {
  id?: number;
  accountId: string;
  productId: string;
  templateId: string;
  // temporary fields, aren't stored in DB
  templateIds: string[]; // used for templates Select
  itemTemplateIds: string[]; // used to save only selected Item templates
  itemTemplateNames: string[];
  parentAssignments: Assignment[]; // used to disjoin assignments
  accountName: string;
  fullName: string;
  productName: string;
  templateName: string;
  unit: string;
}

export interface FormulaEntity {
  preview: string;
  value: string[];
}

export type MeasurementsFormFieldsKey =
  typeof MEASUREMENTS_FORM_FIELDS[keyof typeof MEASUREMENTS_FORM_FIELDS];

export interface Job {
  jobName: string;
  jobNumber: string;
}

export interface FormulaQuery extends ListQuery {
  templateIds?: string[];
}

export interface OrderDetails {
  order: Order;
  DTInfo: DTInfo | null;
  lineItems: OrderLineItem[];
}

export interface BeaconOrder extends FormType {
  id: number;
  accountId: string;
  orderId: string;
  stepperState: {
    values: boolean[];
    lastStep: number;
  };
}

export interface AssignmentMatchBody {
  accountId: string;
  templateIds: string[];
  productId: string;
  excludeFormulaId?: number;
}

export type CreateOrderProps = null | {
  statisticsId: number;
  order?: BeaconOrder;
};

export interface TaxRates {
  state: string;
  zipCode: string;
  taxRegionName: string;
  stateRate: string;
  estimatedCombinedRate: string;
  estimatedCountyRate: string;
  estimatedCityRate: string;
  estimatedSpecialRate: string;
  riskLevel: string;
}

export type MeasurementInputValues = {
  [k in MeasurementsFormFieldsKey]: number | boolean | string;
};

export interface BeaconProductsRequestParams {
  page: number;
  search: string;
  accountId: number;
}

export interface BeaconProductItem {
  productId: string;
  baseProductName: string;
  internalProductName: string;
  productOnErrorImage: string;
  productAdditionalOnErrorImage: string;
  productImage: string;
  productName: string;
  currentSKU: {
    currentUOM: string;
    itemNumber: string;
    unitPrice: number;
    variations: { [k in keyof typeof Variations]?: string[] };
  };
}

export interface BeaconProductsList {
  pageNumRecs: number;
  totalNumRecs: number;
  items: BeaconProductItem[];
}

export interface BeaconProductVariations {
  productId: string;
  productMultiVariation: {
    skus: {
      [k: number]: {
        [k in keyof typeof Variations]?: string[];
      };
    };
  } | null;
}

export interface OrdersSettingsConfig {
  beaconAvailable: boolean;
}
