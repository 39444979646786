export const SECONDS_INTERVAL = {
  MIN: 0,
  MAX: 60,
};

export const STORAGE_ANALYTICS = 'analytics';

export const STORAGE_SESSION_ID_ALIAS = 'sessionId';
export const STORAGE_SESSION_ID_EXPIRATION_DATE_ALIAS = 'sessionIdExpirationDate';
export const STORAGE_SESSION_NO_EXPIRATION = 'noExpiration';

export const STORAGE_STATISTICS_ID_ALIAS = 'statisticsId';
export const STORAGE_STATISTICS_ID_EXPIRATION_DATE_ALIAS = 'statisticsIdExpirationDate';

export const STORAGE_BROADCAST_CHANNEL = 'storage-channel';
export const LEAD_CREATED_MESSAGE = 'lead-created';

export const MULTIPLIERS: { [key: string]: number } = {
  FLAT: 1,
  SHALLOW: 1.055,
  MEDIUM: 1.16,
  STEEP: 1.305,
};
