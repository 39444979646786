import { State } from 'types';

export const selectMarket = (state: State) => state.market;
export const selectMarkets = (state: State) => selectMarket(state).markets;
export const selectLogisticsMarkets = (state: State) => selectMarket(state).logisticsMarkets;
export const selectSelectedMarket = (state: State) => selectMarket(state).selectedMarket;
export const selectMarketsList = (state: State) => selectMarket(state).marketsList;
export const selectStates = (state: State) => selectMarket(state).states;
export const selectUserStates = (state: State) => selectMarket(state).userStates;
export const selectCountryStatesFeatureCollection = (state: State) =>
  selectMarket(state).countryStatesFeatureCollection;
