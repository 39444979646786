import axios from 'axios';

import { googleMapsInstance } from 'modules/googleMaps/classes/GoogleMapsInstance';

export const coordinatesApi = () =>
  axios.get(`${process.env.REACT_APP_GEODATA_API_URL}/coordinates`);

export const searchGoogleApi = async (
  input: string,
  sessionToken: google.maps.places.AutocompleteSessionToken,
) => {
  if (!googleMapsInstance.googleMapsAutocompleteService) {
    console.error('!googleMapsInstance.googleMapsAutocompleteService');
    return [];
  }

  const result = await googleMapsInstance.googleMapsAutocompleteService.getPlacePredictions({
    input,
    locationBias: 'IP_BIAS',
    componentRestrictions: {
      country: ['us', 'ca'],
    },
    types: ['address'],
    sessionToken,
  });

  // good types: "street_address", "premise", ?"subpremise"
  // bad types: "route"
  // https://developers.google.com/maps/documentation/javascript/supported_types#table2

  const filtered = result.predictions.filter(prediction => {
    return (
      prediction.types.includes('street_address') ||
      prediction.types.includes('premise') ||
      prediction.types.includes('subpremise')
    );
  });
  return filtered;
};
