import omit from 'lodash/omit';

import api from 'services/api';
import { ExportType, ListQuery } from 'types';
import {
  FinancingSetting,
  CreateMerchantBody,
  InviteConsumerBody,
  UpdateOfferCodeBody,
  UpdateMerchantRateSheetBody,
  CreateOfferCodeBody,
  ConsumerPaymentRequestBody,
  ConsumerPaymentRefundBody,
  VerifyMicroDepositsBody,
  UpsertPaymentDetailsBody,
  FinancingType,
} from './types';

export const getFinancingAPI = (userId, marketSlug) =>
  api.get(`user/${userId}/financing/${marketSlug}`);

export const saveFinancingAPI = ({
  userId,
  marketSlug,
  financing,
}: {
  userId: number;
  marketSlug: string;
  financing: FinancingSetting;
}) => api.put(`user/${userId}/financing/${marketSlug}`, omit(financing, 'market'));

export const resetFinancingSettingsAPI = (userId, marketSlug) =>
  api.delete(`user/${userId}/financing/${marketSlug}`);

export const getFinancingTypes = (userId: number) => api.get(`/user/${userId}/financing-type`);

export const saveFinancingTypeAPI = (userId: number, body: FinancingType) =>
  api.put(`/user/${userId}/financing-type`, body);

export const deleteFinancingTypeAPI = (userId: number, financingTypeId: number) =>
  api.delete(`/user/${userId}/financing-type/${financingTypeId}`);

export const applyFinancingToMarketsAPI = ({
  userId,
  marketSlugs,
  financing,
}: {
  userId: number;
  marketSlugs: string[];
  financing: Partial<FinancingSetting>;
}) =>
  api.put(`user/${userId}/apply-financing`, { financing: omit(financing, 'market'), marketSlugs });

export const switchToBasicFinancingAPI = ({
  userId,
  marketSlugs,
}: {
  userId: number;
  marketSlugs: string[];
}) => api.post(`user/${userId}/switch-to-basic-financing`, { marketSlugs });

export const applyLoanProductToMarketsAPI = ({
  userId,
  marketsToApply,
  loanProductId,
}: {
  userId: number;
  marketsToApply: { [marketSlug: string]: number[] };
  loanProductId: number;
}) => api.put(`user/${userId}/apply-loan-product`, { marketsToApply, loanProductId });

export const createMerchantAPI = (body: CreateMerchantBody, userId: number) =>
  api.post(`user/${userId}/momnt/merchant`, body);

export const getMerchantDetailsAPI = (userId: number) => api.get(`user/${userId}/momnt/merchant`);

export const getMerchantLoanApplicationsAPI = (userId: number, params: ListQuery) =>
  api.get(`user/${userId}/momnt/loan-applications`, {
    params,
  });

export const getMerchantLoanInvitationsAPI = (userId: number, params: ListQuery) =>
  api.get(`user/${userId}/momnt/loan-invitations`, {
    params,
  });

export const getMerchantAllTransactionsAPI = (userId: number, params: ListQuery) =>
  api.get(`user/${userId}/momnt/all-transactions`, {
    params,
  });

export const getMerchantAvailableTransactionsAPI = (userId: number, params: ListQuery) =>
  api.get(`user/${userId}/momnt/available-transactions`, {
    params,
  });

export const getMerchantRecentActivityAPI = (userId: number, params: ListQuery) =>
  api.get(`user/${userId}/momnt/recent-activity`, {
    params,
  });

export const getMerchantOfferCodesAPI = (userId: number) =>
  api.get(`user/${userId}/momnt/offer-codes`);

export const createMerchantOfferCodeAPI = (userId: number, body: CreateOfferCodeBody) =>
  api.post(`user/${userId}/momnt/offer-code`, body);

export const updateMerchantOfferCodeAPI = (
  userId: number,
  offerCodeId: number,
  body: UpdateOfferCodeBody,
) => api.patch(`user/${userId}/momnt/offer-code/${offerCodeId}`, body);

export const inviteConsumerAPI = (userId: number, body: InviteConsumerBody) =>
  api.post(`user/${userId}/momnt/invite-consumer`, body);

export const inviteConsumerWidgetAPI = (wildcard: string, body: InviteConsumerBody) =>
  api.post(`widget/${wildcard}/momnt/invite-consumer`, body);

export const getMerchantStatements = (userId: number, params: ListQuery) =>
  api.get(`user/${userId}/momnt/statements`, { params });

export const getMerchantStatementDetails = (userId: number, statementId: number) =>
  api.get(`user/${userId}/momnt/statements/${statementId}`);

export const createConsumerPaymentRequestAPI = (userId: number, body: ConsumerPaymentRequestBody) =>
  api.post(`user/${userId}/momnt/payment-request`, body);

export const createConsumerPaymentRefundAPI = (userId: number, body: ConsumerPaymentRefundBody) =>
  api.post(`user/${userId}/momnt/payment-refund`, body);

export const getMerchantRateSheetsAPI = (userId: number) =>
  api.get(`user/${userId}/momnt/rate-sheets`);

export const updateMerchantRateSheetAPI = (userId: number, body: UpdateMerchantRateSheetBody) =>
  api.patch(`user/${userId}/momnt/rate-sheet`, body);

export const getConsumerRefundableTransactionsAPI = (userId: number, applicationId: string) =>
  api.get(`user/${userId}/momnt/refundable-transactions/${applicationId}`);

export const exportMerchantStatementDetails = (
  userId: number,
  statementId: number,
  type: ExportType,
) =>
  api.get(`user/${userId}/momnt/statements/${statementId}/export/${type}`, {
    responseType: 'blob',
  });

export const getWidgetMerchantOfferCodesAPI = (wildcard: string) =>
  api.get(`widget/${wildcard}/momnt/offer-codes`);

export const getWidgetMerchantRateSheetsAPI = (wildcard: string) =>
  api.get(`widget/${wildcard}/momnt/rate-sheets`);

export const verifyMicroDepositsAPI = (userId: number, body: VerifyMicroDepositsBody) =>
  api.post(`user/${userId}/momnt/verify-micro-deposits`, body);

export const initiateMicroDepositsAPI = (userId: number) =>
  api.post(`user/${userId}/momnt/initiate-micro-deposits`, {});

export const getIsClpCompanyAPI = (userId: number) => api.get(`user/${userId}/is-clp-company`);

export const getStatesUnavailableForMomntAPI = (wildcard: string) =>
  api.get(`widget/${wildcard}/states-unavailable-for-momnt`);

export const upsertPaymentDetailsAPI = (userId: number, body: UpsertPaymentDetailsBody) =>
  api.post(`user/${userId}/momnt/payment-details`, body);

export const resendInvitationApi = (userId: number, loanId: number) =>
  api.post(`user/${userId}/resend-invitation/${loanId}`);

export const getLoanApplicationDetailsAPI = (userId: number, loanId: string) =>
  api.get(`user/${userId}/momnt/loan-application/${loanId}`);
