import { decrypt, encrypt } from 'services/crypto';

// round the total squares count up to the nearest third, which is how roofing squares are sold
export function getRoundedSquaresCount(squareFeet: number) {
  // get the appx. squares count
  const squares = squareFeet / 100;

  // use the decimal remainder to round to 1/3 of a square
  const remainder = squares % 1;

  // the remainder should never be negative
  if (remainder < 0) {
    console.error(
      'A negative remainder was calculated for the total roof squares. This is likely a bug.',
      squares,
      remainder,
    );
    return squares;
  }

  // do not round up an exact squares count (rare)
  if (remainder === 0) {
    return squares;
  }

  // round up to the nearest third square
  let rounding = 0;
  if (remainder <= 0.33) rounding = 0.33;
  else if (remainder <= 0.66) rounding = 0.66;
  else if (remainder <= 1) rounding = 1;
  else console.error('Rounding total roof squares was not handled correctly.', squares, remainder);

  return Math.floor(squares) + rounding;
}

export const decryptQQ = async (qq: string) => {
  if (!process.env.REACT_APP_QQ_RESPONSE_SECRET_KEY) {
    console.error('missing configuration');
    return null;
  }

  const decrypted = await decrypt(qq);

  return JSON.parse(decrypted);
};

export const encryptQQ = async (qq: string) => {
  if (!process.env.REACT_APP_QQ_RESPONSE_SECRET_KEY) {
    console.error('missing configuration');
    return '';
  }

  const encrypted = await encrypt(qq);

  return encrypted;
};
