export const insertAfter = (referenceNode: Element, node: Element) => {
  const nextSibling = referenceNode.nextElementSibling;

  return nextSibling
    ? referenceNode.parentNode?.insertBefore(node, nextSibling)
    : referenceNode.parentNode?.appendChild(node);
};

const MAX_ATTEMPTS = 10;
let attempts = 0;
let timeout = 500;

export const insertIntoContainer = (node: Element) => {
  const rqpContainer = document.getElementById('roof-quote-pro-embedded');

  if (attempts >= MAX_ATTEMPTS) {
    return;
  }

  if (!rqpContainer) {
    setTimeout(() => {
      insertIntoContainer(node);
    }, timeout);
    attempts += 1;
    timeout += 100;
    return;
  }

  rqpContainer.appendChild(node);
};
