import { I18nEnum, ThunkResult } from 'types';
import * as subscriptionSelectors from 'modules/subscription/selectors';

import { QuickQuoteConfigState } from './types';
import * as constants from './constants';
import { selectWidgetSettings } from 'modules/widget/selectors';

export const update = (config: Partial<QuickQuoteConfigState>) => ({
  type: constants.UPDATE,
  config,
});

const resetToDefaults = (config: QuickQuoteConfigState) => ({
  type: constants.RESET,
  defaultState: config,
});

export const setMeasurementsOrderValuesInQQConfig =
  (quickQuoteConfig: QuickQuoteConfigState): ThunkResult<Promise<void>> =>
  async dispatch => {
    dispatch(
      update({
        productCard: {
          ...quickQuoteConfig.productCard,
          sendDetailsDisableTitle: I18nEnum.ProposalPending,
        },
        modals: {
          ...quickQuoteConfig.modals,
          thankYou: {
            subtitle: I18nEnum.WeAreWorkingOnYourFinalProposal,
          },
        },
      }),
    );
  };

export const reset = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const subscriptionConfig = subscriptionSelectors.selectSubscriptionConfig(getState());
  const branding = selectWidgetSettings(getState());
  const isMeasurementsOrderEnabled = branding.measurementsOrder.isMeasurementsOrderEnabled;

  dispatch(resetToDefaults(subscriptionConfig.defaultQuickQuoteConfigState));

  if (isMeasurementsOrderEnabled) {
    dispatch(setMeasurementsOrderValuesInQQConfig(subscriptionConfig.defaultQuickQuoteConfigState));
  }
};
