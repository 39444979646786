import { openDB, OpenDBCallbacks } from 'idb';
import { STORAGE_ANALYTICS } from 'modules/global/constants';
import { STORAGE_QUICK_QUOTE } from 'modules/quickQuote/constants';

const DEFAULT_DB_NAME = 'contractor';
const IDB_VERSION = 6;

const TABLES = [STORAGE_QUICK_QUOTE, STORAGE_ANALYTICS];

const idbCallbacks: OpenDBCallbacks<unknown> = {
  upgrade(db) {
    TABLES.forEach(table => {
      if (!db.objectStoreNames.contains(table)) {
        db.createObjectStore(table);
      }
    });
  },
};

class IDB {
  private _isIDBSupported = true;

  constructor() {
    this.openIDB().then(db => {
      if (!db) {
        this._isIDBSupported = false;
        return;
      }

      this._isIDBSupported = true;
    });
  }

  async openIDB() {
    try {
      const db = await openDB(DEFAULT_DB_NAME, IDB_VERSION, idbCallbacks);
      return db;
    } catch (error) {
      console.error('IDB openIDB error', error);
    }
  }

  async setItem(storeKey: string, fieldKey: string | number, value: unknown) {
    const db = await this.openIDB();

    if (!db) {
      return;
    }

    const tx = db.transaction(storeKey, 'readwrite');
    const store = tx.objectStore(storeKey);

    await Promise.all([await store.put(value, fieldKey), await tx.done]);
    db.close();
  }

  async getItem(storeKey: string, fieldKey: string | number) {
    const db = await this.openIDB();

    if (!db) {
      return;
    }

    const tx = db.transaction(storeKey, 'readonly');
    const store = tx.objectStore(storeKey);

    const [result] = await Promise.all([await store.get(fieldKey), await tx.done]);

    db.close();
    return result;
  }

  async getAllItems(storeKey: string) {
    const db = await this.openIDB();

    if (!db) {
      return;
    }

    const tx = db.transaction(storeKey, 'readonly');
    const store = tx.objectStore(storeKey);

    const [result] = await Promise.all([await store.getAll(), await tx.done]);

    db.close();
    return result;
  }

  async deleteItem(storeKey: string, fieldKey: string | number) {
    const db = await this.openIDB();

    if (!db) {
      return;
    }

    const tx = db.transaction(storeKey, 'readwrite');
    const store = tx.objectStore(storeKey);

    await Promise.all([await store.delete(fieldKey), await tx.done]);
    db.close();
  }

  get isIDBSupported() {
    return this._isIDBSupported;
  }
}

export default new IDB();
