export const setupQueryParamsBase = (widgetId: string) => {
  const script = document.currentScript || document.querySelector(`script[src*="${widgetId}.js"]`);

  if (!script) {
    throw new Error(`RoofQuote PRO™ script is not found`);
  }

  const scriptSrc = script.getAttribute('src');

  if (!scriptSrc) {
    throw new Error(`RoofQuote PRO™ script src is not found`);
  }

  const urlObj = new URL(scriptSrc);

  if (!urlObj.search) {
    throw new Error(`RoofQuote PRO™ query params are not present`);
  }

  if (!urlObj.searchParams.has('id')) {
    throw new Error(`RoofQuote PRO™ query param \`id\` not found`);
  }

  const wildcardId = urlObj.searchParams.get('id');

  if (!wildcardId) {
    throw new Error('RoofQuote PRO™ widget id must be provided');
  }

  return {
    wildcardId,
    urlObj,
    script,
  };
};
