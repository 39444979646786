import store from 'store';

import { FinancingSetting, OfferCode, RateSheet } from 'modules/financing/types';
import { Line } from 'modules/product';
import {
  RoofleStructure,
  QuickQuoteState,
  selectors as quickQuoteSelectors,
} from 'modules/quickQuote';
import { PublicQuoteSettingsModel } from 'modules/quoteSettings/types';
import { QuickQuoteProductPriceSettings, SquareFeetEnum } from 'modules/repQuotes';
import { selectors as companySelectors } from 'modules/company';
import { SearchAddressFeature, selectors as searchSelectors } from 'modules/search';
import { selectors as financingSelectors } from 'modules/financing';

import { State } from 'types';
import { getPaymentMultiplier } from './utils';
import { PricesParams } from '../types';

export class PriceConfig {
  constructor({
    squareFeetPrice = 0,
    fixedPrice,
    state,
    selectQQFinancing = true,
    unavailableStates,
    line,
  }: PricesParams) {
    this.squareFeetPrice = squareFeetPrice;
    this.fixedPrice = Number((squareFeetPrice * 100).toFixed(2));
    this.isFixedPrice = fixedPrice;
    this.product = line;
    this.state = state || store.getState();
    this.selectQQFinancing = selectQQFinancing;
    this.unavailableStates = unavailableStates || [];
  }

  readonly fixedPrice: number;

  readonly isFixedPrice: boolean;

  readonly product?: Line;

  readonly selectQQFinancing: boolean;

  readonly state: State;

  readonly squareFeetPrice: number;

  readonly unavailableStates: string[];

  get addressFeature(): SearchAddressFeature | null {
    return searchSelectors.selectAddressFeature(this.state);
  }

  get centerpointState(): string {
    return this.selectQQFinancing ? this.quickQuote.centerpointState || '' : '';
  }

  get companyQuoteSettings(): PublicQuoteSettingsModel | null {
    return companySelectors.selectCompanyQuoteSettings(this.state);
  }

  get financing(): FinancingSetting {
    return this.selectQQFinancing
      ? quickQuoteSelectors.selectQuickQuoteMarketFinancing(store.getState())
      : financingSelectors.selectDefinedBasicFinancing(store.getState());
  }

  get includedStructures(): RoofleStructure[] {
    return this.quickQuote.structures
      .filter((structure: RoofleStructure) => structure.isIncluded)
      .map((structure: RoofleStructure) => {
        if (!this.productPriceSettings) {
          return structure;
        }
        const { squareFeet: squareFeetSettings } = this.productPriceSettings;

        const isCustomSquareFeet =
          squareFeetSettings && squareFeetSettings.type === SquareFeetEnum.Custom;

        const squareFeet = isCustomSquareFeet
          ? squareFeetSettings.value[structure.geoJsonPolygon.id as string]
          : structure.measurements.squareFeet;
        const wholeSquareFeet = isCustomSquareFeet
          ? squareFeetSettings.value[structure.geoJsonPolygon.id as string]
          : structure.measurements.wholeSquareFeet;

        return {
          ...structure,
          measurements: {
            ...structure.measurements,
            squareFeet,
            wholeSquareFeet,
          },
        };
      });
  }

  get includedStructuresTotalSquareFeet(): number {
    const totalSquareFeet = this.includedStructures.reduce(
      (sqft, structure) => sqft + structure.measurements.wholeSquareFeet,
      0,
    );

    return Math.ceil(totalSquareFeet);
  }

  get isUserDetailsProvided(): boolean {
    return quickQuoteSelectors.selectUserDetailsProvided(this.state);
  }

  get offerCodes(): OfferCode[] {
    return financingSelectors.selectOfferCodes(store.getState()).data;
  }

  get paymentMultiplier(): number {
    return getPaymentMultiplier(this.financing.percentageRate, this.financing.months);
  }

  get productPriceSettings(): QuickQuoteProductPriceSettings | null {
    return quickQuoteSelectors.selectProductPriceSettings(this.state);
  }

  get quickQuote(): QuickQuoteState {
    return quickQuoteSelectors.selectQuickQuote(this.state);
  }

  get rateSheets(): RateSheet[] {
    return financingSelectors.selectRateSheets(store.getState());
  }
}

export class OriginalPriceConfig extends PriceConfig {
  get productPriceSettings(): null {
    return null;
  }
}

export class InstantQuotePriceConfig extends PriceConfig {
  get includedStructures(): RoofleStructure[] {
    return this.quickQuote.structures.filter((structure: RoofleStructure) => structure.isIncluded);
  }
}
