export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const getCorrectDocumentRootElement = () => {
  return document.compatMode === 'CSS1Compat' ? document.documentElement : document.body;
};

export const formatPhone = (phone: string) =>
  phone.replace(/[^0-9]+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

export const formatPhoneToHref = (phone: string) =>
  phone.replace(/[^0-9]+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, 'tel:+1-$1-$2-$3');
