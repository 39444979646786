export type SnippetState = {
  snippets: Snippet[];
  snippetsToInsert: {
    [key in SnippetTypeEnum]?: SnippetValue<string>;
  };
};

export interface SnippetValue<T> {
  lineId: number;
  snippetValue?: T;
}

export type Snippet = {
  id: number;
  name: string;
  type: SnippetTypeEnum;
  createdAt: Date;
  value: string | number;
};

export enum SnippetTypeEnum {
  whatsIncluded = 'whatsIncluded',
  additionalCosts = 'additionalCosts',
  price = 'price',
}
